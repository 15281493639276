import type { Rest } from '@libs/data-access/entities'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { getSliceActionTypes } from '@spa/redux/utils/slices'

export enum Step {
  LOADING = 'LOADING',
  SUBMIT_LOADING = 'SUBMIT_LOADING',
  REVALUATION_FORM = 'REVALUATION_FORM',
  SUBMIT_CONFIRMATION = 'SUBMIT_CONFIRMATION',
  SUBMIT_ERROR_FEEDBACK = 'SUBMIT_ERROR_FEEDBACK',
  FORM = 'FORM'
}

export type RestModalState = {
  loading: boolean
  currentStep: Step
  submit: {
    errorContractIds: number[] | null
    successResults: Rest[] | null
  }
}

const initialState: RestModalState = {
  loading: false,
  currentStep: Step.FORM,
  submit: {
    errorContractIds: null,
    successResults: null
  }
}

const restModalSlice = createSlice({
  name: 'ui/restModal',
  initialState,
  reducers: {
    resetRestModal(state) {
      return {
        ...state,
        currentStep: initialState.currentStep,
        submit: { ...initialState.submit }
      }
    },
    updateStep(state, action: PayloadAction<RestModalState['currentStep']>) {
      state.currentStep = action.payload
    },
    createRestsSuccess(state, action: PayloadAction<RestModalState['submit']>) {
      state.submit = action.payload
    }
  }
})

export const { resetRestModal, updateStep, createRestsSuccess } =
  restModalSlice.actions
export const actionTypes = getSliceActionTypes(restModalSlice)
export default restModalSlice.reducer
