import moment from 'moment'
import { createSelector } from 'reselect'

import { getDate } from '../getDate/getDate'

export const getDateIsPastWeek = createSelector(
  getDate,

  date => {
    const today = moment()
    return moment(date).isBefore(today, 'isoWeek')
  }
)
