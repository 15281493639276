import { Anchor, Banner } from '@libs/ui/ds'
import { useCommonAppTranslation } from '@libs/utils/translations'
import useUpgradeUrl from '@spa/redux/billing/plan/hooks/useUpgradeUrl'
import moment from 'moment'
import type { FC } from 'react'

type Props = {
  isLastTrialDay: boolean
  trialEndsAt: Date | null
}
export const SubscriptionBanner: FC<Props> = ({
  isLastTrialDay,
  trialEndsAt
}) => {
  const { t } = useCommonAppTranslation()
  const { url } = useUpgradeUrl('subscription')

  const remainingDays = moment(trialEndsAt).diff(moment(), 'days', true)
  const remainingDayPlusOne = Math.ceil(remainingDays)

  return (
    <Banner
      data-testid='trial-banner'
      variant={isLastTrialDay ? 'warning' : 'information'}
    >
      {isLastTrialDay
        ? t('banner.trial.oneDayLeft')
        : t('banner.trial.default', {
            value: remainingDayPlusOne
          })}
      <Anchor href={url} rel='noreferrer' variant='inherits'>
        {t('banner.trial.link')}
      </Anchor>
    </Banner>
  )
}
