import { useMutation } from '@tanstack/react-query'
import type {
  ApiErrorResponse,
  UseAppMutationOptions
} from 'libs/data-access/queries/src/lib/types'

import { invalidateAccountDetailsCache } from '../../../AccountDetails/accountDetails.cacheKeys'
import { invalidateAccountStatusCache } from '../../../AccountStatus/accountStatus.cacheKeys'
import type { AcccountChurnLocationBasedOptions } from '../accountChurnLocationBased.api'
import { churnAccountLocationBased } from '../accountChurnLocationBased.api'
import { accountChurnLocationBased } from '../accountChurnLocationBased.cacheKeys'

export const useAccountChurnLocationBased = (
  mutationOptions?: UseAppMutationOptions<
    {},
    ApiErrorResponse,
    AcccountChurnLocationBasedOptions
  >
) => {
  return useMutation({
    mutationKey: accountChurnLocationBased.one(),
    mutationFn: (options: AcccountChurnLocationBasedOptions) =>
      churnAccountLocationBased(options),
    onSuccess: (...args) => {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      mutationOptions?.onSuccess(...args)

      invalidateAccountStatusCache()
      invalidateAccountDetailsCache()
    }
  })
}
