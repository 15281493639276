import type { ReduxState } from '@spa/redux/ReduxState'
import { composeCacheKey } from '@spa/redux/utils/reselect'
import { sortBy } from 'lodash-es'
import moment from 'moment'
import createCachedSelector from 're-reselect'

import { weeklyschedulesBaseSelectors } from '../weeklyschedulesBaseSelectors'

type Params = {
  teamId: number
  date: 'YYYY-MM-DD' | string
}

export const getWeeklyscheduleFromTeamId = createCachedSelector(
  weeklyschedulesBaseSelectors.getCachedList,
  (_: ReduxState, params: Params) => params.date,
  (_: ReduxState, params: Params) => params.teamId,

  (weeklyschedules, date, teamId) => {
    const startWeek = moment(date).startOf('isoWeek').format('YYYY-MM-DD')

    /**
     * Order the weeklyschedules by date so we are sure to always pick the most recent
     * In case we have multiple weeklyschedules for the same week
     */
    const weeklyschedulesSortedByCreatedAt = sortBy(
      weeklyschedules,
      'created_at'
    ).reverse()

    return (
      weeklyschedulesSortedByCreatedAt.find(
        w => w.week_start_date === startWeek && w.team_id === teamId
      ) || null
    )
  }
)((_, params) => composeCacheKey(params.date, params.teamId))
