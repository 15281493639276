export const AddIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 10C0 9.54916 0.365482 9.18367 0.816327 9.18367H19.1837C19.6345 9.18367 20 9.54916 20 10C20 10.4508 19.6345 10.8163 19.1837 10.8163H0.816327C0.365482 10.8163 0 10.4508 0 10Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 0C10.4508 0 10.8163 0.365482 10.8163 0.816327V19.1837C10.8163 19.6345 10.4508 20 10 20C9.54916 20 9.18367 19.6345 9.18367 19.1837V0.816327C9.18367 0.365482 9.54916 0 10 0Z'
        fill='currentColor'
      />
    </svg>
  )
}
