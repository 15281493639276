import { useMediaQueryThreshold } from '@libs/ui/ds/lib/theme/screens/useMediaQueryThreshold/useMediaQueryThreshold'
import type { FC, ReactElement } from 'react'
import { memo } from 'react'

import type { HideAtProps } from './HideAt.types'

export const HideAt: FC<HideAtProps> = memo(({ children, hideAt }) => {
  const match = useMediaQueryThreshold({ mediaQueryTreshold: hideAt })

  return match ? null : (children as ReactElement)
})
