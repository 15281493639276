import {
  AlertDiamondIcon,
  CheckIcon,
  InformationCircleIcon,
  AlertTriangleIcon
} from '@libs/ui/ds/assets/icons'
import { memo } from 'react'

import { IconWrap } from '../../../../dataDisplay/IconWrap/base/IconWrap'
import { Loader } from '../../../Loader/base/Loader'
import type { NotificationParams } from '../notifications.types'

import { toastItemClasses } from './ToastItemClasses'

const toastIconsAdapater = {
  info: <InformationCircleIcon />,
  success: <CheckIcon />,
  warning: <AlertTriangleIcon />,
  error: <AlertDiamondIcon />
}

type ToastItemIconProps = {
  intent: NotificationParams['intent']
}

export const ToastItemIcon = memo(({ intent }: ToastItemIconProps) => {
  if (intent === 'loading') {
    return <Loader theme='dark' size='xs' />
  }

  return (
    <IconWrap
      className={toastItemClasses.icon({ intent })}
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      icon={toastIconsAdapater[intent]}
    />
  )
})

ToastItemIcon.displayName = 'ToastItemIcon'
