export const DuplicateIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 1.63265C0 0.730954 0.730973 0 1.63265 0H13.0612C13.9629 0 14.6939 0.730978 14.6939 1.63265V3.87755C14.6939 4.3284 14.3284 4.69388 13.8776 4.69388C13.4267 4.69388 13.0612 4.3284 13.0612 3.87755V1.63265H1.63265V13.0612H3.87755C4.3284 13.0612 4.69388 13.4267 4.69388 13.8776C4.69388 14.3284 4.3284 14.6939 3.87755 14.6939H1.63265C0.730978 14.6939 0 13.9629 0 13.0612V1.63265Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.30612 6.93878C5.30612 6.03708 6.0371 5.30612 6.93878 5.30612H18.3673C19.2691 5.30612 20 6.0371 20 6.93878V18.3673C20 19.269 19.269 20 18.3673 20H6.93878C6.03709 20 5.30612 19.2691 5.30612 18.3673V6.93878ZM18.3673 6.93878H6.93878V18.3673H18.3673V6.93878Z'
        fill='currentColor'
      />
    </svg>
  )
}
