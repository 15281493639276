import Immutable from 'immutable'
import queryString from 'query-string'

export const getPageItems = (state, storeName, pageNumber) => {
  const itemIds = state[storeName].getIn([
    'pagination',
    'pages',
    Number(pageNumber)
  ])

  if (!itemIds) {
    return Immutable.OrderedMap()
  }

  return Immutable.OrderedMap().withMutations(map => {
    itemIds.forEach(itemId => {
      map.set(itemId, state[storeName].getIn(['data', itemId]))
    })
  })
}

export const getCustomPaginationKey = params => {
  const paramsKeys = Object.keys(params).sort((a, b) => a.localeCompare(b))
  const newSortedObject = paramsKeys.reduce(
    (acc, paramKey) => ({ ...acc, [paramKey]: params[paramKey] }),
    {}
  )

  return queryString.stringify(newSortedObject)
}
