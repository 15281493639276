import { combineReducers } from '@reduxjs/toolkit'

import entitiesReducer from './modules/entities'
import metaReducer from './modules/meta'
import pagniationReducer from './modules/pagination'

export default combineReducers({
  entities: entitiesReducer,
  meta: metaReducer,
  pagination: pagniationReducer
})
