import { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'

export const HandleUnboot = () => {
  const { shutdown } = useIntercom()
  useEffect(() => {
    return () => {
      shutdown()
    }
  })
  return null
}
