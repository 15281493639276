import { notifications } from '@libs/ui/ds'
import i18n from '@libs/utils/translations'

export const breaksHasBeenUpdated = ({
  updatedShiftBreaksCount,
  deletedShiftBreaksCount
}) => {
  const messages = []

  if (deletedShiftBreaksCount) {
    messages.push(
      i18n.t('common.notifications:planning.shifts.resize.breaksDeleted', {
        count: deletedShiftBreaksCount
      })
    )
  }

  if (updatedShiftBreaksCount) {
    messages.push(
      i18n.t('common.notifications:planning.shifts.resize.breaksUpdated', {
        count: updatedShiftBreaksCount
      })
    )
  }

  notifications.info({
    message: messages.join(' ')
  })
}
