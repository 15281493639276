import { useCombobox } from '@mantine/core'
import type { FC } from 'react'
import { memo } from 'react'

import { Combobox } from '../../Combobox/base/Combobox'

import type { DropdownMenuProps } from './DropdownMenu.types'
import { DropdownMenuOption } from './compound/DropdownMenuOption'
import { DropdownMenuTarget } from './compound/DropdownMenuTarget'

export const BaseDropdownMenu: FC<DropdownMenuProps> = memo(
  ({ children, onOpenedChange, closeOnOptionClick = true, ...other }) => {
    const combobox = useCombobox({
      onOpenedChange
    })

    return (
      <Combobox
        {...other}
        store={combobox}
        closeOnOptionClick={closeOnOptionClick}
      >
        {children}
      </Combobox>
    )
  }
)

type CompoundDropdownMenuType = {
  Group: typeof Combobox.Group
  Divider: typeof Combobox.Divider
  Dropdown: typeof Combobox.Dropdown
  Empty: typeof Combobox.Empty
  Option: typeof DropdownMenuOption
  Target: typeof DropdownMenuTarget
}

const TypedDropdownMenu = BaseDropdownMenu as typeof BaseDropdownMenu &
  CompoundDropdownMenuType

TypedDropdownMenu.displayName = 'DropdownMenu'

TypedDropdownMenu.Group = Combobox.Group
TypedDropdownMenu.Divider = Combobox.Divider
TypedDropdownMenu.Dropdown = Combobox.Dropdown
TypedDropdownMenu.Empty = Combobox.Empty
TypedDropdownMenu.Option = DropdownMenuOption
TypedDropdownMenu.Target = DropdownMenuTarget

export const DropdownMenu = TypedDropdownMenu
export type { DropdownMenuProps } from './DropdownMenu.types'
