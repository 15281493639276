import { MIME_TYPES } from '@mantine/dropzone'
import { invert } from 'lodash-es'

export const mimeTypes = MIME_TYPES
export const extensionByMimeType = invert(MIME_TYPES)
export const imagesMimeTypes = [
  mimeTypes.png,
  mimeTypes.gif,
  mimeTypes.jpeg,
  mimeTypes.svg,
  mimeTypes.webp,
  mimeTypes.avif,
  mimeTypes.heic,
  mimeTypes.heif
] as const

export const binaryFactor = 1024
export const binaryUnit = [
  'B',
  'KB',
  'MB',
  'GB',
  'TB',
  'PB',
  'EB',
  'ZB',
  'YB'
] as const

export type BinaryUnit = (typeof binaryUnit)[number]
export type FileExtension = keyof typeof MIME_TYPES
export type MimeType = (typeof MIME_TYPES)[FileExtension]
export type ReadableFileSize = {
  value: number
  unit: BinaryUnit
}

export type ImageMimeType = (typeof imagesMimeTypes)[number]
