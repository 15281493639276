import type {
  MembershipRecord,
  MembershipRecordMap
} from '@libs/data-access/entities'
import { DropdownMenu, Anchor } from '@libs/ui/ds'
import { FAP_HOMEPAGE_URL } from '@libs/utils/environments'
import { useAppNavBarTranslation } from '@libs/utils/translations'
import { routes } from '@spa/core/router'
import { useMemoSelector } from '@spa/redux/hooks'
import { useClearAuth } from '@spa/scenes/Auth/shared/services'
import { defaultMembershipTab } from '@spa/scenes/MembershipPage/defaultMembershipTab'
import type { FC } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useAccountFeatureFlags } from 'snap-redux/modules/accounts/hooks'
import { getAccounts } from 'snap-redux/modules/accounts/selectors'
import { useRoleRights } from 'snap-redux/modules/memberships/utils'

import { SubscriptionOption } from './SubscriptionOption'
import { UserMenuAccountOption } from './UserMenuAccountOption'

type Props = {
  currentUserMemberships: MembershipRecordMap
  currentMembership: MembershipRecord
}
export const UserMenuDropdownContent: FC<Props> = ({
  currentUserMemberships,
  currentMembership
}) => {
  const { atLeast } = useRoleRights()
  const navigate = useNavigate()
  const clearAuth = useClearAuth()
  const { t } = useAppNavBarTranslation()
  const accounts = useMemoSelector(getAccounts)
  const sortedActiveMemberships = currentUserMemberships
    .filter(membership => membership?.active || false)
    .sort((a, b) => {
      const nameA = accounts.get(a.account_id)?.get('name')
      const nameB = accounts.get(b.account_id)?.get('name')
      return nameA?.localeCompare(nameB)
    })

  const { has_fap } = useAccountFeatureFlags()

  return (
    <>
      {has_fap && (
        <>
          <Anchor href={FAP_HOMEPAGE_URL} target='_blank'>
            <DropdownMenu.Option>{t('userMenu.fap')}</DropdownMenu.Option>
          </Anchor>
          <DropdownMenu.Divider />
        </>
      )}
      {atLeast.manager && (
        <DropdownMenu.Option
          data-testid='navbar-timeoffs'
          onClick={() => navigate(routes.timeoffs.rootPath)}
        >
          {t('userMenu.timeoffs')}
        </DropdownMenu.Option>
      )}
      {currentMembership && (
        <DropdownMenu.Option
          data-testid='navbar-member-preferences'
          onClick={() =>
            navigate(routes.memberPreferences.format(currentMembership.id))
          }
        >
          {t('userMenu.preferences')}
        </DropdownMenu.Option>
      )}

      {atLeast.owner && <DropdownMenu.Divider />}

      {atLeast.director && (
        <DropdownMenu.Option
          data-testid='navbar-settings'
          onClick={() => navigate(routes.settings.rootPath)}
        >
          {t('userMenu.settings')}
        </DropdownMenu.Option>
      )}

      {atLeast.owner && <SubscriptionOption />}

      {sortedActiveMemberships.size > 1 && (
        <>
          <DropdownMenu.Divider />
          <DropdownMenu.Group label={t('userMenu.accountList')}>
            {sortedActiveMemberships
              .valueSeq()
              .map((membership, index) => (
                <UserMenuAccountOption
                  key={index}
                  membershipId={membership?.id as number}
                />
              ))
              .toArray()}
          </DropdownMenu.Group>
        </>
      )}

      {currentMembership && (
        <DropdownMenu.Option
          data-testid='navbar-member'
          onClick={() => {
            navigate(
              generatePath(routes.member.rootPath, {
                membership_id: currentMembership.get('id'),
                view: defaultMembershipTab
              })
            )
          }}
        >
          {t('userMenu.profile')}
        </DropdownMenu.Option>
      )}

      <DropdownMenu.Option
        intent='danger'
        onClick={() => {
          clearAuth()
        }}
      >
        {t('userMenu.logout')}
      </DropdownMenu.Option>
    </>
  )
}
