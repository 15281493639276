import type { JobSchema } from '@libs/data-access/entities'
import {
  appQueryClient,
  type NewApiErrorResponse,
  type UseAppMutationOptions
} from '@libs/data-access/queries'
import { useMutation } from '@tanstack/react-query'

import type { PutJobOptions } from '../jobs.api'
import { putJob } from '../jobs.api'
import { jobsCacheKeys } from '../jobs.cacheKeys'

export const usePutJob = (
  mutationOptions?: UseAppMutationOptions<
    JobSchema,
    NewApiErrorResponse,
    PutJobOptions
  >
) => {
  return useMutation<JobSchema, NewApiErrorResponse, PutJobOptions>({
    ...mutationOptions,
    mutationKey: jobsCacheKeys.put_job(),
    mutationFn: (opts: PutJobOptions) => putJob(opts),
    onSuccess: (data, variables, ...args) => {
      mutationOptions?.onSuccess?.(data, variables, ...args)
      appQueryClient.invalidateQueries({
        queryKey: jobsCacheKeys.fetch_jobs_invalidate()
      })
    }
  })
}
