import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_PAGINATED_CONTRACT_EVENTS,
  FETCH_PAGINATED_CONTRACT_EVENTS_SUCCEEDED,
  FETCH_PAGINATED_CONTRACT_EVENTS_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchPaginatedContractEvents = (
  accountId,
  params,
  resolve,
  reject
) => ({
  type: FETCH_PAGINATED_CONTRACT_EVENTS,
  accountId,
  params,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const paginatedContractEventsSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_PAGINATED_CONTRACT_EVENTS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_PAGINATED_CONTRACT_EVENTS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/user_contract_events?${queryString.stringify(
        params
      )}`,
      options
    )
}

export function* doFetchPaginatedContractEvents({
  accountId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    paginatedContractEventsSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const ContractEvent = Immutable.Record({
  id: null,
  user_contract_membership_id: null,
  user_contract_first_name: null,
  user_contract_last_name: null,
  location_id: null,
  event_type: null,
  attribute_type: null,
  original_value: null,
  new_value: null,
  author_id: null,
  author_first_name: null,
  author_last_name: null,
  has_amendment: null,
  created_at: null
})

const contractForeignKeys = ['user_contract_id', 'location_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map(),
  pagination: Immutable.Map()
})

function contractEvents(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_PAGINATED_CONTRACT_EVENTS: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }

    case FETCH_PAGINATED_CONTRACT_EVENTS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }

    case FETCH_PAGINATED_CONTRACT_EVENTS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const contractEvents = Immutable.fromJS(action.data.events)
      // @ts-ignore migration from js(x) to ts(x)
      const pagination = Immutable.fromJS(action.data.meta)
      // @ts-ignore migration from js(x) to ts(x)
      const currentPage = action.data.meta.current_page

      return mergeRecords(
        state,
        ContractEvent,
        contractEvents,
        // @ts-ignore migration from js(x) to ts(x)
        contractForeignKeys
      ).withMutations(map => {
        map.mergeDeepIn(['meta'], {
          loading: false,
          updated_at: Date.now(),
          success: true
        })
        map.mergeDeepIn(['pagination'], pagination)
        map.setIn(
          ['pagination', 'pages', currentPage],
          contractEvents.map(contractEvent => contractEvent.get('id')).toSet()
        )
      })
    }
    default:
      return state
  }
}

export default filterActions(contractEvents, [
  FETCH_PAGINATED_CONTRACT_EVENTS,
  FETCH_PAGINATED_CONTRACT_EVENTS_SUCCEEDED,
  FETCH_PAGINATED_CONTRACT_EVENTS_FAILED
])
