import { isEqual, partition } from 'lodash-es'
import moment from 'moment'
import type {
  Break,
  Shift
} from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import { filterShiftBreaksWithSlot } from '../../utils/filterShiftBreaksWithSlot'

type UpdatedShift = {
  newStart: string
  newEnd: string
}

const isBetween = (date: string, updatedShift: UpdatedShift) => {
  return moment(date).isBetween(
    updatedShift.newStart,
    updatedShift.newEnd,
    'minutes'
  )
}

export const breakIsOutside = (
  shiftBreak: Break,
  updatedShift: UpdatedShift
) => {
  return (
    !isBetween(shiftBreak.real_starts_at, updatedShift) &&
    !isBetween(shiftBreak.real_ends_at, updatedShift)
  )
}

const diffInMinutes = (start: string, end: string) => {
  return moment(end).diff(start, 'minutes', true)
}

const maxDate = (date: string, compareDate: string) => {
  return moment.max([moment(date), moment(compareDate)]).toISOString()
}

const minDate = (date: string, compareDate: string) => {
  return moment.min([moment(date), moment(compareDate)]).toISOString()
}

/**
 * Check if start/end date of a break intersect with the new shift range
 */
const breakIntersect = (
  shiftBreak: Break,
  { newStart, newEnd }: UpdatedShift
) => {
  const newBreak = {
    real_starts_at: maxDate(shiftBreak.real_starts_at, newStart),
    real_ends_at: minDate(shiftBreak.real_ends_at, newEnd)
  }

  const oldBreak = {
    real_starts_at: moment(shiftBreak.real_starts_at).toISOString(),
    real_ends_at: moment(shiftBreak.real_ends_at).toISOString()
  }

  return !isEqual(newBreak, oldBreak)
}

/**
 * Update all slots properties for a given break
 */
export const resizeShiftBreak = (
  shiftBreak: Break,
  { newStart, newEnd }: UpdatedShift
) => {
  const planned_starts_at = maxDate(shiftBreak.planned_starts_at, newStart)
  const planned_ends_at = minDate(shiftBreak.planned_ends_at, newEnd)
  const planned_duration = diffInMinutes(planned_starts_at, planned_ends_at)

  const real_starts_at = maxDate(shiftBreak.real_starts_at, newStart)
  const real_ends_at = minDate(shiftBreak.real_ends_at, newEnd)
  const real_duration = diffInMinutes(real_starts_at, real_ends_at)

  return {
    ...shiftBreak,
    planned_starts_at,
    planned_ends_at,
    planned_duration,
    real_starts_at,
    real_ends_at,
    real_duration
  }
}

/**
 * Adapt breaks with slots given the new timerange of the shift
 * - Some breaks may be destroyed if they are outside of shift timerange
 * - Some breaks should be updated if their timerange overlap the shift timerange
 */
export const adaptShiftBreaksSlotOnResize = (
  updatedShift: UpdatedShift,
  shift: Shift
) => {
  const breaksWithSlot = filterShiftBreaksWithSlot(shift.shift_breaks)

  const [deletedShiftBreaks, otherBreaks] = partition(breaksWithSlot, item =>
    breakIsOutside(item, updatedShift)
  )
  const shiftBreaksToUpdate = otherBreaks.filter(item =>
    breakIntersect(item, updatedShift)
  )
  const updatedShiftBreaks = shiftBreaksToUpdate.map(shiftBreak =>
    resizeShiftBreak(shiftBreak, updatedShift)
  )

  return {
    deletedShiftBreaks,
    updatedShiftBreaks
  }
}
