import { useHover, useMergedRef } from '@libs/ui/ds'
import { forwardRef, memo } from 'react'

import { HoverProvider } from './Hover.context'
import type { HoverProps } from './Hover.types'
import { HoverHovered } from './compound/HoverHovered'
import { HoverIdle } from './compound/HoverIdle'

const BaseHover = memo(
  forwardRef<HTMLDivElement, HoverProps>(
    ({ enabled = true, children, ...other }, forwardRef) => {
      const { hovered, ref } = useHover()
      const innerRef = useMergedRef(ref, forwardRef)

      return (
        <HoverProvider value={{ hovered: enabled ? hovered : false, innerRef }}>
          <div ref={innerRef} {...other}>
            {children}
          </div>
        </HoverProvider>
      )
    }
  )
)

type HoverCompoundType = {
  Idle: typeof HoverIdle
  Hovered: typeof HoverHovered
}

const TypedHover = BaseHover as typeof BaseHover & HoverCompoundType

TypedHover.displayName = 'Hover'
TypedHover.Idle = HoverIdle
TypedHover.Hovered = HoverHovered

export const Hover = TypedHover
