import type { ContractSchema } from '@libs/data-access/entities'
import type { ReduxState } from '@spa/redux/ReduxState'
import { keyBy, mapValues, orderBy, partition } from 'lodash-es'
import moment from 'moment'
import type { Shift } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import { getParams } from '../../../params'
import { getPlannableCardsByDayAndTeam } from '../../../plannables/plannableCards/getPlannableCardsByDayAndTeam/getPlannableCardsByDayAndTeam'
import { teamsSelectors } from '../../teams'
import { getDisplayedTeamContracts } from '../getDisplayedTeamContracts/getDisplayedTeamContracts'

/**
 *
 */
const computeFirstCard = (cards: Shift[]) => {
  const [firstCard] = cards

  return cards.reduce((acc, card) => {
    const isBefore = moment(card.starts_at).isBefore(acc.starts_at)
    return isBefore ? card : acc
  }, firstCard)
}

/**
 *
 */
const filterContractCards = (cards: Shift[], contractId: number) => {
  return cards.filter(card => card.user_contract_id === contractId)
}

/**
 * Return the first shift card of a contract
 */
const computeFirstCardByContract = (
  contracts: ContractSchema[],
  internalCards: Shift[]
) => {
  return contracts.map(contract => {
    const internalContractCards = filterContractCards(
      internalCards,
      contract.id
    )

    const firstInternalCard = computeFirstCard(internalContractCards)

    return {
      firstInternalCard,
      contractId: contract.id
    }
  })
}

/**
 * Given the first shift card of each contract, returns sorted contracts depeding on real_starts_at
 */
const sortContractsByFirstCard = (
  firstCardByContract: {
    contractId: number
    firstInternalCard: Shift
  }[]
) => {
  const [internalCardContracts, remainderCardContracts] = partition(
    firstCardByContract,
    contract => contract.firstInternalCard
  )

  const internalCardContractsOrdered = orderBy(
    internalCardContracts,
    item => moment(item.firstInternalCard.starts_at).toISOString(),
    ['asc']
  )

  return [...internalCardContractsOrdered, ...remainderCardContracts]
}

/**
 * Return list of contract sorted by shift real_starts_at
 */
export const getContractsSortedByShiftStarts = (
  state: ReduxState,
  sortDay: string
) => {
  const { locationId, teamId } = getParams(state)
  const teams = teamsSelectors.getPlanningTeams(state, { locationId, teamId })
  const teamsMap = keyBy(teams, 'id')

  return mapValues(teamsMap, team => {
    const contracts = getDisplayedTeamContracts(state, { teamId: team.id })
    const cards = getPlannableCardsByDayAndTeam(state, {
      teamId: team.id,
      includesRests: false,
      day: sortDay
    }) as Shift[]

    const teamWeeklyscheduleId = team?.weeklyschedules?.[0]

    const internalCards = cards.filter(
      card => card.weeklyschedule_id === teamWeeklyscheduleId
    )

    const firstCardByContract = computeFirstCardByContract(
      contracts,
      internalCards
    )

    const sortedItems = sortContractsByFirstCard(firstCardByContract)

    const sortedContractsIds = sortedItems.map(item => item.contractId)
    return sortedContractsIds
  })
}
