import type { ApiErrorResponse } from '@libs/data-access/queries'
import { blobService } from '@libs/data-access/queries'
import { notifications } from '@libs/ui/ds'
import i18n from '@libs/utils/translations'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import type { ParamsDownloadProofDocument } from '../modules/entities'
import { setMetaLoading, setMetaSuccess, setMetaError } from '../modules/meta'

export default function* downloadDocumentProofSignatureData(
  queryParams: ParamsDownloadProofDocument
) {
  yield put(setMetaLoading())

  try {
    const promise = apiRequest({
      service: 'file',
      route: 'downloadDocumentProofSignature',
      params: { signable_document_id: queryParams.signable_document_id }
    })

    notifications.promise(promise, {
      loading: {
        message: i18n.t<any>(
          'common.notifications:documentSign.download.loading'
        )
      },
      success: {
        message: i18n.t<any>(
          'common.notifications:documentSign.download.success'
        )
      },
      apiError: true
    })

    const result = yield promise

    yield put(setMetaSuccess())
    const url = blobService.createBlobUrl(result)
    if (queryParams.merged) {
      blobService.downloadZIP(url, { filename: queryParams.document_name })
    } else {
      blobService.downloadPDF(url, {
        filename: `${queryParams.document_name}.pdf`
      })
    }
  } catch (err) {
    yield put(setMetaError(err as ApiErrorResponse))
  }
}
