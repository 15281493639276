import { sortBy } from 'lodash-es'
import moment from 'moment'

type StackedItem = {
  id: number
  start: string
  end: string
}

export type StackedItemWithOffset<T extends StackedItem = StackedItem> = T & {
  offsetY: number
}

/**
 * Sort StackedItem by start date
 */
const sortByStartDate = (arr: StackedItem[]) => {
  return sortBy(arr, i => i.start)
}

/**
 * Sort StackedItem by duration if they have the same start date
 * The highest duration come first
 */
const sortByDuration = (arr: StackedItem[]) => {
  return arr.sort((a, b) => {
    if (a.start === b.start) {
      return moment(b.end).diff(a.end)
    }

    return 0
  })
}

/**
 * Check if a card overlaps a other one
 */
const cardOverlaps = (card: StackedItem, prevCard: StackedItem) => {
  return moment(card.start).isBefore(prevCard.end)
}

export const computeStackedItems = <
  T extends StackedItem,
  R extends StackedItemWithOffset<T> = StackedItemWithOffset<T>
>(
  stackedItems: T[]
): R[] => {
  const cardsWithOffsetY = [] as R[]
  const dateSortedStackedItems = sortByStartDate(stackedItems)
  const sortedStackedItems = sortByDuration(dateSortedStackedItems)

  sortedStackedItems.forEach(card => {
    if (!cardsWithOffsetY.length) {
      cardsWithOffsetY.push({ ...card, offsetY: 0 } as R)
      return
    }

    const overlapsPrevCard = [...cardsWithOffsetY]
      .reverse()
      .find(prevCard => cardOverlaps(card, prevCard))

    const offsetY = overlapsPrevCard ? overlapsPrevCard.offsetY + 1 : 0
    return cardsWithOffsetY.push({ ...card, offsetY } as R)
  })

  return cardsWithOffsetY
}
