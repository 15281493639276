export const InformationCircleIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM10 1.63265C5.37884 1.63265 1.63265 5.37884 1.63265 10C1.63265 14.6212 5.37884 18.3673 10 18.3673C14.6212 18.3673 18.3673 14.6212 18.3673 10C18.3673 5.37884 14.6212 1.63265 10 1.63265Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.91429 8.76577C7.91429 8.30461 8.28813 7.93077 8.74929 7.93077H9.59998C10.503 7.93077 11.235 8.66278 11.235 9.56577V13.553C11.235 13.6635 11.3245 13.753 11.435 13.753H12.1C12.5612 13.753 12.935 14.1269 12.935 14.588C12.935 15.0492 12.5612 15.423 12.1 15.423H8.74929C8.28813 15.423 7.91429 15.0492 7.91429 14.588C7.91429 14.1269 8.28813 13.753 8.74929 13.753H9.36498C9.47544 13.753 9.56498 13.6635 9.56498 13.553V9.80077C9.56498 9.69031 9.47544 9.60077 9.36498 9.60077H8.74929C8.28813 9.60077 7.91429 9.22692 7.91429 8.76577Z'
        fill='currentColor'
      />
      <path
        d='M8.97496 5.41609C8.97496 4.73266 9.52735 4.17827 10.21 4.17498C10.211 4.17498 10.212 4.17498 10.213 4.17497C10.8971 4.17662 11.4511 4.73164 11.4511 5.41609C11.4511 6.09953 10.8987 6.65393 10.2161 6.65721C10.2151 6.65721 10.214 6.65721 10.213 6.65721C10.212 6.65721 10.211 6.6572 10.21 6.6572C9.52735 6.65391 8.97496 6.09953 8.97496 5.41609Z'
        fill='currentColor'
      />
    </svg>
  )
}
