import type { ReduxState } from '@spa/redux/ReduxState'
import { createSelector } from 'reselect'
import { getAllWeeklyschedules } from 'snap-redux/modules/weeklyschedules/selectors'

import { getCurrentSelectedTeams } from '../../teams/getCurrentSelectedTeams/getCurrentSelectedTeams'

const getWeeklyschedulesInRange = (weeklyschedules, weekRange, selectedTeams) =>
  weeklyschedules?.filter(({ week, team_id: teamId }) => {
    return weekRange.includes(week) && selectedTeams.includes(teamId)
  })

export const getMonthlyPublishPermissionIfUnpublished = createSelector(
  getAllWeeklyschedules,
  getCurrentSelectedTeams,
  (_: ReduxState, weekRange: string[]) => weekRange,

  (weeklyschedules, selectedTeams, weekRange) => {
    const weeklyschedulesInRange = getWeeklyschedulesInRange(
      weeklyschedules,
      weekRange,
      selectedTeams
    )
    // Check if all weeklyschedules of selected teams are published
    const allWeeklyschedulesArePublished = weeklyschedulesInRange?.every(
      weeklyschedule => weeklyschedule.published === true
    )
    // Check if all weeklyschedules of selected teams have permission to publish
    const canPublish = weeklyschedulesInRange?.some(schedule => {
      return schedule?.permissions?.get('can_publish') === true
    })

    return canPublish && !allWeeklyschedulesArePublished
  }
)
