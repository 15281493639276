import type { AlertsType } from 'snap-redux/infra/http/routesConfig/scopes/planning/types/Alert'
import { ALERT_TYPES } from 'snap-redux/infra/http/routesConfig/scopes/planning/types/Alert'

/**
 * Return displayed alert.types by scope
 */
export const alertTypeScopes = {
  week: [
    ALERT_TYPES.MAX_WEEKLY_WORKTIME,
    ALERT_TYPES.PART_TIME_MAX_WEEKLY_WORKTIME,
    ALERT_TYPES.MAX_CONSECUTIVE_WORK_DAYS
  ] as AlertsType[],
  day: [
    ALERT_TYPES.REST_DURATION_BETWEEN_TWO_DAYS,
    ALERT_TYPES.MAX_WORKTIME_WITHOUT_BREAK,
    ALERT_TYPES.MAX_DAILY_WORKTIME,
    ALERT_TYPES.HOUR_CONFLICT,
    ALERT_TYPES.MAX_DAILY_TIME_RANGE
  ] as AlertsType[]
}
