import type { MembershipNameOnly } from '@libs/data-access/entities'
import { useQuery } from '@tanstack/react-query'

import type { UseAppQueryOptions } from '../../../../types'
import type { FetchMembershipNamesOptions } from '../membership.api'
import { fetchMembershipNames } from '../membership.api'
import { membershipQueryKeys } from '../membership.cacheKeys'

export const useFetchMembershipNames = (
  request: FetchMembershipNamesOptions,
  queryOptions?: UseAppQueryOptions<MembershipNameOnly[]>
) => {
  return useQuery({
    ...queryOptions,
    queryKey: membershipQueryKeys.membershipNames(request?.queryParams),
    queryFn: () => fetchMembershipNames(request)
  })
}
