import { errorLoggerService } from '@libs/utils/error-monitoring'
import { select, put, call } from 'redux-saga/effects'

// Throttle the throttable calls for 60 seconds.
const THROTTLED_DURATION_IN_MS = 60 * 1000

export function* fetchSagaEntity(entity, id, params, body, resolve, reject) {
  const state = yield select(state => state)

  // Allow forcing the fetch regardless of the throttling logic thanks to a the `skipThrottled`
  // flag.
  const skipThrottled = (params && params.skipThrottled) || false

  // Remove the skipThrottled from the params. This shouldn't pose any problem down the line, but
  // let's clean our mess after we've made it.
  if (params) {
    delete params.skipThrottled
  }

  // Fetch the last update from the entity. We do it there because this logic can be different from
  // entity to entity.
  const lastUpdate = entity.fetchLastUpdate && entity.fetchLastUpdate(state)

  // Finally, throttle the call if the entity is marked as throttable, the entity was already fetch
  // at least once and the we are not skipping the throttle.
  if (entity.throttled && lastUpdate && !skipThrottled) {
    const now = new Date()
    // @ts-ignore migration from js(x) to ts(x)
    if (now - lastUpdate < THROTTLED_DURATION_IN_MS) {
      yield put(entity.throttled())
      // Resolve the promise so the action thinks it succeeded.
      if (resolve) {
        yield resolve()
      }
      return
    }
  }

  try {
    const data = yield call(
      entity.fetchAPI,
      id,
      { body: JSON.stringify(body) },
      params
    )

    yield put(entity.success(data, id, params))

    if (resolve) {
      yield resolve(data)
    }
  } catch (error) {
    // Send logs to remote service in prod environment
    // if not 404 not_found or 403 access_not_authorized
    if (![404, 403].includes(error.status)) {
      errorLoggerService.apiError(error)
    }

    yield put(entity.failure(error, id))

    if (reject) {
      yield reject(error)
    }
  }
}
