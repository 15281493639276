import { CheckIcon, DropdownMenu } from '@libs/ui/ds'
import { DOMAIN, HTTP_PROTOCOL } from '@libs/utils/environments'
import { trackerService } from '@spa/core/externals/segment'
import { useMemoSelector } from '@spa/redux/hooks'
import {
  useAuthState,
  useSwitchAuthAccount
} from '@spa/scenes/Auth/shared/services'
import { isImpersonationContext } from '@spa/scenes/Auth/shared/services/authState/authServiceUtils'
import { useCallback } from 'react'
import type { FC } from 'react'
import { getMembershipById } from 'snap-redux/modules/memberships/selectors'

type Props = {
  membershipId: number
}
export const UserMenuAccountOption: FC<Props> = ({ membershipId }) => {
  const { auth } = useAuthState()
  const switchAuthAccount = useSwitchAuthAccount()
  const membership = useMemoSelector(state =>
    getMembershipById(state, membershipId)
  )

  const account = useMemoSelector(state =>
    state.accounts.getIn(['data', membership.account_id])
  )
  const accountName = account?.name
  const isSameAccount = auth.accountId === membership.account_id

  const handleClick = useCallback(() => {
    trackerService.reset()

    if (isImpersonationContext()) {
      window.location.href = `${HTTP_PROTOCOL}://${account.subdomain}.${DOMAIN}`
    }

    switchAuthAccount(membership.account_id)
  }, [membership, account])

  return (
    <DropdownMenu.Option
      disabled={isSameAccount}
      rightIcon={isSameAccount ? <CheckIcon /> : undefined}
      key={membership.id}
      data-testid={`navbar-account-${membership.account_id}`}
      onClick={handleClick}
    >
      {accountName}
    </DropdownMenu.Option>
  )
}
