import { useGetActivities } from './Activities/hooks/useGetActivities'
import { useGetCountryConfiguration } from './CountryConfiguration/hooks/useGetCountryConfiguration'
import { useAccountFlipperFeatureFlags } from './FeatureFlags/hooks/useAccountFlipperFeatureFlags'
import { useFetchFlipperFeatureFlagsByAccount } from './FeatureFlags/hooks/useFetchFeatureFlagsByAccount'
import { useFetchJobs } from './Jobs/hooks/useFetchJobs'
import { useMergeJobs } from './Jobs/hooks/useMergeJobs'
import { usePostJob } from './Jobs/hooks/usePostJob'
import { usePutJob } from './Jobs/hooks/usePutJob'
import { useFetchCurrentAccount } from './hooks/useFetchCurrentAccount'

class AccountService {
  useFetchCurrent = useFetchCurrentAccount

  activities = {
    useGetActivities
  }

  countryConfiguration = {
    useFetch: useGetCountryConfiguration
  }

  featureFlags = {
    useFetchAllForAccount: useFetchFlipperFeatureFlagsByAccount,
    useAll: useAccountFlipperFeatureFlags
  }

  jobs = {
    useFetchJobs,
    usePost: usePostJob,
    useMergeJobs,
    usePut: usePutJob
  }
}

export const accountService = new AccountService()
