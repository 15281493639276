import type { Rest } from '@libs/data-access/entities'
import moment from 'moment'

/**
 * Returns true if ranges overlaps
 */
export const overlapsRange = (
  rest: Rest,
  range: { start: string; end: string }
) => {
  const rangeStart = moment(range.start).startOf('day')
  const rangeEnd = moment(range.end).endOf('day')

  return (
    moment(rest.starts_at).isBetween(rangeStart, rangeEnd) ||
    moment(rest.ends_at).isBetween(rangeStart, rangeEnd) ||
    moment(rangeStart).isBetween(rest.starts_at, rest.ends_at) ||
    moment(rangeEnd).isBetween(rest.starts_at, rest.ends_at)
  )
}
