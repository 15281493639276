import { createSliceSaga, SagaType } from 'redux-toolkit-saga'

import fetchAddonsData from '../generator/fetchAddonsData'

const addonsSagaSlice = createSliceSaga({
  name: 'billing/addons/sagas',
  caseSagas: {
    /**
     * Fetch addon data (additional_users, formulas, stand_alones) from snap billing service. This is for pricing purposes only.
     *
     * This allow the front to retrieve the price and not hard code those values in a const file.
     */
    *fetchAddons() {
      yield fetchAddonsData()
    }
  },
  sagaType: SagaType.TakeLatest
})

export const { fetchAddons } = addonsSagaSlice.actions

export default addonsSagaSlice.saga()
