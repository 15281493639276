import { cleanSearchValue } from '../utils'

/**
 * Simple version of the fuzzy search algorithm.
 *
 * Match:
 * fuzzySearch('jq', ['Jquery']) // true
 * fuzzySearch(' JQ ', ['Jquery']) // true
 * fuzzySearch('jr', ['Jquery']) // false
 * fuzzySearch('juy', ['Jquery']) // false
 * fuzzySearch('jqmy', ['Jquery']) // false
 * fuzzySearch('qjy', ['Jquery']) // false
 */
export function startingWithSearch({
  search,
  options
}: {
  search: string
  options: string[]
}) {
  const cleanPattern = cleanSearchValue(search)
  return options.some(i => cleanSearchValue(i).startsWith(cleanPattern))
}
