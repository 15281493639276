type PaginationRange = (number | 'dots')[]

/**
 * This function prevent to have duplicate keys in the range array
 */
export const safePaginationRange = (range: PaginationRange) => {
  return range.reduce<any>((acc, item) => {
    if (typeof item === 'number') {
      return acc.concat(item)
    }

    const dots = acc.includes('dots:first') ? `${item}:last` : `${item}:first`
    return acc.concat(dots)
  }, []) as PaginationRange
}
