import type {
  NewSubscriptionSchema,
  ParamsSubscription
} from '@libs/data-access/entities'
import { errorLoggerService } from '@libs/utils/error-monitoring'
import {
  setMetaError,
  setMetaSuccess,
  setMetaUpdating
} from '@spa/redux/billing/subscription/modules/meta'
import { put, select } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'
import { getCurrentAccount } from 'snap-redux/modules/accounts/selectors'

import { updateSubscription } from '../modules/entities'

export default function* updateSubscriptionData(
  params: Partial<ParamsSubscription>
) {
  yield put(setMetaUpdating())
  const account = yield select(getCurrentAccount)

  try {
    const result: NewSubscriptionSchema = yield apiRequest({
      service: 'billing',
      route: 'putSubscriptionBilling',
      queryParams: {
        account_id: account.id,
        include: ['location_configurations', 'addons', 'invoices']
      },
      body: params
    })

    yield put(updateSubscription({ ...result }))
    yield put(setMetaSuccess())
  } catch (err) {
    errorLoggerService.apiError(err)
    yield put(setMetaError())
  }
}
