import type { ApiErrorResponse } from '@libs/data-access/queries'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import type { ParamsGetDocumentTemplate } from '../modules/entities'
import { setDocumentTemplate } from '../modules/entities'
import { setMetaLoading, setMetaSuccess, setMetaError } from '../modules/meta'
import { setPagination } from '../modules/pagination'

export default function* fetchDocumentTemplateData(
  queryParams: ParamsGetDocumentTemplate
) {
  yield put(setMetaLoading())

  try {
    const result = yield apiRequest({
      service: 'core',
      route: 'fetchDocumentTemplate',
      queryParams
    })

    yield put(setDocumentTemplate(result.resources))
    yield put(setPagination(result.meta))
    yield put(setMetaSuccess())
  } catch (err) {
    yield put(setMetaError(err as ApiErrorResponse))
  }
}
