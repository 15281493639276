import {
  DPAEContractMissingAttributesArray,
  DPAEUserMissingAttributesArray,
  DPAELocationMissingAttributesArray
} from '@libs/data-access/entities'

const isValueInDPAE = (nameInput: string | null) => {
  if (!nameInput) {
    return false
  }

  return [
    DPAEContractMissingAttributesArray,
    DPAEUserMissingAttributesArray,
    DPAELocationMissingAttributesArray
  ].some(attributes =>
    (attributes as ReadonlyArray<string>).includes(nameInput)
  )
}

export default isValueInDPAE
