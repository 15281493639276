import { defineEntitiesSelectors } from './utils/defineEntitiesSelectors'

export const labelsSelectors = defineEntitiesSelectors({ type: 'labels' })

export const modulationperiodsSelectors = defineEntitiesSelectors({
  type: 'modulationperiods'
})
export const contractCountersSelectors = defineEntitiesSelectors({
  type: 'contractCounters'
})
export const accountsSelectors = defineEntitiesSelectors({ type: 'accounts' })

export * from './alerts'
export * from './contracts'
export * from './locations'
export * from './memberships'
export * from './payPeriods'
export * from './publicHolidays'
export * from './rests'
export * from './rows'
export * from './shifts'
export * from './signableTimesheetsPeriodStatus'
export * from './teams'
export * from './weeklyschedules'
