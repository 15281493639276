import { getPlanningPolicies } from '@spa/scenes/Plannings/redux/selectors'
import { flatMap } from 'lodash-es'
import { select } from 'redux-saga/effects'
import { getCurrentMembershipSelector } from 'snap-redux/modules/memberships/selectors'

import type { EntitiesState } from '../../reducers'

import getAllSortedContractIds from './getAllSortedContractIds'

/**
 * In some case we need to only display the current contract in the planning
 * Note: A membership can have multiple contracts on the same weeks
 */
function* getCurrentUserContractIds(normalizedAggregate: EntitiesState) {
  const currentMembership = yield select(getCurrentMembershipSelector)

  const allContractsIds = flatMap(normalizedAggregate.locations, location =>
    location.teams.flatMap(
      teamId => normalizedAggregate.teams[teamId].user_contracts
    )
  )

  const contractIds = allContractsIds.filter(
    id =>
      normalizedAggregate.contracts[id].membership_id === currentMembership.id
  )

  return contractIds
}

/**
 * Based on a feature flag, returns :
 * - all ordered contract ids
 * - Only current user own contract(s)
 */
function* filterRequestedContractIds(normalizedAggregate: EntitiesState) {
  const policies = yield select(getPlanningPolicies)

  if (!policies.canSeeCoworkerPlanning) {
    return yield getCurrentUserContractIds(normalizedAggregate)
  }

  return getAllSortedContractIds(normalizedAggregate)
}

export default filterRequestedContractIds
