import { useUncontrolled } from '@mantine/hooks'
import type { FC } from 'react'
import { memo, useEffect } from 'react'

import type { PopoverProps } from './Popover.types'
import { PopoverDropdown } from './compound/PopoverDropdown'
import { PopoverTarget } from './compound/PopoverTarget'
import { DefaultPopover } from './subs/DefaultPopover'
import { PopoverWithHoverableDropdown } from './subs/PopoverWithHoverableDropdown'

export const BasePopover: FC<PopoverProps> = memo(
  ({
    defaultOpened,
    opened,
    onChange,
    onOpen,
    onClose,
    closeOnClickOutside = true,
    staysOpenWhenHoveringDropdown = true,
    dropdownPosition = 'bottom-start',
    dropdownHeight = 'm',
    dropdownWidth = 'm',
    trigger = 'click',
    children,
    ...other
  }) => {
    const [uncontrolledValue, setUncontrolledValue] = useUncontrolled({
      value: opened,
      defaultValue: defaultOpened,
      onChange
    })

    useEffect(() => {
      if (uncontrolledValue && onOpen) {
        onOpen()
      }

      if (!uncontrolledValue && onClose) {
        onClose()
      }
    }, [uncontrolledValue])

    const PopoverComponent =
      trigger === 'hover' && staysOpenWhenHoveringDropdown
        ? PopoverWithHoverableDropdown
        : DefaultPopover

    return (
      <PopoverComponent
        uncontrolledValue={uncontrolledValue}
        setUncontrolledValue={setUncontrolledValue}
        dropdownWidth={dropdownWidth}
        dropdownHeight={dropdownHeight}
        position={dropdownPosition}
        closeOnClickOutside={closeOnClickOutside}
        width={dropdownWidth === 'target' ? 'target' : undefined}
        trigger={trigger}
        withinPortal
        {...other}
      >
        {children}
      </PopoverComponent>
    )
  }
)

type CompoundPopoverType = {
  Dropdown: typeof PopoverDropdown
  Target: typeof PopoverTarget
}

const TypedPopover = BasePopover as typeof BasePopover & CompoundPopoverType
TypedPopover.displayName = 'Popover'

TypedPopover.Dropdown = PopoverDropdown
TypedPopover.Target = PopoverTarget
export const Popover = TypedPopover
export type { PopoverProps } from './Popover.types'
