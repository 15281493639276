import { createSafeContext } from '@libs/ui/ds/lib/internals'
import type { Ref } from 'react'

export type HoverContext = {
  hovered?: boolean
  enabled?: boolean
  innerRef?: Ref<HTMLDivElement>
}

export const [HoverProvider, useHoverContext] = createSafeContext<HoverContext>(
  {
    context: 'Hover'
  }
)
