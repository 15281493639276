import { useMutation } from '@tanstack/react-query'

import type { UseAppMutationOptions, ApiErrorResponse } from '../../../../types'
import { invalidateAccountDetailsCache } from '../../AccountDetails/accountDetails.cacheKeys'
import type { DeleteLocationGroupDraftOptions } from '../locationGroupDraft.api'
import { deleteLocationGroupDraft } from '../locationGroupDraft.api'
import { locationGroupDraftCacheKeys } from '../locationGroupDraft.cacheKeys'

export const useDeleteLocationGroupDraft = (
  mutationOptions?: UseAppMutationOptions<
    null,
    ApiErrorResponse,
    DeleteLocationGroupDraftOptions
  >
) => {
  return useMutation({
    ...mutationOptions,
    mutationKey: locationGroupDraftCacheKeys.createOne(),
    mutationFn: (opts: DeleteLocationGroupDraftOptions) =>
      deleteLocationGroupDraft(opts),

    onSuccess: (data, variables, ...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, ...args)
      }

      invalidateAccountDetailsCache()
    }
  })
}
