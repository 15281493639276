import type { ImmutableRecord } from '@libs/data-access/entities'
import type Immutable from 'immutable'

export enum CounterType {
  PUBLIC_HOLIDAY = 'public_holiday',
  COMPENSATORY = 'compensatory'
}

export type CounterSchema = {
  id: number
  user_contract_id: number
  counter_type: CounterType
  balance_in_minutes: number
  created_at: string
  effective_year: string
  additional_info: {
    past_guarantee_days?: number
    guarantee_day_balance?: number
  }
}

export type CounterRecord = ImmutableRecord<CounterSchema>
export type CounterRecordMap = Immutable.Map<number, CounterRecord>
