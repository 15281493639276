import { Combobox } from '@libs/ui/ds/lib/components/overlays/Combobox/base/Combobox'
import { cva } from 'class-variance-authority'
import { groupBy, map } from 'lodash-es'
import type { FC } from 'react'
import React from 'react'

import type { SelectOption } from '../../../base/Select.types'

const selectGroupOptionCVA = cva([], {
  variants: {
    isGroup: {
      true: '',
      false: 'pl-8 !text-neutral-700'
    }
  }
})

type SelectGroupOptionListProps = {
  options: readonly SelectOption[]
  selectedOption: SelectOption
  searchValue: string
  selectValue: string
}

export const SelectGroupOptionList: FC<SelectGroupOptionListProps> = ({
  options,
  selectValue,
  searchValue,
  selectedOption
}) => {
  const groupedOptions = groupBy(options, i => i.group || i.label)

  if (!options.length) {
    return null
  }

  return (
    <>
      {map(groupedOptions, (groupedOption, groupName) => {
        const isGroupSelected = selectedOption?.label === groupName
        const hasOneSubOption = groupedOption.length === 2
        const optionsToDisplay =
          hasOneSubOption && !searchValue ? [groupedOption[0]] : groupedOption

        return (
          <Combobox.Group key={groupName}>
            {optionsToDisplay.map(option => {
              const isOptionSelected = selectValue === option.value
              return (
                <Combobox.Option
                  className={selectGroupOptionCVA({ isGroup: !option.group })}
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                  leftIcon={option.leftIcon}
                  selected={isGroupSelected || isOptionSelected}
                >
                  {option.optionLabel || option.label}
                </Combobox.Option>
              )
            })}
          </Combobox.Group>
        )
      })}
    </>
  )
}
