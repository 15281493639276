import jsc from 'js-cookie'

import type { CookiesNames } from './Cookies.constants'
import { COOKIES_NAMES } from './Cookies.constants'
import { useCookieListener } from './utils/useCookieListener'

type CookieOptions = {
  expires?: number | Date | undefined
  path?: string | undefined
  domain?: string | undefined
  secure?: boolean | undefined
  sameSite?: 'strict' | 'Strict' | 'lax' | 'Lax' | 'none' | 'None' | undefined
  [property: string]: any
}

type CookiesHandler = {
  get: (name: string) => string | undefined
  set: (name: string, value: string, opts?: CookieOptions) => void
  remove: (name: string, opts?: CookieOptions) => void
}

export class CookiesService {
  cookies = COOKIES_NAMES

  cookiesHandler: CookiesHandler

  constructor(opts: { cookieHandler: CookiesHandler }) {
    this.cookiesHandler = {
      get: opts.cookieHandler.get,
      set: opts.cookieHandler.set,
      remove: opts.cookieHandler.remove
    }
  }

  /**
   * Reads the value of the specified cookie by name.
   */
  read(name: CookiesNames) {
    return this.cookiesHandler.get(name)
  }

  /**
   * Sets a cookie with the given name, value, and optional attributes.
   */
  set(name: CookiesNames, value: string, opts?: CookieOptions) {
    return this.cookiesHandler.set(name, value, opts)
  }

  /**
   * Reads the access token from the corresponding cookie.
   */
  readAccessTokenExpiresAt() {
    return this.read(this.cookies.ACCESS_TOKEN_EXPIRES_AT)
  }

  /**
   * Reads the account ID from the corresponding cookie, returning it as a number.
   */
  readAccountId() {
    const accountID = this.read(this.cookies.ACCOUNT_ID)

    return accountID ? Number(accountID) : undefined
  }

  /**
   * Reads the user ID from the corresponding cookie, returning it as a number.
   */
  readUserId() {
    const userId = this.read(this.cookies.USER_ID)

    return userId ? Number(userId) : undefined
  }

  /**
   * Reads the impersonated membership ID from the corresponding cookie, returning it as a number.
   */
  readImpersonatedMembershipId() {
    return Number(this.read(this.cookies.IMPERSONATED_MEMBERSHIP_ID))
  }

  /**
   * Reads the last user id
   */
  readLastSignedInUserId() {
    return this.read(this.cookies.LAST_SIGNED_IN_USER_ID)
  }

  /**
   * Update the last user id
   */
  setLastSignedInUserId(lastUserId: number | string) {
    return this.set(this.cookies.LAST_SIGNED_IN_USER_ID, String(lastUserId))
  }

  /**
   * Remove auth cookies
   */
  removeAuthCookies() {
    this.cookiesHandler.remove(COOKIES_NAMES.USER_ID)
    this.cookiesHandler.remove(COOKIES_NAMES.ACCOUNT_ID)
    this.cookiesHandler.remove(COOKIES_NAMES.IMPERSONATED_MEMBERSHIP_ID)
  }

  readGoogleId() {
    return this.read(COOKIES_NAMES.GOOGLE_ID) || null
  }

  readMarketingValues() {
    return {
      firspage: this.read(COOKIES_NAMES.FIRSTPAGE) || null
    }
  }

  readGoogleUTMValues() {
    return {
      utm_medium: this.read(COOKIES_NAMES.GOOGLE_UTM_MEDIUM) || null,
      utm_source: this.read(COOKIES_NAMES.GOOGLE_UTM_SOURCE) || null,
      utm_campaign: this.read(COOKIES_NAMES.GOOGLE_UTM_CAMPAIGN) || null,
      utm_content: this.read(COOKIES_NAMES.GOOGLE_UTM_CONTENT) || null,
      utm_term: this.read(COOKIES_NAMES.GOOGLE_UTM_TERM) || null
    }
  }

  /**
   * Expose reactive cookie listener
   */
  useCookieListener = useCookieListener
}

export const cookiesService = new CookiesService({
  cookieHandler: {
    get: jsc.get,
    set: jsc.set,
    remove: jsc.remove
  }
})
