import type { BaseComponent } from '@libs/ui/ds/lib/internals'
import { Combobox } from '@mantine/core'
import type { FC, PropsWithChildren } from 'react'

export type ComboboxFooterProps = PropsWithChildren<BaseComponent>

export const ComboboxFooter: FC<ComboboxFooterProps> = ({
  children,
  ...otherProps
}) => {
  return (
    <Combobox.Footer {...otherProps} className='-mx-2 p-3'>
      {children}
    </Combobox.Footer>
  )
}
