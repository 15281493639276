import { SkeletonText } from '@libs/ui/ds/lib/components/feedback/Skeleton/specialized/SkeletonText/base/SkeletonText'
import { Flex } from '@libs/ui/ds/lib/components/primitiveLayouts/Flex/base/Flex'
import { useMediaQueryThreshold } from '@libs/ui/ds/lib/theme'
import type { Header } from '@tanstack/react-table'
import { flexRender } from '@tanstack/react-table'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'

import type { TableProps } from '../../Table.types'
import { tableClasses } from '../tableClasses'

import { TableHeaderIconSort } from './TableHeaderIconSort'

const tableHeaderCellCVA = {
  headerCell: cva([tableClasses.headerCell], {
    variants: {
      canSort: {
        true: 'cursor-pointer hover:bg-neutral-200 active:bg-neutral-300'
      },
      isPinned: {
        true: 'sticky z-[1] bg-neutral-100'
      },
      isPrincipalHeader: {
        true: 'text-center'
      },
      borderRight: {
        true: 'border-r-1 border-neutral-200'
      },
      noBackground: {
        true: '!rounded-none bg-neutral-50'
      },
      cellsPaddingSize: {
        s: tableClasses.smallCell,
        m: tableClasses.cell
      }
    }
  }),
  cellContent: cva(['flex-nowrap items-center gap-2'], {
    variants: {
      align: {
        left: 'text-left',
        center: 'text-center',
        right: 'text-right'
      }
    }
  }),
  headerContent: cva(['w-full overflow-auto truncate whitespace-normal'], {
    variants: {
      borderRight: {
        true: 'border-r-1 border-neutral-200'
      }
    }
  })
}

type Props = {
  loading?: boolean
  header: Header<any, unknown>
  isPrincipalHeader?: boolean
  cellsPaddingSize?: TableProps['cellsPaddingSize']
}

export const TableHeaderCell: FC<Props> = ({
  loading,
  header,
  isPrincipalHeader,
  cellsPaddingSize
}) => {
  const { columnDef } = header.column

  const match = useMediaQueryThreshold({
    mediaQueryTreshold: columnDef.meta?.hideOn
  })

  if (match) {
    return null
  }

  return (
    <th
      key={header.id}
      colSpan={header.colSpan}
      onClick={header.column.getToggleSortingHandler()}
      style={{
        ...(header.column.getIsPinned() === 'left' && {
          left: `${header.column.getStart('left')}px`
        }),
        ...(header.column.getIsPinned() &&
          header.column.getIsPinned() === 'right' && {
            right: `${header.column.getAfter('right')}px`
          }),
        ...(header.column.getSize() && {
          width: `${header.column.getSize()}px`
        })
      }}
      className={tableHeaderCellCVA.headerCell({
        cellsPaddingSize,
        className: columnDef.meta?.headerCellClassName,
        borderRight: columnDef.meta?.borderRight,
        noBackground: columnDef.meta?.noBackground,
        canSort: header.column.getCanSort(),
        isPinned: Boolean(header.column.getIsPinned()),
        isPrincipalHeader
      })}
    >
      <Flex
        row
        className={tableHeaderCellCVA.cellContent({
          align: columnDef.meta?.align
        })}
      >
        {loading && <SkeletonText size='xs' className='pr-8' />}
        {!loading && (
          <>
            <div
              className={tableHeaderCellCVA.headerContent({
                className: columnDef.meta?.headerClassName
              })}
            >
              {header.isPlaceholder
                ? null
                : flexRender(columnDef.header, header.getContext())}
            </div>
            {header.column.getCanSort() && (
              <TableHeaderIconSort
                sortedDirection={header.column.getIsSorted()}
              />
            )}
          </>
        )}
      </Flex>
    </th>
  )
}
