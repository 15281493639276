import { useAtom } from 'jotai'

import { atomWithLocalStorage } from '../../../../services'

export type ComboPayDismissPromotion = {
  [membershipId: number]: boolean
}

const dismissAtom = atomWithLocalStorage<ComboPayDismissPromotion>(
  'combopay-dismiss-promotion',
  {}
)

export const useComboPayDismissPromotion = () => {
  const [data, setData] = useAtom(dismissAtom)
  const dismissOne = (membershipId: number) => {
    setData({
      ...data,
      [membershipId]: true
    })
  }

  return {
    data,
    dismissOne
  }
}
