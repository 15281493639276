import { createSliceSaga, SagaType } from 'redux-toolkit-saga'

import fetchBillingPlan from '../generator/fetchBillingPlan'

const planSagaSlice = createSliceSaga({
  name: 'billing/plan/sagas',
  caseSagas: {
    /**
     * Fetch plan from snap billing service. This is for pricing purposes only.
     *
     * This allow the front to retrieve the price of formulas based on the type of subscription they have took.
     */
    *fetchBillingPlans() {
      yield fetchBillingPlan()
    }
  },
  sagaType: SagaType.TakeLatest
})

export const { fetchBillingPlans } = planSagaSlice.actions

export default planSagaSlice.saga()
