import type { CurrencyFormatterParams } from '../../types'

/**
 * Convert number to Currency
 * Let Intl do the job since it's more reliable
 *
 * There should always have 2 numbers after the comma if the number is a float
 * If the number is an integer, there should be no comma
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 */
export const isoCurrency = {
  format: ({ value, lng, args }: CurrencyFormatterParams<number | string>) => {
    const valueAsNumber = parseFloat(value as any)

    const isoCurrency = new Intl.NumberFormat(lng, {
      style: 'currency',
      currency: args.formatParams.currency || 'EUR',
      minimumFractionDigits: valueAsNumber % 1 === 0 ? 0 : 2,
      maximumFractionDigits: 2
    }).format(valueAsNumber)

    return isoCurrency
  }
}
