import type { PaymentUpdateParams } from '@libs/data-access/entities'

import updateLocationBillingData from '../../locations/generator/updateLocationBillingData'
import { getAddonObject } from '../utils'

import updateSubscriptionData from './updateSubscriptionData'

export default function* globalSubscriptionUpdate(
  payload: Partial<PaymentUpdateParams>
) {
  const {
    subscriptionData,
    locationsData,
    subscriptionPlan,
    addons,
    callback
  } = payload

  if (locationsData) {
    yield updateLocationBillingData(locationsData)
  }

  yield updateSubscriptionData({
    ...subscriptionData,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    ...(addons && { addons: getAddonObject(subscriptionPlan, addons) })
  })
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  return callback()
}
