import { ConditionalWrap } from '@libs/ui/ds'
import { groupBy, map } from 'lodash-es'
import type { FC } from 'react'

import type { NativeSelectProps } from '../NativeSelect.types'

type NativeSelectOptionListProps = {
  options: NativeSelectProps['options']
}

export const NativeSelectOptions: FC<NativeSelectOptionListProps> = ({
  options
}) => {
  const groupedOptions = groupBy(options, i => i.group || null)

  if (!options.length) {
    return null
  }

  return (
    <>
      {map(groupedOptions, (group, groupName) => (
        <ConditionalWrap
          key={groupName}
          condition={Boolean(groupName) && groupName !== 'null'}
          wrap={children => <optgroup label={groupName}>{children}</optgroup>}
        >
          <>
            {group.map(option => (
              <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
                data-testid={option['data-testid'] || `option-${option.value}`}
              >
                {option.label}
              </option>
            ))}
          </>
        </ConditionalWrap>
      ))}
    </>
  )
}
