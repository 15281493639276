import type {
  CalendarFeedPlanning,
  UpdateCalendarFeedIntegrationPayload
} from '@libs/data-access/entities'

import { httpServices } from '../../../apis/http/httpServices'
import type { QueryFnParams } from '../../../types'

/**
 *
 */
export const fetchCalendarFeed = async () => {
  const response = await httpServices.core.get<CalendarFeedPlanning>(
    '/planning/calendar_feeds/fetch'
  )
  return response.data
}

export type UpdateCalendarFeedIntegrationOptions = QueryFnParams<{
  payload: UpdateCalendarFeedIntegrationPayload
}>
/**
 *
 */
export const updateCalendarFeedIntegration = async (
  opts?: UpdateCalendarFeedIntegrationOptions
) => {
  const response = await httpServices.core.put<CalendarFeedPlanning>(
    '/planning/calendar_feeds/toggle_integration',
    opts?.payload
  )
  return response.data
}
