import queryString from 'query-string'

import type { SearchParams } from '../appRoutesConfig/utils.types'

/**
 * Transform object of searchParams to valid search string
 *
 * E.g.
 * input = {searchParams: { hello: '1', yo: 'a' }}
 * output = 'hello=1&yo=a'
 *
 * If [currentSearchParams] is provided we merge both params
 */
export const formatSearchParams = ({
  searchParams,
  currentSearchParams = {}
}: {
  currentSearchParams?: SearchParams
  searchParams: SearchParams
}): string | undefined => {
  const params = { ...currentSearchParams, ...searchParams }
  return queryString.stringify(params, { arrayFormat: 'comma' })
}
