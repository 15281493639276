import { Button } from '@libs/ui/ds/lib/components/buttons/Button/base/Button'
import type { BaseComponent } from '@libs/ui/ds/lib/internals'
import { cva } from 'class-variance-authority'
import type { FC, HTMLAttributes, ReactNode } from 'react'
import React from 'react'

import { Flex } from '../../../../primitiveLayouts/Flex/base/Flex'
import { Text } from '../../../../typography/Text/base/Text'

const heroCardCTACVA = {
  root: cva('gap-4 text-m font-bold text-neutral-900'),
  inner: cva('gap-2'),
  button: cva('max-w-32')
}

export type HeroCardCTAProps = BaseComponent &
  HTMLAttributes<HTMLDivElement> & {
    /**
     * Handler for the CTA click event
     */
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    /**
     * Label for the CTA button
     */
    label?: string
    /**
     * Description for the CTA
     */
    desc?: ReactNode
    /**
     * Handler for the dismiss event
     */
    onDismiss?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    /**
     * Label for the dismiss button
     */
    dismissLabel?: string
  }

export const HeroCardCTA: FC<HeroCardCTAProps> = ({
  onClick,
  onDismiss,
  dismissLabel,
  label,
  desc,
  className,
  ...other
}) => {
  return (
    <Flex className={heroCardCTACVA.root({ className })} {...other}>
      <Text>{desc}</Text>
      <Flex row className={heroCardCTACVA.inner()}>
        {onClick && label && (
          <Button
            className={heroCardCTACVA.button()}
            onClick={onClick}
            size='l'
            intent='neutral-dark'
            variant='primary'
          >
            {label}
          </Button>
        )}

        {onDismiss && (
          <Button
            onClick={onDismiss}
            variant='tertiary'
            intent='neutral-dark'
            size='l'
          >
            {dismissLabel}
          </Button>
        )}
      </Flex>
    </Flex>
  )
}
