import type { DeleteRoute, GetRoute, PatchRoute } from '../../types'

import type { PaidLeavesConfigurationsSchema } from './schemas'

export const paidLeavesConfigurationsRoutes = {
  getPaidLeavesConfigurations: {
    method: 'GET',
    path: '/paid_leave/locations/:location_id/configuration'
  },
  patchPaidLeavesConfigurations: {
    method: 'PATCH',
    path: '/paid_leave/locations/:location_id/configuration'
  },
  deletePaidLeavesConfigurations: {
    method: 'DELETE',
    path: '/paid_leave_configurations/:id'
  }
} as const

export type PaidLeavesConfigurationsRoutes = {
  getPaidLeavesConfigurations: GetRoute<
    ['location_id'],
    {},
    PaidLeavesConfigurationsSchema
  >
  patchPaidLeavesConfigurations: PatchRoute<
    ['location_id'],
    {},
    PaidLeavesConfigurationsSchema
  >
  deletePaidLeavesConfigurations: DeleteRoute<
    ['id'],
    {},
    PaidLeavesConfigurationsSchema
  >
}
