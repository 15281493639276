import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Anchor, Button, Flex } from '@libs/ui/ds'
import { BaseForm, Controller } from '@libs/ui/form'
import { useOnboardingTranslation } from '@libs/utils/translations'
import { trackLoginCompleted } from '@spa/core/externals/segment/methods/track/events'
import { routes } from '@spa/core/router'
import { useEffect, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useAtomEmailLogIn } from '../shared/atoms/atomEmailLogIn'
import { useSignInWithPassword } from '../shared/services/authState/hooks/useSignInWithPassword'

import { useSigninAvailabilityValidation } from './SigninAvailabilityForm.validations'

type SignInPayload = {
  email: string
  password: string
}

type SignInFormProps = {
  defaultEmail?: string
}

export const SignInForm = ({ defaultEmail }: SignInFormProps) => {
  const { t, tCommon } = useOnboardingTranslation()
  const [_, setAtomText] = useAtomEmailLogIn()
  const signInWithPasswordMutation = useSignInWithPassword()
  const validationSchema = useSigninAvailabilityValidation()
  const [displayIssue, setDisplayIssue] = useState(false)

  const methods = useForm<SignInPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: defaultEmail
    }
  })
  const { isSubmitting, errors } = methods.formState

  const onSubmit: SubmitHandler<SignInPayload> = async data => {
    await signInWithPasswordMutation.process(
      {
        password: data.password,
        email: data.email
      },
      {
        onSuccess: onSuccessData => {
          trackLoginCompleted({
            userId: String(onSuccessData.user_id),
            email: onSuccessData.info.email,
            account_ids: onSuccessData.info.account_ids,
            membership_ids: onSuccessData.info.membership_ids,
            super_user: onSuccessData.info.super_user
          })

          setAtomText('')
        },
        onError: () => {
          setAtomText(data.email)
        }
      }
    )
  }

  useEffect(() => {
    if (signInWithPasswordMutation?.error) {
      setDisplayIssue(true)
    }
  }, [signInWithPasswordMutation])

  const shouldFocusEmail = Boolean(defaultEmail)

  return (
    <BaseForm methods={methods} onSubmit={onSubmit} className='w-full'>
      <Flex className='gap-6'>
        <Flex className='gap-3'>
          {displayIssue && (
            <Alert
              intent='semantic.red'
              title={t('onboarding.signin.index.errors.wrongTitle')}
            >
              <Trans
                t={t}
                i18nKey='onboarding.signin.index.errors.wrongText'
                components={{
                  1: (
                    <Anchor
                      component={Link}
                      to={routes.forgottenPassword.rootPath}
                      variant='primary'
                      className='text-s'
                    />
                  )
                }}
              />
            </Alert>
          )}
          <Controller.TextInput
            error={Boolean(displayIssue || errors?.email?.message)}
            autoComplete='username'
            errorMessage={errors?.email?.message}
            data-testid='signin-email-input'
            name='email'
            control={methods.control}
            label={tCommon('userInfo.email')}
            type='email'
          />

          <Controller.PasswordInput
            error={Boolean(displayIssue || errors?.password?.message)}
            autoComplete='current-password'
            errorMessage={errors?.password?.message}
            data-testid='signin-password-input'
            name='password'
            control={methods.control}
            label={tCommon('userInfo.password')}
            autoFocus={shouldFocusEmail}
          />
          <Anchor
            component={Link}
            to={routes.forgottenPassword.rootPath}
            variant='primary'
            className='text-right text-s'
          >
            {t('onboarding.common.link.pwdForgotten')}
          </Anchor>
        </Flex>
        <Flex className='gap-4'>
          <Button
            data-testid='signin-submit-button'
            loading={isSubmitting}
            type='submit'
          >
            {t('onboarding.common.cta.signIn')}
          </Button>
        </Flex>
      </Flex>
    </BaseForm>
  )
}
