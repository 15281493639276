import type { ReduxState } from '@spa/redux/ReduxState'
import { mapValues } from 'lodash-es'
import createCachedSelector from 're-reselect'
import type { Shift } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import { HALF_HOUR_IN_MINUTES, ONE_HOUR_IN_MINUTES } from '../constants'
import {
  getHalfHoursIntervalMappedByDay,
  getHoursIntervalMappedByDay
} from '../getDatesIntervalMappedByDay'
import type { IsoHoursDaysDistributions } from '../getDatesIntervalMappedByDay/getDatesIntervalMappedByDay'

import { getCountableShiftsByTeam } from './getCountableShiftsByTeam'
import { computeShiftsDecimalHeadcounts } from './utils/computeShiftsDecimalHeadcounts'

type Params = {
  teamId: number
}

const cacheKeySelector = (_: ReduxState, { teamId }: Params) => teamId

/**
 * Compute and aggregate shifts headcounts for each range interval
 */
const computeCounts = (
  shifts: Shift[],
  hoursIntervalByDay: IsoHoursDaysDistributions,
  interval: number
) => {
  const countsMap = computeShiftsDecimalHeadcounts(shifts, interval)
  const countsByDay = mapValues(hoursIntervalByDay, isoHours =>
    isoHours.map(isoHour => {
      const count = countsMap[isoHour] || 0
      return { count, isoHour }
    })
  )

  return countsByDay
}

/**
 * Get headcounts mapped by days for interval of [1 hour]
 */
export const getHoursHeadcountsMappedByDay = createCachedSelector(
  getCountableShiftsByTeam,
  getHoursIntervalMappedByDay,
  () => ONE_HOUR_IN_MINUTES,
  computeCounts
)(cacheKeySelector)

/**
 * Get headcounts mapped by days for interval of [half an hour]
 */
export const getHalfHoursHeadcountsMappedByDay = createCachedSelector(
  getCountableShiftsByTeam,
  getHalfHoursIntervalMappedByDay,
  () => HALF_HOUR_IN_MINUTES,
  computeCounts
)(cacheKeySelector)
