import type { ContractSchema } from '@libs/data-access/entities'
import type { ReduxState } from '@spa/redux/ReduxState'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'
import type {
  Shift,
  Weeklyschedule
} from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import {
  shiftsSelectors,
  contractsSelectors,
  weeklyschedulesSelectors
} from '../../entities'
import { getParams } from '../../params'

import { filterShiftsByContracts } from './utils/filterShiftsByContracts'
import { filterShiftsByWeeklyschedule } from './utils/filterShiftsByWeeklyschedule'

type Params = {
  teamId: number
}

/**
 * Get weeklyschedule by teamId and date
 */
const getWeeklyschedule = (state: ReduxState, { teamId }: Params) => {
  const { date } = getParams(state)
  return weeklyschedulesSelectors.getWeeklyscheduleFromTeamId(state, {
    teamId,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    date
  })
}

/**
 * Filter shifts that can be counted as headcount
 */
const filterCountableShifts = (
  weeklyschedule: Weeklyschedule,
  teamContracts: ContractSchema[],
  shifts: Shift[]
) => {
  const contractIds = teamContracts.map(contract => Number(contract.id))
  const filteredByWS = filterShiftsByWeeklyschedule(shifts, weeklyschedule.id)
  const filteredByContracts = filterShiftsByContracts(filteredByWS, contractIds)

  return filteredByContracts
}

/**
 * Return shifts that are countable to compute team headcounts by hour
 */
export const getCountableShiftsByTeam = createCachedSelector(
  getWeeklyschedule,
  contractsSelectors.getDisplayedTeamContracts,
  shiftsSelectors.getFreshList,
  filterCountableShifts
)((_, { teamId }: Params) => fallbackToDefaultCacheKey(teamId))
