export const SSRContractMissingAttributesArray = [
  'contract_type',
  'contract_start',
  'contract_end',
  'job',
  'layoff_authorization_date',
  'tutor',
  'work_permit_type',
  'work_permit_number',
  'work_permit_start_date',
  'work_permit_authorization_date',
  'work_permit_expiration_date',
  'work_permit_authorization_request_date',
  'echelon_level',
  'echelon_number',
  'temp_agency_address',
  'temp_agency_zip',
  'temp_agency_city',
  'temp_agency_name'
] as const

export type SSRContractMissingAttributes =
  (typeof SSRContractMissingAttributesArray)[number]

export const DPAEContractMissingAttributesArray = [
  'contract_start',
  'contract_type',
  'contract_end',
  'start_time'
] as const

export type DPAEContractMissingAttributes =
  (typeof DPAEContractMissingAttributesArray)[number]

export const SSRUserMissingAttributesArray = [
  'firstname',
  'birth_name',
  'gender',
  'birth_date',
  'citizenship'
] as const

export type SSRUserMissingAttributes =
  (typeof SSRUserMissingAttributesArray)[number]

export const DPAEUserMissingAttributesArray = [
  'birth_date',
  'birth_department_code',
  'birth_place',
  'firstname',
  'gender',
  'birth_name',
  'social_security_number',
  'zip'
] as const

export type DPAEUserMissingAttributes =
  (typeof DPAEUserMissingAttributesArray)[number]

export const DPAELocationMissingAttributesArray = [
  'address',
  'ape_code',
  'city',
  'name',
  'occupational_medicine_code',
  'siret',
  'zipcode'
] as const

export type DPAELocationMissingAttributes =
  (typeof DPAELocationMissingAttributesArray)[number]

export const SSRTutorLocationMissingAttributesArray = [
  'address',
  'city',
  'zipcode'
] as const

export type SSRTutorLocationMissingAttributes =
  (typeof SSRTutorLocationMissingAttributesArray)[number]

export const SSRTutorMissingAttributesArray = [
  'firstname',
  'birth_name'
] as const

export type SSRTutorMissingAttributes =
  (typeof SSRTutorMissingAttributesArray)[number]

export const SSRFormattedTutorMissingAttributesArray = [
  'tutor_firstname',
  'tutor_birth_name'
] as const

export type SSRFormattedTutorMissingAttributes =
  (typeof SSRFormattedTutorMissingAttributesArray)[number]

export const TUTOR_PREFIX = 'tutor_'

export type SSRMissingAttributes = {
  contract: SSRContractMissingAttributes[]
  user: SSRUserMissingAttributes[]
  tutor: SSRTutorMissingAttributes[]
  location: SSRTutorLocationMissingAttributes[]
}

export type DPAEMissingAttributes = {
  contract: DPAEContractMissingAttributes[]
  location: DPAELocationMissingAttributes[]
  user: DPAEUserMissingAttributes[]
}

export type MissingInformationSchema = {
  membership_id: number
  contract_id: number
  team_id: number
  location_id: number
  firstname: string
  lastname: string
  contract_start: string
  avatar_urls: {
    medium: string
    thumb: string
    small: string
  }
  ssr_missing_attrs: SSRMissingAttributes
  dpae_missing_attrs: DPAEMissingAttributes
}

export type MissingSSRMembershipInformation = {
  userAttr: SSRUserMissingAttributes[]
  contractsAttr: {
    contractId: number
    attrs: Array<
      | SSRContractMissingAttributes
      | SSRTutorLocationMissingAttributes
      | SSRFormattedTutorMissingAttributes
    >
  }[]
}

export type AllMissingAttributes =
  | SSRUserMissingAttributes
  | SSRContractMissingAttributes
  | SSRTutorMissingAttributes
  | SSRTutorLocationMissingAttributes
  | DPAEContractMissingAttributes
  | DPAELocationMissingAttributes
  | DPAEUserMissingAttributes
