import type { Model } from '@libs/data-access/entities'
import { schema } from 'normalizr'
import type { ModulationPeriod as ModulationPeriodSchema } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

export type ModulationPeriodModel = Model<ModulationPeriodSchema, {}>

const ModulationPeriod = new schema.Entity<ModulationPeriodModel>(
  'modulation_periods',
  {}
)

export default ModulationPeriod
