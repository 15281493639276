import type { FetchScheduleRowSchema } from '@libs/data-access/entities'
import { useAppInfiniteQuery } from '@libs/data-access/queries'

import type {
  PaginatedQueryResponse,
  UseAppInfiniteQueryOptions
} from '../../../../types'
import { fetchMonthlyRow, type FetchMonthlyRow } from '../monthly.api'
import { monthlyServiceCacheKeys } from '../monthly.cacheKeys'

export const useFetchRow = (
  request: FetchMonthlyRow,
  queryOptions?: UseAppInfiniteQueryOptions<
    PaginatedQueryResponse<FetchScheduleRowSchema, 'pages'>
  >
) => {
  return useAppInfiniteQuery(
    monthlyServiceCacheKeys.row.all(request.queryParams),
    ({ pageParam }) => fetchMonthlyRow(request, Number(pageParam)),
    queryOptions
  )
}
