import { createSlice } from '@reduxjs/toolkit'

type MetaRow = {
  loading?: boolean
  countersLoading?: boolean
}

export type MetaState = {
  error: boolean
  success: boolean
  loading: boolean
  rows: {
    [key: string]: MetaRow
  }
}

const initialState: MetaState = {
  error: false,
  success: false,
  loading: false,
  rows: {}
}

const paramsSlice = createSlice({
  name: 'ui/planning/meta',
  initialState,
  reducers: {
    setMetaError(state) {
      return {
        ...state,
        error: true,
        success: false,
        loading: false
      }
    },
    setMetaSuccess(state) {
      return {
        ...state,
        error: false,
        success: true,
        loading: false
      }
    },
    setMetaLoading(state) {
      return {
        ...state,
        error: false,
        success: false,
        loading: true
      }
    },

    clearMeta() {
      return { ...initialState }
    }
  }
})

export const { setMetaError, setMetaSuccess, setMetaLoading, clearMeta } =
  paramsSlice.actions
export default paramsSlice.reducer
