import type { ReduxState } from '@spa/redux/ReduxState'

import { getFreshShiftsList } from '../getFreshShiftsList/getFreshShiftsList'

export const getValidShiftsListForBulkActions = (
  state: ReduxState,
  weeklyscheduleId: number
) => {
  const shifts = getFreshShiftsList(state)
  return shifts.filter(shift => shift.weeklyschedule_id === weeklyscheduleId)
}
