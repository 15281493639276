import type { Model } from '@libs/data-access/entities'
import { schema } from 'normalizr'
import type { Label as LabelSchema } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

export type LabelModel = Model<
  LabelSchema,
  {
    teams: number[]
  }
>

const Label = new schema.Entity<LabelModel>('labels', {})

export function create(data: Partial<LabelModel>): LabelModel {
  const original: LabelModel = {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    name: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    color: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    team_id: null,
    teams: [],
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    created_at: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    updated_at: null
  }

  return {
    ...original,
    ...data
  }
}

export default Label
