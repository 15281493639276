import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  UPDATE_TEAM,
  UPDATE_TEAM_SUCCEEDED,
  UPDATE_TEAM_FAILED,
  DELETE_TEAM,
  DELETE_TEAM_SUCCEEDED,
  DELETE_TEAM_FAILED,
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_SUCCEEDED,
  FETCH_LOCATIONS_THROTTLED,
  FETCH_LOCATIONS_FAILED,
  FETCH_LOCATION,
  FETCH_LOCATION_SUCCEEDED,
  FETCH_LOCATION_FAILED,
  CREATE_LOCATION_SUCCEEDED,
  UPDATE_LOCATION_SUCCEEDED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import {
  updateRecord,
  mergeRecords,
  deleteRecord
} from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const updateTeam = (teamId, teamData, resolve, reject) => ({
  type: UPDATE_TEAM,
  teamId,
  teamData,
  resolve,
  reject
})

export const deleteTeam = (teamId, params, resolve, reject) => ({
  type: DELETE_TEAM,
  teamId,
  params,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const updateTeamSagaEntity = {
  success: (data, teamId) => ({ type: UPDATE_TEAM_SUCCEEDED, data, teamId }),
  failure: (error, teamId) => ({ type: UPDATE_TEAM_FAILED, error, teamId }),

  fetchAPI: (teamId, options) =>
    callAPI(`/teams/${teamId}`, {
      method: 'PUT',
      ...options
    })
}

export function* doUpdateTeam({ teamId, teamData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    updateTeamSagaEntity,
    teamId,
    null,
    teamData,
    resolve,
    reject
  )
}

const deleteTeamSagaEntity = {
  success: (data, teamId) => ({ type: DELETE_TEAM_SUCCEEDED, data, teamId }),
  failure: (error, teamId) => ({ type: DELETE_TEAM_FAILED, error, teamId }),

  fetchAPI: (teamId, options, params) =>
    callAPI(`/teams/${teamId}?${queryString.stringify(params)}`, {
      method: 'DELETE',
      ...options
    })
}

export function* doDeleteTeam({ teamId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    deleteTeamSagaEntity,
    teamId,
    params,
    null,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const Team = Immutable.Record({
  id: null,
  name: null,
  location_id: null,
  created_at: null,
  has_shifts: null,
  current_and_future_modulation_location_contracts: null
})

const teamForeignKeys = ['location_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function teams(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_LOCATION:
    case FETCH_LOCATIONS: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_LOCATIONS_THROTTLED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: true })
    }
    case FETCH_LOCATION_FAILED:
    case FETCH_LOCATIONS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_LOCATIONS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const teams = Immutable.fromJS(action.data)
        .map(location => location.get('teams') || Immutable.List())
        .flatten(true)

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Team, teams, teamForeignKeys).mergeDeepIn(
        ['meta'],
        {
          loading: false,
          updated_at: Date.now(),
          success: true
        }
      )
    }
    case FETCH_LOCATION_SUCCEEDED:
    case CREATE_LOCATION_SUCCEEDED:
    case UPDATE_LOCATION_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const teams = Immutable.fromJS(action?.data?.teams || Immutable.Map())

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Team, teams, teamForeignKeys)
    }
    case DELETE_TEAM_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { teamId } = action
      // @ts-ignore migration from js(x) to ts(x)
      return deleteRecord(state, teamId, teamForeignKeys)
    }
    case UPDATE_TEAM_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const team = Immutable.fromJS(action.data)

      return updateRecord(state, Team, team).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    default:
      return state
  }
}

export default filterActions(teams, [
  FETCH_LOCATION,
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_THROTTLED,
  FETCH_LOCATION_FAILED,
  FETCH_LOCATIONS_FAILED,
  FETCH_LOCATIONS_SUCCEEDED,
  FETCH_LOCATION_SUCCEEDED,
  CREATE_LOCATION_SUCCEEDED,
  UPDATE_LOCATION_SUCCEEDED,
  DELETE_TEAM_SUCCEEDED
])
