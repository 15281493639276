import type { Model } from '@libs/data-access/entities'
import { schema } from 'normalizr'
import type { Alert as AlertSchema } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

export type AlertModel = Model<AlertSchema, {}>

const Alert = new schema.Entity<AlertModel>(
  'alerts',
  {},
  { idAttribute: 'user_contract_id' }
)

export default Alert
