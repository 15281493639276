const REGEX_MATCH_WORDS =
  /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g

export const toKebabCase = (str: string) => {
  if (!str) {
    return ''
  }

  const matchRegex = str.match(REGEX_MATCH_WORDS)
  return matchRegex ? matchRegex.map(x => x.toLowerCase()).join('-') : str
}
