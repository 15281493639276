import moment from 'moment'

import type { FormatterParams } from '../../types'

/**
 * A function to display "Few seconds ago", "1 day ago", etc..
 * with a given date
 *
 * Input: Date | string
 * Output: string
 */
export function durationFromNow({
  value
}: FormatterParams<moment.MomentInput>) {
  return moment(value).fromNow()
}

export default {
  format: durationFromNow
}
