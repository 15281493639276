import type { FC } from 'react'
import React from 'react'
import type { Control, FieldErrors } from 'react-hook-form'
import { Controller } from 'react-hook-form'

import { formatErrors } from '../../helpers'

import type { FieldMultiCheckboxProps } from './FieldMultiCheckbox'
import { FieldMultiCheckbox } from './FieldMultiCheckbox'

type ExtendsFieldMultiCheckboxProps = Omit<
  FieldMultiCheckboxProps,
  'error' | 'values' | 'onChange'
>
type ControlledFieldMultiCheckboxProps = {
  inputName: string
  control: Control<any>
  errors: FieldErrors
} & ExtendsFieldMultiCheckboxProps

export const ControlledFieldMultiCheckbox: FC<
  ControlledFieldMultiCheckboxProps
> = ({ control, inputName, errors, ...other }) => (
  <Controller
    name={inputName}
    control={control}
    render={({ field: { onChange, value } }) => (
      <FieldMultiCheckbox
        onChange={onChange}
        values={value}
        {...other}
        error={formatErrors({ inputName, errors })}
      />
    )}
  />
)
