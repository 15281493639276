import { createSliceSaga, SagaType } from 'redux-toolkit-saga'

import * as alertSagas from './alerts/getAlertsChunked'
import * as contractCounterSagas from './contractCounters'
import * as labelSagas from './labels/getLabels'
import * as locationSagas from './locations/getLocations'
import * as membershipSagas from './memberships/getMemberships'
import * as noteSagas from './notes'
import * as payPeriodSagas from './payPeriods/getPayPeriods'
import * as plannableSagas from './plannables'
import * as planningAggregateSaga from './planningAggregate/fetchPlanningAggregate'
import * as publicHolidaySagas from './publicHolidays/getPublicHolidays'
import * as restSagas from './rests'
import * as shiftTemplateSagas from './shiftTemplates/createFromShift'
import * as shiftSagas from './shifts'
import * as signableTimesheetsSagas from './signableTimesheets/fetchSignableTimesheetsPeriodStatus'
import * as weeklyscheduleSagas from './weeklyschedules'

const caseSagas = {
  ...alertSagas,
  ...contractCounterSagas,
  ...labelSagas,
  ...locationSagas,
  ...membershipSagas,
  ...noteSagas,
  ...payPeriodSagas,
  ...plannableSagas,
  ...planningAggregateSaga,
  ...publicHolidaySagas,
  ...restSagas,
  ...shiftSagas,
  ...shiftTemplateSagas,
  ...signableTimesheetsSagas,
  ...weeklyscheduleSagas
}

const entitiesSlice = createSliceSaga({
  name: 'sagas/planning',
  caseSagas,
  sagaType: SagaType.TakeLatest
})

export const entitiesActions = entitiesSlice.actions
export const entitiesSagas = caseSagas

export default entitiesSlice.saga
