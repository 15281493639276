import type { ReduxState } from '@spa/redux/ReduxState'
import { createSelector } from 'reselect'

import { getParams } from '../../../params'
import { getLocationWeeklyschedules } from '../getLocationWeeklyschedules/getLocationWeeklyschedules'
import { getWeeklyscheduleFromTeamId } from '../getWeeklyscheduleFromTeamId/getWeeklyscheduleFromTeamId'

const getWeeklyscheduleByTeamId = (state: ReduxState) => {
  const { date, teamId } = getParams(state)
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  return getWeeklyscheduleFromTeamId(state, { date, teamId })
}

/**
 * Return true if all teams from a locations have their weeklyschedule published
 */
export const getWholeLocationIsPublished = createSelector(
  getParams,
  getLocationWeeklyschedules,
  getWeeklyscheduleByTeamId,

  (params, locationWweeklyschedules, teamWeeklyschedule) => {
    // If only one team is selected we should only return the selected week
    if (params.teamId && teamWeeklyschedule) {
      return !!teamWeeklyschedule?.published
    }

    // Otherwise check for all location weeklyschedules
    return (
      !!locationWweeklyschedules.length &&
      locationWweeklyschedules.every(({ published }) => published === true)
    )
  }
)
