import type { GetRoute } from 'snap-redux/infra/http/routesConfig/types'

export const paidLeaveForecast = {
  getPaidLeaveForecast: {
    method: 'GET',
    path: '/paid_leave/user_contracts/:user_contract_id/forecast'
  }
} as const

export type PaidLeaveForecastRoutes = {
  getPaidLeaveForecast: GetRoute<[], { user_contract_id: number }>
}
