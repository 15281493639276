import { Tooltip } from '@libs/ui/ds'
import { useCommonAppTranslation } from '@libs/utils/translations'
import classnames from 'classnames'
import React from 'react'
import type { ReactNode, FC } from 'react'

import styles from './FieldLabel.module.css'

export type LabelWeight = 'regular' | 'light' | 'bold'
export type LabelAlignment = 'left' | 'right' | 'center'
export type LabelPosition = 'left' | 'right' | 'top' | 'bottom'
export type LabelSize = 'normal' | 'small'
export type LabelColor = 'error' | 'warning' | 'success'

export type FieldLabelProps = {
  alignement?: LabelAlignment
  expand?: boolean
  htmlFor?: string
  keepLabelHeight?: boolean
  label?: ReactNode
  size?: LabelSize
  color?: LabelColor
  noCursor?: boolean
  position?: LabelPosition
  rightComponent?: string | ReactNode
  weight?: LabelWeight
  margin?: boolean
  required?: boolean
  disabled?: boolean
  dataTestId?: string
  spaceBetween?: boolean
  className?: string
}

export const FieldLabel: FC<FieldLabelProps> = ({
  alignement = 'left',
  expand = false,
  htmlFor,
  keepLabelHeight,
  label,
  color,
  noCursor = false,
  position = 'top',
  rightComponent = null,
  size = 'normal',
  weight,
  margin = true,
  required = false,
  disabled = false,
  dataTestId = null,
  spaceBetween = false,
  className = ''
}) => {
  const { t } = useCommonAppTranslation()

  return (
    <label
      className={classnames(
        styles.Label,
        {
          [styles.spaceBetween]: spaceBetween,
          [styles.light]: weight === 'light',
          [styles.regular]: weight === 'regular',
          [styles.small]: size === 'small',
          [styles.noCursor]: noCursor,
          [styles.marginBottom]: margin && position === 'top',
          [styles.marginRight]: margin && position === 'left',
          [styles.marginLeft]: margin && position === 'right',
          [styles.marginTop]: margin && position === 'bottom',
          [styles.expand]: expand,
          [styles.center]: alignement === 'center',
          [styles.right]: alignement === 'right',
          [styles.errorLabel]: color === 'error',
          [styles.warningLabel]: color === 'warning',
          [styles.successLabel]: color === 'success',
          [styles.disabledLabel]: disabled
        },
        className
      )}
      htmlFor={htmlFor}
      data-testid={dataTestId}
    >
      <span>
        {label || (keepLabelHeight ? '\u00A0' : '')}
        {required && (
          <Tooltip position='top' label={t('form.errors.fieldIsRequired')}>
            <span className={styles.required}>*</span>
          </Tooltip>
        )}
      </span>
      <span className={styles.RightLabel}>{rightComponent}</span>
    </label>
  )
}
