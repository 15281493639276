import { chunk } from 'lodash-es'

import { httpServices } from '../../../apis'
import type { ApiErrorResponse, QueryFnParams } from '../../../types'

export type BulkActionOptions = QueryFnParams<{
  payload: { shift_ids: number[] }
}>

export const bulkDeleteShiftsList = async (opts?: BulkActionOptions) => {
  const maxRestPerRequest = 50 // Value provided by the API
  const allShiftIds = opts?.payload?.shift_ids

  const chunkedShiftIds = chunk(allShiftIds, maxRestPerRequest)
  const shiftsPromises = chunkedShiftIds.map(shiftIds =>
    httpServices.core.delete('/shifts/bulk_delete', {
      data: { shift_ids: shiftIds }
    })
  )

  await Promise.all(shiftsPromises)
}

export const bulkValidateShiftsList = async (opts?: BulkActionOptions) => {
  const response = await httpServices.core.post(
    '/planning/shifts/bulk_validate',
    opts?.payload
  )
  return response.data
}

export const bulkInvalidateShiftsList = async (opts?: BulkActionOptions) => {
  const response = await httpServices.core.post(
    '/planning/shifts/bulk_invalidate',
    opts?.payload
  )
  return response.data
}

export type BulkDeleteRestsListOptions = QueryFnParams<{
  payload: { rest_ids: number[] }
}>

export const bulkDeleteRestsList = async (
  opts?: BulkDeleteRestsListOptions
) => {
  const response = await httpServices.core.delete('/rests/bulk_delete', {
    data: opts?.payload
  })
  return response.data
}

/**
 * Delete bulk shifts
 *
 * if [membership_id] is not provided, then the delete will be done for all memberships for a given date
 * if [date] is not provided, then the delete will be done for all days for a given membership
 */
export type DeleteBulkShiftsOptions = QueryFnParams<{
  queryParams: {
    weeklyschedule_id: number
    membership_id?: number
    date?: 'YYYY-MM-DD' | string
  }
}>

export type DeleteBulkShiftsResponse = {
  error: ApiErrorResponse
  data: {
    resources: {
      contract_id: number
      weeklyschedule_id: number
      team_id: number
    }[]
  }
}

export const deleteBulkShifts = async (opts: DeleteBulkShiftsOptions) => {
  const response = await httpServices.core.delete<DeleteBulkShiftsResponse>(
    `/planning/actions/bulk_delete`,
    {
      params: opts?.queryParams
    }
  )
  return response.data
}

/**
 * Invert bulk shifts
 */
export type InvertBulkShiftsOptions = QueryFnParams<{
  payload: {
    source: {
      weeklyschedule_id: number
      membership_id?: number
      date?: 'YYYY-MM-DD' | string
    }
    target: {
      weeklyschedule_id: number
      membership_id?: number
      date?: 'YYYY-MM-DD' | string
    }
  }
}>

export type InvertBulkShiftsResponse = {
  error: ApiErrorResponse
  data: {
    resources: {
      contract_id: number
      weeklyschedule_id: number
      team_id: number
    }[]
  }
}

export const invertBulkShifts = async (opts: InvertBulkShiftsOptions) => {
  const response = await httpServices.core.put<InvertBulkShiftsResponse>(
    `/planning/actions/bulk_invert`,
    opts?.payload
  )
  return response.data
}
