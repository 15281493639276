import { useUncontrolled } from '@libs/ui/ds'
import { useEffect } from 'react'

import type {
  AutocompleteOptions,
  AutocompleteProps
} from '../Autocomplete.types'

type MultiParams = {
  select: {
    value: AutocompleteProps['value']
    defaultValue: AutocompleteProps['defaultValue']
    onChange: AutocompleteProps['onChange']
  }
  isMulti: true
}

type SingleParams = {
  select: {
    value: AutocompleteProps['value']
    defaultValue: AutocompleteProps['defaultValue']
    onChange: AutocompleteProps['onChange']
  }
  isMulti: false
}

type Params = (MultiParams | SingleParams) & {
  search: {
    onSearchChange: AutocompleteProps['onSearchChange']
    searchValue: AutocompleteProps['searchValue']
  }
  options: AutocompleteProps['options']
}

export const useAutocompleteValuesUncontrolled = ({
  select,
  search,
  options,
  isMulti
}: Params) => {
  const mutliDefaultValue = (select.defaultValue as string[]) || []
  const singleDefaultValue = (select.defaultValue as string) || ''

  const [searchValue, setSearchValue] = useUncontrolled({
    defaultValue: '',
    onChange: search.onSearchChange,
    value: search.searchValue
  })
  const [selectValue, setSelectValue] = useUncontrolled({
    onChange: select.onChange as
      | ((value: string | string[], ...payload: any[]) => void)
      | undefined,
    value: select.value,
    defaultValue: isMulti ? mutliDefaultValue : singleDefaultValue
  })
  const [selectedOptions, setSelectedOptions] = useUncontrolled<
    AutocompleteOptions[]
  >({})

  const resetSelectValue = () => {
    setSearchValue('')
    setSelectedOptions(null as any)
    setSelectValue(null as any)
  }

  useEffect(() => {
    if (selectValue === '') {
      setSelectedOptions([])
    }
    if (selectValue) {
      let newOptions: AutocompleteOptions[]

      if (isMulti) {
        newOptions = options.filter(option =>
          (selectValue as string[]).includes(option.value)
        )
      } else {
        const singleOption = options.find(
          option => option.value === selectValue
        )
        newOptions = singleOption ? [singleOption] : []
      }
      setSelectedOptions(newOptions)
    }
    if (selectValue === null) {
      resetSelectValue()
    }
  }, [selectValue])

  return {
    select: {
      selectValue,
      setSelectValue,
      resetSelectValue,
      toggleSelectValue: (value: string) => {
        let updatePayload: string | string[]

        if (isMulti) {
          if (selectValue) {
            const isRemoving = selectValue?.includes(value)

            updatePayload = isRemoving
              ? (selectValue as string[])?.filter(i => i !== value)
              : [...selectValue, value]
          } else {
            updatePayload = [value]
          }
        } else {
          const isRemoving = selectValue === value
          updatePayload = isRemoving ? '' : value
        }

        setSelectValue(updatePayload)
      }
    },
    search: {
      searchValue,
      setSearchValue,
      resetSearchValue: () => setSearchValue('')
    },
    selectedOptions: selectedOptions || []
  }
}
