import type { ReduxState } from '@spa/redux/ReduxState'
import {
  composeCacheKey,
  fallbackToDefaultCacheKey
} from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'
import type {
  Shift,
  Weeklyschedule
} from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import { getDisplayFilterByName } from '../../../displayFilters'
import { weeklyschedulesSelectors, shiftsSelectors } from '../../../entities'
import { getDate } from '../../../params'

type Params = {
  teamId: number
  contractId: number
}

/**
 * Because contracts can be related to different teams we need to use combination
 * of teamId + contractId as the cache key
 */
const cacheKeySelector = (_: ReduxState, { teamId, contractId }: Params) => {
  const cacheKey = composeCacheKey(teamId, contractId)
  return fallbackToDefaultCacheKey(cacheKey)
}

/**
 * Filter displayed shifts cards based on display filters
 */
const filterShiftsCards = (
  weeklyschedule: Weeklyschedule,
  shifts: Shift[],
  hideOthersLocations: boolean
) => {
  const shiftCards = shifts.filter(shift => {
    return !(
      hideOthersLocations && shift.weeklyschedule_id !== weeklyschedule.id
    )
  })

  return shiftCards
}

/**
 * Return displayable shifts cards for a given contract
 */
const getShiftCards = createCachedSelector(
  (state: ReduxState, { teamId }: Params) =>
    weeklyschedulesSelectors.getWeeklyscheduleFromTeamId(state, {
      teamId,
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      date: getDate(state)
    }),

  (state: ReduxState, { contractId }: Params) =>
    shiftsSelectors.getShiftsByContract(state, contractId),

  (state: ReduxState) =>
    getDisplayFilterByName(state, {
      name: 'hideOthersLocations'
    }),

  filterShiftsCards
)(cacheKeySelector)

export default getShiftCards
