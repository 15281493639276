import type {
  MembershipRecord,
  MembershipRecordMap,
  ContractRecordMap
} from '@libs/data-access/entities'
import { ROLE_OWNER, ROLE_MAIN_OWNER } from '@spa/constants'
import type { ReduxState, MetaSchema } from '@spa/redux/ReduxState'
import Immutable from 'immutable'
import { createSelector } from 'reselect'
import { getCurrentUserId } from 'snap-redux/modules/users/selectors'

import { getDataByForeignId } from '../../services/immutableUtils'
import { getCurrentAccountId } from '../accounts/selectors'

export const getIsMembershipsLoading = state =>
  state.memberships.getIn(['meta', 'loading'])

export const getMembershipById = (
  state: ReduxState,
  membershipId: number
): MembershipRecord => state.memberships.getIn(['data', Number(membershipId)])

export const getPlannableMembershipsByIds = (
  state: ReduxState,
  membershipIds: number[]
): MembershipRecord[] => {
  return membershipIds
    .map(membershipId =>
      state.memberships.getIn(['data', Number(membershipId)])
    )
    .filter(membership => membership?.include_owner_in_schedules)
}

export const getMembershipsByAccountId = (
  state: ReduxState,
  accountId: number | string
): MembershipRecordMap =>
  getDataByForeignId(state, 'memberships', 'account_id', accountId) ||
  Immutable.Map()

export const getMembershipsByUserId = (
  state: ReduxState,
  currentUserId?: number
): MembershipRecordMap =>
  getDataByForeignId(state, 'memberships', 'user_id', currentUserId) ||
  Immutable.Map()

export const getCurrentMembershipId = (state: ReduxState): number => {
  const accountId = getCurrentAccountId(state)
  const currentUserId = getCurrentUserId()

  const currentAccountMembershipsIds = state.memberships.getIn([
    'relations',
    'account_id',
    accountId
  ])
  const currentUserMembershipsIds = state.memberships.getIn([
    'relations',
    'user_id',
    currentUserId
  ])

  return (
    currentUserMembershipsIds &&
    currentUserMembershipsIds.intersect(currentAccountMembershipsIds).first()
  )
}

export const getCurrentMembershipSelector = createSelector(
  [
    getCurrentUserId,
    createSelector(
      [state => getCurrentAccountId(state), state => state],
      (accountId, state) => {
        return getMembershipsByAccountId(state, accountId as number)
      }
    )
  ],
  (currentUserId, memberships) => {
    return memberships.find(
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      m => m.user_id === currentUserId
    ) as MembershipRecord
  }
)

export const getMembershipMetaSelector = (
  state
): Immutable.Map<'fetch' | 'meta', MetaSchema> => {
  return state.memberships.get('meta')
}

export const getCurrentOwner = (state: ReduxState): MembershipRecord | null => {
  const accountId = getCurrentAccountId(state)

  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  return getMembershipsByAccountId(state, accountId).find(({ role }) =>
    [ROLE_OWNER, ROLE_MAIN_OWNER].includes(role)
  )
}

export const getCurrentMembershipContracts = (
  state: ReduxState,
  contracts: ContractRecordMap
) => {
  const currentMembership = getCurrentMembershipSelector(state)
  const membershipContracts = contracts.filter(
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    contract => contract.membership_id === currentMembership.id
  )

  return membershipContracts as ContractRecordMap
}

/**
 * Select all plannable memberships
 */
export const getPlannableMemberships = (
  state: ReduxState
): MembershipRecordMap => {
  return state.memberships
    .get('data')
    .filter(membership => membership?.include_owner_in_schedules)
}

/**
 * Given list of contracts, filter the ones that have a plannable membership
 */
export const getContractsByPlannableMembership = (
  state: ReduxState,
  contracts: ContractRecordMap
) => {
  const membershipsList = getPlannableMemberships(state)
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  return contracts.filter(i => membershipsList.has(i.membership_id))
}

/**
 * Check if the current logged membership is the same as the param provided
 */
export const getIsCurrentMembership = (
  state: ReduxState,
  membershipId: number
) => {
  return getCurrentMembershipId(state) === membershipId
}

export const getPagination = (state: ReduxState) =>
  state.memberships.get('pagination')
