import type { ReadableFileSize } from '../file.constants'
import { binaryFactor, binaryUnit } from '../file.constants'

/**
 *
 */
export const convertFileSize = (opts: ReadableFileSize) => {
  if (!opts?.value || !opts?.unit) {
    return undefined
  }
  const index = binaryUnit.indexOf(opts.unit)
  return opts.value * binaryFactor ** index
}
