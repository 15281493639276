import { useUncontrolled } from '@libs/ui/ds'
import type { DatesRangeValue } from '@mantine/dates'
import moment from 'moment'
import { useMemo, useState } from 'react'

import type { DateRangePickerInputProps } from '../../DatePickerInput/specialized/DateRangePickerInput/DateRangePickerInput.types'

import { isDateValid, useRangeErrorMessage } from './formats'
import { monthConfig } from './monthConfig'

export const monthRangeConfig = {
  useSyncDate: ({
    value,
    defaultValue,
    onChange,
    minDate,
    maxDate,
    defaultDate
  }: DateRangePickerInputProps) => {
    const [isDirty, setIsDirty] = useState(false)
    const [controlledValue, setControlledValue] = useUncontrolled({
      value,
      onChange,
      defaultValue
    })

    const [controlledDate, setControlledDate] = useUncontrolled({
      defaultValue: defaultDate || controlledValue?.start
    })

    const handleSetControlledValue = (val: {
      start?: string
      end?: string
    }) => {
      if (!isDirty) {
        setIsDirty(true)
      }
      setControlledValue({ ...controlledValue, ...val })
    }

    const updateControlledStart = newStart => {
      handleSetControlledValue({
        start: newStart,
        end: controlledValue?.end
      })

      if (isDateValid(newStart)) {
        setControlledDate(newStart)
      }
    }

    const updateControlledEnd = newEnd => {
      handleSetControlledValue({
        start: controlledValue?.start,
        end: newEnd
      })

      if (isDateValid(newEnd)) {
        const oneYearSubstracted = moment(newEnd).add(-1, 'year')
        setControlledDate(monthConfig.toStr(oneYearSubstracted))
      }
    }

    const memoValue = useMemo(() => {
      return [
        monthConfig.toDate(controlledValue?.start),
        monthConfig.toDate(controlledValue?.end)
      ] as DatesRangeValue
    }, [controlledValue?.start, controlledValue?.end])

    return {
      strValue: controlledValue,

      onChangeStart: val => {
        updateControlledStart(val)
      },
      onChangeEnd: val => {
        updateControlledEnd(val)
      },
      resetStart: () => handleSetControlledValue({ start: '' }),
      resetEnd: () => handleSetControlledValue({ end: '' }),

      /**
       * Calendar props
       */
      value: memoValue,
      onChange: (args: DatesRangeValue) => {
        const [start, end] = args
        handleSetControlledValue({
          start: monthConfig.toStr(start),
          end: monthConfig.toStr(end)
        })
      },
      date: monthConfig.toDate(controlledDate),
      onDateChange: (val: Date) => setControlledDate(monthConfig.toStr(val)),
      defaultDate: monthConfig.toDate(defaultDate),
      minDate: monthConfig.toDate(minDate),
      maxDate: monthConfig.toDate(maxDate),
      errorMessage: useRangeErrorMessage({
        value: controlledValue,
        isDirty,
        minDate,
        maxDate
      })
    }
  }
}
