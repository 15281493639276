import {
  IS_I18N_DEBUG_ENABLED,
  IS_PHRASE_APP_IN_CONTEXT_ENABLED,
  PHRASE_APP_PROJECT_ID
} from '@libs/utils/environments'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import React from 'react'
import { I18nextProvider, initReactI18next } from 'react-i18next'

import { formatMediator } from './interpolation/formatMediator'
import { localResourcesToBackend } from './resources/localResourcesToBackend'

const i18nAppInstance = i18next.createInstance()

/**
 * Integration of Phrase In-Context Editor
 */
const definePhrase = async () => {
  if (IS_PHRASE_APP_IN_CONTEXT_ENABLED) {
    const { default: PhraseInContextEditorPostProcessor } = await import(
      'i18next-phrase-in-context-editor-post-processor'
    )
    i18nAppInstance.use(
      new PhraseInContextEditorPostProcessor({
        phraseEnabled: true,
        projectId: PHRASE_APP_PROJECT_ID,
        autoLowercase: false
      })
    )
  }
}

/**
 * Initialize i18next,
 * Fallback language, EN and FR
 * Default namespace that will be loaded initially
 */
const init = async () => {
  i18nAppInstance
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(localResourcesToBackend())
    .init({
      debug: IS_I18N_DEBUG_ENABLED,
      fallbackLng: ['en', 'fr'],

      ns: ['common.app', 'common.dates', 'common.notifications'],
      defaultNS: 'common.app',
      keySeparator: '.',

      detection: {
        order: ['localStorage', 'navigator'],
        caches: ['localStorage']
      },

      interpolation: {
        escapeValue: false,
        format: formatMediator
      },

      react: {
        useSuspense: true
      },
      // backend: backend(),
      postProcess: IS_PHRASE_APP_IN_CONTEXT_ENABLED
        ? ['phraseInContextEditor']
        : []
    })
}

definePhrase()
init()

export default i18nAppInstance

export const I18nAppProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  return <I18nextProvider i18n={i18nAppInstance}>{children}</I18nextProvider>
}
