import type { MembershipSchema } from '@libs/data-access/entities'
import type { UseAppQueryOptions } from '@libs/data-access/queries'
import { useQuery } from '@tanstack/react-query'

import type { FetchMembershipOptions } from '../membership.api'
import { fetchMembershipById } from '../membership.api'
import { membershipQueryKeys } from '../membership.cacheKeys'

export const useFetchOne = (
  request: FetchMembershipOptions,
  queryOptions?: UseAppQueryOptions<MembershipSchema>
) =>
  useQuery({
    ...queryOptions,
    queryKey: membershipQueryKeys.membership(request?.pathParams.membership_id),
    queryFn: () => fetchMembershipById(request)
  })
