import type { GetMembershipsResponse } from '@libs/data-access/entities'

import type { GetRoute } from '../../types'

export const membershipRoutes = {
  getMemberships: {
    method: 'GET',
    path: '/accounts/:account_id/memberships'
  }
} as const

export type MembershipRoutes = {
  getMemberships: GetRoute<
    ['account_id'],
    {
      active?: boolean
      contract_location_id?: number
      contract_team_id?: number
    },
    GetMembershipsResponse
  >
}
