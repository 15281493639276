import { actionTypes } from '@spa/scenes/Plannings/Modals/RestModal/redux/modules/main'
import {
  getParams,
  restsSelectors
} from '@spa/scenes/Plannings/redux/selectors'
import { map, uniq, filter } from 'lodash-es'
import { select, takeEvery } from 'redux-saga/effects'
import {
  CREATE_REST_SUCCEEDED,
  DELETE_RESTS_FROM_STORE,
  DELETE_REST_SUCCEEDED,
  UPDATE_REST_SUCCEEDED
} from 'snap-redux/actionTypes'

import fetchPlannablesSideEffectsEntities from '../controllers/request/fetchPlannableSideEffectsEntities'

function* sync(action: any) {
  const params = yield select(getParams)

  // @todo register module redux on planning v2 scene
  if (!params.date) {
    return
  }

  switch (action.type) {
    case DELETE_REST_SUCCEEDED: {
      const params = yield select(getParams)
      yield fetchPlannablesSideEffectsEntities({
        contractIds: [action.data.user_contract_id],
        date: params.date,
        countersParams: { live: true }
      })

      break
    }
    case DELETE_RESTS_FROM_STORE: {
      const params = yield select(getParams)
      const contractIds = action.rests.map(i => i.user_contract_id)

      yield fetchPlannablesSideEffectsEntities({
        contractIds,
        date: params.date,
        countersParams: { live: true }
      })

      break
    }
    /**
     * Rest updated from the restModal
     */
    case actionTypes.createRestsSuccess: {
      const params = yield select(getParams)
      const payloadContractIds = map(
        action.payload.successResults,
        'user_contract_id'
      )
      const payloadRestIds = map(action.payload.successResults, 'id')
      const storedRests = yield select(state =>
        restsSelectors.getCachedMap(state)
      )

      /**
       * Retrieve updated contract_ids based on updated rest_ids
       */
      const updatedContractIds = map(
        filter(storedRests, prevRest => payloadRestIds.includes(prevRest.id)),
        'user_contract_id'
      )

      yield fetchPlannablesSideEffectsEntities({
        contractIds: uniq([...payloadContractIds, ...updatedContractIds]),
        date: params.date,
        countersParams: { live: true }
      })

      break
    }

    case UPDATE_REST_SUCCEEDED: {
      const params = yield select(getParams)
      const prevRest = yield select(state =>
        restsSelectors.getCachedById(state, action.data.id)
      )

      yield fetchPlannablesSideEffectsEntities({
        contractIds: uniq([
          action.data.user_contract_id,
          ...(prevRest ? [prevRest.user_contract_id] : [])
        ]),
        date: params.date,
        countersParams: { live: true }
      })

      break
    }

    /**
     * Rest updated from the favoris panel
     */
    case CREATE_REST_SUCCEEDED: {
      const params = yield select(getParams)
      yield fetchPlannablesSideEffectsEntities({
        contractIds: [action.data.user_contract_id],
        date: params.date,
        countersParams: { live: true }
      })

      break
    }

    default:
      break
  }
}

export default function* restWatcher() {
  yield takeEvery(
    [
      DELETE_REST_SUCCEEDED,
      DELETE_RESTS_FROM_STORE,
      actionTypes.createRestsSuccess,
      UPDATE_REST_SUCCEEDED,
      CREATE_REST_SUCCEEDED
    ],
    sync
  )
}
