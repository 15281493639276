import type { FC } from 'react'

import { Combobox } from '../../../Combobox/base/Combobox'
import { useComboboxContext } from '../../../Combobox/base/Combobox.context'
import type { DropdownMenuTargetProps } from '../DropdownMenu.types'

export const DropdownMenuTarget: FC<DropdownMenuTargetProps> = ({
  children,
  className,
  ...other
}) => {
  const ctx = useComboboxContext()
  return (
    <Combobox.Target {...other}>
      <div
        className={className}
        onClick={() => {
          if (ctx?.store) {
            ctx.store.toggleDropdown()
          }
        }}
      >
        {children}
      </div>
    </Combobox.Target>
  )
}
