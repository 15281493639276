import React from 'react'

type SpinnerSvgProps = {
  className?: string
}

export const SpinnerSvg: React.FC<SpinnerSvgProps> = ({ className }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 48 48'
      fill='none'
      className={className}
    >
      <circle cx='24' cy='24' r='20' strokeWidth='8' />
      <path
        d='M12.2443 7.81966C15.2283 5.65164 18.7537 4.35105 22.4308 4.06165C26.1079 3.77226 29.7934 4.50535 33.0798 6.17987C36.3662 7.85439 39.1256 10.4051 41.0528 13.55C42.98 16.6949 44 20.3116 44 24'
        strokeWidth='8'
        strokeLinecap='round'
      />
    </svg>
  )
}
