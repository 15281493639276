import moment from 'moment'

export const setMinimumDate = (date: string) => {
  const momentDate = moment(date)
  momentDate.year(1970)
  momentDate.month(0)
  momentDate.date(1)

  return momentDate.toISOString()
}

export const addOneDayIfBefore = (
  endDate: string,
  { start }: { start: string }
) => {
  const momentStart = moment(start)
  const isBefore = momentStart.isBefore(endDate)

  return isBefore ? endDate : moment(endDate).date(2).toISOString()
}
