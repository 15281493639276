import bugsnagReact from '@bugsnag/plugin-react'
import type { ReactElement, FC } from 'react'
import React from 'react'

import { errorMonitoringService } from '../errorMonitoringService'

// @TODO: will be remove with version v7 of bugsnag
if (errorMonitoringService.client) {
  errorMonitoringService.client.use(bugsnagReact, React)
}

/**
 * see https://docs.bugsnag.com/platforms/javascript/react/#capturing-react-render-errors
 */
export const BugsnagErrorBoundary = errorMonitoringService.client
  ? errorMonitoringService.client.getPlugin('react')
  : ((({ children }: any) => children as ReactElement) as FC)
