import { flatMap, map, sortBy, uniq } from 'lodash-es'

import type { TeamModel } from '../../models/Team'
import type { EntitiesState } from '../../reducers'

/**
 * Return team contracts sorted by membership sort_index
 */
const getSortedContractsFromTeam = (
  team: TeamModel,
  planningAggregate: EntitiesState
) => {
  const sortedContracts = sortBy(team.user_contracts, contractId => {
    const contract = planningAggregate.contracts[contractId]
    const membership = planningAggregate.memberships[contract.membership_id]
    return membership?.sort_index[team.id]
  })

  return sortedContracts
}

/**
 * Return flat array of unique contracts ids
 * This contracts ids are ordered by:
 * - Team name
 * - Membership sort_index
 */
function getAllSortedContractIds(planningAggregate: EntitiesState): number[] {
  const contractIdsFlatArray = flatMap(
    planningAggregate.locations,
    location => {
      const teamsArr = map(location.teams, id => planningAggregate.teams[id])
      const sortedTeams = sortBy(teamsArr, 'name')
      return sortedTeams.flatMap(team =>
        getSortedContractsFromTeam(team, planningAggregate)
      )
    }
  )

  return uniq(contractIdsFlatArray)
}

export default getAllSortedContractIds
