import Immutable from 'immutable'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_MODULATION_PERIOD_COUNTER_SUCCEEDED,
  FETCH_MODULATION_PERIOD_COUNTERS_SUCCEEDED,
  FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION_SUCCEEDED,
  UPDATE_MODULATION_PERIOD_COUNTER_SUCCEEDED,
  DELETE_MODULATION_PERIOD_COUNTER_ENTRY,
  DELETE_MODULATION_PERIOD_COUNTER_ENTRY_SUCCEDED,
  DELETE_MODULATION_PERIOD_COUNTER_ENTRY_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords, deleteRecord } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Action
// ------------------------------------

export const deleteModulationPeriodCountersEntry = (
  entryId,
  resolve,
  reject
) => ({
  type: DELETE_MODULATION_PERIOD_COUNTER_ENTRY,
  entryId,
  resolve,
  reject
})

// ------------------------------------
// Saga
// ------------------------------------

const modulationPeriodCountersEntrySagaEntity = {
  success: (data, entryId) => ({
    type: DELETE_MODULATION_PERIOD_COUNTER_ENTRY_SUCCEDED,
    data,
    entryId
  }),
  failure: (error, entryId) => ({
    type: DELETE_MODULATION_PERIOD_COUNTER_ENTRY_FAILED,
    error,
    entryId
  }),

  fetchAPI: (entryId, options) =>
    callAPI(`/modulation_period_counter_entries/${entryId}`, {
      method: 'DELETE',
      ...options
    })
}

export function* doDeleteModulationPeriodCountersEntry({
  entryId,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    modulationPeriodCountersEntrySagaEntity,
    entryId,
    null,
    null,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const ModulationPeriodCounterEntry = Immutable.Record({
  id: null,
  modulation_period_counter_id: null,
  value_in_minutes: null,
  resumption_date: null,
  week: null,
  counter_type: null,
  contract_time: null,
  worked_hours: null,
  rest_hours: null,
  balance_in_minutes: null
})

const modulationPeriodCounterEntriesForeignKeys = [
  'modulation_period_counter_id'
]

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function modulationPeriodCounterEntries(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION_SUCCEEDED:
    case FETCH_MODULATION_PERIOD_COUNTERS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const counters = action.data
      const entries = Immutable.fromJS(
        counters.map(counter => counter.resumption_entry).filter(Boolean)
      )

      return mergeRecords(
        state,
        ModulationPeriodCounterEntry,
        entries,
        // @ts-ignore migration from js(x) to ts(x)
        modulationPeriodCounterEntriesForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }

    case FETCH_MODULATION_PERIOD_COUNTER_SUCCEEDED:
    case UPDATE_MODULATION_PERIOD_COUNTER_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { entries, resumption_entry: resumptionEntry } = action.data
      const records = Immutable.fromJS(entries || [])
        .push(Immutable.fromJS(resumptionEntry))
        .filter(Boolean)

      return mergeRecords(
        state,
        ModulationPeriodCounterEntry,
        records,
        // @ts-ignore migration from js(x) to ts(x)
        modulationPeriodCounterEntriesForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }

    case DELETE_MODULATION_PERIOD_COUNTER_ENTRY_SUCCEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { entryId } = action

      return deleteRecord(
        state,
        entryId,
        // @ts-ignore migration from js(x) to ts(x)
        modulationPeriodCounterEntriesForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        success: true
      })
    }
    default:
      return state
  }
}

export default filterActions(modulationPeriodCounterEntries, [
  FETCH_MODULATION_PERIOD_COUNTER_SUCCEEDED,
  FETCH_MODULATION_PERIOD_COUNTERS_SUCCEEDED,
  FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION_SUCCEEDED,
  UPDATE_MODULATION_PERIOD_COUNTER_SUCCEEDED,
  DELETE_MODULATION_PERIOD_COUNTER_ENTRY,
  DELETE_MODULATION_PERIOD_COUNTER_ENTRY_SUCCEDED,
  DELETE_MODULATION_PERIOD_COUNTER_ENTRY_FAILED
])
