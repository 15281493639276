import { useEffect, useState } from 'react'

import type {
  AsyncAutocompleteOptions,
  AsyncAutocompleteProps
} from '../AsyncAutocomplete.types'

type Params = {
  loadOptions: AsyncAutocompleteProps['loadOptions']
  searchValue: string
  defaultOptions: AsyncAutocompleteProps['defaultOptions']
}

export const useGetAsyncAutocompleteOption = ({
  loadOptions,
  searchValue,
  defaultOptions
}: Params) => {
  const [isOptionsLoading, setIsOptionsLoading] = useState(false)
  const [options, setOptions] = useState<AsyncAutocompleteOptions[]>([])

  useEffect(() => {
    /**
     * Default options is true and search value is empty - load initial options
     */
    if (defaultOptions === true && searchValue === '') {
      setIsOptionsLoading(true)
      loadOptions('')
        .then(options => {
          setOptions(options)
        })
        .finally(() => setIsOptionsLoading(false))
      return
    }

    /**
     * Default options is an array with items and search value is empty - use provided defaults
     */
    if (
      Array.isArray(defaultOptions) &&
      defaultOptions.length > 0 &&
      searchValue === ''
    ) {
      setOptions([...defaultOptions])
      return
    }

    /**
     * No default options and empty search - clear options
     */
    if (!defaultOptions && searchValue === '') {
      setOptions([])
      return
    }

    /**
     * Search value is not empty - load options based on search
     */
    if (searchValue !== '') {
      setIsOptionsLoading(true)
      loadOptions(searchValue)
        .then(options => {
          setOptions(options)
        })
        .finally(() => setIsOptionsLoading(false))
    }
  }, [searchValue, defaultOptions, loadOptions])

  return { options, isOptionsLoading }
}
