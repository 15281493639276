import { createSafeContext } from '@libs/ui/ds/lib/internals'

import type { PopoverProps } from './Popover.types'

export type PopoverContext = {
  opened?: PopoverProps['opened']
  onChange?: PopoverProps['onChange']
  dropdownWidth?: PopoverProps['dropdownWidth']
  dropdownHeight?: PopoverProps['dropdownHeight']
  trigger?: PopoverProps['trigger']
}

export const [PopoverProvider, usePopoverContext] =
  createSafeContext<PopoverContext>({
    context: 'Popover'
  })
