import moment from 'moment'

import { getMinutesDiff } from './dateUtils'
import type { Range } from './types'

/**
 * Return a decimal number which represent the decimal overlap between 2 ranges
 *
 * For example given:
 * - intervalRange = 12h00 - 13h00
 * - itemRange = 12h30 - 12h45
 * The decimal overlap will be equals to 0.25
 * Indeed the overlap is 15mn and the intervalRange is 60mn, so the computation will be 15/60
 */
export const getDecimalRangeOverlap = (
  itemRange: Range,
  intervalRange: Range
) => {
  const intervalStart = moment(intervalRange.start)
  const intervalEnd = moment(intervalRange.end)
  const itemStart = moment(itemRange.start)
  const itemEnd = moment(itemRange.end)

  const start = itemStart.isBefore(intervalStart) ? intervalStart : itemStart
  const end = itemEnd.isAfter(intervalEnd) ? intervalEnd : itemEnd

  // Get the minutes difference between interval and
  const intervalRangeDiff = getMinutesDiff(intervalEnd, intervalStart)
  const startDiff = Math.abs(getMinutesDiff(intervalStart, start))
  const endDiff = Math.abs(getMinutesDiff(intervalEnd, end))

  const totalDiff = startDiff + endDiff
  const overlapInMinutes = intervalRangeDiff - totalDiff
  const decimalOverlap = overlapInMinutes / intervalRangeDiff

  // Prevent returning negative numbers if there is no overlap
  return Math.max(decimalOverlap, 0)
}
