import { usePrevious } from '@libs/ui/ds'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { page } from '../methods/page'

export const useSegmentPageView = () => {
  const location = useLocation()
  const previousPathname = usePrevious(location.pathname)

  useEffect(() => {
    if (location.pathname !== previousPathname) {
      page(location.pathname)
    }
  }, [location])
}
