import resourcesToBackend from 'i18next-resources-to-backend'

export const localResourcesToBackend = () => {
  return resourcesToBackend((language, namespace, callback) => {
    import(`../locales/${language}/${namespace}.json`)
      .then(({ default: resources }) => {
        callback(null, resources)
      })
      .catch(error => {
        callback(error, null)
      })
  })
}
