import type { Model } from '@libs/data-access/entities'
import { schema } from 'normalizr'
import type { Row as RowSchema } from 'snap-redux/infra/http/routesConfig/scopes/planning/schemas'

import Rest from './Rest'
import Shift from './Shift'

export type RowModel = Model<
  RowSchema,
  {
    shifts: number[]
    rests: number[]
  }
>

const Row = new schema.Entity<RowModel>('rows', {
  shifts: [Shift],
  rests: [Rest]
})

export default Row
