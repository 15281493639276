import type { WeeklyScheduleSchema } from '@libs/data-access/entities'
import type { ReduxState } from '@spa/redux/ReduxState'
import { useMemoSelector } from '@spa/redux/hooks'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'
import type { Shift } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import type { TeamModel } from '../../../models/Team'
import {
  shiftsSelectors,
  teamsSelectors,
  weeklyschedulesSelectors
} from '../../entities'
import type { PlanningPolicies } from '../getPlanningPolicies'
import { getPlanningPolicies } from '../getPlanningPolicies'

type Params = {
  shiftId: number
  weeklyscheduleId: number
}

const getShiftById = (state: ReduxState, { shiftId }: Params) => {
  return shiftsSelectors.getCachedById(state, shiftId)
}

const getWeeklyscheduleById = (
  state: ReduxState,
  { weeklyscheduleId }: Params
) => weeklyschedulesSelectors.getCachedById(state, weeklyscheduleId)

const getShiftTeamByShiftId = (state: ReduxState, { shiftId }: Params) => {
  const shift = shiftsSelectors.getCachedById(state, shiftId)
  const shiftWeeklyschedule = weeklyschedulesSelectors.getCachedById(
    state,
    shift.weeklyschedule_id
  )

  return teamsSelectors.getCachedById(state, shiftWeeklyschedule?.team_id)
}

const computeEditShiftPolicies = (
  shift: Shift,
  weeklyschedule: Omit<WeeklyScheduleSchema, 'permissions'>,
  shiftTeam: TeamModel,
  policies: PlanningPolicies
) => {
  const isLocked = Boolean(shift.locked_at)

  const isUnplanned = shift?.planification_type === 'unscheduled'

  const isUnplannedPublishAllowed = weeklyschedule.published
    ? policies.canDNDShiftPublished
    : false

  const isPublishAllowed = weeklyschedule.published
    ? policies.canDNDShiftPublished
    : policies.canDNDShift

  const publishRules = isUnplanned
    ? isUnplannedPublishAllowed
    : isPublishAllowed

  const hasDifferentTeam = shiftTeam
    ? shiftTeam?.id !== weeklyschedule.team_id
    : true

  const isExternalShift = hasDifferentTeam
  const isExternalAllowed = isExternalShift
    ? policies.canDragExternalShift
    : true

  return {
    canEditShift: !isLocked && publishRules && isExternalAllowed
  }
}

export const getEditShiftPolicies = createCachedSelector(
  getShiftById,
  getWeeklyscheduleById,
  getShiftTeamByShiftId,
  getPlanningPolicies,
  computeEditShiftPolicies
)((_, { shiftId }) => fallbackToDefaultCacheKey(shiftId))

export const useEditShiftPolicies = (opts: Params) => {
  return useMemoSelector(state => getEditShiftPolicies(state, opts))
}
