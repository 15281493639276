import { useDidMount } from '@libs/ui/ds'
import { type ReactElement } from 'react'

import { useListenAuthTokensRefresh } from './authState/hooks/useListenAuthTokensRefresh'
import { usePostRefreshToken } from './authState/hooks/usePostRefreshToken'

export const AuthenticationRefreshGuard = ({ children }) => {
  const refreshTokenMutation = usePostRefreshToken()

  /**
   * Add background watcher that will refresh tokens whenever expired
   */
  useListenAuthTokensRefresh()

  /**
   * Handle refresh flow when refreshing the browser
   * By doing that, we are sure that the user has a fresh token before
   */
  useDidMount(() => {
    refreshTokenMutation.process()
  })

  if (refreshTokenMutation.isRefreshing) {
    return null
  }

  return children as ReactElement
}
