import { cva } from 'class-variance-authority'
import cn from 'classnames'
import { forwardRef, memo } from 'react'

import { Center } from '../../../primitiveLayouts/Center/base/Center'
import { IconWrap } from '../../IconWrap/base/IconWrap'

import type { BadgeProps } from './Badge.types'
import { badgeCompoundClasses } from './badgeCompoundClasses'

const badgeCVA = {
  root: cva(
    [
      'relative z-0 box-border inline-flex items-center justify-center rounded-full border-1 border-solid'
    ],
    {
      variants: {
        size: {
          dot: 'size-2 max-h-2 min-h-2 min-w-2 max-w-2',
          xs: 'h-4 max-h-4 min-h-4 px-1.5 text-3xs',
          s: 'h-5 max-h-5 min-h-5 px-2 text-xxs',
          m: 'h-6 max-h-6 min-h-6 px-2.5 text-xs',
          l: 'h-7 max-h-7 min-h-7 px-3 text-s',
          xl: 'h-9 max-h-9 min-h-9 px-4 text-s'
        },
        variant: {
          light: 'border-transparent',
          outlined: 'border-current',
          plain: 'border-transparent'
        }
      }
    }
  ),
  colorClass: cva([], {
    variants: {
      variant: {
        light: '',
        outlined: '',
        plain: ''
      },
      color: {
        neutral: '',
        'semantic.blue': '',
        'semantic.green': '',
        'semantic.red': '',
        'semantic.orange': '',
        'secondary.blue': '',
        'secondary.coral': '',
        'secondary.green': '',
        'secondary.orange': '',
        'secondary.purple': ''
      }
    },
    compoundVariants: [...badgeCompoundClasses]
  }),
  wrap: cva(['z-10'], {
    variants: {
      size: {
        dot: '',
        xs: 'gap-0.5',
        s: 'gap-1',
        m: 'gap-1.5',
        l: 'gap-2',
        xl: 'gap-2'
      }
    }
  }),
  animatedWrap: cva(['absolute size-full'], {
    variants: {
      size: {
        dot: 'scale-[2.5]',
        xs: 'scale-90',
        s: 'scale-90',
        m: 'scale-90',
        l: 'scale-90',
        xl: 'scale-90'
      }
    }
  }),
  animatedBox: cva(['absolute size-full animate-ping rounded-full']),
  props: {
    iconSize: {
      dot: 'xs',
      xs: 'xs',
      s: 'xs',
      m: 's',
      l: 's',
      xl: 'm'
    }
  }
} as const

export const Badge = memo(
  forwardRef<HTMLDivElement, BadgeProps>(
    (
      {
        leftIcon,
        rightIcon,
        className,
        label,
        processing = false,
        variant = 'light',
        color = 'neutral',
        size = 'm',
        ...other
      },
      ref
    ) => {
      return (
        <div
          {...other}
          ref={ref}
          className={cn(
            badgeCVA.root({ className, variant, size }),
            badgeCVA.colorClass({ variant, color })
          )}
        >
          {size !== 'dot' && (
            <Center className={badgeCVA.wrap({ size })}>
              {Boolean(leftIcon) && (
                <IconWrap
                  icon={leftIcon}
                  size={badgeCVA.props.iconSize[size]}
                />
              )}
              {Boolean(label) && (
                <span className='w-full truncate'>{label}</span>
              )}
              {Boolean(rightIcon) && (
                <IconWrap
                  icon={rightIcon}
                  size={badgeCVA.props.iconSize[size]}
                />
              )}
            </Center>
          )}
          {processing && (
            <div className={badgeCVA.animatedWrap({ size })}>
              <div
                className={cn(
                  badgeCVA.animatedBox(),
                  badgeCVA.colorClass({ variant, color })
                )}
              />
            </div>
          )}
        </div>
      )
    }
  )
)
Badge.displayName = 'Badge'
export type { BadgeProps } from './Badge.types'
export type { BadgeColor } from './Badge.types'
