import type { Shift } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

/**
 * Filter shifts related to list of contract ids
 */
export const filterShiftsByContracts = (
  shifts: Shift[],
  contractIds: number[]
) => {
  return shifts.filter(shift => contractIds.includes(shift.user_contract_id))
}
