import type { FormatterParams } from '../../types'

/**
 * Convert number to isoDecimal
 * Transform to integer if there is no decimal value
 *
 * Input 125.1234
 * Output 125.12
 *
 * Input 125.00
 * Output 125
 */
export function isoDecimal({ value }: FormatterParams<number | string>) {
  return parseFloat(value as any)
    .toFixed(2)
    .replace('.00', '')
}

export default {
  format: isoDecimal
}
