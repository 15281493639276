import { FieldError } from '@spa/components/FormFields'
import withController from '@spa/hoc/withController'
import { toKebabCase } from '@spa/services/toKebabCase'
import classnames from 'classnames'
import React from 'react'
import type { ChangeEvent, FC } from 'react'

import formStyles from '../FormFields.module.css'

import styles from './FieldInput.module.css'

type ExtendedProps = React.HTMLProps<HTMLInputElement>
type Value = string | number

export type FieldInputProps = {
  defaultValue?: string
  disabled?: boolean
  error?: string
  displayError?: boolean // use with design system v2 inlineInputWrap
  inputId?: string
  inputName?: string
  normalize?(value: Value): any
  onChange?: (data: any) => void
  placeholder?: string
  required?: boolean
  small?: boolean
  step?: string
  type?: string
  value?: Value
  width?: string
  valueAsNumber?: boolean
  highlightError?: boolean
  suffix?: React.ReactNode
  prefixProp?: React.ReactNode
} & ExtendedProps

export const FieldInput: FC<FieldInputProps> = ({
  defaultValue,
  disabled = false,
  error = null,
  displayError = true,
  inputId,
  inputName,
  normalize,
  onChange,
  placeholder = undefined,
  required = false,
  small = false,
  step,
  highlightError,
  type = 'text',
  value = '',
  width = '100%',
  valueAsNumber,
  suffix = null,
  prefixProp = null,
  ...other
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let data = valueAsNumber
      ? (Number.isNaN(e.target.valueAsNumber) ? '' : e.target.valueAsNumber) ??
        e.target.value
      : e.target.value

    if (data && normalize) {
      data = normalize(data)
    }
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    onChange(data)
  }

  return (
    <>
      <div
        className={classnames(styles.Container, {
          [formStyles.FieldError]: !!error || highlightError,
          [styles.ContainerDisabled]: disabled,
          [styles.NoBorder]: type === 'hidden'
        })}
        style={{ width }}
      >
        {prefixProp && (
          <span
            className={classnames(styles.Prefix, {
              [styles.smallPrefix]: small
            })}
          >
            {prefixProp}
          </span>
        )}
        <input
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          data-testid={`field-input-${toKebabCase(inputName)}`}
          type={type}
          step={step}
          value={value}
          defaultValue={defaultValue}
          name={inputName}
          id={inputId || inputName}
          disabled={disabled}
          required={required}
          placeholder={placeholder}
          onChange={handleChange}
          className={classnames(formStyles.Field, styles.Input, {
            [styles.small]: small
          })}
          {...other}
        />
        {suffix && (
          <span
            className={classnames(styles.Suffix, {
              [styles.smallSuffix]: small
            })}
          >
            {suffix}
          </span>
        )}
      </div>
      {displayError && error && <FieldError error={error} />}
    </>
  )
}

export const ControlledFieldInput = withController<FieldInputProps, Value>()(
  FieldInput
)
