import type { DirectDebitInitFlowSchema } from '@libs/data-access/entities'
import { useMutation } from '@tanstack/react-query'
import type {
  ApiErrorResponse,
  UseAppMutationOptions
} from 'libs/data-access/queries/src/lib/types'

import type { InitiateAccountDirectDebitOptions } from '../directDebit.api'
import { initiateAccountDirectDebit } from '../directDebit.api'
import { directDebitCacheKeys } from '../directDebit.cacheKeys'

export const useInitiateAccountDirectDebit = (
  mutationOptions?: UseAppMutationOptions<
    DirectDebitInitFlowSchema,
    ApiErrorResponse,
    InitiateAccountDirectDebitOptions
  >
) => {
  return useMutation<
    DirectDebitInitFlowSchema,
    ApiErrorResponse,
    InitiateAccountDirectDebitOptions
  >({
    ...mutationOptions,
    mutationKey: directDebitCacheKeys.initiate(),
    mutationFn: (requestOptions: InitiateAccountDirectDebitOptions) =>
      initiateAccountDirectDebit(requestOptions),
    onSuccess: (...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess(...args)
      }
    }
  })
}
