import { cva } from 'class-variance-authority'
import React, { memo, useRef, useState } from 'react'

import { Flex } from '../../../primitiveLayouts/Flex/base/Flex'

import { TimelineProvider } from './Timeline.context'
import type { TimelineProps } from './Timeline.types'
import { TimelineItem } from './compound/TimelineItem/TimelineItem'
import ShowButton from './subs/ShowButton'

const timelineCVA = {
  root: cva(['gap-2 sm:gap-4'])
}

const BaseTimeline: React.FC<TimelineProps> = memo(
  ({ max = 2, onShowMore, children, ...other }) => {
    const [showMore, setShowMore] = useState(false)
    const childCount = React.Children.count(children)
    const isMoreChildrenThanMax = childCount > max
    const timelineRef = useRef<HTMLDivElement>(null)

    const initalChildrenToDisplay = React.Children.map(
      children,
      child => child
    )?.slice(0, max)

    return (
      <TimelineProvider value={{ showMore, setShowMore }}>
        <Flex ref={timelineRef} className={timelineCVA.root()} {...other}>
          {!showMore ? initalChildrenToDisplay : children}
          {isMoreChildrenThanMax && (
            <ShowButton
              data-testid={other['data-testid']}
              {...(onShowMore && {
                onClick: () => {
                  if (timelineRef.current && onShowMore) {
                    onShowMore({
                      innerOffsetTop: timelineRef.current.offsetTop
                    })
                  }
                }
              })}
            />
          )}
        </Flex>
      </TimelineProvider>
    )
  }
)

type CompoundTimelineType = {
  Item: typeof TimelineItem
}

const TypedTimeline = BaseTimeline as typeof BaseTimeline & CompoundTimelineType
TypedTimeline.displayName = 'Timeline'
TypedTimeline.Item = TimelineItem

export const Timeline = TypedTimeline
export type { TimelineProps } from './Timeline.types'
export type { TimelineItemProps } from './compound/TimelineItem/TimelineItem.types'
