import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_CONVERSATION_ATTACHMENTS,
  FETCH_CONVERSATION_ATTACHMENTS_FAILED,
  FETCH_CONVERSATION_ATTACHMENTS_SUCCEEDED,
  FETCH_MESSAGES_SUCCEEDED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchConversationAttachments = (
  conversationId,
  params,
  resolve,
  reject
) => ({
  type: FETCH_CONVERSATION_ATTACHMENTS,
  conversationId,
  params,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const conversationAttachmentsSagaEntity = {
  success: (data, conversationId) => ({
    type: FETCH_CONVERSATION_ATTACHMENTS_SUCCEEDED,
    data,
    conversationId
  }),
  failure: (error, conversationId) => ({
    type: FETCH_CONVERSATION_ATTACHMENTS_FAILED,
    error,
    conversationId
  }),

  fetchAPI: (conversationId, options, params) =>
    callAPI(
      `/conversations/${conversationId}/attachments?${queryString.stringify(
        params
      )}`,
      options
    )
}

export function* doFetchConversationAttachments({
  conversationId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    conversationAttachmentsSagaEntity,
    conversationId,
    params,
    undefined,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const ConversationAttachment = Immutable.Record({
  id: null,
  attachment_url: null,
  content_type: null,
  file_name: null,
  message_id: null,
  conversation_id: null
})

const conversationAttachmentForeignKeys = ['message_id', 'conversation_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function conversationAttachments(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_CONVERSATION_ATTACHMENTS: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_CONVERSATION_ATTACHMENTS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_CONVERSATION_ATTACHMENTS_SUCCEEDED: {
      const conversationAttachments = Immutable.fromJS(
        // @ts-ignore migration from js(x) to ts(x)
        action.data.map(attachment => ({
          ...attachment,
          // @ts-ignore migration from js(x) to ts(x)
          conversation_id: action.conversationId
        }))
      )

      return mergeRecords(
        state,
        ConversationAttachment,
        conversationAttachments,
        // @ts-ignore migration from js(x) to ts(x)
        conversationAttachmentForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    case FETCH_MESSAGES_SUCCEEDED: {
      const conversationAttachments = Immutable.fromJS(
        // @ts-ignore migration from js(x) to ts(x)
        action.data.messages
          .filter(message => Boolean(message.attachment))
          .map(message => ({
            ...message.attachment,
            // @ts-ignore migration from js(x) to ts(x)
            conversation_id: action.conversationId
          }))
      )

      return mergeRecords(
        state,
        ConversationAttachment,
        conversationAttachments,
        // @ts-ignore migration from js(x) to ts(x)
        conversationAttachmentForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    default:
      return state
  }
}

export default filterActions(conversationAttachments, [
  FETCH_CONVERSATION_ATTACHMENTS,
  FETCH_CONVERSATION_ATTACHMENTS_FAILED,
  FETCH_CONVERSATION_ATTACHMENTS_SUCCEEDED,
  FETCH_MESSAGES_SUCCEEDED
])
