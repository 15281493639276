import type { HTMLAttributes, PropsWithChildren } from 'react'
import React, { forwardRef, memo } from 'react'

import { useHoverContext } from '../Hover.context'

export type HoverHoveredProps = PropsWithChildren &
  HTMLAttributes<HTMLDivElement> & {}

export const HoverHovered = memo(
  forwardRef<HTMLDivElement, HoverHoveredProps>(
    ({ children, ...other }, ref) => {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      const { hovered } = useHoverContext()

      if (!hovered) {
        return null
      }

      return (
        <div ref={ref} {...other}>
          {children}
        </div>
      )
    }
  )
)
