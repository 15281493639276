import type { ReduxState } from '@spa/redux/ReduxState'
import { createSelector } from 'reselect'
import { getCurrentMembershipSelector } from 'snap-redux/modules/memberships/selectors'

import { getPlanningPolicies } from '../getPlanningPolicies'

type ManagerOwnPolicyManipulation =
  | 'canValidateOwnRealHours'
  | 'canInvalidateOwnRealHours'

type Params = {
  membershipId: number
  policyKey: ManagerOwnPolicyManipulation
}

export const getOwnMembershipValidation = createSelector(
  getCurrentMembershipSelector,
  getPlanningPolicies,
  (_: ReduxState, { membershipId }: Params) => membershipId,
  (_: ReduxState, { policyKey }: Params) => policyKey,

  (currentMembership, policies, membershipId, policyKey) => {
    if (currentMembership.id !== membershipId) {
      return true
    }

    return policies[policyKey]
  }
)
