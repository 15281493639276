import type { ReduxState } from '@spa/redux/ReduxState'
import { createSelector } from 'reselect'

import { getParams } from '../../../params'
import { locationsSelectors } from '../../locations'
import { teamsBaseSelectors } from '../teamsBaseSelectors'

export const getCurrentLocationAndTeam = createSelector(
  (state: ReduxState) =>
    locationsSelectors.getCachedById(state, getParams(state).locationId),
  (state: ReduxState) =>
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    teamsBaseSelectors.getCachedById(state, getParams(state).teamId),
  (state: ReduxState) => {
    const location = locationsSelectors.getCachedById(
      state,
      getParams(state).locationId
    )
    return teamsBaseSelectors.getCachedById(state, location?.team_ids?.[0])
  },

  (location, team, firstLocationTeam) => {
    return {
      currentLocation: location,
      currentTeam: team,
      currentLocationHasMultipleTeams: location?.team_ids?.length > 1,
      defaultLocationTeam: team || firstLocationTeam
    }
  }
)
