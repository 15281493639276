import { useEffect, useState } from 'react'

import type { TableProps } from '../base/Table.types'

type TableHorizontalScrollParams = {
  horizontalScroll: TableProps['horizontalScroll']
  tableRef: any
}

export const useTableHorizontalScroll = ({
  horizontalScroll = false,
  tableRef
}: TableHorizontalScrollParams) => {
  const [tableShadowLeft, setTableShadowLeft] = useState<boolean>(false)
  const [tableShadowRight, setTableShadowRight] =
    useState<boolean>(horizontalScroll)

  const handleScroll = () => {
    if (tableRef.current) {
      const maxScrollLeft =
        tableRef.current.scrollWidth - tableRef.current.clientWidth
      const { scrollLeft } = tableRef.current

      if (scrollLeft === maxScrollLeft) {
        setTableShadowRight(false)
      } else {
        setTableShadowRight(horizontalScroll)
      }

      if (scrollLeft >= maxScrollLeft - 1 || scrollLeft > 0) {
        setTableShadowLeft(true)
      } else {
        setTableShadowLeft(false)
      }
    }
  }

  useEffect(() => {
    if (horizontalScroll) {
      tableRef?.current?.addEventListener('scroll', handleScroll)

      return () => {
        tableRef?.current?.removeEventListener('scroll', handleScroll)
      }
    }
  }, [horizontalScroll])

  return {
    tableRef,
    tableShadowLeft,
    tableShadowRight
  }
}
