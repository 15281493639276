import { httpServices, wsServices } from '@libs/data-access/queries'

import type { CreateExportFilePayload } from '../exportFile.api'
import { createExportFile } from '../exportFile.api'

const ExportFileChannelPayload = { channel: 'Export::FileChannel' } as const

type GenerateExportFileSucceeded = {
  message_id: string
  type: 'api/GENERATE_EXPORT_FILE_SUCCEEDED'
  export_file_id: number
  data: {
    id: number
    generated_at: string
    requester_id: number
    export_type:
      | 'weeklyschedule'
      | 'timesheet'
      | 'user_contract'
      | 'compensatory_balance'
      | 'technical_unemployment'
      | 'modulation'
      | 'cegid_employees'
    requester: null
    document: string
  }
}

const sendWebsocketDownloadRequest = (job_id: string) => {
  wsServices.core.ws.send({
    data: {
      action: 'download',
      message_id: job_id,
      account_id: httpServices.authInfo.accountId
    },
    identifier: ExportFileChannelPayload
  })
}

const waitForJobCompleted = (
  message_id: string
): Promise<GenerateExportFileSucceeded> => {
  return new Promise(resolve => {
    wsServices.core.ws.onMessage((message: GenerateExportFileSucceeded) => {
      if (message.message_id === message_id) {
        return resolve(message)
      }
    })
  })
}

export const useExportFileSubscription = wsServices.core.subscription.create({
  subscribePayload: () => ExportFileChannelPayload,
  messages: {
    onExportFileSucceeded:
      wsServices.core.subscription.defineMessageHandler<GenerateExportFileSucceeded>(
        { type: 'api/GENERATE_EXPORT_FILE_SUCCEEDED' }
      )
  },
  emit: {
    /**
     * Transform websocket emit/receipt message into a Promise
     * So the caller can use async/await to handle the asynchronous flow
     */
    download: async (payload: CreateExportFilePayload) => {
      const { job_id } = await createExportFile({ payload })
      sendWebsocketDownloadRequest(job_id)
      return waitForJobCompleted(job_id)
    }
  }
})
