import type { Rest } from '@libs/data-access/entities'
import type { ReduxState } from '@spa/redux/ReduxState'

import { rowsSelectors } from '../../rows'
import { restsBaseSelectors } from '../restsBaseSelectors'

/**
 * This selector aims to return rests that are up to date
 *
 * When deleting rests, we don't delete the rests entries in the store, resulting in stale data.
 * The fresh data relations are stored in the rows entity
 */
export const getFreshRestsList = (state: ReduxState) => {
  const rests = restsBaseSelectors.getCachedMap(state)
  const rowsList = rowsSelectors.getCachedList(state)

  const displayedRestList = rowsList.flatMap(row =>
    row.rests.map(restId => rests[restId])
  )
  return displayedRestList as Rest[]
}
