import { useMediaQueryThreshold } from '@libs/ui/ds/lib/theme'

import type { ModalProps } from '../Modal.types'

/**
 * In smaller screens display modals in fullscreen
 */
export const useAdaptModalVariant = (inputVariant: ModalProps['variant']) => {
  const isSmallScreen = useMediaQueryThreshold({
    mediaQueryTreshold: 'max-sm'
  })
  return isSmallScreen ? 'fullscreen' : inputVariant
}
