import { getParams } from '@spa/scenes/Plannings/redux/selectors'
import { select, takeEvery } from 'redux-saga/effects'
import { PLANNING_USER_CONTRACT_UPDATED } from 'snap-redux/actionTypes'

import fetchPlannablesSideEffectsEntities from '../controllers/request/fetchPlannableSideEffectsEntities'

function* sync(action: any) {
  const params = yield select(getParams)

  // @todo register module redux on planning v2 scene
  if (!params.date) {
    return
  }

  switch (action.type) {
    case PLANNING_USER_CONTRACT_UPDATED: {
      const params = yield select(getParams)

      yield fetchPlannablesSideEffectsEntities({
        contractIds: [action.data.user_contract_id],
        date: params.date,
        countersParams: { live: true }
      })

      break
    }

    default:
      break
  }
}

export default function* userContractWatcher() {
  yield takeEvery([PLANNING_USER_CONTRACT_UPDATED], sync)
}
