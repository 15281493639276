import { FieldError } from '@spa/components/FormFields'
import { useMemoSelector } from '@spa/redux/hooks'
import { toKebabCase } from '@spa/services/toKebabCase'
import React from 'react'
import 'react-phone-input-2/lib/plain.css'
import type { FC } from 'react'
import type { PhoneInputProps, CountryData } from 'react-phone-input-2'
import PI from 'react-phone-input-2'
import { getCountryConfiguration } from 'snap-redux/modules/countryConfigurations/selectors'

import type { InternationalPhoneInput } from '../constants/types'

import styles from './FieldPhoneNumber.module.css'

const ReactPhoneInput: FC<PhoneInputProps> = (PI as any).default || PI

type Props = {
  inputMask?: string
  inputName?: string
  error?: string
  disabled?: boolean
  onChange: (value: string, data: {} | CountryData) => void
  onBlur: () => void
  value: InternationalPhoneInput
}

export const FieldPhoneNumber: FC<Props> = ({
  inputMask,
  inputName,
  value,
  error,
  disabled = false,
  onChange,
  onBlur
}) => {
  const countryConfiguration = useMemoSelector(getCountryConfiguration)

  return (
    <div className={styles.Container}>
      <ReactPhoneInput
        inputClass={error ? styles.InputErrorStyle : styles.InputStyle}
        buttonClass={error ? styles.ButtonErrorStyle : styles.ButtonStyle}
        dropdownClass={styles.DropdownStyle}
        country={countryConfiguration.iso_code}
        value={value.value}
        isValid={!error}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        {...(inputMask && {
          defaultMask: inputMask,
          alwaysDefaultMask: true
        })}
        inputProps={{
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          'data-testid': `field-input-${toKebabCase(inputName)}`
        }}
      />
      {error && <FieldError error={error} />}
    </div>
  )
}
