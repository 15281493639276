import type { AmendmentSchema } from '@libs/data-access/entities'
import { useMutation } from '@tanstack/react-query'
import { appQueryClient } from 'libs/data-access/queries/src/lib/provider'
import type {
  UseAppMutationOptions,
  NewApiErrorResponse
} from 'libs/data-access/queries/src/lib/types'

import { putAmendment, type PutAmendmentOption } from '../amendments.api'
import { amendmentsCacheKeys } from '../amendments.cacheKeys'

export const usePutAmendment = (
  mutationOptions?: UseAppMutationOptions<
    AmendmentSchema,
    NewApiErrorResponse,
    PutAmendmentOption
  >
) => {
  return useMutation<AmendmentSchema, NewApiErrorResponse, PutAmendmentOption>({
    ...mutationOptions,
    mutationKey: amendmentsCacheKeys.put_amendment(),
    mutationFn: opts => putAmendment(opts),
    onSuccess: (...args) => {
      mutationOptions?.onSuccess?.(...args)
      appQueryClient.invalidateQueries({
        queryKey: amendmentsCacheKeys.fetch_contracts_invalidate()
      })
    }
  })
}
