import { useSafeRouterSearchParams, useUncontrolled } from '@libs/ui/ds'
import { useMemo } from 'react'

export type GlobalFilterStateParams = {
  state?: string
  onChange?: (str: string) => void
}

export const useTableGlobalFilterState = (
  globalFilter: GlobalFilterStateParams = {},
  syncSearchParamsURL?: boolean
) => {
  const [searchParams, setSearchParams] = useSafeRouterSearchParams<{
    search: string
  }>()
  const [filter, setFilter] = useUncontrolled({
    value: globalFilter?.state,
    onChange: globalFilter?.onChange
  })

  const inMemoryState = useMemo(
    () => ({
      filter,
      setFilter
    }),
    [filter, setFilter]
  )

  const inSearchURLState = useMemo(() => {
    return {
      filter: searchParams.search,
      setFilter: (value: string) =>
        setSearchParams({ ...searchParams, search: value })
    }
  }, [searchParams])

  return syncSearchParamsURL ? inSearchURLState : inMemoryState
}
