import { useMutation } from '@tanstack/react-query'

import type { ApiErrorResponse, UseAppMutationOptions } from '../../../../types'
import {
  type BulkActionOptions,
  bulkInvalidateShiftsList
} from '../shift.bulk.api'
import { shiftBulkCacheKeys } from '../shift.bulk.cacheKeys'

export const useBulkInvalidateShifts = (
  mutationOpts?: UseAppMutationOptions<BulkActionOptions>
) => {
  return useMutation<BulkActionOptions, ApiErrorResponse, BulkActionOptions>({
    ...mutationOpts,
    mutationKey: shiftBulkCacheKeys.action.invalidate(),
    mutationFn: (opts: BulkActionOptions) => bulkInvalidateShiftsList(opts)
  })
}
