import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_RESTS,
  FETCH_RESTS_SUCCEEDED,
  FETCH_RESTS_FAILED,
  CREATE_REST,
  CREATE_REST_SUCCEEDED,
  CREATE_REST_FAILED,
  UPDATE_REST,
  UPDATE_REST_SUCCEEDED,
  UPDATE_REST_FAILED,
  DELETE_REST,
  DELETE_REST_SUCCEEDED,
  DELETE_REST_FAILED,
  DELETE_RESTS_FROM_STORE,
  DELETE_CONTRACT_SUCCEEDED,
  FETCH_REST_SUCCEEDED,
  FETCH_REST_FAILED,
  FETCH_REST
} from '../../actionTypes'
import { NOT_FOUND } from '../../constants'
import { call as callAPI } from '../../infra/http'
import {
  mergeRecords,
  updateRecord,
  deleteRecord
} from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchRests = (accountId, params, resolve, reject) => ({
  type: FETCH_RESTS,
  accountId,
  params,
  resolve,
  reject
})

export const hydrateRests = (accountId, data) => ({
  type: FETCH_RESTS_SUCCEEDED,
  data,
  accountId
})

export const fetchRest = (restId, params, resolve, reject) => ({
  type: FETCH_REST,
  restId,
  params,
  resolve,
  reject
})

export const createRest = (restData, resolve, reject) => ({
  type: CREATE_REST,
  restData,
  resolve,
  reject
})

export const updateRest = (restId, restData, resolve, reject) => ({
  type: UPDATE_REST,
  restId,
  restData,
  resolve,
  reject
})

export const deleteRest = (restId, resolve, reject) => ({
  type: DELETE_REST,
  restId,
  resolve,
  reject
})

export const deleteRestsFromStore = rests => ({
  type: DELETE_RESTS_FROM_STORE,
  rests
})

// ------------------------------------
// Sagas
// ------------------------------------

const restsSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_RESTS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_RESTS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/rests?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchRests({ accountId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    restsSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

const fetchRestSagaEntity = {
  success: (data, restId) => ({
    type: FETCH_REST_SUCCEEDED,
    data,
    restId
  }),
  failure: (error, restId) => ({
    type: FETCH_REST_FAILED,
    error,
    restId
  }),

  fetchAPI: (restId, options) => callAPI(`/rests/${restId}`, options)
}

export function* doFetchRest({ restId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    fetchRestSagaEntity,
    restId,
    params,
    undefined,
    resolve,
    reject
  )
}

export const createRestSagaEntity = {
  success: data => ({ type: CREATE_REST_SUCCEEDED, data }),
  failure: error => ({ type: CREATE_REST_FAILED, error }),

  fetchAPI: (id, options) => callAPI('/rests', { method: 'POST', ...options })
}

export function* doCreateRest({ restData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createRestSagaEntity,
    null,
    null,
    restData,
    resolve,
    reject
  )
}

const updateRestSagaEntity = {
  success: (data, restId) => ({ type: UPDATE_REST_SUCCEEDED, data, restId }),
  failure: (error, restId) => ({ type: UPDATE_REST_FAILED, error, restId }),

  fetchAPI: (restId, options) =>
    callAPI(`/rests/${restId}`, { method: 'PUT', ...options })
}

export function* doUpdateRest({ restId, restData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    updateRestSagaEntity,
    restId,
    null,
    restData,
    resolve,
    reject
  )
}

const deleteRestSagaEntity = {
  success: (data, restId) => ({ type: DELETE_REST_SUCCEEDED, data, restId }),
  failure: (error, restId) => ({ type: DELETE_REST_FAILED, error, restId }),

  fetchAPI: (restId, options) =>
    callAPI(`/rests/${restId}`, { method: 'DELETE', ...options })
}

export function* doDeleteRest({ restId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    deleteRestSagaEntity,
    restId,
    null,
    null,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const Rest = Immutable.Record({
  id: null,
  custom_value: null,
  user_contract_id: null,
  original_shift_id: null,
  timeoff_id: null,
  rest_type: null,
  duration_type: null,
  note: null,
  starts_at: null,
  ends_at: null,
  locked_at: null,
  rest_value_updated_by: null,
  rest_value_updated_at: null,
  rest_value_updated_by_id: null,
  accident_date: null
})

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

const restForeignKeys = ['original_shift_id', 'user_contract_id']

function rests(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case DELETE_REST:
    case FETCH_REST:
    case FETCH_RESTS: {
      // @ts-ignore migration from js(x) to ts(x)
      return action.params?.silent === true
        ? state
        : // @ts-ignore migration from js(x) to ts(x)
          state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_REST_FAILED:
    case FETCH_RESTS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_RESTS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const rests = Immutable.fromJS(action.data)

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Rest, rests, restForeignKeys).mergeDeepIn(
        ['meta'],
        {
          loading: false,
          updated_at: Date.now(),
          success: true
        }
      )
    }

    case FETCH_REST_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const rests = Immutable.fromJS([action.data])

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Rest, rests, restForeignKeys).mergeDeepIn(
        ['meta'],
        {
          loading: false,
          updated_at: Date.now(),
          success: true
        }
      )
    }

    case DELETE_REST_FAILED: {
      const {
        // @ts-ignore migration from js(x) to ts(x)
        error: {
          error: { type }
        },
        // @ts-ignore migration from js(x) to ts(x)
        restId
      } = action

      // Handle already deleted rests.
      const isAlreadyDeleted = type === NOT_FOUND
      if (isAlreadyDeleted) {
        // @ts-ignore migration from js(x) to ts(x)
        return deleteRecord(state, restId, restForeignKeys)
      }

      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case CREATE_REST_SUCCEEDED:
    case UPDATE_REST_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const rest = Immutable.fromJS(action.data)
      // @ts-ignore migration from js(x) to ts(x)
      return updateRecord(state, Rest, rest, restForeignKeys)
    }
    case DELETE_REST_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { restId } = action
      // @ts-ignore migration from js(x) to ts(x)
      return deleteRecord(state, restId, restForeignKeys)
    }
    case DELETE_CONTRACT_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { contractId } = action
      const restsIds =
        state.getIn(['relations', 'user_contract_id', contractId]) ||
        Immutable.Set()

      return state
        .deleteIn(['relations', 'user_contract_id', contractId])
        .withMutations(store => {
          restsIds.forEach(restId => {
            store.deleteIn(['data', restId])
          })

          if (store.getIn(['relations', 'original_shift_id'])) {
            store.updateIn(['relations', 'original_shift_id'], shiftMap =>
              shiftMap.map(itemIds => itemIds.subtract(restsIds))
            )
          }
        })
    }
    case DELETE_RESTS_FROM_STORE: {
      // @ts-ignore migration from js(x) to ts(x)
      const restsToDelete = action.rests
      return state.withMutations(store => {
        restsToDelete.forEach(rest =>
          // @ts-ignore migration from js(x) to ts(x)
          deleteRecord(store, rest.id, restForeignKeys)
        )
      })
    }
    default:
      return state
  }
}

export default filterActions(rests, [
  FETCH_RESTS,
  FETCH_RESTS_FAILED,
  FETCH_RESTS_SUCCEEDED,
  FETCH_REST,
  FETCH_REST_FAILED,
  FETCH_REST_SUCCEEDED,
  CREATE_REST_SUCCEEDED,
  UPDATE_REST_SUCCEEDED,
  DELETE_REST_SUCCEEDED,
  DELETE_CONTRACT_SUCCEEDED,
  DELETE_RESTS_FROM_STORE
])
