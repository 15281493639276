export const badgeCompoundClasses = [
  {
    variant: 'light',
    color: 'neutral',
    className: 'bg-neutral-100 text-neutral-900'
  },
  {
    variant: 'outlined',
    color: 'neutral',
    className: 'border-neutral-100 text-neutral-900'
  },
  {
    variant: 'plain',
    color: 'neutral',
    className: 'bg-neutral-400 text-neutral-50'
  },
  {
    variant: 'light',
    color: 'semantic.blue',
    className: 'bg-semantic-blue-100 text-semantic-blue-900'
  },
  {
    variant: 'outlined',
    color: 'semantic.blue',
    className: 'border-semantic-blue-100 text-semantic-blue-900'
  },
  {
    variant: 'plain',
    color: 'semantic.blue',
    className: 'bg-semantic-blue-400 text-semantic-blue-50'
  },
  {
    variant: 'light',
    color: 'semantic.green',
    className: 'bg-semantic-green-100 text-semantic-green-900'
  },
  {
    variant: 'outlined',
    color: 'semantic.green',
    className: 'border-semantic-green-100 text-semantic-green-900'
  },
  {
    variant: 'plain',
    color: 'semantic.green',
    className: 'bg-semantic-green-400 text-semantic-green-50'
  },
  {
    variant: 'light',
    color: 'semantic.red',
    className: 'bg-semantic-red-100 text-semantic-red-900'
  },
  {
    variant: 'outlined',
    color: 'semantic.red',
    className: 'border-semantic-red-100 text-semantic-red-900'
  },
  {
    variant: 'plain',
    color: 'semantic.red',
    className: 'bg-semantic-red-400 text-semantic-red-50'
  },
  {
    variant: 'light',
    color: 'semantic.orange',
    className: 'bg-semantic-orange-100 text-semantic-orange-900'
  },
  {
    variant: 'outlined',
    color: 'semantic.orange',
    className: 'border-semantic-orange-100 text-semantic-orange-900'
  },
  {
    variant: 'plain',
    color: 'semantic.orange',
    className: 'bg-semantic-orange-400 text-semantic-orange-50'
  },
  {
    variant: 'light',
    color: 'secondary.blue',
    className: 'bg-secondary-blue-100 text-secondary-blue-900'
  },
  {
    variant: 'outlined',
    color: 'secondary.blue',
    className: 'border-secondary-blue-100 text-secondary-blue-900'
  },
  {
    variant: 'plain',
    color: 'secondary.blue',
    className: 'bg-secondary-blue-400 text-secondary-blue-50'
  },
  {
    variant: 'light',
    color: 'secondary.coral',
    className: 'bg-secondary-coral-100 text-secondary-coral-900'
  },
  {
    variant: 'outlined',
    color: 'secondary.coral',
    className: 'border-secondary-coral-100 text-secondary-coral-900'
  },
  {
    variant: 'plain',
    color: 'secondary.coral',
    className: 'bg-secondary-coral-400 text-secondary-coral-50'
  },
  {
    variant: 'light',
    color: 'secondary.green',
    className: 'bg-secondary-green-100 text-secondary-green-900'
  },
  {
    variant: 'outlined',
    color: 'secondary.green',
    className: 'border-secondary-green-100 text-secondary-green-900'
  },
  {
    variant: 'plain',
    color: 'secondary.green',
    className: 'bg-secondary-green-400 text-secondary-green-50'
  },
  {
    variant: 'light',
    color: 'secondary.orange',
    className: 'bg-secondary-orange-100 text-secondary-orange-900'
  },
  {
    variant: 'outlined',
    color: 'secondary.orange',
    className: 'border-secondary-orange-100 text-secondary-orange-900'
  },
  {
    variant: 'plain',
    color: 'secondary.orange',
    className: 'bg-secondary-orange-400 text-secondary-orange-50'
  },
  {
    variant: 'light',
    color: 'secondary.purple',
    className: 'bg-secondary-purple-100 text-secondary-purple-900'
  },
  {
    variant: 'outlined',
    color: 'secondary.purple',
    className: 'border-secondary-purple-100 text-secondary-purple-900'
  },
  {
    variant: 'plain',
    color: 'secondary.purple',
    className: 'bg-secondary-purple-400 text-secondary-purple-50'
  }
] as const
