import { useUncontrolled } from '@libs/ui/ds'
import { useEffect } from 'react'

import type { SelectOption, SelectProps } from '../Select.types'

type Params = {
  options: SelectProps['options']
  select: {
    value: SelectProps['value']
    defaultValue: SelectProps['defaultValue']
    onChange: SelectProps['onChange']
  }
  search: {
    onSearchChange: SelectProps['onSearchChange']
    searchValue: SelectProps['searchValue']
  }
}

export const useSelectUncontrolledValues = ({
  select,
  search,
  options
}: Params) => {
  const [searchValue, setSearchValue] = useUncontrolled({
    defaultValue: '',
    onChange: search.onSearchChange,
    value: search.searchValue
  })
  const [selectValue, setSelectValue] = useUncontrolled<string>(select)
  const [selectedOption, setSelectedOption] = useUncontrolled<SelectOption>({})

  const resetSelectValue = () => {
    setSelectedOption(null as any)
    setSearchValue('')
    setSelectValue(null as any)
  }

  useEffect(() => {
    if (selectValue) {
      const newOption = options.find(i => i.value === selectValue)
      if (newOption) {
        setSelectedOption(newOption)
        setSearchValue(newOption.label)
      }
    }

    if (selectValue === null) {
      resetSelectValue()
    }
  }, [selectValue])

  return {
    search: {
      searchValue,
      setSearchValue,
      resetSearchValue: () => setSearchValue('')
    },
    select: {
      selectValue,
      setSelectValue,
      resetSelectValue
    },
    selectedOption
  }
}
