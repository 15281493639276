import { cva } from 'class-variance-authority'

export const tableClasses = {
  /**
   * rows
   */
  headerRow: cva(['z-[1] h-10 bg-neutral-100'])(),
  bodyRow: cva(['h-14'])(),

  /**
   * cells
   */
  headerCell: cva(['first:rounded-l-full last:rounded-r-full'])(),
  cell: cva([
    'h-full px-2 py-3 text-left',
    'md:px-3 md:py-2 md:first:pl-6 md:last:pr-6'
  ])(),
  smallCell: cva([
    'h-full p-2 text-left',
    'md:p-2 md:first:pl-4 md:last:pr-4'
  ])()
}
