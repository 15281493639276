import type { NewSubscriptionSchema } from '@libs/data-access/entities'
import { notifications } from '@libs/ui/ds'
import i18n from '@libs/utils/translations'
import { put, select } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'
import { getCurrentAccountId } from 'snap-redux/modules/accounts/selectors'

import { updateSubscription } from '../modules/entities'
import { setMetaError, setMetaLoading, setMetaSuccess } from '../modules/meta'

export default function* createSubscriptionPayConnectorActivated(
  chargebeeAddonId: string
) {
  yield put(setMetaLoading())
  const accountId = yield select(getCurrentAccountId)

  try {
    const promise = apiRequest({
      service: 'billing',
      route: 'putSubscriptionPayConnector',
      queryParams: {
        account_id: accountId,
        include: ['location_configurations', 'addons', 'invoices']
      },
      body: {
        pay_connector_activated: true,
        addon_ids: [chargebeeAddonId]
      }
    })

    notifications.promise(promise, {
      loading: {
        message: i18n.t<any>(
          'common.notifications:subscription.general.loading'
        )
      },
      success: {
        message: i18n.t<any>(
          'common.notifications:subscription.general.success'
        )
      },
      error: {
        message: i18n.t<any>('common.notifications:subscription.general.error')
      }
    })

    const result: NewSubscriptionSchema = yield promise

    yield put(updateSubscription({ ...result }))
    yield put(setMetaSuccess())
  } catch (err) {
    yield put(setMetaError())
  }
}
