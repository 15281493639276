import { ArrowRightIcon } from '@libs/ui/ds/assets/icons'
import { Flex } from '@libs/ui/ds/lib/components/primitiveLayouts/Flex/base/Flex'
import { Accordion } from '@mantine/core'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'

import { IconButton } from '../../../../../buttons/IconButton/base/IconButton'
import { Text } from '../../../../../typography/Text/base/Text'
import { ThemeIcon } from '../../../../ThemeIcon/base/ThemeIcon'

import type { AccordionControlProps } from './AccordionControl.types'

const accordionControlCVA = {
  control: cva([
    'bg-neutral-50 hover:bg-neutral-50',
    'flex w-full min-w-0 flex-row-reverse items-center justify-between gap-4 p-6'
  ]),
  container: cva(['flex-nowrap justify-between gap-4']),
  leftContainer: cva(['flex min-w-0 grow flex-nowrap items-center gap-4']),
  titleContainer: cva(['flex flex-col items-start gap-1']),
  title: cva(['w-full font-sans text-s  !font-bold']),
  subtitle: cva(['w-full  font-sans text-s !font-normal !text-neutral-500']),
  rightSection: cva(['flex items-center truncate text-s'])
}

export const AccordionControl: FC<AccordionControlProps> = ({
  title,
  className,
  leftSection,
  leftIcon,
  subTitle,
  rightSection,
  disabled,
  ...other
}) => {
  return (
    <Accordion.Control
      {...other}
      disabled={disabled}
      className={accordionControlCVA.control({ className })}
      chevron={
        <IconButton
          disabled={disabled}
          icon={<ArrowRightIcon />}
          variant='secondary'
          intent='neutral-dark'
          size='s'
          component='div'
        />
      }
    >
      <Flex row className={accordionControlCVA.container()}>
        <Flex row className={accordionControlCVA.leftContainer()}>
          {leftSection && <div>{leftSection}</div>}
          {leftIcon && <ThemeIcon icon={leftIcon} color='neutral' size='m' />}
          <div className={accordionControlCVA.titleContainer()}>
            <Text size='m' className={accordionControlCVA.title()}>
              {title}
            </Text>
            {subTitle && (
              <Text size='m' className={accordionControlCVA.subtitle()}>
                {subTitle}
              </Text>
            )}
          </div>
        </Flex>
        {rightSection && (
          <div
            className={accordionControlCVA.rightSection()}
            onClick={e => e.stopPropagation()}
          >
            {rightSection}
          </div>
        )}
      </Flex>
    </Accordion.Control>
  )
}
