import { useAtom } from 'jotai'

import { atomWithLocalStorage } from '../../../../services'

export type PayslipDismiss = {
  memberAdvertDismissed: boolean
  payrollReportAdvertDismissed: boolean
}

export type PayslipDismissKey = keyof PayslipDismiss

const dismissAtom = atomWithLocalStorage<PayslipDismiss>('payslip-dismiss', {
  memberAdvertDismissed: false,
  payrollReportAdvertDismissed: false
})

export const usePayslipDismiss = () => {
  const [data, setData] = useAtom(dismissAtom)
  const dismissOne = (name: PayslipDismissKey) => {
    setData({
      ...data,
      [name]: true
    })
  }

  return {
    data,
    dismissOne
  }
}
