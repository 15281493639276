import { Flex } from '@libs/ui/ds'
import { useInitGTM } from '@spa/core/externals/gtm'
import { useInitRecaptcha } from '@spa/core/externals/recaptcha'
import { trackerService } from '@spa/core/externals/segment'
import { AuthNavbar } from '@spa/scenes/Auth/AuthNavbarLegacy/AuthNavbar'
import React from 'react'

import { UnauthenticatedAppRoutes } from './UnauthenticatedApp.routes'

export const UnauthenticatedApp = () => {
  trackerService.useInitSegment()
  trackerService.useSegmentPageView()
  useInitGTM()
  useInitRecaptcha()

  return (
    <Flex className='h-screen !flex-nowrap'>
      <AuthNavbar />
      <Flex className='grow !flex-nowrap'>
        <UnauthenticatedAppRoutes />
      </Flex>
    </Flex>
  )
}
