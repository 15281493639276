import { cva } from 'class-variance-authority'
import type { FC } from 'react'

import { Flex } from '../../../primitiveLayouts/Flex/base/Flex'
import { Avatar } from '../base/Avatar'

import type { AvatarLabelProps } from './AvatarLabel.types'

const avatarLabelCVA = {
  root: cva(['flex-nowrap items-center gap-2 text-s']),
  text: cva('truncate', {
    variants: {
      variant: {
        inherits: '',
        primary: 'text-neutral-800'
      },
      size: {
        s: 'text-xs',
        m: 'text-s',
        l: 'text-m'
      }
    }
  })
}

export const AvatarLabel: FC<AvatarLabelProps> = ({
  className,
  variant = 'primary',
  style,
  labels,
  ...other
}) => {
  return (
    <Flex row style={style} className={avatarLabelCVA.root({ className })}>
      <Avatar labels={labels} {...other} />
      {labels && (
        <span className={avatarLabelCVA.text({ variant, size: other.size })}>
          {labels.join(' ')}
        </span>
      )}
    </Flex>
  )
}
