import type { ReduxState } from '@spa/redux/ReduxState'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'

import { getContractsByTeamId } from '../../contracts/getContractsByTeamId/getContractsByTeamId'
import { membershipsBaseSelectors } from '../membershipsBaseSelectors'

type Params = {
  teamId: number
}

export const getMembershipsByTeamId = createCachedSelector(
  (state: ReduxState, { teamId }: Params) =>
    getContractsByTeamId(state, { teamId }),
  membershipsBaseSelectors.getCachedList,

  (contracts, memberships) => {
    return memberships.filter(member => {
      return contracts.find(contract => contract.membership_id === member.id)
    })
  }
)((_, { teamId }) => fallbackToDefaultCacheKey(teamId))
