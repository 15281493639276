export const AnalyticsBoardBarsIcon = () => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.83802 2.79482C1.74649 2.90576 1.6701 3.08638 1.6701 3.31336V16.6492C1.6701 16.8762 1.74649 17.0568 1.83802 17.1678C1.92811 17.277 2.01093 17.2994 2.06095 17.2994H17.9528C18.0029 17.2994 18.0857 17.277 18.1758 17.1678C18.2673 17.0568 18.3437 16.8762 18.3437 16.6492V3.31336C18.3437 3.08638 18.2673 2.90576 18.1758 2.79482C18.0857 2.68562 18.0029 2.6632 17.9528 2.6632H2.06095C2.01093 2.6632 1.92811 2.68562 1.83802 2.79482ZM0.00689697 3.31336C0.00689697 2.19257 0.784011 1 2.06095 1H17.9528C19.2298 1 20.0069 2.19257 20.0069 3.31336V16.6492C20.0069 17.77 19.2298 18.9626 17.9528 18.9626H2.06095C0.784011 18.9626 0.00689697 17.77 0.00689697 16.6492V3.31336Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.18571 10.7381C4.64499 10.7381 5.01731 11.1104 5.01731 11.5697V13.0998C5.01731 13.5591 4.64499 13.9314 4.18571 13.9314C3.72643 13.9314 3.35411 13.5591 3.35411 13.0998V11.5697C3.35411 11.1104 3.72643 10.7381 4.18571 10.7381Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.09631 7.50313C7.55559 7.50313 7.92791 7.87545 7.92791 8.33473V13.0998C7.92791 13.5591 7.55559 13.9314 7.09631 13.9314C6.63703 13.9314 6.26471 13.5591 6.26471 13.0998V8.33473C6.26471 7.87545 6.63703 7.50313 7.09631 7.50313Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0069 8.526C10.4662 8.526 10.8385 8.89832 10.8385 9.3576V13.0998C10.8385 13.5591 10.4662 13.9314 10.0069 13.9314C9.54764 13.9314 9.17532 13.5591 9.17532 13.0998V9.3576C9.17532 8.89832 9.54764 8.526 10.0069 8.526Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9175 10.3971C13.3768 10.3971 13.7491 10.7694 13.7491 11.2287V13.0998C13.7491 13.5591 13.3768 13.9314 12.9175 13.9314C12.4582 13.9314 12.0859 13.5591 12.0859 13.0998V11.2287C12.0859 10.7694 12.4582 10.3971 12.9175 10.3971Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.8281 6.6549C16.2874 6.6549 16.6597 7.02722 16.6597 7.4865V13.0998C16.6597 13.5591 16.2874 13.9314 15.8281 13.9314C15.3688 13.9314 14.9965 13.5591 14.9965 13.0998V7.4865C14.9965 7.02722 15.3688 6.6549 15.8281 6.6549Z'
        fill='currentColor'
      />
    </svg>
  )
}
