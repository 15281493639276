import type { AccountDetails } from '@libs/data-access/entities'
import { httpServices } from '@libs/data-access/queries'
import { APP_NAMESPACE } from '@libs/utils/environments'

import type { QueryFnParams } from '../../../types'

import { fallbackAccountDetailsData } from './__mocks__/accountDetails.mock'

export type FetchAccountDetailsOptions = QueryFnParams<{
  pathParams: {
    id: string
  }
}>

/**
 * Fetch account details
 */
export const fetchAccountDetails = async () => {
  try {
    const response = await httpServices.billing.get<AccountDetails>(
      `/accounts/${httpServices.authInfo.accountId}`
    )
    return response.data
  } catch (error) {
    if (APP_NAMESPACE !== 'production') {
      return fallbackAccountDetailsData
    }

    throw error
  }
}
