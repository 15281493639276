export const HouseIcon = () => {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.45261 1.18083C9.77663 0.936468 10.2234 0.936468 10.5474 1.18083L19.3336 7.80709C19.358 7.82543 19.3813 7.84498 19.4037 7.86566C19.758 8.1935 19.9712 8.64602 19.9985 9.12794C19.9995 9.14507 20 9.16222 20 9.17938V19.2982L20 19.3009C19.9986 19.7505 19.8194 20.1812 19.5016 20.4991C19.1837 20.817 18.7529 20.9962 18.3034 20.9976L18.3006 20.9976H1.69939L1.69662 20.9976C1.24707 20.9962 0.816328 20.817 0.498447 20.4991C0.180567 20.1812 0.00137693 19.7505 4.23193e-06 19.3009L0 19.2982V9.17938C0 9.16222 0.000485659 9.14507 0.00145644 9.12794C0.0287685 8.64602 0.242018 8.1935 0.596304 7.86566C0.618657 7.84498 0.642036 7.82543 0.666351 7.80709L9.45261 1.18083ZM1.81818 9.22171V19.1794H18.1818V9.22171C18.181 9.21881 18.18 9.216 18.1787 9.21332L10 3.04529L1.82134 9.21332C1.82001 9.216 1.81896 9.21881 1.81818 9.22171Z'
        fill='currentColor'
      />
    </svg>
  )
}
