import { Anchor, Flex, Image, imagesUrls } from '@libs/ui/ds'
import { useMarketingWebsite } from '@spa/core/externals/marketing/hooks/useMarketingWebsite'
import { cva } from 'class-variance-authority'
import classNames from 'classnames'
import { type FC } from 'react'

import { AuthNavbarAddons } from './AuthNavbarAddons'

const authNavbarCVA = {
  root: cva([
    'w-full items-center justify-between gap-4 bg-neutral-50 px-4 py-6 sm:px-8'
  ]),
  alreadyAnAccount: cva(['hidden text-center text-s text-neutral-600 sm:block'])
}

type AuthNavbarType = {
  className?: string
}

export const AuthNavbar: FC<AuthNavbarType> = ({ className }) => {
  const marketingURL = useMarketingWebsite()

  return (
    <Flex row className={classNames(className, authNavbarCVA.root())}>
      <Anchor href={marketingURL} className='flex justify-center'>
        <Image
          data-testid='auth-nav-company-logo-image'
          className='!w-20 hover:opacity-75 sm:!w-24'
          src={imagesUrls.logo.company.comboLogoBlack}
        />
      </Anchor>

      <AuthNavbarAddons />
    </Flex>
  )
}
