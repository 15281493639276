import type { FormatterParams } from '../../types'

import { trimHours } from './helpers/trimHours'
import { displayTwoNumber } from './minuteDecimalToHours'

/**
 * Transform decimal seconds to readable hours:minutes
 *
 * Input: 1835
 * Output: 0h31
 *
 * Input: 60
 * Output: 0h01
 *
 * Input: 0
 * Output: 0h
 *
 * Input: -99999
 * Output: -27h47
 */

export const secondsDecimalToHours = ({ value }: FormatterParams) => {
  const separator = 'h'
  const signBeforeNumber = Math.sign(value) === -1 ? '-' : ''
  const totalMinutes = value / 60
  const roundedMinutes = Math.abs(Math.round(totalMinutes))
  const hours = Math.floor(roundedMinutes / 60)
  const minutes = roundedMinutes % 60

  const hourFormat = `${displayTwoNumber(hours)}${separator}${displayTwoNumber(
    minutes
  )}`

  const trimHourFormat = trimHours(hourFormat)
  return `${signBeforeNumber}${trimHourFormat}`
}

export default {
  format: secondsDecimalToHours
}
