import type { FormatterParams } from '../../types'

/**
 * Convert a minute duration into a minute readable string
 *
 * Input 125
 * Output 125'
 */
export function minuteDuration({ value }: FormatterParams) {
  return `${value}mn`
}

export default {
  format: minuteDuration
}
