export const ArrowLeftIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.3227 0.011294C13.1948 0.035662 13.0894 0.081958 12.9877 0.158326C12.9462 0.189582 11.3814 1.74969 8.48348 4.6491C3.62141 9.51374 3.99282 9.13455 3.88501 9.34397C3.77292 9.56172 3.7263 9.75439 3.7263 9.99997C3.7263 10.2456 3.77292 10.4382 3.88501 10.656C3.99282 10.8654 3.62087 10.4857 8.48748 15.3544C10.932 17.7999 12.9554 19.8183 12.984 19.8396C13.1651 19.9744 13.3874 20.0269 13.6115 19.9878C13.7889 19.9569 13.9309 19.8788 14.064 19.739C14.1944 19.6021 14.2589 19.4619 14.2807 19.2685C14.3033 19.0672 14.2456 18.8566 14.1234 18.694C14.1027 18.6665 12.1413 16.6991 9.76489 14.322L5.44409 9.99997L9.76489 5.67797C12.1413 3.30087 14.1027 1.33345 14.1234 1.30592C14.3379 1.02077 14.3388 0.60691 14.1256 0.33003C14.0787 0.26915 13.9755 0.171526 13.9175 0.133222C13.8487 0.087686 13.7471 0.04247 13.6712 0.02355C13.5842 0.00189403 13.4051 -0.00440197 13.3227 0.011294Z'
        fill='currentColor'
      />
    </svg>
  )
}
