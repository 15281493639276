import { membershipService } from './Membership/membershipService'
import { useFetchCurrentUser } from './User/hooks/useFetchCurrentUser/useFetchCurrentUser'
import { useFetchOne } from './User/hooks/useFetchOne'
import { userInvalidations } from './User/user.cacheKeys'

export type { FetchUserOptions } from './User/user.api'

class UserService {
  membership = membershipService

  user = {
    useFetchCurrent: useFetchCurrentUser,
    invalidateCurrent: userInvalidations.invalidateCurrent,
    invalidateOne: userInvalidations.invalidateOne,
    useFetchOne
  }
}

export const userService = new UserService()
