import type { ParamsUpdateLocation } from '@libs/data-access/entities'
import { notifications } from '@libs/ui/ds'
import i18n from '@libs/utils/translations'
import { put, select } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'
import { getCurrentAccountId } from 'snap-redux/modules/accounts/selectors'

import { updateLocationBilling } from '../modules/entities'
import { setMetaError, setMetaLoading, setMetaSuccess } from '../modules/meta'

export default function* updateLocationBillingData(
  params: ParamsUpdateLocation
) {
  yield put(setMetaLoading())
  const accountId = yield select(getCurrentAccountId)

  try {
    const promise = apiRequest({
      service: 'billing',
      route: 'putLocationBilling',
      queryParams: {
        account_id: accountId
      },
      body: params
    })

    notifications.promise(promise, {
      loading: {
        message: i18n.t<any>(
          'common.notifications:subscription.general.loading'
        )
      },
      success: {
        message: i18n.t<any>(
          'common.notifications:subscription.general.success'
        )
      },
      error: {
        message: i18n.t<any>('common.notifications:subscription.general.error')
      }
    })

    const result = yield promise

    yield put(updateLocationBilling([result]))
    yield put(setMetaSuccess())
  } catch (err) {
    yield put(setMetaError())
  }
}
