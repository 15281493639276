import { Button, type ButtonProps } from '@libs/ui/ds/lib/components'
import { createButton } from '@libs/ui/ds/lib/components/buttons/BaseButton/utils/createButton'
import React, { forwardRef } from 'react'

type Props = ButtonProps

export const ModalFooterSuccessButton = createButton<Props>(
  forwardRef<HTMLButtonElement, Props>(({ children, ...other }, ref) => {
    return (
      <Button
        ref={ref}
        {...other}
        intent='default'
        variant={other?.variant ? other.variant : 'primary'}
        size='m'
      >
        {children}
      </Button>
    )
  })
)
