import { CloseIcon, Badge, Flex } from '@libs/ui/ds'
import { cva } from 'class-variance-authority'
import React from 'react'

const chipListCVA = {
  root: cva('min-w-0 grow'),
  wrap: cva('max-h-20 flex-wrap gap-0.5 overflow-y-auto py-1'),
  chip: cva(
    [
      'cursor-pointer !bg-neutral-200 hover:!bg-neutral-300 active:!bg-neutral-400'
    ],
    {
      variants: {
        disabled: {
          true: '!cursor-default hover:!bg-neutral-200 active:!bg-neutral-200'
        }
      }
    }
  )
}

export const ChipList = ({ removeSelectValue, selectedOptions, disabled }) => {
  if (!selectedOptions.length) {
    return null
  }
  return (
    <Flex className={chipListCVA.root()}>
      <Flex row className={chipListCVA.wrap()}>
        {selectedOptions.map(i => (
          <Badge
            className={chipListCVA.chip({ disabled })}
            key={i.value}
            size='m'
            variant={i.badgeVariant || 'light'}
            color={i.badgeColor || 'neutral'}
            label={i.label}
            rightIcon={!disabled && <CloseIcon />}
            onClick={e => {
              e.stopPropagation()
              if (!disabled) {
                removeSelectValue(i.value)
              }
            }}
          />
        ))}
      </Flex>
    </Flex>
  )
}
