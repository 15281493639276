import { useMutation } from '@tanstack/react-query'

import type {
  ApiErrorBillingResponse,
  UseAppMutationOptions
} from '../../../../types'
import { invalidateAccountStatusCache } from '../../AccountStatus/accountStatus.cacheKeys'
import type { PutLocationGroupUpdateBillingInfoOptions } from '../locationGroupBillingInfo.api'
import { putLocationGroupUpdateBillingInfo } from '../locationGroupBillingInfo.api'
import {
  invalidateLocationGroupBillingInfoCache,
  locationGroupBillingInfoCacheKeys
} from '../locationGroupBillingInfo.cacheKeys'

export const useUpdateLocationGroupBillingInfo = (
  mutationOptions?: UseAppMutationOptions<
    {},
    ApiErrorBillingResponse,
    PutLocationGroupUpdateBillingInfoOptions
  >
) => {
  return useMutation<
    {},
    ApiErrorBillingResponse,
    PutLocationGroupUpdateBillingInfoOptions
  >({
    ...mutationOptions,
    mutationKey: locationGroupBillingInfoCacheKeys.updateBillingInfo(),
    mutationFn: (requestOptions: PutLocationGroupUpdateBillingInfoOptions) =>
      putLocationGroupUpdateBillingInfo(requestOptions),
    onSuccess: (data, variables, ...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, ...args)
      }

      invalidateAccountStatusCache()
      invalidateLocationGroupBillingInfoCache(variables)
    }
  })
}
