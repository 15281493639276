import { CheckIcon, IconWrap } from '@libs/ui/ds'
import { FieldError } from '@spa/components/FormFields'
import withController from '@spa/hoc/withController'
import { toKebabCase } from '@spa/services/toKebabCase'
import { cva } from 'class-variance-authority'
import classnames from 'classnames'
import React from 'react'
import type { FC } from 'react'

import styles from './FieldCheckbox.module.css'

const sizes = ['S', 'M'] as const

type Size = (typeof sizes)[number]
const defaultSize: Size = 'M'

type Value = boolean

export type FieldCheckboxProps = {
  checked?: boolean
  disabled?: boolean
  error?: string
  inputId?: string
  inputName?: string
  onChange?(e: boolean): void
  value?: string
  size?: Size
}

const oldCheckboxCVA = {
  icon: cva(['text-semantic-green-500'], {
    variants: {
      checked: {
        false: 'opacity-0'
      }
    }
  })
}

export const FieldCheckbox: FC<FieldCheckboxProps> = ({
  checked = false,
  disabled = false,
  error,
  inputId,
  inputName,
  onChange,
  value,
  size = defaultSize
}) => (
  <div>
    <label className={classnames({ [styles.disabled]: disabled })}>
      <input
        id={inputId || inputName}
        type='checkbox'
        checked={checked}
        value={value}
        name={inputName}
        className={styles.Checkbox}
        disabled={disabled}
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        onChange={e => onChange(e.target.checked)}
        data-testid={`field-checkbox-${toKebabCase(
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          inputId?.toString() || inputName
        )}`}
      />
      <div
        className={classnames(styles.IconContainer, {
          [styles.SmallCheckbox]: size === 'S',
          [styles.DisabledCheckbox]: disabled
        })}
      >
        <IconWrap
          icon={<CheckIcon />}
          className={oldCheckboxCVA.icon({ checked })}
          size={size === 'S' ? 'xs' : 's'}
        />
      </div>
    </label>
    {error && <FieldError error={error} />}
  </div>
)

export const ControlledFieldCheckbox = withController<
  FieldCheckboxProps,
  Value
>({ valueName: 'checked' })(FieldCheckbox)
