import { useMutation } from '@tanstack/react-query'

import type { UseAppMutationOptions, ApiErrorResponse } from '../../../types'
import type { PutUserInvitationOptions } from '../auth.api'
import { putUserInvitation } from '../auth.api'
import { authCacheKeys } from '../auth.cacheKeys'

export const usePutUserInvitation = (
  mutationOptions?: UseAppMutationOptions<
    null,
    ApiErrorResponse,
    PutUserInvitationOptions
  >
) => {
  return useMutation({
    ...mutationOptions,
    mutationKey: authCacheKeys.putUserInvitation(),
    mutationFn: (opts: PutUserInvitationOptions) => putUserInvitation(opts),

    onSuccess: (data, variables, ...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, ...args)
      }
    }
  })
}
