import { errorLoggerService } from '@libs/utils/error-monitoring'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import type { ParamsFetchPaidLeaveLedgerOps } from '../modules/entities'
import { setPaidLeaveLegder } from '../modules/entities'
import { setMetaLoading, setMetaSuccess, setMetaError } from '../modules/meta'
import { setPagination } from '../modules/pagination'

export default function* fetchPaidLeaveLedgerOpsData(
  params: ParamsFetchPaidLeaveLedgerOps
) {
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  yield put(setMetaLoading())

  try {
    const result = yield apiRequest({
      route: 'getPaidLeaveLedgerOp',
      params: {
        location_id: params.locationId
      },
      queryParams: {
        paid_leave_acquisition_period_ids:
          params.paid_leave_acquisition_period_ids,
        user_contract_ids: params.user_contract_ids,
        per_page: params?.perPage || 20,
        page: params?.page || 1,
        ...(params?.only_total && { only_total: params.only_total })
      }
    })

    yield put(setPaidLeaveLegder(result.resources))
    yield put(setPagination(result.meta))
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    yield put(setMetaSuccess())
  } catch (err) {
    errorLoggerService.apiError(err)
    yield put(setMetaError({ rawError: err }))
  }
}
