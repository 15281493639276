import { useSafeRouterSearchParams, useUncontrolled } from '@libs/ui/ds'
import type { OnChangeFn, SortingState } from '@tanstack/react-table'
import { useMemo } from 'react'

export type SortingStateParams = {
  defaultValue?: SortingState
  value?: SortingState
  onSortingChange?: OnChangeFn<SortingState>
}

export const useSortingState = (
  { onSortingChange, value, defaultValue }: SortingStateParams = {},
  syncSearchParamsURL?: boolean
) => {
  const [searchParams, setSearchParams] = useSafeRouterSearchParams<{
    sort_by: string
    sort_direction: 'asc' | 'desc' | undefined
  }>()

  const [sortingState, setStortingState] = useUncontrolled<SortingState>({
    defaultValue: defaultValue || [],
    value,
    onChange: value => {
      if (onSortingChange) {
        onSortingChange(value)
      }
      return value
    }
  })

  const inSearchURLState = useMemo(() => {
    return {
      sorting: [
        {
          id: searchParams.sort_by,
          desc: searchParams.sort_direction === 'desc'
        }
      ] as SortingState,
      setSorting: ((newSorting: any) => {
        const [firstSorting] = newSorting()
        setSearchParams({
          ...searchParams,
          sort_by: firstSorting.id,
          sort_direction: firstSorting.desc ? 'desc' : 'asc'
        })
      }) as OnChangeFn<SortingState>
    }
  }, [searchParams])

  const inMemoryState = useMemo(() => {
    return {
      sorting: sortingState,
      setSorting: setStortingState as OnChangeFn<SortingState>
    }
  }, [sortingState, setStortingState])

  return syncSearchParamsURL ? inSearchURLState : inMemoryState
}
