import { QueryClient } from '@tanstack/react-query'

export const queryClientOptions = {
  time: {
    NONE: 0,
    ONE_SECOND: 1000,
    TEN_MINUTES: 1000 * 60 * 10,
    ONE_HOUR: 1000 * 60 * 60,
    INFINITY: Infinity
  },
  polling: {
    TWO_SECONDS: 2000,
    FIVE_SECONDS: 5000
  }
}

export const appQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: queryClientOptions.time.NONE,
      gcTime: queryClientOptions.time.TEN_MINUTES,
      refetchOnWindowFocus: false,
      retry: false
    }
  }
})
