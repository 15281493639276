import { useFetchFloatingBreaksWarning } from './Break/hooks/useFetchFloatingBreaksWarning'
import { useBulkDeleteShiftsList } from './hooks/useBulkDeleteShiftsList'
import { useBulkInvalidateShifts } from './hooks/useBulkInvalidateShifts'
import { useBulkValidateShifts } from './hooks/useBulkValidateShifts'
import * as shiftAPI from './shift.api'
import * as bulkAPI from './shift.bulk.api'

class ShiftService {
  api = shiftAPI

  bulk = {
    api: bulkAPI,

    useDeleteList: useBulkDeleteShiftsList,
    useInvalidate: useBulkInvalidateShifts,
    useValidate: useBulkValidateShifts
  }

  break = {
    useFetchFloatingBreaksWarning
  }
}

export const shiftService = new ShiftService()
export type {
  DeleteBulkShiftsResponse,
  InvertBulkShiftsResponse
} from './shift.bulk.api'
