import { useMutation } from '@tanstack/react-query'

import type { ApiErrorResponse, UseAppMutationOptions } from '../../../../types'
import { duplicateRest, type DuplicatePlannable } from '../monthly.api'
import { monthlyServiceCacheKeys } from '../monthly.cacheKeys'

export const useDuplicateRest = (
  mutationOpts?: UseAppMutationOptions<
    null,
    ApiErrorResponse,
    DuplicatePlannable
  >
) => {
  return useMutation<null, ApiErrorResponse, DuplicatePlannable>({
    ...mutationOpts,
    mutationKey: monthlyServiceCacheKeys.rest.duplicate(),
    mutationFn: (opts: DuplicatePlannable) => duplicateRest(opts)
  })
}
