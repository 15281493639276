import type { SubscriptionStatus } from '@libs/data-access/entities'
import { cookiesService } from '@libs/utils/cookies'
import { COOKIE_DOMAIN } from '@libs/utils/environments'

const setStatusCookie = (status: SubscriptionStatus) => {
  cookiesService.set(
    cookiesService.cookies.ACCOUNT_SUBSCRIPTION_STATUS,
    btoa(status),
    { domain: COOKIE_DOMAIN }
  )
}

export default {
  setStatusCookie
}
