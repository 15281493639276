import { errorLoggerService } from '@libs/utils/error-monitoring'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import { setPlan } from '../modules/entities'
import { setMetaError, setMetaLoading, setMetaSuccess } from '../modules/meta'

export default function* fetchBillingPlan() {
  yield put(setMetaLoading())

  try {
    const result = yield apiRequest({
      service: 'billing',
      route: 'getBillingPlan'
    })

    yield put(setPlan([...result]))
    yield put(setMetaSuccess())
  } catch (err) {
    errorLoggerService.apiError(err)
    yield put(setMetaError())
  }
}
