import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import { memo, useMemo } from 'react'

import { Avatar } from '../../Avatar/base/Avatar'
import { sortAvatarByLabel } from '../utils/sortAvatarByLabel'

import type { AvatarGroupProps } from './AvatarGroup.types'

const avatarGroupCVA = cva(
  [
    `box-border inline-flex items-center justify-center [&>*]:border-solid [&>*]:border-neutral-50`
  ],
  {
    variants: {
      size: {
        s: '[&>*]:border-1 [&>:not(:first-child)]:-ml-1',
        m: '[&>*]:border-2 [&>:not(:first-child)]:-ml-1.5',
        l: '[&>*]:border-2 [&>:not(:first-child)]:-ml-2'
      }
    }
  }
)

const additionalItemCVA = cva(
  [
    'z-10 inline-flex items-center justify-center bg-neutral-200 font-bold text-neutral-700'
  ],
  {
    variants: {
      size: {
        s: 'h-6 min-h-6 min-w-6 rounded px-1 text-xxs',
        m: 'h-8 min-h-8 min-w-8 rounded-md px-1 text-xxs',
        l: 'h-10 min-h-10 min-w-10 rounded-lg px-1 text-xs'
      }
    }
  }
)

// @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
const avatarGroupSortAdapter: Record<AvatarGroupProps['sort'], Function> = {
  'first-label': sortAvatarByLabel,
  none: items => items
}

export const AvatarGroup: FC<AvatarGroupProps> = memo(
  ({
    size = 'm',
    sort = 'first-label',
    items,
    maxItems = 3,
    className,
    ...other
  }) => {
    const itemsToDisplay = useMemo(() => {
      const sortedItems = avatarGroupSortAdapter[sort](items)
      return sortedItems.slice(0, maxItems)
    }, [items])

    const hiddenItemsCount = items.length - maxItems

    return (
      <div {...other} className={avatarGroupCVA({ className, size })}>
        {itemsToDisplay.map((item, key) => (
          <Avatar key={key} {...item} size={size} />
        ))}
        {hiddenItemsCount > 0 && (
          <div className={additionalItemCVA({ size })}>+{hiddenItemsCount}</div>
        )}
      </div>
    )
  }
)

AvatarGroup.displayName = 'AvatarGroup'
export type { AvatarGroupProps } from './AvatarGroup.types'
