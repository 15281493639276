import { formatToIsoDay } from '@spa/utils/date'
import moment from 'moment'
import { createSelector } from 'reselect'

import { getWeekDateParams } from '../../params'

/**
 * Return a list of ISO days contained in the current time range
 */
export const getIsoDaysInWeek = createSelector(
  getWeekDateParams,
  ({ week }): string[] => {
    const currentDate = moment(week.start)
    const isoDaysArr = []

    while (currentDate.isSameOrBefore(week.end)) {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      isoDaysArr.push(formatToIsoDay(currentDate))
      currentDate.add(1, 'day')
    }

    return isoDaysArr
  }
)
