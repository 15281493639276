import { useUncontrolled } from '@mantine/hooks'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import React from 'react'

import type { SearchInputExpandWrapProps } from './SearchInputExpandWrap.types'

const searchInputExpandWrapCVA = {
  root: cva(['transition-all duration-300'])
}

export const SearchInputExpandWrap: FC<SearchInputExpandWrapProps> = ({
  children,
  className,
  enabled = true,
  expanded,
  onExpandedChange,
  min,
  max
}) => {
  const [expandedState, setExpanded] = useUncontrolled({
    defaultValue: false,
    value: expanded,
    onChange: onExpandedChange
  })

  const expandedClassName = expandedState && enabled ? max : min
  return (
    <div
      onFocus={() => setExpanded(true)}
      onBlur={() => setExpanded(false)}
      className={[searchInputExpandWrapCVA.root({ className })]
        .concat(expandedClassName)
        .join(' ')}
    >
      {children}
    </div>
  )
}
