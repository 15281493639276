import { forwardRef, memo } from 'react'

import { InlineInputWrap } from '../../../forms/InlineInputWrap/base/InlineInputWrap'

import type { RadioButtonProps } from './RadioButton.types'
import { RadioButtonGroup } from './compound/RadioButtonGroup'
import { useMergedRadioButtonProps } from './compound/RadioButtonGroup.context'
import { RadioButtonInput } from './subs/RadioButtonInput'

export const BaseRadioButton = memo(
  forwardRef<HTMLInputElement, RadioButtonProps>(
    (
      {
        disabled,
        name,
        className,
        checked,
        endSection,
        onChange,
        label,
        desc,
        value,
        errorMessage,
        required,
        position,
        disableLabelInteraction,
        ...other
      },
      ref
    ) => {
      const mergedProps = useMergedRadioButtonProps({
        value,
        disabled,
        name,
        onChange
      })

      return (
        <InlineInputWrap
          className={className}
          label={label}
          desc={desc}
          errorMessage={errorMessage}
          endSection={endSection}
          required={required}
          position={position}
          disabled={mergedProps.disabled}
          htmlFor={mergedProps.name}
          disableLabelInteraction={disableLabelInteraction}
          field={
            <RadioButtonInput
              {...other}
              ref={ref}
              required={required}
              checked={checked || mergedProps.checked}
              value={mergedProps.value}
              name={mergedProps.name}
              disabled={mergedProps.disabled}
              onChange={mergedProps.onChange}
            />
          }
        />
      )
    }
  )
)

type RadioCompoundType = {
  Group: typeof RadioButtonGroup
  InputBox: typeof RadioButtonInput
}

const TypedRadioButton = BaseRadioButton as typeof BaseRadioButton &
  RadioCompoundType

TypedRadioButton.displayName = 'RadioButton'
TypedRadioButton.Group = RadioButtonGroup
TypedRadioButton.InputBox = RadioButtonInput

export const RadioButton = TypedRadioButton
export type { RadioButtonProps } from './RadioButton.types'
export type { RadioButtonInputProps } from './subs/RadioButtonInput.types'
export type { RadioButtonGroupProps } from './compound/RadioButtonGroup.types'
