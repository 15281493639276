import { Label } from '@spa/scenes/Plannings/redux/models'
import { setEntities } from '@spa/scenes/Plannings/redux/reducers/entities'
import { normalize } from 'normalizr'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import type { PayloadActionWithoutType } from '../../types/types'

type Params = PayloadActionWithoutType<{
  accountId: number
}>

export function* getLabels({ payload: { accountId } }: Params): any {
  const data = yield apiRequest({
    route: 'getLabelsByAccount',
    params: { account_id: accountId }
  })

  const normalized = normalize(data, [Label])

  yield put(setEntities({ labels: normalized.entities.labels }))
}
