export const NavigationMenuHorizontalBoldIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.70833 12.7083C4.2041 12.7083 5.41667 11.4957 5.41667 9.99996C5.41667 8.50419 4.2041 7.29163 2.70833 7.29163C1.21256 7.29163 0 8.50419 0 9.99996C0 11.4957 1.21256 12.7083 2.70833 12.7083Z'
        fill='currentColor'
      />
      <path
        d='M10 12.7083C11.4958 12.7083 12.7083 11.4957 12.7083 9.99996C12.7083 8.50419 11.4958 7.29163 10 7.29163C8.50423 7.29163 7.29167 8.50419 7.29167 9.99996C7.29167 11.4957 8.50423 12.7083 10 12.7083Z'
        fill='currentColor'
      />
      <path
        d='M17.2917 12.7083C18.7874 12.7083 20 11.4957 20 9.99996C20 8.50419 18.7874 7.29163 17.2917 7.29163C15.7959 7.29163 14.5833 8.50419 14.5833 9.99996C14.5833 11.4957 15.7959 12.7083 17.2917 12.7083Z'
        fill='currentColor'
      />
    </svg>
  )
}
