import membershipsSelector from '@spa/scenes/PlanningLegacy/selectorsNext/planningMemberships.selector'
import { all, call, put, select, take } from 'redux-saga/effects'
import { fetchContracts } from 'snap-redux/modules/contracts/index'
import { fetchAllMemberships } from 'snap-redux/modules/memberships/index'
import { fetchShifts } from 'snap-redux/modules/shifts/index'

export function* planningLocationChange({
  account,
  locationId,
  teamId,
  teamIds,
  start,
  end
}) {
  yield all([
    call(function* () {
      yield put(
        // @ts-ignore migration from js(x) to ts(x)
        fetchContracts(account.id, {
          in_progress_on: start.format('YYYY-MM-DD')
        })
      )
      yield all([take('api/FETCH_CONTRACTS_SUCCEEDED')])
    }),
    call(function* () {
      yield put(
        fetchAllMemberships(account.id, {
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          perPage: 100,
          locationId,
          in_progress_on: start.format('YYYY-MM-DD'),
          teamIds
        })
      )
      yield take('api/FETCH_ALL_MEMBERSHIPS_SUCCESS')
    })
  ])

  // Shifts for shared employee
  const memberships = yield select(state =>
    membershipsSelector(state, {
      teamId,
      locationId,
      week: start.format('GGGG-[W]WW'),
      archived: false
    })
  )

  const membershipsIds = memberships.map(m => m.id).toArray()

  yield put(
    // @ts-ignore migration from js(x) to ts(x)
    fetchShifts(account.id, {
      start_date: start.format('YYYY-MM-DD'),
      end_date: end.format('YYYY-MM-DD'),
      'membership_ids[]': membershipsIds
    })
  )

  yield take('api/FETCH_SHIFTS_SUCCEEDED')
}
