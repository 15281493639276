import { Accordion } from '@mantine/core'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'

import type { AccordionItemProps } from './AccordionItem.types'

const accordionItemCVA = {
  item: cva([
    'relative w-full overflow-hidden',
    'border-1 border-solid border-neutral-200 bg-transparent [&>*]:bg-transparent',
    '[&:first-of-type]:rounded-t-xl',
    '[&:last-of-type]:rounded-b-xl',
    '[&:not(:first-of-type)]:border-t-0'
  ])
}

export const AccordionItem: FC<AccordionItemProps> = ({
  value,
  children,
  ...other
}) => {
  return (
    <Accordion.Item
      value={value}
      className={accordionItemCVA.item()}
      {...other}
    >
      {children}
    </Accordion.Item>
  )
}
