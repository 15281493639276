import { keyBy } from 'lodash-es'
import { createSelector } from 'reselect'

import { getLocationWeeklyschedules } from '../getLocationWeeklyschedules/getLocationWeeklyschedules'

export const getWeeklyschedulesMapByTeam = createSelector(
  getLocationWeeklyschedules,

  weeklyschedules => {
    return keyBy(weeklyschedules || [], 'team_id')
  }
)
