import { track } from './track'
import type {
  AccountCreatedProperties,
  ESignDocProperties,
  ExportProperties,
  LoginCompletedProperties,
  PinnedItemProperties,
  SilaeBannerProperties,
  SilaeProperties
} from './types'
import {
  EventName,
  type AddonSubscriptionProperties,
  type BannerProperties,
  type ScheduleFeaturesProperties
} from './types'

export const trackAddonSubscriptionInteractionEvent = (
  properties: AddonSubscriptionProperties
) => track(EventName.ADDON_SUBSCRIPTION_COMPLETED, properties)

export const trackBannerInteractionEvent = (properties: BannerProperties) => {
  track(EventName.BANNER_INTERACTED, properties)
}

export const trackExportInteractionEvent = (properties: ExportProperties) => {
  track(EventName.EXPORT_INTERACTED, properties)
}

export const trackerScheduleFeaturesInteractionEvent = (
  properties: ScheduleFeaturesProperties
) => track(EventName.SCHEDULE_FEATURES_INTERACTED, properties)

export const trackSilaeBannerInteractionEvent = (
  properties: SilaeBannerProperties
) => {
  track(EventName.BANNER_INTERACTED, properties)
}

export const trackSilaeLandingInteractionEvent = (
  properties: SilaeProperties
) => {
  track(EventName.SILAE_LANDING_INTERACTED, properties)
}

export const trackSilaeSubscriptionModalInteractionEvent = (
  properties: SilaeProperties
) => {
  track(EventName.SILAE_SUBSCRIPTION_MODAL_INTERACTED, properties)
}

export const trackPinnedItemOnSideMenu = (properties: PinnedItemProperties) => {
  track(EventName.PINNED_ITEM_INTERACTED, properties)
}

export const trackDownloadESignDocs = (properties: ESignDocProperties) => {
  track(EventName.ESIGN_DOC_INTERACTED, properties)
}

export const trackLoginCompleted = (properties: LoginCompletedProperties) => {
  track(EventName.LOGIN_COMPLETED, properties)
}

export const trackAccountCreated = (properties: AccountCreatedProperties) => {
  track(EventName.ACCOUNT_CREATED, properties)
}
