import { inputFileUtility } from '@libs/ui/ds'
import type { FC } from 'react'

import { Dropzone } from '../base/Dropzone'
import type { DropzoneProps } from '../base/Dropzone.types'

export const DropzonePDF: FC<DropzoneProps> = ({ ...other }) => {
  return <Dropzone accept={[inputFileUtility.mimeTypes.pdf]} {...other} />
}

DropzonePDF.displayName = 'DropzonePDF'
