import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_CONVERSATION,
  FETCH_CONVERSATION_SUCCEEDED,
  FETCH_CONVERSATION_FAILED,
  FETCH_CONVERSATIONS,
  FETCH_CONVERSATIONS_FAILED,
  FETCH_CONVERSATIONS_SUCCEEDED,
  REFRESH_CONVERSATIONS,
  REFRESH_CONVERSATIONS_FAILED,
  REFRESH_CONVERSATIONS_SUCCEEDED,
  CREATE_CONVERSATION,
  CREATE_CONVERSATION_SUCCEEDED,
  CREATE_CONVERSATION_FAILED,
  UPDATE_CONVERSATION,
  UPDATE_CONVERSATION_SUCCEEDED,
  UPDATE_CONVERSATION_FAILED,
  UPDATE_MESSAGE_SUCCEEDED,
  CREATE_MESSAGE_SUCCEEDED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import {
  mergeRecords,
  deleteRecord,
  updateRecord
} from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchConversation = (conversationId, resolve, reject) => ({
  type: FETCH_CONVERSATION,
  conversationId,
  resolve,
  reject
})

export const fetchConversations = (accountId, params, resolve, reject) => ({
  type: FETCH_CONVERSATIONS,
  accountId,
  params,
  resolve,
  reject
})

export const refreshConversations = (accountId, params, resolve, reject) => ({
  type: REFRESH_CONVERSATIONS,
  accountId,
  params,
  resolve,
  reject
})

export const createConversation = (conversationData, resolve, reject) => ({
  type: CREATE_CONVERSATION,
  conversationData,
  resolve,
  reject
})

export const updateConversation = (
  conversationId,
  conversationData,
  resolve,
  reject
) => ({
  type: UPDATE_CONVERSATION,
  conversationId,
  conversationData,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const conversationSagaEntity = {
  success: (data, conversationId) => ({
    type: FETCH_CONVERSATION_SUCCEEDED,
    data,
    conversationId
  }),
  failure: (error, conversationId) => ({
    type: FETCH_CONVERSATION_FAILED,
    error,
    conversationId
  }),

  fetchAPI: (conversationId, options) =>
    callAPI(`/conversations/${conversationId}`, options)
}

export function* doFetchConversation({ conversationId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    conversationSagaEntity,
    conversationId,
    undefined,
    undefined,
    resolve,
    reject
  )
}

const conversationsSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_CONVERSATIONS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_CONVERSATIONS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/conversations?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchConversations({ accountId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    conversationsSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

const refreshConversationsSagaEntity = {
  success: (data, accountId) => ({
    type: REFRESH_CONVERSATIONS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: REFRESH_CONVERSATIONS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/conversations?${queryString.stringify(params)}`,
      options
    )
}

export function* doRefreshConversations({
  accountId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    refreshConversationsSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

const createConversationSagaEntity = {
  success: (data, conversationData) => ({
    type: CREATE_CONVERSATION_SUCCEEDED,
    data,
    conversationData
  }),
  failure: (error, conversationData) => ({
    type: CREATE_CONVERSATION_FAILED,
    error,
    conversationData
  }),

  fetchAPI: (conversationData, options) =>
    callAPI('/conversations', { method: 'POST', ...options })
}

export function* doCreateConversation({ conversationData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createConversationSagaEntity,
    null,
    null,
    conversationData,
    resolve,
    reject
  )
}

const updateConversationSagaEntity = {
  success: (data, conversationId) => ({
    type: UPDATE_CONVERSATION_SUCCEEDED,
    data,
    conversationId
  }),
  failure: (error, conversationId) => ({
    type: UPDATE_CONVERSATION_FAILED,
    error,
    conversationId
  }),

  fetchAPI: (conversationId, options) =>
    callAPI(`/conversations/${conversationId}`, { method: 'PUT', ...options })
}

export function* doUpdateConversation({
  conversationId,
  conversationData,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    updateConversationSagaEntity,
    conversationId,
    null,
    conversationData,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const Conversation = Immutable.Record({
  id: null,
  title: null,
  sender_id: null,
  account_id: null,
  recipient_ids: null,
  recipients: null,
  archived_at: null,
  created_at: null,
  last_read_at: null,
  last_message: null,
  has_attachment: null
})

const conversationForeignKeys = ['account_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  pagination: Immutable.Map(),
  meta: Immutable.Map({
    loading: false,
    show_archived: false,
    flush_next: false,
    updated_at: null
  }),
  relations: Immutable.Map()
})

function conversations(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case UPDATE_MESSAGE_SUCCEEDED:
    case CREATE_MESSAGE_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const message = action.data
      // @ts-ignore migration from js(x) to ts(x)
      const conversation = state.getIn(['data', action.data.conversation_id])
      if (message.sent_at > conversation.getIn(['last_message', 'sent_at'])) {
        return state.mergeDeepIn(
          // @ts-ignore migration from js(x) to ts(x)
          ['data', action.data.conversation_id, 'last_message'],
          message
        )
      }
      return state
    }
    case REFRESH_CONVERSATIONS:
    case FETCH_CONVERSATION: {
      return state.mergeDeepIn(['meta'], {
        ...state.get('meta').toJS(),
        loading: true
      })
    }
    case FETCH_CONVERSATIONS: {
      return state.mergeDeepIn(['meta'], {
        // @ts-ignore migration from js(x) to ts(x)
        loading: true,
        // @ts-ignore migration from js(x) to ts(x)
        show_archived: action.params.archived || false,
        flush_next:
          // @ts-ignore migration from js(x) to ts(x)
          action.params.archived !== undefined &&
          // @ts-ignore migration from js(x) to ts(x)
          action.params.archived !== state.getIn(['meta', 'show_archived'])
      })
    }
    case CREATE_CONVERSATION_FAILED:
    case UPDATE_CONVERSATION_FAILED:
    case REFRESH_CONVERSATIONS_FAILED:
    case FETCH_CONVERSATION_FAILED:
    case FETCH_CONVERSATIONS_FAILED: {
      return state.mergeDeepIn(['meta'], {
        ...state.get('meta').toJS(),
        loading: false,
        success: false
      })
    }
    case REFRESH_CONVERSATIONS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const conversations = Immutable.fromJS(action.data.conversations)
      const oldPagination = state.get('pagination')
      const newTotalCount =
        oldPagination.get('total_count') +
        conversations.filter(
          conversation => !state.get('data').has(conversation.get('id'))
        ).size
      const newTotalPages = Math.ceil(
        // @ts-ignore migration from js(x) to ts(x)
        newTotalCount / oldPagination.get('per_page')
      )
      const newPagination = Immutable.Map({
        ...oldPagination.toJS(),
        total_count: newTotalCount,
        total_pages: newTotalPages
      })

      return mergeRecords(
        state,
        Conversation,
        conversations,
        // @ts-ignore migration from js(x) to ts(x)
        conversationForeignKeys
      )
        .mergeDeepIn(['meta'], {
          ...state.get('meta').toJS(),
          loading: false,
          updated_at: Date.now(),
          success: true
        })
        .mergeDeepIn(['pagination'], newPagination)
    }
    case FETCH_CONVERSATION_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const conversation = Immutable.fromJS(action.data)
      return updateRecord(
        state,
        Conversation,
        conversation,
        // @ts-ignore migration from js(x) to ts(x)
        conversationForeignKeys
      ).mergeDeepIn(['meta'], {
        ...state.get('meta').toJS(),
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    case FETCH_CONVERSATIONS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const conversations = Immutable.fromJS(action.data.conversations)
      // @ts-ignore migration from js(x) to ts(x)
      const pagination = Immutable.fromJS(action.data.meta)
      const currentPage = pagination.get('current_page')

      // Flush state if switching between archived and current messages
      const mergedState = state.getIn(['meta', 'flush_next'])
        ? initialState
        : state

      // Update updated_at value if newest data is fetched (first page)
      const newUpdatedAt =
        currentPage === 1 ? Date.now() : state.getIn(['meta', 'updated_at'])

      return mergeRecords(
        mergedState,
        Conversation,
        conversations,
        // @ts-ignore migration from js(x) to ts(x)
        conversationForeignKeys
      )
        .mergeDeepIn(['meta'], {
          ...state.get('meta').toJS(),
          loading: false,
          updated_at: newUpdatedAt,
          success: true,
          flush_next: false
        })
        .mergeDeepIn(['pagination'], pagination)
    }
    case UPDATE_CONVERSATION_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const conversation = Immutable.fromJS(action.data)
      const oldPagination = state.get('pagination')
      const archivedStatusHasChanged =
        state.getIn(['meta', 'show_archived']) !==
        Boolean(conversation.get('archived_at'))
      const newTotalCount = archivedStatusHasChanged
        ? state.getIn(['pagination', 'total_count']) - 1
        : state.getIn(['pagination', 'total_count'])
      const newTotalPages = Math.ceil(
        // @ts-ignore migration from js(x) to ts(x)
        newTotalCount / oldPagination.get('per_page')
      )
      const newPagination = Immutable.Map({
        ...oldPagination.toJS(),
        total_count: newTotalCount,
        total_pages: newTotalPages
      })

      return archivedStatusHasChanged
        ? // @ts-ignore migration from js(x) to ts(x)
          deleteRecord(state, conversation.get('id'), conversationForeignKeys)
            .mergeDeepIn(['meta'], {
              ...state.get('meta').toJS(),
              loading: false,
              success: true
            })
            .mergeDeepIn(['pagination'], newPagination)
        : updateRecord(
            state,
            Conversation,
            conversation,
            // @ts-ignore migration from js(x) to ts(x)
            conversationForeignKeys
          )
            .mergeDeepIn(['meta'], {
              ...state.get('meta').toJS(),
              loading: false,
              success: true
            })
            .mergeDeepIn(['pagination'], newPagination)
    }
    case CREATE_CONVERSATION_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const conversation = Immutable.fromJS(action.data)
      const oldPagination = state.get('pagination')
      const newTotalCount = state.getIn(['pagination', 'total_count']) + 1
      const newTotalPages = Math.ceil(
        // @ts-ignore migration from js(x) to ts(x)
        newTotalCount / oldPagination.get('per_page')
      )
      const newPagination = Immutable.Map({
        ...oldPagination.toJS(),
        total_count: newTotalCount,
        total_pages: newTotalPages
      })

      return updateRecord(
        state,
        Conversation,
        conversation,
        // @ts-ignore migration from js(x) to ts(x)
        conversationForeignKeys
      )
        .mergeDeepIn(['meta'], {
          ...state.get('meta').toJS(),
          loading: false,
          success: true
        })
        .mergeDeepIn(['pagination'], newPagination)
    }
    default:
      return state
  }
}

export default filterActions(conversations, [
  FETCH_CONVERSATION,
  FETCH_CONVERSATION_SUCCEEDED,
  FETCH_CONVERSATION_FAILED,
  FETCH_CONVERSATIONS,
  FETCH_CONVERSATIONS_FAILED,
  FETCH_CONVERSATIONS_SUCCEEDED,
  REFRESH_CONVERSATIONS,
  REFRESH_CONVERSATIONS_FAILED,
  REFRESH_CONVERSATIONS_SUCCEEDED,
  CREATE_CONVERSATION,
  CREATE_CONVERSATION_SUCCEEDED,
  CREATE_CONVERSATION_FAILED,
  UPDATE_CONVERSATION,
  UPDATE_CONVERSATION_SUCCEEDED,
  UPDATE_CONVERSATION_FAILED,
  UPDATE_MESSAGE_SUCCEEDED,
  CREATE_MESSAGE_SUCCEEDED
])
