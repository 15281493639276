import {
  type ComponentType,
  type FC,
  type ForwardedRef,
  type ReactElement
} from 'react'

import type { AutocompleteCustomTargetWrapProps } from './AutocompleteCustomTargetWrap.types'
import { AutocompleteTargetInputLike } from './targets/AutocompleteTargetInputLike'

type CustomTargetComponent = ComponentType<
  AutocompleteCustomTargetWrapProps & { ref?: ForwardedRef<HTMLElement> }
>
type CustomTargetFunction = (
  props: AutocompleteCustomTargetWrapProps & { ref: ForwardedRef<HTMLElement> }
) => ReactElement

export const AutocompleteCustomTargetWrap: FC<
  AutocompleteCustomTargetWrapProps
> = props => {
  const { customTarget } = props

  /**
   * Default target component if no custom target is provided
   */
  if (!customTarget) {
    return <AutocompleteTargetInputLike {...props} />
  }

  /**
   * Custom target function
   */
  if (typeof customTarget === 'function') {
    return (customTarget as CustomTargetFunction)(props)
  }

  /**
   * Instanciate custom preset target component
   */
  const Component = customTarget as CustomTargetComponent
  return <Component {...props} />
}
