import { usePrevious } from '@libs/ui/ds'
import { useOnLocationChange } from '@libs/ui/router'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { page } from '../methods/page'

export const useSegmentTrackPage = (
  isIdentified: boolean,
  isGrouped: boolean
) => {
  const location = useLocation()
  const previousPathname = usePrevious(location.pathname)
  const [initialPageTracked, setInitialPageTracked] = useState(false)

  // Effect to track the initial page view once identified and grouped
  useEffect(() => {
    if (isIdentified && isGrouped && !initialPageTracked) {
      page(location.pathname)
      setInitialPageTracked(true)
    }
  }, [isIdentified, isGrouped, location.pathname, initialPageTracked])

  // Track subsequent page views
  useOnLocationChange(({ location }) => {
    if (initialPageTracked && location.pathname !== previousPathname) {
      page(location.pathname)
    }
  })
}
