import { getLocationWeeklyschedules } from './getLocationWeeklyschedules/getLocationWeeklyschedules'
import { getMonthlyPublishPermissionIfUnpublished } from './getMonthlyPublishPermissionIfUnpublished/getMonthlyPublishPermissionIfUnpublished'
import { getWeekNotes } from './getWeekNotes/getWeekNotes'
import { getWeeklyscheduleFromTeamId } from './getWeeklyscheduleFromTeamId/getWeeklyscheduleFromTeamId'
import { getWeeklyschedulesMapByTeam } from './getWeeklyschedulesMapByTeam/getWeeklyschedulesMapByTeam'
import { getWholeLocationIsPublished } from './getWholeLocationIsPublished/getWholeLocationIsPublished'
import { weeklyschedulesBaseSelectors } from './weeklyschedulesBaseSelectors'

export const weeklyschedulesSelectors = {
  ...weeklyschedulesBaseSelectors,
  getLocationWeeklyschedules,
  getWeeklyscheduleFromTeamId,
  getWeeklyschedulesMapByTeam,
  getWholeLocationIsPublished,
  getMonthlyPublishPermissionIfUnpublished,
  getWeekNotes
}
