import { filterShiftBreaksWithSlot } from '@spa/scenes/Plannings/redux/modules/entities/shifts/utils/filterShiftBreaksWithSlot'
import type { Shift } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import { computeDecimalOverlapsByInterval } from './computeDecimalOverlapsByInterval'

/**
 * Compute shifts breaks_slot headcount by interval
 */
export const computeBreaksHeadcountsList = (
  shifts: Shift[],
  interval: number
) => {
  const shiftBreakList = shifts.flatMap(shift => shift?.shift_breaks || [])
  const shiftBreakSlotList = filterShiftBreaksWithSlot(shiftBreakList)

  const flatBreaksHeadcountsList = shiftBreakSlotList.flatMap(shiftBreak =>
    computeDecimalOverlapsByInterval(
      {
        start: shiftBreak.real_starts_at,
        end: shiftBreak.real_ends_at
      },
      interval
    )
  )
  // All breaks headcounts are reversed because they should be substracted from the total headcounts
  const reversedHeadcountsList = flatBreaksHeadcountsList.map(item => ({
    ...item,
    count: -item.count
  }))

  return reversedHeadcountsList
}
