import type { Faro } from '@grafana/faro-web-sdk'
import { initializeFaro } from '@grafana/faro-web-sdk'
import type { UserRecord } from '@libs/data-access/entities'
import { FARO_API_KEY, FARO_ENV, FARO_URL } from '@libs/utils/environments'
import { routes } from '@spa/core/router'
import { map, mapKeys } from 'lodash-es'
import qs from 'query-string'
import type { Location, Params } from 'react-router-dom'
import { matchPath } from 'react-router-dom'

class FaroClient {
  private client: Faro

  init({ accountId }: { accountId: number }) {
    if (this.client || !FARO_URL) {
      return
    }

    this.client = initializeFaro({
      url: String(FARO_URL),
      apiKey: String(FARO_API_KEY),
      app: {
        name: 'combo',
        version: '1.0.0',
        environment: String(FARO_ENV)
      },
      user: {
        attributes: {
          accountId: String(accountId)
        }
      }
    })
  }

  /**
   * Set pages metadata with additional window location attributes:
   * - pathname
   * - search_{key}
   */
  setPage(location: Location) {
    if (!this.checkIsFaroEnabled()) {
      return null
    }

    this.client.metas.add({
      page: {
        url: window.location.href,
        attributes: {
          ...this.getCurrentPathMatch(location),
          ...this.formatCurrentSearchParamsObject()
        }
      }
    })
  }

  /**
   * Set user metadata attributes
   */
  setUser(params: {
    user?: UserRecord
    accountId?: number
    membershipId?: number
  }) {
    if (!this.checkIsFaroEnabled()) {
      return null
    }

    this.client.api.setUser({
      email: params?.user?.email || '',
      id: String(params?.user?.id),
      attributes: {
        accountId: String(params?.accountId),
        membershipId: String(params?.membershipId)
      }
    })
  }

  /**
   * Format window.location.search to an object
   */
  private formatCurrentSearchParamsObject() {
    return mapKeys(
      qs.parse(window.location.search),
      (_, key) => `search_${key}`
    )
  }

  /**
   * Prevent faro methods to be called if faro is not enabled
   */
  private checkIsFaroEnabled() {
    return this.client && Boolean(FARO_URL)
  }

  /**
   * Format pathParams object to be used in faro metadata
   */
  private formatPathParamsObject(pathParams: Params<string> = {}) {
    return mapKeys(pathParams, (_, key) => `path_${key}`)
  }

  /**
   *
   */
  private getCurrentPathMatch(location: Location) {
    const match = map(routes).find(route =>
      matchPath(route.rootPath, location.pathname)
    )

    if (!match) {
      return { pathname: '' }
    }

    const res = matchPath(match.rootPath, location.pathname)

    return {
      pathname: res?.pattern.path || '',
      ...this.formatPathParamsObject(res?.params)
    }
  }
}

export const faroClient = new FaroClient()
