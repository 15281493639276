import type { AccountPayConnector } from '@libs/data-access/entities'
import { httpServices } from '@libs/data-access/queries'

import type { QueryFnParams } from '../../../types'

export type FetchPayConnectorOptions = QueryFnParams<{
  pathParams: {
    snapAppAccountId: string
  }
}>

/**
 * Fetch pay connector information
 */
export const fetchPayConnector = async () => {
  const response = await httpServices.billing.get<AccountPayConnector>(
    `/account-based/accounts/${httpServices.authInfo.accountId}/pay-connector`
  )
  return response.data
}
