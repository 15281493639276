export const authCacheKeys = {
  postOauthToken: () => ['postOauthToken'],
  postOauthRevokeToken: () => ['postOauthRevokeToken'],
  postResetUserPassword: () => ['postResetUserPassword'],
  postCheckResetUserPasswordToken: () => ['postCheckResetUserPasswordToken'],
  putUserPassword: () => ['putUserPassword'],
  postCheckExistingEmail: () => ['postCheckExistingEmail'],
  getSignupCheckExistingEmail: () => ['getSignupCheckExistingEmail'],
  getSignupRelatedAccount: () => ['getSignupRelatedAccount'],
  postUserSignup: () => ['postUserSignup'],
  postImpersonateUser: () => ['postImpersonateUser'],
  getUserInvitation: () => ['getUserInvitation'],
  putUserInvitation: () => ['putUserInvitation'],
  getUserConfirmation: () => ['getUserConfirmation'],
  postCreateUser: () => ['postCreateUser'],
  postSignupResendInvitation: () => ['postSignupResendInvitation']
}
