import type { PasswordInputRestriction } from '../subs/PasswordInputRestrictions/PasswordInputRestrictions.types'

export const allRestrictionsMatch = (
  passwordValue: string,
  restrictions?: PasswordInputRestriction[]
): boolean => {
  if (!passwordValue || !restrictions?.length) {
    return true
  }

  return restrictions.every(restriction => restriction.isValid(passwordValue))
}
