import queryString from 'query-string'
import { call } from 'redux-saga/effects'

import {
  FETCH_MONTHLY_DASHBOARD_TURNOVERS,
  FETCH_MONTHLY_DASHBOARD_TURNOVERS_SUCCEEDED,
  FETCH_MONTHLY_DASHBOARD_TURNOVERS_FAILED
} from '../actionTypes'
import { call as callAPI } from '../infra/http'
import { fetchSagaEntity } from '../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchMonthlyDashboardTurnovers = (
  accountId,
  params,
  resolve,
  reject
) => ({
  type: FETCH_MONTHLY_DASHBOARD_TURNOVERS,
  accountId,
  params,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const monthlyDashboardTurnoversSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_MONTHLY_DASHBOARD_TURNOVERS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_MONTHLY_DASHBOARD_TURNOVERS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/monthly_dashboard_turnovers?${queryString.stringify(
        params
      )}`,
      options
    )
}

export function* doFetchMonthlyDashboardTurnovers({
  accountId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    monthlyDashboardTurnoversSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}
