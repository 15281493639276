import { TextArea } from '@libs/ui/ds'
import type { ControlledTextAreaProps } from '@libs/ui/form'
import { ControllerBase } from '@libs/ui/form'
import type { FieldValues, FieldPath } from 'react-hook-form'

export const ControlledTextArea = <
  TFieldValues extends FieldValues = FieldValues,
  TInputName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  onChangeSideEffect,
  rules,
  ...textAreaProps
}: ControlledTextAreaProps<TFieldValues, TInputName>) => (
  <ControllerBase
    rules={rules}
    control={control}
    field={<TextArea {...textAreaProps} />}
    name={name}
    onChangeAdapter={e => e}
    onChangeSideEffect={onChangeSideEffect}
  />
)
