import { fork } from 'redux-saga/effects'

import { getAlertsChunked } from '../../entities/alerts/getAlertsChunked'
import { fetchAndHydrateContractCounters } from '../../entities/contractCounters'
import { fetchAndHydratePlannables } from '../../entities/plannables'
import { fetchSignableTimesheetsPeriodStatus } from '../../entities/signableTimesheets/fetchSignableTimesheetsPeriodStatus'

export type PlannableSideEffectsParams = {
  contractIds: number[]
  date: 'YYYY-MM-DD' | string
  countersParams?: {
    live: boolean
  }
}

export default function* fetchPlannablesSideEffectsEntities(
  params: PlannableSideEffectsParams
) {
  const employeeContrats = params.contractIds.filter(id => !!id)
  const unassignedContract = params.contractIds.filter(id => id === null)

  /** Handle the unassgiend shifts refresh */
  if (unassignedContract.length > 0) {
    // TODO : refresh the unassigned shifts call. Will be done in the next ticket SCH-2384
  }

  /** Handle the employee contracts refresh */
  if (employeeContrats.length > 0) {
    // override contractIds to fetch employee contracts only
    params.contractIds = employeeContrats

    /**
     * Fetch and hydrate rows, shifts and rests
     */
    yield fork(function* () {
      yield fetchAndHydratePlannables({ payload: params })
    })

    /**
     * Fetch and hydrate counters
     */
    yield fork(function* () {
      yield fetchAndHydrateContractCounters({
        payload: {
          ...params,
          params: params.countersParams
        }
      })
    })

    /**
     * Fetch and hydrate alerts
     */
    yield fork(function* () {
      yield getAlertsChunked({
        payload: params
      })
    })

    yield fork(function* () {
      yield fetchSignableTimesheetsPeriodStatus()
    })
  }
}
