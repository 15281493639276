import type { AccountDetails } from '@libs/data-access/entities'

// We are mocking data to not block staging & sandbox
export const fallbackAccountDetailsData = {
  billing_frequency: 'monthly',
  is_location_based: true,
  is_yearly: false,
  cancelled_at: null,
  commitment: null,
  default_location_group_id: '91677933-4e88-4df9-8d84-016a0f5020b6',
  next_billing_at: new Date(),
  plan: 'time',
  switch_to_location_based_at: new Date()
} as AccountDetails
