import type { CountryConfigurationSchema } from '@libs/data-access/entities'

import type { GetRoute } from '../../types'

export const countryConfigurationsRoutes = {
  getCountryConfigurationByAccount: {
    method: 'GET',
    path: '/accounts/:account_id/country_configurations'
  }
} as const

export type CountryConfigurationsRoutes = {
  getCountryConfigurationByAccount: GetRoute<
    ['account_id'],
    {},
    CountryConfigurationSchema
  >
}
