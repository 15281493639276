import type { ReduxState } from '@spa/redux/ReduxState'
import { getWeekRange } from '@spa/scenes/Plannings/utils'
import { sumBy } from 'lodash-es'
import { createSelector } from 'reselect'

import { getDate, getParams } from '../../../params'
import getAlertsByTeamId from '../getAlertsByTeamId/getAlertsByTeamId'
import filterAlertsDataByRange from '../utils/filterAlertsDataByRange'

/**
 * Get all alerts related to a planning week
 */
const getAlertsInWeek = createSelector(
  getDate,
  (state: ReduxState) => getAlertsByTeamId(state, getParams(state).teamId),

  (date, alertsByTeam) => {
    const { dayStart, dayEnd } = getWeekRange(date)

    const filteredAlertsData = filterAlertsDataByRange(alertsByTeam, {
      start: dayStart,
      end: dayEnd
    })

    return {
      alerts: filteredAlertsData,
      count: sumBy(filteredAlertsData, item => item.alerts?.length)
    }
  }
)

export default getAlertsInWeek
