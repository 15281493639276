import type { DebouncedFunc } from 'lodash-es'
import { debounce } from 'lodash-es'

type SimpleFunction = (...args: any) => any

type Options = {
  delay?: number
  leading?: boolean
  trailing?: boolean
}

export const useDebouncedFunction = <T extends SimpleFunction>(
  callback: T,
  opts: Options = {}
) => {
  const { delay = 400, ...restOpts } = opts
  return debounce(callback, delay, restOpts) as DebouncedFunc<T>
}
