import type { Model } from '@libs/data-access/entities'
import { schema } from 'normalizr'
import type { Location as LocationSchema } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import ModulationPeriod from './ModulationPeriod'
import Team from './Team'

export type LocationModel = Model<
  LocationSchema,
  {
    teams: number[]
    team_ids: number[]
  }
>

const Location = new schema.Entity<LocationModel>('locations', {
  teams: [Team],
  modulation_periods: [ModulationPeriod]
})

export function create(data: Partial<LocationModel>): LocationModel {
  const original: LocationModel = {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    name: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    account_id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    collective_agreement_id: null,
    teams: [],
    team_ids: [],
    modulation_periods: [],

    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    updated_at: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    created_at: null
  }

  return {
    ...original,
    ...data
  }
}

export default Location
