import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'

import type { AbsoluteProps } from './Absolute.types'

const absoluteCVA = cva(['absolute z-10 box-border'], {
  variants: {
    x: {
      start: ['left-0'],
      center: ['left-1/2 -translate-x-1/2'],
      end: ['right-0'],
      startOutside: ['left-0 -translate-x-full'],
      startInBetween: ['left-0 -translate-x-1/2'],
      endOutside: ['right-0 translate-x-full'],
      endInBetween: ['right-0 translate-x-1/2']
    },
    y: {
      start: ['top-0'],
      center: ['top-1/2 -translate-y-1/2'],
      end: ['bottom-0'],
      startOutside: ['top-0 -translate-y-full'],
      startInBetween: ['top-0 -translate-y-1/2'],
      endOutside: ['bottom-0 translate-y-full'],
      endInBetween: ['bottom-0 translate-y-1/2']
    }
  }
})

export const Absolute = forwardRef<HTMLDivElement, AbsoluteProps>(
  ({ x, y, className, children, ...other }, ref) => {
    return (
      <div {...other} ref={ref} className={absoluteCVA({ x, y, className })}>
        {children}
      </div>
    )
  }
)

Absolute.displayName = 'Absolute'
