import type { ReduxState } from '@spa/redux/ReduxState'
import { mapValues, isFunction } from 'lodash-es'
import { createSelector } from 'reselect'
import { getCurrentAccount } from 'snap-redux/modules/accounts/selectors'
import { getCurrentMembershipSelector } from 'snap-redux/modules/memberships/selectors'

import getRole from './getRoleName'
import planningPolicies from './policiesList'

export type PlanningPoliciesUnion = keyof typeof planningPolicies
export type PlanningPolicies = Record<PlanningPoliciesUnion, boolean>

export const getPlanningPolicies = createSelector(
  [
    (state: ReduxState) => getCurrentMembershipSelector(state),
    (state: ReduxState) => getCurrentAccount(state),
    (state: ReduxState) => {
      const membership = getCurrentMembershipSelector(state)
      return getRole(membership) as string
    }
  ],
  (membership, account, currentRole) => {
    /**
     * Return all Policies as map
     */
    return mapValues(planningPolicies, item => {
      const permission = item[currentRole]
      return isFunction(permission)
        ? (permission({}, { membership, account }) as boolean)
        : permission
    }) as PlanningPolicies
  }
)
