import { fuzzySearch } from '@libs/ui/ds/lib/internals/search/fuzzySearch/fuzzySearch'
import { startingWithSearch } from '@libs/ui/ds/lib/internals/search/startingWithSearch/startingWithSearch'

import type { SelectProps } from '../Select.types'

type SearchFunction = (opts: { search: string; options: string[] }) => boolean
// @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
type FilterAdapter = Record<SelectProps['searchAdapter'], SearchFunction>

export const selectFilterAdapters: FilterAdapter = {
  /**
   * Simple algorithm that checks if the option precisely starts with the search value
   */
  startingWith: filterOptionInput =>
    startingWithSearch({
      search: filterOptionInput.search,
      options: filterOptionInput.options
    }),
  /**
   * Use fuzzy search algorithm, and search for matching into label and group separately
   */
  fuzzyIncludingGroup: filterOptionInput =>
    fuzzySearch({
      search: filterOptionInput.search,
      options: filterOptionInput.options
    })
}
