import type { LocationGroupEstimates } from '@libs/data-access/entities'

import { httpServices } from '../../../apis'
import type { QueryFnParams } from '../../../types'

export type FetchLocationGroupEstimates = QueryFnParams<{
  pathParams: {
    location_group_id: string
  }
}>

export const fetchLocationGroupEstimates = async (
  options: FetchLocationGroupEstimates
) => {
  const response = await httpServices.billing.get<LocationGroupEstimates>(
    `/location-based/estimates/${options.pathParams.location_group_id}`,
    {
      params: {
        snap_app_account_id: httpServices.authInfo.accountId
      }
    }
  )

  return response.data
}
