import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import { memo } from 'react'

import { useModalContext } from '../../Modal.context'

import type { ModalContentProps } from './ModalContent.types'

const modalContentCVA = {
  root: cva(['mb-6 overflow-auto px-6'], {
    variants: {
      height: {
        auto: '',
        'max-s': '',
        'max-m': '',
        'max-l': '',
        s: '',
        m: '',
        l: ''
      },
      variant: {
        center: '',
        fullscreen: '',
        right: ''
      }
    },
    compoundVariants: [
      {
        height: 'max-s',
        variant: 'center',
        class: 'max-h-60'
      },
      {
        height: 'max-m',
        variant: 'center',
        class: 'max-h-80'
      },
      {
        height: 'max-l',
        variant: 'center',
        class: 'max-h-120'
      },
      {
        height: 's',
        variant: 'center',
        class: 'h-60'
      },
      {
        height: 'm',
        variant: 'center',
        class: 'h-80'
      },
      {
        height: 'l',
        variant: 'center',
        class: 'h-120'
      },
      {
        height: ['s', 'm', 'l', 'max-s', 'max-m', 'max-l', 'auto'],
        variant: 'fullscreen',
        class: 'flex-grow'
      },
      {
        height: ['s', 'm', 'l', 'max-s', 'max-m', 'max-l', 'auto'],
        variant: 'right',
        class: 'flex-grow'
      }
    ]
  })
}

export const ModalContent: FC<ModalContentProps> = memo(
  ({ children, className, ...other }) => {
    const ctx = useModalContext()

    return (
      <div
        className={modalContentCVA.root({
          className,
          variant: ctx?.variant,
          height: ctx?.height
        })}
        {...other}
      >
        {children}
      </div>
    )
  }
)

ModalContent.displayName = 'ModalContent'
