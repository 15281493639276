import { hotKeysList } from './hotkeysList'
import { useSafeHotkeysListener } from './useSafeHotkeysListener'

class HotkeysService {
  keys = hotKeysList

  useSafeHotkeysListener = useSafeHotkeysListener
}

export const hotkeysService = new HotkeysService()
export type { SafeHotkeysCombination } from './hotkeysList'
