import { useUncontrolled } from '@libs/ui/ds'
import {
  AlertCircleIcon,
  CloseIcon,
  InformationCircleIcon,
  LegalScaleIcon,
  AlertTriangleIcon
} from '@libs/ui/ds/assets/icons'
import { IconButton, IconWrap, Flex, HideAt } from '@libs/ui/ds/lib/components'
import type { MediaQueryThreshold } from '@libs/ui/ds/lib/theme'
import { cva } from 'class-variance-authority'
import { forwardRef, memo } from 'react'

import type { AlertProps } from './Alert.types'
import AlertButton from './compound/AlertButton'

const alertCVA = {
  root: cva(['rounded-lg border-1 p-4'], {
    variants: {
      intent: {
        'semantic.blue': 'border-semantic-blue-300',
        'semantic.orange': 'border-semantic-orange-300',
        'semantic.red': 'border-semantic-red-300',
        'secondary.purple': 'border-secondary-purple-400'
      }
    }
  }),
  layoutWrap: cva(['flex-nowrap gap-4']),
  iconSection: cva(['flex'], {
    variants: {
      intent: {
        'semantic.blue': 'text-semantic-blue-500',
        'semantic.orange': 'text-semantic-orange-500',
        'semantic.red': 'text-semantic-red-500',
        'secondary.purple': 'text-secondary-purple-700'
      },
      standalone: {
        true: 'items-center'
      }
    }
  }),
  iconComponent: {
    'semantic.blue': <InformationCircleIcon />,
    'semantic.orange': <AlertCircleIcon />,
    'semantic.red': <AlertTriangleIcon />,
    'secondary.purple': <LegalScaleIcon />
  },
  contentSection: cva(['flex grow flex-col gap-2'], {
    variants: {
      standalone: {
        true: 'justify-center'
      }
    }
  }),
  title: cva(['text-m font-semibold'], {
    variants: {
      intent: {
        'semantic.blue': 'text-semantic-blue-500',
        'semantic.orange': 'text-semantic-orange-500',
        'semantic.red': 'text-semantic-red-500',
        'secondary.purple': 'text-secondary-purple-700'
      }
    }
  }),
  content: cva(['text-s text-neutral-700'])
}

const BaseAlert = memo(
  forwardRef<HTMLDivElement, AlertProps>(
    (
      {
        children,
        className,
        closeable,
        endSection,
        intent = 'semantic.blue',
        onClose,
        title,
        switchAt = 'sm',
        ...other
      },
      ref
    ) => {
      const [open, setOpen] = useUncontrolled({
        defaultValue: true,
        onChange: onClose
      })

      if (!open) {
        return null
      }

      return (
        <div
          {...other}
          ref={ref}
          className={alertCVA.root({ className, intent })}
        >
          <Flex row className={alertCVA.layoutWrap()}>
            <div
              className={alertCVA.iconSection({
                intent,
                standalone: !children
              })}
            >
              <IconWrap size='m' icon={alertCVA.iconComponent[intent]} />
            </div>
            <div className={alertCVA.contentSection({ standalone: !children })}>
              {title && (
                <div
                  className={alertCVA.title({
                    intent
                  })}
                >
                  {title}
                </div>
              )}
              {children && <div className={alertCVA.content()}>{children}</div>}
              {endSection && switchAt !== 'none' && (
                <HideAt hideAt={`min-${switchAt}` as MediaQueryThreshold}>
                  {endSection}
                </HideAt>
              )}
            </div>

            {endSection && switchAt !== 'all' && (
              <HideAt hideAt={`max-${switchAt}` as MediaQueryThreshold}>
                <Flex row className='items-center'>
                  {endSection}
                </Flex>
              </HideAt>
            )}

            {closeable && (
              <Flex row className='sm:items-center'>
                <IconButton
                  aria-label='close-alert-button'
                  size='s'
                  variant='tertiary'
                  intent='neutral-dark'
                  icon={<CloseIcon />}
                  onClick={() => setOpen(false)}
                />
              </Flex>
            )}
          </Flex>
        </div>
      )
    }
  )
)

type CompoundAlertType = {
  Button: typeof AlertButton
}

const TypedAlert = BaseAlert as typeof BaseAlert & CompoundAlertType
TypedAlert.displayName = 'Alert'

TypedAlert.Button = AlertButton
export const Alert = TypedAlert
