import type {
  ContractRecordMap,
  PlannableOverlapExtended
} from '@libs/data-access/entities'
import { Badge, Flex } from '@libs/ui/ds'
import { usePlanningFormTranslation } from '@libs/utils/translations'
import {
  ControlledFieldMultiCheckbox,
  FormGroup
} from '@spa/components/FormFields'
import { membershipsSelectors } from '@spa/scenes/Plannings/redux/selectors'
import { keyBy } from 'lodash-es'
import type { FC } from 'react'
import React from 'react'
import type { Control, FieldErrors } from 'react-hook-form'

type Props = {
  contracts: ContractRecordMap
  plannableOverlapExtended: PlannableOverlapExtended
  control: Control
  errors: FieldErrors
}

const ContractsMultiCheckbox: FC<Props> = ({
  control,
  errors,
  contracts,
  plannableOverlapExtended
}) => {
  const { t } = usePlanningFormTranslation()
  const contractsShifts =
    plannableOverlapExtended.contracts_only_shift_overlap || []
  const contractById = keyBy(contracts.toArray(), 'id')
  const memberships = membershipsSelectors.useGetCachedMap()

  return (
    <FormGroup
      id='restform_overlap_plannable'
      noSpaceBottom
      fieldForm={
        <ControlledFieldMultiCheckbox
          size='S'
          selectAllLabel={t('restForm.deleteAll')}
          labelColor='error'
          dynamicItemsDefaultChecked
          inputName='contracts_ids_shift_overlap'
          control={control}
          errors={errors}
          items={contractsShifts.map(item => {
            const membershipId =
              contractById[item.user_contract_id]?.membership_id
            const membership = memberships[membershipId]

            return {
              value: String(item.user_contract_id),
              label: (
                <Flex row className='text-semantic-red-500'>
                  {t('common.app:models.fullname', {
                    value: {
                      firstname: membership?.firstname,
                      lastname: membership?.lastname
                    }
                  })}
                  <Badge
                    size='s'
                    label={item?.shift_count.toString()}
                    color='semantic.red'
                    className='ml-2'
                  />
                </Flex>
              )
            }
          })}
        />
      }
    />
  )
}

export default ContractsMultiCheckbox
