import { appQueryClient } from '../../../provider'

export const membershipQueryKeys = {
  contracts: queryParams => ['membership_contracts', queryParams],
  memberships: (queryParams = {}) => ['memberships', queryParams],
  membership: (queryParams = {}) => ['membership', queryParams],
  membershipNames: (queryParams = {}) => ['memberships', 'names', queryParams],
  update_membership: () => ['update_membership'] as const,
  delete_membership: () => ['delete_membership'] as const
}

export const membershipInvalidations = {
  invalidateContracts: () => {
    appQueryClient.invalidateQueries({
      queryKey: membershipQueryKeys.contracts({})
    })
  },
  invalidateMembership: (membershipId: number) => {
    appQueryClient.invalidateQueries({
      queryKey: membershipQueryKeys.membership(membershipId)
    })
  }
}
