import { createSafeContext } from '@libs/ui/ds/lib/internals'

import type { AvatarProps } from './Avatar.types'

type AvatarContext = {
  loadedImage?: boolean
  setPreview?: (src: string) => void
  loading?: boolean
  size?: AvatarProps['size']
}

export const [AvatarProvider, useAvatarContext] =
  createSafeContext<AvatarContext>({
    context: 'Avatar'
  })
