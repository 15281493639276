import type { GetRoute, PutRoute, PostRoute, DeleteRoute } from '../../types'

import type { PayPeriodSchema } from './schemas'

export const payPeriodRoutes = {
  getPayPeriods: {
    method: 'GET',
    path: '/accounts/:account_id/pay_periods'
  },
  postPayPeriod: { method: 'POST', path: '/pay_periods' },
  getPayPeriod: { method: 'GET', path: '/payperiods/:id' },
  putPayPeriod: { method: 'PUT', path: '/pay_periods/:id' },
  deletePayPeriod: { method: 'DELETE', path: '/pay_periods/:id' },
  batchPayPeriod: { method: 'PUT', path: '/pay_periods/bulk_update' }
} as const

export type PayPeriodRoutes = {
  /**
   * fetch all pay_periods for an account
   */
  getPayPeriods: GetRoute<
    ['account_id'],
    {},
    {
      meta: {
        current_page: number
        per_page: number
        total_count: number
        total_pages: number
      }
      pay_periods: PayPeriodSchema[]
    }
  >
  postPayPeriod: PostRoute<[], {}, {}>
  getPayPeriod: GetRoute<[], {}, {}>
  putPayPeriod: PutRoute<[], {}, {}>
  deletePayPeriod: DeleteRoute<[], {}, {}>
  batchPayPeriod: PutRoute<
    [],
    { locking: boolean; ids: number[] },
    [],
    PayPeriodSchema[]
  >
}
