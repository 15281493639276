import { Center, CloseIcon, IconWrap, LockIcon } from '@libs/ui/ds'
import type { FC, ReactElement, ReactNode } from 'react'

import { IconButton } from '../../../buttons/IconButton/base/IconButton'
import type { SharedDatePickerProps } from '../SharedDatePicker.types'

type DatePickerInputClearButtonProps = {
  fallback: ReactNode
  readOnly: boolean
  disabled: boolean
  onClear: SharedDatePickerProps['onClear']
  clearable: SharedDatePickerProps['clearable']
}

export const DatePickerInputClearButton: FC<
  DatePickerInputClearButtonProps
> = ({ fallback, readOnly, clearable, disabled, onClear }) => {
  if (readOnly) {
    return (
      <Center className='h-full'>
        <IconWrap size='s' icon={<LockIcon />} />
      </Center>
    )
  }

  if (!clearable || !onClear) {
    return fallback as ReactElement
  }

  return (
    <IconButton
      tabIndex={-1}
      size='xs'
      disabled={disabled}
      variant='tertiary'
      intent='neutral-dark'
      icon={<CloseIcon />}
      onClick={e => {
        if (onClear) {
          onClear(e)
        }
      }}
    />
  )
}
