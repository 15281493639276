import { Tooltip as MantineTooltip } from '@mantine/core'
import { cva } from 'class-variance-authority'
import type { ReactElement } from 'react'
import { forwardRef, memo } from 'react'

import type { TooltipProps } from './Tooltip.types'
import { TooltipGroup } from './compound/TooltipGroup'

const tooltipCVA = cva([
  'max-w-72 whitespace-pre-line bg-neutral-800 px-2 py-1 text-xs'
])

export const BaseTooltip = memo(
  forwardRef<HTMLDivElement, TooltipProps>(
    (
      {
        className,
        disabled = false,
        label,
        opened,
        position = 'bottom',
        children,
        ...other
      },
      ref
    ) => {
      if (disabled) {
        return children as ReactElement
      }

      return (
        <MantineTooltip
          ref={ref}
          {...other}
          opened={opened}
          multiline
          position={position}
          openDelay={400}
          closeDelay={200}
          label={label}
          classNames={{ tooltip: tooltipCVA({ className }) }}
        >
          {children}
        </MantineTooltip>
      )
    }
  )
)

type CompoundTooltipType = {
  Group: typeof TooltipGroup
}

const TypedTooltip = BaseTooltip as typeof BaseTooltip & CompoundTooltipType
TypedTooltip.displayName = 'Tooltip'

TypedTooltip.Group = TooltipGroup
export const Tooltip = TypedTooltip
export type { TooltipProps } from './Tooltip.types'
