import type { ReduxState } from '@spa/redux/ReduxState'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import { HALF_DAY } from '@spa/scenes/Plannings/Forms/RestForm/RestFormSection/components/DayFields/DayFields'
import { formatToIsoDay } from '@spa/utils/date'
import { groupBy, map, mapValues, sortBy } from 'lodash-es'
import moment from 'moment'
import createCachedSelector from 're-reselect'
import { getCurrentAccount } from 'snap-redux/modules/accounts/selectors'

import { labelsSelectors } from '../../../entities'
import { getPlannablesByTeam } from '../getPlannablesByTeam/getPlannablesByTeam'

type Params = {
  teamId: number
  weeklyscheduleId: number
}

const selectPlannableByTeam = (state: ReduxState, { teamId }: Params) =>
  getPlannablesByTeam(state, { teamId })

const selectWeeklyscheduleParam = (
  _: ReduxState,
  { weeklyscheduleId }: Params
) => weeklyscheduleId

/**
 * Get shifts from a specific team
 * - Filtrer the shifts from other other weeklyschedules
 * - Generate view model : Group shifts by halfDay (morning/afternoon) then by labels and by day
 */
export const getShiftCardsGroupedByLabelAndDay = createCachedSelector(
  labelsSelectors.getCachedMap,
  selectPlannableByTeam,
  selectWeeklyscheduleParam,
  getCurrentAccount,

  (labelsMap, plannables, weeklyscheduleId, account) => {
    const hourCutNumber = account.cheat_sheet_hour_cut
    const shiftsFromSameWeeklyschedule = plannables.shifts.filter(
      shift => shift.weeklyschedule_id === weeklyscheduleId
    )

    const groupedByHalfDay = groupBy(shiftsFromSameWeeklyschedule, shift =>
      moment(shift.starts_at).hour() < hourCutNumber
        ? HALF_DAY.MORNING
        : HALF_DAY.AFTERNOON
    )

    const groupedByLabel = mapValues(groupedByHalfDay, shifts =>
      sortBy(
        map(
          groupBy(shifts, shift => Number(shift.label_id)),
          (shifts, key) => ({ labelId: Number(key), shifts })
        ),
        val => labelsMap[val.labelId]?.name
      )
    )

    const viewModel = mapValues(groupedByLabel, labelRows => {
      return labelRows.map(labelRow => {
        const sortedShifts = sortBy(labelRow.shifts, shift => shift.starts_at)

        return {
          labelId: labelRow.labelId,
          shiftsByDay: groupBy(sortedShifts, shift =>
            formatToIsoDay(shift.starts_at)
          )
        }
      })
    })

    return {
      morning: viewModel.morning || [],
      afternoon: viewModel.afternoon || [],
      hourCutNumber
    }
  }
)((_, { teamId }) => fallbackToDefaultCacheKey(teamId))
