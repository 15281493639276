import { put, select } from 'redux-saga/effects'

import type { CountersMode } from '../../../reducers/settings/settings'
import { setCountersMode } from '../../../reducers/settings/settings'
import { contractsSelectors, getParams } from '../../../selectors'
import type { PayloadActionWithoutType } from '../../types/types'

import { fetchAndHydrateContractCounters } from './fetchAndHydrateContractCounters'

type Params = PayloadActionWithoutType<{
  countersMode: CountersMode
  teamId?: number
  locationId?: number
}>

/**
 * Retrieve data to fetch user_contract_counters
 * Chunk with a size of 10 contracts ids to prevent timeout on server
 * Then hydrate redux store with user_contract_counters
 */
export function* fetchAndHydrateAllContractCounters({
  payload: { countersMode, teamId, locationId }
}: Params): any {
  const { date } = yield select(getParams)

  const contractIds: number[] = yield select(
    contractsSelectors.getCachedIdsList
  )

  if (!contractIds.length || !date) {
    return null
  }

  yield fetchAndHydrateContractCounters({
    payload: {
      teamId,
      locationId,
      date,
      params: {
        planning_counter_modes: [countersMode]
      }
    }
  })

  yield put(setCountersMode(countersMode))
}
