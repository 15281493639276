import { contractTypes } from '../index'

export const getContractType = contract => {
  const contractType = contract.contract_type

  return {
    isExtra: contractType === contractTypes.EXTRA,
    isTrainee: contractType === contractTypes.TRAINEE,
    isIntern: contractType === contractTypes.INTERN,
    isInterim: contractType === contractTypes.INTERIM,
    isCdi: contractType === contractTypes.CDI,
    isCdd: contractType === contractTypes.CDD,
    isSeasonal: contractType === contractTypes.SEASONAL
  }
}
