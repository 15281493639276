import { CalendarIcon } from '@libs/ui/ds/assets/icons'
import { SimpleCard } from '@libs/ui/ds/lib/components/cards/SimpleCard/base/SimpleCard'
import { Center } from '@libs/ui/ds/lib/components/primitiveLayouts/Center/base/Center'
import { Flex } from '@libs/ui/ds/lib/components/primitiveLayouts/Flex/base/Flex'
import { HideAt } from '@libs/ui/ds/lib/components/primitiveLayouts/HideAt/base/HideAt'
import { Inline } from '@libs/ui/ds/lib/components/primitiveLayouts/Inline/base/Inline'
import { Text } from '@libs/ui/ds/lib/components/typography/Text/base/Text'
import { outlineFocusMixin } from '@libs/ui/ds/lib/styles/mixins/mixins'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'

import { IconWrap } from '../../../../IconWrap/base/IconWrap'

import type { TimelineItemProps } from './TimelineItem.types'

const timelineItemCVA = {
  root: cva(['w-fit gap-2 sm:gap-4']),
  condensedBorder: cva([
    'ml-4 h-4 w-fit rounded-full border-1 border-solid border-brand-green-700'
  ]),
  border: cva(['rounded-full border-1 border-solid border-brand-green-700']),
  header: cva(['items-center gap-4 sm:gap-6']),
  iconContainer: cva([
    'flex rounded-full border-2 border-solid border-brand-green-700 bg-neutral-50 p-2 text-brand-green-700'
  ]),
  desc: cva(['text-s font-bold text-neutral-600']),
  title: cva(['text-m font-bold text-neutral-700']),
  cardContainer: cva(['flex-nowrap gap-10 pl-0 sm:pl-4']),
  interactiveContainer: cva([''], {
    variants: {
      clickable: {
        true: [
          'cursor-pointer hover:border-brand-green-500 active:border-brand-green-800',
          outlineFocusMixin
        ]
      }
    }
  })
}

export const TimelineItem: FC<TimelineItemProps> = ({
  title,
  icon = <CalendarIcon />,
  condensed = false,
  rightSection,
  desc,
  children,
  onClick,
  ...other
}) => (
  <Flex className={timelineItemCVA.root()} {...other}>
    {!condensed && (
      <HideAt hideAt='min-sm'>
        <div className={timelineItemCVA.condensedBorder()} />
      </HideAt>
    )}
    <Inline stretch='end' className={timelineItemCVA.header()}>
      <div className={timelineItemCVA.iconContainer()}>
        <IconWrap icon={icon} size='s' />
      </div>
      <Flex>
        <Text className={timelineItemCVA.desc()}>{desc}</Text>
        <Text className={timelineItemCVA.title()}>{title}</Text>
      </Flex>
      <Flex row className='justify-end'>
        {rightSection}
      </Flex>
    </Inline>
    <Flex row className={timelineItemCVA.cardContainer()}>
      <HideAt hideAt='max-sm'>
        <div className={timelineItemCVA.border()} />
      </HideAt>
      <SimpleCard
        {...(onClick && { tabIndex: 0 })}
        onClick={onClick}
        className={timelineItemCVA.interactiveContainer({
          clickable: Boolean(onClick)
        })}
      >
        <Center>{children}</Center>
      </SimpleCard>
    </Flex>
  </Flex>
)
