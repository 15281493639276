import { httpServices } from '../../../apis'
import type { QueryFnParams } from '../../../types'

export type FetchMonthlyRow = QueryFnParams<{
  queryParams: {
    location_id?: string
    team_id?: string
    start_date: string
    end_date: string
  }
}>

export type MovePlannable = QueryFnParams<{
  payload: {
    membership_id: number
    date: string
  }
  pathParams: { id: number }
}>

export type DuplicatePlannable = MovePlannable

export type DeletePlannable = QueryFnParams<{
  pathParams: { id: number }
}>

export const fetchMonthlyRow = async (
  opts?: FetchMonthlyRow,
  pageParam?: number | undefined
) => {
  const response = await httpServices.core.get('/schedule_viewer/rows', {
    params: { ...opts?.queryParams, page: pageParam }
  })
  return response.data
}

export type FetchMonthlyHeader = QueryFnParams<{
  queryParams: {
    location_id?: string
    team_id?: string
    start_date: string
    end_date: string
  }
}>

export const fetchMonthlyHeader = async (opts?: FetchMonthlyRow) => {
  const response = await httpServices.core.get('/schedule_viewer/header', {
    params: { ...opts?.queryParams }
  })
  return response.data
}

export const moveShift = async (opts?: MovePlannable) => {
  const response = await httpServices.core.put(
    `/planning/shifts/${opts?.pathParams.id}/move`,
    opts?.payload
  )
  return response.data
}

export const moveRest = async (opts?: MovePlannable) => {
  const response = await httpServices.core.put(
    `/planning/rests/${opts?.pathParams.id}/move`,
    opts?.payload
  )
  return response.data
}

export const duplicateShift = async (opts?: DuplicatePlannable) => {
  const response = await httpServices.core.post(
    `/planning/shifts/${opts?.pathParams.id}/duplicate`,
    opts?.payload
  )
  return response.data
}

export const duplicateRest = async (opts?: DuplicatePlannable) => {
  const response = await httpServices.core.post(
    `/planning/rests/${opts?.pathParams.id}/duplicate`,
    opts?.payload
  )
  return response.data
}

export const deleteShift = async (opts?: DeletePlannable) => {
  const response = await httpServices.core.delete(
    `/shifts/${opts?.pathParams.id}`
  )
  return response.data
}

export const deleteRest = async (opts?: DeletePlannable) => {
  const response = await httpServices.core.delete(
    `/rests/${opts?.pathParams.id}`
  )
  return response.data
}
