import { cva } from 'class-variance-authority'
import { isNumber } from 'lodash-es'
import { forwardRef, memo, useMemo } from 'react'

import type { ProgressBarProps } from './ProgressBar.types'

const progressBarCVA = {
  root: cva(['h-2 w-full overflow-hidden rounded-full bg-neutral-200']),
  inner: cva(['h-full rounded-full bg-brand-green-500'])
}

const computeInnerWidth = (value: number, max: number) => {
  if (!isNumber(value) || !isNumber(max)) {
    return '0%'
  }
  if (value > max) {
    return '100%'
  }
  return `${(value / max) * 100}%`
}

export const ProgressBar = memo(
  forwardRef<HTMLDivElement, ProgressBarProps>(
    ({ className, value, max = 100, ...other }, ref) => {
      const innerWidth = useMemo(
        () => computeInnerWidth(value, max),
        [value, max]
      )

      return (
        <div
          ref={ref}
          {...other}
          className={progressBarCVA.root({ className })}
        >
          <div
            style={{ width: innerWidth }}
            className={progressBarCVA.inner()}
          />
        </div>
      )
    }
  )
)
ProgressBar.displayName = 'ProgressBar'

export type { ProgressBarProps } from './ProgressBar.types'
