import type { ReactNode } from 'react'
import { createContext, useContext, useMemo } from 'react'

export function createSafeContext<ContextValue>({
  context
}: {
  context: string
}) {
  const Context = createContext<ContextValue | null>(null)

  const useSafeContext = (opts?: { throwErrorIfNoContext?: boolean }) => {
    const ctx = useContext(Context)
    if (opts?.throwErrorIfNoContext && (ctx as any)?.context !== context) {
      throw new Error(`${context} context was not found in the tree`)
    }

    return ctx
  }

  const Provider = ({
    children,
    value
  }: {
    value: ContextValue
    children: ReactNode
  }) => {
    /**
     * Initialize custom context value
     * The [_context] key serve as a safeguard to make sure the context in found in react tree
     */
    const val = useMemo(() => {
      return { context, ...value }
    }, [value])

    return <Context.Provider value={val}>{children}</Context.Provider>
  }

  return [Provider, useSafeContext] as const
}
