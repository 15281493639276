import type { ReduxState } from '@spa/redux/ReduxState'
import { useMemoSelector } from '@spa/redux/hooks'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'

import { payPeriodsSelectors } from '../../entities/payPeriods'
import { getEditRestPolicies } from '../useEditRestPolicies/useEditRestPolicies'

type Params = {
  restId: number
  weeklyscheduleId: number
  day: string
}

const getRestPolicies = (
  state: ReduxState,
  { restId, weeklyscheduleId }: Params
) =>
  getEditRestPolicies(state, {
    restId,
    weeklyscheduleId
  })

const getIsRestLockedOnDay = (state: ReduxState, { day }: Params) =>
  payPeriodsSelectors.getLockedDaysInTimerange(state)[day]?.isLocked

const computeRndRestPolicies = (
  { canEditRest }: { canEditRest: boolean },
  isLocked: boolean
) => {
  return {
    canRndRest: canEditRest && !isLocked
  }
}

export const getRndRestPolicies = createCachedSelector(
  getRestPolicies,
  getIsRestLockedOnDay,
  computeRndRestPolicies
)((_, { restId }) => fallbackToDefaultCacheKey(restId))

export const useRndRestPolicies = (opts: Params) => {
  return useMemoSelector(state => getRndRestPolicies(state, opts))
}
