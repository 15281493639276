import { forwardRef } from 'react'

import { breadcrumbCVA } from './Breadcrumb.classes'
import type { BreadcrumbProps } from './Breadcrumb.types'
import { BreadcrumbItem } from './subs/BreadcrumbItem'

const BaseBreadcrumb = forwardRef<HTMLDivElement, BreadcrumbProps>(
  ({ items, className, ...other }, ref) => {
    const enabledItems = items.filter(i => !i.disabled)
    return (
      <div ref={ref} className={breadcrumbCVA.root({ className })} {...other}>
        {enabledItems.map((item, idx) => (
          <BreadcrumbItem
            key={item.label}
            item={item}
            isLast={idx === enabledItems.length - 1}
          />
        ))}
      </div>
    )
  }
)

const TypedBreadcrumb = BaseBreadcrumb as typeof BaseBreadcrumb
TypedBreadcrumb.displayName = 'Breadcrumb'

export const Breadcrumb = TypedBreadcrumb
export type { BreadcrumbProps } from './Breadcrumb.types'
