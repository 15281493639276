import { FieldError } from '@spa/components/FormFields'
import withController from '@spa/hoc/withController'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import uuid from 'uuid/v4'

import InputTimeMask from '../InputTimeMask'
import type { InputTimeMaskProps } from '../InputTimeMask/InputTimeMask'

import styles from './FieldTimeRange.module.css'

export type DoubleStringTuple = [string, string]

export type FieldTimeRangeProps = {
  disabled?: boolean
  error?: string
  inputId?: string
  onBlur?: InputEvent
  onChange?: (val?: DoubleStringTuple) => void
  placeholders?: DoubleStringTuple
  prefixLabels?: DoubleStringTuple
  values?: DoubleStringTuple
  startEl?: Partial<InputTimeMaskProps>
  endEl?: Partial<InputTimeMaskProps>
  highlightError?: boolean
  dataTestId?: string
  syncDS?: boolean
}

export const FieldTimeRange: FC<FieldTimeRangeProps> = ({
  disabled = false,
  error,
  inputId,
  onBlur,
  onChange,
  placeholders = [],
  prefixLabels = [],
  values = ['', ''],
  startEl,
  endEl,
  highlightError,
  syncDS
}) => {
  const inputNameKey = useMemo(() => uuid(), [])

  /**
   * Format data from a string to [string, string]
   * Each FieldTime change would return a new string value
   * We want to format this data before to emit a onChange from FieldTimeRange
   * @param newValue
   * @param index
   */
  const handleChange = (newValue: string, index: number) => {
    if (onChange) {
      const updatedValues = values.map((val, i) =>
        i === index ? newValue : val
      )
      return onChange(updatedValues as DoubleStringTuple)
    }
  }

  return (
    <div>
      <div className={styles.InputRow}>
        {['first', 'second'].map((_, i) => {
          const isFirst = i === 0
          return (
            <InputTimeMask
              key={`input-time-${i}`}
              value={values[i]}
              onBlur={onBlur}
              prefixLabel={prefixLabels[i]}
              onChange={newValue => handleChange(newValue, i)}
              disabled={disabled}
              placeholder={placeholders[i]}
              highlightError={!!error || highlightError}
              inputName={`input-time-${i}`}
              dataTestId={`input-time-${i}`}
              // Only first item props
              {...(isFirst && {
                inputId,
                inputName: inputNameKey,
                ...startEl
              })}
              // Only second item props
              {...(!isFirst && {
                ...endEl
              })}
              syncDS={syncDS}
              containerClassName={!isFirst ? 'ml-2' : ''}
            />
          )
        })}
      </div>
      {error && <FieldError error={error} />}
    </div>
  )
}

export const ControlledFieldTimeRange = withController<
  FieldTimeRangeProps,
  [string, string]
>({ valueName: 'values' })(FieldTimeRange)
