import { useDSTranslation } from '@libs/ui/ds/lib/internals'
import type { FC } from 'react'
import React from 'react'

import { Combobox } from '../../../../overlays/Combobox/base/Combobox'
import type { SelectProps } from '../Select.types'

type SelectEmptyProps = {
  enabled?: boolean
  options: SelectProps['options']
  emptyListLabel: SelectProps['emptyListLabel']
}

export const SelectEmpty: FC<SelectEmptyProps> = ({
  enabled = true,
  options,
  emptyListLabel
}) => {
  const { t } = useDSTranslation()
  if (options?.length || !enabled) {
    return null
  }

  return (
    <Combobox.Empty data-testid='combo-empty-list'>
      {emptyListLabel || t('ds.components.select.defaultNothingFound')}
    </Combobox.Empty>
  )
}
