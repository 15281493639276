import { blobService } from '@libs/data-access/queries'
import { notifications } from '@libs/ui/ds'
import i18n from '@libs/utils/translations'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import type { ParamsDownload } from '../modules/entities'
import { setMetaLoading, setMetaSuccess, setMetaError } from '../modules/meta'

export default function* getSsrRegisteryFileData(queryParams: ParamsDownload) {
  yield put(setMetaLoading())

  try {
    const promise = apiRequest({
      service: 'file',
      route: 'getSsrRegisteryFile',
      queryParams: { location_id: queryParams.location_id }
    })

    notifications.promise(promise, {
      loading: {
        message: i18n.t<any>('common.notifications:ssr.download.loading')
      },
      success: {
        message: i18n.t<any>('common.notifications:ssr.download.success')
      },
      apiError: true
    })

    const result = yield promise

    yield put(setMetaSuccess())
    const url = blobService.createBlobUrl(result)
    blobService.downloadPDF(url, { filename: queryParams.name })
  } catch (err) {
    yield put(setMetaError())
  }
}
