import type { LocationGroupBillingInfo } from '@libs/data-access/entities'
import { useQuery } from '@tanstack/react-query'

import { queryClientOptions } from '../../../../provider'
import type { UseAppQueryOptions } from '../../../../types'
import type { FetchLocationGroupBillingInfo } from '../locationGroupBillingInfo.api'
import { fetchLocationGroupBillingInfo } from '../locationGroupBillingInfo.api'
import { locationGroupBillingInfoCacheKeys } from '../locationGroupBillingInfo.cacheKeys'

export const useGetLocationGroupBillingInfo = (
  request: FetchLocationGroupBillingInfo,
  queryOptions?: UseAppQueryOptions<LocationGroupBillingInfo>
) => {
  return useQuery({
    ...queryOptions,
    queryKey: locationGroupBillingInfoCacheKeys.getByLocationGroupId(request),
    queryFn: () => fetchLocationGroupBillingInfo(request),
    staleTime: queryClientOptions.time.INFINITY
  })
}
