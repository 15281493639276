import { SimpleCard, Text } from '@libs/ui/ds'
import { Flex, Title } from '@libs/ui/ds/lib/components'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import { memo } from 'react'

import type { SectionCardProps } from './SectionCard.types'

const sectionCardCVA = {
  root: cva(['relative w-full']),
  header: cva(['mb-6 min-h-9 min-w-0 flex-nowrap gap-4'], {
    variants: {
      withDescription: { true: 'items-start', false: 'items-center' }
    }
  }),
  headerTitleSection: cva(['inline-flex w-fit items-center gap-4']),
  headerDescriptionSection: cva(['text-neutral-600']),
  headerElementSection: cva(['w-full items-center justify-between']),
  headerLeftSection: cva(['justify-start']),
  headerRightSection: cva(['justify-end'])
}

export const SectionCard: FC<SectionCardProps> = memo(
  ({
    leftIcon,
    title,
    description,
    headerLeftSection,
    headerRightSection,
    children,
    className,
    ...other
  }) => {
    return (
      <SimpleCard
        {...other}
        className={className}
        screens={{ sm: { size: 'm' } }}
        size='s'
      >
        <Flex
          row
          className={sectionCardCVA.header({
            withDescription: Boolean(description)
          })}
        >
          <div className={sectionCardCVA.headerTitleSection()}>
            {leftIcon && <div className='self-start'>{leftIcon}</div>}
            <Flex>
              <Title size={3} className='w-max'>
                {title}
              </Title>
              {description && (
                <Text className={sectionCardCVA.headerDescriptionSection()}>
                  {description}
                </Text>
              )}
            </Flex>
          </div>
          <Flex row className={sectionCardCVA.headerElementSection()}>
            <Flex row className={sectionCardCVA.headerLeftSection()}>
              {headerLeftSection}
            </Flex>
            <Flex row className={sectionCardCVA.headerRightSection()}>
              {headerRightSection}
            </Flex>
          </Flex>
        </Flex>
        <div>{children}</div>
      </SimpleCard>
    )
  }
)

SectionCard.displayName = 'SectionCard'
export type { SectionCardProps } from './SectionCard.types'
