import type { Model } from '@libs/data-access/entities'
import { schema } from 'normalizr'
import type { UserContractCountersSchema } from 'snap-redux/infra/http/routesConfig/scopes/userContractCounters'

export type ContractCounterModel = Model<UserContractCountersSchema, {}>

const ContractCounter = new schema.Entity<ContractCounterModel>(
  'contractCounters',
  {},
  { idAttribute: 'contract_id' }
)

export default ContractCounter
