import type {
  ContractSchema,
  MembershipSchema,
  NormalizeEntity
} from '@libs/data-access/entities'
import { sortBy } from 'lodash-es'

type Params = {
  teamId: number
  contractIds: number[]
  memberships: NormalizeEntity<MembershipSchema>
  contracts: NormalizeEntity<ContractSchema>
}

export const sortContractsByMembershipIndex = ({
  teamId,
  contractIds,
  contracts,
  memberships
}: Params) => {
  return sortBy(contractIds, id => {
    const membershipId = contracts[id].membership_id
    return memberships[membershipId].sort_index[teamId]
  })
}
