import Immutable from 'immutable'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_PAYROLL_SOFTWARE_CONFIGURATIONS,
  FETCH_PAYROLL_SOFTWARE_CONFIGURATIONS_SUCCEEDED,
  FETCH_PAYROLL_SOFTWARE_CONFIGURATIONS_FAILED,
  UPDATE_PAYROLL_SOFTWARE_CONFIGURATION,
  UPDATE_PAYROLL_SOFTWARE_CONFIGURATION_SUCCEEDED,
  UPDATE_PAYROLL_SOFTWARE_CONFIGURATION_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords, updateRecord } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchPayrollSoftwareConfigurations = (
  accountId,
  resolve,
  reject
) => ({
  type: FETCH_PAYROLL_SOFTWARE_CONFIGURATIONS,
  accountId,
  resolve,
  reject
})

export const updatePayrollSoftwareConfiguration = (
  payrollSoftwareConfigurationId,
  payrollSoftwareConfigurationData,
  resolve,
  reject
) => ({
  type: UPDATE_PAYROLL_SOFTWARE_CONFIGURATION,
  payrollSoftwareConfigurationId,
  payrollSoftwareConfigurationData,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const fetchPayrollSoftwareConfigurationsSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_PAYROLL_SOFTWARE_CONFIGURATIONS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_PAYROLL_SOFTWARE_CONFIGURATIONS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options) =>
    callAPI(`/accounts/${accountId}/payroll_software_configurations`, options)
}

export function* doFetchPayrollSoftwareConfigurations({
  accountId,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    fetchPayrollSoftwareConfigurationsSagaEntity,
    accountId,
    null,
    undefined,
    resolve,
    reject
  )
}

const updatePayrollSoftwareConfigurationSagaEntity = {
  success: (data, payrollSoftwareConfigurationId) => ({
    type: UPDATE_PAYROLL_SOFTWARE_CONFIGURATION_SUCCEEDED,
    data,
    payrollSoftwareConfigurationId
  }),
  failure: (error, payrollSoftwareConfigurationId) => ({
    type: UPDATE_PAYROLL_SOFTWARE_CONFIGURATION_FAILED,
    error,
    payrollSoftwareConfigurationId
  }),

  fetchAPI: (payrollSoftwareConfigurationId, options) =>
    callAPI(
      `/payroll_software_configurations/${payrollSoftwareConfigurationId}`,
      {
        method: 'PUT',
        ...options
      }
    )
}

export function* doUpdatePayrollSoftwareConfiguration({
  payrollSoftwareConfigurationId,
  payrollSoftwareConfigurationData,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    updatePayrollSoftwareConfigurationSagaEntity,
    payrollSoftwareConfigurationId,
    null,
    payrollSoftwareConfigurationData,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const PayrollSoftwareConfigurations = Immutable.Record({
  id: null,
  account_id: null,
  payroll_software_id: null,
  codes: Immutable.List(),
  label_codes: Immutable.List()
})

const payrollSoftwareConfigurationsForeignKeys = [
  'payroll_software_id',
  'account_id'
]

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function payrollSoftwareConfigurations(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_PAYROLL_SOFTWARE_CONFIGURATIONS: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_PAYROLL_SOFTWARE_CONFIGURATIONS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_PAYROLL_SOFTWARE_CONFIGURATIONS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const payrollSoftwareConfigurations = Immutable.fromJS(action.data)

      return mergeRecords(
        state,
        PayrollSoftwareConfigurations,
        payrollSoftwareConfigurations,
        // @ts-ignore migration from js(x) to ts(x)
        payrollSoftwareConfigurationsForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    case UPDATE_PAYROLL_SOFTWARE_CONFIGURATION_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const payrollSoftwareConfiguration = Immutable.fromJS(action.data)

      return updateRecord(
        state,
        PayrollSoftwareConfigurations,
        payrollSoftwareConfiguration,
        // @ts-ignore migration from js(x) to ts(x)
        payrollSoftwareConfigurationsForeignKeys
      )
    }
    default:
      return state
  }
}

export default filterActions(payrollSoftwareConfigurations, [
  FETCH_PAYROLL_SOFTWARE_CONFIGURATIONS,
  FETCH_PAYROLL_SOFTWARE_CONFIGURATIONS_FAILED,
  FETCH_PAYROLL_SOFTWARE_CONFIGURATIONS_SUCCEEDED
])
