export const AlertTriangleBoldIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.8089 16.7192L11.4785 0.891356C11.3373 0.622515 11.1253 0.397379 10.8654 0.24028C10.6055 0.0831809 10.3077 9.43514e-05 10.004 0C9.70033 9.43514e-05 9.40247 0.0831809 9.14259 0.24028C8.88272 0.397379 8.67072 0.622515 8.52952 0.891356L0.199087 16.7192C0.0603818 16.9768 -0.00802484 17.2664 0.000749386 17.5589C0.00952362 17.8514 0.0951693 18.1364 0.24907 18.3853C0.400879 18.625 0.611413 18.822 0.860711 18.9576C1.11001 19.0932 1.38981 19.1629 1.67357 19.16H18.3344C18.6212 19.1599 18.9031 19.0858 19.1528 18.9448C19.4026 18.8039 19.6117 18.6008 19.76 18.3554C19.9083 18.1099 19.9907 17.8303 19.9993 17.5437C20.0078 17.2571 19.9423 16.9731 19.8089 16.7192ZM9.17096 6.66434C9.17096 6.44341 9.25873 6.23152 9.41495 6.07529C9.57118 5.91907 9.78307 5.8313 10.004 5.8313C10.2249 5.8313 10.4368 5.91907 10.5931 6.07529C10.7493 6.23152 10.837 6.44341 10.837 6.66434V11.6626C10.837 11.8835 10.7493 12.0954 10.5931 12.2517C10.4368 12.4079 10.2249 12.4956 10.004 12.4956C9.78307 12.4956 9.57118 12.4079 9.41495 12.2517C9.25873 12.0954 9.17096 11.8835 9.17096 11.6626V6.66434ZM10.0457 16.2443C9.71755 16.2424 9.40281 16.1141 9.16695 15.886C8.9311 15.6579 8.79229 15.3476 8.77943 15.0198C8.77386 14.8551 8.80136 14.691 8.86029 14.5371C8.91923 14.3832 9.00842 14.2427 9.12258 14.1239C9.23674 14.0051 9.37355 13.9104 9.52495 13.8453C9.67634 13.7803 9.83923 13.7462 10.004 13.7452C10.3321 13.7471 10.6468 13.8755 10.8827 14.1036C11.1186 14.3317 11.2574 14.6419 11.2702 14.9698C11.2758 15.1345 11.2483 15.2986 11.1894 15.4525C11.1304 15.6063 11.0412 15.7468 10.9271 15.8656C10.8129 15.9845 10.6761 16.0792 10.5247 16.1442C10.3733 16.2093 10.2104 16.2433 10.0457 16.2443Z'
        fill='currentColor'
      />
    </svg>
  )
}
