import type { ContractSchema } from '@libs/data-access/entities'
import type { InvertBulkShiftsResponse } from '@libs/data-access/queries'
import { shiftService } from '@libs/data-access/queries'
import type { ReduxState } from '@spa/redux/ReduxState'
import type {
  CopiedTarget,
  SelectedTarget
} from '@spa/scenes/Plannings/redux/reducers'
import {
  contractsSelectors,
  getParams
} from '@spa/scenes/Plannings/redux/selectors'
import { select } from 'redux-saga/effects'

import fetchPlannablesSideEffectsEntities from '../../../controllers/request/fetchPlannableSideEffectsEntities'
import type { PayloadActionWithoutType } from '../../../types/types'

function* bulkInvertShifts({
  payload: { copied, selected }
}: PayloadActionWithoutType<{
  copied: CopiedTarget
  selected: SelectedTarget
}>) {
  const params = yield select(getParams)
  const data: any = yield select((state: ReduxState) =>
    contractsSelectors.getCachedMap(state)
  )
  const contractMap: Record<number, ContractSchema> = data

  const res: InvertBulkShiftsResponse =
    yield shiftService.bulk.api.invertBulkShifts({
      payload: {
        target: {
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          date: selected.data.day,
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          membership_id: contractMap[selected.data.contractId]?.membership_id,
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          weeklyschedule_id: selected.data.weeklyscheduleId
        },
        source: {
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          date: copied.data.day,
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          membership_id: contractMap[copied.data.contractId]?.membership_id,
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          weeklyschedule_id: copied.data.weeklyscheduleId
        }
      }
    })

  yield fetchPlannablesSideEffectsEntities({
    contractIds: res.data.resources.map(i => i.contract_id),
    date: params.date,
    countersParams: { live: true }
  })
}
export default bulkInvertShifts
