import { INTERCOM_APP_ID } from '@libs/utils/environments'
import { type FC } from 'react'
import { IntercomProvider } from 'react-use-intercom'

import { HandleBoot } from './HandleBoot'
import { HandleUnboot } from './HandleUnboot'

export const UnauthenticatedCustomerCareMenu: FC = () => {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <HandleBoot />
      <HandleUnboot />
    </IntercomProvider>
  )
}
