import type { WeeklyScheduleSchema } from '@libs/data-access/entities'
import moment from 'moment'
import { all, fork, select } from 'redux-saga/effects'

import type { CopiedTarget, SelectedTarget } from '../../../reducers'
import { shiftsSelectors, weeklyschedulesSelectors } from '../../../selectors'
import type { PayloadActionWithoutType } from '../../types/types'

import copyShift from './copyShift'

function* createShiftsFromCopiedSelection({
  payload: { copied, selected }
}: PayloadActionWithoutType<{
  copied: CopiedTarget
  selected: SelectedTarget
}>) {
  const shiftsMap = yield select(shiftsSelectors.getCachedMap)
  const shifts = copied.elements.shiftIds.map(id => shiftsMap[id])
  const weeklyschedule = yield select(state =>
    weeklyschedulesSelectors.getCachedById(
      state,
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      selected.data.weeklyscheduleId
    )
  )

  if (selected.target === 'cell' && copied.target === 'cell') {
    yield bulkCreateShifts(shifts, weeklyschedule, {
      ...selected.data
    })
  }

  if (selected.target === 'row' && ['cell', 'row'].includes(copied.target)) {
    yield bulkCreateShifts(shifts, weeklyschedule, {
      ...selected.data
    })
  }

  if (selected.target === 'day' && copied.target === 'day') {
    yield bulkCreateShifts(shifts, weeklyschedule, {
      ...selected.data
    })
  }
}

const computeWeeklyscheduleDate = (
  weeklyschedule: WeeklyScheduleSchema,
  shift
) => {
  const diff = moment(weeklyschedule.week).diff(
    moment(shift.starts_at).startOf('isoWeek'),
    'week'
  )

  return moment(shift.starts_at).add(diff, 'week')
}

/**
 *
 */
function* bulkCreateShifts(
  shifts,
  weeklyschedule,
  { contractId, weeklyscheduleId, day }: any
) {
  yield all(
    shifts.map(shift => {
      return fork(function* () {
        yield copyShift({
          payload: {
            shiftId: shift.id,
            to: {
              weeklyscheduleId,
              contractId: contractId || shift.user_contract_id,
              date: day || computeWeeklyscheduleDate(weeklyschedule, shift)
            }
          }
        })
      })
    })
  )
}

export default createShiftsFromCopiedSelection
