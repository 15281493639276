import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_PAYROLL,
  FETCH_PAYROLL_SUCCEEDED,
  FETCH_PAYROLL_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { updateRecord } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchPayroll = (payrollId, params, resolve, reject) => ({
  type: FETCH_PAYROLL,
  payrollId,
  params,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const payrollSagaEntity = {
  success: (data, payrollId) => ({
    type: FETCH_PAYROLL_SUCCEEDED,
    data,
    payrollId
  }),
  failure: (error, payrollId) => ({
    type: FETCH_PAYROLL_FAILED,
    error,
    payrollId
  }),

  fetchAPI: (payPeriodId, options, params) =>
    callAPI(
      `/pay_periods/${payPeriodId}/payroll?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchPayroll({ payrollId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    payrollSagaEntity,
    payrollId,
    params,
    undefined,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const Payroll = Immutable.Record({
  id: null,
  start_date: null,
  end_date: null,
  account_id: null,
  pay_period_id: null,
  rows: []
})

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function payrolls(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_PAYROLL: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_PAYROLL_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false })
    }
    case FETCH_PAYROLL_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const payroll = Immutable.fromJS(action.data)

      return updateRecord(state, Payroll, payroll)
        .updateIn(
          ['relations', 'account_id', payroll.get('account_id')],
          // @ts-ignore migration from js(x) to ts(x)
          new Immutable.Set(),
          item_ids => item_ids.add(payroll.get('id'))
        )
        .updateIn(
          ['relations', 'pay_period_id', payroll.get('pay_period_id')],
          // @ts-ignore migration from js(x) to ts(x)
          new Immutable.Set(),
          item_ids => item_ids.add(payroll.get('id'))
        )
        .mergeDeepIn(['meta'], {
          loading: false,
          updated_at: Date.now(),
          success: true
        })
    }
    default:
      return state
  }
}

export default filterActions(payrolls, [
  FETCH_PAYROLL,
  FETCH_PAYROLL_FAILED,
  FETCH_PAYROLL_SUCCEEDED
])
