import { useMemoSelector } from '@spa/redux/hooks'
import { createSelector } from 'reselect'
import type { Shift } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import { rowsSelectors } from '../../rows'
import { shiftsBaseSelectors } from '../shiftsBaseSelectors'

/**
 * This selector aims to return shifts that are up to date
 *
 * When deleting shifts, we don't delete the shifts entries in the store, resulting in stale data.
 * The fresh data relations are stored in the rows entity
 */
export const getFreshShiftsList = createSelector(
  shiftsBaseSelectors.getCachedMap,
  rowsSelectors.getCachedList,
  (shiftsMap, rowsList) => {
    const displayedShiftList = rowsList.flatMap(row =>
      row.shifts.map(shiftId => shiftsMap[shiftId])
    )
    return displayedShiftList as Shift[]
  }
)

export const useGetFreshShiftsList = () => {
  return useMemoSelector(getFreshShiftsList)
}
