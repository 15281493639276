import classnames from 'classnames'
import type { HTMLProps } from 'react'
import React, { forwardRef } from 'react'
import MaskedInput from 'react-text-mask'

import styles from './InputTimeMask.module.css'

export type InputTimeMaskProps = {
  disabled?: boolean
  locked?: boolean
  inputId?: string
  inputName: string
  inputProps?: HTMLProps<HTMLInputElement>
  onBlur?: InputEvent
  onChange: Function
  placeholder?: string
  prefixLabel?: string
  value: string
  highlightError?: boolean
  dataTestId?: string
  syncDS?: boolean
  containerClassName?: string
}

export const hoursRegex = /^[0-2][0-9]:[0-5][0-9]$/
export const isValidTime = (time: string) => {
  return hoursRegex.test(time)
}

const InputTimeMask = forwardRef<
  { inputElement: HTMLInputElement },
  InputTimeMaskProps
>(
  (
    {
      disabled,
      inputId,
      locked,
      inputName,
      inputProps = {},
      onBlur,
      onChange,
      placeholder,
      prefixLabel,
      value,
      highlightError,
      dataTestId,
      syncDS,
      containerClassName
    },
    ref
  ) => {
    const hoursValueStartWithNumberTwo = value && value[0] === '2'
    const mask = [
      /[0-2]/,
      hoursValueStartWithNumberTwo ? /[0-3]/ : /[0-9]/,
      ':',
      /[0-5]/,
      /[0-9]/
    ]

    return (
      <div
        className={classnames(
          styles.InputContainer,
          {
            [styles.InputContainerLocked]: locked,
            [styles.InputContainerDisabled]: disabled || inputProps.disabled,
            [styles.InputContainerError]: highlightError,
            [styles.InputFieldTimeDS]: syncDS
          },
          containerClassName
        )}
      >
        {prefixLabel && <div className={styles.PrefixLabel}>{prefixLabel}</div>}
        <MaskedInput
          mask={mask}
          placeholder={placeholder}
          onBlur={onBlur}
          keepCharPositions={false}
          id={inputId || inputName}
          disabled={disabled || locked}
          onChange={e => onChange(e.target.value)}
          value={value}
          ref={ref}
          data-testid={dataTestId}
          {...inputProps}
        />
      </div>
    )
  }
)

export default InputTimeMask
