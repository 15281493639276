import { billingService } from '@libs/data-access/queries'
import {
  AlertTriangleBoldIcon,
  DropdownMenu,
  IconWrap,
  Tooltip
} from '@libs/ui/ds'
import { useAppNavBarTranslation } from '@libs/utils/translations'
import { routes } from '@spa/core/router/appRoutesConfig'
import useUpgradeUrl from '@spa/redux/billing/plan/hooks/useUpgradeUrl'
import { NOT_PAID } from '@spa/scenes/Subscription/constant'
import React from 'react'
import type { FC } from 'react'
import { Link, generatePath } from 'react-router-dom'

const i18nKeys = {
  willExpire: 'willExpire',
  hasExpired: 'hasExpired',
  paymentFailed: 'paymentFailed'
}

export const SubscriptionOption: FC = () => {
  const { t } = useAppNavBarTranslation()
  const lastInvoice = billingService.account.useLastInvoice()
  const isLastInvoiceUnpaid = lastInvoice?.status === NOT_PAID

  const detailsQuery = billingService.account.useGetDetails()
  const statusQuery = billingService.account.useGetStatus()
  const notPaidInvoicesQuery = billingService.account.useGetNotPaidInvoices()

  const { url: upgradeUrl } = useUpgradeUrl('subscription')
  const { data: accountStatusData, isLoading } =
    billingService.account.useGetStatus()

  if (isLoading || !accountStatusData || !notPaidInvoicesQuery.isSuccess) {
    return null
  }

  const getStatusKey = () => {
    if (accountStatusData.actions.warn_last_trial_day) {
      return i18nKeys.willExpire
    }
    if (
      accountStatusData.actions.warn_trial_has_expired &&
      !accountStatusData.is_active
    ) {
      return i18nKeys.hasExpired
    }
    if (isLastInvoiceUnpaid) {
      return i18nKeys.paymentFailed
    }

    return null
  }

  const statusKey = getStatusKey()

  const dropdownContent = (
    <DropdownMenu.Option>
      {t('userMenu.subscription')}
      {statusKey && (
        <Tooltip
          position='left'
          id='subscription_warning'
          label={t('userMenu.subscriptionWarningMessage.status', {
            context: statusKey
          })}
        >
          <IconWrap icon={<AlertTriangleBoldIcon />} size='s' />
        </Tooltip>
      )}
    </DropdownMenu.Option>
  )

  // Render if subscription is activated
  // Render if LB + churned + FF is activated + non_renewing or cancelled
  if (
    accountStatusData.is_active ||
    (detailsQuery?.data?.is_location_based &&
      (statusQuery?.data?.is_non_renewing ||
        statusQuery?.data?.is_cancelled ||
        notPaidInvoicesQuery.data.length))
  ) {
    return (
      <Link
        to={generatePath(routes.subscription.path)}
        data-testid='navbar-subscription'
      >
        {dropdownContent}
      </Link>
    )
  }

  return (
    <a href={upgradeUrl} rel='noreferrer' data-testid='navbar-subscription'>
      {dropdownContent}
    </a>
  )
}
