import { notifications } from '@libs/ui/ds'
import { errorLoggerService } from '@libs/utils/error-monitoring'
import i18n from '@libs/utils/translations'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import type { ParamsPostBulkPaidLeaveLedgerOps } from '../modules/entities'
import { setMetaError, setMetaLoading, setMetaSuccess } from '../modules/meta'

export default function* postBulkPaidLeaveLedgerOpsData(
  params: ParamsPostBulkPaidLeaveLedgerOps
) {
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  yield put(setMetaLoading())

  try {
    const promise = apiRequest({
      route: 'postPaidLeaveLedgerOp',
      params: {
        location_id: params.locationId
      },
      body: { adjustments: params.adjustements }
    })

    notifications.promise(promise, {
      loading: {
        message: i18n.t<any>('common.notifications:paidLeave.create.loading')
      },
      success: {
        message: i18n.t<any>('common.notifications:paidLeave.create.success')
      },
      error: {
        message: i18n.t<any>('common.notifications:paidLeave.create.error')
      }
    })

    yield promise
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    yield put(setMetaSuccess())
    if (params?.successCallback) {
      yield params.successCallback()
    }
  } catch (err) {
    errorLoggerService.apiError(err)
    yield put(setMetaError({ rawError: err }))
    if (params?.errorCallback) {
      yield params.errorCallback()
    }
  }
}
