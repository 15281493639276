import type { ReduxState } from '@spa/redux/ReduxState'
import createCachedSelector from 're-reselect'
import type { Shift } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import { ONE_HOUR_IN_MINUTES } from '../constants'
import { getDatesArrayWithMinutesInterval } from '../getDatesArrayWithMinutesInterval/getDatesArrayWithMinutesInterval'

import { getCountableShiftsByTeam } from './getCountableShiftsByTeam'
import { computeShiftsDecimalHeadcounts } from './utils/computeShiftsDecimalHeadcounts'

type Params = {
  teamId: number
}

const cacheKeySelector = (_: ReduxState, { teamId }: Params) => teamId

/**
 * Compute and aggregate shifts headcounts for each range interval
 */
const computeCounts = (
  shifts: Shift[],
  hoursIntervalByDay: string[],
  interval: number
) => {
  const countsMap = computeShiftsDecimalHeadcounts(shifts, interval)
  const countsByHour = hoursIntervalByDay.map(isoHour => ({
    count: countsMap[isoHour] || 0,
    isoHour
  }))

  return countsByHour
}

/**
 * Get headcounts mapped by days for interval of [1 hour]
 */
export const getHourHeadcountsArray = createCachedSelector(
  (state: ReduxState, opts: Params) => getCountableShiftsByTeam(state, opts),
  (state: ReduxState) => getDatesArrayWithMinutesInterval(state, {}),
  () => ONE_HOUR_IN_MINUTES,
  computeCounts
)(cacheKeySelector)
