import type { Slice } from '@reduxjs/toolkit'
import { mapValues } from 'lodash-es'

export const getSliceActionTypes = <
  T extends Slice,
  Keys extends keyof T['actions'] = keyof T['actions']
>(
  slice: T
): { [key in Keys]: string } => {
  return mapValues(slice.actions, (v, k) => `${slice.name}/${k}`) as {
    [key in Keys]: string
  }
}
