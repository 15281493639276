import { put, select } from 'redux-saga/effects'
import { createShift } from 'snap-redux/modules/shifts/index'

import type { PayloadActionWithoutType } from '../../types/types'

import { templateToShift } from './utils/templateToShift'

type Params = PayloadActionWithoutType<{
  templateId: number
  to: {
    weeklyscheduleId: number
    contractId: number
    date: string
  }
}>

function* createFromTemplate(action: Params) {
  const { to, templateId } = action.payload
  const template = yield select(state =>
    state.shiftTemplates.getIn(['data', templateId])
  )

  const shift = templateToShift(
    template.toJS(),
    to.weeklyscheduleId,
    to.contractId,
    to.date
  )

  // @ts-ignore migration from js(x) to ts(x)
  yield put(createShift(shift))
}

export default createFromTemplate
