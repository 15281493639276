import type { AccountRecord, AccountStatus } from '@libs/data-access/entities'
import {
  billingService,
  useAccountFlipperFeatureFlags
} from '@libs/data-access/queries'
import { IS_SEGMENT_AVAILABLE } from '@libs/utils/environments'
import useBillingPlanId from '@spa/redux/billing/plan/hooks/useBillingPlanId'
import { getStatusString } from '@spa/redux/billing/subscription/utils/getStatusString'
import { isImpersonationContext } from '@spa/scenes/Auth/shared/services/authState/authServiceUtils'
import { useEffect } from 'react'

import { prefixKeys } from '../utils/prefixKeys'

type Params = {
  account?: AccountRecord | null
}
export const useSegmentGroup = ({ account }: Params, onGroup: () => void) => {
  const currentAccountFlipperFeatureFlags = useAccountFlipperFeatureFlags()
  const {
    data: billingAccountStatusData,
    isLoading: isBillingAccountStatusLoading,
    isError: isBillingAccountStatusError
  } = billingService.account.useGetStatus()

  const {
    data: billingAccountDetailsData,
    isLoading: isBillingAccountDetailsLoading,
    isError: isBillingAccountDetailsError
  } = billingService.account.useGetDetails()

  const planId = useBillingPlanId()
  const permissions = account?.get('permissions')
  const csmInfo = account?.get('csm_info')

  const shouldGroup = Boolean(
    IS_SEGMENT_AVAILABLE &&
      window.analytics &&
      !isImpersonationContext() &&
      account &&
      // Permissions is always an Immutable.Map, but it can be empty
      permissions &&
      permissions?.size > 0 &&
      csmInfo &&
      currentAccountFlipperFeatureFlags &&
      // Wait for data to have loaded or errored
      ((!isBillingAccountStatusLoading && billingAccountStatusData) ||
        isBillingAccountStatusError) &&
      ((!isBillingAccountDetailsLoading && billingAccountDetailsData) ||
        isBillingAccountDetailsError) &&
      planId
  )

  useEffect(() => {
    if (!shouldGroup) {
      return
    }

    const groupId = account?.get('id')

    const analyticsDataGroup = {
      billing_plan: account?.get('billing_plan'),
      account_id: account?.get('id'),
      account_name: account?.get('name'),
      account_slug: account?.get('subdomain'),
      shift_schedule_via_sms: account?.getIn(['permissions', 'sms_enabled']),
      subscription_activated_at: billingAccountStatusData?.activated_at,
      subscription_in_trial: billingAccountStatusData?.is_in_trial,
      subscription_status: getStatusString(
        billingAccountStatusData as AccountStatus
      ),
      subscription_trial_starts_at: billingAccountStatusData?.trial_starts_at,
      subscription_trial_ends_at: billingAccountStatusData?.trial_ends_at,
      subscription_plan_id: planId,
      account_uses_timeclock: account?.uses_timeclock,
      account_manager_can_read_all_salaries:
        account?.manager_can_read_all_salaries,
      account_time_zone: account?.time_zone,
      account_blocked: account?.blocked,
      payroll_report_access_for_managers_permission: account?.get(
        'manager_can_access_to_payroll'
      ),
      hr_dashboard_access_for_directors_permission: account?.get(
        'directors_can_access_dashboard_rh'
      ),
      ...prefixKeys(csmInfo, 'csm_info'),
      ...prefixKeys(permissions, 'permissions'),
      ...prefixKeys(currentAccountFlipperFeatureFlags, 'ff')
    }

    onGroup()
    window.analytics.group(groupId, analyticsDataGroup)
  }, [
    account,
    planId,
    billingAccountStatusData,
    csmInfo,
    currentAccountFlipperFeatureFlags,
    onGroup,
    permissions,
    shouldGroup
  ])
}
