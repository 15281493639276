import type { FormatterParams } from '../../types'

import { isoLastName } from './isoLastName'
import { isoName } from './isoName'

/**
 * Given a firstname and lastname return a concatened string
 * representing the fullname
 *
 * Input: {
 *   firstname: 'georges',
 *   lastname: 'bill'
 * }
 * Output: georges bill
 */
export function fullname({
  value = {}
}: FormatterParams<{ firstname?: string; lastname?: string }>) {
  const { firstname, lastname } = value
  const isoFirstname = isoName({ value: firstname || '' })
  const isoLastname = isoLastName({ value: lastname || '' })

  return `${isoFirstname} ${isoLastname}`.trim()
}

export default {
  format: fullname
}
