import type { FormatterParams } from '../../types'

import { isoName } from './isoName'

/**
 * Given a firstname and lastname return a concatened string
 * representing the fullnameShort
 *
 * Input: {
 *   firstname: 'georges',
 *   lastname: 'bill'
 * }
 * Output: georges bill
 */
export function fullnameShort({
  value = {}
}: FormatterParams<{ firstname?: string; lastname?: string }>) {
  const { firstname = '', lastname = '' } = value
  const isoFirstname = isoName({ value: firstname })
  const initialLastname = lastname ? `${lastname.charAt(0).toUpperCase()}.` : ''

  return `${isoFirstname} ${initialLastname}`.trim()
}

export default {
  format: fullnameShort
}
