import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'

import { gapVariants } from '../../utils/properties/gap'
import { switchAtVariants } from '../../utils/properties/switchAt'

import type { InlineProps } from './Inline.types'

const inlineCVA = cva(['box-border flex flex-wrap'], {
  variants: {
    stretch: {
      start: ['[&>*:first-child]:flex-1'],
      end: ['[&>*:last-child]:flex-1'],
      all: ['[&>*]:flex-1']
    },
    switchAt: switchAtVariants,
    gap: gapVariants
  }
})

export const Inline = forwardRef<HTMLDivElement, InlineProps>(
  (
    {
      children,
      className,
      gap = 0,
      stretch = 'all',
      switchAt = 'none',
      ...other
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        {...other}
        className={inlineCVA({ className, gap, stretch, switchAt })}
      >
        {children}
      </div>
    )
  }
)

Inline.displayName = 'Inline'
