import { flatten, values } from 'lodash-es'
import { normalize } from 'normalizr'
import { all, call, put } from 'redux-saga/effects'

import { Row } from '../../../models'
import { setEntities } from '../../../reducers/entities'
import {
  hydrateOldStoreRests,
  hydrateOldStoreShifts
} from '../../controllers/hydratesOldStore'
import type { PayloadActionWithoutType } from '../../types/types'
import { getContractsChunks } from '../../utils/getContractsChunks'

import { fetchPlannables } from './fetchPlannables'

type Params = PayloadActionWithoutType<{
  date: string
  contractIds: number[]
}>

/**
 * Given a list of contract IDs,
 * Chunk the array in order to run multiple API fetch in parallel
 * Then wait for each request to return and hydrate the store once
 */
export function* fetchAndHydratePlannables({
  payload: { date, contractIds }
}: Params): any {
  if (!contractIds?.length) {
    return
  }

  const chunks = getContractsChunks(contractIds)

  const results = yield all(
    chunks.map(ids => {
      return call(function* () {
        return yield fetchPlannables({
          payload: { date, contractIds: ids }
        })
      })
    })
  )

  if (results) {
    const rows = flatten(results)
    const normalizedRows = normalize(flatten(rows), [Row])

    yield put(setEntities(normalizedRows.entities))
    yield hydrateOldStoreShifts(values(normalizedRows.entities.shifts) as any)
    yield hydrateOldStoreRests(values(normalizedRows.entities.rests as any))
  }
}
