import { useCombobox } from '@mantine/core'
import { useMergedRef } from '@mantine/hooks'
import { forwardRef, memo, useRef } from 'react'

import { Combobox } from '../../../../overlays/Combobox/base/Combobox'
import { SelectEmpty } from '../../base/subs/SelectEmpty'
import { SelectFooter } from '../../base/subs/SelectFooter'
import { SelectSearchInput } from '../../base/subs/SelectSearchInput'
import { filterSelectOptions } from '../../base/utils/filterSelectOptions'
import { useSelectUncontrolledValues } from '../../base/utils/useSelectValuesUncontrolled'

import type { SelectGroupProps } from './SelectGroup.types'
import { SelectGroupOptionList } from './subs/SelectGroupOptionList'
import { flattenSelectGroupOptions } from './utils/flattenSelectGroupOptions'

export const SelectGroup = memo(
  forwardRef<HTMLInputElement, SelectGroupProps>(
    (
      {
        className,
        options = [],
        disabled,
        dropdownPosition = 'bottom-start',
        placeholder,
        searchAdapter = 'startingWith',
        emptyListLabel,
        closeOnOptionClick = true,
        onChange,
        searchValue,
        onSearchChange,
        dropdownHeight = 'm',
        dropdownWidth = 'm',
        searchable = false,
        clearable = false,
        defaultValue,
        value,
        'data-testid': dataTestId,
        footer,
        ...other
      },
      forwardedRef
    ) => {
      const innerRef = useRef<HTMLInputElement>(null)
      const ref = useMergedRef(forwardedRef, innerRef)
      const store = useCombobox()
      const flattenOptions = flattenSelectGroupOptions(options)

      const { select, search, selectedOption } = useSelectUncontrolledValues({
        select: { onChange, value, defaultValue },
        search: { searchValue, onSearchChange },
        options: flattenOptions
      })

      const filteredOptions = filterSelectOptions({
        options: flattenOptions,
        searchAdapter,
        searchValue: search.searchValue
      })

      return (
        <Combobox
          {...other}
          closeOnOptionClick={closeOnOptionClick}
          store={store}
          dropdownPosition={dropdownPosition}
          dropdownHeight={dropdownHeight}
          dropdownWidth={dropdownWidth}
          onOpen={() => innerRef?.current?.focus()}
          onOptionSubmit={val => {
            const newOptionSelected = flattenOptions.find(i => i.value === val)
            select.setSelectValue(val)
            // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
            search.setSearchValue(newOptionSelected?.label)
            if (closeOnOptionClick) {
              store.closeDropdown()
            }
          }}
        >
          <Combobox.Target>
            <SelectSearchInput
              ref={ref}
              data-testid={dataTestId}
              className={className}
              placeholder={placeholder}
              disabled={disabled}
              clearable={clearable}
              store={store}
              setSearchValue={search.setSearchValue}
              resetSearchValue={search.resetSearchValue}
              resetSelectValue={select.resetSelectValue}
              value={search.searchValue}
              selectedOption={selectedOption}
              searchable={searchable}
            />
          </Combobox.Target>

          <Combobox.Dropdown>
            <SelectEmpty
              options={filteredOptions}
              emptyListLabel={emptyListLabel}
            />
            <SelectGroupOptionList
              searchValue={search.searchValue}
              selectValue={select.selectValue}
              selectedOption={selectedOption}
              options={filteredOptions}
            />
            <SelectFooter footer={footer} />
          </Combobox.Dropdown>
        </Combobox>
      )
    }
  )
)

SelectGroup.displayName = 'SelectGroup'
export type { SelectGroupProps, SelectGroupOption } from './SelectGroup.types'

export * from './services/createSelectGroupOption'
