import { EmptyContent, imagesUrls } from '@libs/ui/ds'
import { useCommonAppTranslation } from '@libs/utils/translations'

export const UnhandledError = () => {
  const { t } = useCommonAppTranslation()

  return (
    <EmptyContent
      title={t('pagesErrors.unhandledError.title')}
      desc={t('pagesErrors.unhandledError.description')}
      imageSrc={imagesUrls.illustrations.settings}
      action={{
        onClick: () => window.location.replace('/'),
        label: t('pagesErrors.unhandledError.label')
      }}
    />
  )
}
