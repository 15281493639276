import {
  Stack,
  IconButton,
  CloseIcon,
  BinIcon,
  Button,
  AddIcon
} from '@libs/ui/ds'
import { usePlanningFormTranslation } from '@libs/utils/translations'
import {
  FieldInputNumber,
  FieldTimeRange,
  FieldError
} from '@spa/components/FormFields'
import { isValidTime } from '@spa/components/FormFields/InputTimeMask'
import withController from '@spa/hoc/withController'
import moment from 'moment'
import type { FC, MouseEvent } from 'react'
import { useState } from 'react'

import styles from './FieldTimeRangeDuration.module.css'

type Value = {
  id?: number | string
  plannedDuration?: number
  timeRange?: string[]
}
export type FieldTimeRangeDurationProps = {
  inputName?: string
  disabled?: boolean
  error?: string
  timeRangeConstraint?: {
    start: string
    end: string
  }
  highlightError?: boolean
  disableTimeRange?: boolean
  isManager?: boolean
  value?: Value
  onRemove?: (evt: MouseEvent<HTMLButtonElement>) => void
  onChange?: (newValue: Value) => void
  dataTestId?: string
}

/**
 * @deprecated use FieldTimeRangeDuration in app/features/scheduling/components/FieldTimeRangeDuration.tsx
 */
export const FieldTimeRangeDuration: FC<FieldTimeRangeDurationProps> = ({
  inputName,
  value = { plannedDuration: 0, timeRange: ['', ''] },
  timeRangeConstraint,
  disabled,
  error,
  highlightError,
  disableTimeRange,
  onChange,
  onRemove,
  isManager = false,
  dataTestId = 'field-time-range-duration'
}) => {
  const { t } = usePlanningFormTranslation()
  const [displayTimeRange, setDisplayTimeRange] = useState(
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    value?.timeRange?.length > 0
  )

  const { plannedDuration = 0, timeRange = ['', ''], ...restValue } = value

  const toggleDisplayTimeRange = () => {
    if (displayTimeRange && onChange) {
      onChange({ plannedDuration, timeRange: ['', ''] })
    }
    setDisplayTimeRange(!displayTimeRange)
  }

  /**
   * Compute endTime of [timeRange] given startTime and duration
   */
  const computeTimeRangeWithDuration = (
    startRange: string,
    newDuration: number
  ): [string, string] => {
    const startTime = moment(startRange, 'HH:mm')
    const endTime = startTime.add(newDuration, 'minutes')
    return [startRange, endTime.format('HH:mm')]
  }

  /**
   * Compute [timeRange] based on duration updates
   */
  const handleInputChange = (newDuration: number) => {
    const [startRange] = timeRange

    if (!onChange) {
      return
    }

    switch (true) {
      case !disableTimeRange && newDuration && isValidTime(startRange): {
        return onChange({
          ...restValue,
          plannedDuration: newDuration,
          timeRange: computeTimeRangeWithDuration(startRange, newDuration)
        })
      }
      case !disableTimeRange && (!newDuration || newDuration === 0): {
        return onChange({
          ...restValue,
          plannedDuration: newDuration || 0,
          timeRange: startRange ? [startRange, ''] : []
        })
      }
      default: {
        return onChange({
          ...restValue,
          plannedDuration: newDuration,
          timeRange: displayTimeRange ? timeRange : []
        })
      }
    }
  }

  /**
   * Compute [timeRange:end] based on [timeRange:start] updates
   */
  const handleTimeRangeChange = (newRange: [string, string]) => {
    const [newStartRange] = newRange

    if (isValidTime(newStartRange) && !!plannedDuration) {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      return onChange({
        ...restValue,
        plannedDuration,
        timeRange: computeTimeRangeWithDuration(newStartRange, plannedDuration)
      })
    }
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    onChange({ ...restValue, plannedDuration, timeRange: [newStartRange, ''] })
  }

  /**
   * Compute max duration based on fieldTimeRange values
   */
  const getMaxDuration = () => {
    if (!timeRangeConstraint) {
      return Infinity
    }

    const momentEnd = moment(timeRangeConstraint.end)
    const momentStart = moment(timeRangeConstraint.start)

    if (momentEnd.isBefore(momentStart)) {
      momentEnd.add(1, 'day')
    }

    return moment.duration(momentEnd.diff(momentStart)).asMinutes()
  }

  return (
    <div className={styles.GroupBreakRow} data-testid={dataTestId}>
      <div className={styles.BreakRow}>
        <div className={styles.ContentContainer}>
          <FieldInputNumber
            value={plannedDuration}
            min={0}
            max={getMaxDuration()}
            // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
            onChange={handleInputChange}
            disabled={disabled}
            highlightError={!!error || highlightError}
            width='120px'
            suffix='min'
            inputName={inputName}
            data-testid='field-duration-time-range'
          />
          {!disableTimeRange && displayTimeRange && (
            <div className={styles.TimeRange}>
              <FieldTimeRange
                prefixLabels={[
                  t('component.fieldTimeRange.from'),
                  t('component.fieldTimeRange.to')
                ]}
                values={timeRange as [string, string]}
                onChange={handleTimeRangeChange}
                disabled={disabled}
                endEl={{ locked: true }}
                highlightError={!!error || highlightError}
                dataTestId='field-time-range'
              />
            </div>
          )}

          {!disableTimeRange && !displayTimeRange && !disabled && (
            <Button
              intent='neutral-dark'
              variant='tertiary'
              leftIcon={<AddIcon />}
              onClick={toggleDisplayTimeRange}
              data-testid='add'
              className='ml-3'
            >
              {t('component.fieldTimeRange.showBreak')}
            </Button>
          )}
        </div>

        <Stack gap={2}>
          {!disableTimeRange && displayTimeRange && (
            <IconButton
              icon={<CloseIcon />}
              disabled={disabled}
              onClick={toggleDisplayTimeRange}
              variant='tertiary'
              intent='neutral-dark'
              data-testid='close'
            />
          )}
          {onRemove && !disabled && isManager && (
            <IconButton
              icon={<BinIcon />}
              onClick={onRemove}
              data-testid='delete'
              variant='tertiary'
              intent='neutral-dark'
            />
          )}
        </Stack>
      </div>
      <div>{error && <FieldError error={error} />}</div>
    </div>
  )
}

export const ControlledFieldTimeRangeDuration = withController<
  FieldTimeRangeDurationProps,
  Value
>()(FieldTimeRangeDuration)
