import type { NormalizeEntity } from '@libs/data-access/entities'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { MissingInformationModel } from '../models/MissingInformationModel'

export type ParamsDefaultFetch = {
  location_id?: number
  team_id?: number

  // If those aren't passed in they're suppose to be true
  dpae?: boolean
  ssr?: boolean
}

export type ParamsFetchByMembership = ParamsDefaultFetch & {
  membership_id: number
}

export type ParamsDownload = {
  location_id?: number
  name?: string
}

export type ParamsPaginatedFetch = ParamsDefaultFetch & {
  page?: number
  per_page?: number
}

const initialState: MissingInformationModel = []

export type EntitiesState = {
  missingInformation: NormalizeEntity<MissingInformationModel>
}

const entitiesSlice = createSlice({
  name: 'emp/missingInformation/entities',
  initialState,
  reducers: {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    replaceMissingInformation(
      _,
      action: PayloadAction<Partial<MissingInformationModel>>
    ) {
      return [...action.payload]
    },

    setMissingInformation(
      state,
      action: PayloadAction<MissingInformationModel>
    ) {
      return [...state, ...action.payload]
    }
  }
})

export const { setMissingInformation, replaceMissingInformation } =
  entitiesSlice.actions
export default entitiesSlice.reducer
