import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { getCountryConfiguration } from './getCountryConfiguration'

export type AuthorizedFeatureByCountry = {
  authorizedRegularHoursFeature: boolean
  authorizedHalfDayRest: boolean
  authorizedDashboardAnalysis: boolean
  authorizedBirthDepartmentCode: boolean
  authorizedDailyWorker: boolean
  authorizedIntegrations: boolean
  authorizedMinWage: boolean
  authorizedModulation: boolean
  authorizedDpae: boolean
  authorizedApeCode: boolean
  authorizedRup: boolean
  authorizedHealthInsurance: boolean
  authorizedPayrollExports: boolean
  authorizedRcr: boolean
  authorizedRestRevaluationDuration: boolean
  authorizedPublicHolidays: boolean
  authorizedPublicHolidaysMunicipality: boolean
}

// Return only the boolean authorized values
export const getAuthorizedFeatureByCountryPolicies = createSelector(
  [getCountryConfiguration],
  (countryConfiguration): AuthorizedFeatureByCountry => ({
    authorizedPublicHolidaysMunicipality: countryConfiguration
      ? countryConfiguration.authorized_public_holidays_municipality
      : true,
    authorizedRegularHoursFeature: countryConfiguration
      ? countryConfiguration.authorized_regular_hours_feature
      : true,
    authorizedHalfDayRest: countryConfiguration
      ? countryConfiguration.authorized_half_day_rest
      : true,
    authorizedDashboardAnalysis: countryConfiguration
      ? countryConfiguration.authorized_dashboard_analysis
      : true,
    authorizedBirthDepartmentCode: countryConfiguration
      ? countryConfiguration.authorized_birth_department_code
      : true,
    authorizedDailyWorker: countryConfiguration
      ? countryConfiguration.authorized_daily_worker
      : true,
    authorizedMinWage: countryConfiguration
      ? countryConfiguration.authorized_min_wage
      : true,
    authorizedDpae: countryConfiguration
      ? countryConfiguration.authorized_dpae
      : true,
    authorizedApeCode: countryConfiguration
      ? countryConfiguration.authorized_ape_code
      : true,
    authorizedHealthInsurance: countryConfiguration
      ? countryConfiguration.authorized_health_insurance
      : true,
    authorizedPayrollExports: countryConfiguration
      ? countryConfiguration.authorized_payroll_exports
      : true,
    authorizedRcr: countryConfiguration
      ? countryConfiguration.authorized_rcr
      : true,
    authorizedRestRevaluationDuration: countryConfiguration
      ? countryConfiguration.authorized_rest_revaluation_duration
      : true,
    authorizedPublicHolidays: countryConfiguration
      ? countryConfiguration.authorized_public_holidays
      : true,
    authorizedModulation: countryConfiguration
      ? countryConfiguration.authorized_modulation
      : true,
    authorizedIntegrations: countryConfiguration
      ? countryConfiguration.authorized_integrations
      : true,
    authorizedRup: countryConfiguration
      ? countryConfiguration.authorized_rup
      : true
  })
)

export const useAuthorizedFeaturePolicies = () => {
  return useSelector(getAuthorizedFeatureByCountryPolicies)
}
