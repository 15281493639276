import type { MutationState } from '@tanstack/react-query'
import { useMutation, useMutationState } from '@tanstack/react-query'

import type { UseAppMutationOptions, ApiErrorResponse } from '../../../types'
import type { PostCheckExistingEmailOptions } from '../auth.api'
import { postCheckExistingEmail } from '../auth.api'
import { authCacheKeys } from '../auth.cacheKeys'
import type { PostCheckExistingEmailResponse } from '../types/CheckExistingEmail.post'

export const usePostCheckExistingEmail = (
  mutationOptions?: UseAppMutationOptions<
    PostCheckExistingEmailResponse,
    ApiErrorResponse,
    PostCheckExistingEmailOptions
  >
) => {
  return useMutation({
    ...mutationOptions,
    mutationKey: authCacheKeys.postCheckExistingEmail(),
    mutationFn: (opts: PostCheckExistingEmailOptions) =>
      postCheckExistingEmail(opts),

    onSuccess: (data, variables, ...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, ...args)
      }
    }
  })
}

export const usePostCheckExistingEmailLastMutationState = () => {
  const mutationStates = useMutationState<
    MutationState<PostCheckExistingEmailResponse>
  >({
    filters: {
      mutationKey: authCacheKeys.postCheckExistingEmail()
    }
  })

  return (mutationStates || []).at(-1) ?? null
}
