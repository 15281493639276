import { IS_ENABLE_SIGNUP_FLOW } from '@libs/utils/environments'
import { UnauthenticatedApp } from '@spa/bootstrap/UnauthenticatedApp/UnauthenticatedApp'
import { CustomerCareMenu } from '@spa/features/externals/intercom'
import {
  AuthenticationRefreshGuard,
  useAuthState
} from '@spa/scenes/Auth/shared/services'
import { AuthNavbarProvider } from '@spa/scenes/Auth/shared/services/authNavbar/AuthNavbarProvider'

import { AuthenticatedApp } from './AuthenticatedApp/AuthenticatedApp'
import { GuestApp } from './GuestApp/GuestApp'

export const AppEntryController = () => {
  const authState = useAuthState()

  if (authState.isAuthenticated) {
    return (
      <AuthenticationRefreshGuard>
        <AuthenticatedApp />
        <CustomerCareMenu />
      </AuthenticationRefreshGuard>
    )
  }

  if (IS_ENABLE_SIGNUP_FLOW) {
    return (
      <AuthNavbarProvider>
        <GuestApp />
      </AuthNavbarProvider>
    )
  }

  return (
    <>
      <UnauthenticatedApp />
      <CustomerCareMenu />
    </>
  )
}
