import type { ApiErrorResponse } from '@libs/data-access/queries'
import { notifications } from '@libs/ui/ds'
import i18n from '@libs/utils/translations'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import type { ParamsPostSignatureDocumentEmployee } from '../modules/entities'
import { updateDocumentEmployee } from '../modules/entities'
import { setMetaLoading, setMetaSuccess, setMetaError } from '../modules/meta'

export default function* postSignatureDocumentEmployeeData(
  params: ParamsPostSignatureDocumentEmployee
) {
  yield put(setMetaLoading())

  const { document_id, ...body } = params

  try {
    const promise = apiRequest({
      service: 'core',
      route: 'postSignatureDocumentEmployee',
      params: {
        document_id
      },
      body
    })

    notifications.promise(promise, {
      loading: {
        message: i18n.t<any>(
          'common.notifications:documentSign.sending.loading'
        )
      },
      success: {
        message: i18n.t<any>(
          'common.notifications:documentSign.sending.success'
        )
      },
      apiError: true
    })

    const result = yield promise

    yield put(updateDocumentEmployee(result))
    yield put(setMetaSuccess())
  } catch (err) {
    yield put(setMetaError(err as ApiErrorResponse))
  }
}
