import { appQueryClient } from '../../../../provider'

export const membershipTimeoffQueryKeys = {
  timeoff_eligibility: (queryParams = {}) => [
    'timeoff_eligibility',
    queryParams
  ],
  timeoff_info: (queryParams = {}) => ['timeoff_info', queryParams],
  member_timeoff: (queryParams = {}) => ['member_timeoff', queryParams]
}

export const membershipTimeoffInvalidations = {
  invalidateMemberTimeoff: (membershipId?: number) => {
    appQueryClient.invalidateQueries({
      queryKey: membershipTimeoffQueryKeys.member_timeoff({
        ...(membershipId && {
          membership_id: membershipId
        })
      })
    })
  }
}
