import { EmptyContent, imagesUrls } from '@libs/ui/ds'
import { useCommonAppTranslation } from '@libs/utils/translations'

export const NotFoundError = () => {
  const { t } = useCommonAppTranslation()

  return (
    <EmptyContent
      title={t('pagesErrors.notFoundError.title')}
      desc={t('pagesErrors.notFoundError.description')}
      imageSrc={imagesUrls.illustrations.buoy}
      action={{
        onClick: () => window.location.replace('/'),
        label: t('pagesErrors.notFoundError.label')
      }}
    />
  )
}
