import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_CONTRACT_TEMPLATES,
  FETCH_CONTRACT_TEMPLATES_SUCCEEDED,
  FETCH_CONTRACT_TEMPLATES_FAILED,
  CREATE_CONTRACT_TEMPLATE,
  CREATE_CONTRACT_TEMPLATE_SUCCEEDED,
  CREATE_CONTRACT_TEMPLATE_FAILED,
  UPDATE_CONTRACT_TEMPLATE,
  UPDATE_CONTRACT_TEMPLATE_SUCCEEDED,
  UPDATE_CONTRACT_TEMPLATE_FAILED,
  DELETE_CONTRACT_TEMPLATE,
  DELETE_CONTRACT_TEMPLATE_SUCCEEDED,
  DELETE_CONTRACT_TEMPLATE_FAILED,
  GENERATE_CONTRACT,
  GENERATE_CONTRACT_SUCCEEDED,
  GENERATE_CONTRACT_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import {
  mergeRecords,
  updateRecord,
  deleteRecord
} from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchContractTemplates = (accountId, params, resolve, reject) => ({
  type: FETCH_CONTRACT_TEMPLATES,
  accountId,
  params,
  resolve,
  reject
})

export const createContractTemplate = (
  contractTemplateData,
  resolve,
  reject
) => ({
  type: CREATE_CONTRACT_TEMPLATE,
  contractTemplateData,
  resolve,
  reject
})

export const updateContractTemplate = (
  contractTemplateId,
  contractTemplateData,
  resolve,
  reject
) => ({
  type: UPDATE_CONTRACT_TEMPLATE,
  contractTemplateId,
  contractTemplateData,
  resolve,
  reject
})

export const deleteContractTemplate = (
  contractTemplateId,
  resolve,
  reject
) => ({
  type: DELETE_CONTRACT_TEMPLATE,
  contractTemplateId,
  resolve,
  reject
})

export const generateContract = (contractTemplateData, resolve, reject) => ({
  type: GENERATE_CONTRACT,
  contractTemplateData,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const contractTemplatesSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_CONTRACT_TEMPLATES_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_CONTRACT_TEMPLATES_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/contract_templates?${queryString.stringify(
        params
      )}`,
      options
    )
}

export function* doFetchContractTemplates({
  accountId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    contractTemplatesSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

const createContractTemplateSagaEntity = {
  success: data => ({ type: CREATE_CONTRACT_TEMPLATE_SUCCEEDED, data }),
  failure: error => ({ type: CREATE_CONTRACT_TEMPLATE_FAILED, error }),

  fetchAPI: (id, options) =>
    callAPI('/contract_templates', { method: 'POST', ...options })
}

export function* doCreateContractTemplate({
  contractTemplateData,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    createContractTemplateSagaEntity,
    null,
    null,
    contractTemplateData,
    resolve,
    reject
  )
}

const updateContractTemplateSagaEntity = {
  success: (data, contractTemplateId) => ({
    type: UPDATE_CONTRACT_TEMPLATE_SUCCEEDED,
    data,
    contractTemplateId
  }),
  failure: (error, contractTemplateId) => ({
    type: UPDATE_CONTRACT_TEMPLATE_FAILED,
    error,
    contractTemplateId
  }),

  fetchAPI: (contractTemplateId, options) =>
    callAPI(`/contract_templates/${contractTemplateId}`, {
      method: 'PUT',
      ...options
    })
}

export function* doUpdateContractTemplate({
  contractTemplateId,
  contractTemplateData,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    updateContractTemplateSagaEntity,
    contractTemplateId,
    null,
    contractTemplateData,
    resolve,
    reject
  )
}

const deleteContractTemplateSagaEntity = {
  success: (data, contractTemplateId) => ({
    type: DELETE_CONTRACT_TEMPLATE_SUCCEEDED,
    data,
    contractTemplateId
  }),
  failure: (error, contractTemplateId) => ({
    type: DELETE_CONTRACT_TEMPLATE_FAILED,
    error,
    contractTemplateId
  }),

  fetchAPI: (contractTemplateId, options) =>
    callAPI(`/contract_templates/${contractTemplateId}`, {
      method: 'DELETE',
      ...options
    })
}

export function* doDeleteContractTemplate({
  contractTemplateId,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    deleteContractTemplateSagaEntity,
    contractTemplateId,
    null,
    null,
    resolve,
    reject
  )
}

// INFO : WE CURRENTLY DONT USE THIS FUNC / DONT DELETE
const generateContractSagaEntity = {
  success: data => ({ type: GENERATE_CONTRACT_SUCCEEDED, data }),
  failure: error => ({ type: GENERATE_CONTRACT_FAILED, error }),

  fetchAPI: (id, options, params) =>
    callAPI(`/contract_documents?${queryString.stringify(params)}`, {
      method: 'GET',
      ...options
    })
}

export function* doGenerateContract({ contractTemplateData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    generateContractSagaEntity,
    null,
    null,
    contractTemplateData,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const ContractTemplate = Immutable.Record({
  id: null,
  title: null,
  account_id: null,
  contract_type: null,
  document_url: null,
  variables: null,
  content: null
})

const contractTemplateForeignKeys = ['account_id', 'contract_type']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function contractTemplates(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case GENERATE_CONTRACT:
    case FETCH_CONTRACT_TEMPLATES: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case GENERATE_CONTRACT_FAILED:
    case FETCH_CONTRACT_TEMPLATES_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_CONTRACT_TEMPLATES_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const contractTemplates = Immutable.fromJS(action.data)

      return mergeRecords(
        state,
        ContractTemplate,
        contractTemplates,
        // @ts-ignore migration from js(x) to ts(x)
        contractTemplateForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    case CREATE_CONTRACT_TEMPLATE_SUCCEEDED:
    case UPDATE_CONTRACT_TEMPLATE_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const contractTemplate = Immutable.fromJS(action.data)
      return updateRecord(
        state,
        ContractTemplate,
        contractTemplate,
        // @ts-ignore migration from js(x) to ts(x)
        contractTemplateForeignKeys
      )
    }
    case GENERATE_CONTRACT_SUCCEEDED: {
      return state
    }
    case DELETE_CONTRACT_TEMPLATE_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { contractTemplateId } = action
      return deleteRecord(
        state,
        contractTemplateId,
        // @ts-ignore migration from js(x) to ts(x)
        contractTemplateForeignKeys
      )
    }
    default:
      return state
  }
}

export default filterActions(contractTemplates, [
  GENERATE_CONTRACT,
  FETCH_CONTRACT_TEMPLATES,
  GENERATE_CONTRACT_FAILED,
  FETCH_CONTRACT_TEMPLATES_FAILED,
  FETCH_CONTRACT_TEMPLATES_SUCCEEDED,
  CREATE_CONTRACT_TEMPLATE_SUCCEEDED,
  UPDATE_CONTRACT_TEMPLATE_SUCCEEDED,
  GENERATE_CONTRACT_SUCCEEDED,
  DELETE_CONTRACT_TEMPLATE_SUCCEEDED
])
