import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import { memo, useState } from 'react'

import { CloseIcon } from '../../../../../assets/icons/CloseIcon'
import { IconButton } from '../../../buttons/IconButton/base/IconButton'

import type { BannerProps } from './Banner.types'

const bannerCVA = {
  root: cva(['relative flex min-h-10 w-full items-center justify-center'], {
    variants: {
      variant: {
        danger: 'bg-semantic-red-50 text-semantic-red-600',
        information: 'bg-semantic-blue-50 text-semantic-blue-500',
        legal: 'bg-secondary-purple-50 text-secondary-purple-700',
        warning: 'bg-semantic-orange-50 text-semantic-orange-700'
      }
    }
  }),
  inner: cva([
    'flex w-full items-center justify-center gap-2 px-6 py-2 text-center text-s'
  ]),
  iconButton: cva(['absolute right-2 cursor-pointer'])
}

export const Banner: FC<BannerProps> = memo(
  ({
    className,
    children,
    variant = 'information',
    dismissable = true,
    onClose,
    ...other
  }) => {
    const [dismissed, setDismissed] = useState(false)
    const handleClose = () => {
      if (onClose) {
        onClose()
      }
      setDismissed(true)
    }

    if (dismissed) {
      return null
    }

    return (
      <div {...other} className={bannerCVA.root({ variant, className })}>
        <div className={bannerCVA.inner()}>{children}</div>
        {dismissable && (
          <IconButton
            className={bannerCVA.iconButton()}
            data-testid='banner-close-button'
            variant='tertiary'
            intent='neutral-dark'
            onClick={handleClose}
            icon={<CloseIcon />}
            aria-label='Close banner'
            size='xs'
          />
        )}
      </div>
    )
  }
)

Banner.displayName = 'Banner'
export type { BannerProps } from './Banner.types'
