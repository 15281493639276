import type { FormatterParams } from '../../types'

/**
 * Convert a number with or without dot to transform it to comma
 *
 * Input 125.1234
 * Output 125.12
 *
 * Input 125.00
 * Output 125.00
 *
 * Input 125
 * Output 125.00
 */
export function decimalFixedTwoDigit({
  value
}: FormatterParams<number | string>) {
  return parseFloat(value as any)
    .toFixed(2)
    .replace('.', ',')
}

export default {
  format: decimalFixedTwoDigit
}
