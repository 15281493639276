import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import React, { memo } from 'react'

import type { DividerProps } from './Divider.types'

const dividerCVA = cva(['border-neutral-200'], {
  variants: {
    size: {
      s: ''
    },
    vertical: {
      false: 'w-full',
      true: 'h-full'
    }
  },
  compoundVariants: [
    {
      size: 's',
      vertical: false,
      class: 'border-t-1'
    },
    {
      size: 's',
      vertical: true,
      class: 'border-l-1'
    }
  ]
})

export const Divider: FC<DividerProps> = memo(
  ({ className, vertical = false, size = 's', ...other }) => {
    return (
      <hr {...other} className={dividerCVA({ className, vertical, size })} />
    )
  }
)

Divider.displayName = 'Divider'
export type { DividerProps } from './Divider.types'
