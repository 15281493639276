export const PencilIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.944 0.00889519C15.3222 0.0539432 14.7013 0.261951 14.172 0.602495C13.9931 0.717567 13.8559 0.822639 13.688 0.973023C13.633 1.02229 10.8947 3.75661 7.60295 7.04929C3.44112 11.2123 1.60771 13.0513 1.58441 13.0863C1.52926 13.1692 1.48968 13.2617 1.46047 13.376C1.44529 13.4354 1.11419 14.7278 0.724709 16.248C0.335229 17.7682 0.012381 19.039 0.00727698 19.072C-0.00586702 19.1568 0.00244498 19.3163 0.023949 19.392C0.045085 19.4663 0.098933 19.5814 0.144165 19.649C0.195373 19.7254 0.313373 19.8372 0.391165 19.883C0.557909 19.981 0.764125 20.0197 0.948933 19.9876C1.04522 19.9709 6.68416 18.5243 6.751 18.4991C6.81121 18.4765 6.87365 18.4429 6.93198 18.4017C6.98138 18.3669 18.9026 6.45082 19.0269 6.31198C19.2803 6.0291 19.4563 5.7669 19.6204 5.42798C19.7391 5.1827 19.8173 4.96923 19.8842 4.70798C20.1394 3.71087 19.9704 2.63624 19.4213 1.76406C18.7794 0.744455 17.6923 0.0941032 16.4865 0.00833519C16.3522 -0.00121681 16.0796 -0.000936814 15.944 0.00889519ZM16.056 1.63953C15.7593 1.66459 15.4908 1.7425 15.224 1.8809C15.1199 1.93489 14.936 2.05077 14.936 2.06236C14.936 2.07026 17.9347 5.06813 17.9381 5.06364C17.9396 5.06162 17.9519 5.04558 17.9654 5.02798C18.0053 4.97594 18.0917 4.83217 18.1352 4.74545C18.2501 4.51666 18.3169 4.29546 18.3531 4.02398C18.3683 3.90999 18.3661 3.61707 18.3491 3.49998C18.2547 2.849 17.909 2.308 17.3676 1.96398C17.1295 1.81272 16.8203 1.69898 16.5309 1.65626C16.4057 1.63776 16.1736 1.62959 16.056 1.63953ZM8.58798 8.37598L3.41999 13.544L4.93798 15.062L6.45598 16.58L11.626 11.41L16.796 6.23998L15.28 4.72398C14.4462 3.89018 13.7622 3.20798 13.76 3.20798C13.7578 3.20798 11.4304 5.53358 8.58798 8.37598ZM2.68943 15.1691C2.68455 15.1883 2.51677 15.8428 2.31658 16.6236C2.1164 17.4044 1.9535 18.0442 1.9546 18.0453C1.95628 18.0469 4.82751 17.3121 4.85473 17.3031C4.85983 17.3014 4.37672 16.8127 3.78115 16.2171L2.6983 15.1343L2.68943 15.1691Z'
        fill='currentColor'
      />
    </svg>
  )
}
