import { createSafeContext } from '@libs/ui/ds/lib/internals'

import type { RadioButtonProps } from '../RadioButton.types'

import type { RadioButtonGroupProps } from './RadioButtonGroup.types'

export type RadioButtonGroupContext = {
  onChange?: (value: string) => void
  value?: RadioButtonGroupProps['value']
  name?: RadioButtonGroupProps['name']
  disabled?: RadioButtonGroupProps['disabled']
}

export const [RadioButtonGroupProvider, useRadioButtonGroupContext] =
  createSafeContext<RadioButtonGroupContext>({
    context: 'RadioButtonGroup'
  })

export const useMergedRadioButtonProps = (
  radioButtonProps: Pick<
    RadioButtonProps,
    'disabled' | 'name' | 'onChange' | 'value' | 'checked'
  >
) => {
  const groupProps = useRadioButtonGroupContext({
    throwErrorIfNoContext: false
  })
  if (!groupProps) {
    return radioButtonProps
  }

  return {
    checked: radioButtonProps.value === groupProps.value,
    disabled: groupProps.disabled || radioButtonProps.disabled,
    name: groupProps.name,
    value: groupProps.name,
    onChange: () => {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      groupProps.onChange(radioButtonProps.value)
    }
  }
}
