import type { GetRoute } from '../../types'

import type {
  UserContractCountersSchema,
  GetUserContractCountersQueryParams
} from './schemas'

export const userContractCountersRoutes = {
  getUserContractCounters: {
    method: 'GET',
    path: '/user_contracts/counters'
  }
} as const

export type UserContractCountersRoutes = {
  getUserContractCounters: GetRoute<
    [],
    GetUserContractCountersQueryParams,
    UserContractCountersSchema[]
  >
}
