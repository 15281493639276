import { useMergedRef } from '@libs/ui/ds'
import { cva } from 'class-variance-authority'
import { forwardRef, memo, useRef } from 'react'

import { Inline } from '../../../../primitiveLayouts/Inline/base/Inline'
import { Stack } from '../../../../primitiveLayouts/Stack/base/Stack'
import { Text } from '../../../../typography/Text/base/Text'
import { RadioButtonInput } from '../../base/subs/RadioButtonInput'

import type { RadioCardButtonProps } from './RadioCardButton.types'
import { RadioCardButtonGroup } from './compound/RadioCardButtonGroup'
import { useMergedRadioCardButtonProps } from './compound/RadioCardButtonGroup.context'

const radioCardButtonCVA = {
  root: cva(
    [
      'flex gap-2 py-3 pl-4 pr-3',
      'bg-neutral-50 [&:not(:has(input:checked))]:hover:bg-neutral-100',
      'rounded-md border-1 border-solid border-neutral-200',
      '[&:has(input:checked)]:border-brand-green-600'
    ],
    {
      variants: {
        checked: {
          true: '!border-brand-green-700'
        },
        disabled: {
          true: ['!cursor-default', '!bg-neutral-100 opacity-50'],
          false: 'cursor-pointer'
        }
      }
    }
  ),
  header: cva(['flex w-full gap-2'], {
    variants: {
      hasSubtitle: {
        true: 'items-start',
        false: 'items-center'
      }
    }
  }),
  headerLeftSection: cva(['flex-col justify-center sm:flex-row'], {
    variants: {
      hasSubtitle: {
        true: 'items-start',
        false: 'items-center'
      }
    }
  }),
  titleContainer: cva(['flex flex-col items-start gap-1']),
  title: cva(['w-full truncate font-sans text-s !font-semibold']),
  subtitle: cva(['w-full font-sans text-xs !font-normal !text-neutral-600']),
  headerRightSection: cva(['my-auto inline-flex items-center'])
}

const BaseRadioCardButton = memo(
  forwardRef<HTMLInputElement, RadioCardButtonProps>(
    (
      {
        checked,
        className,
        disabled,
        name,
        rightSection,
        onChange,
        subTitle,
        title,
        value,
        ...other
      },
      forwardRef
    ) => {
      const mergedProps = useMergedRadioCardButtonProps({
        value,
        disabled,
        name,
        onChange
      })

      const inputRef = useRef<HTMLElement>(null)
      const mergedRef = useMergedRef(forwardRef, inputRef)

      return (
        <label
          {...other}
          htmlFor={name}
          className={radioCardButtonCVA.root({
            className,
            checked,
            disabled: Boolean(mergedProps.disabled)
          })}
          ref={mergedRef}
        >
          <Stack
            gap={2}
            className={radioCardButtonCVA.headerLeftSection({
              hasSubtitle: !!subTitle
            })}
          >
            <RadioButtonInput
              // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
              checked={mergedProps.value?.includes(value) || checked}
              disabled={mergedProps.disabled}
              name={mergedProps.name}
              onChange={mergedProps.onChange}
              ref={mergedRef}
              value={mergedProps.value}
            />
          </Stack>
          <Inline
            stretch='start'
            className={radioCardButtonCVA.header({ hasSubtitle: !!subTitle })}
          >
            <div className={radioCardButtonCVA.titleContainer()}>
              <Text className={radioCardButtonCVA.title()}>{title}</Text>
              {subTitle && (
                <Text className={radioCardButtonCVA.subtitle()}>
                  {subTitle}
                </Text>
              )}
            </div>
            <div className={radioCardButtonCVA.headerRightSection()}>
              {rightSection}
            </div>
          </Inline>
        </label>
      )
    }
  )
)

type RadioCardCompoundType = {
  Group: typeof RadioCardButtonGroup
}

const TypedRadioCardButton = BaseRadioCardButton as typeof BaseRadioCardButton &
  RadioCardCompoundType

TypedRadioCardButton.displayName = 'RadioCardButton'
TypedRadioCardButton.Group = RadioCardButtonGroup

export const RadioCardButton = TypedRadioCardButton
export type { RadioCardButtonProps } from './RadioCardButton.types'
export type { RadioCardButtonGroupProps } from './compound/RadioCardButtonGroup.types'
