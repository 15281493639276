import type { GetRoute, PatchRoute, PostRoute } from '../../types'

export const paidLeaveLedgerOperationsRoutes = {
  getPaidLeaveLedgerOp: {
    method: 'GET',
    path: '/paid_leave/locations/:location_id/ledger_operations'
  },
  postPaidLeaveLedgerOp: {
    method: 'POST',
    path: '/paid_leave/locations/:location_id/ledger_operations/adjustments'
  },
  patchPaidLeaveLedger: {
    method: 'PATCH',
    path: '/paid_leave/locations/:location_id/configuration/init_ledger_calculations'
  }
} as const

export type PaidLeaveLedgerOperationsRoutes = {
  getPaidLeaveLedgerOp: GetRoute<['location_id'], {}, {}>
  postPaidLeaveLedgerOp: PostRoute<['location_id'], {}, {}, {}>
  patchPaidLeaveLedger: PatchRoute<['location_id'], {}>
}
