import { Center } from '@libs/ui/ds'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import React from 'react'

import { Spinner } from '../subs/Spinner'

import type { LoaderContainerProps } from './LoaderContainer.types'

const loaderContainerCVA = {
  root: cva(['absolute z-20 flex size-full items-center justify-center'])
}

export const LoaderContainer: FC<LoaderContainerProps> = ({
  loaderProps,
  className
}) => {
  const currentLoaderProps = {
    ...loaderProps,
    theme: loaderProps?.theme || 'dark'
  }

  return (
    <Center className={loaderContainerCVA.root({ className })}>
      <Spinner {...currentLoaderProps} />
    </Center>
  )
}
