import { billingService } from '@libs/data-access/queries'
import moment from 'moment'
import React from 'react'

import { CancelledBanner } from './CancelledBanner'
import { NotPaidInvoiceBanner } from './NotPaidInvoiceBanner'
import RenewBanner from './RenewBanner'
import { SubscriptionBanner } from './SubscriptionBanner/SubscriptionBanner'
import { TrialEndBanner } from './TrialEndBanner'
import { UnpaidInvoiceBanner } from './UnpaidInvoiceBanner'

export type Props = {
  isBillingError?: boolean
}

export const SubscriptionBanners: React.FC<Props> = ({ isBillingError }) => {
  const detailsQuery = billingService.account.useGetDetails({
    enabled: !isBillingError
  })
  const statusQuery = billingService.account.useGetStatus({
    enabled: !isBillingError
  })

  const notPaidInvoicesQuery = billingService.account.useGetNotPaidInvoices()

  if (
    !statusQuery.isSuccess ||
    !detailsQuery.isSuccess ||
    !notPaidInvoicesQuery.isSuccess
  ) {
    return null
  }

  const termLastMonth = moment(statusQuery.data.current_term_ends_at).diff(
    moment(),
    'day'
  )

  return (
    <>
      {statusQuery.data.is_in_trial && (
        <SubscriptionBanner
          isLastTrialDay={statusQuery.data.actions.warn_last_trial_day}
          trialEndsAt={statusQuery.data.trial_ends_at}
        />
      )}
      {!statusQuery.data.is_in_trial &&
        !statusQuery.data.is_cancelled &&
        detailsQuery.data.is_yearly &&
        termLastMonth < 30 && (
          <RenewBanner
            end={statusQuery.data.current_term_ends_at}
            renewingAuto={statusQuery.data.is_active}
          />
        )}

      <UnpaidInvoiceBanner
        isAccountStatusCancelled={statusQuery.data.is_cancelled}
      />

      <NotPaidInvoiceBanner
        isAccountStatusCancelled={statusQuery.data.is_cancelled}
        data={notPaidInvoicesQuery.data}
      />

      {statusQuery.data.is_cancelled && statusQuery.data.is_in_trial && (
        <TrialEndBanner
          redirect={
            detailsQuery.data.is_location_based ? 'subscription' : undefined
          }
        />
      )}

      <CancelledBanner />
    </>
  )
}
