import { useMergedRef } from '@libs/ui/ds'
import React, { useEffect, forwardRef } from 'react'

type Props = {
  indeterminate?: boolean
  disabled?: boolean
}

const SelectableCheckbox = forwardRef<HTMLInputElement, Props>(
  ({ indeterminate, ...other }, ref: React.Ref<HTMLInputElement>) => {
    const defaultRef = React.useRef(null)
    const combinedRef = useMergedRef<HTMLInputElement>(ref, defaultRef)

    useEffect(() => {
      if (defaultRef?.current) {
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        defaultRef.current.indeterminate = indeterminate ?? false
      }
    }, [defaultRef, indeterminate])

    return <input type='checkbox' ref={combinedRef} {...other} />
  }
)

export default SelectableCheckbox
