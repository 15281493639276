export const ALERT_TYPES = {
  HOUR_CONFLICT: 'hour_conflict',
  REST_DURATION_BETWEEN_TWO_DAYS: 'rest_duration_between_two_days',
  MAX_WORKTIME_WITHOUT_BREAK: 'max_worktime_without_break',
  MAX_DAILY_TIME_RANGE: 'max_daily_time_range',
  MAX_DAILY_WORKTIME: 'max_daily_worktime',
  MAX_CONSECUTIVE_WORK_DAYS: 'max_consecutive_work_days',
  MAX_WEEKLY_WORKTIME: 'max_weekly_worktime',
  PART_TIME_MAX_WEEKLY_WORKTIME: 'part_time_max_weekly_worktime'
} as const

/**
 * Schedules Conflict on planned hours
 */
type HourConflictAlert = {
  type: typeof ALERT_TYPES.HOUR_CONFLICT
  date: string
  data: {
    fullname: string
  }
}

/**
 * Rest duration between 2 days
 */
type RestDurationBetweenTwoDayAlert = {
  type: typeof ALERT_TYPES.REST_DURATION_BETWEEN_TWO_DAYS
  date: string
  data: {
    shift_id: number
    fullname: string
    hours_current_daily_rest_duration: number
    hours_legal_daily_rest_duration: number
    previous_shift_date: string
    current_shift_date: string
  }
}

/**
 * Maximum weekly work time without break
 */
type MaxWorktimeWithoutBreakAlert = {
  type: typeof ALERT_TYPES.MAX_WORKTIME_WITHOUT_BREAK
  date: string
  data: {
    shift_id: number
    fullname: string
    hours_current_worktime: number
    hours_legal_worktime: number
    hours_minimum_break_time: number
    current_shift_date: string
  }
}

/**
 * Maximum daily time range
 * Note: previously named "thirteen_hours_max_daily_time_range"
 */
type ThirteenHoursMaxDailyTimeRangeAlert = {
  type: typeof ALERT_TYPES.MAX_DAILY_TIME_RANGE
  date: string
  data: {
    shift_id: number
    fullname: string
    date: string
    current_hours_amplitude: number
    max_hours_amplitude: number
  }
}

/**
 * Maximum daily work time
 */
type MaxDailyWorktimeAlert = {
  type: typeof ALERT_TYPES.MAX_DAILY_WORKTIME
  date: string
  data: {
    date: string
    fullname: string
    current_hours: number
    max_hours: number
  }
}

/**
 * Maximum consecutive worked day exceeded
 */
type MaxConsecutiveWorkDaysAlert = {
  type: typeof ALERT_TYPES.MAX_CONSECUTIVE_WORK_DAYS
  date: string
  data: {
    fullname: string
    days_current_consecutive_count: number
    days_max_consecutive_count: number
    first_consecutive_day: string
    last_consecutive_day: string
  }
}

/**
 * Maximum weekly work time duration
 */
type MaxWeeklyWorktimeAlert = {
  type: typeof ALERT_TYPES.MAX_WEEKLY_WORKTIME
  date: string
  data: {
    fullname: string
    hours_current_weekly_worktime: number
    hours_max_weekly_worktime: number
  }
}

/**
 * Maximum weekly work time duration
 * Only for part-time contract
 */
type PartTimeMaxWeeklyWorktimeAlert = {
  type: typeof ALERT_TYPES.PART_TIME_MAX_WEEKLY_WORKTIME
  date: string
  data: {
    fullname: string
    hours_current_weekly_worktime: number
    hours_max_weekly_worktime: number
  }
}

export type AlertData =
  | HourConflictAlert
  | RestDurationBetweenTwoDayAlert
  | MaxWorktimeWithoutBreakAlert
  | ThirteenHoursMaxDailyTimeRangeAlert
  | MaxDailyWorktimeAlert
  | MaxConsecutiveWorkDaysAlert
  | MaxWeeklyWorktimeAlert
  | PartTimeMaxWeeklyWorktimeAlert

export type AlertsType = AlertData['type']

type Alert = {
  user_contract_id: number
  alerts: AlertData[]
}
export default Alert
