import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import { memo, useState } from 'react'

import { IconWrap } from '../../IconWrap/base/IconWrap'
import { formatAvatarInitials } from '../utils/formatAvatarInitials'

import { AvatarProvider } from './Avatar.context'
import type { AvatarProps } from './Avatar.types'
import { AvatarInput } from './compound/AvatarInput/AvatarInput'

const avatarCVA = {
  root: cva(
    [
      'relative box-border flex items-center justify-center',
      'font-bold uppercase text-neutral-700'
    ],
    {
      variants: {
        size: {
          xs: 'size-4 min-h-4 min-w-4 text-4xs',
          s: 'size-6 min-h-6 min-w-6 text-3xs',
          m: 'size-8 min-h-8 min-w-8 text-xxs',
          l: 'size-10 min-h-10 min-w-10 text-xs',
          xl: 'size-20 min-h-20 min-w-20 text-xxl',
          xxl: 'size-32 min-h-32 min-w-32 text-4xl'
        },
        isImageVariant: {
          true: ''
        },
        loading: {
          true: 'opacity-60'
        }
      }
    }
  ),
  inner: cva(
    [
      'flex size-full items-center justify-center overflow-hidden bg-neutral-100'
    ],
    {
      variants: {
        isImageVariant: {
          true: '',
          false: ''
        },
        size: {
          xs: '',
          s: '',
          m: '',
          l: '',
          xl: '',
          xxl: ''
        }
      },
      compoundVariants: [
        /**
         * Intent: people
         */
        {
          isImageVariant: false,
          size: 'xs',
          class: 'rounded-sm'
        },
        {
          isImageVariant: false,
          size: 's',
          class: 'rounded'
        },
        {
          isImageVariant: false,
          size: 'm',
          class: 'rounded-md'
        },
        {
          isImageVariant: false,
          size: 'l',
          class: 'rounded-lg'
        },
        {
          isImageVariant: false,
          size: 'xl',
          class: 'rounded-2xl'
        },
        {
          isImageVariant: false,
          size: 'xxl',
          class: 'rounded-3xl'
        },
        /**
         * Intent: image
         */
        {
          isImageVariant: true,
          size: 'xs',
          class: 'rounded-sm'
        },
        {
          isImageVariant: true,
          size: 's',
          class: 'rounded-sm'
        },
        {
          isImageVariant: true,
          size: 'm',
          class: 'rounded-sm'
        },
        {
          isImageVariant: true,
          size: 'l',
          class: 'rounded'
        },
        {
          isImageVariant: true,
          size: 'xl',
          class: 'rounded-md'
        },
        {
          isImageVariant: true,
          size: 'xxl',
          class: 'rounded-lg'
        }
      ]
    }
  ),
  image: cva([], {
    variants: {
      isImageVariant: {
        true: 'object-contain',
        false: 'aspect-square object-cover object-center'
      }
    }
  }),
  props: {
    iconSize: {
      xs: 'xs',
      s: 'xs',
      m: 's',
      l: 'm',
      xl: 'l',
      xxl: 'xl'
    }
  }
} as const

const BaseAvatar: FC<AvatarProps> = memo(
  ({
    src,
    size = 'm',
    loading = false,
    className,
    labels,
    iconPlaceholder,
    alt,
    children,
    'data-testid': dataTestid,
    imgLoading = 'lazy',
    ...other
  }) => {
    const [preview, setPreview] = useState<string>('')
    const [loadError, setLoadError] = useState(false)
    const isImageVariant = !labels && Boolean(iconPlaceholder)
    const defaultLabels = labels || ['', '']
    const [firstLabel, secondLabel] = defaultLabels
    const imageSrc = preview || src
    const unloadedImage = !imageSrc || loadError

    return (
      <AvatarProvider
        value={{ setPreview, loadedImage: !unloadedImage, loading, size }}
      >
        <div
          className={avatarCVA.root({
            className,
            isImageVariant,
            size,
            loading
          })}
          data-testid={dataTestid}
        >
          <div className={avatarCVA.inner({ isImageVariant, size })}>
            {unloadedImage && labels && (
              <div>{formatAvatarInitials(defaultLabels)}</div>
            )}
            {unloadedImage && isImageVariant && (
              <IconWrap
                icon={iconPlaceholder}
                size={avatarCVA.props.iconSize[size]}
              />
            )}
            {!unloadedImage && (
              <img
                {...other}
                src={imageSrc}
                className={avatarCVA.image({ isImageVariant })}
                onError={() => setLoadError(true)}
                alt={alt || `${firstLabel} ${secondLabel}`}
                loading={imgLoading}
              />
            )}
          </div>
          {children}
        </div>
      </AvatarProvider>
    )
  }
)

type CompoundAvatarType = {
  Input: typeof AvatarInput
}

const TypedAvatar = BaseAvatar as typeof BaseAvatar & CompoundAvatarType
TypedAvatar.displayName = 'Avatar'

TypedAvatar.Input = AvatarInput
export const Avatar = TypedAvatar
export type { AvatarProps } from './Avatar.types'
