import type { FormatterParams } from '../../types'

import { minuteDecimalToHours } from './minuteDecimalToHours'

type Params = FormatterParams<
  | number
  | {
      value: number
    }
>
/**
 * Convert number of minutes into an hour readable string
 *
 * Input: 125
 * Output: 2h05
 */
export function hourDuration({ value: duration }: Params) {
  let value

  if (typeof duration === 'number') {
    value = duration
    return minuteDecimalToHours({ value })
  }

  if (typeof duration === 'object') {
    value = duration.value

    return minuteDecimalToHours({ value })
  }
}

export default {
  format: hourDuration
}
