import { useDebouncedValue, useUncontrolled } from '@libs/ui/ds'
import { useEffect } from 'react'

import type {
  AsyncAutocompleteOptions,
  AsyncAutocompleteProps
} from '../AsyncAutocomplete.types'

type MultiParams = {
  select: {
    value: AsyncAutocompleteProps['value']
    defaultValue: AsyncAutocompleteProps['defaultValue']
    onChange: AsyncAutocompleteProps['onChange']
  }
  isMulti: true
}

type SingleParams = {
  select: {
    value: AsyncAutocompleteProps['value']
    defaultValue: AsyncAutocompleteProps['defaultValue']
    onChange: AsyncAutocompleteProps['onChange']
  }
  isMulti: false
}

type Params = (MultiParams | SingleParams) & {
  options: AsyncAutocompleteOptions[]
  closeOnOptionClick: AsyncAutocompleteProps['closeOnOptionClick']
}

export const useAsyncAutocompleteValuesUncontrolled = ({
  select,
  isMulti,
  options,
  closeOnOptionClick
}: Params) => {
  const mutliDefaultValue = (select.defaultValue as string[]) || []
  const singleDefaultValue = (select.defaultValue as string) || ''

  const [searchValue, setSearchValue] = useUncontrolled({
    defaultValue: ''
  })
  const [debouncedValue] = useDebouncedValue(searchValue, 500)
  const [selectValue, setSelectValue] = useUncontrolled({
    onChange: select.onChange as
      | ((value: string | string[], ...payload: any[]) => void)
      | undefined,
    value: select.value,
    defaultValue: isMulti ? mutliDefaultValue : singleDefaultValue
  })
  const [selectedOptions, setSelectedOptions] = useUncontrolled<
    AsyncAutocompleteOptions[]
  >({
    defaultValue: []
  })

  const resetSelectValue = () => {
    setSearchValue('')
    setSelectedOptions(null as any)
    setSelectValue(null as any)
  }

  useEffect(() => {
    if (selectValue === '') {
      setSelectedOptions([])
    }
    if (selectValue === null) {
      resetSelectValue()
    }

    let newOptions: AsyncAutocompleteOptions[]

    if (isMulti && Array.isArray(selectValue)) {
      const isRemoving = selectedOptions.length > selectValue.length
      if (isRemoving) {
        const removedOption = selectedOptions.find(
          option => !selectValue.includes(option.value)
        )

        newOptions = [...selectedOptions]
        if (removedOption) {
          newOptions.splice(selectedOptions.indexOf(removedOption), 1)
        }
      } else {
        const addedValue = selectValue.find(
          option => !selectedOptions.find(selected => selected.value === option)
        )
        const addedOption = options.find(option => option.value === addedValue)
        newOptions = addedOption
          ? [...selectedOptions, addedOption]
          : selectedOptions
      }
    } else {
      const singleOption = options.find(option => option.value === selectValue)
      newOptions = singleOption ? [singleOption] : []
    }

    setSelectedOptions(newOptions)
    if (closeOnOptionClick) {
      setSearchValue('')
    }
  }, [selectValue])

  return {
    select: {
      selectValue,
      setSelectValue,
      resetSelectValue,
      toggleSelectValue: (value: string) => {
        let updatePayload: string | string[]

        if (isMulti) {
          if (selectValue) {
            const isRemoving = selectValue?.includes(value)

            updatePayload = isRemoving
              ? (selectValue as string[])?.filter(i => i !== value)
              : [...selectValue, value]
          } else {
            updatePayload = [value]
          }
        } else {
          const isRemoving = selectValue === value
          updatePayload = isRemoving ? '' : value
        }

        setSelectValue(updatePayload)
      }
    },
    search: {
      debouncedSeachValue: debouncedValue,
      searchValue,
      setSearchValue,
      resetSearchValue: () => setSearchValue('')
    },
    selectedOptions: selectedOptions || []
  }
}
