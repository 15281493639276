import { DuplicateIcon } from '@libs/ui/ds/assets/icons/DuplicateIcon'
import { Button, IconButton } from '@libs/ui/ds/lib/components'
import { useDSTranslation } from '@libs/ui/ds/lib/internals'
import type { FC } from 'react'
import { memo } from 'react'

import { CopyButtonWrap } from '../base/CopyButtonWrap'

import type { SimpleCopyButtonProps } from './SimpleCopyButton.types'

export const SimpleCopyButton: FC<SimpleCopyButtonProps> = memo(
  ({
    value,
    as = 'button',
    intent = 'neutral-dark',
    variant = 'secondary',
    ...other
  }) => {
    const { t } = useDSTranslation()

    return (
      <CopyButtonWrap
        value={value}
        infoTooltip={as === 'icon-button' ? true : undefined}
      >
        {as === 'button' ? (
          <Button
            {...other}
            intent={intent}
            variant={variant}
            leftIcon={<DuplicateIcon />}
          >
            {t('ds.components.copyButton.copy')}
          </Button>
        ) : (
          <IconButton
            {...other}
            intent={intent}
            variant={variant}
            icon={<DuplicateIcon />}
          />
        )}
      </CopyButtonWrap>
    )
  }
)

SimpleCopyButton.displayName = 'SimpleCopyButton'
export type { SimpleCopyButtonProps } from './SimpleCopyButton.types'
