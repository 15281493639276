import { useUncontrolled } from '@libs/ui/ds'
import { ArrowRightIcon, ArrowUpIcon } from '@libs/ui/ds/assets/icons'
import { Combobox } from '@libs/ui/ds/lib/components/overlays/Combobox/base/Combobox'
import { cva } from 'class-variance-authority'
import { type FC } from 'react'

import { useMenuContext } from '../../Menu.context'

import { MenuGroupProvider } from './MenuGroup.context'
import type { MenuGroupProps } from './MenuGroup.types'

const menuGroupCVA = {
  root: cva(['my-2'])
}

export const MenuGroup: FC<MenuGroupProps> = ({
  label,
  children,
  className,
  defaultOpened,
  opened,
  onOpenedChange,
  ...other
}) => {
  const [openedState, setOpenedState] = useUncontrolled({
    defaultValue: defaultOpened,
    value: opened,
    onChange: onOpenedChange
  })
  useMenuContext({ throwErrorIfNoContext: true })

  return (
    <MenuGroupProvider value={{}}>
      <div className={menuGroupCVA.root({ className })}>
        <Combobox.Option
          {...other}
          value=''
          onClick={() => setOpenedState(!openedState)}
          rightIcon={openedState ? <ArrowUpIcon /> : <ArrowRightIcon />}
        >
          {label}
        </Combobox.Option>
        {openedState && <div>{children}</div>}
      </div>
    </MenuGroupProvider>
  )
}

MenuGroup.displayName = 'MenuGroup'
