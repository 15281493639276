import type { AccountAddon } from '@libs/data-access/entities'
import { useMutation } from '@tanstack/react-query'

import type { ApiErrorResponse, UseAppMutationOptions } from '../../../../types'
import { userService } from '../../../User'
import {
  type EnableAccountAddonOptions,
  enableAccountAddon
} from '../accountAddon.api'
import { invalidateAddonCache } from '../addons.cacheKeys'

export const useEnableAccountAddon = (
  mutationOpts?: UseAppMutationOptions<
    AccountAddon,
    ApiErrorResponse,
    EnableAccountAddonOptions
  >
) => {
  return useMutation<AccountAddon, ApiErrorResponse, EnableAccountAddonOptions>(
    {
      ...mutationOpts,
      mutationFn: (opts: EnableAccountAddonOptions) => enableAccountAddon(opts),
      onSuccess: (data, variables, ...args) => {
        mutationOpts?.onSuccess?.(data, variables, ...args)
        invalidateAddonCache(variables.pathParams.addon_enum)
        userService.user.invalidateCurrent()
      }
    }
  )
}
