import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'

import type { AutoBoundaryProps } from './AutoBoundary.types'

const autoBoundaryCVA = cva(['m-auto h-full'], {
  variants: {
    boundarySize: {
      sm: 'max-w-screen-sm',
      md: 'max-w-screen-md',
      lg: 'max-w-screen-lg',
      xl: 'max-w-screen-xl',
      '2xl': 'max-w-screen-2xl'
    }
  },
  defaultVariants: {}
})

export const AutoBoundary = forwardRef<HTMLDivElement, AutoBoundaryProps>(
  ({ className, children, boundarySize = 'lg', ...other }, ref) => {
    return (
      <div
        {...other}
        ref={ref}
        className={autoBoundaryCVA({ className, boundarySize })}
      >
        {children}
      </div>
    )
  }
)

AutoBoundary.displayName = 'AutoBoundary'
export * from './AutoBoundary.types'
