import type { ReduxState, Meta } from '@spa/redux/ReduxState'
import moment from 'moment'

/**
 * Formats the date to set as datepicker input
 * Actually transforms it from 'YYYY-MM-DD' to 'DD/MM/YYYY'
 * @param  {String} date
 * @return {String}
 */

export const formatOneDate = date => moment(date).format('YYYY-MM-DD')

/**
 * Transforms a duration in minutes to a duration in hours and minutes.
 * @param  {Integer} durationInMinutes
 * @return {String} "HH:MM"
 */

export const getDuration = (start, end) =>
  (new Date(end).getTime() - new Date(start).getTime()) / (1000 * 60)

export const filterFalsy = array => array.filter(Boolean)

// When date is midnight and we want to display the previous day (eg: a rest which ends on the 13th of july but date is 14th at 00:00)
export const dateIsMidnight = date =>
  moment(date).hour() === 0 && moment(date).minutes() === 0

export const getMetaSelectorFactory =
  entity =>
  (state: ReduxState): Meta =>
    state[entity].get('meta')
