import type { MembershipContractTimeline } from '@libs/data-access/entities'
import { useQuery } from '@tanstack/react-query'

import { queryClientOptions } from '../../../../provider'
import type {
  PaginatedQueryResponse,
  UseAppQueryOptions
} from '../../../../types'
import {
  fetchMembershipContracts,
  type FetchMembershipContractsOptions
} from '../membership.api'
import { membershipQueryKeys } from '../membership.cacheKeys'

import { getActiveContract } from './selector/getActiveContract'

export const useFetchMembershipContracts = (
  request: FetchMembershipContractsOptions,
  queryOptions?: UseAppQueryOptions<
    PaginatedQueryResponse<MembershipContractTimeline, 'contracts'>
  >
) => {
  return useQuery({
    ...queryOptions,
    queryKey: membershipQueryKeys.contracts(request?.pathParams),
    queryFn: () => fetchMembershipContracts(request),
    staleTime: queryClientOptions.time.INFINITY,
    select: data => ({
      ...data,
      derived_state: {
        activeContract: getActiveContract(data.contracts)
      }
    })
  })
}
