import { Pagination } from '@libs/ui/ds/lib/components/navigation/Pagination/base/Pagination'
import { Center } from '@libs/ui/ds/lib/components/primitiveLayouts/Center/base/Center'
import { isNumber } from 'lodash-es'
import type { FC } from 'react'

import type { TableProps } from '../../Table.types'
import { useTableComputedStatus } from '../TableStates/useTableComputedStatus'

type Props = {
  pagination: TableProps['pagination']
  data: TableProps['data']
  states: TableProps['states']
}

export const TablePagination: FC<Props> = ({ pagination, data, states }) => {
  const status = useTableComputedStatus({ data, states })

  /**
   * Don't display table pagination whenever the status is invalid
   */
  if (status === 'error' || status === 'empty') {
    return null
  }

  /**
   * Don't display pagination, when the pagination state is incomplete
   */
  if (
    !pagination ||
    !isNumber(pagination.pageIndex) ||
    !pagination.pageCount ||
    !pagination.onChange ||
    pagination.pageCount <= 1 ||
    !data?.length
  ) {
    return null
  }

  return (
    <Center className='mt-12'>
      <Pagination
        pageIndex={pagination.pageIndex}
        pageCount={pagination.pageCount}
        onChange={(newPageIndex: number) => {
          if (pagination.onChange) {
            pagination.onChange({
              ...pagination,
              pageIndex: newPageIndex
            })
          }
        }}
      />
    </Center>
  )
}
