import { useMemoSelector } from '@spa/redux/hooks'
import { useCallback, useState } from 'react'
import { getCurrentAccount } from 'snap-redux/modules/accounts/selectors'
import { getCurrentMembershipSelector } from 'snap-redux/modules/memberships/selectors'
import { getCurrentUser } from 'snap-redux/modules/users/selectors'

import { useSegmentGroup } from './useSegmentGroup'
import { useSegmentIdentify } from './useSegmentIdentify'
import { useSegmentTrackPage } from './useSegmentTrackPage'

export const useSegment = () => {
  const [isIdentified, setIsIdentified] = useState(false)
  const [isGrouped, setIsGrouped] = useState(false)

  const user = useMemoSelector(getCurrentUser)
  const account = useMemoSelector(getCurrentAccount)
  const membership = useMemoSelector(getCurrentMembershipSelector)

  const onIdentify = useCallback(() => {
    setIsIdentified(true)
  }, [])

  const onGroup = useCallback(() => {
    setIsGrouped(true)
  }, [])

  useSegmentIdentify(
    {
      account,
      membership,
      user
    },
    onIdentify
  )

  useSegmentGroup(
    {
      account
    },
    onGroup
  )

  useSegmentTrackPage(isIdentified, isGrouped)
}
