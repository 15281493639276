import { useFetchCalendarFeed } from './CalendarFeed/hooks/useFetchCalendarFeed'
import { useUpdateCalendarFeedIntegration } from './CalendarFeed/hooks/useUpdateCalendarFeedIntegration'
import { useFetchFloatingBreaksWarning } from './Shift/Break/hooks/useFetchFloatingBreaksWarning'
import { useShiftSentNotificationsSubscription } from './Shift/hooks/useShiftSentNotificationsSubscription'
import * as scheduleAPI from './schedule.api'

class ScheduleService {
  api = scheduleAPI

  calendarFeed = {
    useFetchOne: useFetchCalendarFeed,
    useUpdateIntegration: useUpdateCalendarFeedIntegration
  }

  shift = {
    break: {
      useFetchFloatingBreaksWarning
    },
    useSentNotificationsSubscription: useShiftSentNotificationsSubscription
  }
}

export const scheduleService = new ScheduleService()
