import { Combobox as MantineCombobox } from '@mantine/core'
import type { FC } from 'react'
import { memo } from 'react'

import { ComboboxProvider } from './Combobox.context'
import type { ComboboxProps } from './Combobox.types'
import { ComboboxDivider } from './compound/ComboboxDivider'
import { ComboboxDropdown } from './compound/ComboboxDropdown'
import { ComboboxEmpty } from './compound/ComboboxEmpty'
import { ComboboxFooter } from './compound/ComboboxFooter'
import { ComboboxGroup } from './compound/ComboboxGroup'
import { ComboboxOption } from './compound/ComboboxOption'
import { ComboboxTarget } from './compound/ComboboxTarget'

export const BaseCombobox: FC<ComboboxProps> = memo(
  ({
    store,
    loading = false,
    dropdownWidth = 'm',
    dropdownHeight = 'm',
    children,
    dropdownPosition,
    closeOnOptionClick,
    withinPortal = true,
    ...other
  }) => {
    return (
      <ComboboxProvider
        value={{
          store,
          loading,
          closeOnOptionClick,
          dropdownWidth,
          dropdownHeight
        }}
      >
        <MantineCombobox
          classNames={{ dropdown: 'w-20' }}
          withinPortal={withinPortal}
          position={dropdownPosition}
          store={store}
          {...other}
        >
          {children}
        </MantineCombobox>
      </ComboboxProvider>
    )
  }
)

type CompoundComboboxType = {
  Group: typeof ComboboxGroup
  Option: typeof ComboboxOption
  Divider: typeof ComboboxDivider
  Dropdown: typeof ComboboxDropdown
  Target: typeof ComboboxTarget
  Empty: typeof ComboboxEmpty
  EventsTarget: typeof MantineCombobox.EventsTarget
  Footer: typeof ComboboxFooter
}

const TypedCombobox = BaseCombobox as typeof BaseCombobox & CompoundComboboxType

TypedCombobox.displayName = 'Combobox'

TypedCombobox.Group = ComboboxGroup
TypedCombobox.Option = ComboboxOption
TypedCombobox.Divider = ComboboxDivider
TypedCombobox.Dropdown = ComboboxDropdown
TypedCombobox.Target = ComboboxTarget
TypedCombobox.Empty = ComboboxEmpty
TypedCombobox.EventsTarget = MantineCombobox.EventsTarget
TypedCombobox.Footer = ComboboxFooter

export const Combobox = TypedCombobox
export type { ComboboxProps } from './Combobox.types'
export type { ComboboxDividerProps } from './compound/ComboboxDivider'
export type { ComboboxTargetProps } from './compound/ComboboxTarget'
export type { ComboboxGroupProps } from './compound/ComboboxGroup'
export type { ComboboxOptionProps } from './compound/ComboboxOption.types'
export type { ComboboxDropdownProps } from './compound/ComboboxDropdown'
export type { ComboboxFooterProps } from './compound/ComboboxFooter'
