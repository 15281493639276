import { select } from 'redux-saga/effects'
import type { Shift } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import { shiftsSelectors } from '../../../../selectors'
import type { PayloadActionWithoutType } from '../../../types/types'
import updateShiftTimeRange from '../updateShiftTimeRange'

import { adaptShiftBreaksSlotOnSlide } from './adaptShiftBreaksSlotOnSlide'

type Params = PayloadActionWithoutType<{
  shiftId: number
  newStart: string
  newEnd: string
  notifyUser?: boolean
  errorCallback?: () => void
}>

function* updateShiftOnSlide({ payload }: Params): any {
  const shift: Shift = yield select(state =>
    shiftsSelectors.getCachedById(state, payload.shiftId)
  )

  yield updateShiftTimeRange({
    payload: {
      shiftId: payload.shiftId,
      starts_at: payload.newStart,
      ends_at: payload.newEnd,
      notify_user: payload.notifyUser,
      shift_breaks_attributes: adaptShiftBreaksSlotOnSlide(
        payload.newStart,
        shift
      ),
      errorCallback: payload.errorCallback
    }
  })
}

export default updateShiftOnSlide
