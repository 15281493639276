import type { UpdateWeeklyScheduleSchema } from '@libs/data-access/entities'
import { normalize } from 'normalizr'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http/api'

import { Weeklyschedule } from '../../../models'
import { setEntities } from '../../../reducers/entities'
import { hydrateOldStoreWeeklyschedules } from '../../controllers/hydratesOldStore'
import type {
  AsyncActionCallbacks,
  PayloadActionWithoutType
} from '../../types/types'

type Params = PayloadActionWithoutType<{
  weeklyscheduleId: number
  data: Partial<UpdateWeeklyScheduleSchema>
  callbacks?: AsyncActionCallbacks<UpdateWeeklyScheduleSchema>
}>

export function* updateWeeklyscheduleById({
  payload: { weeklyscheduleId, data, callbacks = {} }
}: Params): any {
  try {
    const res = yield apiRequest({
      route: 'putWeeklyschedule',
      params: { id: weeklyscheduleId },
      queryParams: {
        with_permissions: true
      },
      body: data
    })

    const normalized = normalize([res], [Weeklyschedule])

    yield put(
      setEntities({ weeklyschedules: normalized.entities.weeklyschedules })
    )
    yield hydrateOldStoreWeeklyschedules()
  } catch (e) {
    if (callbacks.reject) {
      callbacks.reject(e)
    }
  }
}
