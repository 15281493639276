import { put } from 'redux-saga/effects'

import { setPaidLeaveLegder } from '../modules/entities'
import { resetMeta } from '../modules/meta'
import { resetPagination } from '../modules/pagination'

export default function* resetPaidLeaveLedgerOpsData() {
  yield put(resetPagination())
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  yield put(resetMeta())
  yield put(setPaidLeaveLegder([]))
}
