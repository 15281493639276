import { useMutation } from '@tanstack/react-query'

import type { UseAppMutationOptions, ApiErrorResponse } from '../../../types'
import { postOauthToken, type PostOauthTokenOptions } from '../auth.api'
import { authCacheKeys } from '../auth.cacheKeys'
import type { PostOauthResponse } from '../types/Oauth.post'

export type MutationOptionPostOauthToken = UseAppMutationOptions<
  PostOauthResponse,
  ApiErrorResponse,
  PostOauthTokenOptions
>

export const usePostOauthToken = (
  mutationOptions?: MutationOptionPostOauthToken
) => {
  return useMutation({
    ...mutationOptions,
    mutationKey: authCacheKeys.postOauthToken(),
    mutationFn: (opts: PostOauthTokenOptions) => postOauthToken(opts),

    onSuccess: (data, variables, ...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, ...args)
      }
    }
  })
}
