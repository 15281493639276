import { useQuery } from '@tanstack/react-query'

import type { UseAppQueryOptions } from '../../../types'
import type { GetUserInvitationOptions } from '../auth.api'
import { getUserInvitation } from '../auth.api'
import { authCacheKeys } from '../auth.cacheKeys'
import type { GetUserInvitationResponse } from '../types'

export const useGetUserInvitation = (
  request: GetUserInvitationOptions,
  queryOptions?: UseAppQueryOptions<GetUserInvitationResponse>
) => {
  return useQuery({
    ...queryOptions,
    queryKey: authCacheKeys.getUserInvitation(),
    queryFn: () => getUserInvitation(request)
  })
}
