import { LockBoldIcon } from '@libs/ui/ds/assets/icons'
import type { BaseComponent } from '@libs/ui/ds/lib/internals'
import type { FC, HTMLAttributes } from 'react'
import React from 'react'

import { Badge } from '../../../../dataDisplay/Badge/base/Badge'

export type HeroCardStartSectionProps = BaseComponent &
  HTMLAttributes<HTMLDivElement> & {
    badgeLabel: string
  }

export const HeroCardStartSection: FC<HeroCardStartSectionProps> = ({
  badgeLabel
}) => {
  return (
    <div>
      <Badge
        color='secondary.blue'
        leftIcon={<LockBoldIcon />}
        label={badgeLabel}
      />
    </div>
  )
}
