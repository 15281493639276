import type { GetRoute } from '../../types'

import type { TimesheetSchema } from './shemas'

export const timesheetsRoutes = {
  getTimesheets: {
    method: 'GET',
    path: 'memberships/:membership_id/timesheets'
  }
} as const

export type TimesheetsRoutes = {
  getTimesheets: GetRoute<['membership_id'], {}, TimesheetSchema>
}
