import type { ContractRecordMap } from '@libs/data-access/entities'
import moment from 'moment'
import { createSelector } from 'reselect'
import { getCurrentAccount } from 'snap-redux/modules/accounts/selectors'
import {
  findAndMergeAmendmentsRelativeToDate,
  isCurrentContract
} from 'snap-redux/modules/contracts'
import { getDataByForeignIds } from 'snap-redux/services/immutableUtils'

import { weeklySchedulesSelector } from './planningWeeklySchedules.selector'

/**
 * teamIds selector
 */
const weeklySchedulesByTeamId = createSelector(
  [weeklySchedulesSelector],
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  weeklyschedules => weeklyschedules.map(ws => ws.team_id)
)

/**
 * contractsByTeamIds Selector
 */
const contractsByTeamIds = createSelector(
  [weeklySchedulesByTeamId, state => state],
  (teamIds, state) => {
    return getDataByForeignIds(
      state,
      'contracts',
      'team_id',
      teamIds.valueSeq()
    )
  }
)

export const contractsByTeamIdsAndWeek = createSelector(
  [contractsByTeamIds, (_, props) => props.week],
  (contracts, week) => {
    return contracts.filter(isCurrentContract, {
      startDateISO: moment(week).startOf('week').format('YYYY-MM-DD'),
      endDateISO: moment(week).endOf('week').format('YYYY-MM-DD')
    }) as ContractRecordMap
  }
)

export const contractWithAmendments = createSelector(
  [
    contractsByTeamIdsAndWeek,
    getCurrentAccount,
    state => state,
    (_, { weekStartAt }) => weekStartAt
  ],
  (contracts, account, state, weekStartAt) => {
    return contracts.map(
      findAndMergeAmendmentsRelativeToDate(state, account, weekStartAt)
    ) as ContractRecordMap
  }
)

const membershipsSelector = createSelector(
  [
    // contract selector
    contractsByTeamIdsAndWeek,
    state => state.memberships.get('data'),
    (_, { locationId }) => locationId
  ],
  (contracts, memberships, locationId) => {
    return (
      contracts
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        .mapEntries(([, { membership_id: membershipId }]) => {
          const membership = memberships.get(membershipId)
          return [membershipId, membership]
        })
        .filter(v => Boolean(v))
        .filter(({ accessible_location_ids }) =>
          accessible_location_ids.includes(locationId)
        ) as ContractRecordMap
    )
  }
)

export default membershipsSelector
