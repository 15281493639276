import { useMutation } from '@tanstack/react-query'

import type {
  ApiErrorBillingResponse,
  UseAppMutationOptions
} from '../../../../types'
import { invalidateAccountDetailsCache } from '../../AccountDetails/accountDetails.cacheKeys'
import type { PostLinkLocationToGroup } from '../locationGroupBillingInfo.api'
import { postLinkLocationToGroup } from '../locationGroupBillingInfo.api'
import { locationGroupBillingInfoCacheKeys } from '../locationGroupBillingInfo.cacheKeys'

export const useLinkLocationToGroup = (
  mutationOptions?: UseAppMutationOptions<
    {},
    ApiErrorBillingResponse,
    PostLinkLocationToGroup
  >
) => {
  return useMutation<{}, ApiErrorBillingResponse, PostLinkLocationToGroup>({
    ...mutationOptions,
    mutationKey: locationGroupBillingInfoCacheKeys.updateBillingInfo(),

    mutationFn: postLinkLocationToGroup,

    onSuccess: (data, variables, ...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, ...args)
      }

      invalidateAccountDetailsCache()
    }
  })
}
