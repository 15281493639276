import type {
  GetSignableTimesheetsResponse,
  SignableTimesheetPeriodStatusModel,
  TimesheetDocumentUrlResponse,
  TimesheetGenerationResponse
} from '@libs/data-access/entities'

import type { GetRoute, PostRoute } from '../../types'

export const signableTimesheetsRoutes = {
  getSignableTimesheets: {
    method: 'GET',
    path: '/teams/:team_id/signable_timesheets'
  },
  sendSignableTimesheets: {
    method: 'POST',
    path: '/signable_timesheets'
  },
  getSignableTimesheetsPeriodStatus: {
    method: 'GET',
    path: '/teams/:team_id/signable_timesheets/period_status'
  },
  getInitialDocumentUrl: {
    method: 'GET',
    path: '/signable_timesheets/:signable_timesheet_id/initial_document_url'
  },
  getSignedDocumentUrl: {
    method: 'GET',
    path: '/signable_timesheets/:signable_timesheet_id/signed_document_url'
  }
} as const

export type SignableTimesheetsRoutes = {
  getSignableTimesheets: GetRoute<
    ['team_id'],
    {
      period_start: string
    },
    GetSignableTimesheetsResponse
  >
  sendSignableTimesheets: PostRoute<
    [],
    {
      membership_ids: number[]
      period_start: string
    },
    TimesheetGenerationResponse[]
  >
  getSignableTimesheetsPeriodStatus: GetRoute<
    ['team_id'],
    {
      period_start: string
    },
    SignableTimesheetPeriodStatusModel
  >
  getInitialDocumentUrl: GetRoute<
    ['signable_timesheet_id'],
    {},
    TimesheetDocumentUrlResponse
  >
  getSignedDocumentUrl: GetRoute<
    ['signable_timesheet_id'],
    {},
    TimesheetDocumentUrlResponse
  >
}
