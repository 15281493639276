import { useClipboard } from '@libs/ui/ds'
import { useDSTranslation } from '@libs/ui/ds/lib/internals'
import type { FC } from 'react'
import React, { memo } from 'react'

import { Tooltip } from '../../../overlays/Tooltip/base/Tooltip'

import type { CopyButtonWrapProps } from './CopyButtonWrap.types'

export const CopyButtonWrap: FC<CopyButtonWrapProps> = memo(
  ({ value, children, infoTooltip, ...other }) => {
    const { t } = useDSTranslation()
    const clipboard = useClipboard({ timeout: 2000 })

    const Child = React.Children.map(children, (child: any) => {
      return React.cloneElement(child, {
        onClick: () => {
          clipboard.copy(value)
        }
      })
    })

    return (
      <Tooltip
        {...other}
        position='top'
        opened={infoTooltip ? undefined : clipboard.copied}
        label={
          clipboard.copied
            ? t('ds.components.copyButton.copied')
            : t('ds.components.copyButton.copy')
        }
      >
        <div className='w-fit'>{Child}</div>
      </Tooltip>
    )
  }
)

CopyButtonWrap.displayName = 'CopyButtonWrap'
export type { CopyButtonWrapProps } from './CopyButtonWrap.types'
