import { InlineInputWrap, useUncontrolled } from '@libs/ui/ds'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import React from 'react'

import { Stack } from '../../../../../primitiveLayouts/Stack/base/Stack'

import { CheckboxGroupProvider } from './CheckboxGroup.context'
import type { CheckboxGroupProps } from './CheckboxGroup.types'

const checkboxGroupCVA = {
  fieldSection: cva(['mt-2 flex'], {
    variants: {
      position: {
        'top-inline': 'flex-row gap-8',
        'top-stacked': 'flex-col items-start gap-2'
      }
    }
  }),
  props: {
    position: {
      'top-inline': 'top',
      'top-stacked': 'top'
    }
  }
} as const

export const CheckboxGroup: FC<CheckboxGroupProps> = ({
  label,
  desc,
  errorMessage,
  disabled,
  required,
  value,
  size,
  name,
  position,
  onChange,
  className,
  children
}) => {
  const [state, setState] = useUncontrolled<string[]>({
    onChange,
    value,
    defaultValue: []
  })

  return (
    <CheckboxGroupProvider
      value={{
        value: state,
        name,
        disabled,
        size,
        onChange: setState
      }}
    >
      <InlineInputWrap
        className={className}
        label={label}
        desc={desc}
        errorMessage={errorMessage}
        disabled={disabled}
        disableLabelInteraction
        required={required}
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        position={checkboxGroupCVA.props.position[position]}
        field={
          <Stack className={checkboxGroupCVA.fieldSection({ position })}>
            {children}
          </Stack>
        }
      />
    </CheckboxGroupProvider>
  )
}
