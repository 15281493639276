import type {
  ReportsExportPayload,
  SpecialReportsExportPayload,
  TimesheetExportPayload,
  WeeklyscheduleExportPayload
} from '@libs/data-access/entities'

import { httpServices } from '../../apis/http/httpServices'
import type { QueryFnParams } from '../../types'

export type CreateExportFilePayload =
  | WeeklyscheduleExportPayload
  | TimesheetExportPayload
  | ReportsExportPayload
  | SpecialReportsExportPayload

export type CreateExportFileResponse = {
  job_id: string
}

export type CreateExportFileOptions = QueryFnParams<{
  payload: CreateExportFilePayload
}>

/**
 *
 */
export const createExportFile = async (opts?: CreateExportFileOptions) => {
  const response = await httpServices.core.post<CreateExportFileResponse>(
    `/accounts/${httpServices.authInfo.accountId}/export_files`,
    {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      ...opts.payload,
      requester_id: httpServices.authInfo.userId,
      current_account: httpServices.authInfo.accountId
    }
  )
  return response.data
}
