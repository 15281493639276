import { cva } from 'class-variance-authority'
import type { FC, PropsWithChildren } from 'react'

import { Flex } from '../../../../primitiveLayouts/Flex/base/Flex'

import { ComboboxDivider } from './ComboboxDivider'

const comboboxGroupCVA = {
  root: cva(['w-full max-w-full gap-y-0.5 border-neutral-200']),
  label: cva(['max-w-full p-2 text-xs font-normal text-neutral-500'])
}

export type ComboboxGroupProps = PropsWithChildren<{
  className?: string
  /**
   * Label text of the group
   */
  label?: string
}>

export const ComboboxGroup: FC<ComboboxGroupProps> = ({
  className,
  children,
  label,
  ...other
}) => {
  return (
    <>
      <Flex {...other} className={comboboxGroupCVA.root({ className })}>
        {label && <div className={comboboxGroupCVA.label()}>{label}</div>}
        {children}
      </Flex>
      <ComboboxDivider className='last-of-type:hidden' />
    </>
  )
}
