import type { ReduxState } from '@spa/redux/ReduxState'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'

import { membershipsSelectors } from '../../../memberships'
import { teamsSelectors } from '../../../teams'
import { contractsBaseSelectors } from '../../contractsBaseSelectors'

type Params = {
  teamId: number
}

/**
 * Filter contracts to only retrieve the ones that are displayed on planning
 */
export const getContractsIncludedInSchedule = createCachedSelector(
  (state: ReduxState, { teamId }: Params) =>
    teamsSelectors.getCachedById(state, teamId),
  contractsBaseSelectors.getCachedMap,
  membershipsSelectors.getCachedMap,

  (team, contracts, memberships) => {
    const userContractIds = team?.user_contracts || []

    return userContractIds.filter(i => {
      const contract = contracts[i]
      const membership = memberships[contract.membership_id]
      return membership.include_owner_in_schedules
    })
  }
)((_, { teamId }) => fallbackToDefaultCacheKey(teamId))
