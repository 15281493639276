import { chunk } from 'lodash-es'

export const DEFAULT_REQUEST_CHUNK_SIZE = 20

type Options = {
  chunkSize: number
}

export const getContractsChunks = (
  contractIds: number[],
  opts: Options = { chunkSize: DEFAULT_REQUEST_CHUNK_SIZE }
) => {
  return chunk(contractIds, opts.chunkSize)
}
