import type { WorktimeTimesheetSchema } from '@libs/data-access/entities'
import { useQuery } from '@tanstack/react-query'
import type { UseAppQueryOptions } from 'libs/data-access/queries/src/lib/types'

import type { FetchTimesheetsByRangeOptions } from '../timesheets.api'
import { fetchTimesheetsByRange } from '../timesheets.api'
import { timesheetsQueryKeys } from '../timesheets.cacheKeys'

export const useFetchTimesheetByRange = (
  request: FetchTimesheetsByRangeOptions,
  queryOptions?: UseAppQueryOptions<WorktimeTimesheetSchema>
) =>
  useQuery({
    ...queryOptions,
    queryKey: timesheetsQueryKeys.timesheets_by_range(request?.queryParams),
    queryFn: () => fetchTimesheetsByRange(request)
  })
