import { createBrowserHistory } from 'history'
import Immutable from 'immutable'
import { filter, values } from 'lodash-es'
import { legacy_createStore as createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import createSagaMiddleware from 'redux-saga'
import socketMiddleware from 'snap-redux/middlewares/socket'
import { Location } from 'snap-redux/modules/locations'
import { Team } from 'snap-redux/modules/teams'

import * as middlewares from './middlewares/logger'
import createReducer from './reducers'
import AppSagas from './sagas'

export const history = createBrowserHistory()

export function configureStore(initialState) {
  // Build the enhancers
  const sagaMiddleware = createSagaMiddleware()
  const filteredMidelwares = filter(
    filter(
      values(middlewares),
      middlewareValue => typeof middlewareValue === 'function'
    )
  )
  const middlewaresArr = [
    ...filteredMidelwares,
    sagaMiddleware,
    socketMiddleware
  ]

  // @ts-ignore migration from js(x) to ts(x)
  const composeEnhancers = composeWithDevTools({
    serialize: {
      immutable: Immutable,
      refs: [Location, Team]
    }
  })

  const reducers = createReducer()
  // @ts-ignore migration from js(x) to ts(x)
  const enhancers = composeEnhancers(applyMiddleware(...middlewaresArr))

  // Build the store
  // @ts-ignore migration from js(x) to ts(x)
  const store = createStore(reducers, initialState, enhancers)

  // Run the generators here
  sagaMiddleware.run(AppSagas)

  return store
}

const store = configureStore({})

export default store
