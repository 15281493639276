import { useMutation } from '@tanstack/react-query'

import type { UseAppMutationOptions, ApiErrorResponse } from '../../../../types'
import { invalidateAccountDetailsCache } from '../../AccountDetails/accountDetails.cacheKeys'
import type {
  CreateLocationGroupDraftOptions,
  CreateLocationGroupDraftResponse
} from '../locationGroupDraft.api'
import { createLocationGroupDraft } from '../locationGroupDraft.api'
import { locationGroupDraftCacheKeys } from '../locationGroupDraft.cacheKeys'

export const useCreateLocationGroupDraft = (
  mutationOptions?: UseAppMutationOptions<
    CreateLocationGroupDraftResponse,
    ApiErrorResponse,
    CreateLocationGroupDraftOptions
  >
) => {
  return useMutation({
    ...mutationOptions,
    mutationKey: locationGroupDraftCacheKeys.createOne(),
    mutationFn: (opts: CreateLocationGroupDraftOptions) =>
      createLocationGroupDraft(opts),

    onSuccess: (data, variables, ...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, ...args)
      }

      invalidateAccountDetailsCache()
    }
  })
}
