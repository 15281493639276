import type { FC } from 'react'
import { Link } from 'react-router-dom'

import { Anchor } from '../../../Anchor/base/Anchor'
import { breadcrumbCVA } from '../Breadcrumb.classes'
import type { BreadcrumbItem as BreadcrumbItemType } from '../Breadcrumb.types'

type BreadcrumbItemProps = {
  isLast: boolean
  item: BreadcrumbItemType
}

export const BreadcrumbItem: FC<BreadcrumbItemProps> = ({ isLast, item }) => {
  if (isLast) {
    return (
      <div key={item.label} className={breadcrumbCVA.item({ isLast })}>
        {item.label}
      </div>
    )
  }

  return (
    <>
      <Anchor
        to={(item as BreadcrumbItemType).route || ''}
        variant='primary'
        component={Link}
        key={item.label}
        className={breadcrumbCVA.item({ isLast })}
      >
        {item.label}
      </Anchor>
      <span>/</span>
    </>
  )
}
