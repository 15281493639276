import type {
  ShiftBreakRecord,
  ShiftTemplateSchema
} from '@libs/data-access/entities'
import { copyDateOnly } from '@spa/utils/date'
import type Immutable from 'immutable'
import type { MomentInput } from 'moment'
import moment from 'moment'

/**
 *
 */
const addOneDayIfOverlaps = (date: MomentInput, overlaps: boolean) => {
  return moment(date).add(overlaps ? 1 : 0, 'days')
}

/**
 * Check if a date is after
 * We need to compare digits because shift templates only contains
 * data about hours and minutes and not dates
 */
const isAfter = (start: MomentInput, end: MomentInput) => {
  const momentStart = moment(start)
  const momentEnd = moment(end)
  const sameHour = momentStart.hour() === momentEnd.hour()

  return sameHour
    ? momentStart.minutes() > momentEnd.minutes()
    : momentStart.hour() > momentEnd.hour()
}

/**
 * Compute shift end datetime
 */
const computeShiftEnd = (template: ShiftTemplateSchema, date: string) => {
  const end = copyDateOnly(template.ends_at, date)
  const overlaps = isAfter(template.starts_at, template.ends_at)
  return addOneDayIfOverlaps(end, overlaps).toISOString()
}

/**
 * Compute break start datetime
 */
const computeBreakStart = (
  template: ShiftTemplateSchema,
  shiftBreak: ShiftBreakRecord,
  date: string
) => {
  const start = copyDateOnly(template.starts_at, date)
  const breakOverlaps = isAfter(
    template.starts_at,
    shiftBreak.planned_starts_at
  )

  const formattedBreakStart = addOneDayIfOverlaps(
    copyDateOnly(shiftBreak.planned_starts_at, start),
    breakOverlaps
  )

  return formattedBreakStart.toISOString()
}

/**
 * Compute break end datetime
 */
const computeBreakEnd = (
  template: ShiftTemplateSchema,
  shiftBreak: ShiftBreakRecord,
  date: string
) => {
  const end = copyDateOnly(template.ends_at, date)
  const breakOverlaps = isAfter(template.starts_at, shiftBreak.planned_ends_at)

  const formattedBreakEnd = addOneDayIfOverlaps(
    copyDateOnly(shiftBreak.planned_ends_at, end),
    breakOverlaps
  )

  return formattedBreakEnd.toISOString()
}

export const templateToShift = (
  template: ShiftTemplateSchema,
  weeklyscheduleId,
  contractId,
  date
) => {
  const {
    label_id: labelId,
    starts_at: startsAt,
    breakduration,
    shift_breaks: shiftBreaks,
    shift_meals: shiftMeals
  } = template

  const updatedBreaks = shiftBreaks.map(shiftBreak => {
    // We only update the breaks with time range
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    if (shiftBreak.planned_starts_at) {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      const breakStart = computeBreakStart(template, shiftBreak, date)
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      const breakEnd = computeBreakEnd(template, shiftBreak, date)

      return {
        ...shiftBreak,
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        real_duration: shiftBreak.planned_duration,
        planned_starts_at: breakStart,
        planned_ends_at: breakEnd,
        real_starts_at: breakStart,
        real_ends_at: breakEnd
      }
    }

    return {
      ...shiftBreak,
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      real_duration: shiftBreak.planned_duration
    }
  })

  return {
    user_contract_id: contractId,
    weeklyschedule_id: weeklyscheduleId,
    starts_at: copyDateOnly(startsAt, date).toISOString(),
    ends_at: computeShiftEnd(template, date),
    breakduration,
    label_id: labelId,
    shift_breaks_attributes: updatedBreaks as Immutable.List<ShiftBreakRecord>,
    shift_meals_attributes: shiftMeals,
    notify_user: true
  }
}

export default templateToShift
