import queryString from 'query-string'
import { call } from 'redux-saga/effects'
import {
  FETCH_TIMESHEETS_FAILED,
  FETCH_TIMESHEETS_SUCCEEDED
} from 'snap-redux/actionTypes'
import { fetchSagaEntity } from 'snap-redux/services/sagaUtils'

import { call as callAPI } from '../../infra/http'

const timesheetSagaEntity = {
  success: (data, membershipId) => ({
    type: FETCH_TIMESHEETS_SUCCEEDED,
    data,
    membershipId
  }),

  failure: (error, membershipId) => ({
    type: FETCH_TIMESHEETS_FAILED,
    error,
    membershipId
  }),

  fetchAPI: (membershipId, options, params) => {
    const queryParams = queryString.stringify(params)

    return callAPI(
      `/memberships/${membershipId}/timesheets?${queryParams}`,
      options
    )
  }
}

export function* doFetchTimesheet({ membershipId, params }, resolve, reject) {
  yield call(
    fetchSagaEntity,
    timesheetSagaEntity,
    membershipId,
    params,
    undefined,
    resolve,
    reject
  )
}
