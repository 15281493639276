import type { UseTranslationOptions } from 'react-i18next'
import { useTranslation, withTranslation } from 'react-i18next'

import i18nAppInstance from '../i18nApp.setup'

import { useCommonsTranslation } from './commonNamespaceServices'

/**
 * @description Helper that injects commons translations services inside the current translation's file
 * It is useful to exposes at the same time:
 * - Feature scope translations
 * - helpers, dates, global notifications translations
 */
const useBundleTranslation = (type: string, opts) => {
  const { t, ...defaultApi } = useTranslation(type, {
    ...opts,
    useSuspense: true,
    i18n: i18nAppInstance
  })
  const commons = useCommonsTranslation()

  return { t, ...commons, ...defaultApi }
}

/**
 * Define a new translation service and expose subset of i18n API
 */
export const defineTranslationService = (serviceName: string) => {
  return [
    (opts?: UseTranslationOptions<string>) =>
      useBundleTranslation(serviceName, opts),
    () => withTranslation(serviceName),
    () => i18nAppInstance.loadNamespaces(serviceName)
  ] as const
}
