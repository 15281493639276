import type { ReduxState } from '@spa/redux/ReduxState'
import Immutable from 'immutable'
import { browserHasEnabledWebSocket } from 'snap-redux/modules/socket/helpers'

import {
  SOCKET_STATUS_NOT_CONNECTED,
  SOCKET_STATUS_CONNECTED,
  SOCKET_STATUS_CONNECTING
} from '.'

export const socketConnectionStatus = (state: ReduxState) =>
  state.socket.getIn(['meta', 'status']) || SOCKET_STATUS_NOT_CONNECTED

export const isSocketConnectionLoading = (state: ReduxState) =>
  socketConnectionStatus(state) === SOCKET_STATUS_CONNECTING

export const isSocketConnected = (state: ReduxState) =>
  socketConnectionStatus(state) === SOCKET_STATUS_CONNECTED

export const isSocketEnabled = (state: ReduxState) =>
  browserHasEnabledWebSocket && isSocketConnected(state)

export const connectedChannels = (state: ReduxState) =>
  state.socket.get('connectedChannels', Immutable.Set())

export const isConnectedToChannel = (state: ReduxState, channel: string) =>
  connectedChannels(state).has(channel)
