import type { NormalizeEntity } from '@libs/data-access/entities'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { SubscriptionModel } from '../models/SubscriptionModel'

export type ParamsState = {
  payload: {
    account_id: number
  }
}

type NullableObj<T> = {
  [P in keyof T]: T[P] | null
}

const initialState: NullableObj<SubscriptionModel> = {
  id: null,
  account_id: null,
  activated_at: null,
  billing_info: null,
  chargebee_customer_id: null,
  chargebee_payment_method: null,
  chargebee_subscription_id: null,
  chargebee_subscription_status: null,
  current_term_starts_at: null,
  current_term_ends_at: null,
  pay_connector_activated: null,
  plan_id: null,
  pricing_method: null,
  reactivated_at: null,
  trial: null,
  trial_starts_at: null,
  trial_ends_at: null,
  cancellation_reason: null,
  cancellation_sub_reason: null,
  cancellation_reason_description: null,
  indicative_reactivation_date: null
}

export type EntitiesState = {
  subscription: NormalizeEntity<SubscriptionModel>
}

const entitiesSlice = createSlice({
  name: 'billing/subscription/entities',
  initialState,
  reducers: {
    setSubscription(state, action: PayloadAction<Partial<SubscriptionModel>>) {
      return { ...state, ...action.payload }
    },

    // Since each call return all the attributes of the object,
    // we juste have to replace the old state by the response's payload.
    updateSubscription(_, action: PayloadAction<SubscriptionModel>) {
      return { ...action.payload }
    }
  }
})

export const { setSubscription, updateSubscription } = entitiesSlice.actions
export default entitiesSlice.reducer
