import { useMergedRef } from '@libs/ui/ds'
import { Switch as MantineSwitch } from '@mantine/core'
import { cva } from 'class-variance-authority'
import { forwardRef, memo, useRef } from 'react'

import { InlineInputWrap } from '../../../forms/InlineInputWrap/base/InlineInputWrap'

import type { SwitchProps } from './Switch.types'

const switchCVA = {
  input: cva(['peer/input']),
  track: cva(
    [
      'cursor-pointer rounded-full border-none bg-neutral-200',
      /**
       * checked state
       */
      'peer-checked/input:bg-brand-green-700',
      /**
       * disabled state
       */
      'peer-disabled/input:cursor-default peer-disabled/input:bg-neutral-200',
      /**
       * checked+disabled state
       */
      'peer-disabled/input:peer-checked/input:!bg-brand-green-200'
    ],
    {
      variants: {
        size: {
          s: 'h-6 w-10 peer-checked/input:[&>*]:left-[calc(100%-20px)]',
          m: 'h-8 w-14 peer-checked/input:[&>*]:left-[calc(100%-28px)]'
        }
      }
    }
  ),
  thumb: cva(['border-none'], {
    variants: {
      size: {
        s: 'left-1 size-4',
        m: 'left-1 size-6'
      },
      disabled: {
        true: '!bg-neutral-400'
      }
    }
  })
}

export const Switch = memo(
  forwardRef<HTMLInputElement, SwitchProps>(
    (
      {
        disabled,
        name,
        className,
        checked,
        onChange,
        size = 'm',
        label,
        desc,
        endSection,
        errorMessage,
        required,
        position,
        fillWidth,
        disableLabelInteraction,
        ...other
      },
      forwardRef
    ) => {
      const inputRef = useRef<HTMLInputElement>(null)
      const mergedRef = useMergedRef(forwardRef, inputRef)

      return (
        <InlineInputWrap
          className={className}
          label={label}
          desc={desc}
          htmlFor={name}
          errorMessage={errorMessage}
          required={required}
          disabled={disabled}
          fillWidth={fillWidth}
          position={position}
          endSection={endSection}
          disableLabelInteraction={disableLabelInteraction}
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          ref={mergedRef}
          field={
            <MantineSwitch
              {...other}
              name={name}
              id={name}
              ref={mergedRef}
              disabled={disabled}
              onChange={onChange}
              checked={checked}
              classNames={{
                input: switchCVA.input(),
                track: switchCVA.track({ size }),
                thumb: switchCVA.thumb({ size, disabled })
              }}
            />
          }
        />
      )
    }
  )
)

Switch.displayName = 'Switch'
export type { SwitchProps } from './Switch.types'
