import { cva } from 'class-variance-authority'
import type { FC, ElementType } from 'react'

import type { TextProps } from './Text.types'

const textCVA = cva([''], {
  variants: {
    size: {
      inherits: '',
      m: ['text-s leading-5'],
      information: ['text-s leading-5'],
      small: ['text-xs leading-4'],
      tiny: ['text-xxs leading-4']
    }
  }
})

export const Text: FC<TextProps> = ({
  children,
  className,
  onClick,
  as,
  size = 'inherits',
  ...other
}) => {
  const Component = (as || 'p') as ElementType

  return (
    <Component
      className={textCVA({ size, className })}
      onClick={onClick}
      {...other}
    >
      {children}
    </Component>
  )
}

export type { TextProps } from './Text.types'
