export const CheckIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.408 0.589444C18.246 0.624964 18.0952 0.703684 17.9927 0.806164C17.9467 0.852244 15.3071 4.60694 12.127 9.14994C8.14926 14.8323 6.31274 17.44 6.24182 17.5063C6.09368 17.6448 5.95418 17.7031 5.7424 17.7148C5.60064 17.7227 5.54718 17.7166 5.44166 17.6808C5.2997 17.6326 5.1824 17.5585 5.08754 17.4568C5.05318 17.42 4.36228 16.5055 3.55222 15.4246C2.74214 14.3437 2.04462 13.4246 2.00214 13.3821C1.77162 13.1516 1.35596 13.0891 1.05 13.2389C0.645063 13.4372 0.460563 13.9418 0.645383 14.3455C0.716363 14.5005 3.73204 18.5143 3.90772 18.6876C4.1111 18.8881 4.3277 19.0415 4.58476 19.1669C4.95876 19.3494 5.27414 19.4227 5.68938 19.4238C6.48928 19.4259 7.22244 19.0643 7.70488 18.4299C7.96802 18.0839 19.3134 1.85378 19.3548 1.76414C19.4487 1.56086 19.4472 1.2767 19.3512 1.06694C19.1878 0.710104 18.7859 0.506604 18.408 0.589444Z'
        fill='currentColor'
      />
    </svg>
  )
}
