import { cva } from 'class-variance-authority'

export const breadcrumbCVA = {
  root: cva(['flex flex-row flex-wrap items-center gap-2']),
  item: cva(['text-xs'], {
    variants: {
      isLast: {
        true: 'text-neutral-800'
      }
    }
  })
}
