import {
  IconWrap,
  AlarmBellIcon,
  Indicator,
  DropdownMenu,
  BaseButton,
  Tooltip
} from '@libs/ui/ds'
import { useAppNavBarTranslation } from '@libs/utils/translations'
import { useMemoSelector } from '@spa/redux/hooks'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import { getNotifications } from 'snap-redux/modules/notifications/selectors'

import { NotificationsDropdownMenuContent } from './NotificationsDropdownMenuContent'

export const NotificationsDropdownMenu: FC = () => {
  const notifications = useMemoSelector(getNotifications)

  const unreadTimeoffsCount = Number(
    Object.values(notifications.get('unread_timeoffs').toJS()).reduce(
      (sum: number, count: number) => sum + count,
      0
    )
  )

  const notificationsCount = useMemo(
    () => notifications.get('unread_conversations') + unreadTimeoffsCount,
    [notifications, unreadTimeoffsCount]
  )
  const { t } = useAppNavBarTranslation()

  return (
    <DropdownMenu dropdownPosition='bottom-end' dropdownWidth='l'>
      <DropdownMenu.Target>
        <BaseButton
          data-testid='navbar-notification'
          className='cursor-pointer overflow-visible rounded-full bg-transparent px-2 py-1 text-neutral-75 hover:bg-neutral-700/60 active:bg-neutral-700'
        >
          <Tooltip label={t('notifications.title')}>
            <Indicator
              color='semantic.red'
              label={notificationsCount?.toString()}
              position='top-end'
              size='xs'
              hide={notificationsCount <= 0}
            >
              <IconWrap className='mt-1' icon={<AlarmBellIcon />} size='m' />
            </Indicator>
          </Tooltip>
        </BaseButton>
      </DropdownMenu.Target>
      <NotificationsDropdownMenuContent
        notifications={notifications}
        count={notificationsCount}
      />
    </DropdownMenu>
  )
}
