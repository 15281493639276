import type { AccountStatus } from '@libs/data-access/entities'
import { httpServices } from '@libs/data-access/queries'
import { APP_NAMESPACE } from '@libs/utils/environments'

import type { QueryFnParams } from '../../../types'

import { fallbackAccountStatusData } from './__mocks__/accountStatus.mock'

export type FetchAccountStatusOptions = QueryFnParams<{
  pathParams: {
    id: string
  }
}>

/**
 * Fetch account status
 */
export const fetchAccountStatus = async () => {
  try {
    const response = await httpServices.billing.get<AccountStatus>(
      `/accounts/${httpServices.authInfo.accountId}/status`,
      { withCredentials: true }
    )
    return response.data
  } catch (error) {
    if (APP_NAMESPACE !== 'production') {
      return fallbackAccountStatusData
    }

    throw error
  }
}
