import { omit, pick } from 'lodash-es'
import queryString from 'query-string'
import { useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import type {
  GetRouteTypes,
  AppRoutesName,
  AppRoutesConfig
} from '../appRoutesConfig'
import { routes } from '../appRoutesConfig'
import type { PathParams, SearchParams } from '../appRoutesConfig/utils.types'
import { formatPathParams, formatSearchParams } from '../services'

/**
 * @deprecated use react-router's useHistory instead.
 * @see https://reactrouter.com/en/main/hooks/use-location
 * @see https://reactrouter.com/en/main/hooks/use-navigate
 * @see https://reactrouter.com/en/main/hooks/use-search-params
 * @example
 * import { useLocation, useNavigate, useParams } from 'react-router-dom'
 *
 * const location = useLocation()
 * const navigate = useNavigate()
 * const [searchParams, setSearchParams] = useSearchParams()
 */
export const useHistoryWrapper = <T extends AppRoutesName>() => {
  const location = useLocation()
  const navigate = useNavigate()

  const currentPathParams = useParams<GetRouteTypes<T>['pathParams']>()

  /**
   * Only update if the location change
   */
  return useMemo(() => {
    const currentSearchParams: GetRouteTypes<T>['searchParams'] =
      queryString.parse(location.search, {
        parseNumbers: true,
        parseBooleans: true
      })

    /**
     * Navigate by updating the queryParams
     */
    const pushRoute = (
      payload: AppRoutesConfig & {
        hash?: string
        keepParams?: boolean
        replace?: boolean
      }
    ) => {
      const { hash, searchParams, pathParams, route, keepParams, replace } =
        payload
      const routeConfig = routes[route]

      const pathname = formatPathParams({
        path: routeConfig.path,
        pathParams: pathParams as PathParams
      })
      const search = formatSearchParams({
        searchParams: searchParams as SearchParams,
        currentSearchParams: keepParams ? currentSearchParams : {}
      })

      navigate({ pathname, search, hash }, { replace })
    }

    const getNextSearchParams = (
      currentSearchParams,
      keepParams: boolean | (keyof GetRouteTypes<T>['searchParams'])[]
    ) => {
      if (keepParams) {
        if (typeof keepParams === 'boolean') {
          return currentSearchParams
        }
        if (keepParams?.length) {
          return pick(currentSearchParams, keepParams)
        }
      }
    }

    /**
     * Navigate by only updating the searchParams
     */
    const updateSearchParams = (payload: {
      searchParams: GetRouteTypes<T>['searchParams']
      hash?: string
      keepParams?:
        | boolean
        | (keyof Exclude<GetRouteTypes<T>['searchParams'], undefined>)[]
      replace?: boolean
    }) => {
      const { keepParams, hash, searchParams, replace } = payload
      const search = formatSearchParams({
        searchParams: searchParams as SearchParams,
        currentSearchParams: getNextSearchParams(
          currentSearchParams,
          keepParams as boolean | (keyof GetRouteTypes<T>['searchParams'])[]
        )
      })

      navigate({ pathname: location.pathname, search, hash }, { replace })
    }

    /**
     * Reset current searchParams
     * You can provide an array of specific searchParams to reset through [searchParamsKeys]
     */
    const resetSearchParams = (
      payload: {
        replace?: boolean
        searchParamsKeys?: (keyof GetRouteTypes<T>['searchParams'])[]
      } = {}
    ) => {
      const { searchParamsKeys, replace } = payload
      const search = searchParamsKeys?.length
        ? formatSearchParams({
            // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
            searchParams: omit(currentSearchParams, payload.searchParamsKeys)
          })
        : ''

      navigate({ pathname: location.pathname, search }, { replace })
    }

    return {
      location,
      navigate,
      searchParams: currentSearchParams,
      pathParams: currentPathParams,
      pushRoute,
      resetSearchParams,
      updateSearchParams
    }
  }, [location, JSON.stringify(currentPathParams)])
}
