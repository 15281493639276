import { useDebouncedValue, useUncontrolled } from '@libs/ui/ds'
import { useEffect } from 'react'

import type { SelectOption } from '../../../base/Select.types'
import type { AsyncSelectProps } from '../AsyncSelect.types'

type Params = {
  options: AsyncSelectProps['options']
  select: {
    value: AsyncSelectProps['value']
    defaultValue: AsyncSelectProps['defaultValue']
    onChange: AsyncSelectProps['onChange']
  }
  asyncSearch: {
    searchValue: AsyncSelectProps['searchValue']
    onSearchChange: AsyncSelectProps['onSearchChange']
    onDebouncedSearchChange: AsyncSelectProps['onDebouncedSearchChange']
    debouncedSearchDelay: AsyncSelectProps['debouncedSearchDelay']
  }
}

export const useAsyncSelectValuesUncontrolled = ({
  select,
  asyncSearch,
  options = []
}: Params) => {
  const [selectValue, setSelectValue] = useUncontrolled<string>(select)
  const [selectedOption, setSelectedOption] = useUncontrolled<SelectOption>({})

  const [searchValue, setSearchValue] = useUncontrolled<string>({
    defaultValue: select?.defaultValue || '',
    onChange: asyncSearch?.onSearchChange,
    value: asyncSearch?.searchValue
  })

  const [debouncedValue] = useDebouncedValue(
    searchValue,
    asyncSearch.debouncedSearchDelay
  )

  const resetSelectValue = () => {
    setSelectedOption(null as any)
    setSearchValue('')
    setSelectValue(null as any)
  }

  useEffect(() => {
    if (selectValue) {
      const newOption = options.find(i => i.value === selectValue)
      if (newOption) {
        setSelectedOption(newOption)
        setSearchValue(newOption.label)
      }
    }
    if (selectValue === null) {
      resetSelectValue()
    }
  }, [selectValue])

  useEffect(() => {
    if (asyncSearch.onDebouncedSearchChange) {
      asyncSearch.onDebouncedSearchChange(debouncedValue)
    }
  }, [debouncedValue])

  return {
    search: {
      searchValue,
      setSearchValue,
      resetSearchValue: () => {
        setSearchValue('')
      }
    },
    select: {
      selectValue,
      setSelectValue,
      resetSelectValue
    },
    selectedOption
  }
}
