import { reduce } from 'lodash-es'

import type { PathParams } from '../appRoutesConfig/utils.types'

/**
 * Replace all matching url params
 * From:   /accounts/:id/users/:name
 * To:     /accounts/123/users/billy
 */
export const formatPathParams = ({
  path,
  pathParams
}: {
  path: string
  pathParams: PathParams | {}
}): string => {
  return reduce(
    pathParams,
    (acc, value, key) => {
      const regex = new RegExp(`:${key}`, 'gi')
      return acc.replace(regex, String(value))
    },
    path
  )
}
