import type { MomentInput } from 'moment'
import moment from 'moment'

export const isFirstDayOfWeek = (date: MomentInput) => {
  return moment(date).day() === 1
}

export const isLastDayOfWeek = (date: MomentInput) => {
  return moment(date).day() === 0
}

export const addOneDay = (date: MomentInput) => {
  return moment(date).add(1, 'day').toISOString()
}

export const removeOneDay = (date: MomentInput) => {
  return moment(date).add(-1, 'day').toISOString()
}
