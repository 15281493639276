import type { SegmentedControlItem } from '@mantine/core'
import { SegmentedControl as MantineSegmentedControl } from '@mantine/core'
import { cva } from 'class-variance-authority'
import { forwardRef, memo } from 'react'

import type { SegmentedControlProps } from './SegmentedControl.types'

const segmentedControlCVA = {
  root: cva(['gap-2 rounded-full bg-neutral-200 p-1']),
  control: cva([
    'box-border !p-0 before:content-none',
    '[&[data-active]>label]:!bg-neutral-50 [&[data-active]>label]:!text-neutral-800'
  ]),
  label: cva(
    [
      'flex items-center justify-center rounded-full text-neutral-700',
      'hover:bg-neutral-300 active:bg-neutral-400/50',
      '[&[data-disabled]]:!text-neutral-400'
    ],
    {
      variants: {
        size: {
          s: 'h-6 px-4 text-xs',
          m: 'h-8 px-6 text-s'
        }
      }
    }
  ),
  indicator: cva(['bg-transparent opacity-0'])
}

export const SegmentedControl = memo(
  forwardRef<HTMLDivElement, SegmentedControlProps>(
    (
      {
        disabled,
        fillWidth,
        name,
        data,
        className,
        onChange,
        size = 'm',
        ...other
      },
      ref
    ) => {
      return (
        <MantineSegmentedControl
          {...other}
          name={name}
          ref={ref}
          fullWidth={fillWidth}
          data={data as SegmentedControlItem[]}
          disabled={disabled}
          onChange={onChange}
          transitionDuration={200}
          classNames={{
            root: segmentedControlCVA.root({ className }),
            label: segmentedControlCVA.label({ size }),
            control: segmentedControlCVA.control(),
            indicator: segmentedControlCVA.indicator()
          }}
        />
      )
    }
  )
)

SegmentedControl.displayName = 'SegmentedControl'
export type { SegmentedControlProps } from './SegmentedControl.types'
