import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_HOLIDAYS,
  FETCH_HOLIDAYS_SUCCEEDED,
  FETCH_HOLIDAYS_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchHolidays = params => ({ type: FETCH_HOLIDAYS, params })

// ------------------------------------
// Sagas
// ------------------------------------

const holidaysSagaEntity = {
  success: data => ({
    type: FETCH_HOLIDAYS_SUCCEEDED,
    data
  }),
  failure: error => ({
    type: FETCH_HOLIDAYS_FAILED,
    error
  }),
  fetchAPI: (id, options, params) =>
    callAPI(`/holidays/?${queryString.stringify(params)}`, options)
}

export function* doFetchHolidays({ params }) {
  // @ts-ignore migration from js(x) to ts(x)
  yield call(fetchSagaEntity, holidaysSagaEntity, null, params)
}

// ------------------------------------
// Models
// ------------------------------------

export const Holiday = Immutable.Record({
  date: null,
  name: null
})

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false })
})

function holidays(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_HOLIDAYS: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_HOLIDAYS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_HOLIDAYS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const holidays = Immutable.fromJS(action.data)
      return mergeRecords(
        state,
        Holiday,
        holidays,
        undefined,
        'date'
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    default:
      return state
  }
}

export default filterActions(holidays, [
  FETCH_HOLIDAYS,
  FETCH_HOLIDAYS_FAILED,
  FETCH_HOLIDAYS_SUCCEEDED
])
