import type { ReduxState } from '@spa/redux/ReduxState'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'

import { getWeekDateParams } from '../../../params'
import { weeklyschedulesSelectors } from '../../weeklyschedules'
import { getFreshShiftsList } from '../getFreshShiftsList/getFreshShiftsList'

export const getShiftsByTeam = createCachedSelector(
  getWeekDateParams,
  getFreshShiftsList,
  weeklyschedulesSelectors.getCachedList,
  (_: ReduxState, teamId: number) => teamId,

  ({ week }, shifts, weeklyschedules, teamId) => {
    const filterByWeek = weeklyschedules.filter(i => i.week === week.iso)
    const filterByTeam = teamId
      ? filterByWeek.filter(i => i.team_id === teamId)
      : filterByWeek

    const weeklyscheduleIds = filterByTeam.map(i => i.id)

    return shifts.filter(shift =>
      weeklyscheduleIds.includes(shift.weeklyschedule_id)
    )
  }
)((_, teamId) => fallbackToDefaultCacheKey(teamId))
