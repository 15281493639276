import type { ApiErrorResponse } from '@libs/data-access/queries'
import { notifications } from '@libs/ui/ds'
import i18n from '@libs/utils/translations'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import {
  deleteDocumentEmployee,
  deleteSignatureFromDocumentEmployee
} from '../../DocumentEmployee/modules/entities'
import type { ParamsDeleteDocumentSignature } from '../modules/entities'
import { deleteDocumentSignature } from '../modules/entities'
import { setMetaLoading, setMetaSuccess, setMetaError } from '../modules/meta'

export default function* deleteDocumentSignatureData(
  params: ParamsDeleteDocumentSignature
) {
  yield put(setMetaLoading())
  const toastId = notifications.loading({
    message: i18n.t<any>('common.notifications:documentSign.delete.loading')
  })

  try {
    yield apiRequest({
      service: 'core',
      route: 'deleteDocumentSignature',
      params: { signable_document_id: params.signable_document_id },
      queryParams: { delete_attachment: params.delete_attachment }
    })

    yield put(deleteDocumentSignature({ id: params.signable_document_id }))
    if (params.attachment_id) {
      yield put(
        deleteSignatureFromDocumentEmployee({ id: params?.attachment_id })
      )
    }
    if (params.delete_attachment) {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      yield put(deleteDocumentEmployee({ id: params.attachment_id }))
    }

    yield put(setMetaSuccess())
    notifications.success({
      id: toastId,
      message: i18n.t<any>('common.notifications:documentSign.delete.success')
    })
  } catch (err) {
    yield put(setMetaError(err as ApiErrorResponse))

    notifications.errorAdapter({
      ...(err as ApiErrorResponse),
      id: toastId
    })
  }
}
