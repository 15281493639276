export const PLANNING_INIT = 'ui/PLANNING_INIT'
export const PLANNING_INIT_SUCCESS = 'ui/PLANNING_INIT_SUCCESS'
export const PLANNING_INIT_ERROR = 'ui/PLANNING_INIT_ERROR'

export const PLANNING_REFRESH = 'ui/PLANNING_REFRESH'
export const PLANNING_REFRESH_SUCCESS = 'ui/PLANNING_REFRESH_SUCCESS'
export const PLANNING_REFRESH_ERROR = 'ui/PLANNING_REFRESH_ERROR'

export const PLANNING_RESET = 'ui/PLANNING_RESET'
export const PLANNING_CANCEL = 'ui/PLANNING_CANCEL'
