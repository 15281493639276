import type { PayloadAction } from '@reduxjs/toolkit'
import { SagaType, createSliceSaga } from 'redux-toolkit-saga'

import fetchMissingInformationData from '../generator/fetchMissingInformation'
import fetchMissingInformationByMembershipIdData from '../generator/fetchMissingInformationByMembershipId'
import fetchPaginatedMissingInformationData from '../generator/fetchPaginatedMissingInformation'
import getSsrRegisteryFileData from '../generator/getSsrRegisteryFile'

import type {
  ParamsDownload,
  ParamsDefaultFetch,
  ParamsPaginatedFetch,
  ParamsFetchByMembership
} from './entities'

const missingInformationSagaSlice = createSliceSaga({
  name: 'emp/missingInformation/sagas',
  caseSagas: {
    /**
     * Fetch missing information of RUP & DPAE
     */
    *fetchMissingInformation({ payload }: PayloadAction<ParamsDefaultFetch>) {
      yield fetchMissingInformationData(payload)
    },
    /**
     * Fetch missing information of RUP & DPAE in pagniated mode
     */
    *fetchPaginatedMissingInformation({
      payload
    }: PayloadAction<ParamsPaginatedFetch>) {
      yield fetchPaginatedMissingInformationData(payload)
    },
    /**
     * Fetch missing information of RUP & DPAE for a specific membershipId
     */
    *fetchMissingInformationByMembershipId({
      payload
    }: PayloadAction<ParamsFetchByMembership>) {
      yield fetchMissingInformationByMembershipIdData(payload)
    },
    /**
     * Fetch SSR registry file to download
     */
    *fetchSsrRegistryFile({ payload }: PayloadAction<ParamsDownload>) {
      yield getSsrRegisteryFileData(payload)
    }
  },
  sagaType: SagaType.TakeLatest
})

export const {
  fetchMissingInformation,
  fetchMissingInformationByMembershipId,
  fetchPaginatedMissingInformation,
  fetchSsrRegistryFile
} = missingInformationSagaSlice.actions

export default missingInformationSagaSlice.saga()
