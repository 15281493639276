import { useUncontrolled } from '@mantine/hooks'
import { useEffect, type ReactNode } from 'react'

type Props = {
  errorMessage: ReactNode
}

export const useDropzoneErrorMessageUncontrolled = ({
  errorMessage
}: Props) => {
  const [internalErrorMessage, setInternalErrorMessage] =
    useUncontrolled<ReactNode>({})

  useEffect(() => {
    if (errorMessage) {
      setInternalErrorMessage(errorMessage)
    }
  }, [errorMessage])

  return { internalErrorMessage, setInternalErrorMessage }
}
