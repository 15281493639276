import type { ChangeEvent } from 'react'
import { useState } from 'react'

type UseUncontrolledInput<T = any, E = HTMLInputElement> = {
  /** Value for controlled state */
  value?: T

  /** Initial value for uncontrolled state */
  defaultValue?: T

  /** Final value for uncontrolled state when value and defaultValue are not provided */
  finalValue?: T

  /** Controlled state onChange handler */
  onChange?: (value: ChangeEvent<E>) => void
}

export function useUncontrolledInput<
  T = any,
  E extends Element = HTMLInputElement
>({
  value,
  defaultValue,
  finalValue,
  onChange = () => {}
}: UseUncontrolledInput<T, E>) {
  const [uncontrolledValue, setUncontrolledValue] = useState(
    defaultValue !== undefined ? defaultValue : finalValue
  )

  const handleUncontrolledChange = (e: ChangeEvent<E>) => {
    setUncontrolledValue(e as any)
    onChange?.(e)
  }

  if (value !== undefined) {
    return [value, onChange, true] as const
  }

  const cleanUncontrolledValue =
    typeof uncontrolledValue === 'string'
      ? uncontrolledValue
      : (uncontrolledValue as any)?.target?.value

  return [cleanUncontrolledValue as T, handleUncontrolledChange, false] as const
}
