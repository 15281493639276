import type { AccountRecord } from '@libs/data-access/entities'
import { userService } from '@libs/data-access/queries'
import {
  Text,
  IconWrap,
  BookOpenIcon,
  MessagesBubbleSquareTypingIcon,
  Flex,
  MultipleNeutralIcon,
  CalendarIcon,
  HouseIcon,
  AnalyticsBoardBarsIcon,
  LayersIcon,
  Image,
  imagesUrls
} from '@libs/ui/ds'
import { useAppNavBarTranslation } from '@libs/utils/translations'
import { Link } from '@spa/components'
import { routes, useHistoryWrapper } from '@spa/core/router'
import useHRDashboardPermissions from '@spa/scenes/DashboardRH/hooks/useHRDashboardPermissions'
import classnames from 'classnames'
import React, { useMemo } from 'react'
import type { FC } from 'react'
import { NavLink } from 'react-router-dom'

import styles from './AppNavBar.module.css'
import { UserMenuDropdown, NotificationsDropdownMenu } from './components'
import { ComboPayBadge } from './components/ComboPayBadge/ComboPayBadge'

type Props = {
  account: AccountRecord
}
export const AppNavBar: FC<Props> = ({ account }) => {
  const { t } = useAppNavBarTranslation()
  const { location } = useHistoryWrapper()
  const { enabled_conversation: enabledConversation } = account
  const {
    canSeeHRDashboardPage,
    canOnlySeePaidLeaveCounters,
    canSeeToucanGraphs
  } = useHRDashboardPermissions()

  const permissions = userService.membership.useFeaturePermissions()

  const HRDashboardLink = useMemo(() => {
    if (canOnlySeePaidLeaveCounters) {
      return routes.dashboardRhPaidLeaves.rootPath
    }

    if (canSeeToucanGraphs) {
      return routes.dashboardRhHome.rootPath
    }

    return routes.dashboardRhHome.rootPath
  }, [canOnlySeePaidLeaveCounters, canSeeToucanGraphs])

  return (
    <Flex
      row
      className='relative z-10 max-w-full flex-nowrap justify-between gap-80 overflow-x-auto overflow-y-hidden bg-neutral-800 pr-2'
    >
      <Flex row className='grow flex-nowrap justify-start'>
        <Link
          to='/'
          data-testid='logo-combo'
          className={classnames(
            styles.CompanyLink,
            location.pathname === '/' && styles.activeLink
          )}
        >
          <Image
            src={imagesUrls.logo.logoComboWhite}
            alt='combo-logo'
            size='s'
          />
        </Link>
        <NavLink
          to={routes.home.rootPath}
          data-testid='navbar-home'
          className={({ isActive }) =>
            classnames(styles.AppNavBarLink, {
              [styles.activeLink]: isActive,
              [styles.disableClick]: isActive
            })
          }
        >
          <Flex row className='justify-center gap-3'>
            <IconWrap icon={<HouseIcon />} size='m' />
            <Text as='span' size='m' className={styles.AppNavBarTextLink}>
              {t('mainMenu.home')}
            </Text>
          </Flex>
        </NavLink>
        <NavLink
          to={routes.planningWeekly.rootPath}
          data-testid='navbar-plannings'
          className={({ isActive }) =>
            classnames(styles.AppNavBarLink, {
              [styles.activeLink]: isActive,
              [styles.disableClick]: isActive
            })
          }
        >
          <Flex row className='justify-center gap-3'>
            <IconWrap icon={<CalendarIcon />} size='m' />
            <Text as='span' size='m' className={styles.AppNavBarTextLink}>
              {t('mainMenu.plannings')}
            </Text>
          </Flex>
        </NavLink>
        <NavLink
          to='/members'
          data-testid='navbar-members'
          className={({ isActive }) =>
            classnames(styles.AppNavBarLink, {
              [styles.activeLink]: isActive
            })
          }
        >
          <Flex row className='justify-center gap-3'>
            <IconWrap icon={<MultipleNeutralIcon />} size='m' />
            <Text as='span' size='m' className={styles.AppNavBarTextLink}>
              {t('mainMenu.team')}
            </Text>
          </Flex>
        </NavLink>
        {permissions?.payroll.can_access && (
          <NavLink
            to={routes.reportsPayrollsList.rootPath}
            data-testid='navbar-reports'
            className={classnames(
              styles.AppNavBarLink,
              location.pathname.startsWith('/reports') && styles.activeLink
            )}
          >
            <Flex row className='justify-center gap-3'>
              <IconWrap icon={<LayersIcon />} size='m' />
              <Text as='span' size='m' className={styles.AppNavBarTextLink}>
                {t('mainMenu.reports')}
              </Text>
            </Flex>
          </NavLink>
        )}
        {canSeeHRDashboardPage && (
          <NavLink
            to={HRDashboardLink}
            data-testid='navbar-dashboard-rh'
            className={classnames(
              styles.AppNavBarLink,
              location.pathname.startsWith('/dashboard-rh/') &&
                styles.activeLink
            )}
          >
            <Flex row className='justify-center gap-3'>
              <IconWrap icon={<AnalyticsBoardBarsIcon />} size='m' />
              <Text as='span' size='m' className={styles.AppNavBarTextLink}>
                {t('mainMenu.hr')}
              </Text>
            </Flex>
          </NavLink>
        )}
        <NavLink
          to='/articles'
          data-testid='navbar-articles'
          className={({ isActive }) =>
            classnames(styles.AppNavBarLink, {
              [styles.activeLink]: isActive
            })
          }
        >
          <Flex row className='justify-center gap-3'>
            <IconWrap icon={<BookOpenIcon />} size='m' />
            <Text as='span' size='m' className={styles.AppNavBarTextLink}>
              {t('mainMenu.articles')}
            </Text>
          </Flex>
        </NavLink>
        {enabledConversation && (
          <NavLink
            to='/conversations'
            data-testid='navbar-conversations'
            className={classnames(
              styles.AppNavBarLink,
              location.pathname.startsWith('/conversations') &&
                styles.activeLink
            )}
          >
            <Flex row className='justify-center gap-3'>
              <IconWrap icon={<MessagesBubbleSquareTypingIcon />} size='m' />
              <Text as='span' size='m' className={styles.AppNavBarTextLink}>
                {t('mainMenu.messages')}
              </Text>
            </Flex>
          </NavLink>
        )}
      </Flex>

      <Flex row className='grow flex-nowrap items-center justify-end'>
        <ComboPayBadge account={account} />
        <NotificationsDropdownMenu />
        <UserMenuDropdown />
      </Flex>
    </Flex>
  )
}
