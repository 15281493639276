import { createContext, useContext, useEffect } from 'react'

import type {
  AuthNavbarContextType,
  AuthNavbarState
} from './AuthNavbarContext.types'

export const AuthNavbarContext = createContext<
  AuthNavbarContextType | undefined
>(undefined)

export const useConfigurationAuthNavbar = (newState: AuthNavbarState) => {
  const authNavbarContext = useContext(AuthNavbarContext)

  useEffect(() => {
    authNavbarContext?.updateNavbar(newState)
  }, [])
}
