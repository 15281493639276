import type {
  WeeklyScheduleRecord,
  WeeklyScheduleRecordMap
} from '@libs/data-access/entities'
import Immutable from 'immutable'
import moment from 'moment'

import {
  getDataByForeignId,
  getDataByForeignIds
} from '../../services/immutableUtils'

export const getWeeklyScheduleById = (state, wksId): WeeklyScheduleRecord => {
  return state.weeklyschedules.getIn(['data', wksId])
}

export const getWeeklyschedulesByTeamId = (
  state,
  teamId
): WeeklyScheduleRecordMap =>
  getDataByForeignId(state, 'weeklyschedules', 'team_id', teamId)

export const getWeeklyschedulesByTeamIds = (state, teamIds) =>
  getDataByForeignIds(state, 'weeklyschedules', 'team_id', teamIds)

export const getWeeklyschedulesByWeek = (state, week) =>
  getDataByForeignId(state, 'weeklyschedules', 'week', week)

export const getWeeklyschedulesByWeeks = (state, weeks) =>
  getDataByForeignIds(state, 'weeklyschedules', 'week', weeks)

export const getWeeklyschedulesByWeekAndTeamIds = (
  state,
  { teamIds, week }: { teamIds: number[]; week: string }
) => {
  return getWeeklyschedulesByTeamIds(state, teamIds).filter(i => {
    return i.week === moment(week).format('YYYY-[W]WW')
  })
}

export const getAllWeeklyschedules = state => {
  return state.weeklyschedules.get('data')
}

export const getIsAllWeeklyschedulesLoading = state => {
  return state.weeklyschedules.getIn(['meta', 'loading'])
}

export const getAllUniqueWeeklyschedules = (state): WeeklyScheduleRecordMap => {
  const ws = state.weeklyschedules.get('data')

  const uniqueWs = ws.reduce((uniqueWeeklyschedules, newWs) => {
    // If I have already put in my array a ws whiche have the same team_id as the newWs I'm reducing
    // I'm checking if the date of the newWs is smaller than the one in the array
    // If it is, I don't need to do anything
    if (
      uniqueWeeklyschedules.find(
        alreadyInArrayWs =>
          alreadyInArrayWs.team_id === newWs.team_id &&
          alreadyInArrayWs.week === newWs.week &&
          moment(newWs.created_at).diff(moment(alreadyInArrayWs.created_at)) < 0
      )
    ) {
      return uniqueWeeklyschedules
    }

    uniqueWeeklyschedules.forEach((alreadyInArrayWs, index) => {
      // If I have already put in my array a ws whiche have the same team_id as the newWs I'm reducing
      // I'm checking if the date of the newWs is greater than the one in the array
      // If it is, I need to remove the ws from the array to replace it by the one I'm reducing
      if (
        alreadyInArrayWs.team_id === newWs.team_id &&
        alreadyInArrayWs.week === newWs.week &&
        moment(newWs.created_at).diff(moment(alreadyInArrayWs.created_at)) > 0
      ) {
        uniqueWeeklyschedules.splice(index, 1)
      }
    })
    uniqueWeeklyschedules.push(newWs)
    return uniqueWeeklyschedules
  }, [])

  return Immutable.fromJS(uniqueWs)
}
