import type { BaseComponent } from '@libs/ui/ds/lib/internals'
import type { FC, HTMLAttributes } from 'react'

type StepperStepProps = BaseComponent &
  HTMLAttributes<HTMLDivElement> & {
    disabled?: boolean
    stepIndex?: number
    active?: number
  }

export const StepperStep: FC<StepperStepProps> = ({
  className,
  children,
  active,
  stepIndex,
  ...other
}) => {
  if (stepIndex !== active) {
    return null
  }

  return (
    <div className={className} {...other}>
      {children}
    </div>
  )
}

export type { StepperStepProps }
