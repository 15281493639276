import { PopoverProvider } from '@libs/ui/ds/lib/components/overlays/Popover/base/Popover.context'
import { Popover as MantinePopover } from '@mantine/core'
import { useRef } from 'react'

export const PopoverWithHoverableDropdown = ({
  uncontrolledValue,
  setUncontrolledValue,
  dropdownHeight,
  dropdownWidth,
  children,
  ...other
}) => {
  const timer = useRef(null)

  return (
    <PopoverProvider
      value={{
        opened: uncontrolledValue,
        onChange: () => undefined,
        trigger: 'hover',
        dropdownHeight,
        dropdownWidth
      }}
    >
      <MantinePopover
        {...other}
        onChange={() => undefined}
        opened={uncontrolledValue}
        withinPortal
      >
        <div
          onMouseEnter={() => {
            // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
            clearTimeout(timer.current)
            setUncontrolledValue(true)
          }}
          onMouseLeave={() => {
            // We need to set a delay before closing the popover so it does not blink when we move the mouse between the target and the dropdown
            // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
            timer.current = setTimeout(() => {
              setUncontrolledValue(false)
            }, 100)
          }}
        >
          {/* children contains both Popover.Target & Popover.Dropdown */}
          {children}
        </div>
      </MantinePopover>
    </PopoverProvider>
  )
}
