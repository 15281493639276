import moment from 'moment'

import type {
  PickerPreset,
  PickerPresetRangeValue,
  PredefinedPickerPreset,
  PickerPresetShape
} from './PickerPresetList.types'
import { pickerPresets } from './pickerPresets.constants'

const isActive = (preset: PickerPresetShape, value: PickerPresetRangeValue) => {
  return (
    moment(preset.start).isSame(value.start, 'day') &&
    moment(preset.end).isSame(value.end, 'day')
  )
}

const isPast = (preset: PickerPresetShape) => {
  return moment(preset.start).isBefore(moment(), 'day')
}

export const resolvePresets = (
  presets: PickerPreset[],
  value: PickerPresetRangeValue
) => {
  return (presets || []).map(preset => {
    const name = (preset as PredefinedPickerPreset)?.name
    const currentPreset = name
      ? pickerPresets[name]()
      : (preset as PickerPresetShape)

    return {
      ...currentPreset,
      isPast: isPast(currentPreset),
      isActive: isActive(currentPreset, value)
    }
  })
}
