import { fork, put } from 'redux-saga/effects'

import { clearEntity, setEntities } from '../../../reducers/entities'
import type { ParamsState } from '../../../reducers/params'
import { entitiesSagas } from '../../entities'
import filterRequestedContractIds from '../../utils/filterRequestedContractIds'

/**
 * Refresh the whole planning for the given date, location and team.
 *
 * This does not trigger:
 * - the loading state (planning placeholder)
 * - empty the planning (once data fetching succeed we replace every data inside the store with new data)
 *
 * @param params
 */
function* planningSilentRefresh(params: ParamsState) {
  const normalizedAggregate = yield entitiesSagas.fetchPlanningAggregate({
    payload: {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      date: params.date,
      locationId: params.locationId,
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      teamId: params.teamId,
      withPermissions: ['weeklyschedules']
    }
  })

  const allContractIds: number[] =
    yield filterRequestedContractIds(normalizedAggregate)

  /**
   * Normalize deep nested structure in parallel
   */
  yield entitiesSagas.fetchAndHydratePlannables({
    payload: {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      date: params.date,
      contractIds: allContractIds
    }
  })

  yield put(setEntities({ ...normalizedAggregate }))
  yield put(clearEntity({ entity: 'alerts' }))

  /**
   * Fetch counters
   */
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  yield fork(entitiesSagas.fetchAndHydrateContractCounters, {
    payload: {
      date: params.date,
      contractIds: allContractIds
    }
  })

  /**
   * Fetch alerts
   */
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  yield fork(entitiesSagas.getAlertsChunked, {
    payload: {
      date: params.date,
      contractIds: allContractIds
    }
  })
}

export default planningSilentRefresh
