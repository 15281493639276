import Immutable from 'immutable'
import { filterActions } from 'redux-ignore'
import { put } from 'redux-saga/effects'

import {
  CONNECT_SOCKET,
  CONNECT_SOCKET_SUCCEEDED,
  CONNECT_SOCKET_FAILED,
  DISCONNECT_SOCKET,
  SOCKET_OPEN_CONNECTION,
  SUBSCRIBE_TO_CHANNEL,
  SUBSCRIBE_TO_DEFAULT_CHANNEL,
  SUBSCRIBE_TO_CHANNEL_SUCCEEDED,
  UNSUBSCRIBE_FROM_CHANNEL,
  SEND_MESSAGE_TO_CHANNEL
} from '../../actionTypes'

export const SOCKET_STATUS_NOT_CONNECTED = 'socket/STATUS_NOT_CONNECTED'
export const SOCKET_STATUS_CONNECTED = 'socket/STATUS_CONNECTED'
export const SOCKET_STATUS_CONNECTING = 'socket/STATUS_CONNECTING'

// Actions
//

export const connectSocket = () => ({ type: CONNECT_SOCKET })
export const connectSocketSucceeded = () => ({ type: CONNECT_SOCKET_SUCCEEDED })
export const connectSocketFailed = () => ({ type: CONNECT_SOCKET_FAILED })

export const disconnectSocket = () => ({ type: DISCONNECT_SOCKET })

export const openSocketConnection = (ticket, resolve, reject) => ({
  type: SOCKET_OPEN_CONNECTION,
  ticket,
  resolve,
  reject
})

export const subscribeToDefaultChannel = channel => ({
  type: SUBSCRIBE_TO_DEFAULT_CHANNEL,
  channel
})
export const subscribeToChannel = (channel, params = {}) => ({
  type: SUBSCRIBE_TO_CHANNEL,
  channel,
  ...params
})
export const unsubscribeFromChannel = (channel, params = {}) => ({
  type: UNSUBSCRIBE_FROM_CHANNEL,
  channel,
  ...params
})
export const sendMessageToChannel = (
  channel,
  action,
  data,
  resolve,
  reject
) => ({
  type: SEND_MESSAGE_TO_CHANNEL,
  channel,
  action,
  data,
  resolve,
  reject
})

export function* doSubscribeToDefaultChannel({ channel }) {
  yield put(subscribeToDefaultChannel(channel))
}

export function* doSubscribeToChannel({ channel }) {
  yield put(subscribeToChannel(channel))
}

// Reducers
//
const initialState = Immutable.Map({
  connectedChannels: Immutable.Set(),
  meta: Immutable.Map({ status: SOCKET_STATUS_NOT_CONNECTED, updated_at: null })
})

function socket(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case CONNECT_SOCKET:
      return state.mergeDeepIn(['meta'], {
        // @ts-ignore migration from js(x) to ts(x)
        status: SOCKET_STATUS_CONNECTING,
        updated_at: new Date()
      })
    case CONNECT_SOCKET_SUCCEEDED:
      return state.mergeDeepIn(['meta'], {
        // @ts-ignore migration from js(x) to ts(x)
        status: SOCKET_STATUS_CONNECTED,
        updated_at: new Date()
      })
    case CONNECT_SOCKET_FAILED:
    case DISCONNECT_SOCKET:
      return state.mergeDeepIn(['meta'], {
        // @ts-ignore migration from js(x) to ts(x)
        status: SOCKET_STATUS_NOT_CONNECTED,
        updated_at: new Date()
      })
    case SUBSCRIBE_TO_CHANNEL_SUCCEEDED:
      return (
        state
          .merge({
            connectedChannels: state
              .get('connectedChannels')
              // @ts-ignore migration from js(x) to ts(x)
              .add(action.channel)
          })
          // @ts-ignore migration from js(x) to ts(x)
          .mergeDeepIn(['meta'], { updated_at: new Date() })
      )
    case UNSUBSCRIBE_FROM_CHANNEL:
      return (
        state
          .merge({
            connectedChannels: state
              .get('connectedChannels')
              // @ts-ignore migration from js(x) to ts(x)
              .remove(action.channel)
          })
          // @ts-ignore migration from js(x) to ts(x)
          .mergeDeepIn(['meta'], { updated_at: new Date() })
      )
    default:
      return state
  }
}

export default filterActions(socket, [
  CONNECT_SOCKET,
  CONNECT_SOCKET_SUCCEEDED,
  CONNECT_SOCKET_FAILED,
  DISCONNECT_SOCKET,
  SUBSCRIBE_TO_CHANNEL_SUCCEEDED,
  UNSUBSCRIBE_FROM_CHANNEL
])
