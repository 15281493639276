import type { Rest, ShiftRecordMap } from '@libs/data-access/entities'
import { call } from 'redux-saga/effects'
import { call as callAPI } from 'snap-redux/infra/http/api'

import createOrUpdateRest from './createOrUpdateRest'

export type CreateRestPayload = {
  onFormSuccess: () => void
  rests: Partial<Rest & { delete_shifts?: boolean }>[]
  overlappingShiftsIds?: string[]
  shifts: ShiftRecordMap
}

/**
 *
 */
const createRestCallAPI = ({ body }: { body: Partial<Rest> }) =>
  callAPI(`/rests`, {
    method: 'POST',
    body: JSON.stringify(body)
  }).catch(reason => ({
    status: 'err',
    userContractId: body.user_contract_id,
    reason
  }))

/**
 *
 */
function* createRestsSaga(payload: CreateRestPayload) {
  const promises = payload.rests.map(rest =>
    call(createRestCallAPI, { body: rest })
  )

  yield createOrUpdateRest({
    shifts: payload.shifts,
    overlappingShiftsIds: payload.overlappingShiftsIds,
    onFormSuccess: payload.onFormSuccess,
    promises
  })
}

export default createRestsSaga
