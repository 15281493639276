import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import { memo } from 'react'

import { useTabsContext } from '../../Tabs.context'

import type { TabsPanelProps } from './TabsPanel.types'

const tabsPanelCva = {
  root: cva(['flex w-full flex-wrap'])
}

export const TabsPanel: FC<TabsPanelProps> = memo(
  ({ value, className, children, ...other }) => {
    const context = useTabsContext()
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    if (context.value !== value) {
      return null
    }

    return (
      <div className={tabsPanelCva.root({ className })} {...other}>
        {children}
      </div>
    )
  }
)

TabsPanel.displayName = 'TabsPanel'
