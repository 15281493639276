import type { AccountRecord } from '@libs/data-access/entities'
import { datesService } from '@libs/utils/translations'
import type { FC } from 'react'

type TimezoneInitProps = {
  account: AccountRecord
}

export const TimezoneInit: FC<TimezoneInitProps> = ({ account }) => {
  datesService.setTimezone(account.time_zone)

  return null
}
