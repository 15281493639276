import { createSafeContext } from '@libs/ui/ds/lib/internals'

import type { ComboboxProps } from './Combobox.types'

export type ComboboxContext = {
  loading?: ComboboxProps['loading']
  opened?: ComboboxProps['opened']
  dropdownWidth?: ComboboxProps['dropdownWidth']
  dropdownHeight?: ComboboxProps['dropdownHeight']
  closeOnOptionClick?: ComboboxProps['closeOnOptionClick']
  store?: ComboboxProps['store']
}

export const [ComboboxProvider, useComboboxContext] =
  createSafeContext<ComboboxContext>({
    context: 'Combobox'
  })
