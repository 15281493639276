import queryString from 'query-string'
import { call } from 'redux-saga/effects'

import {
  FETCH_SUBSCRIPTION_INVOICE,
  FETCH_SUBSCRIPTION_INVOICE_SUCCEEDED,
  FETCH_SUBSCRIPTION_INVOICE_FAILED
} from '../actionTypes'
import { call as callAPI } from '../infra/http'
import { fetchSagaEntity } from '../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchSubscriptionInvoice = (
  invoiceId,
  params,
  resolve,
  reject
) => ({
  type: FETCH_SUBSCRIPTION_INVOICE,
  invoiceId,
  params,
  resolve,
  reject
})

const subscriptionInvoiceSagaEntity = {
  success: (data, invoiceId) => ({
    type: FETCH_SUBSCRIPTION_INVOICE_SUCCEEDED,
    data,
    invoiceId
  }),
  failure: (error, invoiceId) => ({
    type: FETCH_SUBSCRIPTION_INVOICE_FAILED,
    error,
    invoiceId
  }),

  fetchAPI: (invoiceId, options, params) =>
    callAPI(
      `/subscription_invoices/${invoiceId}?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchSubscriptionInvoice({
  invoiceId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    subscriptionInvoiceSagaEntity,
    invoiceId,
    params,
    undefined,
    resolve,
    reject
  )
}
