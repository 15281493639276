import type { WeeklyScheduleRecordMap } from '@libs/data-access/entities'
import type { ReduxState, RelationSet } from '@spa/redux/ReduxState'
import Immutable from 'immutable'
import { createSelector } from 'reselect'
import { getCurrentAccountId } from 'snap-redux/modules/accounts/selectors'
import {
  listToMapWithKey,
  getDataByForeignId
} from 'snap-redux/services/immutableUtils'

type Params = {
  archived: boolean
  week: string
  locationId: number
  teamId: number
}

/**
 * Get locations by current accountId
 */
const locationsSelectorByCurrentAccountId = createSelector(
  [
    state => state.locations.get('data', Immutable.List()),
    state => {
      const accountId = getCurrentAccountId(state)
      return state.locations.getIn(
        ['relations', 'account_id', accountId],
        Immutable.Map()
      )
    },
    (_, { archived }) => archived
  ],
  (locations, locationIds, archived) => {
    const filteredLocations = locationIds
      .map(id => locations.get(id))
      .filter(location => location.archived === archived)

    return listToMapWithKey(filteredLocations, 'id')
  }
)

/**
 * Get teams by locationId
 */
const teamsSelector = createSelector(
  [
    locationsSelectorByCurrentAccountId,
    state => state,
    (_, { locationId }) => locationId
  ],
  (_, state, locationId) => {
    // const keys = locations.keySeq()
    return getDataByForeignId(state, 'teams', 'location_id', locationId)
  }
)

/**
 * Get all weeklySchedules
 */
const allWeeklyScheduleSelector = (
  state: ReduxState
): WeeklyScheduleRecordMap =>
  state.weeklyschedules.get('data', Immutable.List())

/**
 * Get weeklySchedules IDs by week
 */
const weeklySchedulesRelationByWeekSelector = (
  state: ReduxState,
  { week }: Partial<Params>
): RelationSet =>
  state.weeklyschedules.getIn(['relations', 'week', week], Immutable.Map())

/**
 * Get weeklySchedules by week
 */
const allWeeklySchedulesByWeekSelector = createSelector(
  [allWeeklyScheduleSelector, weeklySchedulesRelationByWeekSelector],
  (weeklyschedules, ids) => {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    const result = ids.map(id => weeklyschedules.get(id))

    return listToMapWithKey(result, 'id')
  }
)

/**
 * Get filtered weeklySchedules by week and team
 */
export const weeklySchedulesSelector = createSelector(
  [allWeeklySchedulesByWeekSelector, teamsSelector, (_, { teamId }) => teamId],
  (weeklyschedules, teams, teamId) => {
    const handler =
      teamId === 0
        ? weeklyschedule => teams.has(weeklyschedule.team_id)
        : weeklyschedule => weeklyschedule.team_id === teamId

    return weeklyschedules.filter(handler) as WeeklyScheduleRecordMap
  }
)
