import type { ReduxState } from '@spa/redux/ReduxState'
import { getCurrentMembershipSelector } from 'snap-redux/modules/memberships/selectors'

import { membershipsSelectors } from '../../../memberships'
import { teamsSelectors } from '../../../teams'
import { contractsBaseSelectors } from '../../contractsBaseSelectors'

/**
 * Get current logged user contracts from a specific team
 */
const getCurrentMembershipContractIds = (
  state: ReduxState,
  { teamId }: { teamId: number }
) => {
  const currentMembership = getCurrentMembershipSelector(state)

  const team = teamsSelectors.getCachedById(state, teamId)
  const contracts = contractsBaseSelectors.getCachedMap(state)
  const memberships = membershipsSelectors.getCachedMap(state)
  const allTeamContractIds = team?.user_contracts || []

  const currentMembershipContractIds = allTeamContractIds.filter(i => {
    const contract = contracts[i]
    const membership = memberships[contract.membership_id]
    return membership.id === currentMembership.id
  })

  return currentMembershipContractIds
}

export default getCurrentMembershipContractIds
