import { useMutation } from '@tanstack/react-query'

import type { ApiErrorResponse, UseAppMutationOptions } from '../../../../types'
import { moveShift, type MovePlannable } from '../monthly.api'
import { monthlyServiceCacheKeys } from '../monthly.cacheKeys'

export const useMoveShift = (
  mutationOpts?: UseAppMutationOptions<null, ApiErrorResponse, MovePlannable>
) => {
  return useMutation<null, ApiErrorResponse, MovePlannable>({
    ...mutationOpts,
    mutationKey: monthlyServiceCacheKeys.shift.move(),
    mutationFn: (opts: MovePlannable) => moveShift(opts)
  })
}
