import { CheckCircleIcon, CloseIcon } from '@libs/ui/ds/assets/icons'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import React from 'react'

import { IconWrap } from '../../../../dataDisplay/IconWrap/base/IconWrap'
import { Flex } from '../../../../primitiveLayouts/Flex/base/Flex'
import { Text } from '../../../../typography/Text/base/Text'

import type { PasswordInputRestrictionItemProps } from './PasswordInputRestrictionItem.types'

const passwordInputRestrictionItemCVA = {
  icon: cva([], {
    variants: {
      isValid: {
        true: 'text-semantic-green-500',
        false: 'text-semantic-red-500'
      }
    }
  })
}

export const PasswordInputRestrictionItem: FC<
  PasswordInputRestrictionItemProps
> = ({ passwordValue = '', restriction }) => {
  const isValid = restriction.isValid(passwordValue)

  return (
    <Flex row className='items-center gap-2'>
      <IconWrap
        size='s'
        className={passwordInputRestrictionItemCVA.icon({ isValid })}
        icon={isValid ? <CheckCircleIcon /> : <CloseIcon />}
      />
      <Text>{restriction.label}</Text>
    </Flex>
  )
}
