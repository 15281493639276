import { omit } from 'lodash-es'

import type { SelectOption } from '../../../base/Select.types'
import type { SelectGroupOption } from '../SelectGroup.types'

/**
 * Transform group options to flatten options
 * [group] property is deducted from the data-structure grouping
 */
export const flattenSelectGroupOptions = (options: SelectGroupOption[]) => {
  return options.reduce((acc, option) => {
    const groupItem = omit(option, 'items')
    const subItems = (option.items || []).map(i => ({
      ...i,
      group: groupItem.label
    }))
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    return acc.concat([groupItem, ...subItems])
  }, []) as SelectOption[]
}
