import type { UserRecord, UserRecordMap } from '@libs/data-access/entities'
import { cookiesService } from '@libs/utils/cookies'
import type { ReduxState } from '@spa/redux/ReduxState'
import { getRoleRights } from 'snap-redux/modules/memberships/utils'

import { getDataByForeignId } from '../../services/immutableUtils'
import {
  getCurrentMembershipSelector,
  getMembershipById
} from '../memberships/selectors'

export const getCurrentUserId = (): number =>
  cookiesService.readUserId() as number

export const getCurrentUser = (state: ReduxState): UserRecord | null => {
  const currentUserId = getCurrentUserId()
  return currentUserId && state.users.getIn(['data', currentUserId])
}

export const getCurrentUserRoleRights = state => {
  const currentMembership = getCurrentMembershipSelector(state)
  return getRoleRights(currentMembership)
}

export const getUserById = (state: ReduxState, userId: number): UserRecord =>
  state.users.getIn(['data', Number(userId)])

export const getUserByIdFactory =
  (state: ReduxState): ((userId: number) => UserRecord) =>
  (userId: number): UserRecord =>
    getUserById(state, userId)

export const getUserByMembershipId = (
  state: ReduxState,
  membershipId: number
) => getDataByForeignId(state, 'users', 'membership_id', membershipId)

export const getUserbyMembershipIdFactory =
  (state: ReduxState) => (membershipId: number) => {
    const { user_id: userId } = getMembershipById(state, membershipId) || {}
    const user = getUserById(state, userId)

    return user || null
  }

export const getIsUserLoading = (state: ReduxState): boolean =>
  state.users.getIn(['meta', 'loading'])

export const getUserMeta = (
  state: ReduxState
): { loading: boolean; success: boolean; error: boolean } =>
  state.users.get('meta').toJS()

export const getAllUsers = (state: ReduxState): UserRecordMap =>
  state.users.get('data')
