import type { AccountLocationGroupNotPaidInvoice } from '@libs/data-access/entities'
import { Anchor, Banner } from '@libs/ui/ds'
import { useCommonAppTranslation } from '@libs/utils/translations'
import { routes } from '@spa/core/router'
import React from 'react'
import { generatePath, Link } from 'react-router-dom'

type Props = {
  isAccountStatusCancelled: boolean
  data: AccountLocationGroupNotPaidInvoice[]
}

export const NotPaidInvoiceBanner: React.FC<Props> = ({
  isAccountStatusCancelled,
  data
}) => {
  const { t } = useCommonAppTranslation()

  if (!(data.length && isAccountStatusCancelled)) {
    return null
  }

  return (
    <Banner variant='danger'>
      {t('banner.notPaid.default')}
      <Anchor
        rel='noreferrer'
        variant='inherits'
        component={Link}
        to={{
          pathname: generatePath(routes.subscription.path)
        }}
      >
        {t('banner.notPaid.link')}
      </Anchor>
    </Banner>
  )
}
