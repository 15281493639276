import { useEffect, useRef } from 'react'

/**
 * useDidMount acts like a useEffect except that
 * the function will only be triggered on first mount
 */
export const useDidMount = (func: () => void) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      func()
    }
  }, [])

  return didMount.current
}
