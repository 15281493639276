import type { AccountLocationGroupInvoiceDetails } from '@libs/data-access/entities'

import { httpServices } from '../../../apis'
import type { QueryFnParams } from '../../../types'

export type FetchInvoice = QueryFnParams<{
  pathParams: {
    id: string
  }
  queryParams?: {
    disposition_type?: 'attachment' | 'inline'
  }
}>

export const fetchInvoice = async (options: FetchInvoice) => {
  const response =
    await httpServices.billing.get<AccountLocationGroupInvoiceDetails>(
      `/location-based/invoices/${options.pathParams.id}`,
      {
        params: {
          snap_app_account_id: httpServices.authInfo.accountId,
          disposition_type:
            // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
            options?.queryParams?.disposition_type || 'attachment'
        }
      }
    )
  return response.data
}
