import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import { maxBy } from 'lodash-es'
import createCachedSelector from 're-reselect'

import { getWeekDateParams } from '../../params'
import { computeStackedItems } from '../utils/computeStackedItems'

import getRestTubesByContract from './getRestTubesByContract'
import { overlapsRange } from './utils/overlapsRange'

/**
 * Return planning rest tubes mapped by week index days
 */
export const getStackedRestTubeInTimerange = createCachedSelector(
  getWeekDateParams,
  getRestTubesByContract,

  ({ week }, restTubes) => {
    const filteredRestTubes = restTubes.filter(rest =>
      overlapsRange(rest, week)
    )

    const formatToStackItem = filteredRestTubes.map(i => ({
      id: i.id,
      start: i.starts_at,
      end: i.ends_at
    }))

    const stackedItems = computeStackedItems(formatToStackItem)
    const maxOffsetItem = maxBy(stackedItems, i => i.offsetY)

    return {
      stackedItems,
      maxOffsetY: maxOffsetItem?.offsetY || 0,
      count: filteredRestTubes.length
    }
  }
)((_, { contractId }) => fallbackToDefaultCacheKey(contractId))
