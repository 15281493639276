import type { ReduxState } from '@spa/redux/ReduxState'
import { useMemoSelector } from '@spa/redux/hooks'
import { composeCacheKey } from '@spa/redux/utils/reselect'
import moment from 'moment'
import createCachedSelector from 're-reselect'
import { getCurrentMembershipSelector } from 'snap-redux/modules/memberships/selectors'
import { getRoleRights } from 'snap-redux/modules/memberships/utils'

import {
  contractsSelectors,
  shiftsSelectors,
  weeklyschedulesSelectors
} from '../../entities'
import { getPlanningPolicies } from '../getPlanningPolicies'

type Params = {
  shiftId: number
  weeklyscheduleId: number
}

function isPast(date: string) {
  return moment().isAfter(date, 'minute')
}

const getShiftById = (state: ReduxState, opts: Params) => {
  return shiftsSelectors.getCachedById(state, opts.shiftId)
}

const getIsPastShift = (state: ReduxState, opts: Params) => {
  const shift = shiftsSelectors.getCachedById(state, opts.shiftId)
  return isPast(shift.starts_at)
}

export const getOpenShiftModalPolicies = createCachedSelector(
  getCurrentMembershipSelector,
  getShiftById,
  getIsPastShift,
  contractsSelectors.getCachedMap,
  weeklyschedulesSelectors.getCachedMap,
  getPlanningPolicies,
  (
    currentMembership,
    shift,
    isPastShift,
    contractsMap,
    weeklyschedulesMap,
    policies
  ) => {
    const shiftWeeklyschedule = weeklyschedulesMap[shift.weeklyschedule_id]
    const shiftContract = contractsMap[shift.user_contract_id]
    const isSameMember = shiftContract.membership_id === currentMembership?.id
    const isUnplanned = shift?.planification_type === 'unscheduled'
    const { exact } = getRoleRights(currentMembership)

    /**
     * If the weeklyschedule does not exist, the shift cant be accessed by the logged user
     */
    if (!shiftWeeklyschedule) {
      return false
    }

    /**
     * Nobody can open shift form (not real hours) with unplanned shift
     */
    if (isUnplanned && !shiftWeeklyschedule?.published) {
      return false
    }

    /**
     * Employee can edit their own past shifts
     */
    if (!policies.canEditOwnShift && isPastShift && isSameMember) {
      return true
    }

    /**
     * Manager can NOT edit future shifts if schedule is published and the following option is OFF:
     * Can edit published schedules and validate shifts for own location = OFF
     */
    if (
      exact.manager &&
      !policies.canEditPublishedShift &&
      shiftWeeklyschedule?.published &&
      !isPastShift
    ) {
      return false
    }

    /**
     * Manager and higher can edit their own shifts
     */
    if (policies.canEditOwnShift && isSameMember) {
      return true
    }

    if (
      (shiftWeeklyschedule?.published && policies.canEditPublishedShift) ||
      (!shiftWeeklyschedule?.published && policies.canEditShift)
    ) {
      return true
    }

    return false
  }
)((_, { shiftId, weeklyscheduleId }) =>
  composeCacheKey(shiftId, weeklyscheduleId)
)

export const useOpenShiftModalPolicies = (opts: Params) => {
  return useMemoSelector(state => getOpenShiftModalPolicies(state, opts))
}
