import { cva } from 'class-variance-authority'

export const anchorCVA = cva(['cursor-pointer underline'], {
  variants: {
    variant: {
      primary:
        'text-brand-green-500 hover:text-brand-green-800 active:text-brand-green-900',
      inherits: 'text-inherit hover:opacity-75 active:opacity-50'
    }
  }
})
