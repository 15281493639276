import { coreWSService } from './coreWS/coreWS.service'
import { coreWSSubscriptionFactory } from './coreWS/coreWSSubscription.factory'

class WsServices {
  get core() {
    return {
      ws: coreWSService,
      subscription: coreWSSubscriptionFactory
    }
  }
}

export const wsServices = new WsServices()
