import { GTM_APP_ID } from '@libs/utils/environments'
import type { SignUpCompanyFormType } from '@spa/scenes/Auth/SignUpLegacy/SignUpCompanyForm'
import type { SignUpFormType } from '@spa/scenes/Auth/SignUpLegacy/SignUpForm'
import { useEffect } from 'react'

export const useInitGTM = () => {
  useEffect(() => {
    if (GTM_APP_ID && !document.getElementById('_ss_g_t_m')) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      })
      const headFirstScript = document.getElementsByTagName('script')[0]
      const script = document.createElement('script')
      script.id = '_ss_g_t_m'
      script.async = true
      script.src = `https://www.googletagmanager.com/gtm.js?id=${GTM_APP_ID}`
      headFirstScript?.parentNode?.insertBefore(script, headFirstScript)

      script.onload = () => {
        initGTMNoScript()
      }
      return () => {
        script.remove()
      }
    }
  }, [])
}

const trackIntermediateStep = (data: SignUpFormType['user']) => {
  if (window?.dataLayer) {
    window.dataLayer.push({
      event: 'intermediate_step_free_trial',
      user_data: {
        first_name: data.firstname,
        last_name: data.lastname,
        user_phone: data.phone_number,
        user_email: data.email,
        user_phone_indicator: data.phone_indicator
      }
    })
  }
}

const initGTMNoScript = () => {
  return () => {
    if (GTM_APP_ID && !document.getElementById('_ss_g_t_m_noscript')) {
      const noscript = document.createElement('noscript')
      noscript.id = '_ss_g_t_m_noscript'
      const iframe = document.createElement('iframe')
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${GTM_APP_ID}`
      iframe.style.display = 'none'
      iframe.style.visibility = 'hidden'
      iframe.setAttribute('height', '0')
      iframe.setAttribute('width', '0')
      noscript.appendChild(iframe)
      document.body.insertBefore(noscript, document.body.firstChild)
      return () => {
        noscript.remove()
      }
    }
  }
}

const trackLeadFreeTrial = (
  userData: SignUpFormType['user'],
  userTrackingData: SignUpCompanyFormType['user']
) => {
  if (window?.dataLayer) {
    window.dataLayer.push({
      event: 'generate_lead_free_trial',
      is_user_franchised: Boolean(userTrackingData.tracking_datas.is_franchise),
      user_sector: userTrackingData.tracking_datas.industry,
      user_employees: userTrackingData.tracking_datas.employee_count,
      user_shops: userTrackingData.tracking_datas.location_count,
      user_company_country: userTrackingData.country,
      user_role: userTrackingData.tracking_datas.job,
      user_data: {
        first_name: userData.firstname,
        last_name: userData.lastname,
        user_phone: userData.phone_number,
        user_email: userData.email,
        user_phone_indicator: userData.phone_indicator
      }
    })
  }
}

export const GTM = {
  trackIntermediateStep,
  trackLeadFreeTrial
}
