import type { ReduxState } from '@spa/redux/ReduxState'
import createCachedSelector from 're-reselect'

import { rowsSelectors } from '../../rows'
import { shiftsBaseSelectors } from '../shiftsBaseSelectors'

export const getShiftsByContract = createCachedSelector(
  (state: ReduxState) => shiftsBaseSelectors.getCachedMap(state),
  (state: ReduxState, contractId: number) =>
    rowsSelectors.getCachedById(state, contractId),

  (shifts, row) => {
    const shiftList = (row?.shifts || []).map(id => shifts[id])
    return shiftList
  }
)((_, contractId) => contractId)
