import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  CREATE_LOCATION,
  CREATE_LOCATION_FAILED,
  CREATE_LOCATION_SUCCEEDED,
  FETCH_LOCATION,
  FETCH_LOCATION_FAILED,
  FETCH_LOCATION_SUCCEEDED,
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_FAILED,
  FETCH_LOCATIONS_SUCCEEDED,
  FETCH_LOCATIONS_THROTTLED,
  UPDATE_LOCATION,
  UPDATE_LOCATION_FAILED,
  UPDATE_LOCATION_SUCCEEDED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords, updateRecord } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'
import {
  sendMessageToChannel,
  subscribeToChannel,
  unsubscribeFromChannel
} from '../socket'
import { isConnectedToChannel } from '../socket/selectors'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchLocations = (accountId, params?, resolve?, reject?) => ({
  type: FETCH_LOCATIONS,
  accountId,
  params,
  resolve,
  reject
})

/**
 * @deprecated
 */
export const fetchLocation = (locationId, resolve, reject) => ({
  type: FETCH_LOCATION,
  locationId,
  resolve,
  reject
})

/**
 * @deprecated
 */
export const createLocation = (locationData, resolve, reject) => ({
  type: CREATE_LOCATION,
  locationData,
  resolve,
  reject
})

/**
 * @deprecated
 */
export const updateLocation = (locationId, locationData, resolve, reject) => ({
  type: UPDATE_LOCATION,
  locationId,
  locationData,
  resolve,
  reject
})

// ------------------------------------
// Socket Actions
// ------------------------------------

const CHANNEL_NAME = 'LocationChannel'

export const isConnectedToLocationChannel = state =>
  isConnectedToChannel(state, CHANNEL_NAME)
export const subscribeToLocationChannel = () => subscribeToChannel(CHANNEL_NAME)
export const unsubscribeFromLocationChannel = () =>
  unsubscribeFromChannel(CHANNEL_NAME)

const sendMessageToLocationChanel = (
  messageType,
  messageData,
  resolve,
  reject
) =>
  sendMessageToChannel(CHANNEL_NAME, messageType, messageData, resolve, reject)

export const archiveLocationThroughSocket = (
  locationSourceId,
  locationTargetId,
  teamTargetId,
  resolve,
  reject
) =>
  sendMessageToLocationChanel(
    'archive',
    {
      location_source_id: locationSourceId,
      location_target_id: locationTargetId,
      team_target_id: teamTargetId
    },
    resolve,
    reject
  )

export const unarchiveLocationThroughSocket = (locationId, resolve, reject) =>
  sendMessageToLocationChanel(
    'unarchive',
    { location_id: locationId },
    resolve,
    reject
  )

// ------------------------------------
// Sagas
// ------------------------------------

const locationsSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_LOCATIONS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_LOCATIONS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) => {
    return callAPI(
      `/accounts/${accountId}/locations?${queryString.stringify(
        {
          ...params
        },
        { arrayFormat: 'bracket' }
      )}`,
      options
    )
  }
}

export function* doFetchLocations({ accountId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    locationsSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

const locationSagaEntity = {
  success: (data, locationId) => ({
    type: FETCH_LOCATION_SUCCEEDED,
    data,
    locationId
  }),
  failure: (error, locationId) => ({
    type: FETCH_LOCATION_FAILED,
    error,
    locationId
  }),

  fetchAPI: (locationId, options) =>
    callAPI(`/locations/${locationId}`, options)
}

export function* doFetchLocation({ locationId, resolve, reject }) {
  // @ts-ignore migration from js(x) to ts(x)
  yield call(fetchSagaEntity, locationSagaEntity, locationId, resolve, reject)
}

const createLocationSagaEntity = {
  success: data => ({ type: CREATE_LOCATION_SUCCEEDED, data }),
  failure: error => ({ type: CREATE_LOCATION_FAILED, error }),

  fetchAPI: (id, options) =>
    callAPI('/locations', { method: 'POST', ...options })
}

export function* doCreateLocation({ locationData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createLocationSagaEntity,
    null,
    null,
    locationData,
    resolve,
    reject
  )
}

const updateLocationSagaEntity = {
  success: (data, locationId) => ({
    type: UPDATE_LOCATION_SUCCEEDED,
    data,
    locationId
  }),
  failure: (error, locationId) => ({
    type: UPDATE_LOCATION_FAILED,
    error,
    locationId
  }),

  fetchAPI: (locationId, options) =>
    callAPI(`/locations/${locationId}`, { method: 'PUT', ...options })
}

export function* doUpdateLocation({
  locationId,
  locationData,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    updateLocationSagaEntity,
    locationId,
    null,
    locationData,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const Location = Immutable.Record({
  id: null,
  name: null,
  siret: null,
  logo: null,
  address: null,
  account_id: null,
  bank_holiday_area: null,
  current_modulation_period: null,
  current_modulation_location_contracts: null,
  created_at: null,
  city: null,
  zipcode: null,
  latitude: null,
  longitude: null,
  productivity_objective: null,
  wage_ratio_objective: null,
  collective_agreement_id: null,
  ape_code: null,
  country: null,
  collective_agreement_attributes: null,
  default_meal_type: null,
  workable_days_in_week: null,
  allow_negative_compensatory_counter: false,
  enable_compensatory_for_part_time: false,
  skip_percentage_for_compensatory_deposit: false,
  occupational_medicine_code: null,
  employees_can_set_real_hours: true,
  default_break_duration: null,
  default_break_duration_type: null,
  archived: false,
  partner_id: null,
  have_access_to_all_teams: false,
  urssaf_name: null,
  template_metadata: {},
  paid_leave_acquisition_periods: null,
  paid_leave_configuration: null,
  minimum_shift_duration_for_automatic_breaks_in_minutes: null,
  set_default_break_on_shifts: false,
  teams: null
})

const locationForeignKeys = ['account_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function locations(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_LOCATIONS: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_LOCATIONS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_LOCATIONS_THROTTLED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: true })
    }
    case FETCH_LOCATIONS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const locations = Immutable.fromJS(action.data)

      return mergeRecords(
        state,
        Location,
        locations,
        // @ts-ignore migration from js(x) to ts(x)
        locationForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    case FETCH_LOCATION: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_LOCATION_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_LOCATION_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const location = Immutable.fromJS(action.data)

      return updateRecord(
        state,
        Location,
        location,
        // @ts-ignore migration from js(x) to ts(x)
        locationForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        success: true,
        updated_at: Date.now()
      })
    }
    case CREATE_LOCATION_SUCCEEDED:
    case UPDATE_LOCATION_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const location = Immutable.fromJS(action.data)
      // @ts-ignore migration from js(x) to ts(x)
      return updateRecord(state, Location, location, locationForeignKeys)
    }
    default:
      return state
  }
}

export default filterActions(locations, [
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_THROTTLED,
  FETCH_LOCATIONS_FAILED,
  FETCH_LOCATIONS_SUCCEEDED,
  FETCH_LOCATION,
  FETCH_LOCATION_FAILED,
  FETCH_LOCATION_SUCCEEDED,
  CREATE_LOCATION_SUCCEEDED,
  UPDATE_LOCATION_SUCCEEDED
])
