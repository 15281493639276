import { composeCacheKey } from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'

import { weeklyschedulesSelectors } from '../../entities/weeklyschedules'
import { getPlanningPolicies } from '../getPlanningPolicies'

export const getCanSeeUnpublishedPlanning = createCachedSelector(
  getPlanningPolicies,
  weeklyschedulesSelectors.getCachedById,

  (policies, weeklyschedule) => {
    if (weeklyschedule.published) {
      return true
    }

    return policies.canSeeUnpublishedPlanning
  }
)((_, weeklyscheduleId) => composeCacheKey(weeklyscheduleId))
