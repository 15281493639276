import type { ApiErrorResponseData } from '@libs/data-access/queries'
import { notifications } from '@libs/ui/ds'
import { put } from 'redux-saga/effects'
import { updateRest } from 'snap-redux/modules/rests'

import type { PayloadActionWithoutType } from '../../types/types'

type Params = PayloadActionWithoutType<{
  restId: number
  to: {
    contractId: number
    newStart: string
    newEnd: string
  }
  errorCallback?: () => void
}>

function* updateRestTimeRange(action: Params): any {
  const { restId, to, errorCallback } = action.payload

  const payload = {
    starts_at: to.newStart,
    ends_at: to.newEnd,
    user_contract_id: to.contractId,
    id: restId
  }

  yield put(
    updateRest(restId, payload, null, (error: ApiErrorResponseData) => {
      errorCallback?.()
      if (!error) {
        return
      }
      notifications.errorAdapter({ error })
    })
  )
}

export default updateRestTimeRange
