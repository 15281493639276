import { Anchor, Banner } from '@libs/ui/ds'
import { useCommonAppTranslation } from '@libs/utils/translations'
import { routes } from '@spa/core/router'
import { useMemoSelector } from '@spa/redux/hooks'
import React from 'react'
import type { FC } from 'react'
import { Link, generatePath } from 'react-router-dom'
import { getCurrentAccount } from 'snap-redux/modules/accounts/selectors'
import { useRoleRights } from 'snap-redux/modules/memberships/utils'

export const BlockedBanner: FC = () => {
  const { t } = useCommonAppTranslation()
  const { atLeast } = useRoleRights()
  const account = useMemoSelector(getCurrentAccount)

  if (!atLeast.owner || !account.blocked) {
    return null
  }

  return (
    <Banner variant='danger'>
      {t('banner.blocked.text')}
      <Anchor
        component={Link}
        rel='noreferrer'
        variant='inherits'
        to={{
          pathname: generatePath(routes.subscription.path)
        }}
      >
        {t('banner.blocked.link')}
      </Anchor>
    </Banner>
  )
}
