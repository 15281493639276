import type {
  AccountRecord,
  AccountRecordMap
} from '@libs/data-access/entities'
import { cookiesService } from '@libs/utils/cookies'
import type { ReduxState } from '@spa/redux/ReduxState'
import { Map } from 'immutable'
import { createSelector } from 'reselect'
import type {
  Account,
  AccountPermissions
} from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

export const getCurrentAccountId = (state: ReduxState): number => {
  const accountId = cookiesService.readAccountId() as number

  // Check if accountId is a string representation of a number or subdomain
  if (typeof accountId === 'string') {
    // If accountId is a string that can be converted to a number, return the number.
    // Otherwise, look up the account by subdomain and return its ID, or the subdomain if not found.
    if (!Number.isNaN(Number(accountId))) {
      return Number(accountId)
    }
    const account = state.accounts
      .get('data', Map([]))
      .find(({ subdomain }) => subdomain === accountId) as Account

    if (account) {
      return account.id
    }

    return accountId as unknown as number
  }

  // If accountId is not a string, return it as is (it should be a number or undefined).
  return accountId
}

export const getCurrentAccount = (state: ReduxState): AccountRecord => {
  const accountId = getCurrentAccountId(state)
  return state.accounts.getIn(['data', accountId])
}

export const getAccounts = (state: ReduxState): AccountRecordMap => {
  return state.accounts.get('data')
}

export const getAccountById = (
  state: ReduxState,
  accountId: number
): AccountRecord => {
  return state.accounts.getIn(['data', accountId])
}

export const getCurrentAccountMeta = (state: ReduxState) => {
  return state.accounts.getIn(['meta', 'loading'])
}

export const getPhotoPreference = createSelector(
  [state => state, (state: ReduxState) => getCurrentAccountId(state)],
  (state, accountId) =>
    state.accounts.getIn([
      'data',
      accountId,
      'timeclock_preferences',
      'has_photo_enabled'
    ]) as boolean
)

export const getIsAccountLoading = (state: ReduxState): boolean =>
  state.accounts.getIn(['meta', 'loading'])

export const getAccountPermissions = (
  state: ReduxState
): AccountPermissions => {
  const account = getCurrentAccount(state)
  if (!account) {
    return {} as AccountPermissions
  }
  return account.get('permissions').toJS()
}
