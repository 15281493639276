import { CalendarIcon } from '@libs/ui/ds/assets/icons'
import type { FC } from 'react'
import React from 'react'

import { InputWrap } from '../../../../forms/InputWrap/base/InputWrap'
import { InputBase } from '../../../../inputs/InputBase/base/InputBase'
import { Popover } from '../../../../overlays/Popover/base/Popover'
import { CalendarPicker } from '../../../datePickerUtils/subs/CalendarPicker'
import { DatePickerFocusWrap } from '../../../datePickerUtils/subs/DatePickerFocusWrap'
import { DatePickerInputClearButton } from '../../../datePickerUtils/subs/DatePickerInputClearButton'
import { PickerPresetList } from '../../../datePickerUtils/subs/PickerPresets/PickerPresetList'
import { dateConfig } from '../../../datePickerUtils/utils/dateConfig'
import { useDatePickerOpened } from '../../../datePickerUtils/utils/useDatePickerOpened/useDatePickerOpened'

import type { DatePickerInputProps } from './DatePickerInput.types'

export const DatePickerInput: FC<DatePickerInputProps> = ({
  value,
  onChange,
  defaultValue,
  presets,
  /** Popover props */
  onOpenedChange,
  opened,
  dropdownPosition = 'bottom-start',

  /** InputWrap Props */
  label,
  disabled = false,
  className,
  required,
  errorMessage,
  desc,
  readOnly = false,
  rightSection,
  onClear,
  clearable = false,
  pickerOnly,

  /** Calendar Props */
  maxLevel,
  placeholder,
  excludeDate,
  minDate,
  maxDate,
  defaultDate,
  ...other
}) => {
  const openedValues = useDatePickerOpened({ onOpenedChange, opened })
  const syncDate = dateConfig.useSyncDate({
    value,
    defaultValue,
    defaultDate,
    onChange,
    minDate,
    maxDate
  })

  return (
    <Popover
      dropdownHeight='l'
      dropdownWidth='auto'
      dropdownPosition={dropdownPosition}
      opened={openedValues.opened}
      disabled={disabled || readOnly}
    >
      <DatePickerFocusWrap setOpen={openedValues.setOpened}>
        <InputWrap
          label={label}
          disabled={disabled}
          className={className}
          required={required}
          errorMessage={errorMessage || syncDate.errorMessage}
          desc={desc}
          {...other}
          field={
            <Popover.Target>
              <div>
                <InputBase
                  data-testid='date-picker-input'
                  value={syncDate.strValue}
                  onClick={e => e.preventDefault()}
                  onChange={e => syncDate.onChangeStr(e.target.value)}
                  type='date'
                  placeholder={placeholder}
                  disabled={disabled}
                  readOnly={readOnly || pickerOnly}
                  error={Boolean(errorMessage || syncDate.errorMessage)}
                  leftIcon={<CalendarIcon />}
                  rightSection={
                    <DatePickerInputClearButton
                      disabled={disabled}
                      fallback={rightSection}
                      clearable={clearable}
                      readOnly={readOnly}
                      onClear={e => {
                        onClear?.(e)
                        syncDate.reset()
                      }}
                    />
                  }
                />
              </div>
            </Popover.Target>
          }
        />
        <Popover.Dropdown>
          <CalendarPicker
            value={syncDate.value}
            date={syncDate.date}
            onDateChange={syncDate.onDateChange}
            onChange={val => {
              openedValues.setOpened(false)
              syncDate.onChange(val)
            }}
            minDate={syncDate.minDate}
            maxDate={syncDate.maxDate}
            defaultDate={syncDate.defaultDate}
            type='default'
            componentType='date'
            numberOfColumns={1}
            maxLevel={maxLevel}
            excludeDate={excludeDate}
          />
          <PickerPresetList
            onChange={val => {
              syncDate.onChange(val)
            }}
            presets={presets}
            value={{ start: syncDate.strValue, end: syncDate.strValue }}
          />
        </Popover.Dropdown>
      </DatePickerFocusWrap>
    </Popover>
  )
}

export type { DatePickerInputProps } from './DatePickerInput.types'
