import type { ApiErrorResponse } from '@libs/data-access/queries'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import type { ParamsFetchDocument } from '../modules/entities'
import { replaceDocumentSignature } from '../modules/entities'
import { setMetaLoading, setMetaSuccess, setMetaError } from '../modules/meta'
import { setPagination } from '../modules/pagination'

export default function* fetchDocumentSignatureData(
  queryParams: ParamsFetchDocument
) {
  yield put(setMetaLoading())

  try {
    const result = yield apiRequest({
      service: 'core',
      route: 'fetchDocumentSignature',
      queryParams
    })

    yield put(replaceDocumentSignature(result.signable_documents))
    yield put(setPagination(result.meta))
    yield put(setMetaSuccess())
  } catch (err) {
    yield put(setMetaError(err as ApiErrorResponse))
  }
}
