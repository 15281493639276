import type { ReduxState } from '@spa/redux/ReduxState'
import moment from 'moment'
import { createSelector } from 'reselect'

import { getWeekDateParams } from '../../../params'
import type { ItemRange } from '../constants'
import { diffInHours } from '../utils/diffInHours'
import { multiplyByCellUnit } from '../utils/multiplyByCellUnit'

/**
 * Compute width and left position of an item
 * based on the current time range and the current grid layout
 */
export const getItemGridPositions = createSelector(
  getWeekDateParams,
  (_: ReduxState, itemRange: ItemRange) => itemRange,

  ({ week }, itemRange: ItemRange) => {
    const weekIsoStart = moment(week.start).startOf('day').toISOString()
    const weekIsoEnd = moment(week.end).endOf('day').toISOString()

    const maxOffset = Math.round(diffInHours(weekIsoEnd, weekIsoStart))
    const startOffset = diffInHours(itemRange.start, week.start)
    const endOffset = diffInHours(itemRange.end, week.start)

    // Compute the item start offset
    // And prevent negative offsets when the item start before the time range
    const cleanStartOffset = startOffset > 0 ? startOffset : 0

    // Compute the item end offset
    // And prevent negative offsets when the item ends after the time range
    const cleanEndOffset = Math.min(maxOffset, endOffset)

    // Compute the item size in hours
    const itemSizeInHours = cleanEndOffset - cleanStartOffset || 0

    return {
      width: multiplyByCellUnit(itemSizeInHours),
      left: multiplyByCellUnit(cleanStartOffset)
    }
  }
)
