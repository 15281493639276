import { ArrowLeftIcon, ArrowRightIcon } from '@libs/ui/ds/assets/icons'
import { usePagination } from '@mantine/hooks'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import { memo } from 'react'

import { IconButton } from '../../../buttons/IconButton/base/IconButton'

import type { PaginationProps } from './Pagination.types'
import { PaginationDot } from './subs/PaginationDot'
import { PaginationNumberItem } from './subs/PaginationNumberItem'
import { safePaginationRange } from './utils/safePaginationRange'

const paginationCVA = cva(['flex items-center gap-3 text-neutral-50'], {
  variants: {
    disabled: {
      true: 'pointer-events-none'
    }
  }
})

export const BasePagination: FC<PaginationProps> = memo(
  ({
    className,
    pageIndex,
    pageCount,
    defaultPageIndex,
    disabled,
    onChange,
    ...other
  }) => {
    const pagination = usePagination({
      total: pageCount,
      initialPage: defaultPageIndex,
      page: pageIndex,
      onChange,
      siblings: 1
    })

    return (
      <div {...other} className={paginationCVA({ className, disabled })}>
        <IconButton
          onClick={() => pagination.previous()}
          disabled={pagination.active === 1}
          size='s'
          variant='tertiary'
          icon={<ArrowLeftIcon />}
        />
        {safePaginationRange(pagination.range).map(item => {
          return typeof item === 'string' ? (
            <PaginationDot key={item} />
          ) : (
            <PaginationNumberItem
              value={item}
              onClick={pagination.setPage}
              selected={pagination.active === item}
              key={item}
              disabled={disabled}
            />
          )
        })}
        <IconButton
          onClick={() => pagination.next()}
          disabled={pagination.active === pageCount}
          size='s'
          variant='tertiary'
          icon={<ArrowRightIcon />}
        />
      </div>
    )
  }
)

BasePagination.displayName = 'Pagination'
export const Pagination = BasePagination
export type { PaginationProps } from './Pagination.types'
