import membershipsSelector from '@spa/scenes/PlanningLegacy/selectorsNext/planningMemberships.selector'
import { setParams } from '@spa/scenes/Plannings/redux/reducers'
import moment from 'moment'
import { all, call, put, select, take } from 'redux-saga/effects'
import { getCurrentAccount } from 'snap-redux/modules/accounts/selectors'
import { fetchContracts } from 'snap-redux/modules/contracts/index'
import { fetchAllMemberships } from 'snap-redux/modules/memberships/index'
import { fetchRests } from 'snap-redux/modules/rests/index'
import { fetchShifts } from 'snap-redux/modules/shifts/index'
import { getTeamsByLocationId } from 'snap-redux/modules/teams/selectors'
import { fetchWeeklyschedules } from 'snap-redux/modules/weeklyschedules/index'

import { PLANNING_INIT, PLANNING_INIT_SUCCESS } from '../actionTypes'

export const action = PLANNING_INIT

type InitParams = {
  locationId: number
  teamId: number
  date: string
  isMonthViewV2: boolean
}

function* planningInitSaga({
  payload: { locationId, teamId, date, isMonthViewV2 }
}: {
  payload: InitParams
}) {
  const week = moment(date)
  const start = week.startOf('isoWeek')
  const end = week.clone().endOf('isoWeek')

  const formatStart = start.format('YYYY-MM-DD')
  const formatEnd = end.format('YYYY-MM-DD')

  // STORE DATA
  const account = yield select(getCurrentAccount)
  const teams = yield select(state => getTeamsByLocationId(state, locationId))

  // DERIVED DATA
  const teamIds = teams.map(t => t.id).toArray()

  yield put(setParams({ locationId, teamId, date }))

  if (isMonthViewV2) {
    yield put({ type: PLANNING_INIT_SUCCESS })
    return
  }
  // Once month view v2 is on GA, we can remove the underneath code
  // This is only used for Legacy Month View

  yield all([
    call(function* () {
      // Current weekly schedule
      yield put(
        fetchWeeklyschedules(account.id, {
          week: start.format('GGGG-[W]WW'),
          with_permissions: true
        })
      )

      // Previous weekly schedule
      yield put(
        fetchWeeklyschedules(account.id, {
          week: start.clone().subtract(1, 'week').format('GGGG-[W]WW'),
          with_permissions: true
        })
      )
      yield all([
        take('api/FETCH_WEEKLYSCHEDULES_SUCCEEDED'),
        take('api/FETCH_WEEKLYSCHEDULES_SUCCEEDED')
      ])

      // Contracts
      yield put(
        // @ts-ignore migration from js(x) to ts(x)
        fetchContracts(account.id, {
          in_progress_on: formatStart
        })
      )

      // Rests
      yield put(
        // @ts-ignore migration from js(x) to ts(x)
        fetchRests(account.id, {
          start_date: formatStart,
          end_date: formatEnd
        })
      )

      yield all([
        take('api/FETCH_CONTRACTS_SUCCEEDED'),
        take('api/FETCH_RESTS_SUCCEEDED')
      ])
    }),
    // Memberships
    call(function* () {
      yield put(
        fetchAllMemberships(account.id, {
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          in_progress_on: formatStart,
          skip_most_relevant_contract: true,
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          perPage: 100,
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          locationId,
          teamIds
        })
      )
      yield take('api/FETCH_ALL_MEMBERSHIPS_SUCCESS')
    })
  ])

  // Shifts for shared employee
  const memberships = yield select(state =>
    membershipsSelector(state, {
      teamId,
      locationId,
      week: start.format('GGGG-[W]WW'),
      archived: false
    })
  )

  const membershipsIds = memberships.map(m => m.id).toArray()

  yield put(
    // @ts-ignore migration from js(x) to ts(x)
    fetchShifts(account.id, {
      start_date: formatStart,
      end_date: formatEnd,
      'membership_ids[]': membershipsIds
    })
  )

  yield take('api/FETCH_SHIFTS_SUCCEEDED')

  // Planning Init success
  yield put({ type: PLANNING_INIT_SUCCESS })
}

export default planningInitSaga
