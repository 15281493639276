import type { ReduxState } from '@spa/redux/ReduxState'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'

import { getPlanningPolicies } from '../../../policies/getPlanningPolicies'
import { membershipsSelectors } from '../../memberships'
import { contractsBaseSelectors } from '../contractsBaseSelectors'

import { getContractsIncludedInSchedule } from './utils/getContractsIncludedInSchedule'
import getCurrentMembershipContractIds from './utils/getCurrentMembershipContractIds'
import { sortContractsByMembershipIndex } from './utils/sortContractsByMembershipIndex'

type Params = {
  teamId: number
}

const getContractIds = (state, { teamId }: Params) => {
  const policies = getPlanningPolicies(state)
  return policies.canSeeCoworkerPlanning
    ? getContractsIncludedInSchedule(state, { teamId })
    : getCurrentMembershipContractIds(state, { teamId })
}

/**
 * Return filtered list of contracts that should be displayed on planning
 */
const getSortedTeamContracts = createCachedSelector(
  (_: ReduxState, { teamId }: Params) => teamId,
  getContractIds,
  contractsBaseSelectors.getCachedMap,
  membershipsSelectors.getCachedMap,

  (teamId, contractIds, contracts, memberships) => {
    const sortedContractIds = sortContractsByMembershipIndex({
      contractIds,
      contracts,
      memberships,
      teamId
    })

    return sortedContractIds.map(id => contracts[id])
  }
)((_, { teamId }) => fallbackToDefaultCacheKey(teamId))

export default getSortedTeamContracts
