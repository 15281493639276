import type { WorktimeTimesheetSchema } from '@libs/data-access/entities'
import { httpServices } from '@libs/data-access/queries'

export type FetchTimesheetsByRangeOptions = {
  pathParams: {
    membership_id: number
  }
  queryParams: {
    end_date: string
    start_date: string
  }
}

export const fetchTimesheetsByRange = async (
  opts: FetchTimesheetsByRangeOptions
) => {
  const response = await httpServices.core.get<WorktimeTimesheetSchema>(
    `/memberships/${opts.pathParams.membership_id}/timesheets`,
    {
      params: opts.queryParams
    }
  )

  return response.data
}
