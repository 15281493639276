import addonsSagas from './addons/sagas'
import estimateSagas from './estimate/sagas'
import locationSagas from './locations/sagas'
import planSagas from './plan/sagas'
import subscriptionSagas from './subscription/sagas'

export default [
  ...addonsSagas,
  ...estimateSagas,
  ...locationSagas,
  ...planSagas,
  ...subscriptionSagas
]
