import type { MembershipSchema } from '@libs/data-access/entities'
import {
  httpServices,
  type PaginatedQueryResponse
} from '@libs/data-access/queries'

export type FetchLineManagersOptions = {
  queryParams: {
    per_page: number
    location_id?: number
    team_id?: number
    for_membership_id?: number
    search?: string
  }
}

export const fetchLineManagers = async (opts: FetchLineManagersOptions) => {
  const response = await httpServices.core.get<
    PaginatedQueryResponse<MembershipSchema>
  >(`/employee_management/eligible_line_managers`, {
    params: opts.queryParams
  })

  return response.data
}
