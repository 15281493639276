export const HOURS_IN_DAY = 24
export const DAYS_IN_WEEK = 7
const INITIAL_FOUR_HOURS = 4

export const DEFAULT_GRID_CELL_WIDTH = 64
export const DEFAULT_GRID_HALF_CELL_WIDTH = DEFAULT_GRID_CELL_WIDTH / 2
export const FIVE_MINUTES_SIZE = DEFAULT_GRID_CELL_WIDTH / 12

export const NUMBER_OF_HALF_HOURS_PER_DAY = 48

export const SCROLL_LEFT_OFFSET = 256
export const DAY_GRID_WIDTH = HOURS_IN_DAY * DEFAULT_GRID_CELL_WIDTH
export const FULL_GRID_WIDTH = DAYS_IN_WEEK * DAY_GRID_WIDTH

/**
 * Used for initial scrollzone adjustment
 */
export const INITIAL_SCROLL_OFFSET =
  INITIAL_FOUR_HOURS * DEFAULT_GRID_CELL_WIDTH

export type ItemRange = {
  start: string
  end: string
}
