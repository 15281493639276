import { Button } from '@libs/ui/ds/lib/components'
import { createButton } from '@libs/ui/ds/lib/components/buttons/BaseButton/utils/createButton'
import { cva } from 'class-variance-authority'
import React, { forwardRef, useMemo } from 'react'

import type { ProgressButtonProps } from './ProgressButton.types'
import { PercentSpan } from './subs/PercentSpan'
import { computeInnerWidth } from './utils/computeInnerWidth'

const progressButtonCVA = {
  innerProgress: cva(['absolute left-0 top-0 h-full transition-all'], {
    variants: {
      variant: {
        primary: 'bg-neutral-50/40',
        secondary: 'bg-neutral-900/25',
        tertiary: 'bg-neutral-900/10'
      }
    }
  })
}

export const ProgressButton = createButton<ProgressButtonProps>(
  forwardRef<HTMLButtonElement, ProgressButtonProps>(
    (
      {
        children,
        max = 100,
        value,
        variant = 'primary',
        intent = 'default',
        ...other
      },
      ref
    ) => {
      const innerWidth = useMemo(
        () => computeInnerWidth(value, max),
        [value, max]
      )

      return (
        <Button {...other} ref={ref} variant={variant} intent={intent}>
          <div
            className={progressButtonCVA.innerProgress({ variant })}
            style={{ width: innerWidth }}
          />
          {children || <PercentSpan value={value} />}
        </Button>
      )
    }
  )
)

export type { ProgressButtonProps } from './ProgressButton.types'
