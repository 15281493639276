import { useInitGTM } from '@spa/core/externals/gtm'
import { useInitRecaptcha } from '@spa/core/externals/recaptcha'
import { trackerService } from '@spa/core/externals/segment'

import { GuestAppRoutes } from './GuestApp.routes'

export const GuestApp = () => {
  trackerService.useInitSegment()
  trackerService.useSegmentPageView()
  useInitGTM()
  useInitRecaptcha()

  return <GuestAppRoutes />
}
