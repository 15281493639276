import type {
  AccountRecord,
  AccountStatus,
  MembershipRecord,
  UserRecord
} from '@libs/data-access/entities'
import { billingService } from '@libs/data-access/queries'
import { IS_SEGMENT_AVAILABLE } from '@libs/utils/environments'
import { getStatusString } from '@spa/redux/billing/subscription/utils/getStatusString'
import { isImpersonationContext } from '@spa/scenes/Auth/shared/services/authState/authServiceUtils'
import { useEffect } from 'react'
import { useRoleRights } from 'snap-redux/modules/memberships/utils'

type Params = {
  account?: AccountRecord | null
  membership?: MembershipRecord | null
  user?: UserRecord | null
}

export const useSegmentIdentify = (
  { account, membership, user }: Params,
  onIdentify: () => void
) => {
  const { atLeast, exact } = useRoleRights()

  const {
    data: billingAccountStatusData,
    isLoading: isBillingAccountStatusLoading,
    isError: isBillingAccountStatusError
  } = billingService.account.useGetStatus()

  const shouldIdentify = Boolean(
    IS_SEGMENT_AVAILABLE &&
      window.analytics &&
      !isImpersonationContext() &&
      account &&
      membership &&
      user &&
      ((!isBillingAccountStatusLoading && billingAccountStatusData) ||
        isBillingAccountStatusError)
  )

  const userId = membership?.get('id')
  const userFirstName = membership?.get('firstname')
  const userLastName = membership?.get('lastname')
  const userEmail = user?.get('email')
  const userCreatedAt = user?.get('created_at')
  const accountCountry = account?.get('country')
  const membershipRole = membership?.get('role')
  const membershipCanManagePlanning = membership?.get(
    'can_manage_published_planning'
  )
  const membershipManagerCanEditEmployees = membership?.get(
    'manager_can_edit_employees'
  )
  const accountPayrollSoftwareId = account?.get('payroll_software_id')
  const membershipCanRemoveEmployee = membership?.get('can_remove_employee')
  const isAtLeastManager = atLeast.manager
  const isExactManager = exact.manager
  const isAtLeastDirector = atLeast.director
  const isExactDirector = exact.director
  const isAtLeastOwner = atLeast.owner

  useEffect(() => {
    if (!shouldIdentify) {
      return
    }

    const analyticsData = {
      name: `${userFirstName} ${userLastName}`,
      firstName: userFirstName,
      lastName: userLastName,
      email: userEmail,
      createdAt: userCreatedAt,
      country: accountCountry,
      role: membershipRole,
      schedule_editing_and_shift_approval_permission:
        membershipCanManagePlanning,
      absence_management_permission: isAtLeastManager,
      employee_information_access_permission:
        (isExactManager && membershipManagerCanEditEmployees) ||
        isAtLeastDirector,
      employee_deletion_director_permission:
        (isExactDirector && membershipCanRemoveEmployee) || isAtLeastOwner,
      payroll_software_id: accountPayrollSoftwareId,
      subscription_activated_at: billingAccountStatusData?.activated_at,
      subscription_in_trial: billingAccountStatusData?.is_in_trial,
      subscription_status: getStatusString(
        billingAccountStatusData as AccountStatus
      ),
      subscription_trial_starts_at: billingAccountStatusData?.trial_starts_at,
      subscription_trial_ends_at: billingAccountStatusData?.trial_ends_at
    }

    onIdentify()
    window.analytics.identify(userId, analyticsData)
  }, [
    // Only primitives here for maximum reliability
    accountCountry,
    atLeast.director,
    atLeast.manager,
    atLeast.owner,
    exact.director,
    exact.manager,
    isAtLeastDirector,
    isAtLeastManager,
    isAtLeastOwner,
    isExactDirector,
    isExactManager,
    membershipCanManagePlanning,
    membershipCanRemoveEmployee,
    membershipManagerCanEditEmployees,
    membershipRole,
    shouldIdentify,
    userCreatedAt,
    userEmail,
    userFirstName,
    userId,
    userLastName,
    billingAccountStatusData
  ])
}
