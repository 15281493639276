import { Banner } from '@libs/ui/ds'
import {
  TransApp,
  datesService,
  useCommonAppTranslation
} from '@libs/utils/translations'
import { useMemoSelector } from '@spa/redux/hooks'
import React from 'react'
import { getCurrentAccount } from 'snap-redux/modules/accounts/selectors'

export const TimezoneMismatchBanner = () => {
  const { t } = useCommonAppTranslation()
  const account = useMemoSelector(getCurrentAccount)

  if (datesService.isSameTimezone(account.time_zone)) {
    return null
  }

  return (
    <Banner variant='warning'>
      <TransApp
        t={t}
        values={{ timezone: account.time_zone }}
        i18nKey='banner.timezone.mismatch'
      />
    </Banner>
  )
}
