import * as ga from '@spa/core/externals/ga/trackHistoryChange'
import { createRoot } from 'react-dom/client'

import { AppEntryController } from './AppEntryController'
import { Favicon } from './Metas/Favicon'
import { CommonProviders } from './providers/CommonProviders'

ga.trackHistoryChange()

const container = document.getElementById('root') as Element
const root = createRoot(container)

root.render(
  <CommonProviders>
    <Favicon />
    <AppEntryController />
  </CommonProviders>
)
