import { IS_SEGMENT_AVAILABLE } from '@libs/utils/environments'
import { isImpersonationContext } from '@spa/scenes/Auth/shared/services/authState/authServiceUtils'

import type { EventName, TrackProperties } from './types'

/**
 * Base function to track an analytic event
 */
export const track = (event: EventName, properties?: TrackProperties) => {
  if (!IS_SEGMENT_AVAILABLE || isImpersonationContext()) {
    return
  }

  if (window.analytics?.track) {
    window.analytics.track(event, properties)
  }
}
