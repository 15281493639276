import { Anchor, Flex, Text, Title } from '@libs/ui/ds'
import { useOnboardingTranslation } from '@libs/utils/translations'
import { routes } from '@spa/core/router'
import { Trans } from 'react-i18next'
import { Link, useLocation, useSearchParams } from 'react-router-dom'

import { useConfigurationAuthNavbar } from '../shared/services/authNavbar/AuthNavbarContext'

import { SignInForm } from './SignInForm'

const SignIn = () => {
  const { t } = useOnboardingTranslation()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const defaultEmail = (searchParams?.get?.('email') ||
    location.state?.email) as string | undefined
  const isBack = Boolean(defaultEmail)
  useConfigurationAuthNavbar({
    languages: true,
    cta: {
      sign_up: true
    }
  })

  return (
    <>
      <Flex className='mb-10 gap-2'>
        <Title size={2}>
          {isBack
            ? t('onboarding.signin.index.titleBack')
            : t('onboarding.signin.index.title')}
        </Title>
        <Text className='text-s'>
          <Trans
            t={t}
            i18nKey='onboarding.signin.index.subtitle'
            components={{
              1: (
                <Anchor
                  component={Link}
                  to={routes.signUp.rootPath}
                  variant='primary'
                  className='text-center text-s'
                />
              )
            }}
          />
        </Text>
      </Flex>
      <SignInForm defaultEmail={defaultEmail} />
    </>
  )
}

export default SignIn
