import Immutable from 'immutable'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  CREATE_ATTACHMENT,
  CREATE_ATTACHMENT_SUCCEEDED,
  CREATE_ATTACHMENT_FAILED,
  FETCH_ATTACHMENT,
  FETCH_ATTACHMENT_SUCCEEDED,
  FETCH_ATTACHMENT_FAILURE,
  DELETE_ATTACHMENT,
  DELETE_ATTACHMENT_SUCCEEDED,
  DELETE_ATTACHMENT_FAILED,
  FETCH_MEMBERSHIP_SUCCEEDED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords, updateRecord } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const createAttachment = (attachmentData, resolve, reject) => ({
  type: CREATE_ATTACHMENT,
  attachmentData,
  resolve,
  reject
})
export const fetchAttachment = (attachmentId, resolve, reject) => ({
  type: FETCH_ATTACHMENT,
  attachmentId,
  resolve,
  reject
})
export const deleteAttachment = (attachmentId, resolve, reject) => ({
  type: DELETE_ATTACHMENT,
  attachmentId,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const createAttachmentSagaEntity = {
  success: (data, attachmentData) => ({
    type: CREATE_ATTACHMENT_SUCCEEDED,
    data,
    attachmentData
  }),
  failure: (error, attachmentData) => ({
    type: CREATE_ATTACHMENT_FAILED,
    error,
    attachmentData
  }),

  fetchAPI: (id, options) =>
    callAPI('/attachments', { method: 'POST', ...options })
}

export function* doCreateAttachment({ attachmentData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createAttachmentSagaEntity,
    null,
    null,
    attachmentData,
    function* resolveFunc(...args) {
      if (resolve) {
        yield resolve(...args)
      }
    },
    function* rejectFun(...args) {
      if (reject) {
        yield reject(...args)
      }
    }
  )
}

const fetchAttachmentSagaEntity = {
  success: (data, attachmentId) => ({
    type: FETCH_ATTACHMENT_SUCCEEDED,
    data,
    attachmentId
  }),
  failure: (error, attachmentId) => ({
    type: FETCH_ATTACHMENT_FAILURE,
    error,
    attachmentId
  }),

  fetchAPI: (attachmentId, options) =>
    callAPI(`/attachments/${attachmentId}`, options)
}

export function* doFetchAttachment({ attachmentId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    fetchAttachmentSagaEntity,
    attachmentId,
    null,
    undefined,
    resolve,
    reject
  )
}

const deleteAttachmentSagaEntity = {
  success: (data, attachmentId) => ({
    type: DELETE_ATTACHMENT_SUCCEEDED,
    data,
    attachmentId
  }),
  failure: (error, attachmentId) => ({
    type: DELETE_ATTACHMENT_FAILED,
    error,
    attachmentId
  }),

  fetchAPI: (attachmentId, options) =>
    callAPI(`/attachments/${attachmentId}`, { method: 'DELETE', ...options })
}

export function* doDeleteAttachment({ attachmentId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    deleteAttachmentSagaEntity,
    attachmentId,
    null,
    null,
    function* resolveFunc(...args) {
      if (resolve) {
        yield resolve(...args)
      }
    },
    function* rejectFun(...args) {
      if (reject) {
        yield reject(...args)
      }
    }
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const Attachment = Immutable.Record({
  id: null,
  membership_id: null,
  content_type: null,
  title: null,
  attachment_content_type: null,
  attachment_file_name: null
})

const attachmentForeignKeys = ['membership_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function attachments(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_MEMBERSHIP_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const attachments = Immutable.fromJS(action.data.attachments || [])

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Attachment, attachments, attachmentForeignKeys)
    }
    case CREATE_ATTACHMENT_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const attachment = Immutable.fromJS(action.data)
      // @ts-ignore migration from js(x) to ts(x)
      return updateRecord(state, Attachment, attachment, attachmentForeignKeys)
    }
    case DELETE_ATTACHMENT_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { attachmentId } = action
      const attachment = state.getIn(['data', attachmentId])
      const { membership_id } = attachment

      return state.deleteIn(['data', attachmentId]).updateIn(
        ['relations', 'membership_id', membership_id],
        // @ts-ignore migration from js(x) to ts(x)
        new Immutable.Set(),
        item_ids => item_ids.remove(attachmentId)
      )
    }
    default:
      return state
  }
}

export default filterActions(attachments, [
  FETCH_MEMBERSHIP_SUCCEEDED,
  CREATE_ATTACHMENT_SUCCEEDED,
  DELETE_ATTACHMENT_SUCCEEDED
])
