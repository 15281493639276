import { Checkbox, Flex } from '@libs/ui/ds'

import type { TableProps } from '../base/Table.types'

import { createAppColumnHelper } from './createColumnHelper'

export type concatSelectableColumnParams<T> = {
  selection?: TableProps<T>['selection']
  data?: T[]
}

const concatSelectableColumn = <T extends unknown>({
  selection,
  data
}: concatSelectableColumnParams<T>) => {
  const canBeSelected = Boolean(selection?.onChange && selection?.state)

  if (!canBeSelected || !data?.length) {
    return [] // will concat nothing
  }

  const columnHelper = createAppColumnHelper<T & { _selectColumn: boolean }>()

  return [
    columnHelper.display({
      id: '_selectColumn',
      header: ({ table }) => {
        return (
          <Checkbox
            size='m'
            checked={table.getIsAllPageRowsSelected()}
            onChange={() => {
              table.toggleAllPageRowsSelected(!table.getIsAllPageRowsSelected())
            }}
            indeterminate={table.getIsSomeRowsSelected()}
          />
        )
      },
      cell: ({ row }) => {
        return (
          <Flex className='items-end'>
            <Checkbox
              size='m'
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={() => row.toggleSelected()}
            />
          </Flex>
        )
      },
      enableSorting: false,
      meta: {
        className: 'w-fit h-fit'
      }
    })
  ]
}

export default concatSelectableColumn
