import { Text } from '@libs/ui/ds'
import { FieldError } from '@spa/components/FormFields'
import withController from '@spa/hoc/withController'
import { toKebabCase } from '@spa/services/toKebabCase'
import classnames from 'classnames'
import type { FC, ReactElement } from 'react'
import React from 'react'

import styles from './FieldRadio.module.css'
import { formatOptInputName } from './helper'

type Item = {
  label: string | ReactElement
  value: string
  subLabel?: string
}

type Props = {
  disabled?: boolean
  error?: string
  inline?: boolean
  reverseLine?: boolean
  inputId?: string
  inputName?: string
  onChange?: (val: string) => void
  items: Item[]
  value?: string
}

export const FieldRadio: FC<Props> = ({
  disabled = false,
  error = null,
  inline,
  reverseLine = false,
  inputId,
  onChange,
  inputName,
  items = [],
  value
}) => {
  return (
    <div>
      <div
        className={classnames(styles.Container, { [styles.flexRow]: inline })}
      >
        {items.map(item => (
          <div className={styles.RadioButton} key={item.value}>
            <label
              // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
              htmlFor={formatOptInputName(inputId || inputName, item)}
              className={classnames(styles.Label, {
                [styles.rowLabel]: inline,
                [styles.rowReverse]: reverseLine
              })}
            >
              <input
                name={inputName}
                // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
                id={formatOptInputName(inputId || inputName, item)}
                type='radio'
                // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
                onChange={e => onChange(e.target.value)}
                checked={item.value === value}
                value={item.value}
                disabled={disabled}
                data-testid={`field-radio-${toKebabCase(
                  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
                  inputName
                )}-${toKebabCase(item.value)}`}
              />
              <span
                className={classnames(styles.OptionLabel, {
                  [styles.Disabled]: disabled
                })}
              >
                {item.label}
              </span>
            </label>
            {item.subLabel && (
              <Text size='tiny' className={styles.SubLabel}>
                {item.subLabel}
              </Text>
            )}
          </div>
        ))}
      </div>
      {error && <FieldError error={error} />}
    </div>
  )
}

export const ControlledFieldRadio = withController<Props, string>()(FieldRadio)
