import { groupBy, map } from 'lodash-es'
import type { FC } from 'react'
import React from 'react'

import { Combobox } from '../../../../overlays/Combobox/base/Combobox'
import { ConditionalWrap } from '../../../../utils/ConditionalWrap/base/ConditionalWrap'
import type { SelectProps } from '../Select.types'

type SelectOptionListProps = {
  options: SelectProps['options']
  selectValue: string[]
}

export const SelectOptionList: FC<SelectOptionListProps> = ({
  options,
  selectValue
}) => {
  const groupedOptions = groupBy(options, i => i.group || null)

  if (!options.length) {
    return null
  }

  return (
    <>
      {map(groupedOptions, (group, groupName) => (
        <ConditionalWrap
          key={groupName}
          condition={Boolean(groupName) && groupName !== 'null'}
          wrap={children => (
            <Combobox.Group label={groupName}>{children}</Combobox.Group>
          )}
        >
          <>
            {group.map(option => (
              <Combobox.Option
                selected={selectValue.includes(option.value)}
                key={option.value}
                value={option.value}
                rightSection={option.rightSection}
                disabled={option.disabled}
                leftIcon={option.leftIcon}
                data-testid={`option-${option.value}`}
              >
                {option.optionLabel || option.label}
              </Combobox.Option>
            ))}
          </>
        </ConditionalWrap>
      ))}
    </>
  )
}
