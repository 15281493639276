import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'

import { gapVariants } from '../../utils/properties/gap'
import { switchAtVariants } from '../../utils/properties/switchAt'

import type { StackProps } from './Stack.types'

const stackCVA = cva(['box-border flex'], {
  variants: {
    col: {
      true: ['!flex-col']
    },
    center: {
      true: ['items-center']
    },
    gap: gapVariants,
    switchAt: switchAtVariants
  }
})

export const Stack = forwardRef<HTMLDivElement, StackProps>(
  (
    {
      col = false,
      gap = 0,
      switchAt = 'none',
      center = false,
      className,
      children,
      ...other
    },
    ref
  ) => {
    return (
      <div
        {...other}
        ref={ref}
        className={stackCVA({ col, gap, center, switchAt, className })}
      >
        {children}
      </div>
    )
  }
)

Stack.displayName = 'Stack'
