import type { Bugsnag } from '@bugsnag/js'
import { IS_BUGSNAG_AVAILABLE } from '@libs/utils/environments'

import { errorMonitoringService } from '../errorMonitoringService'

import { getCleanErrorPayload } from './utils'

type NotifyOptions = Omit<Bugsnag.INotifyOpts, 'severity'>
type NotifyErr =
  | Error
  | { errorClass: string; errorMessage: string }
  | { name: string; message: string }
  | string

class ErrorLoggerService {
  private client: Bugsnag.Client

  constructor(client) {
    this.client = client
  }

  info = (err: NotifyErr, opts?: NotifyOptions) => {
    this.notify(err, { ...opts, severity: 'info' })
  }

  warn = (err: NotifyErr, opts?: NotifyOptions) => {
    this.notify(err, { ...opts, severity: 'warning' })
  }

  error = (err: NotifyErr, opts?: NotifyOptions) => {
    this.notify(err, { ...opts, severity: 'error' })
  }

  apiError = (err: NotifyErr & { status: number }) => {
    this.error(err, {
      metaData: {
        status: err?.status,
        type: 'ApiError'
      }
    })
  }

  socketError = (err: NotifyErr) => {
    this.error(err, {
      metaData: {
        type: 'SocketError'
      }
    })
  }

  /**
   * Notify and send event to bugsnag
   */
  private notify(errorPayload: NotifyErr, opts: Bugsnag.INotifyOpts) {
    if (!IS_BUGSNAG_AVAILABLE || !this.client?.notify) {
      return
    }

    try {
      const cleanPayload = getCleanErrorPayload(errorPayload)
      this.client.notify(cleanPayload, opts)
    } catch (e) {
      return e
    }
  }
}

export const errorLoggerService = new ErrorLoggerService(
  errorMonitoringService.client
)
