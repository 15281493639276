import { composeCacheKey } from '@spa/redux/utils/reselect'
import moment from 'moment'
import createCachedSelector from 're-reselect'
import type {
  Rest,
  Shift
} from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import { getPlannableCardsByTeam } from '../getPlannableCardsByTeam/getPlannableCardsByTeam'

export type PlannableCard = Shift | Rest

type Params = {
  teamId: number
  day: string
  includesRests?: boolean
}

const filterCardsInDay = (cards: any[], date: string) => {
  return cards.filter(card =>
    moment(card.starts_at).isBetween(
      moment(date).startOf('day'),
      moment(date).endOf('day'),
      'minutes'
    )
  )
}

export const getPlannableCardsByDayAndTeam = createCachedSelector(
  (state, { teamId }: Params) => getPlannableCardsByTeam(state, { teamId }),
  (_, { includesRests }: Params) => includesRests,
  (_, { day }: Params) => day,
  (plannables, includesRests = true, day) => {
    const restCards = includesRests ? plannables.rests : []
    const cards = [...restCards, ...plannables.shifts]

    return filterCardsInDay(cards, day) as PlannableCard[]
  }
)((_, { teamId, includesRests }: Params) =>
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  composeCacheKey(teamId, includesRests)
)
