import { cva } from 'class-variance-authority'
import type { FC } from 'react'

import { Combobox } from '../../../overlays/Combobox/base/Combobox'

import { MenuProvider } from './Menu.context'
import type { MenuProps } from './Menu.types'
import { MenuGroup } from './compound/MenuGroup/MenuGroup'
import { MenuItem } from './compound/MenuItem/MenuItem'

const menuCVA = {
  root: cva(['w-full'])
}

const BaseMenu: FC<MenuProps> = ({ className, children, ...other }) => {
  return (
    <MenuProvider value={{}}>
      <Combobox>
        <div {...other} className={menuCVA.root({ className })}>
          {children}
        </div>
      </Combobox>
    </MenuProvider>
  )
}

type CompoundMenuType = {
  Group: typeof MenuGroup
  Item: typeof MenuItem
}

const TypedMenu = BaseMenu as typeof BaseMenu & CompoundMenuType
TypedMenu.displayName = 'Menu'

TypedMenu.Group = MenuGroup
TypedMenu.Item = MenuItem
export const Menu = TypedMenu

export type { MenuProps } from './Menu.types'
export type { MenuGroupProps } from './compound/MenuGroup/MenuGroup.types'
export type { MenuItemProps } from './compound/MenuItem/MenuItem.types'
