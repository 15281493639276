import { createSlice } from '@reduxjs/toolkit'

export type MetaState = {
  error: boolean
  success: boolean
  loading: boolean
  fetching: boolean
  updating: boolean
  deleting: boolean
}

const initialState: MetaState = {
  error: false,
  success: false,
  loading: true,
  fetching: false,
  updating: false,
  deleting: false
}

const paramsSlice = createSlice({
  name: 'billing/subscription/meta',
  initialState,
  reducers: {
    setMetaError() {
      return {
        error: true,
        success: false,
        loading: false,
        fetching: false,
        updating: false,
        deleting: false
      }
    },
    setMetaFetching() {
      return {
        error: false,
        success: false,
        loading: false,
        fetching: true,
        updating: false,
        deleting: false
      }
    },
    setMetaSuccess() {
      return {
        error: false,
        success: true,
        loading: false,
        fetching: false,
        updating: false,
        deleting: false
      }
    },
    setMetaLoading() {
      return {
        error: false,
        success: false,
        loading: true,
        fetching: false,
        updating: false,
        deleting: false
      }
    },
    setMetaUpdating() {
      return {
        error: false,
        success: false,
        loading: false,
        fetching: false,
        updating: true,
        deleting: false
      }
    },
    setMetaDeleting() {
      return {
        error: false,
        success: false,
        loading: false,
        fetching: false,
        updating: false,
        deleting: true
      }
    }
  }
})

export const {
  setMetaError,
  setMetaSuccess,
  setMetaLoading,
  setMetaFetching,
  setMetaUpdating,
  setMetaDeleting
} = paramsSlice.actions
export default paramsSlice.reducer
