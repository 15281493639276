import { MARKET_APPLICATION_URL } from '@libs/utils/environments'
import { useMemoSelector } from '@spa/redux/hooks'
import { useMemo } from 'react'
import { getCurrentAccount } from 'snap-redux/modules/accounts/selectors'
import { getCurrentMembershipSelector } from 'snap-redux/modules/memberships/selectors'
import { getCurrentUser } from 'snap-redux/modules/users/selectors'

import useBillingPlanId from './useBillingPlanId'

export type UpgradeUrlFeatureType =
  | 'marketplace'
  | 'hr-dashboard'
  | 'signable-timesheets'
  | 'subscription'
  | 'modulation'
  | 'sms-notification'
  | 'signable-documents'
  | 'documents-models'

const useUpgradeUrl = (feature?: UpgradeUrlFeatureType) => {
  const user = useMemoSelector(getCurrentUser)
  const membership = useMemoSelector(getCurrentMembershipSelector)
  const account = useMemoSelector(getCurrentAccount)

  const planId = useBillingPlanId()

  const url = useMemo(() => {
    let marketPage = null

    switch (feature) {
      case 'subscription':
      case 'documents-models':
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        marketPage = '/app/abonnement?'
        break
      default:
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        marketPage = '/app/people-plan?'
        break
    }

    if (!user) {
      return `${MARKET_APPLICATION_URL}${marketPage}`
    }

    const MARKET_REDIRECT = `${MARKET_APPLICATION_URL}${user.locale.toLowerCase()}${marketPage}`

    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    return `${MARKET_REDIRECT}${new URLSearchParams({
      user_id: user.id?.toString(),
      user_role: membership?.role,
      account_id: account.id?.toString(),
      slug: account.subdomain,
      feature,
      plan: planId,
      return_url: window.location.href
    }).toString()}`
  }, [feature, user, membership?.role, account.id, account.subdomain, planId])

  return { url }
}

export default useUpgradeUrl
