import { ArrowDownIcon, IconWrap, InputWrap, useMergedRef } from '@libs/ui/ds'
import { cva } from 'class-variance-authority'
import { forwardRef, memo, useRef } from 'react'

import type { NativeSelectProps } from './NativeSelect.types'
import { NativeSelectOptions } from './subs/NativeSelectOptions'
import { useNativeSelectValuesUncontrolled } from './utils/useNativeSelectValuesUncontrolled'

const NativeSelectCVA = {
  root: cva(
    [
      'relative block items-center gap-2 rounded border-1 font-sans text-m font-normal'
    ],
    {
      variants: {
        error: {
          true: ['border-semantic-red-400'],
          false: ['border-neutral-400']
        }
      }
    }
  ),
  select: cva([
    'block h-10 w-full max-w-64 cursor-pointer appearance-none text-ellipsis rounded pl-3 pr-9'
  ]),
  icon: cva(
    ['pointer-events-none absolute right-3 top-3 z-[1] cursor-pointer'],
    {
      variants: {
        disabled: { true: ['text-neutral-500'] }
      }
    }
  )
}

export const NativeSelect = memo(
  forwardRef<HTMLSelectElement, NativeSelectProps>(
    (
      {
        className,
        label,
        desc,
        errorMessage,
        helperText,
        required,
        disabled,
        name,
        options,
        value,
        defaultValue,
        fillWidth,
        labelRightSection,
        onChange,
        ...other
      },
      forwardedRef
    ) => {
      const innerRef = useRef<HTMLSelectElement>(null)
      const ref = useMergedRef(forwardedRef, innerRef)

      const { select } = useNativeSelectValuesUncontrolled({
        select: { onChange, defaultValue, value }
      })

      return (
        <InputWrap
          className={className}
          label={label}
          desc={desc}
          errorMessage={errorMessage}
          required={required}
          disabled={disabled}
          htmlFor={name}
          fillWidth={fillWidth}
          helperText={helperText}
          labelRightSection={labelRightSection}
          field={
            <div
              className={NativeSelectCVA.root({ error: Boolean(errorMessage) })}
            >
              <select
                ref={ref}
                disabled={disabled}
                name={name}
                value={select.selectValue}
                onChange={e => select.setSelectValue(e.target.value)}
                className={NativeSelectCVA.select()}
                {...other}
              >
                <NativeSelectOptions options={options} />
              </select>
              <div className={NativeSelectCVA.icon({ disabled })}>
                <IconWrap size='m' icon={<ArrowDownIcon />} />
              </div>
            </div>
          }
        />
      )
    }
  )
)

NativeSelect.displayName = 'NativeSelect'
export type {
  NativeSelectProps,
  NativeSelectOptions
} from './NativeSelect.types'
