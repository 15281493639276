import { monthlyService } from '@libs/data-access/queries'
import {
  getParams,
  weeklyschedulesSelectors
} from '@spa/scenes/Plannings/redux/selectors'
import { select, takeEvery } from 'redux-saga/effects'
import {
  CREATE_NOTE_SUCCEEDED,
  DELETE_NOTE_SUCCEEDED,
  UPDATE_NOTE_SUCCEEDED
} from 'snap-redux/actionTypes'

import { entitiesSagas } from '../entities'

function* noteSync(action: any) {
  const params = yield select(getParams)

  // @todo register module redux on planning v2 scene
  if (!params.date) {
    return
  }

  switch (action.type) {
    case CREATE_NOTE_SUCCEEDED:
    case UPDATE_NOTE_SUCCEEDED: {
      const { weeklyschedule_id } = action.data

      yield entitiesSagas.getWeeklyscheduleById({
        payload: {
          weeklyscheduleId: weeklyschedule_id
        }
      })

      // Invalidate header notes of monthView
      // To keep when we remove redux from note modal
      monthlyService.header.invalidateHeader()

      break
    }

    case DELETE_NOTE_SUCCEEDED: {
      const { noteId } = action

      const weeklyschedules = yield select(state =>
        weeklyschedulesSelectors.getCachedList(state)
      )

      const weeklyschedule: any = weeklyschedules.find(wk =>
        (wk as any).daily_notes.some(note => note.id === noteId)
      )

      yield entitiesSagas.getWeeklyscheduleById({
        payload: {
          weeklyscheduleId: weeklyschedule.id
        }
      })

      // Invalidate header notes of monthView
      // To keep when we remove redux from note modal
      monthlyService.header.invalidateHeader()

      break
    }

    default:
      break
  }
}

export default function* noteWatcher() {
  yield takeEvery(
    [CREATE_NOTE_SUCCEEDED, UPDATE_NOTE_SUCCEEDED, DELETE_NOTE_SUCCEEDED],
    noteSync
  )
}
