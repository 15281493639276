import { SelectGroup } from '@libs/ui/ds'
import { ControllerBase } from '@libs/ui/form'
import type { FieldValues, FieldPath } from 'react-hook-form'

import type { ControlledSelectGroupProps } from './ControlledSelectGroup.types'

export const ControlledSelectGroup = <
  TFieldValues extends FieldValues = FieldValues,
  TInputName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  onChangeSideEffect,
  rules,
  ...selectInputProps
}: ControlledSelectGroupProps<TFieldValues, TInputName>) => (
  <ControllerBase
    rules={rules}
    control={control}
    field={<SelectGroup {...selectInputProps} />}
    name={name}
    onChangeAdapter={e => e}
    onChangeSideEffect={onChangeSideEffect}
  />
)
