import moment from 'moment'
import { put, select } from 'redux-saga/effects'
import { createRest } from 'snap-redux/modules/rests'

import { restsSelectors, rowsSelectors } from '../../../selectors'
import type { PayloadActionWithoutType } from '../../types/types'

type Params = PayloadActionWithoutType<{
  to: {
    contractId: number
    date: 'YYYY-MM-DD' | string
  }
}>

function* createWeeklyRest({ payload }: Params): any {
  const { date, contractId } = payload.to

  const isAllowed = yield select(state => {
    const row = rowsSelectors.getCachedById(state, contractId)

    if (!row) {
      return false
    }

    // If there is no rest for that contract, the creation is allowed
    if (!row.rests.length) {
      return true
    }

    const rests = restsSelectors.getCachedMap(state) || {}

    return !!row.rests.some(restId => {
      if (!rests[restId]) {
        return false
      }

      const startDate = moment(rests[restId].starts_at).format('YYYY-MM-DD')
      return startDate !== date
    })
  })

  // if weekly_rest is already present we do nothing
  if (!isAllowed) {
    return
  }

  const restPayload = {
    user_contract_id: contractId,
    rest_type: 'weekly_rest',
    starts_at: moment(date).format(),
    ends_at: moment(date).add(1, 'day').format()
  }

  // @ts-ignore migration from js(x) to ts(x)
  yield put(createRest(restPayload))
}

export default createWeeklyRest
