import type { UserSchema } from '@libs/data-access/entities'
import type { UseAppQueryOptions } from '@libs/data-access/queries'
import { useQuery } from '@tanstack/react-query'

import type { FetchUserOptions } from '../user.api'
import { fetchUserById } from '../user.api'
import { userQueryKeys } from '../user.cacheKeys'

export const useFetchOne = (
  request: FetchUserOptions,
  queryOptions?: UseAppQueryOptions<UserSchema>
) => {
  return useQuery({
    ...queryOptions,
    queryKey: userQueryKeys.one(Number(request?.pathParams.user_id)),
    queryFn: () => fetchUserById(request)
  })
}
