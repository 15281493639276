import type { FC } from 'react'
import React from 'react'
import { useRoleRights } from 'snap-redux/modules/memberships/utils'

import { BlockedBanner } from './BlockedBanner/BlockedBanner'
import { ImpersonationBanner } from './ImpersonationBanner/ImpersonationBanner'
import { MaintenanceBanner } from './MaintenanceBanner/MaintenanceBanner'
import { SubscriptionBanners } from './SubscriptionBanners/SubscriptionBanners'
import { TimezoneMismatchBanner } from './TimezoneMismatchBanner/TimezoneMismatchBanner'

type Props = {
  isBillingError?: boolean
}

export const Banners: FC<Props> = ({ isBillingError }) => {
  const { atLeast } = useRoleRights()

  return (
    <>
      <ImpersonationBanner />
      <MaintenanceBanner />
      <TimezoneMismatchBanner />
      <BlockedBanner />
      {atLeast.owner && <SubscriptionBanners isBillingError={isBillingError} />}
    </>
  )
}
