import { useMergedRef } from '@libs/ui/ds'
import { useCombobox } from '@mantine/core'
import { forwardRef, memo, useRef } from 'react'

import { Combobox } from '../../../../overlays/Combobox/base/Combobox'
import { SelectCreateOption } from '../../base/subs/SelectCreateOption'
import { SelectEmpty } from '../../base/subs/SelectEmpty'
import { SelectFooter } from '../../base/subs/SelectFooter'
import { SelectOptionList } from '../../base/subs/SelectOptionList'
import { SelectSearchInput } from '../../base/subs/SelectSearchInput'
import { filterSelectOptions } from '../../base/utils/filterSelectOptions'

import type { MultiSelectProps } from './MultiSelect.types'
import { ChipList } from './subs/ChipList/ChipList'
import { SelectAllOption } from './subs/SelectAllOption/SelectAllOption'
import { useMultiSelectValuesUncontrolled } from './utils/useMultiSelectValuesUncontrolled'

const multiSelectCVA = {
  inputClassNames: {
    root: 'max-h-28 !h-fit',
    inner: 'flex-col items-stretch'
  }
}

export const MultiSelect = memo(
  forwardRef<HTMLInputElement, MultiSelectProps>(
    (
      {
        /**
         * InputWrap props
         */
        label,
        desc,
        errorMessage,
        helperText,
        required,
        fillWidth,
        htmlFor,
        placeholder,

        /**
         *
         */
        options = [],
        disabled,
        loading,
        dropdownPosition = 'bottom-start',
        searchAdapter = 'startingWith',
        emptyListLabel,
        closeOnOptionClick = false,
        onChange,
        onSearchChange,
        searchValue,
        dropdownHeight = 'm',
        dropdownWidth = 'm',
        searchable = false,
        clearable = false,
        onCreate,
        defaultValue,
        value,
        'data-testid': dataTestId,
        /**
         * multi-select props
         */
        selectAll = true,
        maxSelected = Infinity,
        footer,
        ...other
      },
      forwardedRef
    ) => {
      const innerRef = useRef<HTMLInputElement>(null)
      const ref = useMergedRef(forwardedRef, innerRef)
      const store = useCombobox()

      const { select, search, selectedOptions } =
        useMultiSelectValuesUncontrolled({
          select: { onChange, defaultValue, value },
          search: { onSearchChange, searchValue },
          options,
          maxSelected
        })

      const filteredOptions = filterSelectOptions({
        options,
        searchAdapter,
        searchValue: search.searchValue
      })

      return (
        <Combobox
          {...other}
          closeOnOptionClick={closeOnOptionClick}
          store={store}
          loading={loading}
          dropdownPosition={dropdownPosition}
          dropdownHeight={dropdownHeight}
          dropdownWidth={dropdownWidth}
          onOpen={() => innerRef?.current?.focus()}
          onOptionSubmit={val => {
            if (!val) {
              return null
            }

            select.toggleSelectValue(val)
            if (closeOnOptionClick) {
              store.closeDropdown()
            }
          }}
        >
          <Combobox.Target>
            <SelectSearchInput
              inputRef={ref}
              data-testid={dataTestId}
              /**
               * InputWrap props
               */
              label={label}
              desc={desc}
              errorMessage={errorMessage}
              helperText={helperText}
              required={required}
              fillWidth={fillWidth}
              htmlFor={htmlFor}
              clearable={clearable}
              classNames={multiSelectCVA.inputClassNames}
              placeholder={placeholder}
              disabled={disabled}
              /**
               * Multi-select props
               */
              prefix={
                selectedOptions.length ? (
                  <ChipList
                    disabled={disabled}
                    removeSelectValue={select.toggleSelectValue}
                    selectedOptions={selectedOptions}
                  />
                ) : null
              }
              /**
               * State props
               */
              store={store}
              setSearchValue={search.setSearchValue}
              resetSearchValue={search.resetSearchValue}
              resetSelectValue={select.resetSelectValue}
              value={search.searchValue}
              searchable={searchable}
            />
          </Combobox.Target>

          <Combobox.Dropdown>
            <SelectEmpty
              enabled={!onCreate}
              options={filteredOptions}
              emptyListLabel={emptyListLabel}
            />
            <SelectCreateOption
              searchValue={search.searchValue}
              onCreate={onCreate}
              resetSearchValue={search.resetSearchValue}
            />
            <SelectAllOption
              maxSelected={maxSelected}
              selectValue={select.selectValue}
              setSelectValue={select.setSelectValue}
              options={filteredOptions}
              enabled={selectAll}
            />
            <SelectOptionList
              selectValue={select.selectValue}
              options={filteredOptions}
            />
            <SelectFooter footer={footer} />
          </Combobox.Dropdown>
        </Combobox>
      )
    }
  )
)

MultiSelect.displayName = 'Select'
