import { IS_SEGMENT_AVAILABLE } from '@libs/utils/environments'

// Official SEGMENT documentation:
// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page

export const page = (name: string, category = 'page') => {
  if (!IS_SEGMENT_AVAILABLE || !window.analytics) {
    return
  }

  window.analytics.page(category, name)
}
