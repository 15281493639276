import { mapValues } from 'lodash-es'
import { useEffect } from 'react'

import { coreWSService } from './coreWS.service'
import type { CoreWSChannelName } from './coreWS.types'

type SubscribePayloadAdditionalOptions = Record<string, any>
type SubscribePayloadType<T> = {
  channel: CoreWSChannelName
} & T

type EventHandler<T> = (data: T) => void

type SubscriptionListener<T> = {
  type: string
  handler: EventHandler<T>
}

type MessageListeners<T> = {
  [K in keyof T]: SubscriptionListener<T[K]>
}

type MessageMutations = Record<string, Function>

class CoreWSSubscriptionFactory {
  /**
   * Factory that returns a subscription hook to subscribe to a
   * WebSocket channel and listen to specific messages event.
   */
  create<
    T,
    SubscribePayload extends SubscribePayloadAdditionalOptions,
    Mutations extends MessageMutations,
    Messages = {
      [K in keyof T]: EventHandler<T[K]>
    }
  >({
    subscribePayload,
    messages,
    emit
  }: {
    subscribePayload: (
      opts: SubscribePayload
    ) => SubscribePayloadType<SubscribePayload>
    messages?: MessageListeners<T>
    emit?: Mutations
  }) {
    return (
      opts?: {
        enabled?: boolean
        subscribePayload?: SubscribePayload
        messages: Partial<Messages>
      },
      dependencies = []
    ) => {
      const enabled = opts?.enabled ?? true
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      this.handleSubscribe(subscribePayload(opts?.subscribePayload), enabled)

      useEffect(() => {
        if (!opts?.messages || !enabled) {
          return
        }

        const { unsubscribe } = coreWSService.onMessage(message => {
          mapValues(messages, (listener, key) => {
            if (listener.type === message?.type) {
              opts.messages[key](message)
            }
          })
        })

        /**
         * Require to remove event listener from the WebSocket client
         * Otherwise, it will keep listening to the event even if the component is unmounted.
         */
        return () => {
          unsubscribe()
        }
      }, [...dependencies, enabled])

      return { emit }
    }
  }

  /**
   * Subscribe/unsubscribe to a WebSocket channel on mount/unmount.
   */
  handleSubscribe(subscribePayload, enabled: boolean) {
    /* eslint-disable react-hooks/rules-of-hooks */
    useEffect(() => {
      if (!enabled) {
        return
      }

      coreWSService.subscribe(subscribePayload)
      return () => {
        coreWSService.unsubscribe(subscribePayload)
      }
    }, [enabled, JSON.stringify(subscribePayload)])
  }

  /**
   * Helper to defines a typesafe message handler for a specific WebSocket message type.
   */
  defineMessageHandler<T extends { type?: string }>(opts: {
    type: T['type']
  }): SubscriptionListener<T> {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    return { type: opts.type, handler: (_: T) => {} }
  }
}

export const coreWSSubscriptionFactory = new CoreWSSubscriptionFactory()
