import {
  SSRContractMissingAttributesArray,
  SSRFormattedTutorMissingAttributesArray,
  SSRTutorLocationMissingAttributesArray,
  SSRUserMissingAttributesArray
} from '@libs/data-access/entities'

const isValueInSSR = (nameInput: string | null) => {
  if (!nameInput) {
    return false
  }

  return [
    SSRContractMissingAttributesArray,
    SSRUserMissingAttributesArray,
    SSRTutorLocationMissingAttributesArray,
    SSRFormattedTutorMissingAttributesArray
  ].some(attributes =>
    (attributes as ReadonlyArray<string>).includes(nameInput)
  )
}

export default isValueInSSR
