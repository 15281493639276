import { useDisableAccountAddon } from './AccountAddon/hooks/useDisableAccountAddon'
import { useEnableAccountAddon } from './AccountAddon/hooks/useEnableAccountAddon'
import { useFetchAccountAddon } from './AccountAddon/hooks/useFetchAccountAddon'
import { invalidateAccountBillingInfoCache } from './AccountBillingInfo/accountBillingInfo.cacheKeys'
import { useGetAccountBillingInfo } from './AccountBillingInfo/hooks/useGetAccountBillingInfo'
import { useAccountChurnLocationBased } from './AccountChurn/LocationBased/hooks/useAccountChurnLocationBased'
import { invalidateAccountDetailsCache } from './AccountDetails/accountDetails.cacheKeys'
import { useGetAccountDetails } from './AccountDetails/hooks/useAccountDetails'
import { useGetAccountDetailsDerivedState } from './AccountDetails/hooks/useAccountDetailsDerivedState'
import { invalidateAccountIdNotPaidInvoices } from './AccountInvoices/accountInvoices.cacheKeys'
import { getAccountInvoice } from './AccountInvoices/fetchs/getAccountInvoice'
import { useAccountBasedGetAccountInvoices } from './AccountInvoices/hooks/useAccountBasedGetAccountInvoices'
import { useAccountBasedLastInvoice } from './AccountInvoices/hooks/useAccountBasedLastInvoice'
import { useAccountInvoiceCollectNow } from './AccountInvoices/hooks/useAccountInvoiceCollectNow'
import { useGetAccountInvoices } from './AccountInvoices/hooks/useGetAccountInvoices'
import { useGetAccountNotPaidInvoices } from './AccountInvoices/hooks/useGetAccountNotPaidInvoices'
import { useGetAccountPayConnector } from './AccountPayConnector/hooks/useGetAccountPayConnector'
import { useAccountReactivateLocationBased } from './AccountReactivate/LocationBased/hooks/useAccountReactivateLocationBased'
import { invalidateAccountStatusCache } from './AccountStatus/accountStatus.cacheKeys'
import { useGetAccountStatus } from './AccountStatus/hooks/useGetAccountStatus'
import { useCreateLocationGroupRecipientEmail } from './LocationGroupBillingInfo/hooks/useCreateLocationGroupRecipientEmail'
import { useDeleteLocationGroupRecipientEmail } from './LocationGroupBillingInfo/hooks/useDeleteLocationGroupRecipientEmail'
import { useLinkLocationToGroup } from './LocationGroupBillingInfo/hooks/useLinkLocationToGroup'
import { useGetLocationGroupBillingInfo } from './LocationGroupBillingInfo/hooks/useLocationGroupBillingInfo'
import { useUpdateLocationGroupBillingInfo } from './LocationGroupBillingInfo/hooks/useUpdateLocationGroupBillingInfo'
import { useUpdateLocationGroupCustomerEmail } from './LocationGroupBillingInfo/hooks/useUpdateLocationGroupEmail'
import { useUpdateLocationGroupRecipientEmail } from './LocationGroupBillingInfo/hooks/useUpdateLocationGroupRecipientEmail'
import { useCreateLocationGroupDraft } from './LocationGroupDraft/hooks/useCreateLocationGroupDraft'
import { useDeleteLocationGroupDraft } from './LocationGroupDraft/hooks/useDeleteLocationGroupDraft'
import { useGetLocationGroupEstimates } from './LocationGroupEstimates/hooks/useLocationGroupEstimates'
import { useBankPaymentLocationGroup } from './LocationGroupPayment/hooks/useBankPaymentLocationGroup'
import { useCardPaymentLocationGroup } from './LocationGroupPayment/hooks/useCardPaymentLocationGroup'
import { useCompleteBankPaymentLocationGroup } from './LocationGroupPayment/hooks/useCompleteBankPaymentLocationGroup'
import { useSetAccountCard } from './Payment/Card/AccountBased/hooks/useCompleteAccountDirectDebit'
import { useCompleteAccountDirectDebit } from './Payment/DirectDebit/AccountBased/hooks/useCompleteAccountDirectDebit'
import { useInitiateAccountDirectDebit } from './Payment/DirectDebit/AccountBased/hooks/useInitiateAccountDirectDebit'

class BillingService {
  accountAddon = {
    useFetchOne: useFetchAccountAddon,
    useDisableOne: useDisableAccountAddon,
    useEnableOne: useEnableAccountAddon
  }

  account = {
    /**
     * [Universal] Fetch account details
     */
    useGetDetails: useGetAccountDetails,
    useGetDetailsDerivedState: useGetAccountDetailsDerivedState,
    /**
     * [Universal] Fetch account status
     */
    useGetStatus: useGetAccountStatus,
    /**
     * [Account-based] Fetch account billing info
     */
    useGetBillingInfo: useGetAccountBillingInfo,
    /**
     * [Account-based] Fetch account pay connector
     */
    useGetPayConnector: useGetAccountPayConnector,
    /**
     * [Account-based] Initiate direct debit setup for the account
     */
    useInitiateDirectDebit: useInitiateAccountDirectDebit,
    /**
     * [Account-based] Complete direct debit setup for the account
     */
    useCompleteDirectDebit: useCompleteAccountDirectDebit,
    /**
     * [Account-based] Set payment card for the account
     */
    useSetCard: useSetAccountCard,
    /**
     * [Location-based] Fetch account invoices
     */
    useGetInvoices: useGetAccountInvoices,
    /**
     * [Location-based] Fetch account not paid invoices
     */
    useGetNotPaidInvoices: useGetAccountNotPaidInvoices,
    /**
     * [Account-based] Fetch account invoices
     */
    useAccountBasedGetInvoices: useAccountBasedGetAccountInvoices,
    /**
     * [Account-based] Get last invoice
     */
    useLastInvoice: useAccountBasedLastInvoice,
    /**
     * [Universal] Invalidate billing account queries
     */
    invalidate: {
      billingInfoQuery: invalidateAccountBillingInfoCache,
      statusQuery: invalidateAccountStatusCache,
      accountDetails: invalidateAccountDetailsCache,
      notPaidInvoices: invalidateAccountIdNotPaidInvoices
    }
  }

  locationGroup = {
    useLinkLocationToGroup,
    /**
     * [Location-based] Fetch one estimate
     */
    useGetEstimate: useGetLocationGroupEstimates,
    /**
     * [Location-based] Fetch one invoice
     */
    getAccountInvoiceById: getAccountInvoice,
    /**
     * [Location-based] Fetch hosted page for collect now for not paid invoices
     */
    useAccountInvoiceCollectNowById: useAccountInvoiceCollectNow,
    /**
     * [Location-based] Fetch one billingInfo
     */
    useGetBillingInfoById: useGetLocationGroupBillingInfo,
    /**
     * [Location-based] Update location group email
     */
    useUpdateCustomerEmail: useUpdateLocationGroupCustomerEmail,
    /**
     * [Location-based] Delete location group email recipient
     */
    useDeleteRecipientEmail: useDeleteLocationGroupRecipientEmail,
    /**
     * [Location-based] Update location group email recipient
     */
    useUpdateRecipientEmail: useUpdateLocationGroupRecipientEmail,
    /**
     * [Location-based] Create location group email recipient
     */
    useCreateRecipientEmail: useCreateLocationGroupRecipientEmail,
    /**
     * [Location-based] Put one billing_information
     */
    useUpdateBillingInfo: useUpdateLocationGroupBillingInfo,
    /**
     * Churn
     */
    usePostChurn: useAccountChurnLocationBased,
    /**
     * Bank payment with GoCardless
     */
    useBankPayment: useBankPaymentLocationGroup,
    useCompleteBankPayment: useCompleteBankPaymentLocationGroup,
    /**
     * Payment by credit Card with stripe
     */
    useCardPayment: useCardPaymentLocationGroup,
    /**
     * Reactivate
     */
    usePostReactivate: useAccountReactivateLocationBased,
    /**
     * Create draft
     */
    useCreateDraft: useCreateLocationGroupDraft,
    /**
     * Delete draft
     */
    useDeleteDraft: useDeleteLocationGroupDraft
  }
}

export const billingService = new BillingService()
export type { AccountDetailsWithDerivedState } from './AccountDetails/hooks/useAccountDetailsDerivedState'
