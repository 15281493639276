import type { FC } from 'react'

import { Combobox } from '../../../Combobox/base/Combobox'
import type { DropdownMenuOptionProps } from '../DropdownMenu.types'

export const DropdownMenuOption: FC<DropdownMenuOptionProps> = ({
  children,
  ...other
}) => {
  return (
    <Combobox.Option {...other} value=''>
      {children}
    </Combobox.Option>
  )
}
