import type { FormatterParams } from '../../types'

/**
 * Display only the ISO currency symbol
 * Let Intl do the job since it's more reliable
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 */
export const isoCurrencySymbol = {
  format: ({ value, lng }: FormatterParams<string>) => {
    const isoCurrencySymbol = Intl.NumberFormat(lng, {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      currency: value
    })
      .formatToParts(0)
      .filter(part => part.type === 'currency')
      .map(part => part.value)
      .join('')

    return isoCurrencySymbol
  }
}
