import type { AccountAddon } from '@libs/data-access/entities'
import { useMutation } from '@tanstack/react-query'

import type { ApiErrorResponse, UseAppMutationOptions } from '../../../../types'
import { userService } from '../../../User'
import {
  type DisableAccountAddonOptions,
  disableAccountAddon
} from '../accountAddon.api'
import { invalidateAddonCache } from '../addons.cacheKeys'

export const useDisableAccountAddon = (
  mutationOpts?: UseAppMutationOptions<
    AccountAddon,
    ApiErrorResponse,
    DisableAccountAddonOptions
  >
) => {
  return useMutation<
    AccountAddon,
    ApiErrorResponse,
    DisableAccountAddonOptions
  >({
    ...mutationOpts,
    mutationFn: (opts: DisableAccountAddonOptions) => disableAccountAddon(opts),
    onSuccess: (data, variables, ...args) => {
      mutationOpts?.onSuccess?.(data, variables, ...args)
      invalidateAddonCache(variables.pathParams.addon_enum)
      userService.user.invalidateCurrent()
    }
  })
}
