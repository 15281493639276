import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_COLLECTIVE_AGREEMENT,
  FETCH_COLLECTIVE_AGREEMENT_SUCCEEDED,
  FETCH_COLLECTIVE_AGREEMENT_FAILED,
  FETCH_COLLECTIVE_AGREEMENTS,
  FETCH_COLLECTIVE_AGREEMENTS_SUCCEEDED,
  FETCH_COLLECTIVE_AGREEMENTS_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { updateRecord, mergeRecords } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchCollectiveAgreement = (
  collectiveAgreementId,
  resolve,
  reject
) => ({
  type: FETCH_COLLECTIVE_AGREEMENT,
  collectiveAgreementId,
  resolve,
  reject
})

export const fetchCollectiveAgreements = params => ({
  type: FETCH_COLLECTIVE_AGREEMENTS,
  params
})

// ------------------------------------
// Sagas
// ------------------------------------

const collectiveAgreementSagaEntity = {
  success: (data, collectiveAgreementId) => ({
    type: FETCH_COLLECTIVE_AGREEMENT_SUCCEEDED,
    data,
    collectiveAgreementId
  }),
  failure: (error, collectiveAgreementId) => ({
    type: FETCH_COLLECTIVE_AGREEMENT_FAILED,
    error,
    collectiveAgreementId
  }),
  fetchAPI: (collectiveAgreementId, options) =>
    callAPI(`/collective_agreements/${collectiveAgreementId}`, options)
}

export function* doFetchCollectiveAgreement({
  collectiveAgreementId,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    collectiveAgreementSagaEntity,
    collectiveAgreementId,
    null,
    undefined,
    resolve,
    reject
  )
}

const collectiveAgreementsSagaEntity = {
  success: data => ({ type: FETCH_COLLECTIVE_AGREEMENTS_SUCCEEDED, data }),
  failure: error => ({ type: FETCH_COLLECTIVE_AGREEMENTS_FAILED, error }),

  fetchLastUpdate: state =>
    state.collectiveAgreements.getIn(['meta', 'updated_at']),

  fetchAPI: (id, options, params) =>
    callAPI(`/collective_agreements?${queryString.stringify(params)}`, {
      method: 'GET',
      ...options
    })
}

export function* doFetchCollectiveAgreements({ params }) {
  // @ts-ignore migration from js(x) to ts(x)
  yield call(fetchSagaEntity, collectiveAgreementsSagaEntity, null, params)
}

// ------------------------------------
// Models
// ------------------------------------

export const CollectiveAgreement = Immutable.Record({
  id: null,
  name: null,
  requested_country: null,
  agreement_country: null,
  minimum_consecutive_rest_hours: null,
  maximum_weekly_worked_hours: null,
  maximum_consecutive_worked_days: null,
  maximum_daily_worked_hours: null,
  public_holiday_markup: null,
  maximum_consecutive_worked_hours_without_break: null,
  night_time_rules: null,
  overtime_rules: null,
  complementary_time_rules: null,
  associated_ape_codes: null,
  seniority_grants_holidays_in_months: null,
  ape_codes: Immutable.List(),
  modulation_default_values: Immutable.Map(),
  sunday_time_rule: Immutable.Map(),
  echelon_type: null,
  years_between_medical_visits: null,
  full_time_threshold: null
})

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false, updated_at: null }),
  relations: Immutable.Map()
})

function collectiveAgreements(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_COLLECTIVE_AGREEMENT:
    case FETCH_COLLECTIVE_AGREEMENTS: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_COLLECTIVE_AGREEMENT_FAILED:
    case FETCH_COLLECTIVE_AGREEMENTS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_COLLECTIVE_AGREEMENT_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const collectiveAgreement = Immutable.fromJS(action.data)

      return updateRecord(state, CollectiveAgreement, collectiveAgreement)
    }
    case FETCH_COLLECTIVE_AGREEMENTS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const collectiveAgreements = Immutable.fromJS(action.data)

      return mergeRecords(
        state,
        CollectiveAgreement,
        collectiveAgreements
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    default:
      return state
  }
}

export default filterActions(collectiveAgreements, [
  FETCH_COLLECTIVE_AGREEMENT,
  FETCH_COLLECTIVE_AGREEMENT_FAILED,
  FETCH_COLLECTIVE_AGREEMENT_SUCCEEDED,
  FETCH_COLLECTIVE_AGREEMENTS,
  FETCH_COLLECTIVE_AGREEMENTS_FAILED,
  FETCH_COLLECTIVE_AGREEMENTS_SUCCEEDED
])
