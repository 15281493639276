import type { ReduxState } from '@spa/redux/ReduxState'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'

import { rowsSelectors } from '../../rows'
import { restsBaseSelectors } from '../restsBaseSelectors'

export const getRestsByContract = createCachedSelector(
  (state: ReduxState, { contractId }: { contractId: number }) =>
    rowsSelectors.getCachedById(state, contractId),

  restsBaseSelectors.getCachedMap,
  (row, rests) => {
    const restList = (row?.rests || []).map(id => rests[id])
    return restList
  }
)((_, { contractId }) => fallbackToDefaultCacheKey(contractId))
