const bucketUrl = 'https://storage.googleapis.com/prod-assets-app-combo'

export const imagesUrls = {
  illustrations: {
    beta: `${bucketUrl}/images/illustrations/beta.svg`,
    book: `${bucketUrl}/images/illustrations/book.svg`,
    buoy: `${bucketUrl}/images/illustrations/buoy.svg`,
    call: `${bucketUrl}/images/illustrations/call.svg`,
    cogwheel: `${bucketUrl}/images/illustrations/cogwheel.svg`,
    conversation: `${bucketUrl}/images/illustrations/conversation.svg`,
    crown: `${bucketUrl}/images/illustrations/crown.svg`,
    customerHappy: `${bucketUrl}/images/illustrations/customer-happy.svg`,
    datas: `${bucketUrl}/images/illustrations/datas.svg`,
    employee: `${bucketUrl}/images/illustrations/employee.svg`,
    esignature: `${bucketUrl}/images/illustrations/esignature.svg`,
    file: `${bucketUrl}/images/illustrations/file.svg`,
    fileDownload: `${bucketUrl}/images/illustrations/file-download.svg`,
    highlight: `${bucketUrl}/images/illustrations/highlight.svg`,
    importedFile: `${bucketUrl}/images/illustrations/imported-file.svg`,
    invoice: `${bucketUrl}/images/illustrations/invoice.svg`,
    magic: `${bucketUrl}/images/illustrations/magic.svg`,
    paiement: `${bucketUrl}/images/illustrations/paiement.svg`,
    peoplePlan: `${bucketUrl}/images/illustrations/people-plan.svg`,
    planning: `${bucketUrl}/images/illustrations/planning.svg`,
    promoteSilae: `${bucketUrl}/images/illustrations/promote-silae.svg`,
    search: `${bucketUrl}/images/illustrations/search.svg`,
    settings: `${bucketUrl}/images/illustrations/settings.svg`,
    shiny: `${bucketUrl}/images/illustrations/shiny.svg`,
    shinyBold: `${bucketUrl}/images/illustrations/shiny-bold.svg`,
    smsNotification: `${bucketUrl}/images/illustrations/sms-notification.svg`,
    sun: `${bucketUrl}/images/illustrations/sun.svg`,
    syncSilae: `${bucketUrl}/images/illustrations/sync-silae.svg`,
    promotionComboPay: `${bucketUrl}/images/illustrations/promotion-combo-pay.svg`
  },
  logo: {
    favicon: type => `${bucketUrl}/images/logo/favicons/favicon-${type}.ico`,
    company: {
      comboLogoBlack: `${bucketUrl}/images/logo/company/Logo-combo-black.png`
    },
    appleCalendar: `${bucketUrl}/images/logo/apple-calendar-logo-64x64.png`,
    googleCalendar: `${bucketUrl}/images/logo/google-calendar-logo-64x64.png`,
    outlookCalendar: `${bucketUrl}/images/logo/outlook-calendar-logo-64x64.png`,
    logoSilae: `${bucketUrl}/images/logo/logo-silae.svg`,
    logoComboWhite: `${bucketUrl}/images/logo/logo-combo-white.svg`,
    payment_method: {
      american_express: `${bucketUrl}/images/logo/payment_method/card-american-express.svg`,
      mastercard: `${bucketUrl}/images/logo/payment_method/card-mastercard.svg`,
      visa: `${bucketUrl}/images/logo/payment_method/card-visa.svg`,
      other_credit_card: `${bucketUrl}/images/logo/payment_method/other-credit-card.svg`,
      sepa: `${bucketUrl}/images/logo/payment_method/sepa.svg`
    }
  },
  marketing: {
    payslip:
      'https://storage.googleapis.com/prod-assets-app-combo/images/billing/payslip-hero-section-image.png'
  }
} as const
