import type { PayPeriodModel } from '@spa/scenes/Plannings/redux/models/PayPeriod'
import { keyBy, mapValues } from 'lodash-es'
import moment from 'moment'

const filterPayPeriodLocked = (payPeriods: PayPeriodModel[]) => {
  return payPeriods.filter(i => !!i.locked_at)
}

const isPayPeriodLocked = (payPeriods: PayPeriodModel[], day: string) => {
  return !!payPeriods.find(i => {
    return moment(day).isBetween(i.start_date, i.end_date, undefined, '[]')
  })
}

export const computeLockedDays = (
  payPeriods: PayPeriodModel[],
  isoDays: string[]
) => {
  const isoDaysAsKeys = keyBy(isoDays)
  const lockedPayPeriods = filterPayPeriodLocked(payPeriods)

  return mapValues(isoDaysAsKeys, day => {
    return {
      isLocked: isPayPeriodLocked(lockedPayPeriods, day)
    }
  })
}
