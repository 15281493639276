import { useMergedScreenProps } from '@libs/ui/ds/lib/theme/screens/useMergedScreenProps/useMergedScreenProps'
import { cva } from 'class-variance-authority'
import { forwardRef } from 'react'

import { gapPaddingAdapter } from '../../utils/gapVariantAdapter'
import { useGridContext } from '../Grid.Context'
import type { AdaptiveGridSpan, GridItemProps } from '../Grid.types'

import {
  computeColumnWidthStyles,
  computeFlexOrderStyles,
  computeOffsetStyles
} from './utils'

const gridItemCVA = {
  container: cva(['box-border'], {
    variants: {
      grow: {
        true: 'flex-grow',
        false: 'flex-grow-0'
      },
      span: {
        content: 'w-auto flex-shrink-0 flex-grow-0 basis-auto',
        auto: 'flex-shrink-0 flex-grow basis-0'
      }
    }
  }),
  content: cva([], {
    variants: {
      gap: gapPaddingAdapter
    }
  })
}

export const GridItem = forwardRef<HTMLDivElement, GridItemProps>(
  ({ children, span, screens, order, offset, className }, ref) => {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    const { columns, gap, grow } = useGridContext()

    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    const mergedProps = useMergedScreenProps({ span, order, offset }, screens)

    return (
      <div
        ref={ref}
        style={{
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          ...computeFlexOrderStyles(mergedProps.order),
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          ...computeOffsetStyles(mergedProps.offset, columns),
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          ...computeColumnWidthStyles(mergedProps.span, columns)
        }}
        className={gridItemCVA.container({
          grow,
          span: mergedProps.span as AdaptiveGridSpan
        })}
      >
        <div className={gridItemCVA.content({ className, gap })}>
          {children}
        </div>
      </div>
    )
  }
)
