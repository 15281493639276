import type { AccountStatus } from '@libs/data-access/entities'
import type { UseAppQueryOptions } from '@libs/data-access/queries'
import { queryClientOptions } from '@libs/data-access/queries'
import { useQuery } from '@tanstack/react-query'

import { fetchAccountStatus } from '../accountStatus.api'
import { accountStatusCacheKeys } from '../accountStatus.cacheKeys'

export const useGetAccountStatus = (
  queryOptions?: UseAppQueryOptions<AccountStatus>
) => {
  return useQuery({
    ...queryOptions,
    queryKey: accountStatusCacheKeys.getByAccountId(),
    queryFn: () => fetchAccountStatus(),
    staleTime: queryClientOptions.time.INFINITY
  })
}
