import type { FormatterParams } from '../../types'

/**
 * Convert a minute duration into a minute readable string
 *
 * Input 125
 * Output 125'
 */
export function isoInteger({ value }: FormatterParams<number | string>) {
  return parseFloat(value as any).toFixed(0)
}

export default {
  format: isoInteger
}
