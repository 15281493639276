import type { BaseComponent } from '@libs/ui/ds/lib/internals'
import { cva } from 'class-variance-authority'
import type { FC, HTMLAttributes } from 'react'

import { Divider } from '../../../../dataDisplay/Divider/base/Divider'

const comboboxDividerCVA = cva(['-mx-2 my-1.5 !w-auto !px-4'])

export type ComboboxDividerProps = BaseComponent &
  HTMLAttributes<HTMLDivElement>

export const ComboboxDivider: FC<ComboboxDividerProps> = ({
  className,
  ...other
}) => {
  return <Divider {...other} className={comboboxDividerCVA({ className })} />
}
