import type { CalendarFeedPlanning } from '@libs/data-access/entities'
import { useQuery } from '@tanstack/react-query'

import type { ApiErrorResponse, UseAppQueryOptions } from '../../../../types'
import { fetchCalendarFeed } from '../calendarFeed.api'
import { calendarFeedQueryKeys } from '../calendarFeed.cacheKeys'

export const useFetchCalendarFeed = (
  request?: {},
  queryOptions?: UseAppQueryOptions<CalendarFeedPlanning>
) => {
  return useQuery<CalendarFeedPlanning, ApiErrorResponse>({
    ...queryOptions,
    queryKey: calendarFeedQueryKeys.all(),
    queryFn: fetchCalendarFeed
  })
}
