import { useCallback, useContext, useMemo } from 'react'

import { ModalDispatchContext } from '../context/modalStateContext'
import type { ModalDefinitionParams } from '../types'

import { throttleWrapper } from './throttleWrapper'

type OptionalArgTuple<T> = T extends undefined ? any[] : [T]

/**
 * Expose disclosure context
 */
export function useDispatchModal<T extends any = undefined>({
  name
}: ModalDefinitionParams) {
  const ctx = useContext(ModalDispatchContext)

  if (!ctx) {
    throw new Error(
      'ModalDispatch.Context is used outside of ModalDispatch.Provider'
    )
  }

  /**
   * Set data into modal context, it is useful if you need to pass multiple primitive data to your modal
   * Also update the search params url with modal=modalName
   */
  const handleOpen = useCallback(
    throttleWrapper((...params: OptionalArgTuple<T>) => {
      const [data] = params
      ctx.open(name, data || {})
    }),
    [name]
  )

  /**
   * Reset data from the modal context and the search params url
   */
  const handleClose = useCallback(
    throttleWrapper(() => {
      ctx.close(name)
    }),
    [name]
  )

  return useMemo(
    () => ({
      open: handleOpen,
      close: handleClose
    }),
    [handleOpen, handleClose]
  )
}
