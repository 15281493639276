import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  CREATE_REPORT,
  CREATE_REPORT_SUCCEEDED,
  CREATE_REPORT_FAILED,
  FETCH_REPORT,
  FETCH_REPORT_WITH_ROWS,
  FETCH_REPORT_SUCCEEDED,
  FETCH_REPORT_FAILED,
  UPDATE_REPORT,
  UPDATE_REPORT_SUCCEEDED,
  UPDATE_REPORT_FAILED,
  FETCH_PAY_PERIODS_SUCCEEDED,
  FETCH_PAY_PERIOD_SUCCEEDED,
  FETCH_REPORT_ROW_SUCCEEDED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { updateRecord, mergeRecords } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'
import {
  subscribeToChannel,
  unsubscribeFromChannel,
  sendMessageToChannel
} from '../socket'

const transformListToMapWithKey = (List, mapKey, options = {}) =>
  List?.toMap().mapEntries(([key, value]) => {
    // when keys are identical (eg: 20, 40, 20 for night hours percentage)
    // we prefix them to avoid mismatching values
    // @ts-ignore migration from js(x) to ts(x)
    const { prefixWithKey } = options
    const newKey = prefixWithKey
      ? `${key}_${value.get(mapKey)}`
      : String(value.get(mapKey))

    return [newKey, value]
  }) || []

// ------------------------------------
// Actions
// ------------------------------------

export const createReport = (reportData, resolve, reject) => ({
  type: CREATE_REPORT,
  reportData,
  resolve,
  reject
})

export const fetchReport = (reportId, resolve, reject) => ({
  type: FETCH_REPORT,
  reportId,
  resolve,
  reject
})

export const fetchReportWithRows = (reportId, resolve, reject) => ({
  type: FETCH_REPORT_WITH_ROWS,
  reportId,
  resolve,
  reject
})

export const updateReport = (reportId, reportData, resolve, reject) => ({
  type: UPDATE_REPORT,
  reportId,
  reportData,
  resolve,
  reject
})

// ------------------------------------
// Socket Actions
// ------------------------------------

export const subscribeToReportChannel = () =>
  subscribeToChannel('ReportChannel')
export const unsubscribeFromReportChannel = () =>
  unsubscribeFromChannel('ReportChannel')
export const sendMessageToReportChanel = (
  messageType,
  messageData,
  resolve,
  reject
) =>
  sendMessageToChannel(
    'ReportChannel',
    messageType,
    messageData,
    resolve,
    reject
  )
export const fetchReportWithRowsThroughSocket = (reportId, resolve, reject) =>
  sendMessageToReportChanel(
    'generate',
    { report_id: reportId },
    resolve,
    reject
  )

// ------------------------------------
// Sagas
// ------------------------------------

const createReportSagaEntity = {
  success: (data, reportData) => ({
    type: CREATE_REPORT_SUCCEEDED,
    data,
    reportData
  }),
  failure: (error, reportData) => ({
    type: CREATE_REPORT_FAILED,
    error,
    reportData
  }),

  fetchAPI: (id, options) => callAPI('/reports', { method: 'POST', ...options })
}

export function* doCreateReport({ reportData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createReportSagaEntity,
    null,
    null,
    reportData,
    resolve,
    reject
  )
}

const fetchReportSagaEntity = {
  success: (data, reportId) => ({
    type: FETCH_REPORT_SUCCEEDED,
    data,
    reportId
  }),
  failure: (error, reportId) => ({
    type: FETCH_REPORT_FAILED,
    error,
    reportId
  }),

  fetchAPI: (reportId, options, params) =>
    callAPI(`/reports/${reportId}?${queryString.stringify(params)}`, options)
}

export function* doFetchReport({ reportId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    fetchReportSagaEntity,
    reportId,
    null,
    undefined,
    resolve,
    reject
  )
}

export function* doFetchReportWithRows({ reportId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    fetchReportSagaEntity,
    reportId,
    { includes: 'rows' },
    undefined,
    resolve,
    reject
  )
}

const updateReportSagaEntity = {
  success: (data, reportId) => ({
    type: UPDATE_REPORT_SUCCEEDED,
    data,
    reportId
  }),
  failure: (error, reportId) => ({
    type: UPDATE_REPORT_FAILED,
    error,
    reportId
  }),

  fetchAPI: (reportId, options) =>
    callAPI(`/reports/${reportId}`, { method: 'PUT', ...options })
}

export function* doUpdateReport({ reportId, reportData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    updateReportSagaEntity,
    reportId,
    null,
    reportData,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const Report = Immutable.Record({
  collective_agreement_id: null,
  evened_out_overtime: null,
  id: null,
  location_id: null,
  team_id: null,
  locked_at: null,
  intersect_modulation: null,
  pay_period_id: null,
  rcr_updated_by_id: null,
  rcr_updated_at: null,
  rows: null
})

const reportForeignKeys = [
  'collective_agreement_id',
  'location_id',
  'pay_period_id'
]

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

export const normalizeRows = rows =>
  rows.map(row =>
    row.withMutations(immutableObject =>
      immutableObject
        // Change sections structure from [{ section_id: 'user_data', ... }, ...] to
        // { user_data: { ... }, ... }
        .updateIn(['sections'], Immutable.Map(), sections =>
          transformListToMapWithKey(sections, 'section_id')
        )
        // Change sup/comp hours structure from [{ percentage: 10, ... }, ...] to
        // { 10: { ... }, ... }
        .updateIn(
          ['sections', 'over_hours', 'data', 'complementary_time'],
          Immutable.Map(),
          complementaryTime =>
            transformListToMapWithKey(complementaryTime, 'percentage')
        )
        .updateIn(
          ['sections', 'over_hours', 'data', 'evened_complementary_time'],
          Immutable.Map(),
          complementaryTime =>
            transformListToMapWithKey(complementaryTime, 'percentage')
        )
        .updateIn(
          ['sections', 'over_hours', 'data', 'supplementary_time'],
          Immutable.Map(),
          supplementaryTime =>
            transformListToMapWithKey(supplementaryTime, 'percentage')
        )
        .updateIn(
          ['sections', 'over_hours', 'data', 'evened_supplementary_time'],
          Immutable.Map(),
          supplementaryTime =>
            transformListToMapWithKey(supplementaryTime, 'percentage')
        )
        .updateIn(
          ['sections', 'over_hours', 'data', 'supplementary_time_with_rcr'],
          Immutable.Map(),
          supplementaryTime =>
            transformListToMapWithKey(supplementaryTime, 'percentage')
        )
        .updateIn(
          ['sections', 'over_hours', 'data', 'complementary_time_with_rcr'],
          Immutable.Map(),
          complementaryTime =>
            transformListToMapWithKey(complementaryTime, 'percentage')
        )
        .updateIn(
          [
            'sections',
            'over_hours',
            'data',
            'evened_supplementary_time_with_rcr'
          ],
          Immutable.Map(),
          supplementaryTime =>
            transformListToMapWithKey(supplementaryTime, 'percentage')
        )
        .updateIn(
          [
            'sections',
            'over_hours',
            'data',
            'evened_complementary_time_with_rcr'
          ],
          Immutable.Map(),
          complementaryTime =>
            transformListToMapWithKey(complementaryTime, 'percentage')
        )
        .updateIn(
          ['sections', 'over_hours', 'data', 'night_time'],
          Immutable.Map(),
          nightTime =>
            transformListToMapWithKey(nightTime, 'percentage', {
              prefixWithKey: true
            })
        )
    )
  )

function reports(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_REPORT_SUCCEEDED:
    case CREATE_REPORT_SUCCEEDED:
    case UPDATE_REPORT_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const report = Immutable.fromJS(action.data).updateIn(
        ['rows'],
        Immutable.Map(),
        normalizeRows
      )

      // @ts-ignore migration from js(x) to ts(x)
      return updateRecord(state, Report, report, reportForeignKeys)
    }
    case FETCH_PAY_PERIOD_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const periodReports = action.data.reports
      const alternativeReports =
        // @ts-ignore migration from js(x) to ts(x)
        action.data.alternative_pay_periods.flatMap(p => p.reports) || []
      const reports = Immutable.fromJS(periodReports.concat(alternativeReports))

      return mergeRecords(
        state,
        Report,
        reports,
        // @ts-ignore migration from js(x) to ts(x)
        reportForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    case FETCH_PAY_PERIODS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const rawReports = action.data.pay_periods.reduce((reports, period) => {
        if (!period.reports) {
          return reports
        }

        const alternativePayPeriodReports =
          period.alternative_pay_periods
            .flatMap(p => p.reports)
            .filter(Boolean) || []

        return [...reports, ...period.reports, ...alternativePayPeriodReports]
      }, [])

      const reports = Immutable.fromJS(rawReports)
      return reports.size
        ? // @ts-ignore migration from js(x) to ts(x)
          mergeRecords(state, Report, reports, reportForeignKeys).mergeDeepIn(
            ['meta'],
            {
              loading: false,
              updated_at: Date.now(),
              success: true
            }
          )
        : state
    }
    case FETCH_REPORT_ROW_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const reportId = action.data.report_id
      const existingReport = state.getIn(['data', reportId])
      if (!existingReport) {
        return state
      }
      // Get the existing rows so we can append the new row to them.
      const existingRows = existingReport.rows || Immutable.List()

      // @ts-ignore migration from js(x) to ts(x)
      const row = Immutable.fromJS(action.data)
      // Normalize the row so it is correctly displayed.
      const normalizedRow = normalizeRows([row])[0]

      // Make sure the rows are unique using their index as unique identifier.
      const reportRows = existingRows
        .push(normalizedRow)
        .groupBy(row => row.get('index'))
        .map(rows => rows.first())
        .toList()

      // Finally, update the report with the new row.
      const report = existingReport.setIn(['rows'], reportRows)

      // @ts-ignore migration from js(x) to ts(x)
      return updateRecord(state, Report, report, reportForeignKeys)
    }
    default:
      return state
  }
}

export default filterActions(reports, [
  FETCH_REPORT_SUCCEEDED,
  CREATE_REPORT_SUCCEEDED,
  UPDATE_REPORT_SUCCEEDED,
  FETCH_PAY_PERIODS_SUCCEEDED,
  FETCH_PAY_PERIOD_SUCCEEDED,
  FETCH_REPORT_ROW_SUCCEEDED
])
