import type { MembershipSchema } from '@libs/data-access/entities'
import { queryClientOptions } from 'libs/data-access/queries/src/lib/provider'

import { useFetchCurrentUser } from '../../User/hooks/useFetchCurrentUser/useFetchCurrentUser'

import { getCurrentMembership } from './useFetchCurrentMembershipPermissions'

export const useFetchCurrentMembership = () => {
  return useFetchCurrentUser<MembershipSchema>(
    {},
    {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      select: data => getCurrentMembership(data),
      staleTime: queryClientOptions.time.INFINITY
    }
  )
}
