import { outlineFocusMixin } from '@libs/ui/ds/lib/styles/mixins/mixins'
import type { DatePickerType } from '@mantine/dates'
import {
  DatePicker as MantineDatePicker,
  MonthPicker as MantineMonthPicker
} from '@mantine/dates'
import { cva } from 'class-variance-authority'
import React from 'react'

import type { CalendarPickerProps } from './CalendarPicker.types'

export const calendarPickerCVA = {
  controlItem: cva(
    [
      '[&:not:[data-disabled]]:hover:bg-neutral-100 [&:not:[data-disabled]]:active:bg-neutral-200 rounded text-neutral-700',
      '[&[data-selected]]:bg-brand-green-300/70 [&[data-selected]]:text-neutral-900 [&[data-selected]]:hover:bg-brand-green-300 [&[data-selected]]:active:bg-brand-green-400',
      '[&[data-disabled]]:!pointer-events-none [&[data-disabled]]:!cursor-default [&[data-disabled]]:text-neutral-500 [&[data-static]]:pointer-events-none',
      '[&[data-disabled]]:[&[data-autofocus]]:bg-neutral-200',
      '[&[data-in-range]]:bg-brand-green-100/50 [&[data-in-range]]:hover:bg-brand-green-100 [&[data-in-range]]:active:bg-brand-green-200',
      '[&[data-today]]:font-bold',
      outlineFocusMixin
    ],
    {
      variants: {
        type: {
          range: [
            '[&[data-in-range]]:rounded-none',
            '[&[data-first-in-range]]:!rounded-l [&[data-first-in-range]]:!rounded-r-none',
            '[&[data-last-in-range]]:!rounded-l-none [&[data-last-in-range]]:!rounded-r',
            '[&[data-last-in-range]]:[&[data-first-in-range]]:!rounded'
          ],
          default: '',
          multiple: ''
        }
      }
    }
  )
}

export function CalendarPicker<Type extends DatePickerType>({
  numberOfColumns,
  maxLevel,
  allowSingleDateInRange,
  defaultValue,
  value,
  onChange,
  type,
  excludeDate,
  minDate,
  maxDate,
  date,
  componentType,
  onDateChange
}: CalendarPickerProps<Type>) {
  const Comp = componentType === 'date' ? MantineDatePicker : MantineMonthPicker
  return (
    <Comp
      excludeDate={excludeDate}
      classNames={{
        day: calendarPickerCVA.controlItem({ type }),
        monthCell: calendarPickerCVA.controlItem({ type }),
        yearsListControl: calendarPickerCVA.controlItem({ type }),
        monthsListControl: calendarPickerCVA.controlItem({ type }),
        calendarHeaderControl: calendarPickerCVA.controlItem({
          type
        }),
        calendarHeaderLevel: calendarPickerCVA.controlItem({ type })
      }}
      allowSingleDateInRange={allowSingleDateInRange}
      type={type}
      numberOfColumns={numberOfColumns}
      maxLevel={maxLevel as any}
      defaultValue={defaultValue}
      onDateChange={onDateChange}
      date={date}
      value={value}
      onChange={onChange}
      size='sm'
      minDate={minDate}
      maxDate={maxDate}
    />
  )
}
