import { useUncontrolled } from '@libs/ui/ds'
import { useEffect } from 'react'

import type { SelectOption } from '../../../base/Select.types'
import type { MultiSelectProps } from '../MultiSelect.types'

type Params = {
  options: MultiSelectProps['options']
  maxSelected: MultiSelectProps['maxSelected']
  select: {
    value: MultiSelectProps['value']
    defaultValue: MultiSelectProps['defaultValue']
    onChange: MultiSelectProps['onChange']
  }
  search: {
    onSearchChange: MultiSelectProps['onSearchChange']
    searchValue: MultiSelectProps['searchValue']
  }
}

export const useMultiSelectValuesUncontrolled = ({
  select,
  search,
  options,
  maxSelected
}: Params) => {
  const [searchValue, setSearchValue] = useUncontrolled({
    defaultValue: '',
    onChange: search.onSearchChange,
    value: search.searchValue
  })
  const [selectValue, setSelectValue] = useUncontrolled<string[]>({
    ...select,
    defaultValue: []
  })
  const [selectedOptions, setSelectedOptions] = useUncontrolled<SelectOption[]>(
    {}
  )

  const resetSelectValue = () => {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    setSelectedOptions(null)
    setSearchValue('')
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    setSelectValue(null)
  }

  useEffect(() => {
    if (selectValue) {
      const newOptions = options.filter(i => selectValue.includes(i.value))
      setSelectedOptions(newOptions)
      setSearchValue('')
    }
  }, [selectValue])

  return {
    search: {
      searchValue,
      setSearchValue,
      resetSearchValue: () => setSearchValue('')
    },
    select: {
      selectValue,
      setSelectValue,
      resetSelectValue,
      removeSelectValue: (value: string) => {
        const updatePayload = selectValue.filter(i => i !== value)
        setSelectValue(updatePayload)
      },
      toggleSelectValue: (value: string) => {
        const isRemoving = selectValue.includes(value)
        if (!isRemoving && maxSelected && selectValue.length >= maxSelected) {
          return
        }

        const updatePayload = isRemoving
          ? selectValue.filter(i => i !== value)
          : [...selectValue, value]

        setSelectValue(updatePayload)
      }
    },
    selectedOptions: selectedOptions || []
  }
}
