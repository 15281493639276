import { useDebouncedValue as useMantineDebouncedValue } from '@mantine/hooks'

const DEFAULT_DELAY = 400

/**
 * Extends mantine use-debounced-value hook
 * Set a default delay of 400
 */
export const useDebouncedValue = <T = any>(
  val: T,
  delay = DEFAULT_DELAY,
  options?: {
    leading: boolean
  }
) => {
  return useMantineDebouncedValue<T>(val, delay, options)
}
