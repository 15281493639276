import type {
  DefaultError,
  InfiniteData,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions
} from '@tanstack/react-query'
import { useInfiniteQuery } from '@tanstack/react-query'

import type { PaginatedQueryResponse } from '../../types'

export const useAppInfiniteQuery = <
  TQueryFnData extends PaginatedQueryResponse<{}, any>,
  TError = DefaultError,
  TData = InfiniteData<TQueryFnData>,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey, unknown>,
  queryOptions?: Omit<
    UseInfiniteQueryOptions<
      TQueryFnData,
      TError,
      TData,
      TQueryFnData,
      TQueryKey
    >,
    | 'queryFn'
    | 'queryKey'
    | 'initialPageParam'
    | 'getNextPageParam'
    | 'getPreviousPageParam'
  >
) =>
  useInfiniteQuery<TQueryFnData, TError, TData, TQueryKey>({
    ...queryOptions,
    queryKey,
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      const hasNextPage =
        lastPage.meta.total_pages !== 0 &&
        lastPage.meta.current_page !== lastPage.meta.total_pages
      return hasNextPage ? lastPage.meta.current_page + 1 : undefined
    },
    getPreviousPageParam: firstPage => {
      const hasPreviousPage = firstPage.meta.current_page > 1
      return hasPreviousPage ? firstPage.meta.current_page - 1 : undefined
    }
  })
