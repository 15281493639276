import { Modal as MantineModal } from '@mantine/core'
import { cva } from 'class-variance-authority'
import { Suspense, forwardRef, memo } from 'react'

import { Loader } from '../../../feedback/Loader/base/Loader'

import { ModalProvider } from './Modal.context'
import type { ModalProps } from './Modal.types'
import { ModalContent } from './compound/ModalContent/ModalContent'
import { ModalFooter } from './compound/ModalFooter/ModalFooter'
import { ModalHeader } from './compound/ModalHeader/ModalHeader'
import { useAdaptModalVariant } from './utils/useAdaptModalVariant'

const modalCVA = {
  overlay: cva(['bg-neutral-800/70']),
  inner: cva(['flex bg-transparent p-0 [&>*]:bg-transparent'], {
    variants: {
      variant: {
        center: 'items-center justify-center',
        fullscreen:
          'max-h-full min-h-full min-w-screen max-w-screen-sm justify-center px-0 py-4 sm:p-4',
        right: 'max-h-screen justify-end'
      }
    }
  }),
  content: cva(['!bg-neutral-50 p-0 shadow-xl shadow-neutral-700/30'], {
    variants: {
      variant: {
        center: 'rounded-lg',
        fullscreen: 'rounded-lg',
        right: 'rounded-l-lg rounded-r-none'
      },
      size: {
        xs: '',
        s: '',
        m: '',
        l: ''
      }
    },
    compoundVariants: [
      {
        variant: 'center',
        size: 'xs',
        class: 'min-w-screen-xs max-w-screen-xs'
      },
      {
        variant: 'center',
        size: 's',
        class: 'min-w-screen max-w-screen sm:min-w-screen-sm sm:max-w-screen-sm'
      },
      {
        variant: 'center',
        size: 'm',
        class: 'min-w-screen max-w-screen md:min-w-screen-md md:max-w-screen-md'
      },
      {
        variant: 'center',
        size: 'l',
        class: 'min-w-screen max-w-screen lg:min-w-screen-lg lg:max-w-screen-lg'
      },
      {
        variant: 'fullscreen',
        size: ['s', 'm', 'l'],
        class: 'size-full max-h-full min-h-full min-w-full max-w-full'
      },
      {
        variant: 'right',
        size: ['s', 'm', 'l'],
        class:
          'h-full max-h-full min-h-full min-w-full max-w-full sm:min-w-120 sm:max-w-120'
      }
    ]
  }),
  body: cva(['flex flex-col p-0'], {
    variants: {
      variant: {
        center: 'h-fit max-h-[calc(100vh-70px)]',
        fullscreen: 'h-full',
        right: 'h-full'
      }
    }
  }),
  header: cva(['hidden']),
  close: cva(['hidden'])
}

const BaseModal = memo(
  forwardRef<HTMLDivElement, ModalProps>(
    (
      {
        className,
        variant: inputVariant = 'center',
        size = 's',
        disableClose,
        height = 'max-m',
        opened,
        onClose,
        children,
        ...other
      },
      ref
    ) => {
      const variant = useAdaptModalVariant(inputVariant)

      return (
        <ModalProvider
          value={{
            onClose,
            variant,
            disableClose,
            opened,
            height
          }}
        >
          <MantineModal
            // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
            opened={opened}
            // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
            onClose={disableClose ? undefined : onClose}
            ref={ref}
            classNames={{
              inner: modalCVA.inner({ className, variant }),
              content: modalCVA.content({ size, variant }),
              overlay: modalCVA.overlay(),
              body: modalCVA.body({ variant }),
              close: modalCVA.close(),
              header: modalCVA.header()
            }}
            {...other}
          >
            <Suspense fallback={<Loader.Overlay />}>{children}</Suspense>
          </MantineModal>
        </ModalProvider>
      )
    }
  )
)

type CompoundModalType = {
  Header: typeof ModalHeader
  Content: typeof ModalContent
  Footer: typeof ModalFooter
}

const TypedModal = BaseModal as typeof BaseModal & CompoundModalType

TypedModal.displayName = 'Modal'
TypedModal.Header = ModalHeader
TypedModal.Content = ModalContent
TypedModal.Footer = ModalFooter

export const Modal = TypedModal
export type { ModalProps } from './Modal.types'
