import type { ReduxState } from '@spa/redux/ReduxState'
import moment from 'moment'
import createCachedSelector from 're-reselect'

import { getTimeRange } from '../../timeRange'
import { ONE_HOUR_IN_MINUTES } from '../constants'

export type IntervalOptions = { interval?: number }

export const selectInterval = (_: ReduxState, opts: IntervalOptions) =>
  opts?.interval || ONE_HOUR_IN_MINUTES

/**
 * Return array of isoDates for each hours between a timeRange
 * Optional interval can be passed as parameters to change the dates intervals
 */
export const getDatesArrayWithMinutesInterval = createCachedSelector(
  selectInterval,
  (state: ReduxState) => getTimeRange(state),

  (interval, timeRange): string[] => {
    const datesArr: string[] = []
    let currentDate = moment(timeRange.start)

    while (currentDate.isBefore(timeRange.end)) {
      datesArr.push(currentDate.toISOString())
      currentDate = currentDate.add(interval, 'minutes')
    }

    return datesArr
  }
)(selectInterval)
