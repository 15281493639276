import type {
  ContractRecordMap,
  PlannableOverlapExtended
} from '@libs/data-access/entities'
import { Flex, SegmentedControl } from '@libs/ui/ds'
import { Controller } from '@libs/ui/form'
import { usePlanningFormTranslation } from '@libs/utils/translations'
import { HideWrapper } from '@spa/components'
import moment from 'moment'
import { useEffect, type FC } from 'react'
import { Controller as ControllerRHF, useFormContext } from 'react-hook-form'
import { useAuthorizedFeaturePolicies } from 'snap-redux/modules/countryConfigurations/selectors'

import { ShiftPlannedOverPeriod } from '../Alerts/ShiftPlannedOverPeriod/ShiftPlannedOverPeriod'

export const HALF_DAY = {
  MORNING: 'morning',
  AFTERNOON: 'afternoon'
}
/**
 * Compute momentStart and momentEnd given string dates
 */
export const getDateRangeMomentDate = (val: Partial<DayFieldsValue>) => {
  const momentStart = moment(val?.start?.date)
    .clone()
    .hours(val?.start?.halfDay === HALF_DAY.AFTERNOON ? 12 : 0)

  const momentEnd = moment(val?.end?.date)
    .clone()
    .add(val?.end?.halfDay === HALF_DAY.AFTERNOON ? 1 : 0, 'days')
    .hours(val?.end?.halfDay === HALF_DAY.AFTERNOON ? 0 : 12)

  return { momentStart, momentEnd }
}

export const halfDay = [HALF_DAY.MORNING, HALF_DAY.AFTERNOON] as const

export type HalfDay = (typeof HALF_DAY)[keyof typeof HALF_DAY]
export type DayFieldsItemValue = {
  halfDay: HalfDay
  date: string
}

export type DayFieldsValue = {
  start: DayFieldsItemValue
  end: DayFieldsItemValue
}

type Props = {
  contracts: ContractRecordMap
  plannableOverlapExtended: PlannableOverlapExtended
  restIsLocked: boolean
  disableCustomRest: boolean
  disabled?: boolean
}
export const DayFields: FC<Props> = ({
  restIsLocked,
  plannableOverlapExtended,
  disableCustomRest = false,
  disabled = false,
  contracts
}) => {
  const { t, tCommon } = usePlanningFormTranslation()
  const { authorizedHalfDayRest } = useAuthorizedFeaturePolicies()

  const {
    control,
    watch,
    setValue,
    formState: { errors }
  } = useFormContext()

  const dateRange = watch('dateRange')

  const isStartDateSameOrAfterEndDate = moment(
    dateRange.start.date
  ).isSameOrAfter(dateRange.end.date)

  const isEndHalfDayDisabled = () => {
    const startsHalfIsAfternoon = dateRange.start.halfDay === HALF_DAY.AFTERNOON
    return isStartDateSameOrAfterEndDate && startsHalfIsAfternoon
  }

  useEffect(() => {
    if (isEndHalfDayDisabled()) {
      setValue('dateRange.end.halfDay', HALF_DAY.AFTERNOON)
    }
  }, [dateRange.start.halfDay])

  useEffect(() => {
    if (isStartDateSameOrAfterEndDate) {
      setValue('dateRange.end.date', dateRange.start.date)
    }
  }, [dateRange.start.date])

  const startDatetabs = [
    {
      value: HALF_DAY.MORNING,
      label: t('restForm.morning'),
      disabled
    },
    {
      value: HALF_DAY.AFTERNOON,
      label: t('restForm.afternoon'),
      disabled
    }
  ]

  const endDateTabs = [
    {
      value: HALF_DAY.MORNING,
      label: t('restForm.morning'),
      disabled: isEndHalfDayDisabled() || disabled
    },
    {
      value: HALF_DAY.AFTERNOON,
      label: t('restForm.afternoon'),
      disabled
    }
  ]

  return (
    <div className='grid gap-3'>
      <Flex row className='flex-nowrap justify-between gap-3'>
        <div className='grow'>
          <Controller.DatePickerInput
            name='dateRange.start.date'
            control={control}
            rules={{
              required: tCommon('form.errors.fieldIsRequired')
            }}
            disabled={disabled}
            required
            maxLevel='year'
          />
        </div>

        {authorizedHalfDayRest && (
          <ControllerRHF
            name='dateRange.start.halfDay'
            control={control}
            render={({ field: { onChange, value } }) => (
              <SegmentedControl
                name='dateRange.start.halfDay'
                defaultValue={value}
                data={startDatetabs}
                disabled={restIsLocked || disableCustomRest}
                onChange={onChange}
              />
            )}
          />
        )}
      </Flex>
      <Flex row className='flex-nowrap justify-between gap-3'>
        <div className='grow'>
          <Controller.DatePickerInput
            name='dateRange.end.date'
            control={control}
            rules={{
              required: tCommon('form.errors.fieldIsRequired')
            }}
            required
            disabled={disabled}
            maxLevel='year'
            {...(watch('dateRange.start.date') !== '' && {
              minDate: watch('dateRange.start.date')
            })}
          />
        </div>
        {authorizedHalfDayRest && (
          <ControllerRHF
            name='dateRange.end.halfDay'
            control={control}
            render={({ field: { onChange, value } }) => (
              <SegmentedControl
                name='dateRange.end.halfDay'
                defaultValue={value}
                value={value}
                data={endDateTabs}
                disabled={restIsLocked || disableCustomRest}
                onChange={onChange}
              />
            )}
          />
        )}
      </Flex>
      <HideWrapper hide={!plannableOverlapExtended?.total_shift_count}>
        <ShiftPlannedOverPeriod
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          control={control}
          errors={errors}
          contracts={contracts}
          plannableOverlapExtended={plannableOverlapExtended}
        />
      </HideWrapper>
    </div>
  )
}
