import {
  isContractInProgressAt,
  isContractInProgressBetween
} from '@libs/data-access/queries'
import Immutable from 'immutable'
import moment from 'moment'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_CONTRACTS,
  FETCH_CONTRACTS_SUCCEEDED,
  FETCH_CONTRACTS_FAILED,
  FETCH_PAGINATED_CONTRACTS,
  FETCH_PAGINATED_FILTERED_CONTRACTS,
  FETCH_PAGINATED_CONTRACTS_SUCCEEDED,
  FETCH_PAGINATED_CONTRACTS_FAILED,
  FETCH_CONTRACT,
  FETCH_CONTRACT_SUCCEEDED,
  FETCH_CONTRACT_FAILED,
  CREATE_CONTRACT,
  CREATE_CONTRACT_SUCCEEDED,
  UPDATE_CONTRACT,
  UPDATE_CONTRACT_SUCCEEDED,
  UPDATE_CONTRACT_FAILED,
  DELETE_CONTRACT,
  DELETE_CONTRACT_SUCCEEDED,
  DELETE_CONTRACT_FAILED,
  FETCH_MEMBERSHIPS_SUCCEEDED,
  FETCH_MEMBERSHIP_SUCCEEDED,
  CREATE_MEMBERSHIP_SUCCEEDED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import {
  mergeRecords,
  updateRecord,
  deleteRecord,
  listGroupToMapByForeignKeys,
  getDataByForeignId,
  keyIn
} from '../../services/immutableUtils'
import { getCustomPaginationKey } from '../../services/paginationUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'
import { filterFalsy } from '../../services/utils'
import { FETCH_ALL_MEMBERSHIPS_SUCCESS } from '../memberships/actionTypes'
import { User } from '../users'

export const EXTRA = 'extra'
export const TRAINEE = 'apprentissage'
export const INTERN = 'intern'
export const INTERIM = 'interim'
export const CDI = 'CDI'
export const CDD = 'CDD'
export const SEASONAL = 'seasonal'
export const contractTypes = {
  EXTRA,
  TRAINEE,
  INTERN,
  INTERIM,
  CDI,
  CDD,
  SEASONAL
}

const CONTRACT_TYPES_WITH_NO_SOCIAL_TAX = [TRAINEE, INTERN]
export const CONTRACT_TYPES_WITH_NO_RCR = [EXTRA, INTERN]

// ------------------------------------
// Actions
// ------------------------------------

export const fetchContracts = (accountId, params, resolve, reject) => ({
  type: FETCH_CONTRACTS,
  accountId,
  params,
  resolve,
  reject
})

export const hydrateContracts = (accountId, data) => ({
  type: FETCH_CONTRACTS_SUCCEEDED,
  data,
  accountId
})

export const fetchPaginatedContracts = (
  accountId,
  params,
  resolve,
  reject
) => ({
  type: FETCH_PAGINATED_CONTRACTS,
  accountId,
  params,
  resolve,
  reject
})

export const fetchPaginatedFilteredContracts = (
  accountId,
  params,
  resolve,
  reject
) => ({
  type: FETCH_PAGINATED_FILTERED_CONTRACTS,
  accountId,
  params,
  resolve,
  reject
})

export const fetchContract = (contractId, params, resolve, reject) => ({
  type: FETCH_CONTRACT,
  contractId,
  params,
  resolve,
  reject
})

export const createContract = (contractData, resolve, reject) => ({
  type: CREATE_CONTRACT,
  contractData,
  resolve,
  reject
})

export const updateContract = (contractId, contractData, resolve, reject) => ({
  type: UPDATE_CONTRACT,
  contractId,
  contractData,
  resolve,
  reject
})

export const deleteContract = (contractId, resolve, reject) => ({
  type: DELETE_CONTRACT,
  contractId,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const contractsSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_CONTRACTS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_CONTRACTS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/user_contracts?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchContracts({ accountId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    contractsSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

const paginatedContractsSagaEntity = {
  success: (data, accountId, params) => ({
    type: FETCH_PAGINATED_CONTRACTS_SUCCEEDED,
    data,
    accountId,
    params
  }),
  failure: (error, accountId) => ({
    type: FETCH_PAGINATED_CONTRACTS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) => {
    return callAPI(
      `/accounts/${accountId}/user_contracts?${queryString.stringify(params, {
        arrayFormat: 'bracket'
      })}`,
      options
    )
  }
}

export function* doFetchPaginatedContracts({
  accountId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    paginatedContractsSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

export function* doFetchPaginatedFilteredContracts({
  accountId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    paginatedContractsSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

const fetchContractSagaEntity = {
  success: (data, contractId) => ({
    type: FETCH_CONTRACT_SUCCEEDED,
    data,
    contractId
  }),
  failure: (error, contractId) => ({
    type: FETCH_CONTRACT_FAILED,
    error,
    contractId
  }),

  fetchAPI: (id, options, params) =>
    callAPI(`/user_contracts/${id}?${queryString.stringify(params)}`, options)
}

export function* doFetchContract({ contractId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    fetchContractSagaEntity,
    contractId,
    params,
    undefined,
    resolve,
    reject
  )
}

const createContractSagaEntity = {
  success: (data, contractData) => ({
    type: UPDATE_CONTRACT_SUCCEEDED,
    data,
    contractData
  }),
  failure: (error, contractData) => ({
    type: UPDATE_CONTRACT_FAILED,
    error,
    contractData
  }),

  fetchAPI: (id, options) =>
    callAPI('/user_contracts', { method: 'POST', ...options })
}

export function* doCreateContract({ contractData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createContractSagaEntity,
    null,
    null,
    contractData,
    resolve,
    reject
  )
}

const updateContractSagaEntity = {
  success: (data, contractId, contractData) => ({
    type: UPDATE_CONTRACT_SUCCEEDED,
    data,
    contractId,
    contractData
  }),
  failure: (error, contractId, contractData) => ({
    type: UPDATE_CONTRACT_FAILED,
    error,
    contractId,
    contractData
  }),

  fetchAPI: (contractId, options) =>
    callAPI(`/user_contracts/${contractId}`, { method: 'PUT', ...options })
}

export function* doUpdateContract({
  contractId,
  contractData,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    updateContractSagaEntity,
    contractId,
    null,
    contractData,
    resolve,
    reject
  )
}

const deleteContractSagaEntity = {
  success: (data, contractId) => ({
    type: DELETE_CONTRACT_SUCCEEDED,
    data,
    contractId
  }),
  failure: (error, contractId) => ({
    type: DELETE_CONTRACT_FAILED,
    error,
    contractId
  }),

  fetchAPI: (contractId, options) =>
    callAPI(`/user_contracts/${contractId}`, { method: 'DELETE', ...options })
}

export function* doDeleteContract({ contractId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    deleteContractSagaEntity,
    contractId,
    null,
    null,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const Contract = Immutable.Record({
  id: null,
  amendments: [],
  membership_id: null,
  location_id: null,
  location_ids: [],
  availabilities: null,
  contract_time: null,
  position: {},
  updated_at: null,
  contract_start: null,
  contract_end: null,
  start_time: null,
  contract_type: null,
  mensual_brut: null,
  hourly_brut: null,
  contract_end_trial: null,
  echelon_other: null,
  account_job: null,
  echelon_level: null,
  echelon_number: null,
  daily_worker: null,
  incomplete: null,
  transportation: null,
  cost_per_hour: null,
  do_not_show_in_register: false,
  contract_end_reason: null,
  layoff_authorization_date: null,
  layoff_authorization_request_date: null,
  team_ids: [],
  team_id: null,
  has_applied_amendments: false,
  tutor: null,
  temp_agency_address: null,
  temp_agency_name: null,
  temp_agency_zip: null,
  temp_agency_city: null,
  collective_agreement_id: null,
  working_days_in_week: 5,
  created_at: null,
  notifications: null,
  has_minimum_wage: false,
  paid_leave_counters: null,
  membership_permissions: {
    can_read: false
  },

  // personal information
  iban: null,
  bic: null,
  work_permit_authorization_date: null,
  work_permit_authorization_request_date: null,
  work_permit_type: null,
  work_permit_number: null,
  work_permit_expiration_date: null,
  birth_date: null,
  birth_place: null,
  birth_department_code: null,
  social_security_number: null,
  phone_number: null,
  firstname: null,
  lastname: null,
  citizenship: null,
  gender: null,
  street_address: null,
  zip: null,
  city: null,
  health_plan: null,
  last_medical_examination_date: null,
  enhanced_medical_examination: null
})

const contractForeignKeys = ['membership_id', 'location_id', 'team_id']

export function isContractInFullProgressBetween(
  contract,
  startDatePeriod,
  endDatePeriod
) {
  const validContractStartDate = contract.contract_start
  const validContractEndDate = contract.contract_end

  const startBeforeStartPeriod = !validContractStartDate
    ? true
    : validContractStartDate && startDatePeriod >= validContractStartDate

  const endAfterEndPeriod = !validContractEndDate
    ? true
    : validContractEndDate && endDatePeriod <= validContractEndDate

  return endAfterEndPeriod && startBeforeStartPeriod
}

export function isCurrentContract(contract) {
  if (this && this.dateISO) {
    return isContractInProgressAt(contract, this.dateISO)
  }

  if (this && this.startDateISO && this.endDateISO) {
    return isContractInProgressBetween(
      contract,
      this.startDateISO,
      this.endDateISO
    )
  }

  throw new Error(
    'You need to pass a context with a value for dateISO or startDateISO and endDateISO'
  )
}

export function getUserFromContract(contract, state) {
  function defaultUser() {
    return new User()
  }

  if (!contract || !state) {
    return defaultUser()
  }

  const membership = state.memberships.getIn(['data', contract.membership_id])

  if (!membership) {
    return defaultUser()
  }

  const user = state.users.getIn(['data', membership.user_id])

  if (user) {
    return user
  }

  return defaultUser()
}

export function costPerHour(contract, socialTax) {
  const {
    hourly_brut: hourlyBrut,
    mensual_brut: mensualBrut,
    contract_time: contractTime,
    contract_type: contractType
  } = contract

  // Return hourly brut for extras
  if (contractType === EXTRA) {
    return hourlyBrut
  }

  if (!mensualBrut || !contractTime) {
    return null
  }

  // Ignore social tax for interns and trainees
  const socialTaxRate = CONTRACT_TYPES_WITH_NO_SOCIAL_TAX.includes(contractType)
    ? 0
    : socialTax

  return (mensualBrut / (contractTime * (52 / 12))) * (1 + socialTaxRate / 100)
}

function hoursToPay(full_time_threshold, overtime_rules, contract_time) {
  if (contract_time <= full_time_threshold || !overtime_rules) {
    return contract_time
  }

  const increased_hours_to_pay = overtime_rules.reduce((sum, overtime_rule) => {
    const min_hours = overtime_rule.get('min_hours')
    let max_hours = overtime_rule.get('max_hours')

    if (contract_time <= min_hours) {
      return sum
    }

    max_hours =
      max_hours && contract_time > max_hours ? max_hours : contract_time
    return (
      sum +
      (max_hours - min_hours) * (1 + overtime_rule.get('percentage') / 100)
    )
  }, 0.0)

  return 35 + increased_hours_to_pay
}

export function hourlyBrut(mensual_brut, contract_time, collective_agreement) {
  if (!mensual_brut || !contract_time) {
    return null
  }

  // Count of week per month -> 12/52 = 4,33
  const weekly_brut = (mensual_brut * 12) / 52
  const hours_to_pay = hoursToPay(
    collective_agreement?.get('full_time_threshold'),
    collective_agreement?.get('overtime_rules'),
    contract_time
  )

  return weekly_brut / hours_to_pay
}

export function mensualBrut(hourly_brut, contract_time, collective_agreement) {
  if (!hourly_brut || !contract_time) {
    return null
  }

  // Count of week per month -> 12/52 = 4,33
  const mensual_brut = hourly_brut / (12 / 52)
  const hours_to_pay = hoursToPay(
    collective_agreement?.get('full_time_threshold'),
    collective_agreement?.get('overtime_rules'),
    contract_time
  )

  return mensual_brut * hours_to_pay
}

export function findAndMergeAmendmentsRelativeToDate(
  state,
  account,
  currentWeekMonday
) {
  const currentWeekSunday = moment(currentWeekMonday)
    .endOf('isoWeek')
    .format('YYYY-MM-DD')
  const socialTax = account && account.get('social_tax')

  return contract => {
    if (!['CDI', 'CDD', 'seasonal'].includes(contract?.contract_type)) {
      return contract
    }

    const amendments = getDataByForeignId(
      state,
      'amendments',
      'user_contract_id',
      contract?.id
    )
      .filter(
        ({ start_date: startDate, end_date: endDate }) =>
          startDate <= currentWeekSunday &&
          (!endDate || endDate >= currentWeekMonday)
      )
      .sortBy(amendment => amendment.start_date)

    const mergedAmendment = amendments.reduce(
      (acc, amendment) =>
        acc.merge(
          amendment
            .toMap()
            .filter(
              keyIn([
                'contract_time',
                'contract_type',
                'mensual_brut',
                'working_days_in_week'
              ])
            )
            .filter(attr => attr)
        ),
      Immutable.Map()
    )

    const newContract = contract.merge(mergedAmendment)
    return newContract.set('cost_per_hour', costPerHour(newContract, socialTax))
  }
}

/**
 * @deprecated TODO: Delete this and use the one from the selectors.
 * use the membership.most_relevant_contract as possible
 * @param contracts
 * @param date
 * @return {*}
 */
export const getMostRelevantContract = (contracts, date) => {
  if (!contracts) {
    return
  }

  if (contracts.size === 1) {
    return contracts.first()
  }

  const currentContract = contracts.find(contract =>
    isContractInProgressAt(contract, date)
  )

  if (currentContract) {
    return currentContract
  }

  const sortedContracts = contracts
    .filter(contract => contract.contract_start)
    .sortBy(contract => contract.contract_start)

  return sortedContracts.last()
}

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.OrderedMap(),
  meta: Immutable.Map({ loading: false, success: false, updated_at: null }),
  relations: Immutable.Map(),
  pagination: Immutable.Map({
    current_page: 1,
    pages: Immutable.Map()
  })
})

function contracts(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_PAGINATED_CONTRACTS:
    case FETCH_CONTRACTS: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], {
        // @ts-ignore migration from js(x) to ts(x)
        loading: (action.params || {}).silent !== true
      })
    }
    case FETCH_PAGINATED_CONTRACTS_FAILED:
    case FETCH_CONTRACTS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_CONTRACTS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const contracts = Immutable.fromJS(action.data)
      const contractsGroupByTeam = listGroupToMapByForeignKeys(
        contracts,
        'team_ids'
      )

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Contract, contracts, contractForeignKeys)
        .mergeDeepIn(['relations', 'team_id'], contractsGroupByTeam)
        .mergeDeepIn(['meta'], {
          loading: false,
          updated_at: Date.now(),
          success: true
        })
    }
    case FETCH_MEMBERSHIPS_SUCCEEDED: {
      const contracts = Immutable.fromJS(
        // @ts-ignore migration from js(x) to ts(x)
        filterFalsy(action.data.map(m => m.most_relevant_contract))
      )
      const contractsGroupByTeam = listGroupToMapByForeignKeys(
        contracts,
        'team_ids'
      )

      return mergeRecords(
        state,
        Contract,
        contracts,
        // @ts-ignore migration from js(x) to ts(x)
        contractForeignKeys
      ).mergeDeepIn(['relations', 'team_id'], contractsGroupByTeam)
    }
    case FETCH_MEMBERSHIP_SUCCEEDED:
    case CREATE_MEMBERSHIP_SUCCEEDED: {
      const contracts = Immutable.fromJS(
        filterFalsy([
          // @ts-ignore migration from js(x) to ts(x)
          ...(action.data.contracts || []),
          // @ts-ignore migration from js(x) to ts(x)
          action.data.most_relevant_contract
        ])
      )
      const contractsGroupByTeam = listGroupToMapByForeignKeys(
        contracts,
        'team_ids'
      )

      return mergeRecords(
        state,
        Contract,
        contracts,
        // @ts-ignore migration from js(x) to ts(x)
        contractForeignKeys
      ).mergeDeepIn(['relations', 'team_id'], contractsGroupByTeam)
    }
    case FETCH_PAGINATED_CONTRACTS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const contracts = Immutable.fromJS(action.data.user_contracts)
      // @ts-ignore migration from js(x) to ts(x)
      const pagination = Immutable.fromJS(action.data.meta)
      // @ts-ignore migration from js(x) to ts(x)
      const currentPage = action.data.meta.current_page
      // @ts-ignore migration from js(x) to ts(x)
      const customPaginationKey = getCustomPaginationKey(action.params)

      return mergeRecords(
        state,
        Contract,
        contracts,
        // @ts-ignore migration from js(x) to ts(x)
        contractForeignKeys
      ).withMutations(map => {
        map.mergeDeepIn(['meta'], {
          loading: false,
          updated_at: Date.now(),
          success: true
        })
        map.mergeDeepIn(['pagination'], pagination)
        map.setIn(
          ['pagination', 'pages', currentPage],
          contracts.map(contract => contract.get('id')).toSet()
        )
        map.mergeDeepIn(['customPagination', customPaginationKey], pagination)
        map.setIn(
          ['customPagination', customPaginationKey, 'pages', currentPage],
          contracts.map(contract => contract.get('id')).toSet()
        )
      })
    }
    case FETCH_ALL_MEMBERSHIPS_SUCCESS: {
      // @ts-ignore migration from js(x) to ts(x)
      const contracts = action.payload.memberships
        .map(m => m.get('most_relevant_contract'))
        .filter(contract => Boolean(contract) && contract.size)

      const contractsGroupByTeam = listGroupToMapByForeignKeys(
        contracts,
        'team_ids'
      )

      return mergeRecords(
        state,
        Contract,
        contracts,
        // @ts-ignore migration from js(x) to ts(x)
        contractForeignKeys
      ).mergeDeepIn(['relations', 'team_id'], contractsGroupByTeam)
    }

    case FETCH_CONTRACT_SUCCEEDED:
    case CREATE_CONTRACT_SUCCEEDED:
    case UPDATE_CONTRACT_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const contract = Immutable.fromJS(action.data)
      const contractsGroupByTeam = listGroupToMapByForeignKeys(
        Immutable.List([contract]),
        'team_ids'
      )

      return updateRecord(
        state,
        Contract,
        contract,
        // @ts-ignore migration from js(x) to ts(x)
        contractForeignKeys
      ).mergeDeepIn(['relations', 'team_id'], contractsGroupByTeam)
    }
    case DELETE_CONTRACT_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { contractId } = action
      const contract = state?.getIn(['data', contractId])

      // @ts-ignore migration from js(x) to ts(x)
      return deleteRecord(state, contractId, contractForeignKeys).withMutations(
        store => {
          contract?.team_ids?.forEach(team_id => {
            store.updateIn(
              ['relations', 'team_id', team_id],
              item_ids => item_ids && item_ids.remove(contractId)
            )
          })
        }
      )
    }
    default:
      return state
  }
}

export default filterActions(contracts, [
  FETCH_CONTRACTS,
  FETCH_CONTRACTS_FAILED,
  FETCH_CONTRACTS_SUCCEEDED,
  FETCH_PAGINATED_CONTRACTS,
  FETCH_PAGINATED_CONTRACTS_SUCCEEDED,
  FETCH_PAGINATED_CONTRACTS_FAILED,
  FETCH_CONTRACT,
  FETCH_CONTRACT_SUCCEEDED,
  FETCH_CONTRACT_FAILED,
  FETCH_MEMBERSHIPS_SUCCEEDED,
  FETCH_MEMBERSHIP_SUCCEEDED,
  CREATE_MEMBERSHIP_SUCCEEDED,
  CREATE_CONTRACT_SUCCEEDED,
  UPDATE_CONTRACT_SUCCEEDED,
  DELETE_CONTRACT_SUCCEEDED,
  FETCH_ALL_MEMBERSHIPS_SUCCESS
])
