import type { MomentInput } from 'moment'
import moment from 'moment'

const ISO_FORMAT_DATETIME_EN = 'YYYY-MM-DD HH:mm' // 1988-08-03
const ISO_FORMAT_DATE_EN = 'YYYY-MM-DD' // 1988-08-03
const ISO_FORMAT_MONTH_EN = 'YYYY-MM' // 1988-08
const ISO_FORMAT_WEEK = 'GGGG-[W]WW' // 1988-[W]34

export const today = moment().format(ISO_FORMAT_DATE_EN)

export const getIsoEnDateTimeFormat = (date: MomentInput) =>
  moment(date).format(ISO_FORMAT_DATETIME_EN)

export const formatToIsoDay = (date: MomentInput) =>
  moment(date).format(ISO_FORMAT_DATE_EN)

export const formatToIsoMonth = (date: MomentInput) =>
  moment(date).format(ISO_FORMAT_MONTH_EN)

export const isIsoDayFormat = (date: MomentInput, strict?: boolean) => {
  return moment(date, ISO_FORMAT_DATE_EN, strict ?? true).isValid()
}

export const formatToIsoWeek = (date: MomentInput) =>
  moment(date).format(ISO_FORMAT_WEEK)

/**
 * Copy only YYYY-MM-DD to the new date,
 * it let the time unchanged
 *
 * ex:
 *
 * input : 01-01-2020 18:00:00
 * toCopy: 02-02-2020 00:00:00
 *
 * copyDateOnly(input, toCopy) // 02-02-2020 18:00:00
 */
export const copyDateOnly = (input, toCopy) => {
  const mtoCopy = moment(toCopy)

  return moment(input)
    .year(mtoCopy.year())
    .month(mtoCopy.month())
    .date(mtoCopy.date())
}

export const dateIsAfternoon = (date: string | Date) => {
  const afternoon = moment(date).set({
    hour: 12,
    minute: 0,
    second: 0,
    millisecond: 0
  })

  return moment(date).isSameOrAfter(afternoon)
}

export const isSameDay = (date1: string | Date, date2: string | Date) => {
  return moment(date1).isSame(date2, 'day')
}

export const getMostRecentDate = (dates: string[]) => {
  return dates.length
    ? moment.max(dates?.map(date => moment(date))).format('YYYY-MM-DD')
    : null
}

export const getOldestDate = (dates: string[]) => {
  return dates.length
    ? moment.min(dates?.map(date => moment(date))).format('YYYY-MM-DD')
    : null
}

export const getFirstDayOfMonth = (month: string) =>
  moment(month).startOf('month').format('YYYY-MM-DD')

export const getLastDayOfMonth = (month: string) =>
  moment(month).endOf('month').format('YYYY-MM-DD')
