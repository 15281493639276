import { billingService } from '@libs/data-access/queries'
import { getStatusString } from '@spa/redux/billing/subscription/utils/getStatusString'
import subscriptionService from '@spa/services/subscription'
import { useEffect } from 'react'

export const useSetAccountStatusCookie = () => {
  const billingAccountStatusQuery = billingService.account.useGetStatus()

  // Set status of the sub for marketing
  useEffect(() => {
    if (billingAccountStatusQuery.data) {
      const statusString = getStatusString(billingAccountStatusQuery.data)
      // statusString can be null if the account is location-based
      if (statusString) {
        subscriptionService.setStatusCookie(statusString)
      }
    }
  }, [billingAccountStatusQuery.data])
}
