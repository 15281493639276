import type { AccountDetails } from '@libs/data-access/entities'
import type {
  ApiErrorBillingResponse,
  UseAppQueryOptions
} from '@libs/data-access/queries'
import { queryClientOptions } from '@libs/data-access/queries'
import { useQuery } from '@tanstack/react-query'

import { fetchAccountDetails } from '../accountDetails.api'
import { accountDetailsCacheKeys } from '../accountDetails.cacheKeys'

import type { AccountDetailsDerivedState } from './utils/AccountDetailsDerivedState.type'
import { computeAccountDetailsDerivedState } from './utils/computeAccountDetailsDerivedState'

export type AccountDetailsWithDerivedState = AccountDetails & {
  derived_state: AccountDetailsDerivedState
}

export const useGetAccountDetailsDerivedState = (
  queryOptions?: UseAppQueryOptions<
    AccountDetails,
    ApiErrorBillingResponse,
    AccountDetailsWithDerivedState
  >
) => {
  return useQuery<
    AccountDetails,
    ApiErrorBillingResponse,
    AccountDetailsWithDerivedState
  >({
    ...queryOptions,
    select: data => {
      return {
        ...data,
        derived_state: computeAccountDetailsDerivedState(data)
      }
    },
    queryKey: accountDetailsCacheKeys.getByAccountId(),
    queryFn: () => fetchAccountDetails(),
    staleTime: queryClientOptions.time.INFINITY
  })
}
