import { billingService } from '@libs/data-access/queries'
import { Banner } from '@libs/ui/ds'
import { TransApp, useCommonAppTranslation } from '@libs/utils/translations'
import { useAuthState } from '@spa/scenes/Auth/shared/services'
import { isImpersonationContext } from '@spa/scenes/Auth/shared/services/authState/authServiceUtils'
import type { FC } from 'react'
import React from 'react'

export const ImpersonationBanner: FC = () => {
  const { t } = useCommonAppTranslation()
  const authState = useAuthState()

  const isCurrentUserImpersonator = isImpersonationContext()

  const accountStatus = billingService.account.useGetDetails({
    enabled: isCurrentUserImpersonator
  })

  if (!accountStatus.isSuccess || !isCurrentUserImpersonator) {
    return null
  }

  return (
    <Banner data-testid='impersonation-banner' variant='danger'>
      <TransApp
        t={t}
        i18nKey='banner.impersonation.message'
        values={{
          accountId: authState.auth.accountId,
          email: `user${authState.auth.userId}@example.com`,
          structure: accountStatus.data.is_location_based
            ? 'Location Based'
            : 'Account Based'
        }}
      />
    </Banner>
  )
}
