import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

export const COUNTERS_MODE = {
  planned: 'planned',
  realized: 'realized',
  monthly: 'monthly'
} as const

export type CountersMode = keyof typeof COUNTERS_MODE
export type SettingsState = {
  countersMode: CountersMode
  sortDaySelected: {
    day: 'YYYY-MM-DD' | string
    updatedAt: string
  }
  favoriteShiftsMode: boolean
}

const initialState: SettingsState = {
  countersMode: 'realized',
  sortDaySelected: {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    day: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    updatedAt: null
  },
  favoriteShiftsMode: false
}

const settingsSlice = createSlice({
  name: 'ui/planning/settings',
  initialState,
  reducers: {
    setCountersMode(
      state,
      action: PayloadAction<SettingsState['countersMode']>
    ) {
      return { ...state, countersMode: action.payload }
    },

    setSortDaySelected(
      state,
      action: PayloadAction<SettingsState['sortDaySelected']['day']>
    ) {
      return {
        ...state,
        sortDaySelected: {
          day: action.payload,
          // Store datetime to track changes events even if the day is the same
          updatedAt: moment().toISOString()
        }
      }
    },

    setFavoriteShiftsMode(
      state,
      action: PayloadAction<SettingsState['favoriteShiftsMode']>
    ) {
      return {
        ...state,
        favoriteShiftsMode: action.payload
      }
    }
  }
})

export const { setCountersMode, setSortDaySelected, setFavoriteShiftsMode } =
  settingsSlice.actions
export default settingsSlice.reducer
