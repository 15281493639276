import { httpServices } from '../../../../apis/http/httpServices'
import type { QueryFnParams } from '../../../../types'

export type FetchFloatingBreaksWarningOptions = QueryFnParams<{
  queryParams: {
    user_contract_id: number
    shift_starts_at: string | null
    shift_ends_at: string | null
  }
}>

/**
 * Fetch floating breaks warning
 */
export const fetchFloatingBreaksWarning = async (
  opts: FetchFloatingBreaksWarningOptions
) => {
  const response = await httpServices.core.get<any>(
    `/payroll/floating_breaks/calculation_warning`,
    {
      params: opts?.queryParams
    }
  )
  return response.data
}
