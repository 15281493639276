import { useScreensMediaQuery } from '@libs/ui/ds/lib/theme'
import type { FC, ReactElement } from 'react'
import { memo } from 'react'

import type { SwitchAtProps } from './SwitchAt.types'

export const SwitchAt: FC<SwitchAtProps> = memo(
  ({ breakpoint, defaultRender = 'larger', larger, smaller }) => {
    const reach = useScreensMediaQuery({
      thresholdType: 'min',
      screenList: breakpoint ? [breakpoint] : []
    })

    if (breakpoint) {
      return (reach === breakpoint ? larger : smaller) as ReactElement
    }

    return (defaultRender === 'larger' ? larger : smaller) as ReactElement
  }
)
