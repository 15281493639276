import { useQuery } from '@tanstack/react-query'
import type {
  PaginatedQueryResponse,
  UseAppQueryOptions
} from 'libs/data-access/queries/src/lib/types'

import type { FetchMemberTimeoffsOptions } from '../membership.timeoff.api'
import { fetchMemberTimeoffs } from '../membership.timeoff.api'
import { membershipTimeoffQueryKeys } from '../membership.timeoff.cacheKeys'
import type { MembershipTimeoffSchema } from '../types/membership.timeoff.schema'

export const useFetchMemberTimeoffs = (
  request: FetchMemberTimeoffsOptions,
  queryOptions?: UseAppQueryOptions<
    PaginatedQueryResponse<MembershipTimeoffSchema, 'timeoffs'>
  >
) =>
  useQuery({
    ...queryOptions,
    queryKey: membershipTimeoffQueryKeys.member_timeoff({
      ...request?.pathParams,
      ...request?.queryParams
    }),
    queryFn: () => fetchMemberTimeoffs(request)
  })
