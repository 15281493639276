import type { GridSpan, GridColumnNumber } from '../Grid.types'

/**
 * Compute percentage width styles
 */
export const computeColumnWidthStyles = (
  span: GridSpan,
  columns: GridColumnNumber
) => {
  if (typeof span !== 'number') {
    return {}
  }

  const percentWidth = `${(span / columns) * 100}%`
  return { flexBasis: percentWidth, maxWidth: percentWidth }
}

/**
 * Compute flex-order styles
 */
export const computeFlexOrderStyles = (order: number) => {
  return { order }
}

/**
 * Compute percentage width styles
 */
export const computeOffsetStyles = (
  offset: GridColumnNumber,
  columns: GridColumnNumber
) => {
  const percentWidth = `${(offset / columns) * 100}%`
  return { marginLeft: percentWidth }
}
