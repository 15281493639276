import { Combobox } from '@libs/ui/ds/lib/components/overlays/Combobox/base/Combobox'
import { Flex } from '@libs/ui/ds/lib/components/primitiveLayouts/Flex/base/Flex'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'

import { useMenuContext } from '../../Menu.context'
import { useMenuGroupContext } from '../MenuGroup/MenuGroup.context'

import type { MenuItemProps } from './MenuItem.types'

const menuGroupCVA = {
  root: cva(['group overflow-hidden'], {
    variants: {
      inInsideGroup: {
        true: 'pl-9'
      }
    }
  })
}

export const MenuItem: FC<MenuItemProps> = ({
  children,
  className,
  rightSection,
  hoveredRightSection,
  ...other
}) => {
  const menuGroupContext = useMenuGroupContext({ throwErrorIfNoContext: false })
  useMenuContext({ throwErrorIfNoContext: true })

  return (
    <Combobox.Option
      value=''
      className={menuGroupCVA.root({
        className,
        inInsideGroup: Boolean(menuGroupContext)
      })}
      rightSection={
        (hoveredRightSection || rightSection) && (
          <Flex row className='items-center justify-center gap-2'>
            {rightSection}
            {hoveredRightSection && (
              <div className='max-h-4 opacity-0 group-hover:opacity-100'>
                {hoveredRightSection}
              </div>
            )}
          </Flex>
        )
      }
      {...other}
    >
      {children}
    </Combobox.Option>
  )
}

MenuItem.displayName = 'MenuItem'
