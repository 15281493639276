import type { Rest, RestRecord, RestType } from '@libs/data-access/entities'
import { CounterType } from '@libs/data-access/entities'
import { useCommonAppTranslation } from '@libs/utils/translations'
import Immutable from 'immutable'

export type RestItem = {
  can_be_bulked: boolean
  can_be_included_in_modulation: 'no' | 'optional' | 'required'
  can_have_custom_value: boolean
  counter_type?: CounterType | null
  background_color?: string
  deductible_from_overtime: boolean
  deductible_from_payroll: boolean
  did_not_show_option: boolean
  has_duration: boolean
  id: RestType
  is_most_used: boolean
  name: string
  monthly_planning_color?: string
  only_for_daily_workers: boolean
  short_name: string
  type: 'day' | 'hour'
  unexpected: boolean
  usable_in_rest_form: boolean
  usable_in_timeoff: boolean
  uses_location_worked_days: boolean
  worked_rest: boolean
  use_in_dashboard_chart: boolean
  canDnd: boolean
  revaluation_hours_in_week_min: number
}

export type RestIterable = Immutable.Iterable<string, RestItem>

export const REST_ACCIDENT_ID = 'work_accident_by_day'
export const REST_ACCIDENT_LEGACY_ID = 'work_accident'

/*
 * This need to be move to be returned by the back-end.
 */

// @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
const REST_MAP: RestIterable = Immutable.OrderedMap<RestItem>({
  weekly_rest: {
    id: 'weekly_rest',
    name: 'models.rests.weekly_rest.name',
    short_name: 'models.rests.weekly_rest.shortName',
    type: 'day',
    did_not_show_option: false,
    has_duration: false,
    deductible_from_payroll: false,
    usable_in_timeoff: false,
    usable_in_rest_form: true,
    background_color: '#b8c0cf',
    is_most_used: true,
    monthly_planning_color: '#A3E1FB',
    revaluation_hours_in_week_min: 1,
    unexpected: false,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'no',
    can_have_custom_value: false,
    can_be_bulked: true,
    use_in_dashboard_chart: false,
    canDnd: true
  },
  temporary_unavailability: {
    id: 'temporary_unavailability',
    name: 'models.rests.temporary_unavailability.name',
    short_name: 'models.rests.temporary_unavailability.shortName',
    type: 'day',
    did_not_show_option: false,
    has_duration: false,
    deductible_from_payroll: false,
    usable_in_timeoff: true,
    usable_in_rest_form: true,
    background_color: '#C37D7D',
    revaluation_hours_in_week_min: 1,
    is_most_used: false,
    monthly_planning_color: '#A3E1FB',
    unexpected: false,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'no',
    can_have_custom_value: false,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  paid_leave: {
    id: 'paid_leave',
    name: 'models.rests.paid_leave.name',
    short_name: 'models.rests.paid_leave.shortName',
    type: 'day',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: false,
    usable_in_timeoff: true,
    usable_in_rest_form: true,
    background_color: '#8FAC9B',
    revaluation_hours_in_week_min: 1,
    is_most_used: true,
    monthly_planning_color: '#4B9EFF',
    unexpected: false,
    worked_rest: false,
    deductible_from_overtime: true,
    uses_location_worked_days: true,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'optional',
    can_have_custom_value: true,
    can_be_bulked: true,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  sick_leave: {
    id: 'sick_leave',
    name: 'models.rests.sick_leave.name',
    short_name: 'models.rests.sick_leave.shortName',
    type: 'day',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: true,
    usable_in_timeoff: false,
    revaluation_hours_in_week_min: 0,
    usable_in_rest_form: true,
    background_color: '#C37D7D',
    is_most_used: true,
    monthly_planning_color: '#FF6C70',
    unexpected: true,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'optional',
    can_have_custom_value: true,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  unpaid_leave: {
    id: 'unpaid_leave',
    name: 'models.rests.unpaid_leave.name',
    short_name: 'models.rests.unpaid_leave.shortName',
    type: 'day',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: true,
    usable_in_timeoff: true,
    usable_in_rest_form: true,
    background_color: '#8FAC9B',
    revaluation_hours_in_week_min: 1,
    is_most_used: false,
    monthly_planning_color: '#F7AE72',
    unexpected: true,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'optional',
    can_have_custom_value: true,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  family_event: {
    id: 'family_event',
    name: 'models.rests.family_event.name',
    short_name: 'models.rests.family_event.shortName',
    type: 'day',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: false,
    usable_in_timeoff: true,
    usable_in_rest_form: true,
    background_color: '#C37D7D',
    revaluation_hours_in_week_min: 1,
    is_most_used: false,
    monthly_planning_color: 'rgba(156,103,255,0.5)',
    unexpected: true,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'optional',
    can_have_custom_value: true,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  holiday: {
    id: 'holiday',
    name: 'models.rests.holiday.name',
    short_name: 'models.rests.holiday.shortName',
    type: 'day',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: false,
    usable_in_timeoff: false,
    usable_in_rest_form: true,
    background_color: '#8FAC9B',
    is_most_used: false,
    revaluation_hours_in_week_min: 1,
    monthly_planning_color: '#F4CAFD',
    unexpected: false,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'optional',
    can_have_custom_value: true,
    can_be_bulked: true,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  work_accident: {
    id: REST_ACCIDENT_LEGACY_ID,
    name: 'models.rests.work_accident.name',
    short_name: 'models.rests.work_accident.shortName',
    type: 'hour',
    did_not_show_option: true,
    has_duration: true,
    deductible_from_payroll: true,
    usable_in_timeoff: false,
    usable_in_rest_form: true,
    revaluation_hours_in_week_min: 0,
    is_most_used: false,
    background_color: '#718099',
    monthly_planning_color: '#FF6C70',
    unexpected: true,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'optional',
    can_have_custom_value: false,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  work_accident_by_day: {
    id: REST_ACCIDENT_ID,
    name: 'models.rests.work_accident_by_day.name',
    short_name: 'models.rests.work_accident_by_day.shortName',
    type: 'day',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: true,
    usable_in_timeoff: false,
    usable_in_rest_form: true,
    revaluation_hours_in_week_min: 0,
    is_most_used: false,
    background_color: '#718099',
    monthly_planning_color: '#FF6C70',
    unexpected: true,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'optional',
    can_have_custom_value: true,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  maternity: {
    id: 'maternity',
    name: 'models.rests.maternity.name',
    short_name: 'models.rests.maternity.shortName',
    type: 'day',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: true,
    usable_in_timeoff: false,
    usable_in_rest_form: true,
    background_color: '#C37D7D',
    revaluation_hours_in_week_min: 0,
    is_most_used: false,
    monthly_planning_color: 'rgba(156,103,255,0.5)',
    unexpected: false,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'optional',
    can_have_custom_value: true,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  compensatory_rest: {
    id: 'compensatory_rest',
    name: 'models.rests.compensatory_rest.name',
    short_name: 'models.rests.compensatory_rest.shortName',
    type: 'hour',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: false,
    usable_in_timeoff: false,
    usable_in_rest_form: true,
    is_most_used: true,
    monthly_planning_color: '#4B9EFF',
    unexpected: false,
    worked_rest: true,
    deductible_from_overtime: false,
    revaluation_hours_in_week_min: 1,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: CounterType.COMPENSATORY,
    can_be_included_in_modulation: 'required',
    can_have_custom_value: false,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: false
  },
  medical_examination: {
    id: 'medical_examination',
    name: 'models.rests.medical_examination.name',
    short_name: 'models.rests.medical_examination.shortName',
    type: 'hour',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: false,
    usable_in_timeoff: false,
    usable_in_rest_form: true,
    is_most_used: false,
    monthly_planning_color: '#F7F399',
    revaluation_hours_in_week_min: 1,
    unexpected: false,
    worked_rest: true,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'required',
    can_have_custom_value: false,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  formation: {
    id: 'formation',
    name: 'models.rests.formation.name',
    short_name: 'models.rests.formation.shortName',
    type: 'hour',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: false,
    usable_in_timeoff: false,
    usable_in_rest_form: true,
    revaluation_hours_in_week_min: 1,
    is_most_used: false,
    monthly_planning_color: '#F7F399',
    unexpected: false,
    worked_rest: true,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'required',
    can_have_custom_value: false,
    can_be_bulked: true,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  unjustified: {
    id: 'unjustified',
    name: 'models.rests.unjustified.name',
    short_name: 'models.rests.unjustified.shortName',
    type: 'hour',
    did_not_show_option: true,
    has_duration: true,
    deductible_from_payroll: true,
    usable_in_timeoff: false,
    usable_in_rest_form: true,
    revaluation_hours_in_week_min: 1,
    is_most_used: false,
    monthly_planning_color: '#F7AE72',
    unexpected: true,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'optional',
    can_have_custom_value: false,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  justified: {
    id: 'justified',
    name: 'models.rests.justified.name',
    short_name: 'models.rests.justified.shortName',
    type: 'hour',
    did_not_show_option: true,
    has_duration: true,
    deductible_from_payroll: true,
    usable_in_timeoff: false,
    usable_in_rest_form: true,
    revaluation_hours_in_week_min: 1,
    is_most_used: false,
    monthly_planning_color: '#F7AE72',
    unexpected: true,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'optional',
    can_have_custom_value: false,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  suspended: {
    id: 'suspended',
    name: 'models.rests.suspended.name',
    short_name: 'models.rests.suspended.shortName',
    type: 'day',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: false,
    usable_in_timeoff: false,
    usable_in_rest_form: true,
    background_color: '#b8c0cf',
    revaluation_hours_in_week_min: 1,
    is_most_used: false,
    monthly_planning_color: '#F7AE72',
    unexpected: true,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'optional',
    can_have_custom_value: true,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  occupational_hazard: {
    id: 'occupational_hazard',
    name: 'models.rests.occupational_hazard.name',
    short_name: 'models.rests.occupational_hazard.shortName',
    type: 'day',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: true,
    usable_in_timeoff: false,
    usable_in_rest_form: true,
    background_color: '#C37D7D',
    revaluation_hours_in_week_min: 0,
    is_most_used: false,
    monthly_planning_color: '#FF6C70',
    unexpected: false,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'optional',
    can_have_custom_value: true,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  paternity: {
    id: 'paternity',
    name: 'models.rests.paternity.name',
    short_name: 'models.rests.paternity.shortName',
    type: 'day',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: true,
    usable_in_timeoff: false,
    usable_in_rest_form: true,
    revaluation_hours_in_week_min: 0,
    background_color: '#C37D7D',
    is_most_used: false,
    monthly_planning_color: 'rgba(156,103,255,0.5)',
    unexpected: false,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'optional',
    can_have_custom_value: true,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  parental_leave: {
    id: 'parental_leave',
    name: 'models.rests.parental_leave.name',
    short_name: 'models.rests.parental_leave.shortName',
    type: 'day',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: true,
    usable_in_timeoff: false,
    usable_in_rest_form: true,
    background_color: '#C37D7D',
    revaluation_hours_in_week_min: 0,
    is_most_used: false,
    monthly_planning_color: 'rgba(156,103,255,0.5)',
    unexpected: false,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'optional',
    can_have_custom_value: true,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  disciplinary_suspension: {
    id: 'disciplinary_suspension',
    name: 'models.rests.disciplinary_suspension.name',
    short_name: 'models.rests.disciplinary_suspension.shortName',
    type: 'day',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: true,
    usable_in_timeoff: false,
    usable_in_rest_form: true,
    background_color: '#b8c0cf',
    is_most_used: false,
    monthly_planning_color: '#F7AE72',
    revaluation_hours_in_week_min: 1,
    unexpected: false,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'optional',
    can_have_custom_value: true,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  technical_unemployment: {
    id: 'technical_unemployment',
    name: 'models.rests.technical_unemployment.name',
    short_name: 'models.rests.technical_unemployment.shortName',
    type: 'day',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: true,
    usable_in_timeoff: false,
    usable_in_rest_form: true,
    revaluation_hours_in_week_min: 0,
    background_color: '#C37D7D',
    is_most_used: false,
    monthly_planning_color: '#B48865',
    unexpected: false,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'optional',
    can_have_custom_value: true,
    can_be_bulked: true,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  late: {
    id: 'late',
    name: 'models.rests.late.name',
    short_name: 'models.rests.late.shortName',
    type: 'hour',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: true,
    usable_in_timeoff: false,
    usable_in_rest_form: false,
    is_most_used: false,
    background_color: '#C37192',
    revaluation_hours_in_week_min: 1,
    unexpected: false,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'optional',
    can_have_custom_value: false,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  rtt: {
    id: 'rtt',
    name: 'models.rests.rtt.name',
    short_name: 'models.rests.rtt.shortName',
    type: 'day',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: false,
    usable_in_timeoff: true,
    usable_in_rest_form: true,
    background_color: '#8FAC9B',
    revaluation_hours_in_week_min: 1,
    is_most_used: false,
    monthly_planning_color: '#4B9EFF',
    unexpected: false,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: undefined,
    can_be_included_in_modulation: 'no',
    can_have_custom_value: true,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  clothing_compensatory_rest: {
    id: 'clothing_compensatory_rest',
    name: 'models.rests.clothing_compensatory_rest.name',
    short_name: 'models.rests.clothing_compensatory_rest.shortName',
    type: 'day',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: false,
    usable_in_timeoff: false,
    usable_in_rest_form: true,
    background_color: '#8FAC9B',
    is_most_used: false,
    monthly_planning_color: '#4B9EFF',
    revaluation_hours_in_week_min: 1,
    unexpected: false,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'optional',
    can_have_custom_value: true,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  night_compensatory_rest: {
    id: 'night_compensatory_rest',
    name: 'models.rests.night_compensatory_rest.name',
    short_name: 'models.rests.night_compensatory_rest.shortName',
    type: 'day',
    did_not_show_option: false,
    has_duration: true,
    deductible_from_payroll: false,
    usable_in_timeoff: false,
    usable_in_rest_form: true,
    background_color: '#8FAC9B',
    revaluation_hours_in_week_min: 1,
    is_most_used: false,
    monthly_planning_color: '#4B9EFF',
    unexpected: false,
    worked_rest: false,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    counter_type: null,
    can_be_included_in_modulation: 'optional',
    can_have_custom_value: true,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  default_rest: {
    id: 'default_rest',
    name: 'models.rests.default_rest.name',
    short_name: 'models.rests.default_rest.shortName',
    type: 'day',
    worked_rest: false,
    did_not_show_option: false,
    has_duration: false,
    deductible_from_payroll: false,
    usable_in_timeoff: false,
    is_most_used: false,
    usable_in_rest_form: true,
    revaluation_hours_in_week_min: 1,
    background_color: '#444444',
    monthly_planning_color: '#444444',
    unexpected: false,
    counter_type: null,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    can_be_included_in_modulation: 'no',
    can_have_custom_value: true,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: true
  },
  public_holiday_compensatory: {
    id: 'public_holiday_compensatory',
    name: 'models.rests.public_holiday_compensatory.name',
    short_name: 'models.rests.public_holiday_compensatory.shortName',
    type: 'hour',
    worked_rest: false,
    did_not_show_option: false,
    has_duration: false,
    deductible_from_payroll: false,
    usable_in_timeoff: false,
    is_most_used: false,
    usable_in_rest_form: true,
    revaluation_hours_in_week_min: 1,
    background_color: '#8FAC9B',
    unexpected: false,
    counter_type: CounterType.PUBLIC_HOLIDAY,
    deductible_from_overtime: false,
    uses_location_worked_days: false,
    only_for_daily_workers: false,
    can_be_included_in_modulation: 'no',
    can_have_custom_value: false,
    can_be_bulked: false,
    use_in_dashboard_chart: true,
    canDnd: false
  }
})

export const RestTypesType = {
  HOUR: 'hour',
  DAY: 'day'
}

export const CUSTOM = 'custom'
export const DEFAULT = 'default'

export const REST_TYPE_IDS: string[] = Object.values(
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  REST_MAP.map(i => i.id).toJS()
)
export const DEFAULT_REST = REST_MAP.get('default_rest')
export const MONTHLY_PLANNING_COLOR = REST_MAP.filter(r =>
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  Boolean(r.monthly_planning_color)
)
export const PAID_LEAVE = 'paid_leave'

export const getRestConfig = (rest?: Rest): RestItem => {
  if (!rest) {
    return DEFAULT_REST
  }
  return REST_MAP.get(rest?.rest_type, DEFAULT_REST)
}

export const getRestName = (restType?: string) => {
  if (!restType) {
    return ''
  }

  return REST_MAP.get(restType).name
}
export const useRestName = (restType?: string) => {
  const { t } = useCommonAppTranslation()

  return restType ? t(getRestName(restType)) : t(getRestName('default_rest'))
}

export const isRestDay = (rest: Rest): boolean => rest.duration_type === 'daily'

export const isRestHour = (rest: Rest): boolean =>
  rest.duration_type === 'hourly'

export const filterRestsBy = (
  rests: Immutable.Map<number, RestRecord>,
  cb: (v: RestItem) => boolean
) => {
  return rests.filter(rest => cb(getRestConfig(rest)))
}

export const isRestDayType = (rest: Rest | any): boolean => {
  const restItem = getRestConfig(rest)
  return restItem.type === RestTypesType.DAY
}

export const isRestCard = (rest: Rest) => isRestHour(rest)

export default REST_MAP
