import {
  useGetAccountFlipperFeatureFlags,
  userService,
  wsServices
} from '@libs/data-access/queries'
import { Loader, SetDesignSystemConfig } from '@libs/ui/ds'
import {
  errorLoggerService,
  errorMonitoringService
} from '@libs/utils/error-monitoring'
import i18n, { datesService } from '@libs/utils/translations'
import { AppNavBar } from '@spa/components/AppNavBar/AppNavBar'
import { useInitTitle } from '@spa/core/browser'
import { useInitGTM } from '@spa/core/externals/gtm'
import { useInitMaze } from '@spa/core/externals/maze'
import { trackerService } from '@spa/core/externals/segment'
import { UpscopeScript } from '@spa/core/externals/upscope/UpscopeScript'
import { fetchAddons } from '@spa/redux/billing/addons/modules/addons'
import { fetchLocationBilling } from '@spa/redux/billing/locations/modules/locationBilling'
import { fetchBillingPlans } from '@spa/redux/billing/plan/modules/plan'
import { useMemoSelector } from '@spa/redux/hooks'
import { useAuthState, useClearAuth } from '@spa/scenes/Auth/shared/services'
import { isNumber } from 'lodash-es'
import type { FC } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchAccount } from 'snap-redux/modules/accounts'
import { getCurrentAccount } from 'snap-redux/modules/accounts/selectors'
import { fetchCountryConfigurations } from 'snap-redux/modules/countryConfigurations'
import { fetchLocations } from 'snap-redux/modules/locations'
import { getCurrentMembershipSelector } from 'snap-redux/modules/memberships/selectors'
import { fetchUnreadNotifications } from 'snap-redux/modules/notifications'
import { openSocketConnection } from 'snap-redux/modules/socket'
import { fetchSocketAuthorizationTicket } from 'snap-redux/modules/socketAuthorizationTickets'
import { fetchUser } from 'snap-redux/modules/users'
import { getCurrentUser } from 'snap-redux/modules/users/selectors'
import { useActions } from 'snap-redux/utils'

import { AuthenticatedAppRoutes } from './AuthenticatedApp.routes'
import ExpiredTrialRoutes from './ExpiredTrial.routes'
import { useCanAccessApp } from './authorization/useCanAccessApp'
import { Banners, TimezoneInit } from './components'
import { useSetAccountStatusCookie } from './utils/useSetAccountStatusCookie'

// Async action creators
const actionCreators = {
  fetchUser,
  fetchAccount,
  fetchSocketAuthorizationTicket,
  fetchLocations,
  fetchAddons,
  fetchBillingPlans
}

export const AuthenticatedApp: FC = () => {
  const dispatch = useDispatch()
  const authState = useAuthState()
  const clearAuth = useClearAuth()
  const actions = useActions(actionCreators)

  const account = useMemoSelector(getCurrentAccount)
  const accessAppQuery = useCanAccessApp()

  const userMeQuery = userService.user.useFetchCurrent({})
  trackerService.useInitSegment()
  trackerService.useSegment()
  useInitGTM()
  useInitTitle()
  useSetAccountStatusCookie()
  useInitMaze()

  const accountFlipperFeatureFlags = useGetAccountFlipperFeatureFlags()
  const currentUser = useMemoSelector(getCurrentUser)
  const coreWSServiceConnect = wsServices.core.ws.useConnect()
  const currentMembership = useMemoSelector(getCurrentMembershipSelector)

  // componentDidMount
  useEffect(() => {
    dispatch(
      fetchCountryConfigurations({
        account_id: authState.auth.accountId as number
      })
    )

    Promise.all([
      actions.fetchUser(authState.auth.userId),
      actions.fetchAccount(authState.auth.accountId, {}),
      actions.fetchLocations(authState.auth.accountId)
    ])
      .then(([user, account]) => {
        errorMonitoringService.identify(user, account)
      })
      .catch(() => {
        clearAuth()
      })
  }, [])

  // componentDidMount. This should be call ONLY once.
  useEffect(() => {
    if (!isNumber(authState.auth.accountId)) {
      return
    }
    const account_id = authState.auth.accountId
    // Call the subscription for the account
    dispatch(fetchLocationBilling({ account_id }))

    const fetchWsTicketPromise = accountId => {
      return actions.fetchSocketAuthorizationTicket(accountId, {
        account_id: accountId
      })
    }

    fetchWsTicketPromise(authState.auth.accountId).then((response: any) => {
      // @ts-ignore migration from js(x) to ts(x)
      dispatch(openSocketConnection(response.ticket))
    }, errorLoggerService.error)
  }, [actions, authState.auth.accountId, dispatch])

  // Call once the subscription data only for the admin
  useEffect(() => {
    if (isNumber(authState.auth.accountId)) {
      actions.fetchAddons()
      actions.fetchBillingPlans()
    }
  }, [actions, authState.auth.accountId])

  useEffect(() => {
    if (userMeQuery.data) {
      const locale = userMeQuery.data.locale.toLowerCase()
      i18n.changeLanguage(locale)
      datesService.setLocale(locale)
    }
    if (currentMembership) {
      // @ts-ignore migration from js(x) to ts(x)
      dispatch(fetchUnreadNotifications(currentMembership?.id))
    }
  }, [currentMembership, userMeQuery.data, dispatch])

  if (
    !currentUser ||
    !account ||
    !userMeQuery.isSuccess ||
    coreWSServiceConnect.isPending ||
    accountFlipperFeatureFlags.isLoading
  ) {
    return <Loader.Overlay />
  }

  return (
    <div className='h-full sm:w-full md:min-w-screen-md'>
      <SetDesignSystemConfig
        config={{
          timezone: account?.time_zone,
          language: userMeQuery.data.locale.toLowerCase()
        }}
      />
      <UpscopeScript />
      <TimezoneInit account={account} />
      <Banners isBillingError={accessAppQuery.isError} />
      <AppNavBar account={account} />

      {accessAppQuery.canAccessApp ? (
        <AuthenticatedAppRoutes />
      ) : (
        <ExpiredTrialRoutes />
      )}
    </div>
  )
}
