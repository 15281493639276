import { useUncontrolled } from '@mantine/hooks'
import { cva } from 'class-variance-authority'
import cn from 'classnames'
import type { FC } from 'react'

import { InlineInputWrap } from '../../../../forms/InlineInputWrap/base/InlineInputWrap'
import { Stack } from '../../../../primitiveLayouts/Stack/base/Stack'

import { RadioButtonGroupProvider } from './RadioButtonGroup.context'
import type { RadioButtonGroupProps } from './RadioButtonGroup.types'

const radioButtonGroupCVA = {
  fieldSection: cva(['mt-2 flex'], {
    variants: {
      position: {
        'top-inline': 'flex-row gap-8',
        'top-stacked': 'flex-col items-start gap-2'
      }
    }
  }),
  props: {
    position: {
      'top-inline': 'top',
      'top-stacked': 'top'
    }
  }
} as const

export const RadioButtonGroup: FC<RadioButtonGroupProps> = ({
  label,
  desc,
  errorMessage,
  disabled,
  required,
  value,
  name,
  position,
  onChange,
  className,
  classNames,
  children
}) => {
  const [state, setState] = useUncontrolled<string>({
    onChange,
    value,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    defaultValue: null
  })

  return (
    <RadioButtonGroupProvider
      value={{
        value: state,
        name,
        disabled,
        onChange: setState
      }}
    >
      <InlineInputWrap
        className={cn(className, classNames?.root)}
        label={label}
        desc={desc}
        errorMessage={errorMessage}
        disableLabelInteraction
        required={required}
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        position={radioButtonGroupCVA.props.position[position]}
        field={
          <Stack
            className={radioButtonGroupCVA.fieldSection({
              className: classNames?.inner,
              position
            })}
          >
            {children}
          </Stack>
        }
      />
    </RadioButtonGroupProvider>
  )
}
