import { getCurrentLocationAndTeam } from './getCurrentLocationAndTeam/getCurrentLocationAndTeam'
import { getCurrentSelectedTeams } from './getCurrentSelectedTeams/getCurrentSelectedTeams'
import { getPlanningTeams } from './getPlanningTeams/getPlanningTeams'
import { teamsBaseSelectors } from './teamsBaseSelectors'

export const teamsSelectors = {
  ...teamsBaseSelectors,
  getPlanningTeams,
  getCurrentSelectedTeams,
  getCurrentLocationAndTeam
}
