import { CalendarIcon } from '@libs/ui/ds/assets/icons'
import type { FC } from 'react'

import { InputWrap } from '../../../../forms/InputWrap/base/InputWrap'
import { InputBase } from '../../../../inputs/InputBase/base/InputBase'
import { Popover } from '../../../../overlays/Popover/base/Popover'
import { Flex } from '../../../../primitiveLayouts/Flex/base/Flex'
import { CalendarPicker } from '../../../datePickerUtils/subs/CalendarPicker'
import { CalendarRangeArrow } from '../../../datePickerUtils/subs/CalendarRangeArrow'
import { DatePickerFocusWrap } from '../../../datePickerUtils/subs/DatePickerFocusWrap'
import { DatePickerInputClearButton } from '../../../datePickerUtils/subs/DatePickerInputClearButton'
import { monthRangeConfig } from '../../../datePickerUtils/utils/monthRangeConfig'
import { useDatePickerOpened } from '../../../datePickerUtils/utils/useDatePickerOpened/useDatePickerOpened'

import type { MonthRangePickerInputProps } from './MonthRangePickerInput.types'

export const MonthRangePickerInput: FC<MonthRangePickerInputProps> = ({
  value,
  defaultValue,
  onChange,

  /** Popover props */
  onOpenedChange,
  opened,
  dropdownPosition = 'bottom-start',

  /** InputWrap Props */
  label,
  disabled = false,
  className,
  required,
  errorMessage,
  desc,
  readOnly = false,
  rightSection,
  onClear,
  clearable = false,
  pickerOnly,

  /** Calendar Props */
  allowSingleDateInRange,
  maxLevel,
  placeholder,
  excludeDate,
  minDate,
  maxDate,
  defaultDate,
  ...other
}) => {
  const openedValues = useDatePickerOpened({ onOpenedChange, opened })
  const monthRangeSync = monthRangeConfig.useSyncDate({
    value,
    defaultValue,
    onChange,
    minDate,
    maxDate,
    defaultDate
  })

  return (
    <Popover
      dropdownHeight='l'
      dropdownWidth='auto'
      dropdownPosition={dropdownPosition}
      opened={openedValues.opened}
      disabled={disabled || readOnly}
    >
      <DatePickerFocusWrap setOpen={openedValues.setOpened}>
        <InputWrap
          label={label}
          disabled={disabled}
          className={className}
          required={required}
          errorMessage={errorMessage || monthRangeSync.errorMessage}
          desc={desc}
          {...other}
          field={
            <Popover.Target>
              <Flex row className='flex-nowrap items-center gap-2'>
                <InputBase
                  className='grow'
                  data-testid='month-picker-input'
                  value={monthRangeSync.strValue?.start}
                  onChange={e => monthRangeSync.onChangeStart(e.target.value)}
                  type='month'
                  placeholder={placeholder}
                  readOnly={readOnly || pickerOnly}
                  disabled={disabled}
                  error={Boolean(errorMessage || monthRangeSync.errorMessage)}
                  leftIcon={<CalendarIcon />}
                  rightSection={
                    <DatePickerInputClearButton
                      disabled={disabled}
                      readOnly={readOnly}
                      fallback={rightSection}
                      clearable={clearable}
                      onClear={e => {
                        onClear?.(e)
                        monthRangeSync.resetStart()
                      }}
                    />
                  }
                />
                <CalendarRangeArrow />
                <InputBase
                  className='grow'
                  data-testid='month-picker-input'
                  value={monthRangeSync.strValue?.end}
                  onChange={e => monthRangeSync.onChangeEnd(e.target.value)}
                  type='month'
                  placeholder={placeholder}
                  readOnly={readOnly || pickerOnly}
                  disabled={disabled}
                  error={Boolean(errorMessage || monthRangeSync.errorMessage)}
                  leftIcon={<CalendarIcon />}
                  rightSection={
                    <DatePickerInputClearButton
                      disabled={disabled}
                      readOnly={readOnly}
                      fallback={rightSection}
                      clearable={clearable}
                      onClear={e => {
                        onClear?.(e)
                        monthRangeSync.resetEnd()
                      }}
                    />
                  }
                />
              </Flex>
            </Popover.Target>
          }
        />
        <Popover.Dropdown>
          <CalendarPicker
            value={monthRangeSync.value}
            date={monthRangeSync.date}
            onDateChange={monthRangeSync.onDateChange}
            onChange={monthRangeSync.onChange}
            minDate={monthRangeSync.minDate}
            maxDate={monthRangeSync.maxDate}
            defaultDate={monthRangeSync.defaultDate}
            type='range'
            componentType='month'
            allowSingleDateInRange={allowSingleDateInRange}
            numberOfColumns={2}
            maxLevel={maxLevel}
            excludeDate={excludeDate}
          />
        </Popover.Dropdown>
      </DatePickerFocusWrap>
    </Popover>
  )
}

export type { MonthRangePickerInputProps } from './MonthRangePickerInput.types'
