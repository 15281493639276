import { GTM_APP_ID } from '@libs/utils/environments'
import { useEffect } from 'react'

const elfsightClassName = 'elfsight-app-1e2ca96e-5f0b-4021-8da0-3903c71954b7'

/**
 * @description hook to manage marketing widget with elfsight library.
 */
export const useInitElfsight = () => {
  useEffect(() => {
    if (
      GTM_APP_ID &&
      (navigator.languages.includes('fr') ||
        navigator.languages.includes('fr-FR')) &&
      !document.getElementsByClassName(elfsightClassName)?.length
    ) {
      const script = document.createElement('script')
      script.src = 'https://apps.elfsight.com/p/platform.js'
      script.defer = true
      script.id = 'elfsight-script'
      document.body.appendChild(script)

      const divElement = document.createElement('div')
      divElement.setAttribute('id', 'elfsight-reviews')
      divElement.className += `${elfsightClassName} mb-16`

      const elsightElement = document.getElementById('elfsight-sign-up')
      elsightElement?.append(divElement)
    }

    return () => {
      if (GTM_APP_ID) {
        document.getElementById('elfsight-reviews')?.remove()
        document.getElementById('elfsight-script')?.remove()
      }
    }
  }, [])
}
