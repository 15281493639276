import type { FC, PropsWithChildren } from 'react'
import React from 'react'

type DatePickerFocusWrapProps = PropsWithChildren & {
  setOpen: (open: boolean) => void
}

export const DatePickerFocusWrap: FC<DatePickerFocusWrapProps> = ({
  setOpen,
  children
}) => {
  return (
    <div onFocus={() => setOpen(true)} onBlur={() => setOpen(false)}>
      {children}
    </div>
  )
}
