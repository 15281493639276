import type { MomentInput } from 'moment'
import moment from 'moment'

type Break = {
  planned_duration: number
  real_duration: number
  planned_starts_at: string
  planned_ends_at: string
  real_starts_at: string
  real_ends_at: string
}

const findOriginalBreakDate = (
  breakStart: MomentInput,
  shiftStart: MomentInput,
  date: MomentInput
) => {
  const sameDateAsShift = moment(breakStart).isSame(shiftStart, 'day')
  return sameDateAsShift ? moment(date) : moment(date).add(1, 'day')
}

const setDateDefaults = <I extends Break>(
  item: I,
  shiftStart: MomentInput,
  date: MomentInput
) => {
  const breakStart = item.planned_starts_at
  const breakEnd = item.planned_ends_at
  const originalDate = findOriginalBreakDate(breakStart, shiftStart, date)

  const edited = {
    start: moment(breakStart)
      .year(originalDate.year())
      .month(originalDate.month())
      .date(originalDate.date()),
    end: moment(breakEnd)
      .year(originalDate.year())
      .month(originalDate.month())
      .date(originalDate.date())
  }

  return {
    ...item,
    real_duration: item.planned_duration,
    planned_starts_at: edited.start.toISOString(),
    planned_ends_at: edited.end.toISOString(),
    real_starts_at: edited.start.toISOString(),
    real_ends_at: edited.end.toISOString()
  }
}

export default setDateDefaults
