import { cva } from 'class-variance-authority'

export const toastItemClasses = {
  root: cva(['rounded-md bg-neutral-800 px-5 py-4 before:content-none']),
  closeButton: cva([
    'box-content !rounded-full bg-none p-1 text-neutral-50 hover:!bg-neutral-50/10 active:!bg-neutral-50/20',
    '!translate-x-0 !translate-y-0'
  ]),
  body: cva(['flex items-center gap-4 font-normal']),
  icon: cva([''], {
    variants: {
      intent: {
        info: 'text-semantic-blue-400',
        success: 'text-semantic-green-400',
        warning: 'text-semantic-orange-400',
        error: 'text-semantic-red-400',
        loading: 'text-neutral-50'
      }
    }
  }),
  text: cva(['flex flex-col gap-1 text-xs !text-neutral-50/90']),
  title: cva(['text-s font-medium text-neutral-50'])
}
