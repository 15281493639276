import classnames from 'classnames'
import React from 'react'
import type { Message } from 'react-hook-form'

import styles from './FieldError.module.css'

type Props = {
  spaceTop?: boolean
  error?: Message
}

export const FieldError: React.FC<Props> = ({ error, spaceTop = true }) => {
  if (!error) {
    return null
  }

  return (
    <div className={classnames(styles.Error, { [styles.spaceTop]: spaceTop })}>
      {error}
    </div>
  )
}
