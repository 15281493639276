import { setParams } from '@spa/scenes/Plannings/redux/reducers'
import moment from 'moment'
import { select, put } from 'redux-saga/effects'
import { getCurrentAccount } from 'snap-redux/modules/accounts/selectors'
import { getTeamsByLocationId } from 'snap-redux/modules/teams/selectors'

import { planningDateChange } from './planningDateChange'
import { planningLocationAndTeamChange } from './planningLocationAndTeamChange'
import { planningLocationChange } from './planningLocationChange'
import { planningTeamChange } from './planningTeamChange'

function* planningRefreshSaga({
  payload: { locationId, teamId, date, isMonthViewV2, skipFetch }
}) {
  const start = isMonthViewV2 ? moment(date) : moment(date).startOf('isoWeek')
  const end = start.clone().endOf('isoWeek')
  const account = yield select(getCurrentAccount)
  const teams = yield select(state => getTeamsByLocationId(state, locationId))

  // DERIVED DATA
  const teamIds = teamId ? [teamId] : teams.map(t => t.id).toArray()

  const oldParams = yield select(state => state.planningWeekly.get('params'))
  const newParams = {
    locationId,
    teamId,
    date: start.format('YYYY-MM-DD') // the old date from the store is the start of the week
  }

  yield put(setParams(newParams))

  // in Month View v2 when changing month we don't need to fetch data
  if (skipFetch) {
    return
  }

  const changed = {
    date: oldParams.get('date') !== newParams.date,
    location: oldParams.get('locationId') !== newParams.locationId,
    team: oldParams.get('teamId') !== newParams.teamId
  }

  // DATE HAS CHANGED
  if (changed.date) {
    yield planningDateChange({
      teamIds,
      start,
      end,
      account,
      locationId: newParams.locationId,
      teamId: newParams.teamId
    })
  }

  // BOTH LOCATION AND TEAM HAVE CHANGED
  if (!changed.date && changed.team && changed.location) {
    yield planningLocationAndTeamChange({
      teamIds,
      start,
      end,
      account,
      locationId: newParams.locationId,
      teamId: newParams.teamId
    })
  }

  // LOCATION HAS CHANGED
  if (!changed.date && !changed.team && changed.location) {
    yield planningLocationChange({
      teamIds,
      start,
      end,
      account,
      locationId: newParams.locationId,
      teamId: newParams.teamId
    })
  }

  // TEAM HAS CHANGED
  if (!changed.date && !changed.location && changed.team) {
    yield planningTeamChange({
      start,
      end,
      locationId: newParams.locationId,
      teamId: newParams.teamId,
      accountId: account.id
    })
  }

  yield put({ type: 'ui/PLANNING_REFRESH_SUCCESS' })
}

export default planningRefreshSaga
