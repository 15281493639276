import type { ActivitySchemaQuery } from '@libs/data-access/entities'

import { httpServices } from '../../../apis'
import type { QueryFnParams } from '../../../types'

export type FetchActivities = QueryFnParams<{
  queryParams: {
    start_date: string
    end_date: string
    page: number
    activity_type?: string
    key?: string
    location_id?: string | number
    team_id?: string | number
  }
}>

export const fetchActivities = async (options: FetchActivities) => {
  const response = await httpServices.core.get<ActivitySchemaQuery>(
    `/accounts/${httpServices.authInfo.accountId}/activities`,
    {
      params: options.queryParams
    }
  )

  return response.data
}
