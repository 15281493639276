import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type PaginationPaidLeavesLedgerState = {
  current_page: number
  per_page: number
  total_count: number
  total_pages: number
}

const initialState: PaginationPaidLeavesLedgerState = {
  current_page: 0,
  per_page: 0,
  total_count: 0,
  total_pages: 0
}

const paramsSlice = createSlice({
  name: 'emp/paidLeavesLedger/pagination',
  initialState,
  reducers: {
    setPagination(_, action: PayloadAction<PaginationPaidLeavesLedgerState>) {
      return {
        ...action.payload
      }
    },
    resetPagination() {
      return {
        current_page: 0,
        per_page: 0,
        total_count: 0,
        total_pages: 0
      }
    }
  }
})

export const { setPagination, resetPagination } = paramsSlice.actions
export default paramsSlice.reducer
