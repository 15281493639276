import { cva } from 'class-variance-authority'
import type { ElementType, FC } from 'react'

import type { TitleProps } from './Title.types'

const titleCVA = cva(['text-neutral-800'], {
  variants: {
    size: {
      1: ['font-display text-3xl font-bold leading-12 tracking-wide'],
      2: ['font-display text-xxl font-bold leading-10 tracking-wide'],
      3: ['font-display text-xl font-bold leading-8 tracking-wide'],
      4: ['font-sans text-ml font-semibold leading-6'],
      5: ['font-sans text-m font-semibold leading-6'],
      6: ['font-sans text-s font-medium leading-5']
    }
  }
})

export const Title: FC<TitleProps> = ({
  as,
  size = 1,
  className,
  children,
  ...other
}) => {
  const assertedAs = as || `h${size}`
  const Component = assertedAs as ElementType

  return (
    <Component {...other} className={titleCVA({ size, className })}>
      {children}
    </Component>
  )
}

export type { TitleProps } from './Title.types'
