import { useUncontrolledInput } from '@libs/ui/ds'
import { forwardRef, memo } from 'react'

import { InputWrap } from '../../../forms/InputWrap/base/InputWrap'
import { formatMaxLengthCounter } from '../../../forms/InputWrap/utils/formatMaxLengthCounter'
import { InputBase } from '../../InputBase/base/InputBase'

import type { TextInputProps } from './TextInput.types'

const BaseTextInput = memo(
  forwardRef<HTMLInputElement, TextInputProps>(
    (
      {
        label,
        desc,
        errorMessage,
        helperText,
        required,
        disabled = false,
        fillWidth,
        className,
        name,
        labelRightSection,
        placeholder,
        maxLength,
        value,
        onChange,
        ...other
      },
      ref
    ) => {
      const [controlledValue, setControlledValue] = useUncontrolledInput({
        value,
        onChange
      })

      return (
        <InputWrap
          label={label}
          desc={desc}
          errorMessage={errorMessage}
          helperText={
            formatMaxLengthCounter({ value: controlledValue, maxLength }) ||
            helperText
          }
          labelRightSection={labelRightSection}
          required={required}
          disabled={disabled}
          htmlFor={name}
          fillWidth={fillWidth}
          className={className}
          field={
            <InputBase
              ref={ref}
              type='text'
              aria-label={label as string}
              aria-placeholder={placeholder}
              placeholder={placeholder}
              error={Boolean(errorMessage)}
              disabled={disabled}
              name={name}
              maxLength={maxLength}
              aria-required={required}
              required={required}
              value={controlledValue}
              onChange={setControlledValue}
              {...other}
            />
          }
        />
      )
    }
  )
)

const TypedTextInput = BaseTextInput
TypedTextInput.displayName = 'TextInput'

export type { TextInputProps } from './TextInput.types'
export const TextInput = TypedTextInput
