export const RewardStarsIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.06066 10.4886C4.40123 10.006 6.22961 8.15648 6.69713 5.80036C6.79242 5.32074 6.89962 4.84113 7.01874 4.36451C7.10807 4.0018 7.44159 3.75 7.81382 3.75H8.41832C8.78757 3.756 9.11215 4.00779 9.20148 4.36751C9.31166 4.81415 9.41291 5.26379 9.50522 5.71643C9.98167 8.08453 11.822 9.94305 14.1774 10.4107C14.6718 10.5096 15.1631 10.6175 15.6515 10.7404C16.0058 10.8273 16.25 11.1511 16.25 11.5198V12.1523C16.25 12.518 16.0028 12.8447 15.6485 12.9317C15.172 13.0486 14.6926 13.1565 14.2132 13.2494C11.8458 13.72 9.99061 15.5815 9.51713 17.9616C9.42184 18.4412 9.31464 18.9179 9.19851 19.3915C9.10917 19.7512 8.78459 20 8.41534 20H7.81382C7.44754 20 7.12296 19.7512 7.03363 19.3915C6.91749 18.9329 6.81327 18.4712 6.72095 18.0066C6.24152 15.6415 4.40123 13.789 2.04874 13.3183C1.56336 13.2194 1.07797 13.1115 0.598543 12.9916C0.244181 12.9047 0 12.5779 0 12.2122V11.6067C0 11.244 0.244182 10.9203 0.595565 10.8303C1.08393 10.7044 1.57229 10.5935 2.06661 10.4916L2.06066 10.4886Z'
        fill='currentColor'
      />
      <path
        d='M16.6209 0C17.568 2.08279 17.4715 5.38665 16.6136 7.5H15.8876C15.3854 6.28259 15.2448 5.03219 15.2131 3.75C15.2057 2.47637 15.3402 1.17829 15.8791 0H16.6209Z'
        fill='currentColor'
      />
      <path
        d='M12.5 3.37965C14.5825 2.43237 17.8858 2.52893 20 3.38698V4.11302C18.7828 4.61539 17.5326 4.75595 16.2506 4.78773C14.9772 4.79506 13.6793 4.66061 12.5012 4.12158L12.5 3.37965Z'
        fill='currentColor'
      />
    </svg>
  )
}
