import { useMutation } from '@tanstack/react-query'

import type { ApiErrorResponse, UseAppMutationOptions } from '../../../../types'
import { duplicateShift, type DuplicatePlannable } from '../monthly.api'
import { monthlyServiceCacheKeys } from '../monthly.cacheKeys'

export const useDuplicateShift = (
  mutationOpts?: UseAppMutationOptions<
    null,
    ApiErrorResponse,
    DuplicatePlannable
  >
) => {
  return useMutation<null, ApiErrorResponse, DuplicatePlannable>({
    ...mutationOpts,
    mutationKey: monthlyServiceCacheKeys.shift.duplicate(),
    mutationFn: (opts: DuplicatePlannable) => duplicateShift(opts)
  })
}
