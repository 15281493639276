import { cookiesService } from '@libs/utils/cookies'
import { INTERCOM_API_SECRET } from '@libs/utils/environments'
import { useAuthState } from '@spa/scenes/Auth/shared/services'
import encHex from 'crypto-js/enc-hex'
import HmacSHA256 from 'crypto-js/hmac-sha256'
import { useMemo } from 'react'

const createIntercomUserHash = (key?: string) => {
  return HmacSHA256(key, INTERCOM_API_SECRET).toString(encHex) as string
}

export const useCreateIntercomUserHash = () => {
  const authState = useAuthState()
  const currentUserId =
    authState.auth.userId || cookiesService.readLastSignedInUserId()

  const userHash = useMemo(() => {
    if (!currentUserId) {
      return undefined
    }

    return createIntercomUserHash(String(currentUserId))
  }, [currentUserId])

  return { userHash, userId: String(currentUserId) }
}
