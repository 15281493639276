import type { MomentInput } from 'moment'
import moment from 'moment'

export const getWeekRange = (date: MomentInput) => {
  return {
    momentStart: moment(date).startOf('isoWeek'),
    momentEnd: moment(date).endOf('isoWeek'),

    dayStart: moment(date).startOf('isoWeek').format('YYYY-MM-DD'),
    dayEnd: moment(date).endOf('isoWeek').format('YYYY-MM-DD')
  }
}
