import { billingService } from '@libs/data-access/queries'
import { Anchor, Banner } from '@libs/ui/ds'
import { useCommonAppTranslation } from '@libs/utils/translations'
import { routes } from '@spa/core/router'
import useUpgradeUrl from '@spa/redux/billing/plan/hooks/useUpgradeUrl'
import moment from 'moment'
import React from 'react'
import { Link, generatePath } from 'react-router-dom'

export const CancelledBanner: React.FC = () => {
  const { t } = useCommonAppTranslation()

  const detailsQuery = billingService.account.useGetDetails()
  const statusQuery = billingService.account.useGetStatus()
  const notPaidInvoicesQuery = billingService.account.useGetNotPaidInvoices()

  // Compute redirect parameter unconditionally even before queries succeed
  const redirectParam = detailsQuery.data?.is_location_based
    ? 'subscription'
    : undefined
  const { url } = useUpgradeUrl(redirectParam)

  if (
    !detailsQuery.isSuccess ||
    !statusQuery.isSuccess ||
    !notPaidInvoicesQuery.isSuccess
  ) {
    return null
  }

  const isCancelled = statusQuery.data.is_cancelled
  const isNonRenewing = statusQuery.data.is_non_renewing
  const isInTrial = statusQuery.data.is_in_trial
  const hasUnpaidInvoices = notPaidInvoicesQuery.data.length > 0
  const isLocationBased = detailsQuery.data.is_location_based

  const showCancelledBanner = isCancelled && !isInTrial && !hasUnpaidInvoices
  const showLocationBasedCancelledBanner =
    isLocationBased && !hasUnpaidInvoices && (isCancelled || isNonRenewing)

  if (!showCancelledBanner && !showLocationBasedCancelledBanner) {
    return null
  }

  const endDate =
    detailsQuery.data.cancelled_at || statusQuery.data.current_term_ends_at
  const isFutureDate = moment(endDate).isAfter(moment())

  const text = isFutureDate
    ? t('banner.cancelled.nonRenewingText', { date: endDate })
    : t('banner.cancelled.cancelledText', { date: endDate })

  const linkText = detailsQuery.data.is_location_based
    ? t('banner.cancelled.link')
    : t('banner.cancelled.activeLink')

  return (
    <Banner variant='danger'>
      {text}
      {redirectParam ? (
        <Anchor href={url} variant='inherits' rel='noreferrer'>
          {linkText}
        </Anchor>
      ) : (
        <Anchor
          variant='inherits'
          rel='noreferrer'
          component={Link}
          to={{
            pathname: generatePath(routes.subscription.path)
          }}
        >
          {linkText}
        </Anchor>
      )}
    </Banner>
  )
}
