import { useMutation } from '@tanstack/react-query'
import type {
  ApiErrorResponse,
  UseAppMutationOptions
} from 'libs/data-access/queries/src/lib/types'

import { invalidateAccountBillingInfoCache } from '../../../../AccountBillingInfo/accountBillingInfo.cacheKeys'
import { invalidateAccountDetailsCache } from '../../../../AccountDetails/accountDetails.cacheKeys'
import { invalidateAccountStatusCache } from '../../../../AccountStatus/accountStatus.cacheKeys'
import type { CompleteAccountDirectDebitOptions } from '../directDebit.api'
import { completeAccountDirectDebit } from '../directDebit.api'
import { directDebitCacheKeys } from '../directDebit.cacheKeys'

export const useCompleteAccountDirectDebit = (
  mutationOptions?: UseAppMutationOptions<
    void,
    ApiErrorResponse,
    CompleteAccountDirectDebitOptions
  >
) => {
  return useMutation<void, ApiErrorResponse, CompleteAccountDirectDebitOptions>(
    {
      ...mutationOptions,
      mutationKey: directDebitCacheKeys.complete(),
      mutationFn: (requestOptions: CompleteAccountDirectDebitOptions) =>
        completeAccountDirectDebit(requestOptions),
      onSuccess: (...args) => {
        if (mutationOptions?.onSuccess) {
          mutationOptions.onSuccess(...args)
        }

        invalidateAccountStatusCache()
        invalidateAccountDetailsCache()
        invalidateAccountBillingInfoCache()
      }
    }
  )
}
