import type { EstimateSchema } from '@libs/data-access/entities'
import { errorLoggerService } from '@libs/utils/error-monitoring'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import {
  setMetaError,
  setMetaSuccess,
  setMetaLoading
} from '../../subscription/modules/meta'
import { setEstimate } from '../modules/entities'

export default function* fetchEstimateData(chargebee_customer_id: string) {
  yield put(setMetaLoading())

  try {
    const result: EstimateSchema = yield apiRequest({
      service: 'billing',
      route: 'getEstimate',
      params: { id: chargebee_customer_id }
    })

    yield put(setEstimate({ ...result }))
    yield put(setMetaSuccess())
  } catch (err) {
    errorLoggerService.apiError(err)
    yield put(setMetaError())
  }
}
