import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_REWARDS,
  FETCH_REWARDS_SUCCEEDED,
  FETCH_REWARDS_FAILED,
  FETCH_REWARDS_BY_MEMBERSHIP,
  FETCH_REWARDS_BY_MEMBERSHIP_SUCCEEDED,
  FETCH_REWARDS_BY_MEMBERSHIP_FAILED,
  CREATE_REWARD,
  CREATE_REWARD_SUCCEEDED,
  CREATE_REWARD_FAILED,
  UPDATE_REWARD,
  UPDATE_REWARD_SUCCEEDED,
  UPDATE_REWARD_FAILED,
  DELETE_REWARD,
  DELETE_REWARD_SUCCEEDED,
  DELETE_REWARD_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import {
  mergeRecords,
  updateRecord,
  deleteRecord
} from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchRewards = (locationId, resolve, reject) => ({
  type: FETCH_REWARDS,
  locationId,
  resolve,
  reject
})

/**
 * @deprecated
 * Use the libs/data-access/queries/src/lib/queries/Rewards/rewards.api.ts instead
 */
export const fetchRewardsByMembership = (
  membershipId,
  params,
  resolve,
  reject
) => ({
  type: FETCH_REWARDS_BY_MEMBERSHIP,
  membershipId,
  params,
  resolve,
  reject
})

export const createReward = (rewardData, resolve, reject) => ({
  type: CREATE_REWARD,
  rewardData,
  resolve,
  reject
})

export const updateReward = (rewardId, rewardData, resolve, reject) => ({
  type: UPDATE_REWARD,
  rewardId,
  rewardData,
  resolve,
  reject
})

export const deleteReward = (rewardId, resolve, reject) => ({
  type: DELETE_REWARD,
  rewardId,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const fetchRewardsSagaEntity = {
  success: (data, locationId) => ({
    type: FETCH_REWARDS_SUCCEEDED,
    data,
    locationId
  }),
  failure: (error, locationId) => ({
    type: FETCH_REWARDS_FAILED,
    error,
    locationId
  }),

  fetchAPI: (locationId, options) =>
    callAPI(`/locations/${locationId}/rewards`, options)
}

export function* doFetchRewards({ locationId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    fetchRewardsSagaEntity,
    locationId,
    null,
    undefined,
    resolve,
    reject
  )
}

const fetchRewardsByMembershipSagaEntity = {
  success: (data, membershipId) => ({
    type: FETCH_REWARDS_BY_MEMBERSHIP_SUCCEEDED,
    data,
    membershipId
  }),
  failure: (error, membershipId) => ({
    type: FETCH_REWARDS_BY_MEMBERSHIP_FAILED,
    error,
    membershipId
  }),

  fetchAPI: (membershipId, options, params) =>
    callAPI(
      `/memberships/${membershipId}/rewards?${queryString.stringify({
        per_page: 15,
        ...params
      })}`,
      options
    )
}

export function* doFetchRewardsByMembership({
  membershipId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    fetchRewardsByMembershipSagaEntity,
    membershipId,
    params,
    undefined,
    resolve,
    reject
  )
}

const createRewardSagaEntity = {
  success: data => ({ type: CREATE_REWARD_SUCCEEDED, data }),
  failure: error => ({ type: CREATE_REWARD_FAILED, error }),

  fetchAPI: (id, options) => callAPI('/rewards', { method: 'POST', ...options })
}

export function* doCreateReward({ rewardData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createRewardSagaEntity,
    null,
    null,
    rewardData,
    resolve,
    reject
  )
}

const updateRewardSagaEntity = {
  success: (data, rewardId) => ({
    type: UPDATE_REWARD_SUCCEEDED,
    data,
    rewardId
  }),
  failure: (error, rewardId) => ({
    type: UPDATE_REWARD_FAILED,
    error,
    rewardId
  }),

  fetchAPI: (rewardId, options) =>
    callAPI(`/rewards/${rewardId}`, {
      method: 'PUT',
      ...options
    })
}

export function* doUpdateReward({ rewardId, rewardData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    updateRewardSagaEntity,
    rewardId,
    null,
    rewardData,
    resolve,
    reject
  )
}

const deleteRewardSagaEntity = {
  success: (data, rewardId) => ({
    type: DELETE_REWARD_SUCCEEDED,
    data,
    rewardId
  }),
  failure: (error, rewardId) => ({
    type: DELETE_REWARD_FAILED,
    error,
    rewardId
  }),

  fetchAPI: (rewardId, options) =>
    callAPI(`/rewards/${rewardId}`, {
      method: 'DELETE',
      ...options
    })
}

export function* doDeleteReward({ rewardId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    deleteRewardSagaEntity,
    rewardId,
    null,
    null,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const Reward = Immutable.Record({
  id: null,
  user_contract_id: null,
  reward_template_id: null,
  name: null,
  payroll_software_code: null,
  date: null,
  value: null,
  color: null // TODO: This attr doesn't even exist in the back. To remove from the code.
})

const foreignKeys = ['user_contract_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function reward(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_REWARDS_BY_MEMBERSHIP:
    case FETCH_REWARDS: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_REWARDS_BY_MEMBERSHIP_FAILED:
    case FETCH_REWARDS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_REWARDS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const rewards = Immutable.fromJS(action.data)

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Reward, rewards, foreignKeys).mergeDeepIn(
        ['meta'],
        {
          loading: false,
          updated_at: Date.now(),
          success: true
        }
      )
    }
    case FETCH_REWARDS_BY_MEMBERSHIP_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const rewards = Immutable.fromJS(action.data.rewards)
      // @ts-ignore migration from js(x) to ts(x)
      const pagination = Immutable.fromJS(action.data.meta)
      // @ts-ignore migration from js(x) to ts(x)
      const currentPage = action.data.meta.current_page

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Reward, rewards, foreignKeys).withMutations(
        map => {
          map.mergeDeepIn(['meta'], {
            loading: false,
            updated_at: Date.now(),
            success: true
          })
          map.mergeDeepIn(['pagination'], pagination)
          map.setIn(
            ['pagination', 'pages', currentPage],
            rewards.map(reward => reward.get('id')).toSet()
          )
        }
      )
    }
    case CREATE_REWARD_SUCCEEDED:
    case UPDATE_REWARD_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const reward = Immutable.fromJS(action.data)
      // @ts-ignore migration from js(x) to ts(x)
      return updateRecord(state, Reward, reward, foreignKeys)
    }
    case DELETE_REWARD_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { rewardId } = action
      // @ts-ignore migration from js(x) to ts(x)
      return deleteRecord(state, rewardId, foreignKeys)
    }
    default:
      return state
  }
}

export default filterActions(reward, [
  FETCH_REWARDS_BY_MEMBERSHIP,
  FETCH_REWARDS,
  FETCH_REWARDS_BY_MEMBERSHIP_FAILED,
  FETCH_REWARDS_FAILED,
  FETCH_REWARDS_SUCCEEDED,
  FETCH_REWARDS_BY_MEMBERSHIP_SUCCEEDED,
  CREATE_REWARD_SUCCEEDED,
  UPDATE_REWARD_SUCCEEDED,
  DELETE_REWARD_SUCCEEDED
])
