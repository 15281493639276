import { createSlice } from '@reduxjs/toolkit'

export type MetaState = {
  error: boolean
  success: boolean
  loading: boolean
}

const initialState: MetaState = {
  error: false,
  success: false,
  loading: false
}

const paramsSlice = createSlice({
  name: 'billing/locationBilling/meta',
  initialState,
  reducers: {
    setMetaError() {
      return {
        error: true,
        success: false,
        loading: false
      }
    },
    setMetaSuccess() {
      return {
        error: false,
        success: true,
        loading: false
      }
    },
    setMetaLoading() {
      return {
        error: false,
        success: false,
        loading: true
      }
    }
  }
})

export const { setMetaError, setMetaSuccess, setMetaLoading } =
  paramsSlice.actions
export default paramsSlice.reducer
