import Immutable from 'immutable'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  CREATE_EXPORT_FILES,
  CREATE_EXPORT_FILES_SUCCEEDED,
  CREATE_EXPORT_FILES_FAILED,
  GENERATE_EXPORT_FILE_SUCCEEDED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { fetchSagaEntity } from '../../services/sagaUtils'
import {
  subscribeToChannel,
  unsubscribeFromChannel,
  sendMessageToChannel
} from '../socket'
import { isConnectedToChannel } from '../socket/selectors'

// ------------------------------------
// Actions
// ------------------------------------

export const createExportFiles = (accountId, body, resolve, reject) => ({
  type: CREATE_EXPORT_FILES,
  body,
  accountId,
  resolve,
  reject
})

// ------------------------------------
// Socket Actions
// ------------------------------------

export const CHANNEL_NAME = 'Export::FileChannel'

export const isConnectedToExportFileChannel = state =>
  isConnectedToChannel(state, CHANNEL_NAME)

export const subscribeToExportFileChannel = () =>
  subscribeToChannel(CHANNEL_NAME)
export const unsubscribeFromExportFileChannel = () =>
  unsubscribeFromChannel(CHANNEL_NAME)
const sendMessageToExportFileChanel = (
  messageType,
  messageData,
  resolve,
  reject
) =>
  sendMessageToChannel(CHANNEL_NAME, messageType, messageData, resolve, reject)
export const downloadFile = (accountId, messageId, resolve, reject) =>
  sendMessageToExportFileChanel(
    'download',
    { account_id: accountId, message_id: messageId },
    resolve,
    reject
  )

// ------------------------------------
// Sagas
// ------------------------------------

const createExportFileSagaEntity = {
  success: (data, exportFileData) => ({
    type: CREATE_EXPORT_FILES_SUCCEEDED,
    data,
    exportFileData
  }),
  failure: (error, exportFileData) => ({
    type: CREATE_EXPORT_FILES_FAILED,
    error,
    exportFileData
  }),

  fetchAPI: (accountId, options) =>
    callAPI(`/accounts/${accountId}/export_files`, {
      method: 'POST',
      ...options
    })
}

export function* doCreateExportFiles({ accountId, body, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createExportFileSagaEntity,
    accountId,
    null,
    body,
    resolve,
    reject
  )
}

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  relations: Immutable.Map()
})

function exportFiles(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case CREATE_EXPORT_FILES:
    case CREATE_EXPORT_FILES_FAILED:
    case CREATE_EXPORT_FILES_SUCCEEDED:
      return state
    case GENERATE_EXPORT_FILE_SUCCEEDED:
      return state
    default:
      return state
  }
}

export default filterActions(exportFiles, [
  CREATE_EXPORT_FILES,
  CREATE_EXPORT_FILES_SUCCEEDED,
  CREATE_EXPORT_FILES_FAILED,
  GENERATE_EXPORT_FILE_SUCCEEDED
])
