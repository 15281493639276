import type { HolidaySchema } from '@libs/data-access/entities'

import type { GetRoute } from '../../types/utils'

export const publicHolidayRoutes = {
  getPublicHolidays: {
    method: 'GET',
    path: '/holidays'
  }
} as const

export type PublicHolidayRoutes = {
  getPublicHolidays: GetRoute<
    [],
    { start_date: string; end_date: string; location_id: number },
    HolidaySchema<string>
  >
}
