import { billingService } from '@libs/data-access/queries'
import { Loader, SuspenseWrap } from '@libs/ui/ds'
import { routes } from '@spa/core/router'
import { lazy } from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'

const SubscriptionAccountBased = lazy(
  () => import('@spa/scenes/Subscription/account-based')
)
const SubscriptionsView = lazy(
  () =>
    import(
      '@spa/scenes/Subscription/location-based/views/home/SubscriptionsView'
    )
)
const SubscriptionDetailsView = lazy(
  () =>
    import(
      '@spa/scenes/Subscription/location-based/views/details/SubscriptionDetailsView'
    )
)

const ExpiredTrialRoutes = () => {
  const billingAccountQuery = billingService.account.useGetDetails()

  if (billingAccountQuery.isLoading) {
    return <Loader.Overlay />
  }

  if (billingAccountQuery.isError) {
    return null
  }

  return (
    <SuspenseWrap>
      <Routes>
        <Route
          path={routes.subscription.path}
          element={<SubscriptionAccountBased />}
        />
        <Route
          path={routes.subscriptionsDetails.path}
          element={<SubscriptionDetailsView />}
        />
        <Route
          path={routes.subscriptions.path}
          element={<SubscriptionsView />}
        />
        <Route
          path='*'
          element={
            <Navigate
              to={
                // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
                billingAccountQuery?.data.is_location_based
                  ? routes.subscriptions.path
                  : routes.subscription.path
              }
            />
          }
        />
      </Routes>
    </SuspenseWrap>
  )
}

export default ExpiredTrialRoutes
