import { useUncontrolled } from '@mantine/hooks'
import type { FC } from 'react'

import { RadioCardButtonGroupProvider } from './RadioCardButtonGroup.context'
import type { RadioCardButtonGroupProps } from './RadioCardButtonGroup.types'

export const RadioCardButtonGroup: FC<RadioCardButtonGroupProps> = ({
  disabled,
  value,
  name,
  onChange,
  children
}) => {
  const [state, setState] = useUncontrolled<string>({
    onChange,
    value,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    defaultValue: null
  })

  return (
    <RadioCardButtonGroupProvider
      value={{
        value: state,
        name,
        disabled,
        onChange: setState
      }}
    >
      {children}
    </RadioCardButtonGroupProvider>
  )
}
