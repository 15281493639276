import { appQueryClient } from '../../../provider'

export const accountBillingInfoCacheKeys = {
  getByAccountId: () => ['billingInfo'] as const
}

export const invalidateAccountBillingInfoCache = () => {
  appQueryClient.invalidateQueries({
    queryKey: accountBillingInfoCacheKeys.getByAccountId()
  })
}
