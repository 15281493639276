import { useUncontrolled } from '@libs/ui/ds'
import type { MomentInput } from 'moment'
import { useEffect, useState } from 'react'

import { toDate, toString, useErrorMessage } from './formats'

export const monthConfig = {
  /**
   * Format string format YYYY-MM to Date object
   */
  toDate: (value?: string) => {
    return toDate({
      value,
      format: 'YYYY-MM'
    })
  },

  /**
   * Format Date object to string format YYYY-MM
   */
  toStr: (value?: MomentInput) =>
    toString({
      value,
      format: 'YYYY-MM'
    }),

  /**
   * Transforms and synchronize date/value attributes for the simple date-input-picker
   */
  useSyncDate: (opts: {
    value?: string
    onChange?: (val: string) => void
    defaultValue?: string
    minDate?: string
    maxDate?: string
    defaultDate?: string
  }) => {
    const [isDirty, setIsDirty] = useState(false)
    const [controlledValue, setControlledValue] = useUncontrolled(opts)
    const [controlledDate, setControlledDate] = useUncontrolled({
      defaultValue: opts.defaultDate || controlledValue
    })

    const handleSetControlledValue = (val: string) => {
      if (!isDirty) {
        setIsDirty(true)
      }
      setControlledValue(val)
    }

    useEffect(() => {
      if (controlledValue) {
        setControlledDate(controlledValue)
      }
    }, [controlledValue])

    return {
      strValue: controlledValue,
      onChangeStr: handleSetControlledValue,

      /**
       * Calendar props
       */
      value: monthConfig.toDate(controlledValue),
      onChange: val => {
        handleSetControlledValue(monthConfig.toStr(val))
      },
      reset: () => handleSetControlledValue(''),
      date: monthConfig.toDate(controlledDate),
      onDateChange: (val: Date) => setControlledDate(monthConfig.toStr(val)),
      minDate: monthConfig.toDate(opts.minDate),
      maxDate: monthConfig.toDate(opts.maxDate),
      defaultDate: monthConfig.toDate(opts.defaultDate),
      errorMessage: useErrorMessage({
        value: controlledValue,
        isDirty,
        minDate: opts.minDate,
        maxDate: opts.maxDate
      })
    }
  }
}
