import type { BaseComponent } from '@libs/ui/ds/lib/internals'
import type { ReactNode } from 'react'
import React from 'react'

import { Combobox } from '../../../../overlays/Combobox/base/Combobox'

type SelectFooterProps = BaseComponent & {
  footer?: ReactNode
}

export const SelectFooter: React.FC<SelectFooterProps> = ({
  footer,
  ...otherProps
}) => {
  if (!footer) {
    return null
  }

  return <Combobox.Footer {...otherProps}>{footer}</Combobox.Footer>
}
