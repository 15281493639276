import { useViewportSize } from '@mantine/hooks'
import { map, sortBy } from 'lodash-es'

import type { Screen, ScreenThresholdType } from '../screens'
import { screenConfig } from '../screens'

const screenListOrdered = sortBy(
  map(screenConfig, (value, screen) => ({ value, screen })),
  'value'
)

/**
 * Compute screen threshold given a viewport width
 */
export const computeScreenMatch = ({
  width,
  screenList,
  thresholdType = 'min'
}: {
  width: number
  screenList?: Screen[]
  thresholdType?: ScreenThresholdType
}) => {
  const filteredList = screenList
    ? [...screenListOrdered].filter(i =>
        screenList.includes(i.screen as Screen)
      )
    : [...screenListOrdered]

  const orderedList =
    thresholdType === 'min' ? filteredList.reverse() : filteredList

  const match = orderedList.find(({ value }) => {
    return thresholdType === 'min' ? value <= width : value > width
  })

  return (match ? match.screen : null) as Screen
}

export type UseScreensMediaQueryParams = {
  thresholdType?: ScreenThresholdType
  screenList?: Screen[]
}

/**
 * Return the matched screen token based on thresholdType and screenList
 * @param thresholdType Represent the media-query comparison "min" (min-width) or "max" (max-width)
 * @param screenList Look for media-query matching for a subset of screen-list
 */
export const useScreensMediaQuery = ({
  thresholdType,
  screenList
}: UseScreensMediaQueryParams) => {
  const viewPortSize = useViewportSize()
  const width = viewPortSize?.width || window.innerWidth
  return computeScreenMatch({ width, screenList, thresholdType })
}
