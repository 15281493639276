export const formatMaxLengthCounter = ({
  maxLength,
  value = ''
}: {
  maxLength?: number
  value: string
}) => {
  if (!maxLength || maxLength <= 0) {
    return null
  }

  return `${value?.length}/${maxLength}`
}
