import type { FC, PropsWithChildren } from 'react'
import React from 'react'

export const PageCentered: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className='absolute top-0 flex h-screen w-full items-center justify-center'>
      {children}
    </div>
  )
}
