import { useDeleteAmendment } from './Amendments/hooks/useDeleteAmendment'
import { usePostAmendment } from './Amendments/hooks/usePostAmendment'
import { usePutAmendment } from './Amendments/hooks/usePutAmendment'
import { useFetchLineManagers } from './LineManagers/hooks/useGetLineManager'
import { useFetchMemberTimeoffs } from './Timeoffs/hooks/useFetchMembershipTimeoffs'
import { useFetchTimeoffEligibilityForDate } from './Timeoffs/hooks/useFetchTimeoffEligibilityForDate'
import { useFetchTimeoffInfo } from './Timeoffs/hooks/useFetchTimeoffInfo'
import { membershipTimeoffInvalidations } from './Timeoffs/membership.timeoff.cacheKeys'
import { useFetchTimesheetByRange } from './Timesheets/hooks/useFetchTimesheetByRange'
import { queryStateCurrentMembershipPermissions } from './hooks/queryStateCurrentMembershipPermissions'
import { useDeleteMembership } from './hooks/useDeleteMembership'
import { useFetchCurrentMembership } from './hooks/useFetchCurrentMembership'
import { useCurrentMembershipPermissions } from './hooks/useFetchCurrentMembershipPermissions'
import { useFetchMembershipContracts } from './hooks/useFetchMembershipContracts'
import { useFetchMembershipNames } from './hooks/useFetchMembershipNames'
import { useFetchMemberships } from './hooks/useFetchMemberships'
import { useFetchOne } from './hooks/useFetchOne'
import { useFetchSearchedMemberships } from './hooks/useFetchSearchedMemberships'
import { useUpdateMembership } from './hooks/useUpdateMembership'
import { membershipInvalidations } from './membership.cacheKeys'

export type { FetchMembershipOptions } from './membership.api'

class MembershipService {
  useFetchAll = useFetchMemberships

  useFetchNames = useFetchMembershipNames

  useFeaturePermissions = useCurrentMembershipPermissions

  queryFeaturePermissions = queryStateCurrentMembershipPermissions

  membership = {
    useFetchOne,
    useFetchCurrent: useFetchCurrentMembership,
    useFetchSearch: useFetchSearchedMemberships,
    invalidateOne: membershipInvalidations.invalidateMembership,
    useUpdateMembership,
    useDeleteMembership
  }

  contract = {
    useFetchContracts: useFetchMembershipContracts,
    invalidateFetchContracts: membershipInvalidations.invalidateContracts
  }

  amendment = {
    usePost: usePostAmendment,
    usePut: usePutAmendment,
    useDelete: useDeleteAmendment
  }

  lineManagers = {
    useGet: useFetchLineManagers
  }

  timesheets = {
    useFetchByRange: useFetchTimesheetByRange
  }

  timeoff = {
    useFetch: useFetchMemberTimeoffs,
    invalidateMemberTimeoff:
      membershipTimeoffInvalidations.invalidateMemberTimeoff,
    useFetchInfo: useFetchTimeoffInfo,
    useFetchEligibility: useFetchTimeoffEligibilityForDate
  }
}

export const membershipService = new MembershipService()
