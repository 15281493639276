import { appQueryClient } from '../../../provider'

export const accountDetailsCacheKeys = {
  getByAccountId: () => ['accountDetails'] as const
}

export const invalidateAccountDetailsCache = () => {
  appQueryClient.invalidateQueries({
    queryKey: accountDetailsCacheKeys.getByAccountId()
  })
}
