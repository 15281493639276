import { chunkShiftByInterval } from './chunkShiftByInterval'
import { getDecimalRangeOverlap } from './getDecimalRangeOverlap'

type RangeItem = {
  start: string
  end: string
}

/**
 * Given a rangeItem with a start_date and end_date
 * Chunk the range into smaller dates interval
 * Then map each interval with the decimal overlap
 */
export const computeDecimalOverlapsByInterval = (
  rangeItem: RangeItem,
  interval: number
) => {
  const intervalsArr = chunkShiftByInterval(rangeItem, interval)

  return intervalsArr.map((intervalRange, idx, arr) => {
    const isFirst = idx === 0
    const isLast = idx === arr.length - 1

    /**
     * For first interval chunk,
     * We need to compute overlap between rangeItem.start and the interval range
     */
    if (isFirst) {
      return {
        isoHour: intervalRange.start,
        count: getDecimalRangeOverlap(rangeItem, intervalRange)
      }
    }

    /**
     * For last interval chunk,
     * We need to compute overlap between rangeItem.end and the interval range
     */
    if (isLast) {
      return {
        isoHour: intervalRange.start,
        count: getDecimalRangeOverlap(rangeItem, intervalRange)
      }
    }

    /**
     * Otherwise the count is 1 since the shift chunk
     * is fully contained in the given interval
     */
    return {
      isoHour: intervalRange.start,
      count: 1
    }
  })
}
