import { useMergedRef } from '@libs/ui/ds'
import { CheckIcon, DashIcon } from '@libs/ui/ds/assets/icons'
import { cva } from 'class-variance-authority'
import { forwardRef, useEffect, useRef } from 'react'

import { IconWrap } from '../../../../dataDisplay/IconWrap/base/IconWrap'
import { Center } from '../../../../primitiveLayouts/Center/base/Center'

import type { CheckboxInputProps } from './CheckboxInput.types'

const checkboxInputCVA = {
  root: cva([]),
  input: cva(['peer/input hidden']),
  outerBox: cva(
    [
      'inline-flex',
      'border-1 border-neutral-200 bg-neutral-50 [&>*]:invisible',
      /**
       * Checked state
       */
      'peer-checked/input:border-none peer-checked/input:[&>*]:visible',
      ' peer-checked/input:bg-brand-green-600 peer-checked/input:text-neutral-50',
      /**
       * Indeterminate state
       */
      'peer-indeterminate/input:text-neutral-50 peer-indeterminate/input:[&>*]:visible',
      'peer-indeterminate/input:border-none peer-indeterminate/input:bg-brand-green-600',
      /**
       * Disabled state
       */
      'peer-disabled/input:border-1 peer-disabled/input:border-solid peer-disabled/input:border-neutral-400',
      'peer-disabled/input:bg-neutral-200 peer-disabled/input:text-neutral-400'
    ],
    {
      variants: {
        size: {
          xs: 'size-4 min-h-4 min-w-4 rounded-sm',
          s: 'size-5 min-h-5 min-w-5 rounded',
          m: 'size-6 min-h-6 min-w-6 rounded'
        }
      }
    }
  ),
  props: {
    size: {
      m: 's',
      s: 'xs',
      xs: 'xs'
    }
  }
} as const

export const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(
  (
    {
      size = 'm',
      indeterminate,
      disabled,
      checked,
      required,
      onChange,
      name,
      value,
      ...other
    },
    forwardedRef
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const mergedRef = useMergedRef(forwardedRef, inputRef)

    useEffect(() => {
      if (inputRef) {
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        inputRef.current.indeterminate = indeterminate
      }
    }, [inputRef, indeterminate])

    return (
      <div className={checkboxInputCVA.root()}>
        <input
          {...other}
          name={name}
          required={required}
          disabled={disabled}
          checked={checked}
          value={value}
          onChange={e => {
            // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
            onChange(e)
          }}
          ref={mergedRef}
          type='checkbox'
          className={checkboxInputCVA.input()}
        />
        <Center className={checkboxInputCVA.outerBox({ size })}>
          <IconWrap
            icon={indeterminate ? <DashIcon /> : <CheckIcon />}
            size={checkboxInputCVA.props.size[size]}
          />
        </Center>
      </div>
    )
  }
)
