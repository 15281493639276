import { ArrowDownIcon, BaseButton, IconWrap, InputWrap } from '@libs/ui/ds'
import type { InputWrapProps } from '@libs/ui/ds/lib/components/forms/InputWrap/base/InputWrap.types'
import { outlineFocusMixin } from '@libs/ui/ds/lib/styles/mixins/mixins'
import { cva } from 'class-variance-authority'
import type { FC, ForwardedRef } from 'react'
import { useEffect, useState } from 'react'

import type { AutocompleteCustomTargetWrapProps } from '../AutocompleteCustomTargetWrap.types'

type AutocompleteTargetButtonProps = Omit<InputWrapProps, 'field' | 'htmlFor'> &
  AutocompleteCustomTargetWrapProps & {
    ref?: ForwardedRef<HTMLElement>
  }

const targetInputLikeCVA = {
  root: cva(
    [
      'relative flex w-64 min-w-0 max-w-64 items-center justify-between gap-2 px-4 py-2',
      'cursor-pointer rounded-md border-1 border-neutral-200 bg-neutral-50 font-sans text-m font-normal hover:bg-neutral-100/70',
      outlineFocusMixin
    ],
    {
      variants: {
        disabled: {
          true: [
            'cursor-not-allowed border-neutral-300 bg-neutral-100 text-neutral-500'
          ]
        },
        error: {
          true: ['border-semantic-red-400']
        }
      }
    }
  ),
  content: cva(['inline-block truncate text-s font-normal text-neutral-500'], {
    variants: {
      placeholder: {
        true: 'text-neutral-500',
        false: 'text-neutral-700'
      }
    }
  })
}

export const AutocompleteTargetInputLike: FC<AutocompleteTargetButtonProps> = ({
  className,
  desc,
  disabled,
  errorMessage,
  fillWidth,
  label,
  labelRightSection,
  name,
  placeholder,
  required,
  selectedOptions,
  store,
  helperText,
  ref,
  ...otherProps
}) => {
  const [selectedOptionsDisplay, setSelectedOptionsDisplay] = useState('')

  useEffect(() => {
    setSelectedOptionsDisplay(
      selectedOptions
        .reverse()
        .map(i => i.label)
        .join(', ')
    )
  }, [selectedOptions])

  const isSelectedOption = selectedOptions.length > 0

  return (
    <InputWrap
      className={className}
      label={label}
      desc={desc}
      errorMessage={errorMessage}
      fillWidth={fillWidth}
      required={required}
      disabled={disabled}
      htmlFor={name}
      labelRightSection={labelRightSection}
      helperText={helperText}
      field={
        <BaseButton
          ref={ref as ForwardedRef<HTMLButtonElement>}
          disabled={disabled}
          onClick={() => store.openDropdown()}
          data-testid={otherProps['data-testid'] || 'custom-target-inputlike'}
          className={targetInputLikeCVA.root({
            disabled,
            error: Boolean(errorMessage)
          })}
        >
          <div
            className={targetInputLikeCVA.content({
              placeholder: !isSelectedOption
            })}
          >
            {isSelectedOption ? selectedOptionsDisplay : placeholder || ''}
          </div>
          <IconWrap
            size='s'
            className='text-neutral-500'
            icon={<ArrowDownIcon />}
          />
        </BaseButton>
      }
    />
  )
}
