import { sortBy } from 'lodash-es'

import type { AvatarProps } from '../../Avatar/base/Avatar'

/**
 * Sort avatar by second labels value
 */
export const sortAvatarByLabel = (items: Pick<AvatarProps, 'labels'>[]) => {
  return sortBy(items, i => {
    return i.labels?.[0] || null
  })
}
