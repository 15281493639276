import { DEFAULT_LANGUAGE } from '@libs/utils/environments'
import moment from 'moment'
import momentTimezone from 'moment-timezone'

class DatesService {
  /**
   * 'en' is implicit because it's always loaded by default
   */
  async initLocalesDates() {
    moment.locale(DEFAULT_LANGUAGE, [
      // @ts-ignore-next-line
      await import('moment/dist/locale/fr').default,
      // @ts-ignore-next-line
      await import('moment/dist/locale/es').default,
      // @ts-ignore-next-line
      await import('moment/dist/locale/it').default,
      // @ts-ignore-next-line
      await import('moment/dist/locale/nl').default
    ])
  }

  /**
   * Set global locale for moment
   */
  setLocale(locale: string): void {
    const isoLocale = locale.toLowerCase()
    moment.locale(isoLocale)
  }

  /**
   * Set global timezone for moment
   */
  setTimezone(timezone: string): void {
    momentTimezone.tz.setDefault(timezone)
  }

  /**
   * Check if the browser/system timezone is the same as the App timezone
   */
  isSameTimezone(timezone: string) {
    const browserTimezone = momentTimezone.tz.guess(true)
    const browserDate = moment().tz(browserTimezone)
    const appDate = moment().tz(timezone)

    if (!appDate || !browserDate) {
      return true
    }

    return appDate.utcOffset() === browserDate.utcOffset()
  }
}

export const datesService = new DatesService()
datesService.initLocalesDates()
