import { useDispatchModal } from '@libs/ui/ds/lib/internals/contexts/globalModalContext/hooks/useDispatchModal'
import { useReadModal } from '@libs/ui/ds/lib/internals/contexts/globalModalContext/hooks/useReadModal'
import React, { useMemo, useState } from 'react'

import { Modal } from '../../base/Modal'

import type { ConfirmModalProps } from './useConfirmModal'
import { GLOBAL_APP_CONFIRM_MODAL } from './useConfirmModal'

export const ConfirmModal = () => {
  const [isConfirming, setIsConfirming] = useState(false)
  const { data, isOpen } = useReadModal<ConfirmModalProps>({
    name: GLOBAL_APP_CONFIRM_MODAL
  })
  const { close } = useDispatchModal<ConfirmModalProps>({
    name: GLOBAL_APP_CONFIRM_MODAL
  })

  /**
   * Prevent fade-out animation issues when the modal unmount
   * This happens because the modal data is set to null before unmounting
   */
  const fallbackData = useMemo(() => {
    if (data) {
      return {
        ...data,
        intent: data?.intent || 'danger'
      }
    }
  }, [data])

  /**
   * Handle asynchronous action inside the confirm-modal
   * So, loading state can be handled properly
   */
  const handleConfirm = async () => {
    try {
      setIsConfirming(true)
      if (data?.onConfirmAsync) {
        await data?.onConfirmAsync()
      }
      await data?.onConfirm()
    } catch (e) {
      await data?.onCancel()
    } finally {
      setIsConfirming(false)
    }
  }

  return (
    <Modal
      variant='center'
      size={fallbackData?.size || 'xs'}
      height={fallbackData?.height || 'max-s'}
      opened={isOpen}
      onClose={close}
    >
      <Modal.Header title={fallbackData?.title || ''} />
      <Modal.Content className='text-s'>{fallbackData?.content}</Modal.Content>
      <Modal.Footer>
        <Modal.Footer.Cancel onClick={fallbackData?.onCancel}>
          {fallbackData?.cancelLabel}
        </Modal.Footer.Cancel>
        {fallbackData?.intent === 'default' && (
          <Modal.Footer.Success loading={isConfirming} onClick={handleConfirm}>
            {fallbackData?.confirmLabel}
          </Modal.Footer.Success>
        )}
        {fallbackData?.intent === 'danger' && (
          <Modal.Footer.Danger loading={isConfirming} onClick={handleConfirm}>
            {fallbackData?.confirmLabel}
          </Modal.Footer.Danger>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export * from './useConfirmModal'
