import { useUncontrolled } from '@mantine/hooks'

export type HeadlessStepConfig = {
  active: number
  steps: {
    disabled: boolean
  }[]
}

export const useStepperState = (opts?: {
  defaultValue?: number
  value?: HeadlessStepConfig
  onChange?: (newValue: HeadlessStepConfig) => void
}) => {
  const [uncontrolledValue, uncontrolledSetValue] =
    useUncontrolled<HeadlessStepConfig>({
      defaultValue: {
        active: opts?.defaultValue || 0,
        steps: []
      },
      value: opts?.value,
      onChange: opts?.onChange
    })

  const isLastStep =
    uncontrolledValue.active >= (uncontrolledValue.steps?.length as number) - 1
  const isFirstStep = uncontrolledValue.active === 0

  const isNextStepEnabled =
    !isLastStep &&
    !uncontrolledValue.steps?.[uncontrolledValue.active]?.disabled
  const isPrevStepEnabled = !isFirstStep

  return {
    register: {
      defaultValue: opts?.defaultValue,
      value: uncontrolledValue,
      onChange: uncontrolledSetValue
    },
    active: uncontrolledValue.active,

    isLastStep,
    isFirstStep,
    isPrevStepEnabled,
    isNextStepEnabled,
    updateStep: (stepIndex: number) => {
      const stepEnabled = !uncontrolledValue.steps?.[stepIndex]?.disabled

      if (stepEnabled) {
        uncontrolledSetValue({
          ...uncontrolledValue,
          active: stepIndex
        })
      }
    },
    nextStep: () => {
      if (isNextStepEnabled) {
        uncontrolledSetValue({
          ...uncontrolledValue,
          active: uncontrolledValue.active + 1
        })
      }
    },
    prevStep: () => {
      if (isPrevStepEnabled) {
        uncontrolledSetValue({
          ...uncontrolledValue,
          active: uncontrolledValue.active - 1
        })
      }
    }
  }
}
