import { all } from 'redux-saga/effects'

import membershipWatcher from './membershipWatcher'
import noteWatcher from './noteWatcher'
import restWatcher from './restWatcher'
import shiftWatcher from './shiftWatcher'
import userContractWatcher from './userContractWatcher'
import weeklyscheduleWatcher from './weeklyscheduleWatcher'

export default function* planningsWatcher() {
  yield all([
    shiftWatcher(),
    restWatcher(),
    userContractWatcher(),
    membershipWatcher(),
    weeklyscheduleWatcher(),
    noteWatcher()
  ])
}
