import { PublicHoliday } from '@spa/scenes/Plannings/redux/models'
import { setEntities } from '@spa/scenes/Plannings/redux/reducers/entities'
import { normalize } from 'normalizr'
import { put, select } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import type { ParamsState } from '../../../reducers/params'
import { getParams, getWeekDateParams } from '../../../selectors'
import type { WeekDateParams } from '../../../selectors/params/getWeekDateParams/getWeekDateParams'

export function* getPublicHolidays(): any {
  const { locationId }: ParamsState = yield select(getParams)
  const { week }: WeekDateParams = yield select(getWeekDateParams)

  if (!week.start) {
    return
  }

  const data = yield apiRequest({
    route: 'getPublicHolidays',
    queryParams: {
      start_date: week.start,
      end_date: week.end,
      location_id: locationId
    }
  })

  const normalized = normalize(data, [PublicHoliday])
  yield put(setEntities({ publicHolidays: normalized.entities.publicHolidays }))
}
