import type { Screen } from '@libs/ui/ds/lib/theme'

export const switchAtVariants: Record<Screen | 'none' | 'all', string> = {
  all: 'flex-col',
  none: 'flex-row',
  sm: 'flex-col sm:flex-row',
  md: 'flex-col md:flex-row',
  lg: 'flex-col lg:flex-row',
  xl: 'flex-col xl:flex-row',
  '2xl': 'flex-col 2xl:flex-row'
} as const

export type SwitchAtVariant = keyof typeof switchAtVariants
