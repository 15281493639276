import type { ReduxState } from '@spa/redux/ReduxState'

import type {
  DisplayFilterName,
  DisplayFilters
} from '../../reducers/displayFilters'

const getDisplayFilterByName = <
  T extends DisplayFilterName = DisplayFilterName
>(
  state: ReduxState,
  { name }: { name: T }
) => {
  return state.plannings.displayFilters[name] as DisplayFilters[T]
}

export default getDisplayFilterByName
