import { NavigationMenuHorizontalBoldIcon } from '@libs/ui/ds/assets/icons'
import { Button } from '@libs/ui/ds/lib/components'
import { groupBy } from 'lodash-es'
import type { FC } from 'react'
import { memo } from 'react'

import { DropdownMenu } from '../../../overlays/DropdownMenu/base/DropdownMenu'
import { SwitchAt } from '../../../primitiveLayouts/SwitchAt/base/SwitchAt'
import { IconButton } from '../../IconButton/base/IconButton'

import type { AdaptiveButtonListProps } from './AdaptiveButtonList.types'
import { AdaptiveDropdownItem } from './subs/AdaptiveDropdownItem'

export const AdaptiveButtonList: FC<AdaptiveButtonListProps> = memo(
  ({
    className,
    switchToDropdownAt = null,
    dropdownMenuProps,
    dropdownTargetIcon = <NavigationMenuHorizontalBoldIcon />,
    actions
  }) => {
    const groups = groupBy(actions, i =>
      i.asButton ? 'asButton' : 'asDropdownItem'
    )

    return (
      <div className={className}>
        <SwitchAt
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          breakpoint={switchToDropdownAt}
          smaller={
            <DropdownMenu
              dropdownWidth='l'
              dropdownPosition='bottom-end'
              {...dropdownMenuProps}
            >
              <DropdownMenu.Target>
                <IconButton variant='secondary' icon={dropdownTargetIcon} />
              </DropdownMenu.Target>
              <DropdownMenu.Dropdown>
                {actions?.map((item, idx) => (
                  <AdaptiveDropdownItem key={idx} {...item} />
                ))}
              </DropdownMenu.Dropdown>
            </DropdownMenu>
          }
          larger={
            <>
              {groups?.asButton?.map((item, idx) => {
                const { asButton: _, ...rest } = item
                return (
                  <Button key={idx} {...rest}>
                    {item.children}
                  </Button>
                )
              })}
              {Boolean(groups?.asDropdownItem?.length) && (
                <DropdownMenu
                  dropdownWidth='l'
                  dropdownPosition='bottom-end'
                  {...dropdownMenuProps}
                >
                  <DropdownMenu.Target>
                    <IconButton
                      intent='neutral-dark'
                      variant='tertiary'
                      icon={dropdownTargetIcon}
                    />
                  </DropdownMenu.Target>
                  <DropdownMenu.Dropdown>
                    {groups.asDropdownItem.map((item, idx) => (
                      <AdaptiveDropdownItem key={idx} {...item} />
                    ))}
                  </DropdownMenu.Dropdown>
                </DropdownMenu>
              )}
            </>
          }
        />
      </div>
    )
  }
)
AdaptiveButtonList.displayName = 'AdaptiveButtonList'
export type {
  AdaptiveButtonListAction,
  AdaptiveButtonListProps
} from './AdaptiveButtonList.types'
