import { useMemo } from 'react'

import { useGetAccountBillingInfo } from '../../AccountBillingInfo/hooks/useGetAccountBillingInfo'
import { useGetAccountDetails } from '../../AccountDetails/hooks/useAccountDetails'

import { useAccountBasedGetAccountInvoices } from './useAccountBasedGetAccountInvoices'

export const useAccountBasedLastInvoice = () => {
  const { data: details, isError: isDetailsError } = useGetAccountDetails()

  const isBillingInfoQueryEnabled = useMemo(
    () => Boolean(details && !details.is_location_based),
    [details]
  )

  const { data: billingInfo } = useGetAccountBillingInfo({
    enabled: !isDetailsError && isBillingInfoQueryEnabled
  })

  const chargebeeSubscriptionId = useMemo(
    () => billingInfo?.chargebee_subscription_id,
    [billingInfo]
  )

  const { data: invoices } = useAccountBasedGetAccountInvoices(
    chargebeeSubscriptionId as string,
    {
      enabled: Boolean(chargebeeSubscriptionId)
    }
  )

  const lastInvoice = useMemo(() => {
    return invoices?.[invoices.length - 1] || null
  }, [invoices])

  return lastInvoice
}
