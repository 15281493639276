import { useInitSegment } from './hooks/useInitSegment'
import { useSegment } from './hooks/useSegment'
import { useSegmentPageView } from './hooks/useSegmentPageView'
import { reset } from './methods/reset'
import { useTrackScheduleFeaturesInteraction } from './methods/track/hooks/useTrackScheduleFeaturesInteraction'
import { track } from './methods/track/track'

export const trackerService = {
  reset,
  track,
  useSegment,
  useInitSegment,
  useSegmentPageView
}
export { useTrackScheduleFeaturesInteraction }
