export const ArrowUpIcon = () => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.904 4.49268C9.62133 4.51561 9.36993 4.60853 9.148 4.77206C9.1039 4.80456 7.7124 6.18896 4.67364 9.22362C2.24795 11.646 0.239804 13.655 0.2111 13.688C0.114252 13.7994 0.0570039 13.904 0.0228599 14.032C0.00739591 14.09 0.00473191 14.12 0.00473191 14.236C0.00473191 14.352 0.00739591 14.382 0.0228599 14.44C0.0599719 14.5792 0.123964 14.6906 0.230404 14.8014C0.374156 14.951 0.5327 15.0288 0.732492 15.0477C0.943972 15.0677 1.14399 15.0132 1.30594 14.8914C1.33347 14.8707 3.30089 12.9094 5.678 10.5329L10 6.21208L14.322 10.5329C16.6991 12.9094 18.6665 14.8707 18.6941 14.8914C18.856 15.0132 19.056 15.0677 19.2675 15.0477C19.4673 15.0288 19.6259 14.9509 19.7695 14.8014C19.8426 14.7253 19.8785 14.6749 19.9238 14.5846C19.9536 14.5252 19.992 14.394 19.992 14.3515C19.992 14.3391 19.9956 14.3267 20 14.324C20.0046 14.3212 20.008 14.2798 20.008 14.2271C20.008 14.1678 20.0052 14.1368 20 14.14C19.9954 14.1429 19.992 14.1354 19.9919 14.1225C19.9918 14.0831 19.9602 13.9659 19.934 13.908C19.9004 13.8338 19.8523 13.7608 19.7906 13.6903C19.7628 13.6585 17.7546 11.6495 15.328 9.22583C12.429 6.33035 10.8954 4.80408 10.856 4.77518C10.6623 4.63317 10.4521 4.54425 10.224 4.50773C10.1532 4.49641 9.9664 4.48761 9.904 4.49268ZM0.00305191 14.236C0.00306791 14.2888 0.00436391 14.3094 0.00592391 14.2817C0.00749191 14.254 0.00748391 14.2108 0.00589991 14.1857C0.00431591 14.1606 0.00303591 14.1832 0.00305191 14.236Z'
        fill='currentColor'
      />
    </svg>
  )
}
