import { CheckIcon } from '@libs/ui/ds/assets/icons'
import { Combobox } from '@mantine/core'
import { cva } from 'class-variance-authority'
import { forwardRef } from 'react'

import { IconWrap } from '../../../../dataDisplay/IconWrap/base/IconWrap'
import { useComboboxContext } from '../Combobox.context'

import type { ComboboxOptionProps } from './ComboboxOption.types'

const comboboxItemCVA = {
  root: cva(
    [
      'flex min-h-9 flex-row items-center gap-2 px-2 py-2.5',
      'cursor-pointer rounded text-s font-normal leading-4'
    ],
    {
      variants: {
        withGroup: {
          true: 'pl-5'
        },
        intent: {
          'neutral-dark': null,
          danger: null
        },
        active: {
          true: null,
          false: null
        },
        disabled: {
          true: '!cursor-default !text-neutral-700 opacity-40 hover:!bg-transparent active:!bg-transparent'
        }
      },
      compoundVariants: [
        {
          intent: 'neutral-dark',
          active: false,
          class: [
            'text-neutral-800 hover:bg-neutral-100',
            'active:bg-neutral-200 [&[data-combobox-selected]]:bg-neutral-100'
          ]
        },
        {
          intent: 'neutral-dark',
          active: true,
          class: [
            'bg-brand-green-100 text-brand-green-800',
            'hover:bg-brand-green-100 active:bg-brand-green-200'
          ]
        },
        {
          intent: 'danger',
          active: false,
          class: [
            'text-semantic-red-400 hover:bg-neutral-100',
            'active:bg-neutral-200  [&[data-combobox-selected]]:bg-neutral-100'
          ]
        },
        {
          intent: 'danger',
          active: true,
          class: [
            'bg-neutral-100 text-semantic-red-700',
            'hover:hover:bg-neutral-100 active:bg-neutral-100  [&[data-combobox-selected]]:bg-neutral-100'
          ]
        }
      ]
    }
  ),
  label: cva(['grow'])
}

export const ComboboxOption = forwardRef<HTMLDivElement, ComboboxOptionProps>(
  (
    {
      active = false,
      selected = false,
      leftIcon,
      leftSection,
      rightIcon,
      rightSection,
      withGroup = false,
      children,
      className,
      disabled = false,
      closeOnClick,
      intent = 'neutral-dark',
      'data-testid': dataTestId,
      value,
      onClick,
      ...other
    },
    ref
  ) => {
    const ctx = useComboboxContext()

    return (
      <Combobox.Option
        ref={ref}
        {...other}
        data-testid={dataTestId || `option-${value}`}
        onClick={e => {
          if (disabled) {
            return
          }

          if (onClick) {
            onClick(e)
          }

          if (ctx?.closeOnOptionClick || closeOnClick) {
            ctx?.store?.closeDropdown()
          }
        }}
        value={value}
        disabled={disabled}
        className={comboboxItemCVA.root({
          withGroup,
          disabled,
          active,
          intent,
          className
        })}
      >
        {leftIcon && <IconWrap size='s' icon={leftIcon} />}
        {Boolean(leftSection && !leftIcon) && leftSection}
        <div className={comboboxItemCVA.label()}>{children}</div>
        {Boolean(rightSection) && rightSection}
        {(rightIcon || selected) && (
          <IconWrap size='s' icon={selected ? <CheckIcon /> : rightIcon} />
        )}
      </Combobox.Option>
    )
  }
)
