// TODO: After including Immer in the codebase, createReducer will be added instead of pure JS there
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_ARTICLES,
  FETCH_ARTICLES_SUCCEEDED,
  FETCH_ARTICLES_FAILED,
  FETCH_ARTICLE,
  FETCH_ARTICLE_SUCCEEDED,
  FETCH_ARTICLE_FAILED,
  CREATE_ARTICLE,
  CREATE_ARTICLE_SUCCEEDED,
  CREATE_ARTICLE_FAILED,
  UPDATE_ARTICLE,
  UPDATE_ARTICLE_SUCCEEDED,
  UPDATE_ARTICLE_FAILED,
  DELETE_ARTICLE,
  DELETE_ARTICLE_SUCCEEDED,
  DELETE_ARTICLE_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchArticles = (accountId, params) => ({
  type: FETCH_ARTICLES,
  accountId,
  params
})

export const fetchArticle = (articleId, resolve, reject) => ({
  type: FETCH_ARTICLE,
  articleId,
  resolve,
  reject
})

export const createArticle = (articleData, resolve, reject) => ({
  type: CREATE_ARTICLE,
  articleData,
  resolve,
  reject
})

export const updateArticle = (articleId, articleData, resolve, reject) => ({
  type: UPDATE_ARTICLE,
  articleId,
  articleData,
  resolve,
  reject
})

export const deleteArticle = (articleId, resolve, reject) => ({
  type: DELETE_ARTICLE,
  articleId,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const articlesSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_ARTICLES_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_ARTICLES_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/articles?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchArticles({ accountId, params }) {
  // @ts-ignore migration from js(x) to ts(x)
  yield call(fetchSagaEntity, articlesSagaEntity, accountId, params)
}

const articleSagaEntity = {
  success: (data, articleId) => ({
    type: FETCH_ARTICLE_SUCCEEDED,
    data,
    articleId
  }),
  failure: (error, articleId) => ({
    type: FETCH_ARTICLE_FAILED,
    error,
    articleId
  }),

  fetchAPI: (articleId, options) => callAPI(`/articles/${articleId}`, options)
}

export function* doFetchArticle({ articleId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    articleSagaEntity,
    articleId,
    undefined,
    undefined,
    resolve,
    reject
  )
}

const createArticleSagaEntity = {
  success: data => ({ type: CREATE_ARTICLE_SUCCEEDED, data }),
  failure: error => ({ type: CREATE_ARTICLE_FAILED, error }),

  fetchAPI: (id, options) =>
    callAPI('/articles', { method: 'POST', ...options })
}

export function* doCreateArticle({ articleData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createArticleSagaEntity,
    null,
    null,
    articleData,
    function* resolveFunc(...args) {
      if (resolve) {
        yield resolve(...args)
      }
    },
    function* rejectFun(...args) {
      if (reject) {
        yield reject(...args)
      }
    }
  )
}

const updateArticleSagaEntity = {
  success: (data, articleId) => ({
    type: UPDATE_ARTICLE_SUCCEEDED,
    data,
    articleId
  }),
  failure: (error, articleId) => ({
    type: UPDATE_ARTICLE_FAILED,
    error,
    articleId
  }),

  fetchAPI: (articleId, options) =>
    callAPI(`/articles/${articleId}`, { method: 'PUT', ...options })
}

export function* doUpdateArticle({ articleId, articleData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    updateArticleSagaEntity,
    articleId,
    null,
    articleData,
    function* resolveFunc(...args) {
      if (resolve) {
        yield resolve(...args)
      }
    },
    function* rejectFun(...args) {
      if (reject) {
        yield reject(...args)
      }
    }
  )
}

const deleteArticleSagaEntity = {
  success: (data, articleId) => ({
    type: DELETE_ARTICLE_SUCCEEDED,
    data,
    articleId
  }),
  failure: (error, articleId) => ({
    type: DELETE_ARTICLE_FAILED,
    error,
    articleId
  }),

  fetchAPI: (articleId, options) =>
    callAPI(`/articles/${articleId}`, { method: 'DELETE', ...options })
}

export function* doDeleteArticle({ articleId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    deleteArticleSagaEntity,
    articleId,
    null,
    null,
    function* resolveFunc(...args) {
      if (resolve) {
        yield resolve(...args)
      }
    },
    function* rejectFun(...args) {
      if (reject) {
        yield reject(...args)
      }
    }
  )
}

// ------------------------------------
// Models
// ------------------------------------

export type Article = {
  id: number
  title?: string
  author?: string
  content?: string
  created_at: string
  updated_at: string
  account_id: string
  attachment_url?: string
  attachment_file_name?: string
  attachment_content_type?: string
}

export type ArticleState = {
  data?: {
    [key: number | string]: Article
  }
  pagination?: {
    current_page: number
    total_page: number
    total_count: number
    per_page: number
  }
  meta: {
    loading: boolean
    success: boolean
  }
}

// ------------------------------------
// Reducers
// ------------------------------------

const initialState: ArticleState = {
  data: {},
  pagination: {
    current_page: 0,
    total_page: 0,
    total_count: 0,
    per_page: 0
  },
  meta: {
    loading: false,
    success: false
  }
}

function articles(state: ArticleState = initialState, action: any = {}) {
  switch (action.type) {
    case FETCH_ARTICLES:
    case FETCH_ARTICLE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: true,
          success: false
        }
      }
    }
    case FETCH_ARTICLE_FAILED:
    case FETCH_ARTICLES_FAILED: {
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: false,
          success: false
        }
      }
    }
    case FETCH_ARTICLES_SUCCEEDED: {
      return {
        ...state,
        data: action.data.articles,
        pagination: action.data.meta,
        meta: {
          ...state.meta,
          loading: false,
          success: true
        }
      }
    }
    case CREATE_ARTICLE_SUCCEEDED:
    case FETCH_ARTICLE_SUCCEEDED: {
      const articles = { ...state.data, [action.data.id]: action.data }
      return {
        ...state,
        data: articles,
        meta: {
          ...state.meta,
          loading: false,
          success: true
        }
      }
    }
    case UPDATE_ARTICLE_SUCCEEDED: {
      return {
        ...state,
        data: {
          ...state.data,
          [Number(action.data.id)]: {
            // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
            ...(state.data[action?.data?.id] || {}),
            ...action.data
          }
        },
        meta: {
          ...state.meta,
          loading: false,
          success: true
        }
      }
    }
    case DELETE_ARTICLE_SUCCEEDED: {
      const articles = { ...state.data }
      delete articles[action.articleId]

      return {
        ...state,
        data: articles
      }
    }
    default:
      return state
  }
}

export default filterActions(articles, [
  FETCH_ARTICLES,
  FETCH_ARTICLES_FAILED,
  FETCH_ARTICLES_SUCCEEDED,
  FETCH_ARTICLE,
  FETCH_ARTICLE_SUCCEEDED,
  FETCH_ARTICLE_FAILED,
  CREATE_ARTICLE_SUCCEEDED,
  UPDATE_ARTICLE_SUCCEEDED,
  DELETE_ARTICLE_SUCCEEDED
])
