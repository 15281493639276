import { deburr, flow } from 'lodash-es'

import { cleanSearchValue } from '../utils'

function deburrStr(str: string) {
  return deburr(str)
}

function escapeAsterixRegExp(str: string) {
  return str.replace(/^\*+/g, '').replace(/\*+/g, '*')
}

function escapeInvalidCharRegExp(str: string) {
  return str.replace(/[-[\]{}()+]/g, '\\$&')
}

export function escapeRegExp(str: string) {
  return flow(deburrStr, escapeAsterixRegExp, escapeInvalidCharRegExp)(str)
}

/**
 * Simple version of the fuzzy search algorithm.
 *
 * Match:
 * fuzzySearch('jq', ['Jquery']) // true
 * fuzzySearch('jr', ['Jquery']) // true
 * fuzzySearch('juy', ['Jquery']) // true
 * fuzzySearch('jqmy', ['Jquery']) // false
 * fuzzySearch('qjy', ['Jquery']) // false
 */
export function fuzzySearch({
  search,
  options
}: {
  search: string
  options: string[]
}) {
  const cleanPattern = cleanSearchValue(search)
  const fuzzyPattern = cleanPattern.replace(/./g, '$&.*')
  const escapedPattern = escapeRegExp(fuzzyPattern)
  const regexpPattern = new RegExp(`.*${escapedPattern}.*`, 'i')

  const isMatch = options.some(i => regexpPattern.test(String(deburrStr(i))))
  return isMatch
}
