import { toPairs, fromPairs } from 'lodash-es'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

/**
 * Iterates over action creator object
 * It creates a promise and call the action creator function
 * with its params, plus the resolve/reject params automatically
 * and returns the promise to access .then/.catch/.finally
 *
 * @use ```
 * const mapDispatchToProps = (dispatch) => bindPromiseActionCreators({
 *   fetchUser,
 *   fetchAccount,
 *   fetchShift,
 *   updateUser,
 *   updateAccount,
 *   updateShift
 * }, dispatch)
 * ```
 *
 * @param actionCreators {{[name: string]: <any>Function>}} Object of action creators
 * @param dispatch {Function} Redux Dispatcher Function
 *
 * @returns {{[name: string]: <any>Promise}}
 */
export function bindPromiseActionCreators(actionCreators, dispatch) {
  const handler = ([actionCreatorName, actionCreator]) => [
    actionCreatorName,
    (...args) =>
      new Promise((resolve, reject) => {
        // actionCreator.length is the number of arguments passed to the function when it is called
        // 2 is the number of dynamic argument we add (resolve + reject function)
        // We subtract them together to get the maximum size of arguments
        // @see https://github.com/snapshift/snap-web/pull/926
        const fnArgsLen = actionCreator.length - 2
        // number of missing arguments
        const missingArgs = fnArgsLen - args.length
        const hasMissingArgs = missingArgs > 0

        let params = [...args]

        // If the function call misses arguments we add it with undefined value
        if (hasMissingArgs) {
          for (let i = 0; i < missingArgs; i++) {
            params.push(undefined)
          }
        }

        // We fineley add resolve and reject to the function arguments
        params = params.concat([resolve, reject])

        const action = actionCreator(...params)
        dispatch(action)
      })
  ]
  const objectAsTuple = toPairs(actionCreators)
  return fromPairs(objectAsTuple.map(handler))
}

/**
 * This hook is available in order to memoize the return value of
 * bindPromiseActionCreators and make it safe to use it as a useEffect dependency.
 * One must ensure that the actionCreators parameter is an unchanging reference to
 * an object rather than a new object at every subsequent call.
 */
export function useActions(actionCreators) {
  const dispatch = useDispatch()
  return useMemo(
    () => bindPromiseActionCreators(actionCreators, dispatch),
    [actionCreators, dispatch]
  )
}

/*
 * Wraps in mapDispatchToProps a function with a promise
 * and dispatch the action returned by "fn".
 *
 * It avoids `fetchXXXXXX` and `fetchXXXXXXPromise` repetition in the code
 *
 * @param fn {Function}
 * @param params {<any>[]}
 * @param dispatch {Function}
 *
 * @returns {Promise<any>}
 */
export function promiseDispatchWrapper(fn, params, dispatch) {
  return new Promise((resolve, reject) => {
    dispatch(fn(...params.concat([resolve, reject])))
  })
}

type AllSettledPromise<T> = {
  fulfilled: PromiseFulfilledResult<T>[]
  rejected: PromiseRejectedResult[]
}

/**
 * Add predicate for PromiseFulfilledResult, it enable our Promise.allSettled to be typed correctly
 */
type TisFulfilledPredicate = <T extends object = {}>(
  results: PromiseSettledResult<T>
) => results is PromiseFulfilledResult<T>
const isFulfilledPredicate: TisFulfilledPredicate = <T extends object = {}>(
  result
): result is PromiseFulfilledResult<T> => {
  const isFulfilled = result.status === 'fulfilled'
  return isFulfilled
}

/**
 * Add predicate for PromiseRejectedResult, it enable our Promise.allSettled to be typed correctly
 */
type TisRejectedPredicate = (
  results: PromiseSettledResult<any>
) => results is PromiseRejectedResult
const isRejectedPredicate: TisRejectedPredicate = (
  result
): result is PromiseRejectedResult => {
  const isFullfilled = result.status === 'rejected'
  return isFullfilled
}

/**
 * Convenient Promise.allSettled formatting/type predicates formatter
 */
export const getPromiseAllSettledResults = <T extends object>(
  results: PromiseSettledResult<T>[]
): AllSettledPromise<T> => {
  const fulfilled = results.filter(isFulfilledPredicate)
  const rejected = results.filter(isRejectedPredicate)
  return { fulfilled, rejected }
}
