import { Flex } from '@libs/ui/ds'
import type { SupportedLanguage } from '@spa/features/i18n/types'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { CapterraRate } from './assets/CapterraRate'
import { GoogleRate } from './assets/GoogleRate'

const SIGN_IN_OUT_ASSETS_URL =
  'https://snapshift-public-assets.s3.eu-west-1.amazonaws.com/sign-in-out'

type SignInOutImageUrl = `${typeof SIGN_IN_OUT_ASSETS_URL}/${string}`

const PLANNING_IMAGES: Record<SupportedLanguage, SignInOutImageUrl> = {
  en: `${SIGN_IN_OUT_ASSETS_URL}/planning-en.png`,
  es: `${SIGN_IN_OUT_ASSETS_URL}/planning-es.png`,
  fr: `${SIGN_IN_OUT_ASSETS_URL}/planning-fr.png`,
  it: `${SIGN_IN_OUT_ASSETS_URL}/planning-it.png`
}

export const PlanningView: FC = () => {
  const { i18n } = useTranslation()
  const currentLanguage = i18n.resolvedLanguage as SupportedLanguage
  const imageSrc = PLANNING_IMAGES[currentLanguage]

  return (
    <>
      <div className='h-full max-h-[70vh] min-h-[350px]'>
        <div
          className='-ml-8 h-full'
          style={{
            backgroundImage: `url('${imageSrc}')`,
            backgroundSize: 'cover',
            width: 'calc(100%+2rem)'
          }}
        />
      </div>
      <Flex row className='mb-8 mt-16 justify-center gap-4 self-center px-8'>
        <CapterraRate />
        <GoogleRate />
      </Flex>
    </>
  )
}
