import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import { memo } from 'react'

import type { BaseSkeletonProps } from './BaseSkeleton.types'

const skeletonCVA = {
  root: cva(['animate-pulse']),
  inner: cva(['size-full rounded bg-neutral-200'])
}

export const BaseSkeleton: FC<BaseSkeletonProps> = memo(
  ({ className, ...other }) => {
    return (
      <div className={skeletonCVA.root({ className })}>
        <div {...other} className={skeletonCVA.inner()} />
      </div>
    )
  }
)

BaseSkeleton.displayName = 'Skeleton'
export const Skeleton = BaseSkeleton
export type { BaseSkeletonProps } from './BaseSkeleton.types'
