import Immutable from 'immutable'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_LABELS,
  FETCH_LABELS_SUCCEEDED,
  FETCH_LABELS_FAILED,
  FETCH_LABELS_FROM_LOCATION,
  FETCH_LABELS_FROM_ACCOUNT,
  CREATE_LABEL,
  CREATE_LABEL_SUCCEEDED,
  CREATE_LABEL_FAILED,
  UPDATE_LABEL,
  UPDATE_LABEL_SUCCEEDED,
  UPDATE_LABEL_FAILED,
  DELETE_LABEL,
  DELETE_LABEL_SUCCEEDED,
  DELETE_LABEL_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import {
  mergeRecords,
  updateRecord,
  deleteRecord
} from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchLabels = teamId => ({ type: FETCH_LABELS, teamId })

export const hydrateLabels = (teamId, data) => ({
  type: FETCH_LABELS_SUCCEEDED,
  data,
  teamId
})

export const fetchLabelsFromLocation = locationId => ({
  type: FETCH_LABELS_FROM_LOCATION,
  locationId
})

export const fetchLabelsFromAccount = accountId => ({
  type: FETCH_LABELS_FROM_ACCOUNT,
  accountId
})

export const createLabel = (labelData, resolve, reject) => ({
  type: CREATE_LABEL,
  labelData,
  resolve,
  reject
})

export const updateLabel = (labelId, labelData, resolve, reject) => ({
  type: UPDATE_LABEL,
  labelId,
  labelData,
  resolve,
  reject
})

export const deleteLabel = (labelId, resolve, reject) => ({
  type: DELETE_LABEL,
  labelId,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const labelsSagaEntity = {
  success: (data, teamId) => ({
    type: FETCH_LABELS_SUCCEEDED,
    data,
    teamId
  }),
  failure: (error, teamId) => ({
    type: FETCH_LABELS_FAILED,
    error,
    teamId
  }),

  fetchAPI: (teamId, options) => callAPI(`/teams/${teamId}/labels`, options)
}

export function* doFetchLabels({ teamId }) {
  // @ts-ignore migration from js(x) to ts(x)
  yield call(fetchSagaEntity, labelsSagaEntity, teamId)
}

const labelsFromLocationSagaEntity = {
  success: (data, locationId) => ({
    type: FETCH_LABELS_SUCCEEDED,
    data,
    locationId
  }),
  failure: (error, locationId) => ({
    type: FETCH_LABELS_FAILED,
    error,
    locationId
  }),

  fetchAPI: (locationId, options) =>
    callAPI(`/locations/${locationId}/labels`, options)
}

export function* doFetchLabelsFromLocation({ locationId }) {
  // @ts-ignore migration from js(x) to ts(x)
  yield call(fetchSagaEntity, labelsFromLocationSagaEntity, locationId)
}

const labelsFromAccountSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_LABELS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_LABELS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options) =>
    callAPI(`/accounts/${accountId}/labels`, options)
}

export function* doFetchLabelsFromAccount({ accountId }) {
  // @ts-ignore migration from js(x) to ts(x)
  yield call(fetchSagaEntity, labelsFromAccountSagaEntity, accountId)
}

const createLabelSagaEntity = {
  success: data => ({ type: CREATE_LABEL_SUCCEEDED, data }),
  failure: error => ({ type: CREATE_LABEL_FAILED, error }),

  fetchAPI: (id, options) => callAPI('/labels', { method: 'POST', ...options })
}

export function* doCreateLabel({ labelData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createLabelSagaEntity,
    null,
    null,
    labelData,
    resolve,
    reject
  )
}

const updateLabelSagaEntity = {
  success: (data, labelId) => ({ type: UPDATE_LABEL_SUCCEEDED, data, labelId }),
  failure: (error, labelId) => ({ type: UPDATE_LABEL_FAILED, error, labelId }),

  fetchAPI: (labelId, options) =>
    callAPI(`/labels/${labelId}`, { method: 'PUT', ...options })
}

export function* doUpdateLabel({ labelId, labelData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    updateLabelSagaEntity,
    labelId,
    null,
    labelData,
    resolve,
    reject
  )
}

const deleteLabelSagaEntity = {
  success: (data, labelId) => ({ type: DELETE_LABEL_SUCCEEDED, data, labelId }),
  failure: (error, labelId) => ({ type: DELETE_LABEL_FAILED, error, labelId }),

  fetchAPI: (labelId, options) =>
    callAPI(`/labels/${labelId}`, { method: 'DELETE', ...options })
}

export function* doDeleteLabel({ labelId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    deleteLabelSagaEntity,
    labelId,
    null,
    null,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const Label = Immutable.Record({
  id: null,
  name: null,
  color: null,
  team_id: null
})

const labelForeignKeys = ['team_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function labels(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_LABELS_FROM_ACCOUNT:
    case FETCH_LABELS_FROM_LOCATION:
    case FETCH_LABELS: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case CREATE_LABEL_FAILED:
    case FETCH_LABELS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_LABELS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const labels = Immutable.fromJS(action.data)

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Label, labels, labelForeignKeys).mergeDeepIn(
        ['meta'],
        {
          loading: false,
          updated_at: Date.now(),
          success: true
        }
      )
    }
    case CREATE_LABEL:
    case UPDATE_LABEL:
    case DELETE_LABEL: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case CREATE_LABEL_SUCCEEDED:
    case UPDATE_LABEL_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const label = Immutable.fromJS(action.data)

      // @ts-ignore migration from js(x) to ts(x)
      return updateRecord(state, Label, label, labelForeignKeys).mergeDeepIn(
        ['meta'],
        { loading: false }
      )
    }
    case DELETE_LABEL_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { labelId } = action
      // @ts-ignore migration from js(x) to ts(x)
      return deleteRecord(state, labelId, labelForeignKeys).mergeDeepIn(
        ['meta'],
        { loading: false }
      )
    }
    default:
      return state
  }
}

export default filterActions(labels, [
  FETCH_LABELS,
  FETCH_LABELS_FROM_LOCATION,
  FETCH_LABELS_FROM_ACCOUNT,
  FETCH_LABELS_FAILED,
  FETCH_LABELS_SUCCEEDED,
  CREATE_LABEL,
  CREATE_LABEL_FAILED,
  CREATE_LABEL_SUCCEEDED,
  UPDATE_LABEL,
  UPDATE_LABEL_SUCCEEDED,
  DELETE_LABEL,
  DELETE_LABEL_SUCCEEDED
])
