import { useContext } from 'react'

import { ModalReadContext } from '../context/modalStateContext'
import type { ModalDefinitionParams } from '../types'

/**
 * Expose disclosure context
 */
export function useReadModal<T extends any = undefined>({
  name
}: ModalDefinitionParams) {
  const ctx = useContext(ModalReadContext)

  if (!ctx) {
    throw new Error('Modal.Context is used outside of Modal.Provider')
  }

  const isOpen = ctx.opened?.name === name
  const data = isOpen ? (ctx.opened?.data as T) : null

  return {
    isOpen,
    data
  }
}
