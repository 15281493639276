import type { TimeRangeState } from '@spa/scenes/Plannings/redux/reducers'
import moment from 'moment'
import type {
  Rest,
  Shift
} from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

type PlannableItem = Shift | Rest
export type PlannableCardItem = {
  id: number
  isRest: boolean
  start: string
  end: string
}

/**
 * Format shift and rest to have common API PlannableCardItem
 */
export const formatToPlannableCards = (
  plannables: PlannableItem[]
): PlannableCardItem[] => {
  return plannables.map(item => {
    if ((item as Rest).duration_type) {
      return {
        id: item.id,
        isRest: true,
        start: item.starts_at,
        end: item.ends_at
      }
    }

    return {
      id: item.id,
      isRest: false,
      start: (item as Shift).real_starts_at,
      end: (item as Shift).real_ends_at
    }
  })
}

/**
 * Filters cards that are in current timeRange
 */
export const filterCardsInTimeRange = (
  plannables: PlannableItem[],
  { start, end }: TimeRangeState
) => {
  return plannables.filter(
    i =>
      moment(i.starts_at).isBetween(start, end, 'minutes', '[]') ||
      moment(i.ends_at).isBetween(start, end, 'minutes', '(]')
  )
}
