import { put, select } from 'redux-saga/effects'
import type {
  Rest,
  Shift
} from 'snap-redux/infra/http/routesConfig/scopes/planning/types'
import { getCurrentAccountId } from 'snap-redux/modules/accounts/selectors'
import { hydrateContracts } from 'snap-redux/modules/contracts'
import { hydrateLabels } from 'snap-redux/modules/labels'
import { hydrateMemberships } from 'snap-redux/modules/memberships'
import { hydrateRests } from 'snap-redux/modules/rests'
import { hydrateShifts } from 'snap-redux/modules/shifts'
import { hydrateWeeklyschedules } from 'snap-redux/modules/weeklyschedules'

import {
  contractsSelectors,
  labelsSelectors,
  membershipsSelectors,
  weeklyschedulesSelectors
} from '../../../selectors'

/**
 * Hydrate labels immutable stores
 */
export function* hydrateOldStoreLabel({ teamId }: { teamId: number }) {
  const labels = yield select(labelsSelectors.getCachedList)

  yield put(hydrateLabels(teamId, labels))
}
/**
 *
 * Hydrate weeklyschedules immutable stores
 */
export function* hydrateOldStoreWeeklyschedules() {
  const accountId = yield select(getCurrentAccountId)
  const weeklyschedules = yield select(weeklyschedulesSelectors.getCachedList)

  yield put(hydrateWeeklyschedules(accountId, weeklyschedules))
}

/**
 * Hydrate memberships immutable stores
 */
export function* hydrateOldStoreMemberships() {
  const accountId = yield select(getCurrentAccountId)
  const memberships = yield select(membershipsSelectors.getCachedList)

  yield put(hydrateMemberships(accountId, memberships))
}

/**
 * Hydrate contracts immutable stores
 */
export function* hydrateOldStoreContracts() {
  const accountId = yield select(getCurrentAccountId)
  const contracts = yield select(contractsSelectors.getCachedList)

  yield put(hydrateContracts(accountId, contracts))
}

/**
 * Hydrate shift immutable stores
 */
export function* hydrateOldStoreShifts(shifts: Shift[]) {
  const accountId = yield select(getCurrentAccountId)

  yield put(hydrateShifts(accountId, shifts))
}

/**
 * Hydrate rest immutable stores
 */
export function* hydrateOldStoreRests(rests: Rest[]) {
  const accountId = yield select(getCurrentAccountId)

  yield put(hydrateRests(accountId, rests))
}
