export function isContractInProgressBetween(
  contract,
  startDatePeriod,
  endDatePeriod
) {
  const validContractStartDate = contract.contract_start
  const validContractEndDate = contract.contract_end

  const startContractDateBeforeEndPeriod =
    validContractStartDate && validContractStartDate <= endDatePeriod
  const endContractDateAfterStartPeriod =
    validContractEndDate && validContractEndDate >= startDatePeriod

  return (
    (!validContractStartDate && !validContractEndDate) ||
    (startContractDateBeforeEndPeriod && !validContractEndDate) ||
    (!validContractStartDate && endContractDateAfterStartPeriod) ||
    (startContractDateBeforeEndPeriod && endContractDateAfterStartPeriod)
  )
}
