import type { ReduxState } from '@spa/redux/ReduxState'
import type { TimeRangeState } from '@spa/scenes/Plannings/redux/reducers'
import type { MomentInput } from 'moment'
import moment from 'moment'
import { createSelector } from 'reselect'

import { getWeekDateParams } from '../../../params'
import { getTimeRange } from '../../../timeRange'
import { computeDiffGridPositioning } from '../utils/computeDiffGridPositioning'

const isInRange = (dateTime: MomentInput, range: TimeRangeState) => {
  return moment(dateTime).isBetween(range.start, range.end, 'minutes', '[]')
}

export const getComputedPositionInTimeRange = createSelector(
  getTimeRange,
  getWeekDateParams,
  (_: ReduxState, dateTime: string) => dateTime,

  (timeRange, { week }, dateTime) => {
    if (!isInRange(dateTime, timeRange)) {
      return null
    }

    return computeDiffGridPositioning(dateTime, week.start)
  }
)
