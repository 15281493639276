import type { GetRoute, PutRoute } from '../../types'

import type { ModulationPeriodCounterSchema } from './schemas'

export const modulationPeriodCountersRoutes = {
  getModulationPeriodCountersByLocation: {
    method: 'GET',
    path: '/locations/:location_id/modulation_period_counters'
  },
  getModulationPeriodCountersByMembership: {
    method: 'GET',
    path: '/memberships/:membership_id/modulation_period_counters'
  },
  getModulationPeriodCounter: {
    method: 'GET',
    path: '/modulation_period_counters/:id'
  },
  putModulationPeriodCounter: {
    method: 'PUT',
    path: '/modulation_period_counters/:id'
  },
  getModulationPeriodCountersByUserContract: {
    method: 'GET',
    path: '/user_contracts/:user_contract_id/modulation_period_counters'
  }
} as const

export type ModulationPeriodCountersRoutes = {
  getModulationPeriodCountersByLocation: GetRoute<
    ['location_id'],
    { location_id?: string; week?: string },
    ModulationPeriodCounterSchema[]
  >
  getModulationPeriodCountersByMembership: GetRoute<
    ['membership_id'],
    {},
    ModulationPeriodCounterSchema[]
  >
  getModulationPeriodCounter: GetRoute<
    ['id'],
    {},
    ModulationPeriodCounterSchema[]
  >
  putModulationPeriodCounter: PutRoute<
    ['id'],
    {},
    ModulationPeriodCounterSchema
  >
  getModulationPeriodCountersByUserContract: GetRoute<
    ['user_contract_id'],
    { includes?: string },
    ModulationPeriodCounterSchema[]
  >
}
