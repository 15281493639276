import type { CountryConfigurationSchema } from '@libs/data-access/entities'
import type { UseAppQueryOptions } from '@libs/data-access/queries'
import { useQuery } from '@tanstack/react-query'

import { queryClientOptions } from '../../../../provider'
import { fetchCountryConfiguration } from '../accountCountryConfiguration.api'
import { accountCountryConfigurationCacheKeys } from '../accountCountryConfiguration.cacheKeys'

export const useGetCountryConfiguration = (
  queryOptions?: UseAppQueryOptions<CountryConfigurationSchema>
) => {
  return useQuery({
    ...queryOptions,
    queryKey: accountCountryConfigurationCacheKeys.getCountryConfiguration(),
    queryFn: () => fetchCountryConfiguration(),
    staleTime: queryClientOptions.time.INFINITY
  })
}
