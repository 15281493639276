import type { BankHolidayAddressSchema } from '@libs/data-access/entities'

import type { GetRoute } from '../../types'

export const bankHolidayAddressesRoutes = {
  getBankHolidayAddresses: {
    method: 'GET',
    path: '/locations/:location_id/bank_holiday_addresses'
  }
} as const

export type BankHolidayAddressesRoutes = {
  getBankHolidayAddresses: GetRoute<
    ['location_id'],
    { page?: number; per_page?: number; q?: string },
    BankHolidayAddressSchema
  >
}
