import type { Table } from '@tanstack/react-table'
import { flexRender } from '@tanstack/react-table'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'

import type { TableProps } from '../Table.types'

import { tableClasses } from './tableClasses'

const tableFooterCVA = {
  footerRow: cva([tableClasses.headerRow]),
  footerCell: cva([tableClasses.cell, tableClasses.headerCell], {
    variants: {
      isPinned: {
        true: 'sticky z-[1] bg-neutral-50'
      }
    }
  })
}

type TableFooterProps = {
  showFooter: TableProps['showFooter']
  table: Table<any>
}

export const TableFooter: FC<TableFooterProps> = ({ table, showFooter }) => {
  if (!showFooter) {
    return null
  }

  return (
    <tfoot>
      {table.getFooterGroups().map(footerGroup => (
        <tr key={footerGroup.id} className={tableFooterCVA.footerRow()}>
          {footerGroup.headers.map(footer => {
            const { columnDef } = footer.column
            return (
              <th
                key={footer.id}
                className={tableFooterCVA.footerCell({
                  className: columnDef?.meta?.footerClassname,
                  isPinned: Boolean(footer.column.getIsPinned())
                })}
                style={{
                  ...(footer.column.getIsPinned() === 'left' && {
                    left: `${footer.column.getStart()}px`
                  }),
                  ...(footer.column.getSize() && {
                    width: `${footer.column.getSize()}px`
                  })
                }}
              >
                {footer.isPlaceholder
                  ? null
                  : flexRender(
                      footer.column.columnDef.footer,
                      footer.getContext()
                    )}
              </th>
            )
          })}
        </tr>
      ))}
    </tfoot>
  )
}
