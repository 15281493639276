import { useMutation } from '@tanstack/react-query'

import type { ApiErrorResponse, UseAppMutationOptions } from '../../../../types'
import { moveRest, type MovePlannable } from '../monthly.api'
import { monthlyServiceCacheKeys } from '../monthly.cacheKeys'

export const useMoveRest = (
  mutationOpts?: UseAppMutationOptions<null, ApiErrorResponse, MovePlannable>
) => {
  return useMutation<null, ApiErrorResponse, MovePlannable>({
    ...mutationOpts,
    mutationKey: monthlyServiceCacheKeys.rest.move(),
    mutationFn: (opts: MovePlannable) => moveRest(opts)
  })
}
