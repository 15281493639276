import type { Bugsnag } from '@bugsnag/js'
import bugsnag from '@bugsnag/js'
import {
  BUGSNAG_REACT_API_KEY,
  BUGSNAG_RELEASE_STAGE,
  COMMIT_HASH,
  IS_BUGSNAG_AVAILABLE
} from '@libs/utils/environments'

class ErrorMonitoringService {
  private bugsnagClient: Bugsnag.Client | null = null

  constructor() {
    this.initBugsnag()
  }

  private initBugsnag() {
    if (!IS_BUGSNAG_AVAILABLE || !bugsnag) {
      return
    }

    this.bugsnagClient = bugsnag({
      appVersion: COMMIT_HASH,
      apiKey: BUGSNAG_REACT_API_KEY || 'development',
      releaseStage: BUGSNAG_RELEASE_STAGE || 'development',
      notifyReleaseStages: ['production', 'review-app']
    })
  }

  /**
   * Identify bugsnag on app bootstrap,
   * So each event will receive additional information to help debugging
   */
  identify(user: any, account: any) {
    if (!this.bugsnagClient) {
      return
    }

    /**
     * Note: the membership might be undefined.
     */
    const membership = (user?.memberships || []).find(
      m => m.account_id === account.id
    )

    this.bugsnagClient.user = {
      id: user?.id,
      email: user?.email,
      firstname: user?.firstname,
      lastname: user?.lastname,
      membership_ids: user?.memberships.map(m => m.id),
      account: {
        id: account.id,
        subdomain: account.subdomain
      },
      membership: {
        id: membership?.id || 'missing membership',
        role: membership?.role || 'missing membership'
      }
    }
  }

  get client(): Bugsnag.Client | null {
    return this.bugsnagClient
  }
}

export const errorMonitoringService = new ErrorMonitoringService()
