import Immutable from 'immutable'
import moment from 'moment'
import { filterActions } from 'redux-ignore'
import {
  FETCH_TIMESHEETS,
  FETCH_TIMESHEETS_FAILED,
  FETCH_TIMESHEETS_SUCCEEDED
} from 'snap-redux/actionTypes'

export const TimesheetModel = Immutable.Record({
  date: {
    start_date: null,
    end_date: null,
    total_planned_hours: null,
    total_real_hours: null,
    total_realized_hours: null,
    total_worked_days: null,
    total_meals: null,
    weekly_reports: []
  }
})

export const timesheetForeignKeys = ['membership_id']

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false, success: false, error: false })
})

function timesheets(state = initialState, { type, ...payload }) {
  switch (type) {
    case FETCH_TIMESHEETS: {
      return state.setIn(
        ['meta'],
        Immutable.Map({
          loading: true,
          success: false,
          error: false
        })
      )
    }
    case FETCH_TIMESHEETS_FAILED: {
      return state.setIn(
        ['meta'],
        Immutable.Map({
          loading: false,
          success: false,
          error: true
        })
      )
    }
    case FETCH_TIMESHEETS_SUCCEEDED: {
      const timesheetsData = Immutable.fromJS(payload.data.timesheet)
      const startDate = timesheetsData.get('start_date')
      const month = moment(startDate).format('YYYY-MM')

      return state.setIn(['data', month], timesheetsData).setIn(
        ['meta'],
        Immutable.Map({
          loading: false,
          success: true,
          error: false
        })
      )
    }

    default:
      return state
  }
}

export default filterActions(timesheets, [
  FETCH_TIMESHEETS,
  FETCH_TIMESHEETS_FAILED,
  FETCH_TIMESHEETS_SUCCEEDED
])
