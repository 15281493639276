import type React from 'react'

import type {
  BaseButtonProps,
  HTMLAnchorType,
  HTMLButtonType
} from '../base/BaseButton.types'

/**
 * Returns the new props for the button component
 */
export const useButtonProps = (props: BaseButtonProps) => {
  type ModdedOther = typeof props & {
    component: React.ElementType
    to?: string
  } & Pick<HTMLAnchorType, 'href'> &
    Pick<HTMLButtonType, 'type' | 'role'>

  const {
    component = 'button',
    href,
    type,
    role,
    to,
    disabled,
    rel = 'noopener noreferrer'
  } = props as ModdedOther

  const buttonProps: Record<
    string,
    boolean | string | undefined | ((e: any) => any)
  > = {}

  if (href || to) {
    buttonProps.rel = rel
  }

  if (component === 'button') {
    buttonProps.type = type ?? 'button'
    buttonProps.disabled = disabled
  } else {
    if (!role) {
      if (component === 'a' || typeof component === 'object') {
        buttonProps.role = 'link'
      } else {
        buttonProps.role = 'button'
      }
    }

    if (disabled) {
      buttonProps.onClick = (e: MouseEvent) => e.preventDefault()
      buttonProps['aria-disabled'] = disabled
    }
  }

  return buttonProps
}
