import { ArrowRightIcon, SimpleCard } from '@libs/ui/ds'
import {
  ThemeIcon,
  Flex,
  Text,
  Title,
  IconButton
} from '@libs/ui/ds/lib/components'
import { outlineFocusMixin } from '@libs/ui/ds/lib/styles/mixins/mixins'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import { memo } from 'react'

import type { SummaryCardProps } from './SummaryCard.types'

const summaryCardCVA = {
  root: cva(['relative w-full', outlineFocusMixin], {
    variants: {
      clickable: {
        true: 'cursor-pointer hover:bg-neutral-100'
      }
    }
  }),
  desc: cva(['text-neutral-600'])
}

export const SummaryCard: FC<SummaryCardProps> = memo(
  ({ title, desc, icon, clickable = true, className, ...other }) => {
    return (
      <SimpleCard
        {...other}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        className={summaryCardCVA.root({
          className,
          clickable
        })}
      >
        <Flex row className='items-center gap-4'>
          {icon && <ThemeIcon icon={icon} />}
          <Flex row className='flex-1 justify-between'>
            <Flex>
              <Text className={summaryCardCVA.desc()}>{desc}</Text>
              <Title size={3}>{title}</Title>
            </Flex>
          </Flex>
          <IconButton
            disabled={!clickable}
            icon={clickable ? <ArrowRightIcon /> : null}
            variant='tertiary'
            size='m'
            intent='neutral-dark'
          />
        </Flex>
      </SimpleCard>
    )
  }
)

SummaryCard.displayName = 'SummaryCard'
export type { SummaryCardProps } from './SummaryCard.types'
