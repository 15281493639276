import { IS_SEGMENT_AVAILABLE } from '@libs/utils/environments'

export const reset = async () => {
  if (!IS_SEGMENT_AVAILABLE) {
    return
  }

  if (window.analytics?.reset) {
    await window.analytics.reset()
  }
}
