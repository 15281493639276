import { useMemoSelector } from '@spa/redux/hooks'
import { createSelector } from 'reselect'

import { getCountryConfiguration } from './getCountryConfiguration'

export const getAuthorizedPayrollSoftwareExportTypes = createSelector(
  [getCountryConfiguration],
  (countryConfiguration): string[] => {
    return countryConfiguration?.authorized_payroll_software_export_types
  }
)

export const useAuthorizedPayrollSoftwareExportTypes = () => {
  return useMemoSelector(getAuthorizedPayrollSoftwareExportTypes)
}
