import { appQueryClient } from '../../../provider'

export const monthlyServiceCacheKeys = {
  row: { all: queryParams => ['schedule_row', queryParams] },
  header: queryParams => ['schedule_header', queryParams],
  shift: {
    move: () => ['shift_move'] as const,
    duplicate: () => ['shift_duplicate'] as const,
    delete: () => ['shift_delete'] as const
  },
  rest: {
    move: () => ['rest_move'] as const,
    duplicate: () => ['rest_duplicate'] as const,
    delete: () => ['rest_delete'] as const
  }
}

export const monthlyServiceInvalidations = {
  invalidateRow: () => {
    appQueryClient.invalidateQueries({
      queryKey: monthlyServiceCacheKeys.row.all({})
    })
  },
  invalidateHeader: () => {
    appQueryClient.invalidateQueries({
      queryKey: monthlyServiceCacheKeys.header({})
    })
  }
}
