import type { TableProps } from '@libs/ui/ds'
import type { ColumnDef } from '@tanstack/react-table'

import concatSelectableColumn from './concatSelectableColumn'

export type createColumnsParams<T> = {
  columns: ColumnDef<T, any>[]
  selection?: TableProps<T>['selection']
  data?: T[]
}

export const useCreateColumns = <T extends unknown>({
  columns,
  selection,
  data
}: createColumnsParams<T>) => {
  const filteredColumns = columns.filter(
    column => column.meta?.enabled !== false
  )

  if (selection?.position === 'start') {
    return [
      ...concatSelectableColumn<T>({ selection, data }),
      ...filteredColumns
    ] as ColumnDef<T, any>[]
  }

  return [
    ...filteredColumns,
    ...concatSelectableColumn<T>({ selection, data })
  ] as ColumnDef<T, any>[]
}
