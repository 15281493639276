export const ArrowDownIcon = () => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.723996 5.3607C0.538508 5.37747 0.370228 5.46108 0.231724 5.60529C0.124852 5.71656 0.0620199 5.8246 0.0257479 5.95943C0.00749191 6.02728 0.00512391 6.05145 0.00493191 6.17199C0.00474791 6.28737 0.00741191 6.31824 0.0224999 6.37566C0.0578519 6.51018 0.116516 6.61434 0.22546 6.73599C0.262892 6.77779 2.27138 8.78683 4.68876 11.2005C7.6654 14.1726 9.10408 15.6038 9.14622 15.6347C9.34324 15.7794 9.55527 15.867 9.8 15.9048C9.88753 15.9184 10.1125 15.9184 10.2 15.9048C10.4459 15.8668 10.6566 15.7797 10.8538 15.6347C10.8957 15.6038 12.3537 14.1533 15.315 11.1964C17.7344 8.78058 19.7422 6.77226 19.7766 6.73348C19.8492 6.65172 19.8983 6.57894 19.934 6.49999C19.9599 6.44272 19.9918 6.32507 19.9919 6.28646C19.992 6.27462 19.9956 6.26271 20 6.25999C20.0046 6.25716 20.008 6.21575 20.008 6.16304C20.008 6.10375 20.0052 6.0728 20 6.07599C19.9954 6.07885 19.992 6.07108 19.992 6.05748C19.992 6.01413 19.9539 5.88338 19.9238 5.82343C19.8783 5.7328 19.8425 5.68264 19.7683 5.60529C19.6267 5.45788 19.4664 5.37946 19.2692 5.3612C19.053 5.34116 18.8568 5.39419 18.6941 5.51662C18.6665 5.53733 16.6991 7.49864 14.322 9.87509L10 14.1959L5.67 9.86736C2.28145 6.47996 1.32695 5.53004 1.28 5.49843C1.1526 5.41266 1.04231 5.37322 0.892884 5.36C0.843996 5.35567 0.802196 5.35259 0.799996 5.35316C0.797796 5.35372 0.763596 5.35712 0.723996 5.3607ZM0.00305191 6.17199C0.00306791 6.22479 0.00436391 6.24535 0.00592391 6.21768C0.00749191 6.19 0.00748391 6.1468 0.00589991 6.12168C0.00431591 6.09655 0.00303591 6.11919 0.00305191 6.17199Z'
        fill='currentColor'
      />
    </svg>
  )
}
