import { Loader } from '../../../feedback/Loader/base/Loader'

export const SuspenseWrapSpinner = () => {
  return (
    <div
      className='absolute flex size-full items-center justify-center'
      data-testid='loading-spinner'
    >
      <Loader theme='dark' size='s' />
    </div>
  )
}
