import type {
  ParamsSubscription,
  PaymentUpdateParams
} from '@libs/data-access/entities'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSliceSaga, SagaType } from 'redux-toolkit-saga'

import createSubscriptionPayConnectorActivated from '../generator/createSubscriptionPayConnectorActivated'
import deleteSubscriptionPayConnectorActivated from '../generator/deleteSubscriptionPayConnectorActivated'
import globalSubscriptionUpdate from '../generator/globalSubscriptionUpdate'
import updateSubscriptionData from '../generator/updateSubscriptionData'

const subscriptionSagaSlice = createSliceSaga({
  name: 'billing/subscription/sagas',
  caseSagas: {
    /**
     * Add pay_connector to subscription from snap billing service
     */
    *createSubscriptionPayConnector({
      payload
    }: PayloadAction<{ chargebeeAddonId: string }>) {
      yield createSubscriptionPayConnectorActivated(payload.chargebeeAddonId)
    },

    /**
     * Remove pay_connector to subscription data from snap billing service
     */
    *deleteSubscriptionPayConnector() {
      yield deleteSubscriptionPayConnectorActivated()
    },

    /**
     * Update subscription from snap billing service
     */
    *updateSubscriptionBilling({
      payload
    }: PayloadAction<Partial<ParamsSubscription>>) {
      yield updateSubscriptionData(payload)
    },

    /**
     *  This saga will be called each time the subscription
     *  need to be updated after a payment occured.
     */
    *updateSubscriptionGlobal({
      payload
    }: PayloadAction<Partial<PaymentUpdateParams>>) {
      yield globalSubscriptionUpdate(payload)
    }
  },
  sagaType: SagaType.TakeLatest
})

export const {
  updateSubscriptionBilling,
  updateSubscriptionGlobal,
  createSubscriptionPayConnector,
  deleteSubscriptionPayConnector
} = subscriptionSagaSlice.actions

export default subscriptionSagaSlice.saga()
