import type { PayloadAction } from '@reduxjs/toolkit'
import { SagaType, createSliceSaga } from 'redux-toolkit-saga'

import deleteDocumentEmployeeData from '../generator/deleteDocumentEmployee'
import fetchDocumentEmployeeData from '../generator/fetchDocumentEmployee'
import postSignatureDocumentEmployeeData from '../generator/postSignatureDocumentEmployee'

import type {
  ParamsDeleteDocumentEmployee,
  ParamsFetchDocumentEmployee,
  ParamsPostSignatureDocumentEmployee
} from './entities'

const documentEmployeeSagaSlice = createSliceSaga({
  name: 'emp/documentEmployee/sagas',
  caseSagas: {
    /**
     * Fetch all the documents in the database attached to the membership_id passed.
     */
    *fetchDocumentEmployee({
      payload
    }: PayloadAction<ParamsFetchDocumentEmployee>) {
      yield fetchDocumentEmployeeData(payload)
    },

    /**
     * Post document for electornic signatures with all the signatories and information needed.
     */
    *postSignatureDocumentEmployee({
      payload
    }: PayloadAction<ParamsPostSignatureDocumentEmployee>) {
      yield postSignatureDocumentEmployeeData(payload)
    },

    /**
     * Delete document
     */
    *deleteDocumentEmployee({
      payload
    }: PayloadAction<ParamsDeleteDocumentEmployee>) {
      yield deleteDocumentEmployeeData(payload)
    }
  },
  sagaType: SagaType.TakeLatest
})

export const {
  fetchDocumentEmployee,
  postSignatureDocumentEmployee,
  deleteDocumentEmployee
} = documentEmployeeSagaSlice.actions

export default documentEmployeeSagaSlice.saga()
