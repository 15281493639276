import type { WeeklyScheduleSchema } from '@libs/data-access/entities'
import type { ReduxState } from '@spa/redux/ReduxState'
import { useMemoSelector } from '@spa/redux/hooks'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'

import { shiftsSelectors, weeklyschedulesSelectors } from '../../entities'
import { payPeriodsSelectors } from '../../entities/payPeriods'
import { getDateIsPastWeek } from '../../params'
import { isSameDay, isBeforeDay } from '../../plannables/utils'
import { getEditShiftPolicies } from '../useEditShiftPolicies/useEditShiftPolicies'

type Params = {
  shiftId: number
  weeklyscheduleId: number
  day: string
}

const getShiftPolicies = (
  state: ReduxState,
  { shiftId, weeklyscheduleId }: Params
) =>
  getEditShiftPolicies(state, {
    shiftId,
    weeklyscheduleId
  })

const getWeeklyshechduleById = (
  state: ReduxState,
  { weeklyscheduleId }: Params
) => weeklyschedulesSelectors.getCachedById(state, weeklyscheduleId)

const getShiftById = (state: ReduxState, { shiftId }: Params) =>
  shiftsSelectors.getCachedById(state, shiftId)

const getIsShiftLockedOnDay = (state: ReduxState, { day }: Params) =>
  payPeriodsSelectors.getLockedDaysInTimerange(state)[day]?.isLocked

const computeRndShiftPolicies = (
  isPastWeek: boolean,
  { canEditShift }: { canEditShift: boolean },
  weeklyschedule: Omit<WeeklyScheduleSchema, 'permissions'>,
  { starts_at, validated_at }: { starts_at: string; validated_at: string },
  isLocked: boolean
) => {
  const toLockedAllowed = !isLocked
  const today = new Date()
  const isFromToday = isSameDay(today, starts_at)
  const isFromPast = isBeforeDay(starts_at, today)

  const isPublished = weeklyschedule?.published
  const moveInFutureAllowed = isPublished
    ? !isPastWeek && !isFromPast && !isFromToday
    : true

  const fromValidated = Boolean(validated_at)

  return {
    canRndShift:
      canEditShift && toLockedAllowed && moveInFutureAllowed && !fromValidated
  }
}

export const getRndShiftPolicies = createCachedSelector(
  getDateIsPastWeek,
  getShiftPolicies,
  getWeeklyshechduleById,
  getShiftById,
  getIsShiftLockedOnDay,
  computeRndShiftPolicies
)((_, { shiftId }) => fallbackToDefaultCacheKey(shiftId))

export const useRndShiftPolicies = (opts: Params) => {
  return useMemoSelector(state => getRndShiftPolicies(state, opts))
}
