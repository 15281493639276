import type { FC } from 'react'
import { memo } from 'react'

import type { LoaderProps } from './Loader.types'
import { LoaderContainer } from './compound/LoaderContainer'
import { LoaderOverlay } from './compound/LoaderOverlay'
import { LoaderWrap } from './compound/LoaderWrap'
import { Spinner } from './subs/Spinner'

const BaseLoader: FC<LoaderProps> = memo(({ ...other }) => {
  return <Spinner {...other} />
})

type CompoundLoaderType = {
  Overlay: typeof LoaderOverlay
  Wrap: typeof LoaderWrap
  Container: typeof LoaderContainer
}

const TypedLoader = BaseLoader as typeof BaseLoader & CompoundLoaderType
TypedLoader.displayName = 'Loader'

TypedLoader.Overlay = LoaderOverlay
TypedLoader.Wrap = LoaderWrap
TypedLoader.Container = LoaderContainer
export const Loader = TypedLoader
export type { LoaderProps } from './Loader.types'
export type { LoaderOverlayProps } from './compound/LoaderOverlay.types'
export type { LoaderWrapProps } from './compound/LoaderWrap.types'
export type { LoaderContainerProps } from './compound/LoaderContainer.types'
