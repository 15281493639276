import { useMemoSelector } from '@spa/redux/hooks'
import { createSelector } from 'reselect'

import { getCountryConfiguration } from './getCountryConfiguration'

export const getAuthorizedWorkPermitTypes = createSelector(
  [getCountryConfiguration],
  (countryConfiguration): string[] => {
    return countryConfiguration.authorized_work_permit_types
  }
)

export const useAuthorizedWorkPermitTypes = () => {
  return useMemoSelector(getAuthorizedWorkPermitTypes)
}
