import { NumberInput } from '@libs/ui/ds'
import { ControllerBase } from '@libs/ui/form'
import type { FieldValues, FieldPath } from 'react-hook-form'

import type { ControlledNumberInputProps } from './ControlledNumberInput.types'

export const ControlledNumberInput = <
  TFieldValues extends FieldValues = FieldValues,
  TInputName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  onChangeSideEffect,
  rules,
  ...numberInputProps
}: ControlledNumberInputProps<TFieldValues, TInputName>) => (
  <ControllerBase
    rules={rules}
    control={control}
    name={name}
    onChangeAdapter={e => e}
    onChangeSideEffect={onChangeSideEffect}
    field={<NumberInput {...numberInputProps} />}
  />
)
