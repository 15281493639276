import { useSafeRouterSearchParams } from '@libs/ui/ds'
import { routes } from '@spa/core/router'
import { useLocation, createSearchParams } from 'react-router-dom'

export const useRedirectToWhenSignedOut = () => {
  const { pathname } = useLocation()
  const [currentSearchParams] = useSafeRouterSearchParams()
  const isPathnameValid = pathname && pathname !== '/'

  const searchParams = createSearchParams({
    ...(isPathnameValid ? { redirect_to: pathname } : {}),
    ...currentSearchParams
  })
  return `${routes.signIn.rootPath}?${searchParams.toString()}`
}
