import { PopoverProvider } from '@libs/ui/ds/lib/components/overlays/Popover/base/Popover.context'
import { Popover as MantinePopover } from '@mantine/core'

export const DefaultPopover = ({
  uncontrolledValue,
  setUncontrolledValue,
  dropdownHeight,
  dropdownWidth,
  children,
  trigger,
  ...other
}) => {
  return (
    <PopoverProvider
      value={{
        opened: uncontrolledValue,
        onChange: setUncontrolledValue,
        trigger,
        dropdownHeight,
        dropdownWidth
      }}
    >
      <MantinePopover
        {...other}
        onChange={setUncontrolledValue}
        opened={uncontrolledValue}
        withinPortal
      >
        {children}
      </MantinePopover>
    </PopoverProvider>
  )
}
