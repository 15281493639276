import type { AccountAddon } from '@libs/data-access/entities'
import { useQuery } from '@tanstack/react-query'

import type { UseAppQueryOptions } from '../../../../types'
import {
  type FetchAccountAddonOptions,
  fetchAccountAddon
} from '../accountAddon.api'
import { addonCacheKeys } from '../addons.cacheKeys'

export const useFetchAccountAddon = (
  request: FetchAccountAddonOptions,
  queryOptions?: UseAppQueryOptions<AccountAddon>
) => {
  return useQuery({
    ...queryOptions,
    queryKey: addonCacheKeys.one(request.pathParams.addon_enum),
    queryFn: () => fetchAccountAddon(request)
  })
}
