import type { JobSchema } from '@libs/data-access/entities'
import { useQuery } from '@tanstack/react-query'

import type {
  PaginatedQueryResponse,
  UseAppQueryOptions
} from '../../../../types'
import type { FetchJobsOptions } from '../jobs.api'
import { fetchJobs } from '../jobs.api'
import { jobsCacheKeys } from '../jobs.cacheKeys'

export const useFetchJobs = (
  request: FetchJobsOptions,
  queryOptions?: UseAppQueryOptions<PaginatedQueryResponse<JobSchema, 'jobs'>>
) => {
  return useQuery({
    ...queryOptions,
    queryKey: jobsCacheKeys.fetch_jobs(request?.queryParams),
    queryFn: () => fetchJobs(request)
  })
}
