import { CloseIcon } from '@libs/ui/ds/assets/icons'
import {
  IconButton,
  ThemeIcon,
  Inline,
  Title
} from '@libs/ui/ds/lib/components'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import { memo } from 'react'

import { useModalContext } from '../../Modal.context'

import type { ModalHeaderProps } from './ModalHeader.types'

const modalHeaderCVA = {
  root: cva(['p-6']),
  leftSection: cva(['items-center']),
  desc: cva(['text-xs text-neutral-500'])
}

export const ModalHeader: FC<ModalHeaderProps> = memo(
  ({ className, title, leftIcon, desc, ...other }) => {
    const ctx = useModalContext()
    return (
      <div {...other} className={modalHeaderCVA.root({ className })}>
        <Inline stretch='start' gap={6}>
          <Inline
            stretch='end'
            gap={4}
            className={modalHeaderCVA.leftSection()}
          >
            {leftIcon && <ThemeIcon color='neutral' icon={leftIcon} size='m' />}
            <div>
              <Title size={3}>{title}</Title>
              <div className={modalHeaderCVA.desc()}>{desc}</div>
            </div>
          </Inline>
          <IconButton
            disabled={ctx?.disableClose}
            data-testid='icon-close'
            icon={<CloseIcon />}
            variant='tertiary'
            intent='neutral-dark'
            onClick={ctx?.onClose}
          />
        </Inline>
      </div>
    )
  }
)

ModalHeader.displayName = 'ModalHeader'
