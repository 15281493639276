import { fork, select } from 'redux-saga/effects'
import { getCurrentAccount } from 'snap-redux/modules/accounts/selectors'

import { getWeekDateParams } from '../../../selectors'
import { entitiesSagas } from '../../entities'

export function* requestCoreEntities() {
  const { week } = yield select(getWeekDateParams)
  const account = yield select(getCurrentAccount)

  if (!week.start) {
    return
  }

  /**
   * Request and hydrate weeklyschedules
   */
  yield fork(function* () {
    yield entitiesSagas.getWeeklyschedules({
      payload: {
        accountId: account.id,
        week: week.iso,
        withPermissions: true
      }
    })
  })

  /**
   * Request and hydrate all locations
   *
   * Locations are already fetched in planning aggregate but we need
   * to request all locations in order to display properly shifts from externals location
   */
  yield fork(function* () {
    yield entitiesSagas.getLocations({
      payload: {
        accountId: account.id
      }
    })
  })

  /**
   * Request labels from all location
   *
   * This request is needed in order to properly display shifts from externals location
   */
  yield fork(function* () {
    yield entitiesSagas.getLabels({
      payload: {
        accountId: account.id
      }
    })
  })
}
