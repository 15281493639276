import { call } from 'redux-saga/effects'
import {
  DUPLICATE_WEEKLYSCHEDULE,
  DUPLICATE_WEEKLYSCHEDULE_FAILED,
  DUPLICATE_WEEKLYSCHEDULE_SUCCEEDED
} from 'snap-redux/actionTypes'
import { fetchSagaEntity } from 'snap-redux/services/sagaUtils'

import { call as callAPI } from '../../../infra/http'

export type DuplicateWeeklyschedulePayload = {
  membership_ids: number[]
  target_weeklyschedule_ids: number[]
}

const duplicateWeeklyscheduleSagaEntity = {
  success: data => ({ type: DUPLICATE_WEEKLYSCHEDULE_SUCCEEDED, data }),
  failure: error => ({ type: DUPLICATE_WEEKLYSCHEDULE_FAILED, error }),
  fetchAPI: (id, options) =>
    callAPI(`/weeklyschedules/${id}/duplicate`, { method: 'POST', ...options })
}

export function* doDuplicateWeeklyschedule({
  weeklyscheduleId,
  duplicatePayload,
  resolve,
  reject
}: {
  weeklyscheduleId: number
  duplicatePayload: DuplicateWeeklyschedulePayload
  resolve: any
  reject: any
}) {
  yield call(
    fetchSagaEntity,
    duplicateWeeklyscheduleSagaEntity,
    weeklyscheduleId,
    null,
    duplicatePayload,
    resolve,
    reject
  )
}

export const duplicateWeeklyschedule = (
  weeklyscheduleId: number,
  duplicatePayload: DuplicateWeeklyschedulePayload,
  resolve,
  reject
) => ({
  type: DUPLICATE_WEEKLYSCHEDULE,
  weeklyscheduleId,
  duplicatePayload,
  resolve,
  reject
})
