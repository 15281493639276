import { useMergedScreenProps } from '@libs/ui/ds/lib/theme'
import type { FC } from 'react'
import { memo } from 'react'

import { simpleCardCVA } from './SimpleCard.classes'
import type { SimpleCardProps } from './SimpleCard.types'

export const SimpleCard: FC<SimpleCardProps> = memo(
  ({
    children,
    size = 'm',
    shadow,
    className,
    rounded = 'm',
    screens = {},
    hideBorder,
    ...other
  }) => {
    const mergedProps = useMergedScreenProps({ size }, screens)

    return (
      <div
        {...other}
        className={simpleCardCVA.root({
          className,
          size: mergedProps.size,
          shadow: Boolean(shadow),
          rounded,
          hideBorder: Boolean(hideBorder)
        })}
      >
        {children}
      </div>
    )
  }
)

SimpleCard.displayName = 'SimpleCard'
export type { SimpleCardProps } from './SimpleCard.types'
