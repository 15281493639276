import type { PayloadAction } from '@reduxjs/toolkit'
import { createSliceSaga, SagaType } from 'redux-toolkit-saga'

import fetchEstimateData from '../generator/fetchEstimateData'

const estimateSagaSlice = createSliceSaga({
  name: 'billing/estimate/sagas',
  caseSagas: {
    *fetchEstimate({ payload }: PayloadAction<string>) {
      yield fetchEstimateData(payload)
    }
  },
  sagaType: SagaType.TakeLatest
})

export const { fetchEstimate } = estimateSagaSlice.actions

export default estimateSagaSlice.saga()
