import type { WeeklyScheduleSchema } from '@libs/data-access/entities'
import { shiftService } from '@libs/data-access/queries'
import { notifications } from '@libs/ui/ds'
import { select } from 'redux-saga/effects'
import type { Shift } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import {
  getParams,
  shiftsSelectors,
  weeklyschedulesSelectors
} from '../../../../selectors'
import fetchPlannablesSideEffectsEntities from '../../../controllers/request/fetchPlannableSideEffectsEntities'
import type { PayloadActionWithoutType } from '../../../types/types'

type Params = PayloadActionWithoutType<{
  shiftIds: number[]
  weeklyscheduleId: number
}>

function* bulkToggleValidateShifts({ payload }: Params) {
  const weeklyscheduleMap = yield select(weeklyschedulesSelectors.getCachedMap)
  const weeklyschedule: WeeklyScheduleSchema =
    weeklyscheduleMap[payload.weeklyscheduleId]

  if (!weeklyschedule.published) {
    return
  }

  const params = yield select(getParams)
  const allShifts = yield select(shiftsSelectors.getCachedMap)
  const shifts: Shift[] = payload.shiftIds.map(id => allShifts[id])
  const filteredShifts = shifts.filter(
    shift => shift?.weeklyschedule_id === payload.weeklyscheduleId
  )
  const shouldValidate = filteredShifts.some(shift => !shift?.validated_at)
  const updatedContractIds = filteredShifts.map(shift => shift.user_contract_id)

  const requestParams = {
    payload: { shift_ids: filteredShifts.map(shift => shift?.id) }
  }

  try {
    yield shouldValidate
      ? shiftService.bulk.api.bulkValidateShiftsList(requestParams)
      : shiftService.bulk.api.bulkInvalidateShiftsList(requestParams)

    yield fetchPlannablesSideEffectsEntities({
      contractIds: updatedContractIds,
      date: params.date,
      countersParams: { live: true }
    })
  } catch (e) {
    notifications.errorAdapter({ error: e.error })
  }
}

export default bulkToggleValidateShifts
