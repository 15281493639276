import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_PAGINATED_DSN_EVENTS,
  FETCH_PAGINATED_DSN_EVENTS_SUCCEEDED,
  FETCH_PAGINATED_DSN_EVENTS_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchDsnEvents = (accountId, params, resolve, reject) => ({
  type: FETCH_PAGINATED_DSN_EVENTS,
  accountId,
  params,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const fetchDsnEventsSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_PAGINATED_DSN_EVENTS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_PAGINATED_DSN_EVENTS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/dsn_events?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchDsnEvents({ accountId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    fetchDsnEventsSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const DsnEvents = Immutable.Record({
  id: null,
  event_type: null,
  file_id: null,
  location_id: null,
  object_description: null,
  object_end_date: null,
  object_id: null,
  object_start_date: null,
  object_type: null,
  occurred_at: null,
  rest_type: null,
  team_id: null,
  duration_in_days: null,
  duration_in_hours: null,
  user_contract_id: null,
  user_contract: Immutable.Map()
})

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map(),
  pagination: Immutable.Map()
})

function dsnEvents(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_PAGINATED_DSN_EVENTS: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_PAGINATED_DSN_EVENTS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_PAGINATED_DSN_EVENTS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const dsnEnvents = Immutable.fromJS(action.data.events)
      // @ts-ignore migration from js(x) to ts(x)
      const pagination = Immutable.fromJS(action.data.meta)
      // @ts-ignore migration from js(x) to ts(x)
      const currentPage = action.data.meta.current_page

      return mergeRecords(state, DsnEvents, dsnEnvents).withMutations(map => {
        map.mergeDeepIn(['meta'], {
          loading: false,
          updated_at: Date.now(),
          success: true
        })
        map.mergeDeepIn(['pagination'], pagination)
        map.setIn(
          ['pagination', 'pages', currentPage],
          dsnEnvents.map(dsnEnvent => dsnEnvent.get('id')).toSet()
        )
      })
    }
    default:
      return state
  }
}

export default filterActions(dsnEvents, [
  FETCH_PAGINATED_DSN_EVENTS,
  FETCH_PAGINATED_DSN_EVENTS_FAILED,
  FETCH_PAGINATED_DSN_EVENTS_SUCCEEDED
])
