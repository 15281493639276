import type { ReduxState } from '@spa/redux/ReduxState'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import moment from 'moment'
import createCachedSelector from 're-reselect'

import { getDate } from '../../../params'
import { getPlannableCardsByDayAndTeam } from '../getPlannableCardsByDayAndTeam/getPlannableCardsByDayAndTeam'

type Params = {
  teamId: number
}

export const getFirstStartPlannableCardOfDayInTeam = createCachedSelector(
  (state: ReduxState, opts) =>
    getPlannableCardsByDayAndTeam(state, { ...opts, day: getDate(state) }),

  cards => {
    const [firstCard] = cards

    return cards.reduce((acc, card) => {
      const isBefore = moment(card.starts_at).isBefore(acc.starts_at)
      return isBefore ? card : acc
    }, firstCard)
  }
)((_, { teamId }: Params) => fallbackToDefaultCacheKey(teamId))
