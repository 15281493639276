type CacheKey = string | number | boolean

/**
 * Because re-select does not accept null or undefined as cache key selector
 * We better return empty string by default when the key is not defined
 */
export const fallbackToDefaultCacheKey = (key: CacheKey) => {
  return key || ''
}

/**
 * Because re-select does not accept null or undefined as cache key selector
 * We better return empty string by default when the key is not defined
 */
export const composeCacheKey = (...args: CacheKey[]) => {
  return args.join('-')
}
