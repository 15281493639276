import { takeEvery, debounce, call } from 'redux-saga/effects'
import { abort } from 'snap-redux/infra/http/api'

import {
  PLANNING_INIT,
  PLANNING_REFRESH,
  PLANNING_CANCEL
} from '../actionTypes'

import planningInitSaga from './init.sagas'
import planningRefreshSaga from './refresh/refresh.sagas'
import { workerSagasFactory } from './worker.sagas'

const sagas = [
  debounce(
    500,
    PLANNING_INIT as any,
    workerSagasFactory(planningInitSaga, PLANNING_CANCEL)
  ),
  debounce(
    500,
    PLANNING_REFRESH as any,
    workerSagasFactory(planningRefreshSaga, PLANNING_CANCEL)
  ),
  takeEvery(PLANNING_CANCEL, function* () {
    yield call(abort)
  })
]

export default sagas
