import { useMemoSelector } from '@spa/redux/hooks'
import { createSelector } from 'reselect'

import { getCountryConfiguration } from './getCountryConfiguration'

export const getAuthorizedContractTypes = createSelector(
  [getCountryConfiguration],
  (countryConfiguration): string[] => {
    if (!countryConfiguration?.authorized_contract_types) {
      return []
    }
    return countryConfiguration.authorized_contract_types
  }
)

export const useAuthorizedContractTypes = () => {
  return useMemoSelector(getAuthorizedContractTypes)
}
