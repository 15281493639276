import { takeLatest, takeEvery, throttle } from 'redux-saga/effects'

import * as ACTIONS from '../actionTypes'
import { doFetchMonthlyDashboardTurnovers } from '../actions/monthlyDashboardTurnovers'
import { doFetchMonthlyWorkTime } from '../actions/monthlyWorkTime'
import { doFetchPlannableOverlap } from '../actions/plannableOverlap'
import { doFetchPlannablesList } from '../actions/plannables'
import { doFetchSubscriptionInvoice } from '../actions/subscriptionInvoice'
import { doFetchTheoreticalRestDuration } from '../actions/theoreticalRestDuration'
import { doFetchAccountLocations } from '../modules/account_locations'
import {
  doFetchAccount,
  doUpdateAccount,
  doUpdateAccountReorder
} from '../modules/accounts'
import {
  doCreateAllLocationsReportFile,
  doFetchAllLocationsReportFiles,
  doDeleteAllLocationsReportFile
} from '../modules/allLocationsReportFiles'
import {
  doFetchArticles,
  doFetchArticle,
  doCreateArticle,
  doUpdateArticle,
  doDeleteArticle
} from '../modules/articles'
import {
  doFetchAttachment,
  doCreateAttachment,
  doDeleteAttachment
} from '../modules/attachments'
import {
  doFetchCollectiveAgreement,
  doFetchCollectiveAgreements
} from '../modules/collectiveAgreements'
import { doFetchPaginatedContractEvents } from '../modules/contractEvents'
import {
  doFetchContractTemplates,
  doCreateContractTemplate,
  doUpdateContractTemplate,
  doDeleteContractTemplate,
  doGenerateContract
} from '../modules/contractTemplates'
import {
  doFetchContracts,
  doFetchContract,
  doFetchPaginatedContracts,
  doFetchPaginatedFilteredContracts,
  doCreateContract,
  doUpdateContract,
  doDeleteContract
} from '../modules/contracts'
import { doFetchConversationAttachments } from '../modules/conversationAttachments'
import {
  doFetchConversation,
  doFetchConversations,
  doRefreshConversations,
  doCreateConversation,
  doUpdateConversation
} from '../modules/conversations'
import {
  doFetchCountersByContractId,
  doFetchCountersByLocationId
} from '../modules/counters'
import { countryConfigurationsSaga } from '../modules/countryConfigurations'
import { doFetchCountryMinWages } from '../modules/country_min_wages'
import { doFetchDasbhoardKPIs } from '../modules/dashboard_kpis'
import { doFetchDsnEvents } from '../modules/dsnEvents'
import { doFetchDsnFiles, doCreateDsnFiles } from '../modules/dsnFiles'
import { doCreateExportFiles } from '../modules/exportFiles'
import { doFetchHolidays } from '../modules/holidays'
import {
  doFetchIntegrations,
  doUpdateIntegration,
  docreateIntegration
} from '../modules/integrations'
import {
  doFetchLabels,
  doFetchLabelsFromLocation,
  doFetchLabelsFromAccount,
  doCreateLabel,
  doUpdateLabel,
  doDeleteLabel
} from '../modules/labels'
import {
  doFetchLocations,
  doFetchLocation,
  doCreateLocation,
  doUpdateLocation
} from '../modules/locations'
import {
  doFetchMembershipsTutors,
  doFetchMemberships,
  doFetchMemberlistMemberships,
  doFetchNotificationMemberships,
  doFetchMembership,
  doCreateMembership,
  doUpdateMembership,
  doDeleteMembership,
  doInviteMembership,
  doFetchAllMemberships,
  doUpdateMembershipPermissions
} from '../modules/memberships'
import { FETCH_ALL_MEMBERSHIPS } from '../modules/memberships/actionTypes'
import {
  doFetchMessages,
  doRefreshMessages,
  doCreateMessage,
  doUpdateMessage
} from '../modules/messages'
import { doDeleteModulationPeriodCountersEntry } from '../modules/modulationPeriodCounterEntries'
import {
  doFetchModulationPeriodCounter,
  doFetchModulationPeriodCounters,
  doUpdateModulationPeriodCounter,
  doFetchModulationPeriodCountersByLocation,
  doFetchModulationPeriodCountersByMembership
} from '../modules/modulationPeriodCounters'
import {
  doFetchModulationPeriod,
  doFetchModulationPeriods,
  doCreateModulationPeriod,
  doUpdateModulationPeriod,
  doDeleteModulationPeriod
} from '../modules/modulationPeriods'
import { doCreateNote, doUpdateNote, doDeleteNote } from '../modules/notes'
import { doFetchUnreadNotifications } from '../modules/notifications'
import {
  doFetchPadUsers,
  doCreatePadUser,
  doUpdatePadUser,
  doDeletePadUser
} from '../modules/padUsers'
import { paidLeaveConfigurationSaga } from '../modules/paidLeaveLedgerOperations'
import { doFetchPayPeriods, doFetchPayPeriod } from '../modules/payPeriods'
import { doFetchPayrollSoftware } from '../modules/payrollSoftware'
import {
  doFetchPayrollSoftwareConfigurations,
  doUpdatePayrollSoftwareConfiguration
} from '../modules/payrollSoftwareConfigurations'
import { doFetchPayroll } from '../modules/payrolls'
import { doFetchReportFiles } from '../modules/reportFiles'
import {
  doCreateReport,
  doFetchReport,
  doFetchReportWithRows,
  doUpdateReport
} from '../modules/reports'
import {
  doFetchRests,
  doFetchRest,
  doCreateRest,
  doUpdateRest,
  doDeleteRest
} from '../modules/rests'
import { doFetchRevenues, doUpdateRevenue } from '../modules/revenues'
import {
  doFetchRewardTemplates,
  doCreateRewardTemplate,
  doUpdateRewardTemplate,
  doDeleteRewardTemplate
} from '../modules/rewardTemplates'
import {
  doFetchRewards,
  doFetchRewardsByMembership,
  doCreateReward,
  doUpdateReward,
  doDeleteReward
} from '../modules/rewards'
import {
  doFetchShiftTemplates,
  doCreateShiftTemplate,
  doUpdateShiftTemplate,
  doDeleteShiftTemplate
} from '../modules/shiftTemplates'
import {
  doFetchShifts,
  doFetchShift,
  doFetchPaginatedShifts,
  doCreateShift,
  doCreateBulkShifts,
  doUpdateShift,
  doDeleteShift,
  doFetchAllShifts,
  doValidateBulkShifts,
  doInvalidateBulkShifts
} from '../modules/shifts'
import { FETCH_ALL_SHIFTS } from '../modules/shifts/actionTypes'
import { doSubscribeToDefaultChannel } from '../modules/socket'
import { doFetchSocketAuthorizationTicket } from '../modules/socketAuthorizationTickets'
import { doUpdateTeam, doDeleteTeam } from '../modules/teams'
import {
  doUpdateTimeoff,
  doCreateTimeoff,
  doDeleteTimeoff
} from '../modules/timeoffs'
import { doFetchTimesheet } from '../modules/timesheets/sagas'
import {
  doFetchUser,
  doFetchUserToucanJWT,
  doUpdateUser
} from '../modules/users'
import {
  doFetchWeeklyschedules,
  doFetchWeeklyschedule,
  doDuplicateWeeklyschedule,
  doUpdateWeeklyschedule,
  doDeleteWeeklyschedule
} from '../modules/weeklyschedules'

export default [
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_ARTICLES, doFetchArticles),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_ARTICLE, doFetchArticle),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.CREATE_ARTICLE, doCreateArticle),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.UPDATE_ARTICLE, doUpdateArticle),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.DELETE_ARTICLE, doDeleteArticle),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_LOCATIONS, doFetchLocations),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_LOCATION, doFetchLocation),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.CREATE_LOCATION, doCreateLocation),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.UPDATE_LOCATION, doUpdateLocation),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.UPDATE_TEAM, doUpdateTeam),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.DELETE_TEAM, doDeleteTeam),

  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_ACCOUNT_LOCATIONS, doFetchAccountLocations),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_HOLIDAYS, doFetchHolidays),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_INTEGRATIONS, doFetchIntegrations),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.UPDATE_INTEGRATION, doUpdateIntegration),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.CREATE_INTEGRATION, docreateIntegration),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_CONVERSATION, doFetchConversation),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_CONVERSATIONS, doFetchConversations),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.REFRESH_CONVERSATIONS, doRefreshConversations),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.UPDATE_CONVERSATION, doUpdateConversation),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.CREATE_CONVERSATION, doCreateConversation),

  takeLatest(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.FETCH_CONVERSATION_ATTACHMENTS,
    doFetchConversationAttachments
  ),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_MESSAGES, doFetchMessages),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.REFRESH_MESSAGES, doRefreshMessages),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.UPDATE_MESSAGE, doUpdateMessage),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.CREATE_MESSAGE, doCreateMessage),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_LABELS, doFetchLabels),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_LABELS_FROM_LOCATION, doFetchLabelsFromLocation),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_LABELS_FROM_ACCOUNT, doFetchLabelsFromAccount),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.CREATE_LABEL, doCreateLabel),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.UPDATE_LABEL, doUpdateLabel),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.DELETE_LABEL, doDeleteLabel),

  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_CONTRACTS, doFetchContracts),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_CONTRACT, doFetchContract),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_PAGINATED_CONTRACTS, doFetchPaginatedContracts),
  takeEvery(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.FETCH_PAGINATED_FILTERED_CONTRACTS,
    doFetchPaginatedFilteredContracts
  ),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.CREATE_CONTRACT, doCreateContract),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.UPDATE_CONTRACT, doUpdateContract),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.DELETE_CONTRACT, doDeleteContract),

  takeEvery(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.FETCH_PAGINATED_CONTRACT_EVENTS,
    doFetchPaginatedContractEvents
  ),

  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_CONTRACT_TEMPLATES, doFetchContractTemplates),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.CREATE_CONTRACT_TEMPLATE, doCreateContractTemplate),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.UPDATE_CONTRACT_TEMPLATE, doUpdateContractTemplate),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.DELETE_CONTRACT_TEMPLATE, doDeleteContractTemplate),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.FETCH_COUNTERS_BY_CONTRACT_ID,
    doFetchCountersByContractId
  ),
  takeLatest(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.FETCH_COUNTERS_BY_LOCATION_ID,
    doFetchCountersByLocationId
  ),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_PAGINATED_DSN_EVENTS, doFetchDsnEvents),

  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.CREATE_DSN_FILES, doCreateDsnFiles),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_DSN_FILES, doFetchDsnFiles),

  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_MEMBERSHIPS, doFetchMemberships),
  throttle(
    250,
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.FETCH_MEMBERLIST_MEMBERSHIPS,
    doFetchMemberlistMemberships
  ),
  throttle(
    250,
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.FETCH_NOTIFICATION_MEMBERSHIPS,
    doFetchNotificationMemberships
  ),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_MEMBERSHIPS_TUTORS, doFetchMembershipsTutors),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_MEMBERSHIP, doFetchMembership),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.CREATE_MEMBERSHIP, doCreateMembership),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.UPDATE_MEMBERSHIP, doUpdateMembership),
  takeLatest(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.UPDATE_MEMBERSHIP_PERMISSIONS,
    doUpdateMembershipPermissions
  ),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.DELETE_MEMBERSHIP, doDeleteMembership),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.INVITE_MEMBERSHIP, doInviteMembership),

  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_SHIFTS, doFetchShifts),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_SHIFT, doFetchShift),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_PAGINATED_SHIFTS, doFetchPaginatedShifts),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.CREATE_SHIFT, doCreateShift),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.CREATE_BULK_SHIFTS, doCreateBulkShifts),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.VALIDATE_BULK_SHIFTS, doValidateBulkShifts),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.INVALIDATE_BULK_SHIFTS, doInvalidateBulkShifts),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.UPDATE_SHIFT, doUpdateShift),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.DELETE_SHIFT, doDeleteShift),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.VALIDATE_BULK_SHIFTS, doValidateBulkShifts),

  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_SHIFT_TEMPLATES, doFetchShiftTemplates),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.CREATE_SHIFT_TEMPLATE, doCreateShiftTemplate),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.UPDATE_SHIFT_TEMPLATE, doUpdateShiftTemplate),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.DELETE_SHIFT_TEMPLATE, doDeleteShiftTemplate),

  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_REWARD_TEMPLATES, doFetchRewardTemplates),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.CREATE_REWARD_TEMPLATE, doCreateRewardTemplate),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.UPDATE_REWARD_TEMPLATE, doUpdateRewardTemplate),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.DELETE_REWARD_TEMPLATE, doDeleteRewardTemplate),

  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_REWARDS, doFetchRewards),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_REWARDS_BY_MEMBERSHIP, doFetchRewardsByMembership),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.CREATE_REWARD, doCreateReward),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.UPDATE_REWARD, doUpdateReward),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.DELETE_REWARD, doDeleteReward),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.GENERATE_CONTRACT, doGenerateContract),

  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_RESTS, doFetchRests),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_REST, doFetchRest),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.CREATE_REST, doCreateRest),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.UPDATE_REST, doUpdateRest),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.DELETE_REST, doDeleteRest),

  takeEvery(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.FETCH_THEORETICAL_REST_DURATION,
    doFetchTheoreticalRestDuration
  ),

  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_USER, doFetchUser),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.UPDATE_USER, doUpdateUser),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_USER_TOUCAN_JWT, doFetchUserToucanJWT),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.UPDATE_TIMEOFF, doUpdateTimeoff),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.CREATE_TIMEOFF, doCreateTimeoff),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.DELETE_TIMEOFF, doDeleteTimeoff),

  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_TIMESHEETS, doFetchTimesheet),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_ATTACHMENT, doFetchAttachment),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.CREATE_ATTACHMENT, doCreateAttachment),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.DELETE_ATTACHMENT, doDeleteAttachment),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_ACCOUNT, doFetchAccount),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.UPDATE_ACCOUNT, doUpdateAccount),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.UPDATE_ACCOUNT_REORDER, doUpdateAccountReorder),

  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_WEEKLYSCHEDULES, doFetchWeeklyschedules),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_WEEKLYSCHEDULE, doFetchWeeklyschedule),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.DUPLICATE_WEEKLYSCHEDULE, doDuplicateWeeklyschedule),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.UPDATE_WEEKLYSCHEDULE, doUpdateWeeklyschedule),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.DELETE_WEEKLYSCHEDULE, doDeleteWeeklyschedule),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.CREATE_NOTE, doCreateNote),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.UPDATE_NOTE, doUpdateNote),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.DELETE_NOTE, doDeleteNote),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_PAD_USERS, doFetchPadUsers),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.CREATE_PAD_USER, doCreatePadUser),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.UPDATE_PAD_USER, doUpdatePadUser),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.DELETE_PAD_USER, doDeletePadUser),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_PAYROLL, doFetchPayroll),

  takeLatest(ACTIONS.FETCH_PAYROLL_SOFTWARE, doFetchPayrollSoftware),

  takeLatest(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.FETCH_PAYROLL_SOFTWARE_CONFIGURATIONS,
    doFetchPayrollSoftwareConfigurations
  ),
  takeEvery(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.UPDATE_PAYROLL_SOFTWARE_CONFIGURATION,
    doUpdatePayrollSoftwareConfiguration
  ),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_PAY_PERIODS, doFetchPayPeriods),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_PAY_PERIOD, doFetchPayPeriod),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_REVENUES, doFetchRevenues),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.UPDATE_REVENUE, doUpdateRevenue),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_COLLECTIVE_AGREEMENT, doFetchCollectiveAgreement),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_COLLECTIVE_AGREEMENTS, doFetchCollectiveAgreements),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_MONTHLY_WORK_TIME, doFetchMonthlyWorkTime),

  takeLatest(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.FETCH_MONTHLY_DASHBOARD_TURNOVERS,
    doFetchMonthlyDashboardTurnovers
  ),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_PLANNABLES_LIST, doFetchPlannablesList),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_PLANNABLE_OVERLAP, doFetchPlannableOverlap),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_SUBSCRIPTION_INVOICE, doFetchSubscriptionInvoice),

  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_UNREAD_NOTIFICATIONS, doFetchUnreadNotifications),

  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_DASHBOARD_KPIS, doFetchDasbhoardKPIs),

  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.CREATE_EXPORT_FILES, doCreateExportFiles),

  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.CREATE_REPORT, doCreateReport),
  takeEvery(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.CREATE_ALL_LOCATIONS_REPORT_FILE,
    doCreateAllLocationsReportFile
  ),
  takeEvery(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.FETCH_ALL_LOCATIONS_REPORT_FILES,
    doFetchAllLocationsReportFiles
  ),
  takeEvery(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.DELETE_ALL_LOCATIONS_REPORT_FILE,
    doDeleteAllLocationsReportFile
  ),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_REPORT, doFetchReport),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_REPORT_WITH_ROWS, doFetchReportWithRows),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.UPDATE_REPORT, doUpdateReport),

  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_REPORT_FILES, doFetchReportFiles),

  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_MODULATION_PERIOD, doFetchModulationPeriod),
  // @ts-ignore migration from js(x) to ts(x)
  takeLatest(ACTIONS.FETCH_MODULATION_PERIODS, doFetchModulationPeriods),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.CREATE_MODULATION_PERIOD, doCreateModulationPeriod),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.UPDATE_MODULATION_PERIOD, doUpdateModulationPeriod),
  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.DELETE_MODULATION_PERIOD, doDeleteModulationPeriod),

  takeEvery(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION,
    doFetchModulationPeriodCountersByLocation
  ),

  takeEvery(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.FETCH_MODULATION_PERIOD_COUNTERS_BY_MEMBERSHIP,
    doFetchModulationPeriodCountersByMembership
  ),

  takeEvery(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.UPDATE_MODULATION_PERIOD_COUNTER,
    doUpdateModulationPeriodCounter
  ),

  takeLatest(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.DELETE_MODULATION_PERIOD_COUNTER_ENTRY,
    doDeleteModulationPeriodCountersEntry
  ),

  takeLatest(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.FETCH_SOCKET_AUTHORIZATION_TICKET,
    doFetchSocketAuthorizationTicket
  ),

  takeLatest(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.FETCH_MODULATION_PERIOD_COUNTER,
    doFetchModulationPeriodCounter
  ),
  takeLatest(
    // @ts-ignore migration from js(x) to ts(x)
    ACTIONS.FETCH_MODULATION_PERIOD_COUNTERS,
    doFetchModulationPeriodCounters
  ),

  // @ts-ignore migration from js(x) to ts(x)
  takeEvery(ACTIONS.FETCH_COUNTRY_MIN_WAGES, doFetchCountryMinWages),

  takeLatest(ACTIONS.CONNECT_SOCKET_SUCCEEDED, doSubscribeToDefaultChannel, {
    channel: 'NotificationChannel'
  }),
  takeLatest(FETCH_ALL_MEMBERSHIPS, doFetchAllMemberships),
  takeLatest(FETCH_ALL_SHIFTS, doFetchAllShifts),
  countryConfigurationsSaga(),
  paidLeaveConfigurationSaga()
]
