import { useMutation } from '@tanstack/react-query'

import type { UseAppMutationOptions, ApiErrorResponse } from '../../../types'
import type { PostRevokeOauthTokenOptions } from '../auth.api'
import { postRevokeOauthToken } from '../auth.api'
import { authCacheKeys } from '../auth.cacheKeys'

export const usePostRevokeOauthToken = (
  mutationOptions?: UseAppMutationOptions<
    null,
    ApiErrorResponse,
    PostRevokeOauthTokenOptions
  >
) => {
  return useMutation({
    ...mutationOptions,
    mutationKey: authCacheKeys.postOauthRevokeToken(),
    mutationFn: (opts: PostRevokeOauthTokenOptions) =>
      postRevokeOauthToken(opts),

    onSuccess: (data, variables, ...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, ...args)
      }
    }
  })
}
