import type { ReduxState } from '@spa/redux/ReduxState'
import { createSelector } from 'reselect'

import { getParams } from '../../params'

const getSignableTimesheetsPeriods = (state: ReduxState) =>
  state.plannings.entities.signableTimesheetsPeriods

const getSignableTimesheetsPeriodStatus = createSelector(
  getParams,
  getSignableTimesheetsPeriods,
  (params, signableTimesheetsPeriods) => {
    if (
      !params.teamId ||
      !signableTimesheetsPeriods ||
      !signableTimesheetsPeriods[params.teamId]
    ) {
      return undefined
    }

    return signableTimesheetsPeriods[params.teamId].period_status
  }
)
export default getSignableTimesheetsPeriodStatus
