import type {
  ComboPayrollSyncStatusAtrtributes,
  ContractEndReasons,
  ImmutableRecord,
  JobSchema,
  LineManagerType
} from '@libs/data-access/entities'
import type Immutable from 'immutable'

export const ContractTypeArray = [
  'CDI',
  'CDD',
  'interim',
  'intern',
  'seasonal',
  'apprentissage',
  'extra'
] as const

export type ContractType = (typeof ContractTypeArray)[number]

export const TemporaryContractReasonArray = [
  'employee_replacement',
  'increased_activity'
] as const

export type TemporaryContractReasonType =
  (typeof TemporaryContractReasonArray)[number]

export type WorkPermitType =
  | 'residence_permit'
  | 'resident_card'
  | 'temporary_residence_permit'
  | 'long_residence_permit'
  | 'pluriannual_residence_permit'
  | 'provisional_residence_authorization'
  | 'residence_permit_application_receipt'
  | 'protection_recognition_receipt'
  | 'nie'
  | 'passport'
  | 'work_permit'
  | 'young_graduate_temporary_permit'
  | 'short_shengen_visa'
  | 'posted_worker_for_european_service_provider_permit'
  | 'vacation_work_visa'
  | 'work_authorization_receipt'
  | 'job_search_or_entrepreneurship_permit'
  | 'algerian_student_permit'
  | 'student_permit'
  | 'posted_worker_permit'
  | 'seasonal_worker_permit'
  | 'temporary_worker_permit'
  | 'employee_permit'

export const AVAILABLE = 'available'
export const NOT_AVAILABLE = 'not_available'
export const PARTIALLY_AVAILABLE = 'partially'
export type AvailabilityOption =
  | typeof AVAILABLE
  | typeof NOT_AVAILABLE
  | typeof PARTIALLY_AVAILABLE
export type AvailabilityDetails = string
export type Availabilities = {
  is_avail_0: AvailabilityOption
  is_avail_1: AvailabilityOption
  is_avail_2: AvailabilityOption
  is_avail_3: AvailabilityOption
  is_avail_4: AvailabilityOption
  is_avail_5: AvailabilityOption
  is_avail_6: AvailabilityOption
  is_avail_details_0: AvailabilityDetails
  is_avail_details_1: AvailabilityDetails
  is_avail_details_2: AvailabilityDetails
  is_avail_details_3: AvailabilityDetails
  is_avail_details_4: AvailabilityDetails
  is_avail_details_5: AvailabilityDetails
  is_avail_details_6: AvailabilityDetails
}

type ConfigPeriod = {
  acquired_subtotal: number
  balance_subtotal: number
  paid_leave_acquisition_period_id?: number
  paid_leave_configuration_id: number
  taken_subtotal: number
  user_contract_id: number
  last_operation_id: string
}

export type PaidLeaveCounters = {
  current_period: ConfigPeriod
  previous_period: ConfigPeriod
  total_balance: number
}

type MembershipPermissions = {
  can_read: boolean
}

export type PaidCountersRecord = ImmutableRecord<PaidLeaveCounters>

export type ContractState = {
  current_page: number
  pages: Immutable.Map<any, any>
  per_page: number
  total_count: number
  data: Immutable.Map<keyof number, ContractSchema>
  meta: Immutable.Map<string, boolean>
  relations: Immutable.Map<any, any>
}

export type ContractSchema = ComboPayrollSyncStatusAtrtributes & {
  id: number
  availabilities: Availabilities
  collective_agreement_id: number
  contract_end_reason: ContractEndReasons
  contract_end_trial: string
  contract_end: string
  contract_start: string
  contract_time: number
  contract_type: ContractType
  temporary_contract_reason: TemporaryContractReasonType
  cost_per_hour: number
  created_at: string
  daily_worker: boolean
  do_not_show_in_register: boolean
  echelon_level: string
  echelon_number: number
  echelon_other: string
  has_applied_amendments: boolean
  has_minimum_wage: boolean
  rcr_balance_in_minutes: number
  rtt_policy?: {
    name: string
    id: number
  }
  hourly_brut: number
  incomplete: boolean
  account_job: JobSchema
  layoff_authorization_date: string
  layoff_authorization_request_date: string
  location_id: number
  location_ids: number[]
  membership_id: number
  membership_permissions: MembershipPermissions
  mensual_brut: number
  notifications: any
  paid_leave_counters?: Immutable.List<PaidCountersRecord>
  position: {}
  start_time: string
  team_id: number
  team_ids: Immutable.List<number>
  temp_agency_address: string
  temp_agency_city: string
  temp_agency_name: string
  temp_agency_zip: string
  transportation: string
  tutor: { id: number; firstname: string; lastname: string }
  updated_at: string
  working_days_in_week: number
  line_manager: LineManagerType

  // ----------------------------------------------------------------

  /**
   * @deprecated Use {@link MembershipSchema.firstname} instead.
   */
  firstname: string
  /**
   * @deprecated Use {@link MembershipSchema.lastname} instead.
   */
  lastname: string
  /**
   * @deprecated Use {@link MembershipSchema.birth_date} instead.
   */
  birth_date: string
  /**
   * @deprecated Use {@link MembershipSchema.birth_place} instead.
   */
  birth_place: string
  /**
   * @deprecated Use {@link MembershipSchema.social_security_number} instead.
   */
  social_security_number: string
  /**
   * @deprecated Use {@link MembershipSchema.work_permits} instead.
   */
  work_permit_expiration_date: string
  /**
   * @deprecated Use {@link MembershipSchema.street_address} instead.
   */
  street_address: string
  /**
   * @deprecated Use {@link MembershipSchema.zip} instead.
   */
  zip: string
  /**
   * @deprecated Use {@link MembershipSchema.city} instead.
   */
  city: string
}

export type ContractRecord = ImmutableRecord<ContractSchema>
export type ContractRecordMap = Immutable.Map<number, ContractRecord>
export type ContractRecordIterable = Immutable.Iterable<number, ContractRecord>

export type MembershipContractTypeOf = 'ContractSchema' | 'Amendment'
export type MembershipAmendmentDataType = Partial<ContractSchema> & {
  amended_fields: string[]
  start_date: string
  end_date: string
  user_contract_id: number
  original_contract_id: number
  type: 'Amendment'
}
export type MembershipContractTimeline = Partial<ContractSchema> &
  Pick<ContractSchema, 'id'> & {
    amendments: MembershipAmendmentDataType[]
    type: 'ContractSchema'
  }

export type SchedulesContracAmendments = {
  contract_end: string
  contract_start: string
  contract_time: number
  id: number
}

export type SchedulesContractSchema = ContractSchema & {
  amendments: SchedulesContracAmendments[]
}
