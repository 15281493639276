import type { ApiErrorResponse } from '@libs/data-access/queries'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import type { ParamsFillDocumentTemplate } from '../modules/entities'
import { resetDocumentGenerated } from '../modules/entities'
import {
  setMetaLoading,
  setMetaSuccess,
  setMetaError,
  setMetaWS
} from '../modules/meta'

export default function* fillDocumentTemplateData(
  body: ParamsFillDocumentTemplate
) {
  yield put(setMetaLoading())
  yield put(setMetaWS({ status: 'processing' }))

  try {
    yield apiRequest({
      service: 'core',
      route: 'fillDocumentTemplate',
      params: {
        document_template_id: body.document_template_id
      },
      body: {
        contract_id: body.contract_id,
        output_name: body.output_name,
        convert_pdf: body.convert_pdf,
        ...(body?.amendment_id && { amendment_id: body.amendment_id })
      }
    })

    yield put(setMetaSuccess())
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    yield put(resetDocumentGenerated())
  } catch (err) {
    yield put(setMetaWS({ status: 'failure' }))
    yield put(setMetaError(err as ApiErrorResponse))
  }
}
