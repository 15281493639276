/* eslint-disable no-var */
/* eslint-disable prefer-rest-params */
import { IS_UPSCOPE_AVAILABLE, UPSCOPE_KEY } from '@libs/utils/environments'
import { useAuthState } from '@spa/scenes/Auth/shared/services'
import type { FC } from 'react'
import { useEffect } from 'react'

export const UpscopeScript: FC = () => {
  const { auth } = useAuthState()

  useEffect(() => {
    if (IS_UPSCOPE_AVAILABLE) {
      ;(function (w, u, d) {
        var i: any = function () {
          i.c(arguments)
        }
        i.q = []
        i.c = function (args) {
          i?.q?.push?.(args)
        }
        const l = function () {
          const s = d.createElement('script')
          s.type = 'text/javascript'
          s.async = true
          s.src = `https://code.upscope.io/${UPSCOPE_KEY}.js`
          const x = d.getElementsByTagName('script')[0]
          x?.parentNode?.insertBefore(s, x)
        }
        if (typeof u !== 'function') {
          w.Upscope = i
          l()
        }
      })(window, window.Upscope, document)

      window.Upscope('init')
    }
  }, [])

  useEffect(() => {
    if (
      typeof window.Upscope === 'function' &&
      IS_UPSCOPE_AVAILABLE &&
      auth.userId
    ) {
      window.Upscope('updateConnection', {
        // Set the user ID below. If you don't have one, set to undefined.
        uniqueId: auth.userId
      })
    }
  }, [auth.userId])

  return null
}
