import Immutable from 'immutable'
import { filterActions } from 'redux-ignore'

import {
  FETCH_ACCOUNT_SUCCEEDED,
  UPDATE_ACCOUNT_SUCCEEDED
} from '../../actionTypes'
import { updateRecord } from '../../services/immutableUtils'

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: { loading: false, success: false, error: false },
  relations: Immutable.Map()
})

export const model = Immutable.Record({
  id: null,
  account_id: null,
  format: null,
  last_value: null,
  next_value: null,
  prefix: null
})

const foreignKeys = ['account_id']

function employeeIdSequence(state = initialState, { type, ...payload }) {
  switch (type) {
    case FETCH_ACCOUNT_SUCCEEDED:
    case UPDATE_ACCOUNT_SUCCEEDED: {
      const account = payload.data
      const sequence = account?.employee_id_sequence

      // If we refetch the account and the sequence is not present, it means it juste has been deleted by the backend.
      // In this case, also remove it from the redux store.
      if (!account || !sequence) {
        return initialState
      }

      const record = Immutable.fromJS(sequence)

      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      return updateRecord(state, model, record, foreignKeys).mergeDeepIn(
        ['meta'],
        { error: false, loading: false, success: true }
      )
    }
    default:
      return state
  }
}

export default filterActions(employeeIdSequence, [
  FETCH_ACCOUNT_SUCCEEDED,
  UPDATE_ACCOUNT_SUCCEEDED
])
