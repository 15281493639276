import type {
  ApiErrorResponse,
  ApiErrorResponseData
} from '@libs/data-access/queries'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

type WebSocketStatus = 'processing' | 'success' | 'failure'
export type MetaState = {
  error: boolean
  success: boolean
  loading: boolean
  rawError: ApiErrorResponseData | null
  ws?: WebSocketStatus
}

const initialState: MetaState = {
  error: false,
  success: false,
  loading: false,
  rawError: null,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  ws: null
}

const paramsSlice = createSlice({
  name: 'emp/documentTemplate/meta',
  initialState,
  reducers: {
    setMetaError(state, error: PayloadAction<ApiErrorResponse>) {
      return {
        ...state,
        error: true,
        success: false,
        loading: false,
        rawError: error.payload.error
      }
    },
    setMetaSuccess(state) {
      return {
        ...state,
        error: false,
        success: true,
        loading: false,
        rawError: null
      }
    },
    setMetaLoading(state) {
      return {
        ...state,
        error: false,
        success: false,
        loading: true,
        rawError: null
      }
    },
    setMetaWS(state, wsStatus: PayloadAction<{ status: WebSocketStatus }>) {
      return {
        ...state,
        ws: wsStatus.payload.status
      }
    }
  }
})

export const { setMetaError, setMetaSuccess, setMetaLoading, setMetaWS } =
  paramsSlice.actions
export default paramsSlice.reducer
