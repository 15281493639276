import type { DirectDebitInitFlowSchema } from '@libs/data-access/entities'
import { httpServices } from 'libs/data-access/queries/src/lib/apis'
import type { QueryFnParams } from 'libs/data-access/queries/src/lib/types'

export type CompleteAccountDirectDebitOptions = QueryFnParams<{
  payload: {
    redirect_flow_id: string
  }
}>

export const completeAccountDirectDebit = async (
  options: CompleteAccountDirectDebitOptions
) => {
  await httpServices.billing.post<void>(
    `/account-based/accounts/${httpServices.authInfo.accountId}/payment/direct-debit/complete`,
    options.payload
  )
}

export type InitiateAccountDirectDebitOptions = QueryFnParams<{
  payload: {
    redirect_url: string
  }
}>

export const initiateAccountDirectDebit = async (
  options: InitiateAccountDirectDebitOptions
) => {
  const response = await httpServices.billing.post<DirectDebitInitFlowSchema>(
    `/account-based/accounts/${httpServices.authInfo.accountId}/payment/direct-debit`,
    options.payload
  )

  return response.data
}
