import { fuzzySearch } from '@libs/ui/ds/lib/internals'
import { startingWithSearch } from '@libs/ui/ds/lib/internals/search/startingWithSearch/startingWithSearch'

import type { AutocompleteProps } from '../Autocomplete.types'

type Params = {
  options: AutocompleteProps['options']
  searchAdapter: AutocompleteProps['searchAdapter']
  searchValue: string
}

type SearchFunction = (opts: { search: string; options: string[] }) => boolean
type FilterAdapter = Record<
  NonNullable<AutocompleteProps['searchAdapter']>,
  SearchFunction
>

const selectFilterAdapters: FilterAdapter = {
  /**
   * Simple algorithm that checks if the option precisely starts with the search value
   */
  startingWith: filterOptionInput =>
    startingWithSearch({
      search: filterOptionInput.search,
      options: filterOptionInput.options
    }),
  /**
   * Use fuzzy search algorithm, and search for matching into label and group separately
   */
  fuzzyIncludingGroup: filterOptionInput =>
    fuzzySearch({
      search: filterOptionInput.search,
      options: filterOptionInput.options
    })
}

export const filterAutocompleteOption = ({
  options,
  searchAdapter,
  searchValue
}: Params) => {
  const shouldFilterOptions = options.every(i => i.label !== searchValue)

  if (!shouldFilterOptions || !searchAdapter) {
    return options
  }
  const filterAlgorithm = selectFilterAdapters[searchAdapter]

  return options.filter(i =>
    filterAlgorithm({
      search: searchValue,
      options: [
        i.group ?? '',
        i.label ?? '',
        `${i.group ?? ''} ${i.label ?? ''}`
      ]
    })
  )
}
