import { httpServices } from '../../../../apis'
import type { QueryFnParams } from '../../../../types'

export type AcccountChurnLocationBasedOptions = QueryFnParams<{
  payload: {
    cancellation_reason: string
    cancellation_sub_reason: string | null
    cancellation_reason_description: string | null
    indicative_reactivation_date: Date | null
  }
}>

export const churnAccountLocationBased = async (
  options: AcccountChurnLocationBasedOptions
) => {
  const response = await httpServices.billing.post(
    `/location-based/accounts/${httpServices.authInfo.accountId}/churn`,
    options.payload
  )

  return response.data
}
