import { useQuery } from '@tanstack/react-query'

import { queryClientOptions } from '../../../../provider'
import { fetchFlipperFeatureFlags } from '../featureFlags.api'
import { featureFlagsCacheKeys } from '../featureFlags.cacheKeys'

export const useFetchFlipperFeatureFlagsByAccount = () =>
  useQuery({
    staleTime: queryClientOptions.time.INFINITY,
    queryKey: featureFlagsCacheKeys.action.flipper.getByAccountId(),
    queryFn: () => fetchFlipperFeatureFlags()
  })
