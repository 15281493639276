import { createSafeContext } from '@libs/ui/ds/lib/internals'

import type { CheckboxProps } from '../../Checkbox.types'

import type { CheckboxGroupProps } from './CheckboxGroup.types'

export type CheckboxGroupContext = {
  onChange?: (value: string[]) => void
  value?: CheckboxGroupProps['value']
  size?: CheckboxGroupProps['size']
  name?: CheckboxGroupProps['name']
  checked?: CheckboxGroupProps['checked']
  disabled?: CheckboxGroupProps['disabled']
}

export const [CheckboxGroupProvider, useCheckboxGroupContext] =
  createSafeContext<CheckboxGroupContext>({
    context: 'CheckboxGroup'
  })

export const useMergedCheckboxProps = (
  checkboxProps: Pick<
    CheckboxProps,
    'disabled' | 'name' | 'size' | 'onChange' | 'value' | 'checked'
  >
) => {
  const groupProps = useCheckboxGroupContext({ throwErrorIfNoContext: false })
  if (!groupProps) {
    return checkboxProps
  }

  return {
    disabled: groupProps.disabled || checkboxProps.disabled,
    name: groupProps.name,
    value: groupProps.value,
    size: groupProps.size,
    checked: groupProps.checked,
    onChange: e => {
      const newValue = e.target.checked
        ? // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          groupProps.value.concat(checkboxProps.value)
        : // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          groupProps.value.filter(i => i !== checkboxProps.value)
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      groupProps.onChange(newValue)
    }
  }
}
