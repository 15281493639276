import { useMergedScreenProps } from '@libs/ui/ds/lib/theme'
import { cva } from 'class-variance-authority'
import { forwardRef } from 'react'

import { gapVariants } from '../../utils/properties/gap'

import type { SimpleGridProps } from './SimpleGrid.types'

const simpleGridCVA = cva(['box-border grid gap-0'], {
  variants: {
    gap: gapVariants,
    cols: {
      1: 'grid-cols-1',
      2: 'grid-cols-2',
      3: 'grid-cols-3',
      4: 'grid-cols-4',
      5: 'grid-cols-5',
      6: 'grid-cols-6',
      7: 'grid-cols-7',
      8: 'grid-cols-8',
      9: 'grid-cols-9',
      10: 'grid-cols-10',
      11: 'grid-cols-11',
      12: 'grid-cols-12'
    }
  }
})

export const SimpleGrid = forwardRef<HTMLDivElement, SimpleGridProps>(
  ({ cols = 3, gap = 0, className, children, screens = {}, ...other }, ref) => {
    const mergedProps = useMergedScreenProps(
      {
        cols,
        gap
      },
      screens
    )

    return (
      <div
        ref={ref}
        {...other}
        className={simpleGridCVA({
          className,
          gap: mergedProps?.gap,
          cols: mergedProps?.cols
        })}
      >
        {children}
      </div>
    )
  }
)

SimpleGrid.displayName = 'SimpleGrid'
