import { usePrevious } from '@libs/ui/ds'
import {
  FieldError,
  FieldLabel,
  FieldCheckbox,
  FormGroup
} from '@spa/components/FormFields'
import type {
  LabelPosition,
  LabelColor
} from '@spa/components/FormFields/FieldLabel/FieldLabel'
import classnames from 'classnames'
import { difference, without, toString, map } from 'lodash-es'
import type { FC, ReactNode } from 'react'
import React, { useEffect } from 'react'

import type { FieldCheckboxProps } from '../FieldCheckbox/FieldCheckbox'

import styles from './FieldMultiCheckbox.module.css'

const sizes = ['S', 'M'] as const

type Size = (typeof sizes)[number]
const defaultSize: Size = 'M'

type TCheckboxItem<T extends any = string> = Pick<
  FieldCheckboxProps,
  'disabled'
> & {
  label: ReactNode
  value: T
}

export type FieldMultiCheckboxProps<T extends any = any> = {
  disabled?: boolean
  dynamicItemsDefaultChecked?: boolean
  error?: string
  expand?: boolean
  inputName?: string
  items?: TCheckboxItem<T>[]
  keepValuesSync?: boolean
  label?: string
  labelColor?: LabelColor
  labelPosition?: LabelPosition
  size?: Size
  minSelected?: number
  onChange?(v: T[]): void
  selectAllLabel?: string
  values?: T[]
}

export const FieldMultiCheckbox: FC<FieldMultiCheckboxProps> = ({
  disabled,
  dynamicItemsDefaultChecked,
  error,
  expand,
  inputName,
  items = [],
  keepValuesSync = true,
  label,
  labelColor,
  labelPosition = 'right',
  size = defaultSize,
  minSelected = 0,
  onChange,
  selectAllLabel,
  values = []
}) => {
  const isHorizontal = ['top', 'bottom'].includes(labelPosition)
  const isVertical = ['right', 'left'].includes(labelPosition)
  const itemValues = map(items, 'value')
  const oldItemsValues = usePrevious(itemValues) || null

  /**
   * If [minSelected] props is provided, We should not
   * allow the users to select less items than the minimum rule
   */
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  const isDisabled = (val: string): boolean => {
    if (minSelected > 0 && minSelected === values.length) {
      return values.includes(val)
    }
  }

  /**
   * Format in order to return arr of values
   * This values refers checkbox attr[value]
   */
  const handleChange = (name: string, checked: boolean) => {
    const updatedValues = checked ? values.concat(name) : without(values, name)

    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    onChange(updatedValues)
  }

  /**
   * Handle change for selectAll checkbox
   */
  const handleSelectAllChange = () => {
    const allAreChecked = values.length === items.length
    const updatedValues = allAreChecked ? [] : items.map(_ => _.value)
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    onChange(updatedValues)
  }

  /**
   * Compute new dynamic items we should add to values
   */
  const getIdsToAdd = (): string[] => {
    if (!dynamicItemsDefaultChecked) {
      return []
    }

    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    const newItemHasBeenAdded = items.length > oldItemsValues?.length
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    return newItemHasBeenAdded ? difference(itemValues, oldItemsValues) : []
  }

  /**
   * In case we have dynamic items we may want to synchronized values
   * The main goal is to prevent having unexistant or outdated values
   */
  useEffect(() => {
    if (keepValuesSync && oldItemsValues) {
      const idsToRemove = difference(values, itemValues)
      const idsToAdd = getIdsToAdd()
      const newValues = without(values, ...idsToRemove).concat(idsToAdd)
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      onChange(newValues)
    }
  }, [toString(itemValues)])

  return (
    <div className={styles.Container} data-testid='field-Multicheckbox'>
      {label && <FieldLabel label={label} />}
      <div
        className={classnames(styles.ItemsContainer, {
          [styles.horizontalItemsContainer]: isHorizontal,
          [styles.verticalItemsContainer]: isVertical,
          [styles.verticalItemsContainerLeft]: labelPosition === 'left',
          [styles.horizontalItemsContainerExpand]: expand && isHorizontal,
          [styles.verticalItemsContainerExpand]: expand && isVertical
        })}
      >
        {selectAllLabel && items.length > 1 && (
          <FormGroup
            id='selectAll'
            labelPosition={labelPosition}
            // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
            itemsAlignement={isHorizontal ? 'center' : null}
            fieldLabel={
              <FieldLabel
                label={selectAllLabel}
                // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
                size={size === 'S' ? 'small' : null}
                color={labelColor}
              />
            }
            fieldForm={
              <FieldCheckbox
                size={size}
                inputName='selectAll'
                checked={values.length === items.length}
                onChange={handleSelectAllChange}
                disabled={disabled}
              />
            }
          />
        )}
        {items.map(item => (
          <div
            key={item.value}
            className={classnames({
              [styles.horizontalItem]: isHorizontal
            })}
          >
            <FormGroup
              size={size}
              id={item.value}
              // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
              itemsAlignement={isHorizontal ? 'center' : null}
              labelPosition={labelPosition}
              useUniqueKeyId
              fieldLabel={
                <FieldLabel
                  label={item.label}
                  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
                  size={size === 'S' ? 'small' : null}
                  color={labelColor}
                />
              }
              fieldForm={
                <FieldCheckbox
                  {...item}
                  inputId={item.value}
                  size={size}
                  inputName={inputName}
                  checked={values.includes(item.value)}
                  onChange={newValue => handleChange(item.value, newValue)}
                  disabled={disabled || isDisabled(item.value)}
                />
              }
            />
          </div>
        ))}
      </div>
      {error && <FieldError error={error} />}
    </div>
  )
}
