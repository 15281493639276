import type { WeeklyScheduleSchema } from '@libs/data-access/entities'
import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_WEEKLYSCHEDULES,
  FETCH_WEEKLYSCHEDULES_SUCCEEDED,
  FETCH_WEEKLYSCHEDULES_FAILED,
  FETCH_WEEKLYSCHEDULE,
  FETCH_WEEKLYSCHEDULE_SUCCEEDED,
  FETCH_WEEKLYSCHEDULE_FAILED,
  DUPLICATE_WEEKLYSCHEDULE_SUCCEEDED,
  UPDATE_WEEKLYSCHEDULE,
  UPDATE_WEEKLYSCHEDULE_SUCCEEDED,
  UPDATE_WEEKLYSCHEDULE_FAILED,
  DELETE_WEEKLYSCHEDULE,
  DELETE_WEEKLYSCHEDULE_SUCCEEDED,
  DELETE_WEEKLYSCHEDULE_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import {
  mergeRecords,
  updateRecord,
  deleteRecord
} from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchWeeklyschedules = (
  accountId: number | string,
  params?: {
    location_id?: number
    team_id?: number
    start_date?: string
    end_date?: string
    week?: string
    /**
     * when silent=true, then the loading state of the reducer is not triggered
     */
    silent?: boolean
    with_permissions?: boolean
  },
  resolve?: any,
  reject?: any
) => ({
  type: FETCH_WEEKLYSCHEDULES,
  accountId,
  params,
  resolve,
  reject
})

export const hydrateWeeklyschedules = (accountId, data) => ({
  type: FETCH_WEEKLYSCHEDULES_SUCCEEDED,
  data,
  accountId
})

export const fetchWeeklyschedule = (
  weeklyscheduleId: number,
  params?: {},
  resolve?: any,
  reject?: any
) => ({
  type: FETCH_WEEKLYSCHEDULE,
  weeklyscheduleId,
  params,
  resolve,
  reject
})

export const updateWeeklyschedule = (
  weeklyscheduleId,
  weeklyscheduleData,
  params?: {},
  resolve?: any,
  reject?: any
) => {
  return {
    type: UPDATE_WEEKLYSCHEDULE,
    weeklyscheduleId,
    weeklyscheduleData,
    params,
    resolve,
    reject
  }
}

export const deleteWeeklyschedule = (weeklyscheduleId, resolve, reject) => ({
  type: DELETE_WEEKLYSCHEDULE,
  weeklyscheduleId,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const weeklyschedulesSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_WEEKLYSCHEDULES_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_WEEKLYSCHEDULES_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/weeklyschedules?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchWeeklyschedules({
  accountId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    weeklyschedulesSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

const weeklyscheduleSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_WEEKLYSCHEDULE_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_WEEKLYSCHEDULE_FAILED,
    error,
    accountId
  }),

  fetchAPI: (weeklyscheduleId, options, params) =>
    callAPI(
      `/weeklyschedules/${weeklyscheduleId}?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchWeeklyschedule({
  weeklyscheduleId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    weeklyscheduleSagaEntity,
    weeklyscheduleId,
    params,
    undefined,
    resolve,
    reject
  )
}

const updateWeeklyscheduleSagaEntity = {
  success: (data, weeklyscheduleId) => ({
    type: UPDATE_WEEKLYSCHEDULE_SUCCEEDED,
    data,
    weeklyscheduleId
  }),
  failure: (error, weeklyscheduleId) => ({
    type: UPDATE_WEEKLYSCHEDULE_FAILED,
    error,
    weeklyscheduleId
  }),

  fetchAPI: (weeklyscheduleId, options, params) =>
    callAPI(
      `/weeklyschedules/${weeklyscheduleId}?${queryString.stringify(params)}`,
      {
        method: 'PUT',
        ...options
      }
    )
}

export function* doUpdateWeeklyschedule({
  weeklyscheduleId,
  weeklyscheduleData,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    updateWeeklyscheduleSagaEntity,
    weeklyscheduleId,
    params,
    weeklyscheduleData,
    resolve,
    reject
  )
}

const deleteWeeklyscheduleSagaEntity = {
  success: (data, weeklyscheduleId) => ({
    type: DELETE_WEEKLYSCHEDULE_SUCCEEDED,
    data,
    weeklyscheduleId
  }),
  failure: (error, weeklyscheduleId) => ({
    type: DELETE_WEEKLYSCHEDULE_FAILED,
    error,
    weeklyscheduleId
  }),

  fetchAPI: (weeklyscheduleId, options) =>
    callAPI(`/weeklyschedules/${weeklyscheduleId}`, {
      method: 'DELETE',
      ...options
    })
}

export function* doDeleteWeeklyschedule({ weeklyscheduleId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    deleteWeeklyscheduleSagaEntity,
    weeklyscheduleId,
    null,
    null,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const Weeklyschedule = Immutable.Record({
  id: null,
  week: null,
  team_id: null,
  published: null,
  published_at: null,
  publication_message: null,
  created_at: null,
  permissions: Immutable.Map()
})

const weeklyscheduleForeignKeys = ['week', 'team_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map<number, WeeklyScheduleSchema>(),
  meta: Immutable.Map<string, boolean>({ loading: false }),
  relations: Immutable.Map<any, any>()
})

function weeklyschedules(state = initialState, action: any = {}) {
  switch (action.type) {
    case FETCH_WEEKLYSCHEDULE:
    case FETCH_WEEKLYSCHEDULES: {
      return action.params?.silent === true
        ? state
        : (state as any).mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_WEEKLYSCHEDULE_FAILED:
    case FETCH_WEEKLYSCHEDULES_FAILED: {
      return (state as any).mergeDeepIn(['meta'], {
        loading: false,
        success: false
      })
    }

    case FETCH_WEEKLYSCHEDULE_SUCCEEDED: {
      const weeklyschedules = Immutable.fromJS([action.data])

      return mergeRecords(
        state,
        Weeklyschedule,
        weeklyschedules,
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        weeklyscheduleForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }

    case FETCH_WEEKLYSCHEDULES_SUCCEEDED: {
      const weeklyschedules = Immutable.fromJS(action.data)

      return mergeRecords(
        state,
        Weeklyschedule,
        weeklyschedules,
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        weeklyscheduleForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    case DUPLICATE_WEEKLYSCHEDULE_SUCCEEDED:
    case UPDATE_WEEKLYSCHEDULE_SUCCEEDED: {
      const weeklyschedule = Immutable.fromJS(action.data)
      return updateRecord(
        state,
        Weeklyschedule,
        weeklyschedule,
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        weeklyscheduleForeignKeys
      )
    }
    case DELETE_WEEKLYSCHEDULE_SUCCEEDED: {
      const { weeklyscheduleId } = action
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      return deleteRecord(state, weeklyscheduleId, weeklyscheduleForeignKeys)
    }
    default:
      return state
  }
}

export * from './sagas/duplicateWeeklyschedule'
export default filterActions(weeklyschedules, [
  FETCH_WEEKLYSCHEDULES,
  FETCH_WEEKLYSCHEDULES_FAILED,
  FETCH_WEEKLYSCHEDULES_SUCCEEDED,
  FETCH_WEEKLYSCHEDULE,
  FETCH_WEEKLYSCHEDULE_FAILED,
  FETCH_WEEKLYSCHEDULE_SUCCEEDED,
  DUPLICATE_WEEKLYSCHEDULE_SUCCEEDED,
  UPDATE_WEEKLYSCHEDULE_SUCCEEDED,
  DELETE_WEEKLYSCHEDULE_SUCCEEDED
])
