import { MARKET_APPLICATION_URL } from '@libs/utils/environments'
import { useTranslation } from 'react-i18next'

export const useMarketingWebsite = () => {
  const { i18n } = useTranslation()

  switch (i18n.language) {
    case 'fr':
      return `${MARKET_APPLICATION_URL}fr/`
    case 'es':
      return `${MARKET_APPLICATION_URL}es/`
    default:
      return MARKET_APPLICATION_URL
  }
}
