import { call } from 'redux-saga/effects'

import {
  FETCH_THEORETICAL_REST_DURATION,
  FETCH_THEORETICAL_REST_DURATION_SUCCEEDED,
  FETCH_THEORETICAL_REST_DURATION_FAILED
} from '../actionTypes'
import { call as callAPI } from '../infra/http'
import { fetchSagaEntity } from '../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchTheoreticalRestDuration = (restData, resolve, reject) => ({
  type: FETCH_THEORETICAL_REST_DURATION,
  restData,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const theoreticalRestSagaEntity = {
  success: (data, restData) => ({
    type: FETCH_THEORETICAL_REST_DURATION_SUCCEEDED,
    data,
    restData
  }),
  failure: (error, restData) => ({
    type: FETCH_THEORETICAL_REST_DURATION_FAILED,
    error,
    restData
  }),

  fetchAPI: (restData, options) =>
    callAPI('/rest_theoretical_duration', {
      method: 'POST',
      ...options
    })
}

export function* doFetchTheoreticalRestDuration({ restData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    theoreticalRestSagaEntity,
    null,
    undefined,
    restData,
    resolve,
    reject
  )
}
