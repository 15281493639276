import { normalize } from 'normalizr'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http/api'

import { Weeklyschedule } from '../../../models'
import { setEntities } from '../../../reducers/entities'
import type { PayloadActionWithoutType } from '../../types/types'

type Params = PayloadActionWithoutType<{
  weeklyscheduleId: number
}>

export function* getWeeklyscheduleById({
  payload: { weeklyscheduleId }
}: Params): any {
  if (!weeklyscheduleId) {
    return
  }

  const data = yield apiRequest({
    route: 'getWeeklyschedule',
    params: { id: weeklyscheduleId }
  })

  const normalized = normalize([data], [Weeklyschedule])

  yield put(
    setEntities({ weeklyschedules: normalized.entities.weeklyschedules })
  )
}
