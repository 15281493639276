import type { ApiErrorResponse } from '@libs/data-access/queries'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import type { ParamsUpdateDocumentTemplate } from '../modules/entities'
import { updateDocumentTemplate } from '../modules/entities'
import { setMetaLoading, setMetaSuccess, setMetaError } from '../modules/meta'

export default function* updateDocumentTemplateData(
  payload: ParamsUpdateDocumentTemplate
) {
  yield put(setMetaLoading())

  const { document_template_id, ...other } = payload

  try {
    const result = yield apiRequest({
      service: 'core',
      route: 'updateDocumentTemplate',
      params: { document_template_id },
      body: other
    })

    yield put(updateDocumentTemplate(result))
    yield put(setMetaSuccess())
  } catch (err) {
    yield put(setMetaError(err as ApiErrorResponse))
  }
}
