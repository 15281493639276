import { useQuery } from '@tanstack/react-query'
import type { UseAppQueryOptions } from 'libs/data-access/queries/src/lib/types'

import type { FetchTimeoffInfoOptions } from '../membership.timeoff.api'
import { fetchTimeoffInfo } from '../membership.timeoff.api'
import { membershipTimeoffQueryKeys } from '../membership.timeoff.cacheKeys'
import type { TimeoffInfoSchema } from '../types/membership.timeoff.schema'

export const useFetchTimeoffInfo = (
  request: FetchTimeoffInfoOptions,
  queryOptions?: UseAppQueryOptions<TimeoffInfoSchema>
) =>
  useQuery({
    ...queryOptions,
    queryKey: membershipTimeoffQueryKeys.timeoff_info({
      ...request?.pathParams
    }),
    queryFn: () => fetchTimeoffInfo(request)
  })
