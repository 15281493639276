import { cva } from 'class-variance-authority'

export const simpleCardCVA = {
  root: cva(['bg-neutral-50'], {
    variants: {
      size: {
        m: 'p-6',
        s: 'p-4',
        xs: 'p-2',
        xxs: 'p-0'
      },
      shadow: {
        true: 'shadow-lg'
      },
      fixed: {
        true: 'fixed inset-x-0 bottom-0 z-[1]'
      },
      rounded: {
        m: 'rounded-lg',
        s: 'rounded-md'
      },
      hideBorder: {
        false: 'border-1 border-solid border-neutral-200'
      }
    }
  })
}
