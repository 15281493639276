import type { CountryConfigurationSchema } from '@libs/data-access/entities'

import { httpServices } from '../../../apis'

export const fetchCountryConfiguration = async () => {
  const response = await httpServices.core.get<CountryConfigurationSchema>(
    `/accounts/${httpServices.authInfo.accountId}/country_configurations`
  )

  return response.data
}
