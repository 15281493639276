/* eslint-disable no-underscore-dangle,func-names,prefer-rest-params,prefer-spread */
import {
  IS_SEGMENT_AVAILABLE,
  SEGMENT_WRITE_KEY
} from '@libs/utils/environments'
import { useEffect } from 'react'

export const useInitSegment = () => {
  const segmentDCNDefinition = () => {
    const analytics = window.analytics || []

    if (!analytics.initialize) {
      if (!analytics.invoked) {
        analytics.invoked = true
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'screen',
          'once',
          'off',
          'on',
          'addSourceMiddleware',
          'addIntegrationMiddleware',
          'setAnonymousId',
          'addDestinationMiddleware',
          'register'
        ]
        analytics.factory = function (methodName) {
          return function () {
            if (analytics.initialized) {
              return analytics[methodName].apply(analytics, arguments)
            }

            const functionParameters = Array.prototype.slice.call(arguments)
            if (
              ['track', 'screen', 'alias', 'group', 'page', 'identify'].indexOf(
                methodName
              ) > -1
            ) {
              const canonicalSelector = document.querySelector(
                "link[rel='canonical']"
              )
              functionParameters.push({
                __t: 'bpc',
                c: canonicalSelector?.getAttribute('href') || 0,
                p: window.location.pathname,
                u: window.location.href,
                s: window.location.search,
                t: document.title,
                r: document.referrer
              })
            }
            functionParameters.unshift(methodName)
            analytics.push(functionParameters)
            return analytics
          }
        }

        for (let i = 0; i < analytics.methods.length; i++) {
          const methodName = analytics.methods[i]
          analytics[methodName] = analytics.factory(methodName)
        }
        analytics.load = function (key, i) {
          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.async = true
          script.src = `https://cdn.segment.com/analytics.js/v1/${SEGMENT_WRITE_KEY}/analytics.min.js`

          const scriptHead = document.getElementsByTagName('script')[0]
          scriptHead?.parentNode?.insertBefore(script, scriptHead)
          analytics._loadOptions = i
        }
        analytics._writeKey = SEGMENT_WRITE_KEY
        analytics.SNIPPET_VERSION = '5.2.0'

        // Load the script
        analytics.load()

        // Once the script is loaded, we cleanup the trait
        analytics.ready(function () {
          analytics?.user?.()?.traits?.({})
          analytics?.group?.()?.traits?.({})
        })

        // Set global analytics
        window.analytics = analytics
      }
    }
  }

  useEffect(() => {
    if (IS_SEGMENT_AVAILABLE) {
      segmentDCNDefinition()
    }
  }, [])
}
