import { queryClientOptions } from '@libs/data-access/queries'
import { useQuery } from '@tanstack/react-query'

import { fetchPayConnector } from '../accountPayConnector.api'
import { accountPayConnectorCacheKeys } from '../accountPayConnector.cacheKeys'

export const useGetAccountPayConnector = () => {
  return useQuery({
    queryKey: accountPayConnectorCacheKeys.getByAccountId(),
    queryFn: () => fetchPayConnector(),
    staleTime: queryClientOptions.time.INFINITY
  })
}
