import type { FormatterParams } from '../../types'
import { minuteDecimalToHours } from '../durations/minuteDecimalToHours'

/**
 * Get the gap between two dates and output the duration in hours::minutes
 *
 * Input: {
 *   start: "2020-11-03T12:00:00"
 *   end: "2020-11-03T15:30:00"
 * }
 * Output: 3h30
 *
 */
export const hourBetweenTwoDates = ({ value }: FormatterParams) => {
  const { start, end } = value
  const timeDiffMillisecond = Math.abs(
    new Date(end).getTime() - new Date(start).getTime()
  )
  const minDecimal = timeDiffMillisecond / (1000 * 60)
  return minuteDecimalToHours({ value: minDecimal })
}

export default {
  format: hourBetweenTwoDates
}
