import { useCombobox } from '@mantine/core'
import { useMergedRef } from '@mantine/hooks'
import { forwardRef, memo, useRef } from 'react'

import { Combobox } from '../../../overlays/Combobox/base/Combobox'

import type { SelectProps } from './Select.types'
import { SelectCreateOption } from './subs/SelectCreateOption'
import { SelectEmpty } from './subs/SelectEmpty'
import { SelectFooter } from './subs/SelectFooter'
import { SelectOptionList } from './subs/SelectOptionList'
import { SelectSearchInput } from './subs/SelectSearchInput'
import { filterSelectOptions } from './utils/filterSelectOptions'
import { useSelectUncontrolledValues } from './utils/useSelectValuesUncontrolled'

export const Select = memo(
  forwardRef<HTMLInputElement, SelectProps>(
    (
      {
        className,
        /**
         * InputWrap props
         */
        label,
        desc,
        errorMessage,
        error,
        helperText,
        required,
        fillWidth,
        htmlFor,
        placeholder,
        labelRightSection,
        clearable = false,

        /**
         *
         */
        options = [],
        disabled,
        loading,
        dropdownPosition = 'bottom-start',
        searchAdapter = 'startingWith',
        emptyListLabel,
        closeOnOptionClick = true,
        onChange,
        onSearchChange,
        searchValue,
        dropdownHeight = 'm',
        dropdownWidth = 'm',
        searchable = false,
        defaultValue,
        onCreate,
        value,
        'data-testid': dataTestId,
        footer,
        ...other
      },
      forwardedRef
    ) => {
      const innerRef = useRef<HTMLInputElement>(null)
      const ref = useMergedRef(forwardedRef, innerRef)
      const store = useCombobox()

      const { select, search, selectedOption } = useSelectUncontrolledValues({
        select: { onChange, defaultValue, value },
        search: { onSearchChange, searchValue },
        options
      })

      const filteredOptions = filterSelectOptions({
        options,
        searchAdapter,
        searchValue: search.searchValue
      })

      return (
        <Combobox
          {...other}
          closeOnOptionClick={closeOnOptionClick}
          store={store}
          loading={loading}
          dropdownPosition={dropdownPosition}
          dropdownHeight={dropdownHeight}
          dropdownWidth={dropdownWidth}
          onOpen={() => innerRef?.current?.focus()}
          onOptionSubmit={val => {
            if (!val) {
              return
            }

            const newOptionSelected = options.find(i => i.value === val)
            select.setSelectValue(val)

            if (newOptionSelected) {
              search.setSearchValue(newOptionSelected.label)
            }
            if (closeOnOptionClick) {
              store.closeDropdown()
            }
          }}
        >
          <Combobox.Target>
            <SelectSearchInput
              inputRef={ref}
              data-testid={dataTestId}
              /**
               * InputWrap props
               */
              label={label}
              desc={desc}
              error={error}
              labelRightSection={labelRightSection}
              errorMessage={errorMessage}
              helperText={helperText}
              required={required}
              fillWidth={fillWidth}
              htmlFor={htmlFor}
              clearable={clearable}
              className={className}
              placeholder={placeholder}
              disabled={disabled}
              /**
               * State props
               */
              store={store}
              setSearchValue={search.setSearchValue}
              resetSearchValue={search.resetSearchValue}
              resetSelectValue={select.resetSelectValue}
              value={search.searchValue}
              selectedOption={selectedOption}
              searchable={searchable}
            />
          </Combobox.Target>

          <Combobox.Dropdown>
            <SelectEmpty
              enabled={!onCreate}
              options={filteredOptions}
              emptyListLabel={emptyListLabel}
            />
            <SelectCreateOption
              searchValue={search.searchValue}
              onCreate={onCreate}
              resetSearchValue={search.resetSearchValue}
            />
            <SelectOptionList
              selectValue={[select.selectValue]}
              options={filteredOptions}
            />
            <SelectFooter footer={footer} />
          </Combobox.Dropdown>
        </Combobox>
      )
    }
  )
)

Select.displayName = 'Select'
export type { SelectProps, SelectOption } from './Select.types'

export * from './services/createSafeSelectOption'
