import { userService } from '@libs/data-access/queries'
import { useMemoSelector } from '@spa/redux/hooks'
import { getCurrentAccount } from 'snap-redux/modules/accounts/selectors'
import { useRoleRights } from 'snap-redux/modules/memberships/utils'

const useHRDashboardPermissions = () => {
  const { atLeast } = useRoleRights()
  const account = useMemoSelector(getCurrentAccount)
  const permissions = userService.membership.useFeaturePermissions()

  // HR dashboard permissions
  // If this is false, the user can't access the HR dashboard at all
  const canAccessHRDashboard =
    atLeast.owner ||
    (atLeast.director && account.directors_can_access_dashboard_rh)

  // Paid leave dashboard permissions
  // If this is false, the user can't access the paid leave dashboard at all
  const canAccessPaidLeaveCounters =
    atLeast.owner ||
    (atLeast.director &&
      account?.get('director_can_read_paid_leave_dashboard')) ||
    (atLeast.manager &&
      !atLeast.director &&
      account?.get('manager_can_read_paid_leave_dashboard'))

  // Payslip dashboard permissions
  // If this is false, the user can't access the payslip dashboard at all
  const canSeePayslips =
    (permissions?.payslips_distribution?.ever_activated && atLeast.owner) ||
    permissions?.payslips_distribution?.should_promote_feature

  // Can access /dashboard-rh, but it doesn't mean that the user can see all
  // the content of the page. For example, you can access the paid leave counters,
  // but you can't see the graphs.
  const canSeeHRDashboardPage =
    canAccessHRDashboard || canAccessPaidLeaveCounters

  // TODO: check with the back-end team how to better, this is hardy maintanable
  // See: https://snapshiftapp.atlassian.net/browse/AD-1143
  const canOnlySeePaidLeaveCounters =
    !canAccessHRDashboard && canAccessPaidLeaveCounters

  // Document signature permissions
  // If both are false, the user can't access the e-sign document dashboard at all
  const canSeeDocumentSignature = permissions?.document.can_read
  const readOnlyAccessDocumentSignature =
    permissions?.document.can_read && !permissions?.document.can_sign

  // Promotion permissions
  // It allow the display of promotion banner inside the side menu.
  const canPromoteDocumentSignature =
    !permissions?.document.can_sign &&
    !permissions?.document.can_read &&
    atLeast.owner

  const canPromotePayslip =
    permissions?.payslips_distribution?.should_promote_feature
  const canPromoteToucanGraphs =
    !permissions?.dashboard_hr?.can_read && atLeast.director

  // Document dashboard permissions
  // Display the submenu for the payslip OR/AND e-sign document in the side menu
  const canSeeDocument =
    (canSeeDocumentSignature ||
      readOnlyAccessDocumentSignature ||
      canSeePayslips) &&
    !canOnlySeePaidLeaveCounters

  // Toucan dashboard permission
  const canSeeToucanGraphs =
    permissions?.dashboard_hr?.can_read &&
    atLeast.director &&
    !canOnlySeePaidLeaveCounters

  return {
    canAccessHRDashboard,
    canAccessPaidLeaveCounters,
    canOnlySeePaidLeaveCounters,
    canSeeDocument,
    canSeeDocumentSignature,
    canSeeHRDashboardPage,
    canSeePayslips,
    canSeeToucanGraphs,
    promoteDocumentSignature: canPromoteDocumentSignature,
    promotePayslips: canPromotePayslip,
    promoteToucan: canPromoteToucanGraphs,
    readOnlyAccessDocumentSignature
  }
}

export default useHRDashboardPermissions
