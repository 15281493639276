import { scheduleService } from '@libs/data-access/queries'
import { select } from 'redux-saga/effects'
import { getCurrentAccountId } from 'snap-redux/modules/accounts/selectors'

import type { PayloadActionWithoutType } from '../../types/types'

type Params = PayloadActionWithoutType<{
  date: string
  contractIds: number[]
}>

export function* fetchPlannables({ payload: { date, contractIds } }: Params) {
  const accountId = yield select(getCurrentAccountId)

  const rows = yield scheduleService.api.fetchScheduleRows({
    queryParams: {
      account_id: accountId,
      week: date,
      user_contract_ids: contractIds
    }
  })

  return rows
}
