import { Text } from '@libs/ui/ds'
import { useDSTranslation } from '@libs/ui/ds/lib/internals'
import React from 'react'

import { Combobox } from '../../../../../overlays/Combobox/base/Combobox'
import type { MultiSelectProps } from '../../MultiSelect/MultiSelect.types'

type AsyncSelectCreateOptionProps = {
  onCreate: MultiSelectProps['onCreate']
  searchValue: string
}

export const AsyncSelectCreateOption: React.FC<
  AsyncSelectCreateOptionProps
> = ({ onCreate, searchValue }) => {
  const { t } = useDSTranslation()

  if (!onCreate || !searchValue.length) {
    return null
  }

  return (
    <Combobox.Group label={t('ds.components.select.createOptionGroup')}>
      <Combobox.Option
        value={null as any}
        onClick={() => {
          onCreate(searchValue)
        }}
      >
        {t('ds.components.select.createOptionLabel')}{' '}
        <Text as='span' className='font-medium'>
          "{searchValue}"
        </Text>
      </Combobox.Option>
    </Combobox.Group>
  )
}
