import type { ReduxState } from '@spa/redux/ReduxState'
import { useMemoSelector } from '@spa/redux/hooks'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import moment from 'moment'
import createCachedSelector from 're-reselect'

import { getDate } from '../getDate/getDate'

export type WeekDateParams = {
  date: 'YYYY-MM-DD' | string
  week: {
    start: 'YYYY-MM-DD' | string
    end: 'YYYY-MM-DD' | string
    iso: 'GGGG-[W]WW' | string
  }
}

/**
 * Return derived date helpers to be used in selectors or saga that need to compute:
 * - start/end of a week
 * - start/end of a day
 */
export const getWeekDateParams = createCachedSelector(
  (state: ReduxState) => getDate(state),
  date => {
    const week = {
      iso: date ? moment(date).endOf('isoWeek').format('GGGG-[W]WW') : null,
      start: date ? moment(date).startOf('isoWeek').format('YYYY-MM-DD') : null,
      end: date ? moment(date).endOf('isoWeek').format('YYYY-MM-DD') : null
    }

    return { date, week } as WeekDateParams
  }
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
)(state => fallbackToDefaultCacheKey(getDate(state)))

export const useGetWeekDateParams = () => {
  return useMemoSelector(getWeekDateParams)
}
