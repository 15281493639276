import { ConditionalWrap, Text } from '@libs/ui/ds'
import type { LabelPosition } from '@spa/components/FormFields/FieldLabel/FieldLabel'
import { toKebabCase } from '@spa/services/toKebabCase'
import classnames from 'classnames'
import React from 'react'
import type { ReactElement, FC } from 'react'

import styles from './FormGroup.module.css'

type Props = {
  expand?: boolean
  fieldForm: ReactElement
  fieldLabel?: ReactElement
  id: string
  itemsAlignement?: 'left' | 'center' | 'right'
  labelPosition?: LabelPosition
  fullWidth?: boolean
  noSpaceBottom?: boolean
  size?: 'S' | 'M'
  spacedHorizontally?: boolean
  fieldExplanation?: string
  alignFieldAndLabel?: boolean
}
export const FormGroup: FC<Props> = ({
  expand,
  fieldForm,
  fieldLabel,
  id,
  itemsAlignement = 'left',
  labelPosition = 'top',
  fullWidth = false,
  noSpaceBottom = false,
  size = 'M',
  spacedHorizontally = false,
  fieldExplanation = null,
  alignFieldAndLabel = false
}) => {
  const isReactFragment = variableToInspect => {
    if (variableToInspect.type) {
      return variableToInspect.type === React.Fragment
    }
    return variableToInspect === React.Fragment
  }

  return (
    <div
      data-testid={`form-group-${toKebabCase(id?.toString())}`}
      className={classnames(styles.FormGroup, {
        [styles.fullWidth]: fullWidth,
        [styles.noSpaceBottom]: noSpaceBottom,
        [styles.smallSpaceBottom]: size === 'S',
        [styles.spacedHorizontally]: spacedHorizontally
      })}
    >
      <div
        className={classnames(styles.Container, {
          [styles.expandedContainer]: expand,
          [styles.centerAlignement]: itemsAlignement === 'center',
          [styles.rowContainer]: labelPosition === 'left',
          [styles.rowReverseContainer]: labelPosition === 'right',
          [styles.colContainer]: labelPosition === 'top',
          [styles.colReverseContainer]: labelPosition === 'bottom',
          [styles.withFieldExplanation]: fieldExplanation
        })}
      >
        {fieldLabel && (
          <ConditionalWrap
            condition={alignFieldAndLabel}
            wrap={children => (
              <div className={styles.alignLabel}>{children}</div>
            )}
          >
            {React.cloneElement(
              fieldLabel as ReactElement<{
                position: LabelPosition
                htmlFor: string
              }>,
              {
                position: labelPosition,
                htmlFor: id
              }
            )}
          </ConditionalWrap>
        )}
        <ConditionalWrap
          condition={alignFieldAndLabel}
          wrap={children => <div className={styles.alignLabel}>{children}</div>}
        >
          <>
            {React.cloneElement(
              fieldForm as ReactElement<{
                inputid: string
              }>,
              isReactFragment(fieldForm)
                ? {}
                : {
                    inputid: id
                  }
            )}
            {fieldExplanation && (
              <Text as='span' size='information'>
                {fieldExplanation}
              </Text>
            )}
          </>
        </ConditionalWrap>
      </div>
    </div>
  )
}
