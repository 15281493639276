import { combineReducers } from '@reduxjs/toolkit'

import displayFiltersReducer from './displayFilters'
import entitiesReducer from './entities'
import gridSelectionReducer from './gridSelection'
import metaReducer from './meta'
import paramsReducer from './params'
import settingsReducer from './settings/settings'
import timeRangeReducer from './timeRange/timeRange'

export default combineReducers({
  displayFilters: displayFiltersReducer,
  entities: entitiesReducer,
  meta: metaReducer,
  params: paramsReducer,
  settings: settingsReducer,
  timeRange: timeRangeReducer,
  gridSelection: gridSelectionReducer
})
