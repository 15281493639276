import type { AccountSchema } from '@libs/data-access/entities'

import { httpServices } from '../../apis'
import type { QueryFnParams } from '../../types'

export type FetchCurrentAccountOptions = QueryFnParams<{
  queryParams: {}
}>

/**
 *
 */
export const fetchCurrentAccount = async (opts: FetchCurrentAccountOptions) => {
  const response = await httpServices.core.get<AccountSchema>(
    `/accounts/${httpServices.authInfo.accountId}`,
    {
      params: opts?.queryParams
    }
  )
  return response.data
}
