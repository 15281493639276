import React from 'react'
import { Trans } from 'react-i18next'

type TransAppType = typeof Trans

export const TransApp: TransAppType = ({ ...restProps }) => {
  return (
    <Trans
      {...restProps}
      components={{
        /**
         * Italic default component
         */
        i: <i />,
        /**
         * Bold default component
         */
        b: <b />,
        /**
         * Underline default component
         */
        u: <u />,
        ...restProps.components
      }}
    />
  )
}
