import { Checkbox } from '@libs/ui/ds'
import { ControllerBase } from '@libs/ui/form'
import type { RefObject } from 'react'
import type { FieldValues, FieldPath } from 'react-hook-form'

import type { ControlledCheckboxProps } from './ControlledCheckbox.types'

export const ControlledCheckbox = <
  TFieldValues extends FieldValues = FieldValues,
  TInputName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  ref,
  rules,
  onChangeSideEffect,
  ...checkboxProps
}: ControlledCheckboxProps<TFieldValues, TInputName>) => (
  <ControllerBase
    rules={rules}
    control={control}
    field={
      <Checkbox {...checkboxProps} ref={ref as RefObject<HTMLInputElement>} />
    }
    name={name}
    onChangeAdapter={e => e.target.checked}
    valueAdapter='checked'
    onChangeSideEffect={onChangeSideEffect}
  />
)
