import {
  getParams,
  shiftsSelectors
} from '@spa/scenes/Plannings/redux/selectors'
import { uniq } from 'lodash-es'
import { put, select, takeEvery } from 'redux-saga/effects'
import {
  CREATE_BULK_SHIFTS_SUCCEEDED,
  CREATE_SHIFT_SUCCEEDED,
  DELETE_BULK_SHIFTS_SUCCEEDED,
  DELETE_SHIFT_SUCCEEDED,
  INVALIDATE_BULK_SHIFTS_SUCCEEDED,
  UPDATE_SHIFT_SUCCEEDED,
  VALIDATE_BULK_SHIFTS_SUCCEEDED
} from 'snap-redux/actionTypes'

import { controllersActions } from '../controllers'
import fetchPlannablesSideEffectsEntities from '../controllers/request/fetchPlannableSideEffectsEntities'

function* sync(action: any) {
  const params = yield select(getParams)

  // @todo register module redux on planning v2 scene
  if (!params.date) {
    return
  }

  switch (action.type) {
    case UPDATE_SHIFT_SUCCEEDED: {
      const params = yield select(getParams)
      const prevShift = yield select(state =>
        shiftsSelectors.getCachedById(state, action.data.id)
      )

      yield fetchPlannablesSideEffectsEntities({
        contractIds: uniq([
          action.data.user_contract_id,
          ...(prevShift ? [prevShift.user_contract_id] : [])
        ]),
        date: params.date,
        countersParams: { live: true }
      })

      break
    }
    case DELETE_SHIFT_SUCCEEDED:
    case CREATE_SHIFT_SUCCEEDED: {
      const params = yield select(getParams)
      yield fetchPlannablesSideEffectsEntities({
        contractIds: [action.data.user_contract_id],
        date: params.date,
        countersParams: { live: true }
      })

      break
    }

    case CREATE_BULK_SHIFTS_SUCCEEDED: {
      const params = yield select(getParams)
      yield fetchPlannablesSideEffectsEntities({
        contractIds: [action.data[0].user_contract_id],
        date: params.date,
        countersParams: { live: true }
      })

      break
    }

    case INVALIDATE_BULK_SHIFTS_SUCCEEDED:
    case VALIDATE_BULK_SHIFTS_SUCCEEDED:
    case DELETE_BULK_SHIFTS_SUCCEEDED: {
      const params = yield select(getParams)

      yield put(
        controllersActions.planningSilentRefresh({
          locationId: params.locationId,
          teamId: params.team_id,
          date: params.date
        })
      )
      break
    }

    default:
      break
  }
}

export default function* shiftWatcher() {
  yield takeEvery(
    [
      UPDATE_SHIFT_SUCCEEDED,
      DELETE_SHIFT_SUCCEEDED,
      CREATE_SHIFT_SUCCEEDED,
      INVALIDATE_BULK_SHIFTS_SUCCEEDED,
      VALIDATE_BULK_SHIFTS_SUCCEEDED,
      CREATE_BULK_SHIFTS_SUCCEEDED,
      DELETE_BULK_SHIFTS_SUCCEEDED
    ],
    sync
  )
}
