import { createSafeContext } from '@libs/ui/ds/lib/internals'

import type { GridProps } from './Grid.types'

export type GridContext = {
  columns?: GridProps['columns']
  gap?: GridProps['gap']
  grow?: GridProps['grow']
}

export const [GridProvider, useGridContext] = createSafeContext<GridContext>({
  context: 'Grid'
})
