import Immutable from 'immutable'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'
import { User } from 'snap-redux/modules/users'

import {
  FETCH_REPORT_FILES,
  FETCH_REPORT_FILES_SUCCEEDED,
  FETCH_REPORT_FILES_FAILED,
  FETCH_REPORT_FILE_ROW_SUCCEEDED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'
import { sendMessageToChannel } from '../socket'

// ------------------------------------
// Actions
// ------------------------------------
export const fetchReportFiles = (reportId, resolve, reject) => ({
  type: FETCH_REPORT_FILES,
  reportId,
  resolve,
  reject
})

// ------------------------------------
// Socket Actions
// ------------------------------------

const CHANNEL_NAME = 'Report::FileChannel'

const sendMessageToReportFileChanel = (
  messageType,
  messageData,
  resolve,
  reject
) =>
  sendMessageToChannel(CHANNEL_NAME, messageType, messageData, resolve, reject)
export const downloadFile = (reportId, messageId, resolve, reject) =>
  sendMessageToReportFileChanel(
    'download',
    { report_id: reportId, message_id: messageId },
    resolve,
    reject
  )

// ------------------------------------
// Sagas
// ------------------------------------
const fetchReportFilesSagaEntity = {
  success: (data, reportId) => ({
    type: FETCH_REPORT_FILES_SUCCEEDED,
    data,
    reportId
  }),
  failure: (error, reportId) => ({
    type: FETCH_REPORT_FILES_FAILED,
    error,
    reportId
  }),

  fetchAPI: (reportId, options) =>
    callAPI(`/reports/${reportId}/report_files`, options)
}

export function* doFetchReportFiles({ reportId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    fetchReportFilesSagaEntity,
    reportId,
    null,
    undefined,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const ReportFile = Immutable.Record({
  id: null,
  generated_at: null,
  report_id: null,
  requester_id: null,
  requester: null,
  document: null,
  export_type: null
})

const reportFileForeignKeys = ['report_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map(),
  progress: Immutable.Map()
})

function reportFiles(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_REPORT_FILES: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_REPORT_FILE_ROW_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const reportFilesRows = action.payload
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['progress'], {
        [reportFilesRows.report_id]: {
          index: reportFilesRows.index,
          total: reportFilesRows.total
        }
      })
    }
    case FETCH_REPORT_FILES_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const reportFiles = Immutable.fromJS(action.data, (key, value) =>
        key === 'requester' ? User(value) : value
      )

      return (
        // @ts-ignore migration from js(x) to ts(x)
        mergeRecords(state, ReportFile, reportFiles, reportFileForeignKeys)
          .mergeDeepIn(['meta'], {
            loading: false,
            updated_at: Date.now(),
            success: true
          })
          // @ts-ignore migration from js(x) to ts(x)
          .deleteIn(['progress', action.data.report_id])
      )
    }
    case FETCH_REPORT_FILES_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    default:
      return state
  }
}

export default filterActions(reportFiles, [
  FETCH_REPORT_FILES,
  FETCH_REPORT_FILES_SUCCEEDED,
  FETCH_REPORT_FILE_ROW_SUCCEEDED
])
