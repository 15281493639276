import type { TeamRecord, TeamRecordMap } from '@libs/data-access/entities'
import type { ReduxState } from '@spa/redux/ReduxState'
import type { Iterable } from 'immutable'

import {
  getDataByForeignId,
  getDataByForeignIds
} from '../../services/immutableUtils'

export const getAllTeams = state => state.teams.get('data')

export const getIsTeamsLoading = state => state.teams.getIn(['meta', 'loading'])

export const getTeamsByLocationIds = (
  state: ReduxState,
  locationIds: Iterable.Indexed<number> | string[] | number[]
): TeamRecordMap =>
  getDataByForeignIds(state, 'teams', 'location_id', locationIds)

export const getTeamsByLocationId = (
  state: ReduxState,
  locationId: number
): TeamRecordMap =>
  getDataByForeignId(state, 'teams', 'location_id', Number(locationId)).sort(
    (firstTeam, secTeam) => firstTeam.name.localeCompare(secTeam.name)
  )

export const getTeamByIds = (
  state: ReduxState,
  teamIds: Iterable.Indexed<number>
): TeamRecord => getDataByForeignIds(state, 'teams', 'location_id', teamIds)

export const getTeamById = (state: ReduxState, teamId: number): TeamRecord =>
  state.teams.getIn(['data', teamId])
