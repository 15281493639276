import type { AccountFeatureFlagsSchema } from '@libs/data-access/entities/lib/entities/Account/FeatureFlags/schemas/AccountFeatureFlags'
import { useMemoSelector } from '@spa/redux/hooks'

import { getCurrentAccount } from '../selectors'

export const useAccountFeatureFlags =
  (): Partial<AccountFeatureFlagsSchema> => {
    const { feature_flags } = useMemoSelector(getCurrentAccount)
    return feature_flags.toJS()
  }
