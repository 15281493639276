import type { Rest, WeeklyScheduleSchema } from '@libs/data-access/entities'
import { getRestConfig } from '@spa/constants/RestTypes'
import type { ReduxState } from '@spa/redux/ReduxState'
import { useMemoSelector } from '@spa/redux/hooks'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'

import { restsSelectors, weeklyschedulesSelectors } from '../../entities'
import type { PlanningPolicies } from '../getPlanningPolicies'
import { getPlanningPolicies } from '../getPlanningPolicies'

type Params = {
  restId: number
  weeklyscheduleId: number
}

const getWeeklyscheduleById = (
  state: ReduxState,
  { weeklyscheduleId }: Params
) => weeklyschedulesSelectors.getCachedById(state, weeklyscheduleId)

const getRestById = (state: ReduxState, { restId }: Params) =>
  restsSelectors.getCachedById(state, restId)

const computeEditRestPolicies = (
  weeklyschedule: Omit<WeeklyScheduleSchema, 'permissions'>,
  rest: Rest,
  policies: PlanningPolicies
) => {
  const { canDnd } = getRestConfig(rest)

  const allowedLocked = rest.locked_at
    ? policies.canDeleteLockedRest
    : policies.canDNDRest

  const allowedPublished = weeklyschedule.published
    ? policies.canDNDRestPublished
    : policies.canDNDRest

  return {
    canEditRest: canDnd && allowedPublished && allowedLocked
  }
}

export const getEditRestPolicies = createCachedSelector(
  getWeeklyscheduleById,
  getRestById,
  getPlanningPolicies,

  computeEditRestPolicies
)((_, { restId }) => fallbackToDefaultCacheKey(restId))

export const useEditRestPolicies = (opts: Params) => {
  return useMemoSelector(state => getEditRestPolicies(state, opts))
}
