import { FieldError } from '@spa/components/FormFields'
import withController from '@spa/hoc/withController'
import { toKebabCase } from '@spa/services/toKebabCase'
import classnames from 'classnames'
import React from 'react'
import type { ChangeEvent, FC } from 'react'

import styles from './FieldSwitch.module.css'

type LegacySwitchColor = 'yellow' | 'red'

type Value = boolean

type LegacySwitchSize = 'S' | 'M' | 'L'

export type FieldSwitchProps = {
  disabled?: boolean
  error?: string
  color?: LegacySwitchColor
  inputId?: string
  inputName?: string
  checked?: boolean
  onChange?: (checked: boolean) => void
  size?: LegacySwitchSize
  register?: (Ref, validateRule?) => void
}
export const FieldSwitch: FC<FieldSwitchProps> = ({
  disabled = false,
  checked = false,
  onChange,
  error,
  inputId,
  inputName,
  size = 'M',
  color,
  register
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    onChange(e.target.checked)
  }

  return (
    <div>
      <label>
        <input
          type='checkbox'
          className={styles.SwitchInput}
          name={inputName}
          id={inputId || inputName}
          disabled={disabled}
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          onChange={onChange ? handleChange : null}
          checked={checked}
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          data-testid={`field-switch-${toKebabCase(inputId || inputName)}`}
          {...register}
        />
        <div
          className={classnames(styles.Switch, {
            [styles.DisabledSwitch]: disabled,
            [styles.LargeSwitch]: size === 'L',
            [styles.MediumSwitch]: size === 'M',
            [styles.SmallSwitch]: size === 'S',
            [styles.WarningSwitch]: color === 'yellow',
            [styles.DangerSwitch]: color === 'red'
          })}
        >
          <span
            className={classnames(styles.Plot, {
              [styles.LargePlot]: size === 'L',
              [styles.MediumPlot]: size === 'M',
              [styles.SmallPlot]: size === 'S'
            })}
          />
        </div>
      </label>
      {error && <FieldError error={error} />}
    </div>
  )
}

export const ControlledFieldSwitch = withController<FieldSwitchProps, Value>({
  valueName: 'checked'
})(FieldSwitch)
