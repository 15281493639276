const EVERYONE = {
  employee: true,
  manager: true,
  director: true,
  admin: true,
  main_owner: true
}

const MANAGER_AND_HIGHER = {
  employee: false,
  manager: true,
  director: true,
  admin: true,
  main_owner: true
}

const DIRECTOR_AND_HIGHER = {
  employee: false,
  manager: false,
  director: true,
  admin: true,
  main_owner: true
}

const ADMIN_AND_HIGHER = {
  employee: false,
  manager: false,
  director: false,
  admin: true,
  main_owner: true
}

const NOBODY = {
  employee: false,
  manager: false,
  director: false,
  admin: false,
  main_owner: false
}

const permissionsRules = {
  /**
   * Permissions:
   * Navbar display filters
   */
  canHideAlerts: DIRECTOR_AND_HIGHER,
  canHideRCRCounters: MANAGER_AND_HIGHER,
  canHideRests: EVERYONE,
  canHideAvaibilities: EVERYONE,
  canHideNonScheduled: EVERYONE,
  canHideOthersLocations: EVERYONE,
  canShowExtraOrInterimOnly: EVERYONE,
  canSeeAlerts: MANAGER_AND_HIGHER,
  canSeeDailyCountHeader: MANAGER_AND_HIGHER,

  /**
   * Permissions:
   * Planning navbar options
   */
  canAccessPlanningOptions: MANAGER_AND_HIGHER,
  canShowShiftTemplates: MANAGER_AND_HIGHER,
  canSortContracts: MANAGER_AND_HIGHER,
  canDownload: MANAGER_AND_HIGHER,
  canPrint: MANAGER_AND_HIGHER,
  canOpenAnalyse: MANAGER_AND_HIGHER,
  canChangeCounters: MANAGER_AND_HIGHER,
  canAddMembership: {
    employee: false,
    manager: (_, { membership }) => membership.manager_can_edit_employees,
    director: true,
    admin: true,
    main_owner: true
  },
  canEditNotes: MANAGER_AND_HIGHER,
  canDuplicatePlanning: MANAGER_AND_HIGHER,
  canSeeBirthdays: MANAGER_AND_HIGHER,

  /**
   * Permissions:
   * Publish related
   */
  canSeeUnpublishedPlanning: MANAGER_AND_HIGHER,
  canValidateOwnRealHours: {
    employee: false,
    manager: (_, { membership }) =>
      membership.can_manage_published_planning &&
      membership.can_validate_own_real_hours,
    director: true,
    admin: true,
    main_owner: true
  },
  canInvalidateOwnRealHours: {
    employee: false,
    manager: (_, { membership }) =>
      membership.can_manage_published_planning &&
      membership.can_validate_own_real_hours &&
      membership.can_invalidate_shift,
    director: true,
    admin: true,
    main_owner: true
  },

  /**
   * Permissions:
   * Profile access
   */
  canSeeCoworkerPlanning: {
    ...MANAGER_AND_HIGHER,
    employee: (_, { account }) => !!account?.employee_can_read_coworker_planning
  },
  canSeeEmployeeProfile: {
    employee: false,
    manager: (_, { membership }) => membership.manager_can_edit_employees,
    director: true,
    admin: true,
    main_owner: true
  },
  canSeeManagerProfile: DIRECTOR_AND_HIGHER,
  canSeeDirectorProfile: ADMIN_AND_HIGHER,
  canSeeAdminProfile: ADMIN_AND_HIGHER,

  /**
   * Permissions:
   * Shift / Rests actions
   */
  canEditOwnShift: MANAGER_AND_HIGHER,
  canEditShift: MANAGER_AND_HIGHER,
  canEditPublishedShift: {
    employee: false,
    manager: (_, { membership }) => membership.can_manage_published_planning,
    director: (_, { membership }) => membership.can_manage_published_planning,
    admin: true,
    main_owner: true
  },

  canEditRest: MANAGER_AND_HIGHER,
  canEditPublishedRest: {
    employee: false,
    manager: (_, { membership }) => membership.can_manage_published_planning,
    director: (_, { membership }) => membership.can_manage_published_planning,
    admin: true,
    main_owner: true
  },

  /**
   * Permissions:
   * Drag n Drop
   */
  canDNDNote: MANAGER_AND_HIGHER,
  canDNDFavorite: MANAGER_AND_HIGHER,
  canDropFavoriteToLockedDay: NOBODY,
  canDNDCopyFavoritePublished: {
    employee: false,
    manager: (_, { membership }) => membership.can_manage_published_planning,
    director: (_, { membership }) => membership.can_manage_published_planning,
    admin: true,
    main_owner: true
  },
  canDNDDeleteFavorite: MANAGER_AND_HIGHER,

  canDNDRest: MANAGER_AND_HIGHER,
  canDropRestToLockedDay: NOBODY,
  canDeleteLockedRest: NOBODY,
  canDNDDeleteRest: {
    employee: false,
    manager: true,
    director: true,
    admin: true,
    main_owner: true
  },
  canDNDRestPublished: {
    employee: false,
    manager: (_, { membership }) => membership.can_manage_published_planning,
    director: (_, { membership }) => membership.can_manage_published_planning,
    admin: true,
    main_owner: true
  },

  canDNDShift: MANAGER_AND_HIGHER,
  canDragExternalShift: NOBODY,
  canDropShiftToLockedDay: NOBODY,
  canDeleteLockedShift: NOBODY,
  canDNDShiftPublished: {
    employee: false,
    manager: (_, { membership }) => membership.can_manage_published_planning,
    director: (_, { membership }) => membership.can_manage_published_planning,
    admin: true,
    main_owner: true
  },

  /**
   * Grid selection
   */
  canSelectGrid: MANAGER_AND_HIGHER,

  /**
   * Counters
   */
  canSeeOwnCounters: {
    ...MANAGER_AND_HIGHER,
    employee: (_, p) => p.account?.show_real_and_planned_hours_on_planning
  },
  canSeeCounters: MANAGER_AND_HIGHER,
  canSeeDailyWorkerBadge: MANAGER_AND_HIGHER,
  canSeeRCRCounters: MANAGER_AND_HIGHER,
  canSeeMonthlyGapCounters: MANAGER_AND_HIGHER
}

export default permissionsRules
