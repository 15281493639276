import { useDispatchModal } from '@libs/ui/ds/lib/internals/contexts/globalModalContext/hooks/useDispatchModal'
import type { ReactNode } from 'react'
import { useCallback, useMemo } from 'react'

import type { ModalProps } from '../../base/Modal.types'

export type OpenConfirmModalProps = {
  title: string
  confirmLabel: string
  cancelLabel?: string
  content?: ReactNode
  intent?: 'default' | 'danger'
  size?: 's' | 'xs'
  height?: ModalProps['height']
  onConfirmAsync?: () => Promise<any>
}

export type ConfirmModalProps = OpenConfirmModalProps & {
  onConfirm: () => void
  onCancel: () => void
}

export const GLOBAL_APP_CONFIRM_MODAL = 'GLOBAL_APP_CONFIRM_MODAL'

/**
 * Expose disclosure context
 */
export function useConfirmModal() {
  const ctx = useDispatchModal<ConfirmModalProps>({
    name: GLOBAL_APP_CONFIRM_MODAL
  })

  if (!ctx) {
    throw new Error(
      'ModalDispatch.Context is used outside of ModalDispatch.Provider'
    )
  }

  /**
   * Reset data from the modal context and the search params url
   */
  const handleOpen = useCallback(
    async (data: OpenConfirmModalProps) => {
      return new Promise<{ confirmed: boolean }>(resolve => {
        ctx.open({
          ...data,
          onConfirm: async () => {
            ctx.close()
            resolve({ confirmed: true })
          },
          onCancel: () => {
            ctx.close()
            resolve({ confirmed: false })
          }
        })
      })
    },
    [ctx.open]
  )

  return useMemo(
    () => ({
      open: handleOpen,
      close: ctx.close
    }),
    [ctx.open]
  )
}
