import { useDSTranslation } from '@libs/ui/ds'
import type { FC, ForwardedRef } from 'react'

import type { ButtonProps } from '../../../../../buttons/Button/base/Button'
import { Button } from '../../../../../buttons/Button/base/Button'
import type { AutocompleteCustomTargetWrapProps } from '../AutocompleteCustomTargetWrap.types'

type AutocompleteCustomTargetSimpleButtonProps = Pick<
  AutocompleteCustomTargetWrapProps,
  'selectedOptions' | 'store' | 'disabled' | 'data-testid' | 'data-testid'
> &
  Pick<ButtonProps, 'leftIcon' | 'rightIcon'> & {
    ref?: ForwardedRef<HTMLElement>
  }

export const AutocompleteTargetButton: FC<
  AutocompleteCustomTargetSimpleButtonProps
> = ({
  selectedOptions,
  store,
  disabled,
  leftIcon,
  rightIcon,
  ref,
  ...otherProps
}) => {
  const { t } = useDSTranslation()
  const numberOfSelectedOptions = selectedOptions.length

  return (
    <Button
      {...otherProps}
      data-testid={otherProps['data-testid'] || 'custom-target-button'}
      ref={ref as ForwardedRef<HTMLButtonElement>}
      onClick={() => {
        store.openDropdown()
      }}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      intent='neutral-dark'
      variant='secondary'
      disabled={disabled}
    >
      {t('ds.components.autocomplete.simpleButton.label', {
        count: numberOfSelectedOptions
      })}
    </Button>
  )
}
