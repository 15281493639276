/* eslint-disable-next-line */
import dotenv from 'dotenv'

const processIsDefined = () => {
  return typeof process === 'object' && Boolean(Object.keys(process.env).length)
}

/**
 * Currently we use the @libs/utils/environment to expose env variables for all nx libs and apps
 *
 * Some apps use vite syntax [import.meta.env] to source envs
 * Some apps use webpack or node syntax [process.env] to source envs
 */
export const loadEnvs = () => {
  if (processIsDefined()) {
    dotenv?.config?.()
    return process.env
  }

  return (import.meta as any).env
}
