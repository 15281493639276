import { FieldInput } from '@spa/components/FormFields'
import withController from '@spa/hoc/withController'
import type { FC, FocusEvent } from 'react'
import React from 'react'

import type { FieldInputProps } from '../FieldInput/FieldInput'

type ExtendsFieldInputProps = Omit<FieldInputProps, 'type'>
type Value = number
export type FieldInputNumberProps = {
  min?: number
  max?: number
  onChange?: (newValue: number) => void | Function
  displayError?: boolean // use with design system v2 inlineInputWrap
  value?: number
} & ExtendsFieldInputProps

export const FieldInputNumber: FC<FieldInputNumberProps> = ({
  value,
  onChange,
  onBlur,
  displayError = true,
  max,
  min,
  ...other
}) => {
  const handleChange = (newValue: number) => {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    onChange(newValue)
  }

  const handleBlur = (evt: FocusEvent<HTMLInputElement>) => {
    switch (true) {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      case value < min:
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        onChange(min)
        break
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      case value > max:
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        onChange(max)
        break
      default:
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        onChange(value)
        break
    }

    if (onBlur) {
      onBlur(evt)
    }
  }

  return (
    <FieldInput
      {...other}
      max={max}
      min={min}
      type='number'
      value={value}
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      onChange={handleChange}
      valueAsNumber
      displayError={displayError}
      onBlur={handleBlur}
    />
  )
}

export const ControlledFieldInputNumber = withController<
  FieldInputNumberProps,
  Value
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
>()(FieldInputNumber)
