import { getHotkeyHandler, useWindowEvent } from '@libs/ui/ds'
import type { KeyboardEvent } from 'react'

import type { SafeHotkeysCombination } from './hotkeysList'

type SafeHandler = {
  keys: SafeHotkeysCombination
  handler: (event: KeyboardEvent<HTMLElement> | KeyboardEvent) => void
}

export const useSafeHotkeysListener = ({
  enabled = true,
  preventDefault = false,
  handlers
}: {
  enabled?: boolean
  preventDefault?: boolean
  handlers: SafeHandler[]
}) => {
  useWindowEvent('keydown', event => {
    if (!enabled) {
      return null
    }

    getHotkeyHandler(
      handlers.map(({ keys, handler }) => [
        keys,
        handler,
        {
          preventDefault
        }
      ])
    )(event)
  })
}
