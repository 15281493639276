import { useMemoSelector } from '@spa/redux/hooks'
import { createSelector } from 'reselect'

import { getCountryConfiguration } from './getCountryConfiguration'

export const getReportColumnsToHide = createSelector(
  [getCountryConfiguration],
  countryConfiguration => {
    const { worked_hours_report, rests_report } =
      countryConfiguration.report_columns_to_hide

    return {
      workedHoursReport: {
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        hideRegularWorkedColumn: worked_hours_report.includes(
          'regular_worked_hours'
        ),
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        hideHolidayWorkedColumn: worked_hours_report.includes(
          'holiday_worked_hours'
        ),
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        hideLabourDayColumn: worked_hours_report.includes(
          'labour_day_worked_hours'
        )
      },
      restsReport: {
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        hideRTTColumn: rests_report.includes('rtt'),
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        hideHolidayColumn: rests_report.includes('holiday_hours')
      }
    }
  }
)

export const useReportColumnsToHide = () => {
  return useMemoSelector(getReportColumnsToHide)
}
