import {
  PLANNING_INIT,
  PLANNING_INIT_ERROR,
  PLANNING_INIT_SUCCESS,
  PLANNING_REFRESH,
  PLANNING_REFRESH_SUCCESS,
  PLANNING_REFRESH_ERROR,
  PLANNING_RESET
} from '@spa/redux/planning/planningWeekly/actionTypes'
import Immutable from 'immutable'
import { filterActions } from 'redux-ignore'

const initialState = Immutable.fromJS({
  meta: {
    init: true,
    loading: false,
    error: false
  },
  params: {
    locationId: 0,
    teamId: 0,
    date: null
  }
})

function planningWeeklyReducer(state = initialState, { type }) {
  switch (type) {
    case PLANNING_INIT:
      return state.mergeDeepIn(
        ['meta'],
        Immutable.Map({ loading: true, init: true })
      )
    case PLANNING_INIT_SUCCESS:
      return state.mergeDeepIn(
        ['meta'],
        Immutable.Map({ init: false, loading: false })
      )
    case PLANNING_INIT_ERROR:
      return state.mergeDeepIn(
        ['meta'],
        Immutable.Map({ init: false, error: true, loading: false })
      )

    case PLANNING_REFRESH:
      return state.mergeDeepIn(['meta'], Immutable.Map({ loading: true }))
    case PLANNING_REFRESH_SUCCESS:
      return state.mergeDeepIn(['meta'], Immutable.Map({ loading: false }))
    case PLANNING_REFRESH_ERROR:
      return state.mergeDeepIn(
        ['meta'],
        Immutable.Map({ loading: false, error: true })
      )
    case PLANNING_RESET: {
      return state.mergeDeep(initialState)
    }

    default:
      return state
  }
}

export default filterActions(planningWeeklyReducer, [
  PLANNING_INIT,
  PLANNING_INIT_ERROR,
  PLANNING_INIT_SUCCESS,
  PLANNING_REFRESH,
  PLANNING_REFRESH_SUCCESS,
  PLANNING_REFRESH_ERROR,
  PLANNING_RESET
])
