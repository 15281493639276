import { errorLoggerService } from '@libs/utils/error-monitoring'
import type { PayloadAction } from '@reduxjs/toolkit'
import { put } from 'redux-saga/effects'
import { createSliceSaga, SagaType } from 'redux-toolkit-saga'
import type { GetCountryConfigurationsParams } from 'snap-redux/infra/http/routesConfig/scopes/countryConfigurations'

import { apiRequest } from '../../infra/http'

import { countryConfigurationsReducerActions } from './reducer'

const countryConfigurationsSagaSlice = createSliceSaga({
  name: 'sagas/countryConfigurations',
  caseSagas: {
    *fetchCountryConfigurations({
      payload
    }: PayloadAction<GetCountryConfigurationsParams>) {
      try {
        const countryConfigurations: any = yield apiRequest({
          route: 'getCountryConfigurationByAccount',
          params: payload
        })

        yield put(
          countryConfigurationsReducerActions.setData({
            data: countryConfigurations
          })
        )
      } catch (e) {
        errorLoggerService.error(e)
      }
    }
  },
  sagaType: SagaType.TakeLatest
})

export const { fetchCountryConfigurations } =
  countryConfigurationsSagaSlice.actions
export default countryConfigurationsSagaSlice.saga
