import { put } from 'redux-saga/effects'
import type { ShiftBreakAttribute } from 'snap-redux/infra/http/routesConfig/scopes/planning/types/Shift'
import { updateShift } from 'snap-redux/modules/shifts'

import type { PayloadActionWithoutType } from '../../types/types'

type Params = PayloadActionWithoutType<{
  shiftId: number
  starts_at: string
  ends_at: string
  notify_user?: boolean
  shift_breaks_attributes?: ShiftBreakAttribute[]
  destroy_shift_breaks_attributes?: ShiftBreakAttribute[]
  errorCallback?: (err?: any) => void
}>

const formatDestroyBreakAttribute = (
  shiftBreaks: ShiftBreakAttribute[] = []
) => {
  return shiftBreaks.map(item => ({ id: item.id, _destroy: true }))
}

function* updateShiftTimeRange({ payload }: Params): any {
  const formattedPayload = {
    ...payload,
    shift_breaks_attributes: [
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      ...payload.shift_breaks_attributes,
      ...formatDestroyBreakAttribute(payload.destroy_shift_breaks_attributes)
    ],
    id: payload.shiftId,
    notify_user: payload.notify_user || false
  }

  yield put(
    updateShift(payload.shiftId, formattedPayload, null, err => {
      if (payload.errorCallback) {
        payload.errorCallback(err)
      }
    })
  )
}

export default updateShiftTimeRange
