import queryString from 'query-string'
import { call } from 'redux-saga/effects'

import {
  FETCH_MONTHLY_WORK_TIME,
  FETCH_MONTHLY_WORK_TIME_SUCCEEDED,
  FETCH_MONTHLY_WORK_TIME_FAILED
} from '../actionTypes'
import { call as callAPI } from '../infra/http'
import {
  subscribeToChannel,
  unsubscribeFromChannel,
  sendMessageToChannel
} from '../modules/socket'
import { isConnectedToChannel } from '../modules/socket/selectors'
import { fetchSagaEntity } from '../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchMonthlyWorkTime = (accountId, params, resolve, reject) => ({
  type: FETCH_MONTHLY_WORK_TIME,
  accountId,
  params,
  resolve,
  reject
})

// ------------------------------------
// Socket Actions
// ------------------------------------

const CHANNEL_NAME = 'Planning::MonthlyWorkTimeChannel'

export const isConnectedToMonthlyWorkTimeChannel = state =>
  isConnectedToChannel(state, CHANNEL_NAME)
export const subscribeToMonthlyWorkTimeChannel = () =>
  subscribeToChannel(CHANNEL_NAME)
export const unsubscribeFromMonthlyWorkTimeChannel = () =>
  unsubscribeFromChannel(CHANNEL_NAME)
const sendMessageToMonthlyWorkTimeChanel = (
  messageType,
  messageData,
  resolve,
  reject
) =>
  sendMessageToChannel(CHANNEL_NAME, messageType, messageData, resolve, reject)
export const generateMonthlyWorkTime = (
  accountId,
  week,
  teamId,
  resolve,
  reject
) =>
  sendMessageToMonthlyWorkTimeChanel(
    'generate',
    { account_id: accountId, week, team_id: teamId },
    resolve,
    reject
  )

// ------------------------------------
// Sagas
// ------------------------------------

const monthlyWorkTimeSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_MONTHLY_WORK_TIME_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_MONTHLY_WORK_TIME_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/monthly_work_time?${queryString.stringify(
        params
      )}`,
      options
    )
}

export function* doFetchMonthlyWorkTime({
  accountId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    monthlyWorkTimeSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}
