import type { NavigateOptions, URLSearchParamsInit } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'

type DefaultSearchType = Record<string, any>

export const useSafeRouterSearchParams = <
  T extends DefaultSearchType = DefaultSearchType
>(): [
  T,
  (
    nextInit?:
      | URLSearchParamsInit
      | ((prev: URLSearchParams) => URLSearchParamsInit),
    navigateOpts?: NavigateOptions
  ) => void
] => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamsObj = Object.fromEntries(searchParams)

  return [searchParamsObj as T, setSearchParams]
}
