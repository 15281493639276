import type {
  PaginatedQueryRequest,
  PaginatedQueryResponse
} from '@libs/data-access/queries'
import { httpServices } from '@libs/data-access/queries'

import type {
  MembershipTimeoffEligibilitySchema,
  MembershipTimeoffSchema,
  TimeoffInfoSchema
} from './types/membership.timeoff.schema'

export type FetchTimeoffEligibilityForDateOptions = {
  pathParams: {
    membership_id: number
  }
  queryParams: {
    effective_date: string
  }
}

export const fetchTimeoffEligibilityForDate = async (
  opts: FetchTimeoffEligibilityForDateOptions
) => {
  const response =
    await httpServices.core.get<MembershipTimeoffEligibilitySchema>(
      `/memberships/${opts.pathParams.membership_id}/timeoff_eligibility`,
      {
        params: opts.queryParams
      }
    )

  return response.data
}

export type FetchTimeoffInfoOptions = {
  pathParams: {
    membership_id: number
    timeoff_id: number
  }
}

export const fetchTimeoffInfo = async (opts: FetchTimeoffInfoOptions) => {
  const response = await httpServices.core.get<TimeoffInfoSchema>(
    `/memberships/${opts.pathParams.membership_id}/timeoffs/${opts.pathParams.timeoff_id}`
  )

  return response.data
}

export type FetchMemberTimeoffsOptions = {
  pathParams: {
    membership_id: number
  }
  queryParams: PaginatedQueryRequest
}

export const fetchMemberTimeoffs = async (opts: FetchMemberTimeoffsOptions) => {
  const response = await httpServices.core.get<
    PaginatedQueryResponse<MembershipTimeoffSchema, 'timeoffs'>
  >(`/memberships/${opts.pathParams.membership_id}/timeoffs`, {
    params: opts?.queryParams
  })

  return response.data
}
