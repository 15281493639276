import { Loader } from '@libs/ui/ds/lib/components/feedback/Loader/base/Loader'
import type { BaseComponent } from '@libs/ui/ds/lib/internals'
import { Combobox } from '@mantine/core'
import { cva } from 'class-variance-authority'
import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'

import { simpleCardCVA } from '../../../../cards/SimpleCard/base/SimpleCard.classes'
import { useComboboxContext } from '../Combobox.context'

const comboboxDropdownCVA = {
  root: cva(
    [
      simpleCardCVA.root({
        size: 'xs',
        rounded: 'm',
        shadow: true
      })
    ],
    {
      variants: {
        dropdownWidth: {
          s: '!min-w-56 !max-w-56',
          m: '!min-w-72 !max-w-72',
          l: '!min-w-96 !max-w-96',
          xl: '!min-w-120 !max-w-120',
          target: ''
        },
        dropdownHeight: {
          s: '!max-h-52 overflow-y-auto',
          m: '!max-h-72 overflow-y-auto',
          l: '!max-h-96 overflow-y-auto',
          auto: '!h-auto'
        },
        loading: {
          true: 'overflow-hidden'
        }
      }
    }
  ),
  wrapper: cva(['flex w-full flex-col gap-0.5'])
}

export type ComboboxDropdownProps = BaseComponent &
  HTMLAttributes<HTMLDivElement> & {}

export const ComboboxDropdown = forwardRef<
  HTMLDivElement,
  ComboboxDropdownProps
>(({ className, children, ...other }, ref) => {
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  const { dropdownHeight, dropdownWidth, loading } = useComboboxContext()

  return (
    <Combobox.Dropdown
      ref={ref}
      {...other}
      className={comboboxDropdownCVA.root({
        dropdownHeight,
        dropdownWidth,
        className,
        loading
      })}
    >
      {loading && <Loader.Overlay className='fixed top-0' />}
      <Combobox.Options className={comboboxDropdownCVA.wrapper()}>
        {children}
      </Combobox.Options>
    </Combobox.Dropdown>
  )
})
