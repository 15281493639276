import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_MESSAGES,
  FETCH_MESSAGES_SUCCEEDED,
  FETCH_MESSAGES_FAILED,
  REFRESH_MESSAGES,
  REFRESH_MESSAGES_SUCCEEDED,
  REFRESH_MESSAGES_FAILED,
  CREATE_MESSAGE,
  CREATE_MESSAGE_SUCCEEDED,
  CREATE_MESSAGE_FAILED,
  UPDATE_MESSAGE,
  UPDATE_MESSAGE_SUCCEEDED,
  UPDATE_MESSAGE_FAILED,
  FETCH_CONVERSATIONS_SUCCEEDED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords, updateRecord } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchMessages = (conversationId, params, resolve, reject) => ({
  type: FETCH_MESSAGES,
  conversationId,
  params,
  resolve,
  reject
})

export const refreshMessages = (conversationId, params, resolve, reject) => ({
  type: REFRESH_MESSAGES,
  conversationId,
  params,
  resolve,
  reject
})

export const createMessage = (messageData, resolve, reject) => ({
  type: CREATE_MESSAGE,
  messageData,
  resolve,
  reject
})

export const updateMessage = (messageId, messageData, resolve, reject) => ({
  type: UPDATE_MESSAGE,
  messageId,
  messageData,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const messagesSagaEntity = {
  success: (data, conversationId) => ({
    type: FETCH_MESSAGES_SUCCEEDED,
    data,
    conversationId
  }),
  failure: (error, conversationId) => ({
    type: FETCH_MESSAGES_FAILED,
    error,
    conversationId
  }),

  fetchAPI: (conversationId, options, params) =>
    callAPI(
      `/conversations/${conversationId}/messages?${queryString.stringify(
        params
      )}`,
      options
    )
}

export function* doFetchMessages({ conversationId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    messagesSagaEntity,
    conversationId,
    params,
    undefined,
    resolve,
    reject
  )
}

const refreshMessagesSagaEntity = {
  success: (data, conversationId) => ({
    type: REFRESH_MESSAGES_SUCCEEDED,
    data,
    conversationId
  }),
  failure: (error, conversationId) => ({
    type: REFRESH_MESSAGES_FAILED,
    error,
    conversationId
  }),

  fetchAPI: (conversationId, options, params) =>
    callAPI(
      `/conversations/${conversationId}/messages?${queryString.stringify(
        params
      )}`,
      options
    )
}

export function* doRefreshMessages({
  conversationId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    refreshMessagesSagaEntity,
    conversationId,
    params,
    undefined,
    resolve,
    reject
  )
}

const createMessageSagaEntity = {
  success: data => ({ type: CREATE_MESSAGE_SUCCEEDED, data }),
  failure: error => ({ type: CREATE_MESSAGE_FAILED, error }),

  fetchAPI: (id, options) =>
    callAPI('/messages', { method: 'POST', ...options })
}

export function* doCreateMessage({ messageData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createMessageSagaEntity,
    null,
    null,
    messageData,
    resolve,
    reject
  )
}

const updateMessageSagaEntity = {
  success: (data, messageId) => ({
    type: UPDATE_MESSAGE_SUCCEEDED,
    data,
    messageId
  }),
  failure: (error, messageId) => ({
    type: UPDATE_MESSAGE_FAILED,
    error,
    messageId
  }),

  fetchAPI: (messageId, options) =>
    callAPI(`/messages/${messageId}`, { method: 'PUT', ...options })
}

export function* doUpdateMessage({ messageId, messageData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    updateMessageSagaEntity,
    messageId,
    null,
    messageData,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const Message = Immutable.Record({
  id: null,
  conversation_id: null,
  sender_id: null,
  body: null,
  attachment: null,
  sent_at: null
})

const messageForeignKeys = ['conversation_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  pagination: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function messages(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_CONVERSATIONS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const lastMessages = action.data.conversations.map(conv =>
        Immutable.Map(conv.last_message)
      )

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Message, lastMessages, messageForeignKeys)
    }
    case FETCH_MESSAGES:
    case REFRESH_MESSAGES:
    case CREATE_MESSAGE:
    case UPDATE_MESSAGE:
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    case CREATE_MESSAGE_FAILED:
    case UPDATE_MESSAGE_FAILED:
    case REFRESH_MESSAGES_FAILED:
    case FETCH_MESSAGES_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case REFRESH_MESSAGES_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const messages = Immutable.fromJS(action.data.messages)
      const oldPagination = state.get('pagination')
      const newTotalCount =
        oldPagination.get('total_count') +
        messages.filter(message => !state.get('data').has(message.get('id')))
          .size
      const newTotalPages = Math.ceil(
        // @ts-ignore migration from js(x) to ts(x)
        newTotalCount / oldPagination.get('per_page')
      )
      const newPagination = Immutable.Map({
        ...oldPagination.toJS(),
        total_count: newTotalCount,
        total_pages: newTotalPages
      })

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Message, messages, messageForeignKeys)
        .mergeDeepIn(['meta'], {
          loading: false,
          updated_at: Date.now(),
          success: true
        })
        .mergeDeepIn(['pagination'], newPagination)
    }
    case FETCH_MESSAGES_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const messages = Immutable.fromJS(action.data.messages)
      // @ts-ignore migration from js(x) to ts(x)
      const pagination = Immutable.fromJS(action.data.meta)
      const currentPage = pagination.get('current_page')
      const newUpdatedAt =
        currentPage === 1 ? Date.now() : state.getIn(['meta', 'updated_at'])

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Message, messages, messageForeignKeys)
        .mergeDeepIn(['meta'], {
          loading: false,
          updated_at: newUpdatedAt,
          success: true
        })
        .mergeDeepIn(['pagination'], pagination)
    }
    case UPDATE_MESSAGE_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const message = Immutable.fromJS(action.data)

      return updateRecord(
        state,
        Message,
        message,
        // @ts-ignore migration from js(x) to ts(x)
        messageForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        success: true
      })
    }
    case CREATE_MESSAGE_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const message = Immutable.fromJS(action.data)
      const newTotalCount = state.getIn(['pagination', 'total_count']) + 1

      // @ts-ignore migration from js(x) to ts(x)
      return updateRecord(state, Message, message, messageForeignKeys)
        .mergeDeepIn(['meta'], {
          loading: false,
          success: true
        })
        .mergeDeepIn(['pagination', 'total_count'], newTotalCount)
    }
    default:
      return state
  }
}

export default filterActions(messages, [
  FETCH_MESSAGES,
  FETCH_MESSAGES_SUCCEEDED,
  FETCH_MESSAGES_FAILED,
  REFRESH_MESSAGES,
  REFRESH_MESSAGES_SUCCEEDED,
  REFRESH_MESSAGES_FAILED,
  CREATE_MESSAGE,
  CREATE_MESSAGE_SUCCEEDED,
  CREATE_MESSAGE_FAILED,
  UPDATE_MESSAGE,
  UPDATE_MESSAGE_SUCCEEDED,
  UPDATE_MESSAGE_FAILED,
  FETCH_CONVERSATIONS_SUCCEEDED
])
