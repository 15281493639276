import type { AccountDetails, AccountStatus } from '@libs/data-access/entities'
import type { MembershipFeaturesPermissionsSchema } from '@libs/data-access/entities/lib/entities/User/Membership/MembershipFeaturesPermissions/MembershipFeaturesPermissions'
import { billingService, userService } from '@libs/data-access/queries'
import { isImpersonationContext } from '@spa/scenes/Auth/shared/services/authState/authServiceUtils'

export const evaluateCanAccessApp = (
  data?: AccountStatus,
  permissions?: MembershipFeaturesPermissionsSchema,
  billingDetails?: AccountDetails
) => {
  /**
   * If data is undefined, we can assume that the user can access the app.
   */
  if (!data || !permissions || !billingDetails) {
    return true
  }

  const { actions, activated_at, is_cancelled } = data

  /**
   * If the user is impersonating another user, we can assume that the user can access the app.
   */
  if (isImpersonationContext()) {
    return true
  }

  /**
   * If the account is suspended due to unpaid invoice on days+N, the user cannot access the app.
   */
  if (actions.suspend_due_to_unpaid) {
    return false
  }

  /**
   * If you are a cancelled account you are able to consult your old data
   * The account needs to get the permissions and to be a location based account
   */
  if (billingDetails.is_location_based && is_cancelled) {
    return true
  }

  /**
   * If the account trial has expired and the account is not activated, the user cannot access the app.
   */
  if (!activated_at && actions.warn_trial_has_expired) {
    return false
  }

  return true
}

export const useCanAccessApp = () => {
  const { data, isError, isLoading, isSuccess } =
    billingService.account.useGetStatus()

  const { data: billingDetails } = billingService.account.useGetDetails()

  const permissions = userService.membership.useFeaturePermissions()

  return {
    isLoading,
    isSuccess,
    isError,
    canAccessApp: evaluateCanAccessApp(data, permissions, billingDetails)
  }
}
