import { generatePath } from 'react-router-dom'

import type { AppRoutesName } from './appRoutesConfig.types'

type AppRoutesConfigTypes = {
  [key in AppRoutesName]: {
    path: string
    rootPath: string
    format?: any
  }
}

export const routes: AppRoutesConfigTypes = {
  /**
   * Auth routes for unauthenticated users
   */
  authWildcard: {
    path: '/users/*',
    rootPath: '/users/*'
  },
  signIn: {
    path: '/users/sign_in',
    rootPath: '/users/sign_in'
  },
  signUp: {
    path: '/users/sign_up',
    rootPath: '/users/sign_up'
  },
  signUpCompany: {
    path: '/users/sign_up_company',
    rootPath: '/users/sign_up_company'
  },
  signOut: {
    path: '/users/sign_out',
    rootPath: '/users/sign_out'
  },
  forgottenPassword: {
    path: '/users/password/forgotten',
    rootPath: '/users/password/forgotten'
  },
  editPassword: {
    path: '/users/password/edit',
    rootPath: '/users/password/edit'
  },
  signUpInvitation: {
    path: '/users/invitation/accept',
    rootPath: '/users/invitation/accept'
  },
  pendingInvitation: {
    path: '/users/invitation/awaiting',
    rootPath: '/users/invitation/awaiting'
  },
  notSentInvitation: {
    path: '/users/invitation/not_sent',
    rootPath: '/users/invitation/not_sent'
  },
  userConfirmation: {
    path: '/users/confirmation',
    rootPath: '/users/confirmation'
  },

  /**
   * dashboard - root
   */
  welcome: {
    path: '/',
    rootPath: '/'
  },
  home: {
    path: '/home',
    rootPath: '/home'
  },

  /**
   * plannings
   */
  plannings: {
    path: '/plannings/:view',
    rootPath: '/plannings/:view'
  },
  planningWeekly: {
    path: 'week',
    rootPath: '/plannings/week'
  },
  planningDay: {
    path: 'day',
    rootPath: '/plannings/day'
  },
  planningDayPrint: {
    path: 'day-print',
    rootPath: '/plannings/day-print'
  },
  planningLabels: {
    path: 'labels',
    rootPath: '/plannings/labels'
  },
  planningBulkAction: {
    path: 'bulk-actions',
    rootPath: '/plannings/bulk-actions'
  },

  /**
   * member_v2 tabs navigation
   */
  membersTabDocuments: {
    path: 'documents',
    rootPath: '/members/:membership_id/documents'
  },
  membersTabAccess: {
    path: 'access',
    rootPath: '/members/:membership_id/access'
  },
  membersTabWorktime: {
    path: 'worktime',
    rootPath: '/members/:membership_id/worktime'
  },
  membersTabContracts: {
    path: 'contracts',
    rootPath: '/members/:membership_id/contracts'
  },
  membersTabTimeoffs: {
    path: 'contracts',
    rootPath: '/members/:membership_id/timeoffs'
  },

  /**
   * members
   */
  members: {
    path: '/members',
    rootPath: '/members'
  },
  member: {
    path: '/members/:membership_id/:view',
    rootPath: '/members/:membership_id/:view'
  },
  memberSubview: {
    path: '/members/:membership_id/:view/:subview',
    rootPath: '/members/:membership_id/:view/:subview'
  },
  memberTimesheet: {
    path: '/members/:membership_id/timesheet',
    rootPath: '/members/:membership_id/timesheet'
  },
  memberPreferences: {
    path: '/members/:membership_id/settings',
    rootPath: '/members/:membership_id/settings',
    format(membershipId: string | number) {
      return generatePath(this.rootPath, {
        membership_id: String(membershipId)
      })
    }
  },
  memberCounterModulation: {
    path: '/members/:membership_id/modulation_entries/:counterModulationId',
    rootPath: '/members/:membership_id/modulation_entries/:counterModulationId'
  },
  memberPaidLeaveCounter: {
    path: '/members/:membership_id/paid-leave-counters/:counterId',
    rootPath: '/members/:membership_id/paid-leave-counters/:counterId'
  },

  /**
   * conversations
   */
  conversations: {
    path: '/conversations',
    rootPath: '/conversations'
  },
  conversation: {
    path: ':id',
    rootPath: '/conversations/:id'
  },

  /**
   * articles
   */
  articles: {
    path: '/articles',
    rootPath: '/articles'
  },
  article: {
    path: '/articles/:id',
    rootPath: '/articles/:id'
  },
  articleCreate: {
    path: '/articles/new',
    rootPath: '/articles/new'
  },
  articleUpdate: {
    path: '/articles/:id/edit',
    rootPath: '/articles/:id/edit'
  },

  /**
   * timeoffs
   */
  timeoffs: {
    path: '/timeoffs',
    rootPath: '/timeoffs'
  },
  timeoffsPending: {
    path: '/timeoffs',
    rootPath: '/timeoffs/pending'
  },
  timeoffsTreated: {
    path: '/timeoffs/treated',
    rootPath: '/timeoffs/treated'
  },
  timeoffsExpired: {
    path: '/timeoffs/expired',
    rootPath: '/timeoffs/expired'
  },
  timeoffsCalendar: {
    path: '/timeoffs/calendar',
    rootPath: '/timeoffs/calendar'
  },

  /**
   * subscription
   */
  subscription: {
    path: '/subscription',
    rootPath: '/subscription'
  },

  /**
   * [LOCATION-BASED]
   * subscriptions
   */
  subscriptions: {
    path: '/subscriptions',
    rootPath: '/subscriptions'
  },
  subscriptionsDetails: {
    path: '/subscriptions/:location_group_id',
    rootPath: '/subscriptions/:location_group_id'
  },

  /**
   * integrations
   */
  integrations: {
    path: '/integrations',
    rootPath: '/integrations'
  },
  tillerInfo: {
    path: '/integrations/tiller',
    rootPath: '/integrations/tiller'
  },
  zeltyInfo: {
    path: '/integrations/zelty',
    rootPath: '/integrations/zelty'
  },
  cashpadInfo: {
    path: '/integrations/cashpad',
    rootPath: '/integrations/cashpad'
  },
  innovorderInfo: {
    path: '/integrations/innovorder',
    rootPath: '/integrations/innovorder'
  },
  lightspeedInfo: {
    path: '/integrations/lightspeed',
    rootPath: '/integrations/lightspeed'
  },
  menlogInfo: {
    path: '/integrations/menlog',
    rootPath: '/integrations/menlog'
  },
  ladditionInfo: {
    path: '/integrations/laddition',
    rootPath: '/integrations/laddition'
  },
  carrePOSInfo: {
    path: '/integrations/carre-pos',
    rootPath: '/integrations/carre-pos'
  },
  iziPassInfo: {
    path: '/integrations/izi-pass',
    rootPath: '/integrations/izi-pass'
  },
  pielectronicsInfo: {
    path: '/integrations/pi_electronics',
    rootPath: '/integrations/pi_electronics'
  },
  adpInfo: {
    path: '/integrations/adp',
    rootPath: '/integrations/adp'
  },
  revoXEF: {
    path: '/integrations/revo_xef',
    rootPath: '/integrations/revo_xef'
  },

  /**
   * reports
   */
  reports: {
    path: '/reports',
    rootPath: '/reports'
  },
  reportsPayrolls: {
    path: 'payrolls',
    rootPath: '/reports/payrolls'
  },
  reportsPayrollsList: {
    path: '',
    rootPath: '/reports/payrolls'
  },
  reportsPayrollContainer: {
    path: '/reports/payrolls',
    rootPath: '/reports/payrolls'
  },
  reportsPayroll: {
    path: ':id',
    rootPath: '/reports/payrolls/:id',
    format(id) {
      return this.path.replace(':id', id)
    }
  },
  reportsPayrollFiles: {
    path: ':id/files',
    rootPath: '/reports/payrolls/:id/files',
    format(id) {
      return this.path.replace(':id', id)
    }
  },
  reportsActivities: {
    path: 'activities',
    rootPath: '/reports/activities'
  },
  reportsTimeclock: {
    path: 'timeclock',
    rootPath: '/reports/timeclock'
  },
  reportsContracts: {
    path: 'contracts',
    rootPath: '/reports/contracts'
  },

  /**
   * dashboard-rh
   */
  dashboardRh: {
    path: '/dashboard-rh',
    rootPath: '/dashboard-rh'
  },
  dashboardRhHome: {
    path: 'home',
    rootPath: '/dashboard-rh/home'
  },
  dashboardRhEntries: {
    path: 'entries',
    rootPath: '/dashboard-rh/entries'
  },
  dashboardRhTrialend: {
    path: 'trial-end',
    rootPath: '/dashboard-rh/trial-end'
  },
  dashboardRhAbsenceLog: {
    path: 'absence-log',
    rootPath: '/dashboard-rh/absence-log'
  },
  dashboardRhIncompleteProfiles: {
    path: 'incomplete-profiles',
    rootPath: '/dashboard-rh/incomplete-profiles'
  },
  dashboardRhClockInOutFollowUp: {
    path: 'clock-in-out-follow-up',
    rootPath: '/dashboard-rh/clock-in-out-follow-up'
  },
  dashboardRhContractsChanges: {
    path: 'contracts-changes',
    rootPath: '/dashboard-rh/contracts-changes'
  },
  dashboardRhExits: {
    path: 'exits',
    rootPath: '/dashboard-rh/exits'
  },
  dashboardRhPaidLeaves: {
    path: 'paid-leaves',
    rootPath: '/dashboard-rh/paid-leaves'
  },
  dashboardRhDocumentSignature: {
    path: 'document-signing',
    rootPath: '/dashboard-rh/document-signing'
  },
  payslips: {
    path: 'payslips',
    rootPath: '/dashboard-rh/payslips'
  },
  payslipsMarketing: {
    path: 'marketing',
    rootPath: '/dashboard-rh/payslips/marketing'
  },
  payslipsHistory: {
    path: 'history',
    rootPath: '/dashboard-rh/payslips/history'
  },
  payslipsDistributionFlow: {
    path: 'distribution/:import_id',
    rootPath: '/dashboard-rh/payslips/distribution/:import_id'
  },
  dashboardRhWorkPermitPermit: {
    path: 'residence-permit',
    rootPath: '/dashboard-rh/residence-permit'
  },
  dashboardRhToucan: {
    path: '/dashboard-rh/analytics/:view',
    rootPath: '/dashboard-rh/analytics/:view'
  },
  dashboardRhSummary: {
    path: 'summary',
    rootPath: '/dashboard-rh/analytics/summary'
  },
  dashboardRhWorkforce: {
    path: 'workforce',
    rootPath: '/dashboard-rh/analytics/workforce'
  },
  dashboardRhWorkedHours: {
    path: 'worked-hours',
    rootPath: '/dashboard-rh/analytics/worked-hours'
  },
  dashboardRhAbsence: {
    path: 'absence',
    rootPath: '/dashboard-rh/analytics/absence'
  },

  /**
   * settings
   */
  settings: {
    path: '/settings',
    rootPath: '/settings'
  },
  settingsLocations: {
    path: 'locations',
    rootPath: '/settings/locations'
  },
  settingsLocationsNew: {
    path: 'locations/new',
    rootPath: '/settings/locations/new'
  },
  settingsLocation: {
    path: 'locations/:id',
    rootPath: '/settings/locations/:id'
  },
  modulationLocationContract: {
    path: 'location/:location_id/modulation/:modulation_location_contract_id',
    rootPath:
      '/location/:location_id/modulation/:modulation_location_contract_id'
  },
  settingsContact: {
    path: 'contact',
    rootPath: '/settings/contact'
  },
  settingsCollectiveAgreement: {
    path: 'collective-agreement',
    rootPath: '/settings/collective-agreement'
  },
  settingsPrint: {
    path: 'print',
    rootPath: '/settings/print'
  },
  settingsPreferences: {
    path: 'preferences',
    rootPath: '/settings/preferences'
  },
  settingsNotification: {
    path: 'notification',
    rootPath: '/settings/notification'
  },
  settingsWageRatio: {
    path: 'wage-ratio',
    rootPath: '/settings/wage-ratio'
  },
  settingsProductivity: {
    path: 'productivity',
    rootPath: '/settings/productivity'
  },
  settingsPayPreferences: {
    path: 'pay-preferences',
    rootPath: '/settings/pay-preferences'
  },
  settingsTimeoffPolicies: {
    path: 'timeoff-policies',
    rootPath: '/settings/timeoff-policies'
  },
  settingsTimeoffPolicyNew: {
    path: 'timeoff-policies/new',
    rootPath: '/settings/timeoff-policies/new'
  },
  settingsTimeoffPolicy: {
    path: 'timeoff-policies/:id',
    rootPath: '/settings/timeoff-policies/:id'
  },
  settingsCashRegister: {
    path: 'cash-register',
    rootPath: '/settings/cash-register'
  },
  settingsMarketplace: {
    path: 'marketplace',
    rootPath: '/settings/marketplace'
  },
  settingsPayrollSoftware: {
    path: 'payroll-software',
    rootPath: '/settings/payroll-software'
  },
  settingsADP: {
    path: 'adp',
    rootPath: '/settings/adp'
  },
  settingsSilaeHrSynchronization: {
    path: 'silae-hr-synchro',
    rootPath: '/settings/marketplace/silae-hr-synchro'
  },
  settingsSilaeHrSynchronizationTable: {
    path: 'table',
    rootPath: '/settings/marketplace/silae-hr-synchro/table'
  },
  settingsSilaeHrSynchronizationConfiguration: {
    path: 'configuration',
    rootPath: '/settings/marketplace/silae-hr-synchro/configuration'
  },
  settingsSilaeHrSynchronizationPromotion: {
    path: 'promotion',
    rootPath: '/settings/marketplace/silae-hr-synchro/promotion'
  },
  settingsComboPayHrSynchronization: {
    path: 'combo-pay-hr-synchro',
    rootPath: '/settings/marketplace/combo-pay-hr-synchro'
  },
  settingsComboPayHrSynchronizationTable: {
    path: 'table',
    rootPath: '/settings/marketplace/combo-pay-hr-synchro/table'
  },
  settingsComboPayHrSynchronizationConfiguration: {
    path: 'configuration',
    rootPath: '/settings/marketplace/combo-pay-hr-synchro/configuration'
  },
  settingsTemplateDocuments: {
    path: 'template-documents',
    rootPath: '/settings/template-documents'
  },
  settingsJobTitle: {
    path: 'job-title',
    rootPath: '/settings/job-title'
  },
  settingsSubscription: {
    path: 'subscription',
    rootPath: '/settings/subscription'
  },
  settingsLegalRgpd: {
    path: 'legal/rgpd',
    rootPath: '/settings/legal/rgpd'
  },

  /**
   * Settings for Location with Billing
   */
  settingsLocationBilling: {
    path: 'settings/locations/:locationId/billing',
    rootPath: '/settings/locations/:locationId/billing'
  },

  /**
   * Timeclock settings
   */
  settingsTimeclock: {
    path: 'timeclock',
    rootPath: '/settings/timeclock'
  },
  timeclockConfiguration: {
    path: 'configuration',
    rootPath: '/settings/timeclock/configuration'
  },
  timeclockId: {
    path: 'id',
    rootPath: '/settings/timeclock/id'
  },
  timeclockEstablishment: {
    path: 'establishment',
    rootPath: '/settings/timeclock/establishment'
  },

  /**
   * Electronic Signature Page
   */
  electronicSignature: {
    path: '/electronic-signature',
    rootPath: '/electronic-signature'
  }
} as const
