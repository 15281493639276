import { history } from '@spa/redux/store'

export const trackHistoryChange = () => {
  history.listen(location => {
    const { ga } = window as any
    if (ga && typeof location === 'object') {
      ga('set', 'page', location.pathname + location.search)
      ga('send', 'pageview')
    }
  })
}
