import { IS_DEV_ENV } from '@libs/utils/environments'
import { errorLoggerService } from '@libs/utils/error-monitoring'
import queryString from 'query-string'
import ReconnectingWebSocket from 'reconnecting-websocket'
import uuidv5 from 'uuid/v5'

import {
  SOCKET_ACTION_PATTERN,
  SOCKET_OPEN_CONNECTION,
  SUBSCRIBE_TO_CHANNEL,
  SUBSCRIBE_TO_DEFAULT_CHANNEL,
  SUBSCRIBE_TO_CHANNEL_SUCCEEDED,
  SUBSCRIBE_TO_CHANNEL_FAILED,
  UNSUBSCRIBE_FROM_CHANNEL,
  SEND_MESSAGE_TO_CHANNEL
} from '../actionTypes'
import { actionSucceeded, actionFailed } from '../actions/utils'
import {
  SOCKET_REJECT_SUBSCRIPTION,
  SOCKET_CONFIRM_SUBSCRIPTION,
  SOCKET_URL
} from '../constants'
import {
  connectSocket,
  connectSocketSucceeded,
  disconnectSocket,
  subscribeToChannel as subscribeToChannelAction
} from '../modules/socket'

const SOCKET_NAMESPACE = 'f6393796-0961-468e-a54f-6bdb71626fdd'

// Define the socket once.
let socket

// Queue of Promises resolve or reject functions. This allows us to behave like a regular promises
// in the rest of the App.
const promiseQueue: Map<
  string,
  { resolve(value?): void; reject(value?): void }
> = new Map()

// Socket opening. For now, we dispatch an update to the redux store.
const onOpen = (ws, store) => () => {
  store.dispatch(connectSocketSucceeded())

  // Reconnect to channels if we were disconnected.
  const channels = store.getState().socket.get('connectedChannels')
  channels.forEach(channel => store.dispatch(subscribeToChannelAction(channel)))
}

// Socket closing. Dispatch an update to the redux store and clear the promise queue.
const onClose = (_: any, store) => () => {
  store.dispatch(disconnectSocket())
  promiseQueue.clear()
}

// Receiving a message from the socket. For now, explicitly handle messages with destination
// 'redux'. For those, dispatch the resulting action to the redux store.
// The destination key is an identifier added by the Snapshift server and not part of the WebSocket
// protocol nor the ActionCable protocol.
const onMessage = (_: any, store) => evt => {
  const data = JSON.parse(evt.data)

  // TODO: See if this can be refactored into something resembling a saga entity.
  // TODO: Use the Promises queue to resolve or reject the channel subscription action.
  if (data.type) {
    const { type, identifier } = data

    if (type === SOCKET_CONFIRM_SUBSCRIPTION) {
      const { channel } = JSON.parse(identifier)
      const action = { type: SUBSCRIBE_TO_CHANNEL_SUCCEEDED, channel }

      // Notify the rest of the store of the channel subscription success.
      return store.dispatch(action)
    }

    if (type === SOCKET_REJECT_SUBSCRIPTION) {
      const { channel } = JSON.parse(identifier)
      const action = { type: SUBSCRIBE_TO_CHANNEL_FAILED, channel }

      // Notify the rest of the store of the channel subscription failure.
      return store.dispatch(action)
    }
  }

  if (data.message) {
    const { destination, message_id: messageId, ...action } = data.message
    if (destination === 'redux') {
      if (messageId) {
        const { resolve, reject } = promiseQueue.get(messageId) || {}
        if (actionSucceeded(action) && resolve) {
          resolve(action.data)
        } else if (actionFailed(action) && reject) {
          reject(action.data)
        }
        promiseQueue.delete(messageId)
      }

      // Forward the redux action to redux store.
      return store.dispatch({ type: action.type, payload: action.data })
    }
  }
}

const onError = () => evt => {
  errorLoggerService.socketError(JSON.stringify(evt))
}

const openSocket = (action, store) => {
  // Close the socket and clear the queue before re-opening it.
  if (socket) {
    promiseQueue.clear()
    socket.close()
  }

  const socketConnectionParams = { ticket: action.ticket }
  socket = new ReconnectingWebSocket(
    `${SOCKET_URL}?${queryString.stringify(socketConnectionParams)}`
  )

  // Use custom event listener that curry the socket and the store. Having the store allows us to
  // dispatch from these listeners.
  // TODO: See how useful it is to keep the socket in there, since we can access it globally in this
  // file.
  socket.onmessage = onMessage(socket, store)
  socket.onclose = onClose(socket, store)
  socket.onopen = onOpen(socket, store)
  socket.onerror = onError()

  // Tell the rest of the store that we are connecting to the socket.
  store.dispatch(connectSocket())
}

const subscribeToChannel = ({ channel, type: _, ...params }) => {
  // To prevent also using the type of the redux action in the message identifier, we destructure it
  // from the argument **but** we do not use it elsewhere.

  // Subscribe to channel.
  const message = JSON.stringify({
    command: 'subscribe',
    identifier: JSON.stringify({ channel, ...params })
  })

  socket.send(message)
}

const unsubscribeFromChannel = ({ channel, type: _, ...params }) => {
  // To prevent also using the type of the redux action in the message identifier, we destructure it
  // from the argument **but** we do not use it elsewhere.

  // Unsubscribe from channel.
  const message = JSON.stringify({
    command: 'unsubscribe',
    identifier: JSON.stringify({ channel, ...params })
  })

  try {
    socket.send(message)
  } catch (e) {
    if (IS_DEV_ENV && e.name !== 'InvalidStateError') {
      throw e
    }
  }
}

const sendMessageToChannel = ({ channel, action, data, resolve, reject }) => {
  // Generate a message ID that is reproducible or use the one in the data.
  const messageIdData = { channel, ...action, ...data }
  const messageId = data.message_id
    ? data.message_id
    : uuidv5(queryString.stringify(messageIdData), SOCKET_NAMESPACE)

  const { identifierParams } = data
  const identifier = JSON.stringify({ channel, ...(identifierParams || {}) })

  // Send message to channel.
  const message = JSON.stringify({
    command: 'message',
    identifier,
    data: JSON.stringify({ action, message_id: messageId, ...data })
  })

  // Add the current message to the promise queue.
  promiseQueue.set(messageId, { resolve, reject })

  // Send the message to the socket.
  socket.send(message)
}

export default store => next => action => {
  // Directly skip actions that are not related to the socket.
  if (!SOCKET_ACTION_PATTERN.exec(action.type)) {
    return next(action)
  }

  switch (action.type) {
    case SOCKET_OPEN_CONNECTION:
      openSocket(action, store)
      return next(action)

    case SUBSCRIBE_TO_CHANNEL:
    case SUBSCRIBE_TO_DEFAULT_CHANNEL:
      subscribeToChannel(action)
      return next(action)

    case UNSUBSCRIBE_FROM_CHANNEL:
      unsubscribeFromChannel(action)
      return next(action)

    case SEND_MESSAGE_TO_CHANNEL:
      sendMessageToChannel(action)
      return next(action)

    default:
      return next(action)
  }
}
