import type { WheelEvent } from 'react'

export const onWheelPreventChange = (e: WheelEvent) => {
  const target = e.target as HTMLInputElement

  if (target && target.type === 'number') {
    // Prevent the input value change
    target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()
    // Also prevent default scrolling behavior
    e.preventDefault()
  }
}
