export const hotKeysList = Object.freeze({
  /**
   * mods
   */
  alt: { code: 'alt' },
  ctrl: { code: 'ctrl' },
  meta: { code: 'meta' },
  mod: { code: 'mod' },
  shift: { code: 'shift' },
  enter: { code: 'enter' },
  /**
   * Escapes
   */
  backspace: { code: 'Backspace' },
  delete: { code: 'delete' },
  Escape: { code: 'Escape' },
  /**
   * Arrows
   */
  ArrowLeft: { code: 'ArrowLeft' },
  ArrowRight: { code: 'ArrowRight' },
  ArrowUp: { code: 'ArrowUp' },
  ArrowDown: { code: 'ArrowDown' },
  /**
   * Alphabetical
   */
  a: { code: 'a' },
  b: { code: 'b' },
  c: { code: 'c' },
  d: { code: 'd' },
  e: { code: 'e' },
  f: { code: 'f' },
  g: { code: 'g' },
  h: { code: 'h' },
  i: { code: 'i' },
  j: { code: 'j' },
  k: { code: 'k' },
  l: { code: 'l' },
  m: { code: 'm' },
  n: { code: 'n' },
  o: { code: 'o' },
  p: { code: 'p' },
  q: { code: 'q' },
  r: { code: 'r' },
  s: { code: 's' },
  t: { code: 't' },
  u: { code: 'u' },
  v: { code: 'v' },
  w: { code: 'w' },
  x: { code: 'x' },
  y: { code: 'y' },
  z: { code: 'z' }
} as const)

export type SafeHotKey = keyof typeof hotKeysList
export type SafeHotkeysCombination =
  | SafeHotKey
  | `${SafeHotKey}+${SafeHotKey}`
  | `${SafeHotKey}+${SafeHotKey}+${SafeHotKey}`
