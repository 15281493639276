import React, { useMemo, useState } from 'react'

import { AuthContext } from './AuthContext'
import type { AuthState } from './AuthState.types'
import { readAuthCookies } from './authServiceUtils'

export const AuthStateProvider: React.FC<{
  children: React.ReactNode
  initialState?: Partial<AuthState>
}> = ({ children, initialState }) => {
  const initialAuthState = { ...readAuthCookies(), ...initialState }
  const [auth, setAuthState] = useState(initialAuthState)

  const updateAuthState = newState => {
    setAuthState({ ...auth, ...newState })
  }

  const value = useMemo(() => ({ auth, updateAuthState }), [auth])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
