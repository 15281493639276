import type { ApiErrorResponse } from '@libs/data-access/queries'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import type { ParamsFetchDocumentEmployee } from '../modules/entities'
import { replaceDocumentEmployee } from '../modules/entities'
import { setMetaLoading, setMetaSuccess, setMetaError } from '../modules/meta'
import { setPagination } from '../modules/pagination'

export default function* fetchDocumentEmployeeData(
  queryParams: ParamsFetchDocumentEmployee
) {
  yield put(setMetaLoading())

  try {
    const result = yield apiRequest({
      service: 'core',
      route: 'fetchDocumentEmployee',
      queryParams
    })

    yield put(replaceDocumentEmployee(result.resources))
    yield put(setPagination(result.meta))
    yield put(setMetaSuccess())
  } catch (err) {
    yield put(setMetaError(err as ApiErrorResponse))
  }
}
