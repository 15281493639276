import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'
import type {
  Rest,
  Shift
} from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import { getSortedTeamContracts } from '../../../entities/contracts/getSortedTeamContracts'
import { restsSelectors } from '../../../entities/rests'
import { rowsSelectors } from '../../../entities/rows'
import { shiftsSelectors } from '../../../entities/shifts'

export const getPlannablesByTeam = createCachedSelector(
  rowsSelectors.getCachedMap,
  shiftsSelectors.getCachedMap,
  restsSelectors.getCachedMap,
  getSortedTeamContracts,
  (rows, shifts, rests, contracts) => {
    const plannables = (contracts as any).reduce(
      (acc, contract) => {
        const row = rows[contract.id]

        if (!row) {
          return acc
        }

        const shiftsArr = row?.shifts.map(id => shifts[id])
        const restsArr = row?.rests.map(id => rests[id])
        return {
          shifts: acc.shifts.concat(shiftsArr),
          rests: acc.rests.concat(restsArr)
        }
      },
      {
        rests: [],
        shifts: []
      }
    )

    return plannables as {
      rests: Rest[]
      shifts: Shift[]
    }
  }
)((state, { teamId }) => fallbackToDefaultCacheKey(teamId))
