import {
  SortAscendingIcon,
  SortDefaultIcon,
  SortDescendingIcon
} from '@libs/ui/ds/assets/icons'
import type { SortingColumn } from '@tanstack/react-table'
import type { FC } from 'react'
import React from 'react'

import { IconWrap } from '../../../../IconWrap/base/IconWrap'

const sortedDirectionIconMap = {
  asc: <SortAscendingIcon />,
  desc: <SortDescendingIcon />,
  false: <SortDefaultIcon />
}

type TableHeaderIconSortProps = {
  sortedDirection?: ReturnType<SortingColumn<unknown>['getIsSorted']>
}

export const TableHeaderIconSort: FC<TableHeaderIconSortProps> = ({
  sortedDirection
}) => {
  return (
    <IconWrap
      className='shrink-0'
      icon={sortedDirectionIconMap[String(sortedDirection)]}
    />
  )
}
