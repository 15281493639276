import type { ActivitySchemaQuery } from '@libs/data-access/entities'
import { useQuery } from '@tanstack/react-query'

import { queryClientOptions } from '../../../../provider'
import type { ApiErrorResponse, UseAppQueryOptions } from '../../../../types'
import type { FetchActivities } from '../accountActivities.api'
import { fetchActivities } from '../accountActivities.api'
import { accountActivitiesCacheKeys } from '../accountActivities.cacheKeys'

export type UseGetActivitiesParams = {
  requestOptions?: FetchActivities
}

export const useGetActivities = (
  { requestOptions }: UseGetActivitiesParams,
  queryOptions?: UseAppQueryOptions<ActivitySchemaQuery>
) => {
  return useQuery<ActivitySchemaQuery, ApiErrorResponse>({
    ...queryOptions,
    queryKey: accountActivitiesCacheKeys.getActivities(requestOptions),
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    queryFn: () => fetchActivities(requestOptions),
    staleTime: queryClientOptions.time.ONE_SECOND
  })
}
