import { fork, put, select } from 'redux-saga/effects'

import {
  setEntities,
  setMetaLoading,
  setMetaSuccess,
  setParams
} from '../../../reducers'
import type { ParamsState } from '../../../reducers/params'
import { getWeekDateParams } from '../../../selectors'
import type { WeekDateParams } from '../../../selectors/params/getWeekDateParams/getWeekDateParams'
import { entitiesSagas } from '../../entities'
import filterRequestedContractIds from '../../utils/filterRequestedContractIds'
import {
  hydrateOldStoreContracts,
  hydrateOldStoreLabel,
  hydrateOldStoreMemberships,
  hydrateOldStoreWeeklyschedules
} from '../hydratesOldStore'
import clearRows from '../request/clearRows'
import { requestCoreEntities } from '../request/requestCoreEntities'

/**
 * Handle planning refresh
 * - Fetch all core data alongs with rows and counters data
 * - Reinitialize redux store
 */
function* planningRefresh(params: ParamsState) {
  yield put(setMetaLoading())
  yield put(setParams(params))

  const { date }: WeekDateParams = yield select(getWeekDateParams)

  yield clearRows()
  yield requestCoreEntities()

  const planningAggregate = yield entitiesSagas.fetchPlanningAggregate({
    payload: {
      date,
      locationId: params.locationId,
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      teamId: params.teamId,
      withPermissions: ['weeklyschedules']
    }
  })

  /**
   * Normalize deep nested structure in parallel
   */
  yield fork(function* () {
    yield put(setEntities({ ...planningAggregate }))

    /**
     * Global public holidays
     */
    yield fork(entitiesSagas.getPublicHolidays)
  })

  const contractIds = yield filterRequestedContractIds(planningAggregate)

  /**
   * Fetch and hydrate rows / alerts / counters
   */
  yield fork(function* () {
    const payload = { date, contractIds }

    yield fork(function* () {
      yield entitiesSagas.fetchAndHydratePlannables({ payload })
      yield put(setMetaSuccess())
    })

    yield fork(function* () {
      const isSelectingATeam = params.teamId && params.teamId > 0
      const payload = {
        date,
        locationId: isSelectingATeam ? undefined : params.locationId,
        teamId: isSelectingATeam ? params.teamId : undefined
      }
      yield entitiesSagas.fetchAndHydrateContractCounters({ payload })
    })

    yield fork(function* () {
      yield entitiesSagas.getAlertsChunked({ payload })
    })

    yield fork(function* () {
      yield entitiesSagas.fetchSignableTimesheetsPeriodStatus()
    })
  })

  /**
   * Hydrate old store entities
   */
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  yield hydrateOldStoreLabel({ teamId: params.teamId })
  yield hydrateOldStoreWeeklyschedules()
  yield hydrateOldStoreMemberships()
  yield hydrateOldStoreContracts()
}

export default planningRefresh
