import type { ReduxState } from '@spa/redux/ReduxState'
import { groupBy } from 'lodash-es'

import { weeklyschedulesBaseSelectors } from '../weeklyschedulesBaseSelectors'

export const getWeekNotes = (state: ReduxState, weeklyscheduleId: number) => {
  const weeklyschedule = weeklyschedulesBaseSelectors.getCachedById(
    state,
    weeklyscheduleId
  )

  if (!weeklyschedule?.daily_notes?.length) {
    return null
  }

  return groupBy(weeklyschedule.daily_notes, 'date')
}
