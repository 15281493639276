import { Membership } from '@spa/scenes/Plannings/redux/models'
import { setEntities } from '@spa/scenes/Plannings/redux/reducers/entities'
import { normalize } from 'normalizr'
import { put, select } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'
import { getCurrentAccountId } from 'snap-redux/modules/accounts/selectors'

import { getParams } from '../../../selectors'

export function* getMemberships(): any {
  const { date, locationId, teamId } = yield select(getParams)
  const accountId = yield select(getCurrentAccountId)

  if (!date) {
    return
  }

  const data = yield apiRequest({
    route: 'getMemberships',
    params: {
      account_id: accountId
    },
    queryParams: {
      location_id: locationId,
      in_progress_on: date,
      team_id: teamId
    }
  })

  const normalized = normalize(data, [Membership])

  yield put(setEntities(normalized.entities))
}
