import type { JobSchema } from '@libs/data-access/entities'
import type {
  ApiErrorResponse,
  UseAppMutationOptions
} from '@libs/data-access/queries'
import { appQueryClient } from '@libs/data-access/queries'
import { useMutation } from '@tanstack/react-query'

import { mergeJobs, type MergeJobsOptions } from '../jobs.api'
import { jobsCacheKeys } from '../jobs.cacheKeys'

export const useMergeJobs = (
  mutationOptions?: UseAppMutationOptions<
    JobSchema,
    ApiErrorResponse,
    MergeJobsOptions
  >
) => {
  return useMutation<JobSchema, ApiErrorResponse, MergeJobsOptions>({
    ...mutationOptions,
    mutationKey: jobsCacheKeys.merge_jobs(),
    mutationFn: opts => mergeJobs(opts),
    onSuccess: (data, variables, ...args) => {
      mutationOptions?.onSuccess?.(data, variables, ...args)
      appQueryClient.invalidateQueries({
        queryKey: jobsCacheKeys.fetch_jobs_invalidate()
      })
    }
  })
}
