import type { ButtonProps } from '@libs/ui/ds'
import { Button } from '@libs/ui/ds'
import { createButton } from '@libs/ui/ds/lib/components/buttons/BaseButton/utils/createButton'
import { forwardRef } from 'react'

import type { AlertButtonProps } from './AlertButton.types'

const AlertButton = createButton<ButtonProps>(
  forwardRef<HTMLButtonElement, AlertButtonProps>(
    (
      {
        onClick,
        leftIcon,
        rightIcon,
        children,
        disabled = false,
        size = 's',
        ...other
      },
      forwardedRef
    ) => {
      return (
        <Button
          {...other}
          intent='neutral-dark'
          variant='secondary'
          size={size}
          onClick={onClick}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          disabled={disabled}
          ref={forwardedRef}
          className='w-fit'
        >
          {children}
        </Button>
      )
    }
  )
)

export default AlertButton
