import type {
  DocumentConvertedSchema,
  DocumentSignatureEntrySchema,
  DocumentStatuses,
  InitalFormatType,
  NormalizeEntity,
  SignatoriesFormatType
} from '@libs/data-access/entities'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { DocumentSignatureModel } from '../models/DocumentSignatureModel'

export type ParamsPostDocument = {
  document: {
    data: string
    filename: string
    originalFile?: string | ArrayBuffer
  }
  ordered_signatures: boolean
  manually_positioned: boolean
  signatories: SignatoriesFormatType[]
  read_and_approved?: boolean
  initials?: InitalFormatType
  reminder_delay?: number
  attach_to_membership_id?: number
}

export type ParamsDeleteDocumentSignature = {
  signable_document_id: number
  attachment_id?: number
  delete_attachment: boolean
}

export type ParamsFetchDocument = {
  page: number
  per_page: number
  location_ids?: number[]
  team_ids?: number[]
  status?: DocumentStatuses
  search?: string
}

export type ParamsDownloadDocument = {
  signable_document_id: number
  document_name: string
}

export type ParamsDownloadProofDocument = {
  signable_document_id: number
  document_name: string
  merged: boolean
}

export type ParamsReminderDocument = {
  signable_document_id: number
}

export type ParamsConvertDocument = {
  document_id: number
}

export type ParamsGetDocumentB64 = {
  file_base64: string
  status: 'success' | 'failure'
}

const initialState: DocumentSignatureModel = {
  documentsSignature: [],
  documentConverted: undefined
}

export type EntitiesState = {
  documentSignature: NormalizeEntity<DocumentSignatureModel>
}

type replaceDocumentSignaturePayload = DocumentSignatureEntrySchema[]

type setDocumentSignaturePayload = DocumentSignatureEntrySchema[]

type setDocumentConvertedPayload = DocumentConvertedSchema

type deleteDocumentSignaturePayload = { id: number }

const entitiesSlice = createSlice({
  name: 'emp/documentSignature/entities',
  initialState,
  reducers: {
    replaceDocumentSignature(
      state,
      action: PayloadAction<replaceDocumentSignaturePayload>
    ) {
      const { documentConverted } = state
      return {
        documentsSignature: [...action.payload],
        documentConverted
      }
    },

    setDocumentSignature(
      state,
      action: PayloadAction<setDocumentSignaturePayload>
    ) {
      const { documentsSignature, documentConverted } = state
      return {
        documentsSignature: [...action.payload, ...documentsSignature],
        documentConverted
      }
    },

    deleteDocumentSignature(
      state,
      action: PayloadAction<deleteDocumentSignaturePayload>
    ) {
      const { documentsSignature } = state
      const itemIndex = documentsSignature.findIndex(
        document => document.id === action.payload.id
      )

      if (itemIndex !== -1) {
        documentsSignature.splice(itemIndex, 1)
      }
    },

    setDocumentConverted(
      state,
      action: PayloadAction<setDocumentConvertedPayload>
    ) {
      const { documentsSignature } = state
      return { documentsSignature, documentConverted: action.payload }
    },

    resetDocumentConverted(state) {
      const { documentsSignature } = state
      return { documentsSignature, documentConverted: undefined }
    }
  }
})

export const {
  setDocumentSignature,
  replaceDocumentSignature,
  setDocumentConverted,
  resetDocumentConverted,
  deleteDocumentSignature
} = entitiesSlice.actions
export default entitiesSlice.reducer
