import { groupBy, mapValues, sumBy } from 'lodash-es'
import type { Shift } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import { computeBreaksHeadcountsList } from './computeBreaksHeadcountsList'
import { computeDecimalOverlapsByInterval } from './computeDecimalOverlapsByInterval'
import { resetSecondAndMilliseconds } from './dateUtils'
import { roundDecimalToQuarter } from './roundDecimalToQuarter'

/**
 * Compute shifts headcount by interval
 */
const computeShiftsHeadcountsList = (shifts: Shift[], interval: number) => {
  return shifts.flatMap(shift =>
    computeDecimalOverlapsByInterval(
      {
        start: resetSecondAndMilliseconds(shift.real_starts_at),
        end: resetSecondAndMilliseconds(shift.real_ends_at)
      },
      interval
    )
  )
}

/**
 * Given a list of shifts
 * Return the headcount sum for each range interval
 */
export const computeShiftsDecimalHeadcounts = (
  shifts: Shift[],
  interval: number
) => {
  const shiftsHeadcountsList = computeShiftsHeadcountsList(shifts, interval)
  const breaksHeadcountsList = computeBreaksHeadcountsList(shifts, interval)
  const totalHeadcountsList = [...shiftsHeadcountsList, ...breaksHeadcountsList]

  const groupedByIsoHour = groupBy(totalHeadcountsList, 'isoHour')
  const reducedSum = mapValues(groupedByIsoHour, item => {
    const headcount = sumBy(item, 'count')
    return roundDecimalToQuarter(headcount)
  })

  return reducedSum
}
