import { NavigationMenuHorizontalBoldIcon } from '@libs/ui/ds/assets/icons'

import { IconWrap } from '../../../../dataDisplay/IconWrap/base/IconWrap'
import { Center } from '../../../../primitiveLayouts/Center/base/Center'

export const PaginationDot = () => {
  return (
    <Center className='w-8'>
      <IconWrap
        className='text-neutral-600'
        size='xs'
        icon={<NavigationMenuHorizontalBoldIcon />}
      />
    </Center>
  )
}
