import type { AmendmentSchema } from '@libs/data-access/entities'
import { type QueryFnParams, httpServices } from '@libs/data-access/queries'

export type PostAmendmentOption = QueryFnParams<{
  payload: Partial<{
    start_date: string | null | undefined
    end_date: string | null
    user_contract_id: number
    has_minimum_wage: boolean
    echelon_level: string | null
    echelon_number: number | null
    echelon_other: string | null
    mensual_brut: number | null
    contract_time: number | null
    working_days_in_week: number | null
    job_id: string | null
  }>
}>

export const postAmendment = async (opts?: PostAmendmentOption) => {
  const response = await httpServices.core.post<AmendmentSchema>(
    '/amendments',
    opts?.payload
  )
  return response.data
}

export type DeleteAmendmentOption = QueryFnParams<{
  pathParams: {
    amendment_id: number
  }
}>

export const deleteAmendment = async (opts: DeleteAmendmentOption) => {
  const response = await httpServices.core.delete(
    `/amendments/${opts.pathParams.amendment_id}`
  )
  return response.data
}

export type PutAmendmentOption = QueryFnParams<{
  pathParams: {
    amendment_id: number
  }
  payload: Partial<{
    start_date: string | null
    end_date: string | null
    user_contract_id: number
    has_minimum_wage: boolean
    echelon_level: string | null
    echelon_number: number | null
    echelon_other: string | null
    mensual_brut: number | null
    hourly_brut: number | null
    contract_time: number | null
    working_days_in_week: number | null
    job_id: string | null
  }>
}>

export const putAmendment = async (opts: PutAmendmentOption) => {
  const response = await httpServices.core.put<AmendmentSchema>(
    `/amendments/${opts.pathParams.amendment_id}`,
    opts.payload
  )
  return response.data
}
