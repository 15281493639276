import {
  PLAN_TO_ADDON_NAME,
  PLAN_TO_PAYROLL_ADDON
} from '@spa/scenes/Subscription/constant'

const getAddonObject = (
  subscriptionPlan: string,
  addons: { chargebee_id: string }[]
): { chargebee_id: string }[] => {
  const padAddon = `padonly-${PLAN_TO_ADDON_NAME[subscriptionPlan]}`
  const payExportAddon = PLAN_TO_PAYROLL_ADDON[subscriptionPlan]

  return addons.map(addon => {
    return {
      chargebee_id: addon.chargebee_id === 'pad' ? padAddon : payExportAddon
    }
  })
}

export default getAddonObject
