import { useEffect } from 'react'

import { i18nDSInstance } from '../../internals/i18n/i18nDS.setup'

import type { DesignSystemConfig } from './InternalDesignSystemProvider'
import { useInternalDesignSystem } from './InternalDesignSystemProvider'

export const SetDesignSystemConfig = ({
  config
}: {
  config: DesignSystemConfig
}) => {
  const { setConfig } = useInternalDesignSystem()

  useEffect(() => {
    if (config) {
      i18nDSInstance.changeLanguage(config.language)
      setConfig(config)
    }
  }, [config.timezone, config.language])

  return null
}
