import queryString from 'query-string'
import { call } from 'redux-saga/effects'

import {
  FETCH_PLANNABLES_LIST,
  FETCH_PLANNABLES_LIST_SUCCEEDED,
  FETCH_PLANNABLES_LIST_FAILED
} from '../actionTypes'
import { call as callAPI } from '../infra/http'
import { fetchSagaEntity } from '../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchPlannablesList = (contractId, params, resolve, reject) => ({
  type: FETCH_PLANNABLES_LIST,
  contractId,
  params,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const plannablesSagaEntity = {
  success: (data, contractId) => ({
    type: FETCH_PLANNABLES_LIST_SUCCEEDED,
    data,
    contractId
  }),
  failure: (error, contractId) => ({
    type: FETCH_PLANNABLES_LIST_FAILED,
    error,
    contractId
  }),

  fetchAPI: (contractId, options, params) =>
    callAPI(
      `/user_contracts/${contractId}/plannables?${queryString.stringify(
        params
      )}`,
      options
    )
}

export function* doFetchPlannablesList({
  contractId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    plannablesSagaEntity,
    contractId,
    params,
    undefined,
    resolve,
    reject
  )
}
