import { Portal } from '@mantine/core'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import { memo } from 'react'

import { SimpleCard } from '../../SimpleCard/base/SimpleCard'

import type { FloatingCardProps } from './FloatingCard.types'

const floatingCardCVA = {
  root: cva(['fixed z-[3] rounded-none shadow-lg-top'], {
    variants: {
      position: {
        bottom: 'inset-x-0 bottom-0',
        left: 'inset-y-0 left-0',
        right: 'inset-y-0 right-0',
        top: 'inset-x-0 top-0'
      }
    }
  })
}

export const FloatingCard: FC<FloatingCardProps> = memo(
  ({ children, className, position = 'bottom', ...other }) => {
    return (
      <Portal>
        <SimpleCard
          {...other}
          className={floatingCardCVA.root({ className, position })}
          size='s'
        >
          <div>{children}</div>
        </SimpleCard>
      </Portal>
    )
  }
)

FloatingCard.displayName = 'FloatingCard'
export type { FloatingCardProps } from './FloatingCard.types'
