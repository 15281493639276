import type { MonthlyHeaderSchema } from '@libs/data-access/entities'
import { useQuery } from '@tanstack/react-query'

import { queryClientOptions } from '../../../../provider'
import type { UseAppQueryOptions } from '../../../../types'
import { fetchMonthlyHeader, type FetchMonthlyHeader } from '../monthly.api'
import { monthlyServiceCacheKeys } from '../monthly.cacheKeys'

export const useFetchHeader = (
  request: FetchMonthlyHeader,
  queryOptions?: UseAppQueryOptions<MonthlyHeaderSchema>
) => {
  return useQuery({
    ...queryOptions,
    queryKey: monthlyServiceCacheKeys.header(request.queryParams),
    queryFn: () => fetchMonthlyHeader(request),
    staleTime: queryClientOptions.time.INFINITY
  })
}
