import type { MembershipRecord } from '@libs/data-access/entities'
import {
  ROLE_ADMIN,
  ROLE_DIRECTOR,
  ROLE_EMPLOYEE,
  ROLE_MAIN_OWNER,
  ROLE_MANAGER
} from '@spa/constants'
import { getRoleRights } from 'snap-redux/modules/memberships/utils'

const getRoleName = (membership: MembershipRecord) => {
  const { atLeast } = getRoleRights(membership)

  if (atLeast.main_owner) {
    return ROLE_MAIN_OWNER
  }
  if (atLeast.owner) {
    return ROLE_ADMIN
  }
  if (atLeast.director) {
    return ROLE_DIRECTOR
  }
  if (atLeast.manager) {
    return ROLE_MANAGER
  }
  if (!atLeast.manager) {
    return ROLE_EMPLOYEE
  }
}

export default getRoleName
