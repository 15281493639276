import type { ReduxState } from '@spa/redux/ReduxState'
import { useMemoSelector } from '@spa/redux/hooks'

import type {
  EntitiesArrays,
  EntitiesState,
  EntitiesNames
} from '../../../reducers'

import {
  createCachedEntitiesMapSelector,
  createCachedEntityByIdSelector,
  createCachedEntitiesListSelector,
  createCachedEntitiesListIdsSelector
} from './createCachedEntitiesSelector'

export const defineEntitiesSelectors = <T extends EntitiesNames>({
  type
}: {
  type: T
}) => {
  /**
   * Expose API to export cached entity map
   */
  const getCachedMap = (state: ReduxState) =>
    createCachedEntitiesMapSelector(state, { type }) as EntitiesState[T]
  const useGetCachedMap = () => useMemoSelector(getCachedMap)

  /**
   * Expose API to export cached entity array
   */
  const getCachedList = (state: ReduxState) =>
    createCachedEntitiesListSelector(state, { type }) as EntitiesArrays[T]
  const useGetCachedList = () => useMemoSelector(getCachedList)

  /**
   * Expose API to export cached entity ids array
   */
  const getCachedIdsList = (state: ReduxState) =>
    createCachedEntitiesListIdsSelector(state, { type }) as EntitiesArrays[T]
  const useGetCachedIdsList = () => useMemoSelector(getCachedList)

  /**
   * Expose API to export cached entity by id
   */
  const getCachedById = (state: ReduxState, id: number) =>
    createCachedEntityByIdSelector(state, {
      type,
      id
    }) as EntitiesState[T][number]
  const useGetCachedById = (id: number) =>
    useMemoSelector(state => getCachedById(state, id))

  return {
    getCachedMap,
    useGetCachedMap,
    getCachedList,
    getCachedIdsList,
    useGetCachedList,
    useGetCachedIdsList,
    getCachedById,
    useGetCachedById
  }
}
