import type { UpdateWeeklyScheduleSchema } from '@libs/data-access/entities'
import { uniq } from 'lodash-es'
import { select } from 'redux-saga/effects'
import type { Shift } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import { getParams } from '../../../selectors'
import { entitiesSagas } from '../../entities'
import type {
  AsyncActionCallbacks,
  PayloadActionWithoutType
} from '../../types/types'

import fetchPlannablesSideEffectsEntities from './fetchPlannableSideEffectsEntities'

export type DeleteShiftFromWeeklyscheduleParams = PayloadActionWithoutType<{
  weeklyscheduleId: number
  data: {
    shiftsToRemove: Shift[]
  }
  callbacks?: AsyncActionCallbacks<UpdateWeeklyScheduleSchema>
}>

export function* deleteShiftsFromWeeklyscheduleById({
  payload: { weeklyscheduleId, data, callbacks = {} }
}: DeleteShiftFromWeeklyscheduleParams): any {
  try {
    if (!weeklyscheduleId) {
      return
    }

    yield entitiesSagas.updateWeeklyscheduleById({
      payload: {
        weeklyscheduleId,
        data: {
          shifts_attributes: data.shiftsToRemove.map(({ id }) => ({
            id,
            _destroy: true
          }))
        }
      }
    })

    const contractIds = uniq(
      data.shiftsToRemove.map(shift => shift.user_contract_id)
    )
    const { date } = yield select(getParams)
    yield fetchPlannablesSideEffectsEntities({
      date,
      contractIds,
      countersParams: { live: true }
    })
  } catch (e) {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    callbacks.reject(e)
  }
}
