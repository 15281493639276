import { controllersActions } from '@spa/scenes/Plannings/redux/modules/controllers/controllersSliceSaga'
import { getParams } from '@spa/scenes/Plannings/redux/selectors'
import { put, select, takeEvery } from 'redux-saga/effects'
import { CREATE_MEMBERSHIP_SUCCEEDED } from 'snap-redux/actionTypes'

function* membershipSync(action) {
  const params = yield select(getParams)

  // @todo register module redux on planning v2 scene
  if (!params.date) {
    return
  }

  switch (action.type) {
    case CREATE_MEMBERSHIP_SUCCEEDED: {
      yield put(
        controllersActions.planningSilentRefresh({
          locationId: params.locationId,
          teamId: action.data.most_relevant_contract.team_id,
          date: params.date
        })
      )
      break
    }

    default:
      break
  }
}

export default function* membershipWatcher() {
  yield takeEvery([CREATE_MEMBERSHIP_SUCCEEDED], membershipSync)
}
