export const ROLE_EMPLOYEE = 'employee'
export const ROLE_MANAGER = 'manager'
export const ROLE_DIRECTOR = 'director'
export const ROLE_OWNER = 'owner'
export const ROLE_ADMIN = 'admin'
export const ROLE_MAIN_OWNER = 'main_owner'

export const DEFAULT_WORKABLE_DAYS_IN_WEEKS = 5
export const DAYS_IN_WEEKS = 7
export const DEFAULT_CONTRACT_TIME = 35

export const NOT_FOUND = 'not_found'

// This variable is used as funnel_id on segment track service
export const PAYSLIP_MARKETING_PAGE_FUNNEL_ID = 'Payslip_Marketing_page'

/* This variable is used to:
 - find if a country has SMIC
 - define default workable day for a given country
*/
export const COUNTRIES_WITH_SPECIFIC_RULES = [
  'BL',
  'FR',
  'GF',
  'GP',
  'GY',
  'MF',
  'MQ',
  'PM',
  'RE'
]

export const ROLE_ARRAY = [
  'employee',
  'manager',
  'director',
  'owner',
  'main_owner'
] as const

export type RoleArrayType = (typeof ROLE_ARRAY)[number]
