import { useInitElfsight } from '@spa/core/externals/gtm'
import type { FC, PropsWithChildren } from 'react'
import React from 'react'

export const SignUpWrapper: FC<PropsWithChildren> = ({ children }) => {
  useInitElfsight()

  return (
    <div>
      {children}
      <div id='elfsight-sign-up' />
    </div>
  )
}
