import queryString from 'query-string'
import { call } from 'redux-saga/effects'

import {
  FETCH_COUNTRY_MIN_WAGES_SUCCEEDED,
  FETCH_COUNTRY_MIN_WAGES_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { fetchSagaEntity } from '../../services/sagaUtils'

const fetchCountryMinWagesSagaEntity = {
  success: data => ({
    type: FETCH_COUNTRY_MIN_WAGES_SUCCEEDED,
    data
  }),
  failure: error => ({
    type: FETCH_COUNTRY_MIN_WAGES_FAILED,
    error
  }),

  fetchAPI: (id, options, params) =>
    callAPI(`/min_wages?${queryString.stringify(params)}`, options)
}

export function* doFetchCountryMinWages({ params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    fetchCountryMinWagesSagaEntity,
    null,
    params,
    undefined,
    resolve,
    reject
  )
}
