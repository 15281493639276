import type { ReduxState } from '@spa/redux/ReduxState'
import type { TeamModel } from '@spa/scenes/Plannings/redux/models/Team'

import { getParams } from '../../../params'
import { teamsSelectors } from '../../teams'
import { getDisplayedTeamContracts } from '../getDisplayedTeamContracts/getDisplayedTeamContracts'

export const getAllDisplayedContracts = (
  state: ReduxState,
  teams?: TeamModel[]
) => {
  const { locationId, teamId } = getParams(state)
  const currentTeams =
    teams ||
    teamsSelectors.getPlanningTeams(state, {
      locationId,
      teamId
    })

  const allDisplayedContracts = currentTeams
    .map(team => {
      const teamId = team.id
      const contracts = getDisplayedTeamContracts(state, {
        teamId
      })
      return contracts
    })
    .flatMap(contract => contract)

  return allDisplayedContracts
}
