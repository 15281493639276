import type { FormatterParams } from '../../types'

/**
 * Input: 0.33 (hours)
 * Output: 20 (minutes)
 */
export const hourDecimalToMinutes = ({ value }: FormatterParams<number>) => {
  return Math.round(value * 60)
}
export default {
  format: hourDecimalToMinutes
}
