import { ConditionalWrap, useDSTranslation } from '@libs/ui/ds'
import { cva } from 'class-variance-authority'
import { groupBy, map } from 'lodash-es'
import type { FC } from 'react'

import { Combobox } from '../../../../overlays/Combobox/base/Combobox'
import type {
  AutocompleteOptions,
  AutocompleteProps
} from '../Autocomplete.types'

type AutocompleteOptionListProps = {
  emptyListLabel?: AutocompleteProps['emptyListLabel']
  options: AutocompleteProps['options']
  loading?: AutocompleteProps['loading']
  selectedOptions: AutocompleteOptions[]
  searchValue?: string
}

const autocompleteOptionsCVA = {
  root: cva(['flex max-h-56 flex-col gap-y-0.5 overflow-auto'])
}

export const AutocompleteOptionsList: FC<AutocompleteOptionListProps> = ({
  options,
  emptyListLabel,
  searchValue,
  loading,
  selectedOptions
}) => {
  const { t } = useDSTranslation()
  const groupedOptions = groupBy(options, i => i.group || null)

  if (!options.length && searchValue && !loading) {
    return (
      <Combobox.Empty data-testid='autocomplete-empty-list'>
        {emptyListLabel || t('ds.components.autocomplete.emptyListLabel')}
      </Combobox.Empty>
    )
  }

  if (!options.length) {
    return null
  }

  return (
    <div className={autocompleteOptionsCVA.root()}>
      {map(groupedOptions, (group, groupName) => (
        <ConditionalWrap
          key={groupName}
          condition={Boolean(groupName) && groupName !== 'null'}
          wrap={children => (
            <Combobox.Group label={groupName}>{children}</Combobox.Group>
          )}
        >
          <>
            {group.map(option => (
              <Combobox.Option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
                leftIcon={option.leftIcon}
                withGroup={Boolean(groupName) && groupName !== 'null'}
                selected={selectedOptions.some(i => i.value === option.value)}
                active={selectedOptions.some(i => i.value === option.value)}
                data-testid={option['data-testid']}
              >
                {option.optionLabel || option.label}
              </Combobox.Option>
            ))}
          </>
        </ConditionalWrap>
      ))}
    </div>
  )
}
