import { appQueryClient } from '../../../provider'

import type { FetchLocationGroupBillingInfo } from './locationGroupBillingInfo.api'

export const locationGroupBillingInfoCacheKeys = {
  linkLocationToGroup: requestParams =>
    ['linkLocationToGroup', requestParams] as const,
  getByLocationGroupId: (requestParams: FetchLocationGroupBillingInfo) =>
    [
      'locationGroupBillingInfo',
      requestParams.pathParams.location_group_id
    ] as const,
  updateBillingInfo: () => ['locationGroupUpdateBillingInfo'] as const,
  updateLocationGroupEmail: () => ['updateLocationGroupEmail'] as const,
  createLocationGroupEmailRecipient: () =>
    ['createLocationGroupEmailRecipient'] as const,
  updateLocationGroupEmailRecipient: () =>
    ['updateLocationGroupEmailRecipient'] as const,
  deleteLocationGroupEmailRecipient: () =>
    ['deleteLocationGroupEmailRecipient'] as const
}

export const invalidateLocationGroupBillingInfoCache = requestParams => {
  appQueryClient.invalidateQueries({
    queryKey:
      locationGroupBillingInfoCacheKeys.getByLocationGroupId(requestParams)
  })
}
