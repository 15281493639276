import Immutable from 'immutable'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  CREATE_NOTE,
  CREATE_NOTE_SUCCEEDED,
  CREATE_NOTE_FAILED,
  UPDATE_NOTE,
  UPDATE_NOTE_SUCCEEDED,
  UPDATE_NOTE_FAILED,
  DELETE_NOTE,
  DELETE_NOTE_SUCCEEDED,
  DELETE_NOTE_FAILED,
  FETCH_WEEKLYSCHEDULES_SUCCEEDED,
  DELETE_WEEKLYSCHEDULE_SUCCEEDED,
  FETCH_WEEKLYSCHEDULE_SUCCEEDED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import {
  mergeRecords,
  updateRecord,
  deleteRecord
} from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const createNote = (noteData, resolve, reject) => ({
  type: CREATE_NOTE,
  noteData,
  resolve,
  reject
})

export const updateNote = (noteId, noteData, resolve, reject) => ({
  type: UPDATE_NOTE,
  noteId,
  noteData,
  resolve,
  reject
})

export const deleteNote = (noteId, resolve, reject) => ({
  type: DELETE_NOTE,
  noteId,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const createNoteSagaEntity = {
  success: data => ({ type: CREATE_NOTE_SUCCEEDED, data }),
  failure: error => ({ type: CREATE_NOTE_FAILED, error }),

  fetchAPI: (id, options) =>
    callAPI('/daily_notes', { method: 'POST', ...options })
}

export function* doCreateNote({ noteData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createNoteSagaEntity,
    null,
    null,
    noteData,
    resolve,
    reject
  )
}

const updateNoteSagaEntity = {
  success: (data, noteId) => ({ type: UPDATE_NOTE_SUCCEEDED, data, noteId }),
  failure: (error, noteId) => ({ type: UPDATE_NOTE_FAILED, error, noteId }),

  fetchAPI: (noteId, options) =>
    callAPI(`/daily_notes/${noteId}`, { method: 'PUT', ...options })
}

export function* doUpdateNote({ noteId, noteData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    updateNoteSagaEntity,
    noteId,
    null,
    noteData,
    resolve,
    reject
  )
}

const deleteNoteSagaEntity = {
  success: (data, noteId) => ({ type: DELETE_NOTE_SUCCEEDED, data, noteId }),
  failure: (error, noteId) => ({ type: DELETE_NOTE_FAILED, error, noteId }),

  fetchAPI: (noteId, options) =>
    callAPI(`/daily_notes/${noteId}`, { method: 'DELETE', ...options })
}

export function* doDeleteNote({ noteId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    deleteNoteSagaEntity,
    noteId,
    null,
    null,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const Note = Immutable.Record({
  id: null,
  note: null,
  date: null,
  weeklyschedule_id: null,
  service_id: null,
  created_at: null,
  updated_at: null
})

const noteForeignKeys = ['weeklyschedule_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function notes(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_WEEKLYSCHEDULE_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const weeklyschedule = Immutable.fromJS(action.data)
      const notes = weeklyschedule.get('daily_notes', Immutable.List())

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Note, notes, noteForeignKeys)
    }
    case FETCH_WEEKLYSCHEDULES_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const notes = Immutable.fromJS(action.data)
        .map(
          weeklyschedule =>
            weeklyschedule.get('daily_notes') || Immutable.List()
        )
        .flatten(true)

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Note, notes, noteForeignKeys)
    }
    case CREATE_NOTE_SUCCEEDED:
    case UPDATE_NOTE_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const note = Immutable.fromJS(action.data)
      // @ts-ignore migration from js(x) to ts(x)
      return updateRecord(state, Note, note, noteForeignKeys)
    }
    case DELETE_NOTE_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { noteId } = action
      // @ts-ignore migration from js(x) to ts(x)
      return deleteRecord(state, noteId, noteForeignKeys)
    }
    case DELETE_WEEKLYSCHEDULE_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { weeklyscheduleId } = action
      const notesIds =
        state.getIn(['relations', 'weeklyschedule_id', weeklyscheduleId]) ||
        Immutable.Set()

      return state
        .deleteIn(['relations', 'weeklyschedule_id', weeklyscheduleId])
        .withMutations(store => {
          notesIds.forEach(noteId => {
            store.deleteIn(['data', noteId])
          })
        })
    }
    default:
      return state
  }
}

export default filterActions(notes, [
  FETCH_WEEKLYSCHEDULE_SUCCEEDED,
  FETCH_WEEKLYSCHEDULES_SUCCEEDED,
  CREATE_NOTE_SUCCEEDED,
  UPDATE_NOTE_SUCCEEDED,
  DELETE_NOTE_SUCCEEDED,
  DELETE_WEEKLYSCHEDULE_SUCCEEDED
])
