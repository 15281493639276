import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type SelectionCellTarget = {
  target: 'cell'
  data: {
    contractId: number
    teamId: number
    weeklyscheduleId: number
    day: string
  }
}

export type SelectionRowTarget = {
  target: 'row'
  data: {
    contractId: number
    teamId: number
    weeklyscheduleId: number
    day?: null
  }
}

export type SelectionDayTarget = {
  target: 'day'
  data: {
    day: string
    teamId: number
    weeklyscheduleId: number
    contractId: null
  }
}

export type SelectionEmptyTarget = {
  target: 'empty'
  data: null
}

export type SelectedTarget =
  | SelectionCellTarget
  | SelectionRowTarget
  | SelectionDayTarget
  | SelectionEmptyTarget

export type CopiedTarget = SelectedTarget & {
  elements: {
    shiftIds: number[]
  }
}

export type GridSelection = {
  selected: SelectedTarget
  copied: CopiedTarget
  focused: boolean
}

export const createSelectTargetId = (params: SelectedTarget) => {
  const { teamId, contractId, day } = params.data || {}
  return `${teamId}-${contractId}-${day}`
}

export const createSelectTarget = (opts: SelectedTarget) => {
  return opts
}

const initialState: GridSelection = {
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  selected: null,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  copied: null,
  focused: false
}

const gridSelectionSlice = createSlice({
  name: 'ui/planning/gridSelection',
  initialState,
  reducers: {
    /**
     * Reset the focused state
     */
    setUnfocused(state) {
      return { ...state, focused: false }
    },
    /**
     * Reset the selected and focused state
     */
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    setUnselected(state) {
      return { ...state, focused: null, selected: null }
    },
    /**
     * Set the focused state
     */
    setFocused(state) {
      return { ...state, focused: true }
    },
    /**
     * Reset the copied state
     */
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    resetCopied(state) {
      return { ...state, copied: null }
    },

    /**
     * Set the selected target
     */
    setSelectedTarget(state, action: PayloadAction<SelectedTarget>) {
      return {
        ...state,
        focused: true,
        selected: action.payload
      }
    },
    /**
     * Copy the selected target
     */
    copySelectedTarget(state, action: PayloadAction<CopiedTarget['elements']>) {
      const currentSelection = state.selected

      if (!currentSelection?.data) {
        return state
      }

      return {
        ...state,
        focused: true,
        copied: {
          ...currentSelection,
          elements: action.payload
        }
      }
    }
  }
})

export const {
  copySelectedTarget,
  setSelectedTarget,
  setUnfocused,
  setFocused,
  setUnselected,
  resetCopied
} = gridSelectionSlice.actions
// @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
export default gridSelectionSlice.reducer
