import { call } from 'redux-saga/effects'

import {
  FETCH_SOCKET_AUTHORIZATION_TICKET,
  FETCH_SOCKET_AUTHORIZATION_TICKET_SUCCEEDED,
  FETCH_SOCKET_AUTHORIZATION_TICKET_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Sagas
// ------------------------------------

export const fetchSocketAuthorizationTicket = (
  accountId,
  params,
  resolve,
  reject
) => ({
  type: FETCH_SOCKET_AUTHORIZATION_TICKET,
  accountId,
  params,
  resolve,
  reject
})

const wsTicketSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_SOCKET_AUTHORIZATION_TICKET_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_SOCKET_AUTHORIZATION_TICKET_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options) =>
    callAPI('/socket_authorization_tickets', { method: 'POST', ...options })
}

export function* doFetchSocketAuthorizationTicket({
  accountId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    wsTicketSagaEntity,
    accountId,
    undefined,
    params,
    resolve,
    reject
  )
}
