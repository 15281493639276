import Immutable from 'immutable'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_MODULATION_PERIOD,
  FETCH_MODULATION_PERIOD_SUCCEEDED,
  FETCH_MODULATION_PERIOD_FAILED,
  FETCH_MODULATION_PERIODS,
  FETCH_MODULATION_PERIODS_SUCCEEDED,
  FETCH_MODULATION_PERIODS_FAILED,
  CREATE_MODULATION_PERIOD,
  CREATE_MODULATION_PERIOD_SUCCEEDED,
  CREATE_MODULATION_PERIOD_FAILED,
  UPDATE_MODULATION_PERIOD,
  UPDATE_MODULATION_PERIOD_SUCCEEDED,
  UPDATE_MODULATION_PERIOD_FAILED,
  DELETE_MODULATION_PERIOD,
  DELETE_MODULATION_PERIOD_SUCCEEDED,
  DELETE_MODULATION_PERIOD_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import {
  mergeRecords,
  updateRecord,
  deleteRecord
} from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchModulationPeriod = (modulationPeriodId, resolve, reject) => ({
  type: FETCH_MODULATION_PERIOD,
  modulationPeriodId,
  resolve,
  reject
})

export const fetchModulationPeriods = (locationId, resolve, reject) => ({
  type: FETCH_MODULATION_PERIODS,
  locationId,
  resolve,
  reject
})

export const createModulationPeriod = (
  modulationPeriodData,
  resolve,
  reject
) => ({
  type: CREATE_MODULATION_PERIOD,
  modulationPeriodData,
  resolve,
  reject
})

export const updateModulationPeriod = (
  modulationPeriodId,
  modulationPeriodData,
  resolve,
  reject
) => ({
  type: UPDATE_MODULATION_PERIOD,
  modulationPeriodId,
  modulationPeriodData,
  resolve,
  reject
})

export const deleteModulationPeriod = (
  modulationPeriodId,
  resolve,
  reject
) => ({
  type: DELETE_MODULATION_PERIOD,
  modulationPeriodId,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const modulationPeriodSagaEntity = {
  success: (data, modulationPeriodId) => ({
    type: FETCH_MODULATION_PERIOD_SUCCEEDED,
    data,
    modulationPeriodId
  }),
  failure: (error, modulationPeriodId) => ({
    type: FETCH_MODULATION_PERIOD_FAILED,
    error,
    modulationPeriodId
  }),

  fetchAPI: (modulationPeriodId, options) =>
    callAPI(`/modulation_periods/${modulationPeriodId}`, options)
}

export function* doFetchModulationPeriod({
  modulationPeriodId,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    modulationPeriodSagaEntity,
    modulationPeriodId,
    null,
    undefined,
    resolve,
    reject
  )
}

const modulationPeriodsSagaEntity = {
  success: (data, locationId) => ({
    type: FETCH_MODULATION_PERIODS_SUCCEEDED,
    data,
    locationId
  }),
  failure: (error, locationId) => ({
    type: FETCH_MODULATION_PERIODS_FAILED,
    error,
    locationId
  }),

  fetchAPI: (locationId, options) =>
    callAPI(`/locations/${locationId}/modulation_periods`, options)
}

export function* doFetchModulationPeriods({ locationId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    modulationPeriodsSagaEntity,
    locationId,
    null,
    undefined,
    resolve,
    reject
  )
}

const createModulationPeriodSagaEntity = {
  success: data => ({
    type: CREATE_MODULATION_PERIOD_SUCCEEDED,
    data
  }),
  failure: error => ({ type: CREATE_MODULATION_PERIOD_FAILED, error }),

  fetchAPI: (id, options) =>
    callAPI('/modulation_periods/', { method: 'POST', ...options })
}

export function* doCreateModulationPeriod({
  modulationPeriodData,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    createModulationPeriodSagaEntity,
    null,
    null,
    modulationPeriodData,
    resolve,
    reject
  )
}

const updateModulationPeriodSagaEntity = {
  success: (data, modulationPeriodId) => ({
    type: UPDATE_MODULATION_PERIOD_SUCCEEDED,
    data,
    modulationPeriodId
  }),
  failure: (error, modulationPeriodId) => ({
    type: UPDATE_MODULATION_PERIOD_FAILED,
    error,
    modulationPeriodId
  }),

  fetchAPI: (modulationPeriodId, options) =>
    callAPI(`/modulation_periods/${modulationPeriodId}`, {
      method: 'PUT',
      ...options
    })
}

export function* doUpdateModulationPeriod({
  modulationPeriodId,
  modulationPeriodData,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    updateModulationPeriodSagaEntity,
    modulationPeriodId,
    null,
    modulationPeriodData,
    resolve,
    reject
  )
}

const deleteModulationPeriodSagaEntity = {
  success: (data, modulationPeriodId) => ({
    type: DELETE_MODULATION_PERIOD_SUCCEEDED,
    data,
    modulationPeriodId
  }),
  failure: (error, modulationPeriodId) => ({
    type: DELETE_MODULATION_PERIOD_FAILED,
    error,
    modulationPeriodId
  }),

  fetchAPI: (modulationPeriodId, options) =>
    callAPI(`/modulation_periods/${modulationPeriodId}`, {
      method: 'DELETE',
      ...options
    })
}

export function* doDeleteModulationPeriod({
  modulationPeriodId,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    deleteModulationPeriodSagaEntity,
    modulationPeriodId,
    null,
    null,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const ModulationPeriod = Immutable.Record({
  id: null,
  location_id: null,
  start_date: null,
  end_date: null,
  full_time_max_weekly_worked_hours: null,
  full_time_min_weekly_worked_hours: null,
  half_time_max_weekly_worked_hours: null,
  half_time_min_weekly_worked_hours: null,
  total_period_hours: null,
  full_time: null,
  half_time: null,
  team_ids: null,
  modulation_periods_rests: Immutable.List(),
  subtracted_holiday: null,
  subtracted_paid_leave: null
})

const modulationPeriodsForeignKeys = ['location_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function modulationPeriod(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_MODULATION_PERIOD: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_MODULATION_PERIODS: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_MODULATION_PERIOD_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_MODULATION_PERIODS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_MODULATION_PERIOD_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const modulationPeriod = Immutable.fromJS(action.data)

      return updateRecord(
        state,
        ModulationPeriod,
        modulationPeriod,
        // @ts-ignore migration from js(x) to ts(x)
        modulationPeriodsForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    case FETCH_MODULATION_PERIODS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const modulationPeriods = Immutable.fromJS(action.data)

      return mergeRecords(
        state,
        ModulationPeriod,
        modulationPeriods,
        // @ts-ignore migration from js(x) to ts(x)
        modulationPeriodsForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    case CREATE_MODULATION_PERIOD_SUCCEEDED:
    case UPDATE_MODULATION_PERIOD_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const modulationPeriod = Immutable.fromJS(action.data)
      return updateRecord(
        state,
        ModulationPeriod,
        modulationPeriod,
        // @ts-ignore migration from js(x) to ts(x)
        modulationPeriodsForeignKeys
      )
    }
    case DELETE_MODULATION_PERIOD_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { modulationPeriodId } = action

      return deleteRecord(
        state,
        modulationPeriodId,
        // @ts-ignore migration from js(x) to ts(x)
        modulationPeriodsForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        success: true
      })
    }
    default:
      return state
  }
}

export default filterActions(modulationPeriod, [
  FETCH_MODULATION_PERIOD,
  FETCH_MODULATION_PERIOD_SUCCEEDED,
  FETCH_MODULATION_PERIOD_FAILED,
  FETCH_MODULATION_PERIODS,
  FETCH_MODULATION_PERIODS_SUCCEEDED,
  FETCH_MODULATION_PERIODS_FAILED,
  CREATE_MODULATION_PERIOD,
  CREATE_MODULATION_PERIOD_SUCCEEDED,
  CREATE_MODULATION_PERIOD_FAILED,
  UPDATE_MODULATION_PERIOD,
  UPDATE_MODULATION_PERIOD_SUCCEEDED,
  UPDATE_MODULATION_PERIOD_FAILED,
  DELETE_MODULATION_PERIOD,
  DELETE_MODULATION_PERIOD_SUCCEEDED,
  DELETE_MODULATION_PERIOD_FAILED
])
