import type { AccountLocationGroupInvoice } from '@libs/data-access/entities'
import { useQuery } from '@tanstack/react-query'

import type {
  PaginatedQueryResponse,
  UseAppQueryOptions
} from '../../../../types'
import type { FetchInvoicesOptions } from '../accountInvoices.api'
import { fetchInvoices } from '../accountInvoices.api'
import { accountInvoicesCacheKeys } from '../accountInvoices.cacheKeys'

export const useGetAccountInvoices = (
  request?: FetchInvoicesOptions,
  queryOptions?: UseAppQueryOptions<
    PaginatedQueryResponse<AccountLocationGroupInvoice>
  >
) => {
  return useQuery({
    ...queryOptions,
    queryKey: accountInvoicesCacheKeys.getByAccountId(request),
    queryFn: () => fetchInvoices(request)
  })
}
