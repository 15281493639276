import moment from 'moment'
import { createSelector } from 'reselect'

import { getParams } from '../../../params'
import { teamsSelectors } from '../../teams'
import { weeklyschedulesBaseSelectors } from '../weeklyschedulesBaseSelectors'

export const getLocationWeeklyschedules = createSelector(
  weeklyschedulesBaseSelectors.getCachedList,
  teamsSelectors.getCachedMap,
  getParams,

  (weeklyschedules, teams, { locationId, date }) => {
    if (!weeklyschedules?.length || !teams) {
      return []
    }

    const week = moment(date).startOf('isoWeek').format('YYYY-MM-DD')

    return (
      weeklyschedules
        // check if its in week
        .filter(weeklyschedule => {
          const team = teams[weeklyschedule.team_id]
          const isSameLocation = team?.location_id === locationId
          const date = moment(weeklyschedule.week_start_date).format(
            'YYYY-MM-DD'
          )
          const isSameDate = date === week

          return isSameDate && isSameLocation
        })
    )
  }
)
