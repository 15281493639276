import moment from 'moment'

import { useAuthState } from './useAuthState'

const TOKEN_EXPIRATION_OFFSET_HOURS = 1 // Offset to check token expiration

/**
 * Checks if the access token is close to expiring, using a specified offset.
 */
export const isTokenExpiredSoon = (expiresAt: string) => {
  const nowWithOffset = moment().add(TOKEN_EXPIRATION_OFFSET_HOURS, 'hour')
  return nowWithOffset.isAfter(expiresAt)
}

/**
 * Periodically checks if the access token is close to expiring.
 * When the expiration threshold is near, both accessToken and refreshToken are refreshed.
 */
export const useIsAccessTokenExpiredSoon = () => {
  const { auth } = useAuthState()
  const expiresAt = auth.accessTokenExpiresAt as string

  return {
    isTokenExpiredSoon: isTokenExpiredSoon(expiresAt)
  }
}
