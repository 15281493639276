import type { ShiftSchema } from '@libs/data-access/entities'

import type { GetRoute, PutRoute, PostRoute, DeleteRoute } from '../../types'

export const shiftsRoutes = {
  postShift: { method: 'POST', path: '/shifts' },
  putShift: { method: 'PUT', path: '/shifts/:id' },
  deleteShift: { method: 'DELETE', path: '/shifts/:id' },
  getShiftsByUser: { method: 'GET', path: '/users/:user_id/shifts' },
  getShiftsByAccount: {
    method: 'GET',
    path: '/accounts/:account_id/shifts'
  }
} as const

export type ShiftsRoutes = {
  postShift: PostRoute<[], {}, ShiftSchema>
  putShift: PutRoute<['id'], {}, ShiftSchema>
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  deleteShift: DeleteRoute<[], {}, null>
  getShiftsByUser: GetRoute<['user_id'], {}, ShiftSchema[]>
  getShiftsByAccount: GetRoute<
    ['account_id'],
    { start_date: string; end_date: string; membership_ids: number[] },
    ShiftSchema[]
  >
}
