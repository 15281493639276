export const ArrowRightFullIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_759_121)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.5244 0.616827C10.3364 0.635475 10.1769 0.712683 10.0368 0.852811C9.89332 0.996291 9.81929 1.15165 9.80116 1.34731C9.78239 1.54985 9.8298 1.73455 9.942 1.89601C9.96557 1.92993 11.2358 3.20605 13.595 5.56599L17.2119 9.18396L0.675997 9.1882L0.615901 9.20537C0.464453 9.24867 0.349181 9.31577 0.241917 9.42308C0.130869 9.53419 0.0668852 9.64331 0.0256052 9.79201C0.00673324 9.86 0.00450924 9.88192 0.00450924 10C0.00450924 10.1181 0.00673324 10.14 0.0256052 10.208C0.0668852 10.3567 0.130869 10.4658 0.241917 10.5769C0.349181 10.6843 0.464453 10.7514 0.615901 10.7947L0.675997 10.8118L17.2119 10.8161L13.595 14.434C11.2358 16.794 9.96557 18.0701 9.942 18.104C9.8298 18.2655 9.78239 18.4502 9.80116 18.6527C9.81929 18.8484 9.89332 19.0037 10.0368 19.1472C10.2173 19.3277 10.4307 19.404 10.6939 19.3823C10.831 19.371 10.9411 19.332 11.068 19.2499C11.1121 19.2214 12.0433 18.2948 15.4508 14.8889C17.8306 12.5102 19.7988 10.5379 19.8245 10.5059C19.9048 10.4063 19.9546 10.304 19.9798 10.1866C19.9872 10.1522 19.9966 10.111 20.0007 10.0951C20.0101 10.058 20.0102 9.91611 20.0009 9.8979C19.9969 9.89026 19.9894 9.86061 19.9842 9.83201C19.9634 9.71904 19.9129 9.60888 19.8357 9.50801C19.8121 9.47721 17.8423 7.50224 15.4584 5.11917C12.0443 1.70632 11.1121 0.778643 11.068 0.750107C10.9231 0.656363 10.8341 0.628283 10.632 0.612531C10.6122 0.610987 10.5638 0.612923 10.5244 0.616827ZM0.00302124 10C0.00302124 10.0506 0.00432524 10.0713 0.00590924 10.046C0.00749324 10.0207 0.00749324 9.97931 0.00590924 9.95401C0.00432524 9.92872 0.00302124 9.94941 0.00302124 10Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_759_121'>
          <rect width='20' height='20' fill='none' />
        </clipPath>
      </defs>
    </svg>
  )
}
