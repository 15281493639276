import { appQueryClient } from '../../../provider'

export const accountInvoicesCacheKeys = {
  getByAccountId: request => ['invoices', request] as const,
  getByAccountIdNotPaidInvoices: () => ['not-paid-invoices'] as const,
  getByAccountBasedAccountId: () => ['account-based-invoices'] as const,
  getByAccountInvoiceId: request => ['invoice', request] as const,
  getByCustomerIdInvoiceCollectNow: request =>
    ['invoice-collect-now', request] as const
}

export const invalidateAccountIdNotPaidInvoices = () => {
  appQueryClient.invalidateQueries({
    queryKey: accountInvoicesCacheKeys.getByAccountIdNotPaidInvoices()
  })
}
