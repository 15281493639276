import type { ReduxState } from '@spa/redux/ReduxState'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import { formatToIsoDay } from '@spa/utils/date'
import { groupBy, mapValues } from 'lodash-es'
import createCachedSelector from 're-reselect'

import { sortByDate } from '../../utils'
import getRestCards from '../getRestCards/getRestCards'
import getShiftCards from '../getShiftCards/getShiftCards'

type PlannableItem = {
  id: number
  rest_type?: string
  duration_type?: string
  starts_at?: string
}

/**
 * return WeekMap of id and isRest
 */
const mapDaysByIdAndRest = (days: Record<string, PlannableItem[]>) => {
  return mapValues(days, items =>
    items.map(item => ({ id: item.id, isRest: Boolean(item.duration_type) }))
  )
}

/**
 * Group array of item by their starts_at, and format to iso day YYYY-MM-DD
 */
const groupByIsoDay = (cards: PlannableItem[]) => {
  return groupBy(cards, item => formatToIsoDay(item.starts_at))
}

type Params = {
  teamId: number
  contractId: number
}

/**
 * Use only contractId as cache key
 */
const cacheKeySelector = (_: ReduxState, { contractId }: Params) => {
  return fallbackToDefaultCacheKey(contractId)
}

/**
 * Return planning rest cards mapped by iso days
 */
const getPlannableCardsGroupedByDay = createCachedSelector(
  (state: ReduxState, params: Params) => getShiftCards(state, params),
  (state: ReduxState, params: Params) => getRestCards(state, params),

  (shiftCards, restCards) => {
    const cards = [...restCards, ...shiftCards]
    const cardsByIsoDay = groupByIsoDay(sortByDate(cards))

    return mapDaysByIdAndRest(cardsByIsoDay)
  }
)(cacheKeySelector)

export default getPlannableCardsGroupedByDay
