import { useMemoSelector } from '@spa/redux/hooks'
import { useAuthState } from '@spa/scenes/Auth/shared/services'
import { useEffect } from 'react'
import type { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { getCurrentMembershipId } from 'snap-redux/modules/memberships/selectors'
import { getCurrentUser } from 'snap-redux/modules/users/selectors'

import { faroClient } from '../services/faroClient'

export const FaroSetup: FC = () => {
  const user = useMemoSelector(getCurrentUser)
  const { auth } = useAuthState()
  const membershipId = useMemoSelector(getCurrentMembershipId)
  const location = useLocation()

  useEffect(() => {
    faroClient.init({ accountId: Number(auth.accountId) })
  }, [])

  useEffect(() => {
    faroClient.setPage(location)
  }, [location])

  useEffect(() => {
    if (user && auth.accountId && membershipId) {
      faroClient.setUser({
        user,
        membershipId,
        accountId: auth.accountId
      })
    }
  }, [user, auth.accountId, membershipId])

  return null
}
