import { httpServices } from '../../../apis'
import type { QueryFnParams } from '../../../types'

export type CreateLocationGroupDraftOptions = QueryFnParams<{
  payload: {
    snap_app_location_id: string
  }
}>

export type CreateLocationGroupDraftResponse = {
  location_group_id: string
}

export const createLocationGroupDraft = async (
  options: CreateLocationGroupDraftOptions
) => {
  const response =
    await httpServices.billing.post<CreateLocationGroupDraftResponse>(
      `/location-based/location-groups/drafts`,
      {
        snap_app_account_id: String(httpServices.authInfo.accountId),
        snap_app_location_id: options.payload.snap_app_location_id
      }
    )

  return response.data
}

export type DeleteLocationGroupDraftOptions = QueryFnParams<{
  pathParams: {
    location_group_id: string
  }
}>

export const deleteLocationGroupDraft = async (
  options: DeleteLocationGroupDraftOptions
) => {
  const response = await httpServices.billing.delete<null>(
    `/location-based/location-groups/drafts/${options.pathParams.location_group_id}?snap_app_account_id=${httpServices.authInfo.accountId}`
  )

  return response.data
}
