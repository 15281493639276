import { Banner } from '@libs/ui/ds'
import {
  IS_MAINTENANCE_BANNER_ENABLED,
  WEB_MAINTENANCE_BANNER_MESSAGE
} from '@libs/utils/environments'
import { useCommonAppTranslation } from '@libs/utils/translations'
import type { FC } from 'react'

export const MaintenanceBanner: FC = () => {
  const { t } = useCommonAppTranslation()

  if (!IS_MAINTENANCE_BANNER_ENABLED) {
    return null
  }

  return (
    <Banner variant='warning'>
      {t(`banner.maintenance.${WEB_MAINTENANCE_BANNER_MESSAGE}`)}
    </Banner>
  )
}
