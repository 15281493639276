import { CloseIcon } from '@libs/ui/ds'
import type { FC } from 'react'
import React from 'react'

import { IconButton } from '../../../../buttons/IconButton/base/IconButton'
import type { SearchInputProps } from '../SearchInput.types'

type SearchInputClearButtonProps = {
  displayClear: boolean
  onClear?: SearchInputProps['onClear']
  setControlledValue?: (args: any) => void
}

export const SearchInputClearButton: FC<SearchInputClearButtonProps> = ({
  displayClear,
  onClear,
  setControlledValue
}) => {
  if (!displayClear) {
    return null
  }

  return (
    <IconButton
      size='xs'
      variant='tertiary'
      intent='neutral-dark'
      icon={<CloseIcon />}
      onClick={event => {
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        setControlledValue({ target: { value: '' } } as any)
        if (onClear) {
          onClear(event)
        }
      }}
    />
  )
}
