import { DatePickerInput } from '@libs/ui/ds'
import { ControllerBase } from '@libs/ui/form'
import type { FieldValues, FieldPath } from 'react-hook-form'

import type { ControlledDatePickerInputProps } from './ControlledDatePickerInput.types'

export const ControlledDatePickerInput = <
  TFieldValues extends FieldValues = FieldValues,
  TInputName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  onChangeSideEffect,
  rules,
  ...other
}: ControlledDatePickerInputProps<TFieldValues, TInputName>) => (
  <ControllerBase
    rules={rules}
    control={control}
    field={<DatePickerInput {...other} />}
    name={name}
    onChangeAdapter={val => val}
    onChangeSideEffect={onChangeSideEffect}
  />
)
