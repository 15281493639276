import Immutable from 'immutable'

const mapListToTuple = (list, key, Record?: any) =>
  list.map(item => [item.get(key), Record ? new Record(item) : item])

export const listToMapWithKey = (list, key) =>
  Immutable.Map(mapListToTuple(list, key))
export const listToOrderedMapWithKey = (list, key) =>
  Immutable.OrderedMap(mapListToTuple(list, key))

export const arrayToMapWithKey = (array, key) =>
  listToMapWithKey(Immutable.fromJS(array), key)
export const arrayToOrderedMapWithKey = (array, key) =>
  listToOrderedMapWithKey(Immutable.fromJS(array), key)

export const listToMapWithKeyAndRecord = (list, key, record) =>
  Immutable.Map(mapListToTuple(list, key, record))
export const listToOrderedMapWithKeyAndRecord = (list, key, record) =>
  Immutable.OrderedMap(mapListToTuple(list, key, record))

// predicate function for Immutable
export function keyIn(keys) {
  const keySet = Immutable.Set(keys)
  return (v, k) => keySet.has(k)
}

export const listGroupToMapByForeignKey = (list, foreign_id, key = 'id') =>
  list.reduce((accumulator, item) => {
    if (!item.get(foreign_id)) {
      return accumulator
    }
    return accumulator.update(item.get(foreign_id), Immutable.Set(), item_ids =>
      item_ids.add(item.get(key))
    )
  }, Immutable.Map())

export const listGroupToMapByForeignKeys = (list, foreign_ids, key = 'id') =>
  list.reduce((accumulator, item) => {
    if (!item.get(foreign_ids)) {
      return accumulator
    }

    return item
      .get(foreign_ids)
      .reduce(
        (acc, foreign_id) =>
          acc.update(foreign_id, Immutable.Set(), item_ids =>
            item_ids.add(item.get(key))
          ),
        accumulator
      )
  }, Immutable.Map())

export const getDataByForeignId = (
  store,
  moduleName,
  foreignIdKey,
  foreignId,
  key = 'id'
) => {
  const relatedItemIds = store[moduleName].getIn(
    ['relations', foreignIdKey, foreignId],
    Immutable.Map()
  )
  const listRelatedItemIds = relatedItemIds
    .map(itemId => store[moduleName].getIn(['data', itemId]))
    .toList()

  return listToMapWithKey(listRelatedItemIds, key) as any
}

export const getDataByForeignIds = (
  store,
  moduleName,
  foreignIdKey,
  foreignIds,
  key = 'id'
) => {
  const relatedItemIds = foreignIds
    ? foreignIds.reduce((accumulator, otherItemId) => {
        const itemIds = store[moduleName].getIn([
          'relations',
          foreignIdKey,
          otherItemId
        ])
        return itemIds ? accumulator.union(itemIds) : accumulator
      }, Immutable.Set())
    : Immutable.Set()

  const listRelatedItemIds = relatedItemIds
    ? relatedItemIds
        .map(itemId => store[moduleName].getIn(['data', itemId]))
        .toList()
    : Immutable.List()

  return listToMapWithKey(listRelatedItemIds, key) as any
}

export const getDataByForeignIdThroughOtherForeignId = (
  store,
  moduleName,
  foreignIdKey,
  otherModuleName,
  otherForeignIdKey,
  otherForeignId,
  key = 'id'
) => {
  const otherForeignIds = store[otherModuleName].get([
    'relations',
    otherForeignIdKey,
    otherForeignId
  ])

  return getDataByForeignIds(
    store,
    moduleName,
    foreignIdKey,
    otherForeignIds,
    key
  )
}

// ////

function safeMergeMapInRecord(a, b) {
  return a.withMutations(record =>
    b?.forEach((value, key) => {
      if (record.has(key)) {
        record.set(key, value)
      }
    })
  )
}

// ////

export const updateRecord = (
  store,
  Record,
  newData,
  foreignKeys = [],
  primaryKey = 'id'
) => {
  const id = newData?.get(primaryKey)
  const oldData = store.getIn(['data', id])

  return store
    .updateIn(['data', id], new Record(), record =>
      safeMergeMapInRecord(record, newData)
    )
    .withMutations(collection => {
      foreignKeys?.forEach(foreignKey => {
        if (oldData) {
          collection.updateIn(
            ['relations', foreignKey, oldData.get(foreignKey)],
            item_ids => item_ids && item_ids.remove(id)
          )
        }
        if (newData?.get(foreignKey)) {
          collection.updateIn(
            ['relations', foreignKey, newData.get(foreignKey)],
            Immutable.Set(),
            item_ids => item_ids.add(id)
          )
        }
      })
    })
}

export const deleteRecord = (store, id, foreignKeys = []) => {
  const data = store.getIn(['data', id], null)

  if (!data) {
    return store
  }

  return store.deleteIn(['data', id]).withMutations(collection => {
    foreignKeys.forEach(foreignKey => {
      collection.updateIn(
        ['relations', foreignKey, data.get(foreignKey)],
        item_ids => item_ids && item_ids.remove(id)
      )
    })
  })
}

export const mergeRecords = (
  store,
  Record,
  listData,
  foreignKeys = [],
  primaryKey = 'id'
) =>
  store.withMutations(collection => {
    listData.forEach(mapItem => {
      updateRecord(collection, Record, mapItem, foreignKeys, primaryKey)
    })
  })

export const replaceRecord = (
  store,
  Record,
  newData,
  foreignKeys = [],
  primaryKey = 'id'
) =>
  store.withMutations(collection => {
    const id = newData.get(primaryKey)
    // Delete the record if already present.
    if (store.has(id)) {
      deleteRecord(collection, id, foreignKeys)
    }

    // Add the record to the store.
    updateRecord(collection, Record, newData, foreignKeys, primaryKey)
  })

export const isImmutableEquals = (firstImmutable, secondImmutable) => {
  return firstImmutable.equals && firstImmutable.equals(secondImmutable)
}
