import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import { map } from 'lodash-es'
import createCachedSelector from 're-reselect'

import { contractsSelectors } from '../../contracts'
import { alertsBaseSelectors } from '../alertsBaseSelectors'

const getAlertsByTeamId = createCachedSelector(
  alertsBaseSelectors.getCachedList,
  (state, teamId) =>
    contractsSelectors.getSortedTeamContracts(state, { teamId }),
  (_, teamId: number) => teamId,

  (alerts, contracts, teamId) => {
    const contractIds = map(contracts, 'id')

    return teamId
      ? alerts.filter(alert => contractIds.includes(alert.user_contract_id))
      : alerts
  }
)((_, teamId) => fallbackToDefaultCacheKey(teamId))

export default getAlertsByTeamId
