import type { AccountStatus } from '@libs/data-access/entities'

// We are mocking data to not block staging & sandbox
export const fallbackAccountStatusData = {
  activated_at: new Date(),
  current_term_ends_at: new Date(),
  current_term_starts_at: new Date(),
  is_active: true,
  is_cancelled: false,
  is_in_trial: false,
  is_non_renewing: false,
  reactivated_at: null,
  trial_ends_at: new Date(),
  trial_starts_at: new Date(),
  actions: {
    suspend_due_to_unpaid: false,
    warn_incomplete_draft_for_group_ids: ['123-test'],
    warn_last_trial_day: false,
    warn_missing_billing_info_for_group_ids: [],
    warn_missing_payment_method_for_group_ids: [],
    warn_reactivate: false,
    warn_trial_has_expired: false
  }
} as AccountStatus
