import type { MembershipSchema } from '@libs/data-access/entities'
import { useQuery } from '@tanstack/react-query'

import type {
  PaginatedQueryResponse,
  UseAppQueryOptions
} from '../../../../types'
import type { FetchSearchedMembershipsOptions } from '../membership.api'
import { fetchSearchedMemberships } from '../membership.api'
import { membershipQueryKeys } from '../membership.cacheKeys'

export const useFetchSearchedMemberships = (
  request: FetchSearchedMembershipsOptions,
  queryOptions?: UseAppQueryOptions<
    PaginatedQueryResponse<MembershipSchema, 'memberships'>
  >
) => {
  return useQuery({
    ...queryOptions,
    queryKey: membershipQueryKeys.memberships(request?.queryParams),
    queryFn: () => fetchSearchedMemberships(request)
  })
}
