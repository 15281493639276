import type { ModulationUserContractSchema } from '@libs/data-access/entities'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'

import type { UseAppQueryOptions } from '../../../../types'
import type { FetchAllModulationUserContractsOptions } from '../modulation.contract.api'
import { fetchAllModulationUserContracts } from '../modulation.contract.api'
import { modulationUserContractsCachekeys } from '../modulation.contract.cacheKeys'

const today = moment().format('YYYY-MM-DD')

export const useFetchModulationUserContracts = (
  request: FetchAllModulationUserContractsOptions,
  queryOptions?: UseAppQueryOptions<ModulationUserContractSchema[]>
) => {
  return useQuery({
    ...queryOptions,
    queryKey: modulationUserContractsCachekeys.all(
      request?.pathParams?.membership_id
    ),
    queryFn: () => fetchAllModulationUserContracts(request)
  })
}

export const useFetchCurrentModulationUserContract = (
  request: FetchAllModulationUserContractsOptions,
  queryOptions?: UseAppQueryOptions<ModulationUserContractSchema[]>
) =>
  useFetchModulationUserContracts(request, {
    ...queryOptions,
    select: data =>
      data?.filter(
        (muc: ModulationUserContractSchema) =>
          moment(today).isSameOrAfter(muc.interval_begin) &&
          moment(today).isSameOrBefore(muc.interval_end)
      )
  })?.data?.[0]

export const useFetchPastModulationUserContracts = (
  request: FetchAllModulationUserContractsOptions,
  queryOptions?: UseAppQueryOptions<ModulationUserContractSchema[]>
) =>
  useFetchModulationUserContracts(request, {
    ...queryOptions,
    select: data =>
      data?.filter((muc: ModulationUserContractSchema) =>
        moment(today).isAfter(muc.interval_end)
      )
  })?.data

export const useFetchFutureModulationUserContracts = (
  request: FetchAllModulationUserContractsOptions,
  queryOptions?: UseAppQueryOptions<ModulationUserContractSchema[]>
) =>
  useFetchModulationUserContracts(request, {
    ...queryOptions,
    select: data =>
      data?.filter((muc: ModulationUserContractSchema) =>
        moment(today).isBefore(muc.interval_begin)
      )
  })?.data
