import { useQuery } from '@tanstack/react-query'

import type { UseAppQueryOptions } from '../../../types'
import type { GetUserConfirmationOptions } from '../auth.api'
import { getUserConfirmation } from '../auth.api'
import { authCacheKeys } from '../auth.cacheKeys'

export const useGetUserConfirmation = (
  request: GetUserConfirmationOptions,
  queryOptions?: UseAppQueryOptions<null>
) => {
  return useQuery({
    ...queryOptions,
    queryKey: authCacheKeys.getUserConfirmation(),
    queryFn: () => getUserConfirmation(request)
  })
}
