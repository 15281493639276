import { EmptyContent, imagesUrls } from '@libs/ui/ds'
import { useCommonAppTranslation } from '@libs/utils/translations'

const LOCAL_STORAGE_KEY = 'already-force-refreshed'
const FIVE_SECONDS = 5000

type ExpiryItem = {
  expiry: number
}

const setWithExpiry = () => {
  const now = new Date()
  const item: ExpiryItem = {
    expiry: now.getTime() + FIVE_SECONDS
  }

  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(item))
}

const getWithExpiry = () => {
  const item = localStorage.getItem(LOCAL_STORAGE_KEY)

  if (!item) {
    return null
  }

  const alreadyForceRefreshed: ExpiryItem = JSON.parse(item)
  const now = new Date()

  if (now.getTime() > alreadyForceRefreshed.expiry) {
    localStorage.removeItem(LOCAL_STORAGE_KEY)
    return null
  }

  return true
}

export const OutdatedError = () => {
  const { t } = useCommonAppTranslation()

  const alreadyForceRefreshed = getWithExpiry() || false

  if (!alreadyForceRefreshed) {
    setWithExpiry()
    window.location.reload()

    return null
  }

  return (
    <EmptyContent
      title={t('pagesErrors.outdatedError.title')}
      desc={t('pagesErrors.outdatedError.description')}
      imageSrc={imagesUrls.illustrations.buoy}
      action={{
        onClick: () => window.location.reload(),
        label: t('pagesErrors.outdatedError.label')
      }}
    />
  )
}
