import type { FormatterParams } from '../../types'

import { isoName } from './isoName'

/**
 * Given a firstname or lastname, returns an ISO name
 * Capitalize first letter of names or composed names
 *
 * Input: georges sand
 * Output: Georges Sand
 */
export function isoLastName({ value = '' }: FormatterParams<string>) {
  return isoName({ value }).toUpperCase()
}

export default {
  format: isoLastName
}
