import type { BaseComponent } from '@libs/ui/ds/lib/internals'
import type { FC } from 'react'
import { memo } from 'react'

import type { NotificationParams } from '../notifications.types'

import { toastItemClasses } from './ToastItemClasses'
import { ToastItemIcon } from './ToastItemIcon'

type ToastItemProps = BaseComponent &
  Pick<NotificationParams, 'title' | 'message' | 'intent'>

export const ToastItem: FC<ToastItemProps> = memo(
  ({ message, intent = 'info', title, ...other }) => {
    return (
      <div
        {...other}
        data-testid={`toast-${intent}`}
        className={toastItemClasses.body()}
      >
        <ToastItemIcon intent={intent} />
        <div className={toastItemClasses.text()}>
          {title && <div className={toastItemClasses.title()}>{title}</div>}
          <div className='whitespace-pre-line'>{message}</div>
        </div>
      </div>
    )
  }
)

ToastItem.displayName = 'ToastItem'
