import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import React from 'react'

import { Absolute } from '../../../../primitiveLayouts/Absolute/base/Absolute'
import { Spinner } from '../subs/Spinner'

import type { LoaderOverlayProps } from './LoaderOverlay.types'

const loaderOverlayCVA = {
  root: cva(['absolute flex size-full items-center justify-center']),
  overlay: cva(['absolute z-10 size-full bg-neutral-50/60']),
  spinnerZ: cva(['z-20'])
}

export const LoaderOverlay: FC<LoaderOverlayProps> = ({
  loaderProps,
  className,
  ...other
}) => {
  const currentLoaderProps = {
    ...loaderProps,
    theme: loaderProps?.theme || 'dark'
  }

  return (
    <Absolute
      {...other}
      x='center'
      y='center'
      className={loaderOverlayCVA.root({ className })}
    >
      <div className={loaderOverlayCVA.overlay()} />
      <div className={loaderOverlayCVA.spinnerZ()}>
        <Spinner {...currentLoaderProps} />
      </div>
    </Absolute>
  )
}
