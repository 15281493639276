import { Loader, SearchInput } from '@libs/ui/ds'
import { useEffect, useRef, type FC } from 'react'

type Props = {
  dropdownOpened?: boolean
  loading?: boolean
  search: {
    searchValue: string
    setSearchValue: (value: string, ...payload: any[]) => void
    resetSearchValue: () => void
  }
}

export const AutocompleteSearchInput: FC<Props> = ({
  dropdownOpened,
  search,
  loading
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (dropdownOpened) {
      inputRef.current?.focus()
    }
  }, [dropdownOpened])

  return (
    <div className='sticky top-0 z-10 bg-neutral-50'>
      <SearchInput
        inputRef={inputRef}
        autoFocus
        value={search.searchValue}
        onChange={e => search.setSearchValue(e.target.value)}
        data-testid='autocomplete-search-input'
        rightSection={loading ? <Loader size='xs' /> : undefined}
      />
    </div>
  )
}
