import { useQuery } from '@tanstack/react-query'

import type { UseAppQueryOptions } from '../../../../../types'
import type { FetchFloatingBreaksWarningOptions } from '../shift.break.api'
import { fetchFloatingBreaksWarning } from '../shift.break.api'
import { shiftBreakCacheKeys } from '../shift.break.cacheKeys'

export const useFetchFloatingBreaksWarning = (
  request: FetchFloatingBreaksWarningOptions,
  queryOptions?: UseAppQueryOptions<{ warning: boolean }>
) => {
  return useQuery({
    ...queryOptions,
    queryKey: shiftBreakCacheKeys.warning(request?.queryParams),
    queryFn: () => fetchFloatingBreaksWarning(request),
    enabled:
      !!request?.queryParams?.shift_ends_at &&
      !!request?.queryParams?.shift_starts_at &&
      !!queryOptions?.enabled &&
      !!request?.queryParams?.user_contract_id
  })
}
