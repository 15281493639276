import type { TFunction } from 'i18next'
import { isPlainObject, isString, values } from 'lodash-es'
import type { FieldErrors } from 'react-hook-form'

/**
 * React-hook-form have inconsistent data structure for fieldArray
 * In order to format and display error for each field item we need to get the parent inputName.
 * Following the convetion: inputName=`${name}[idx]` this function will return the parent inputName
 */
const findRootInputName = (inputName = '') => {
  // replace 'hello[123]' to 'hello'
  return inputName.replace(/\[[0-9]*\].+/g, '')
}

const findFirstNestedError = (errObj: any) => {
  if (errObj?.message) {
    return errObj.message
  }
  const firstKey = values(errObj)[0]
  return firstKey ? findFirstNestedError(firstKey) : null
}

type FormatErrorParams = {
  inputName: string
  raw?: boolean
  index?: number
  errors: FieldErrors
  t?: TFunction
}

/**
 * Parse and format the error message from react-hook-form/yup
 * Helps formatting combined message form mixed/array validation,
 * By default it will just take the first error from the array
 *
 * If raw option is set to true the error object is returned
 */
export function formatErrors({
  inputName,
  raw = false,
  index = 0,
  errors,
  t
}: FormatErrorParams): string | undefined {
  if (!errors) {
    return
  }

  /**
   * Return original error object
   */
  if (raw && errors[inputName]) {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    return errors[inputName].message.toString()
  }

  /**
   * Generic error message
   */
  if (errors[inputName] && errors[inputName]?.type === 'required' && t) {
    return t('validations.global.required')
  }

  /**
   * Simple error message
   */
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  if (errors[inputName] && isString(errors[inputName].message)) {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    return errors[inputName].message.toString()
  }

  /**
   * Mixed error message (basically for nested objects)
   */
  if (isPlainObject(errors[inputName])) {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    const [firstErr]: any = Object.values(errors[inputName])
    return (
      (firstErr && firstErr.message) || findFirstNestedError(errors[inputName])
    )
  }

  /**
   * FieldArray item error
   */
  if (errors[findRootInputName(inputName)]) {
    const rootName = findRootInputName(inputName)
    if (!errors[rootName]?.[index]) {
      return
    }

    const [firstErr]: any = Object.values(errors[rootName]?.[index])
    return firstErr?.message || firstErr
  }
}
