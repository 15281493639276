import { useMutation } from '@tanstack/react-query'

import type { ApiErrorResponse, UseAppMutationOptions } from '../../../../types'
import type { DeletePlannable } from '../monthly.api'
import { deleteShift } from '../monthly.api'
import { monthlyServiceCacheKeys } from '../monthly.cacheKeys'

export const useDeleteShift = (
  mutationOpts?: UseAppMutationOptions<null, ApiErrorResponse, DeletePlannable>
) => {
  return useMutation<null, ApiErrorResponse, DeletePlannable>({
    ...mutationOpts,
    mutationKey: monthlyServiceCacheKeys.shift.delete(),
    mutationFn: (opts: DeletePlannable) => deleteShift(opts)
  })
}
