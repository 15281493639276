export const ViewOffIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.3685 2.08779C18.6807 2.41302 18.6702 2.92978 18.3449 3.24201L3.0388 17.9359C2.71357 18.2481 2.19681 18.2376 1.88458 17.9123C1.57236 17.5871 1.58291 17.0703 1.90814 16.7581L17.2143 2.06423C17.5395 1.75201 18.0563 1.76255 18.3685 2.08779Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.6665 6.97272C15.9319 6.60821 16.4424 6.5278 16.8069 6.79312C17.763 7.489 18.6447 8.28165 19.438 9.1585C19.7992 9.55651 19.9993 10.0748 19.9993 10.6123C19.9993 11.1501 19.799 11.6685 19.4375 12.0666C17.2881 14.4426 13.6875 16.9977 9.99501 16.9389C9.08388 16.9423 8.17759 16.8059 7.3078 16.5344C6.87743 16.4001 6.63744 15.9423 6.77177 15.5119C6.90611 15.0816 7.36388 14.8416 7.79425 14.9759C8.50691 15.1984 9.24956 15.3098 9.99611 15.3062L10.0139 15.3063C13.0119 15.3575 16.1988 13.2132 18.2273 10.9708L18.2287 10.9693C18.3175 10.8716 18.3667 10.7443 18.3667 10.6123C18.3667 10.4803 18.3175 10.353 18.2287 10.2553L18.2273 10.2539C17.5093 9.46029 16.7114 8.74291 15.8461 8.11312C15.4816 7.8478 15.4012 7.33723 15.6665 6.97272Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0114 4.28585C11.5855 4.30277 13.1337 4.68927 14.531 5.41422C14.9312 5.62184 15.0873 6.11456 14.8797 6.51476C14.6721 6.91495 14.1794 7.07107 13.7792 6.86345C12.6081 6.25592 11.3107 5.93219 9.99146 5.91838L9.98572 5.9183C8.54403 5.89307 7.01496 6.39081 5.56185 7.20928C4.11341 8.02511 2.78903 9.13278 1.77214 10.2545L1.77134 10.2553C1.68253 10.353 1.63332 10.4803 1.63332 10.6123C1.63332 10.7443 1.68253 10.8716 1.77134 10.9693L1.7758 10.9742C2.87693 12.2051 4.16759 13.2524 5.59904 14.0762C5.98979 14.3011 6.12424 14.8002 5.89935 15.1909C5.67446 15.5817 5.17539 15.7161 4.78464 15.4912C3.20336 14.5812 1.7775 13.4245 0.560889 12.0649C0.200383 11.667 0.000671387 11.1493 0.000671387 10.6123C0.000671387 10.0743 0.201147 9.55561 0.562958 9.15745C1.68479 7.92011 3.14393 6.69735 4.76061 5.78676C6.37194 4.87917 8.18875 4.25465 10.0114 4.28585Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5369 8.83817C11.0958 8.49207 10.5447 8.31687 9.98473 8.34478C9.42481 8.37269 8.8938 8.60185 8.48938 8.99009C8.08495 9.37834 7.83432 9.89956 7.78359 10.4579C7.73285 11.0162 7.88543 11.5741 8.21324 12.0288C8.47687 12.3946 8.3941 12.9048 8.02836 13.1684C7.66263 13.432 7.15243 13.3493 6.8888 12.9835C6.33094 12.2096 6.0713 11.2602 6.15763 10.3101C6.24397 9.36001 6.67048 8.47302 7.35871 7.81232C8.04694 7.15162 8.95059 6.76165 9.90344 6.71415C10.8563 6.66665 11.7943 6.96481 12.5448 7.55378C12.8995 7.83211 12.9613 8.34526 12.683 8.69994C12.4047 9.05461 11.8915 9.1165 11.5369 8.83817Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.0612 9.79598C13.5121 9.79598 13.8776 10.1615 13.8776 10.6123C13.8776 11.1215 13.7773 11.6257 13.5824 12.0962C13.3875 12.5666 13.1019 12.9941 12.7418 13.3541C12.3818 13.7142 11.9543 13.9998 11.4839 14.1947C11.0134 14.3896 10.5092 14.4899 10 14.4899C9.54916 14.4899 9.18368 14.1244 9.18368 13.6735C9.18368 13.2227 9.54916 12.8572 10 12.8572C10.2948 12.8572 10.5867 12.7991 10.8591 12.6863C11.1315 12.5735 11.3789 12.4081 11.5874 12.1997C11.7958 11.9912 11.9612 11.7438 12.074 11.4714C12.1868 11.199 12.2449 10.9071 12.2449 10.6123C12.2449 10.1615 12.6104 9.79598 13.0612 9.79598Z'
        fill='currentColor'
      />
    </svg>
  )
}
