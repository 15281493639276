import type { MembershipSchema, Model } from '@libs/data-access/entities'
import { schema } from 'normalizr'

export type MembershipModel = Model<
  MembershipSchema & {
    avatar_urls: { medium: string; small: string; thumb: string }
    minor_employee: boolean
  },
  {}
>

const Membership = new schema.Entity<MembershipModel>('memberships', {})

export default Membership
