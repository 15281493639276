import { put } from 'redux-saga/effects'
import { createSliceSaga, SagaType } from 'redux-toolkit-saga'
import { apiRequest } from 'snap-redux/infra/http'
import { fetchLocations } from 'snap-redux/modules/locations'

import { paidLeaveLedgerOperationReducerActions } from './reducer'

const paidLeaveLedgerOperation = createSliceSaga({
  name: 'sagas/contracts',
  caseSagas: {
    /**
     * @deprecated use {@link fetchPaidLeaveLedgerOpsData} instead.
     */
    *getPaidLeaveLedgerOps({ payload }): Generator<any, void | unknown, any> {
      try {
        const {
          locationId,
          paidLeaveAcquisitionPeriodIds,
          userContractIds,
          page
        } = payload

        yield put(paidLeaveLedgerOperationReducerActions.setLoading(true))

        const paidLeaveLedgerOperations = yield apiRequest({
          route: 'getPaidLeaveLedgerOp',
          params: {
            location_id: locationId
          },
          queryParams: {
            paid_leave_acquisition_period_ids: paidLeaveAcquisitionPeriodIds,
            user_contract_ids: [userContractIds],
            per_page: 20,
            page: page || 1
          }
        })

        yield put(
          paidLeaveLedgerOperationReducerActions.setData({
            data: paidLeaveLedgerOperations
          })
        )
      } catch (e) {
        return e
      }
    },
    *patchPaidLeaveLedger({ payload }) {
      const { locationId, accountId } = payload

      yield apiRequest({
        route: 'patchPaidLeaveLedger',
        params: {
          location_id: locationId
        },
        queryParams: {
          account_id: accountId
        }
      })

      yield put(
        // @ts-ignore migration from js(x) to ts(x)
        fetchLocations(accountId, {
          includes: [
            'paid_leave_acquisition_periods',
            'paid_leave_configuration'
          ],
          skipThrottled: true
        })
      )
    }
  },
  sagaType: SagaType.Watch
})

export const { getPaidLeaveLedgerOps, patchPaidLeaveLedger } =
  paidLeaveLedgerOperation.actions

export default paidLeaveLedgerOperation.saga
