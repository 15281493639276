import {
  AddIcon,
  CloseIcon,
  NavigationMenuHorizontalBoldIcon
} from '@libs/ui/ds/assets/icons'
import { useDSTranslation } from '@libs/ui/ds/lib/internals'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'

import { Button } from '../../../../buttons/Button/base/Button'
import { Flex } from '../../../../primitiveLayouts/Flex/base/Flex'
import { IconWrap } from '../../../IconWrap/base/IconWrap'
import { useTimelineContext } from '../Timeline.context'

import type { ShowButtonProps } from './ShowButton.types'

const showButtonCVA = {
  root: cva(['items-center gap-6']),
  icon: cva([
    'flex rounded-full border-2 border-solid border-brand-green-700 bg-neutral-50 p-2 text-brand-green-700'
  ])
}

const ShowButton: FC<ShowButtonProps> = ({ onClick, ...other }) => {
  const { t } = useDSTranslation()
  const ctx = useTimelineContext()

  return (
    <Flex row className={showButtonCVA.root()}>
      <div className={showButtonCVA.icon()}>
        <IconWrap icon={<NavigationMenuHorizontalBoldIcon />} size='s' />
      </div>
      <Button
        data-testid={`${other['data-testid']}-button`}
        variant='tertiary'
        intent='neutral-dark'
        rightIcon={ctx?.showMore ? <CloseIcon /> : <AddIcon />}
        onClick={e => {
          e.preventDefault()
          if (onClick) {
            onClick(e)
          }
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          ctx.setShowMore(!ctx.showMore)
        }}
      >
        {t(`ds.components.timeline.${ctx?.showMore ? 'showLess' : 'showMore'}`)}
      </Button>
    </Flex>
  )
}

export default ShowButton
