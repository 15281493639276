import { createSelector } from 'reselect'

import { getIsoDaysInWeek } from '../../../timeRange'
import { payPeriodsBaseSelectors } from '../payPeriodsBaseSelectors'
import { computeLockedDays } from '../utils/computeLockedDays'

/**
 * Return a map with [isoDays] as keys and [isLocked] as value
 * The isLocked property is based on the payPeriod entity
 */
export const getLockedDaysInWeek = createSelector(
  payPeriodsBaseSelectors.getCachedList,
  getIsoDaysInWeek,
  computeLockedDays
)
