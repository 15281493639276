import { inputFileUtility, useDSTranslation } from '@libs/ui/ds/lib/internals'
import { Dropzone as MantineDropzone } from '@mantine/dropzone'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import { forwardRef } from 'react'

import { InputWrap } from '../../../forms/InputWrap/base/InputWrap'

import type { DropzoneProps } from './Dropzone.types'
import { DropzoneFileList } from './subs/DropzoneFileList'
import { DropzoneHelperText } from './subs/DropzoneHelperText'
import { DropzoneLabel } from './subs/DropzoneLabel'
import { useDropzoneErrorMessageUncontrolled } from './utils/useDropzoneErrorMessageUncontrolled'
import { useFilesState } from './utils/useFilesState'

const dropzoneCVA = {
  wrap: cva(['flex w-full flex-col gap-4']),
  root: cva(
    [
      'group/dropzone relative rounded-lg',
      'border-1 border-dashed border-neutral-300 hover:bg-neutral-100',
      '[&[data-accept]]:!border-brand-green-700 [&[data-accept]]:!bg-brand-green-100 [&[data-accept]]:!text-brand-green-700',
      '[&[data-reject]]:!border-semantic-red-700 [&[data-reject]]:!bg-semantic-red-100 [&[data-reject]]:!text-semantic-red-700'
    ],
    {
      variants: {
        fillWidth: {
          true: 'w-full',
          false: 'w-fit'
        },
        size: {
          s: 'gap-1 px-6 py-8',
          m: 'gap-4 px-6 py-16'
        },
        disabled: {
          true: 'cursor-default bg-neutral-100',
          false: 'cursor-pointer bg-neutral-50'
        }
      }
    }
  ),
  inner: cva(['pointer-events-none'])
}

export const BaseDropzone: FC<DropzoneProps> = forwardRef<
  HTMLDivElement,
  DropzoneProps
>(
  (
    {
      className,
      title,
      info,
      value,
      onChange,
      onReject,
      maxSize,
      multiple = true,
      validator,
      accept,
      loading,
      disabled = false,
      name,
      size = 'm',

      /* inputwrap props */
      label,
      desc,
      errorMessage,
      helperText,
      required,
      fillWidth = true,
      labelRightSection,
      ...other
    },
    ref
  ) => {
    const { t } = useDSTranslation()

    const { internalErrorMessage, setInternalErrorMessage } =
      useDropzoneErrorMessageUncontrolled({ errorMessage })
    const { files, addFile, deleteFile } = useFilesState({
      onChange,
      value,
      multiple
    })

    return (
      <div {...other} className={dropzoneCVA.wrap({ className })} ref={ref}>
        <InputWrap
          label={label}
          desc={desc}
          errorMessage={internalErrorMessage}
          helperText={helperText}
          required={required}
          htmlFor={name}
          fillWidth={fillWidth}
          className={className}
          labelRightSection={labelRightSection}
          field={
            <MantineDropzone
              maxFiles={multiple ? 0 : 1}
              name={name}
              validator={validator}
              onDrop={e => {
                setInternalErrorMessage(undefined)
                addFile(e)
              }}
              onReject={e => {
                setInternalErrorMessage(
                  t('ds.components.dropzone.rejection.feedback')
                )
                if (e && onReject) {
                  onReject(e)
                }
              }}
              multiple={multiple}
              disabled={disabled}
              accept={accept}
              classNames={{
                root: dropzoneCVA.root({ disabled, size, fillWidth }),
                inner: dropzoneCVA.inner()
              }}
              {...(maxSize && {
                maxSize: inputFileUtility.convertFileSize(maxSize)
              })}
            >
              <div>
                <DropzoneLabel
                  size={size}
                  loading={loading}
                  title={title}
                  desc={info}
                />
                <DropzoneHelperText
                  size={size}
                  accept={accept}
                  maxSize={maxSize}
                />
              </div>
            </MantineDropzone>
          }
        />
        {Boolean(files?.length) && (
          <DropzoneFileList
            size={size}
            accept={accept}
            files={files}
            deleteFile={deleteFile}
            disabled={disabled}
          />
        )}
      </div>
    )
  }
)

type CompoundDropzoneType = {
  Accept: typeof MantineDropzone.Accept
  Idle: typeof MantineDropzone.Idle
  Reject: typeof MantineDropzone.Reject
}

const TypedDropzone = BaseDropzone as typeof BaseDropzone & CompoundDropzoneType

TypedDropzone.displayName = 'Dropzone'
TypedDropzone.Accept = MantineDropzone.Accept
TypedDropzone.Idle = MantineDropzone.Idle
TypedDropzone.Reject = MantineDropzone.Reject

export const Dropzone = TypedDropzone
export type { DropzoneProps } from './Dropzone.types'
export type { FileRejection, FileWithPath } from '@mantine/dropzone'
