import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_COUNTERS_BY_CONTRACT_ID,
  FETCH_COUNTERS_BY_CONTRACT_ID_SUCCEEDED,
  FETCH_COUNTERS_BY_CONTRACT_ID_FAILED,
  FETCH_COUNTERS_BY_LOCATION_ID,
  FETCH_COUNTERS_BY_LOCATION_ID_SUCCEEDED,
  FETCH_COUNTERS_BY_LOCATION_ID_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchCountersByContractId = (
  contractId,
  params,
  resolve,
  reject
) => ({
  type: FETCH_COUNTERS_BY_CONTRACT_ID,
  contractId,
  params,
  resolve,
  reject
})

export const fetchCountersByLocationId = (
  locationId,
  params,
  resolve,
  reject
) => ({
  type: FETCH_COUNTERS_BY_LOCATION_ID,
  locationId,
  params,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const fetchCountersByContractIdSagaEntity = {
  success: (data, contractId) => ({
    type: FETCH_COUNTERS_BY_CONTRACT_ID_SUCCEEDED,
    data,
    contractId
  }),
  failure: (error, contractId) => ({
    type: FETCH_COUNTERS_BY_CONTRACT_ID_FAILED,
    error,
    contractId
  }),

  fetchAPI: (contractId, options, params) =>
    callAPI(
      `/user_contracts/${contractId}/counters?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchCountersByContractId({
  contractId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    fetchCountersByContractIdSagaEntity,
    contractId,
    params,
    undefined,
    resolve,
    reject
  )
}

const fetchCountersByLocationIdSagaEntity = {
  success: (data, locationId) => ({
    type: FETCH_COUNTERS_BY_LOCATION_ID_SUCCEEDED,
    data,
    locationId
  }),
  failure: (error, locationId) => ({
    type: FETCH_COUNTERS_BY_LOCATION_ID_FAILED,
    error,
    locationId
  }),

  fetchAPI: (locationId, options, params) =>
    callAPI(
      `/locations/${locationId}/counters?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchCountersByLocationId({
  locationId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    fetchCountersByLocationIdSagaEntity,
    locationId,
    params,
    undefined,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const Counter = Immutable.Record({
  id: null,
  user_contract_id: null,
  counter_type: null,
  balance_in_minutes: null,
  effective_year: null,
  additional_info: null
})

const counterForeignKeys = ['user_contract_id', 'counter_type']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function counters(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_COUNTERS_BY_CONTRACT_ID:
    case FETCH_COUNTERS_BY_LOCATION_ID: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_COUNTERS_BY_LOCATION_ID_FAILED:
    case FETCH_COUNTERS_BY_CONTRACT_ID_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_COUNTERS_BY_LOCATION_ID_SUCCEEDED:
    case FETCH_COUNTERS_BY_CONTRACT_ID_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const counters = Immutable.fromJS(action.data)

      return mergeRecords(
        state,
        Counter,
        counters,
        // @ts-ignore migration from js(x) to ts(x)
        counterForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    default:
      return state
  }
}

export default filterActions(counters, [
  FETCH_COUNTERS_BY_CONTRACT_ID,
  FETCH_COUNTERS_BY_LOCATION_ID,
  FETCH_COUNTERS_BY_LOCATION_ID_FAILED,
  FETCH_COUNTERS_BY_CONTRACT_ID_FAILED,
  FETCH_COUNTERS_BY_LOCATION_ID_SUCCEEDED,
  FETCH_COUNTERS_BY_CONTRACT_ID_SUCCEEDED
])
