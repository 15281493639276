import { createSafeContext } from '@libs/ui/ds/lib/internals'

import type { ModalProps } from './Modal.types'

export type ModalContext = {
  opened?: ModalProps['opened']
  onClose?: ModalProps['onClose']
  disableClose?: ModalProps['disableClose']
  height?: ModalProps['height']
  variant?: ModalProps['variant']
}

export const [ModalProvider, useModalContext] = createSafeContext<ModalContext>(
  {
    context: 'Modal_UI'
  }
)
