import { cva } from 'class-variance-authority'
import { forwardRef, memo } from 'react'

import { Flex } from '../../../primitiveLayouts/Flex/base/Flex'

import type { FieldSetProps } from './FieldSet.types'

const fieldSetCVA = {
  root: cva(['rounded-lg bg-neutral-100'], {
    variants: {
      size: {
        m: 'px-6 py-5'
      },
      fillWidth: {
        true: 'w-full'
      }
    }
  })
}

export const FieldSet = memo(
  forwardRef<HTMLDivElement, FieldSetProps>(
    ({ size = 'm', fillWidth = true, className, children, ...other }, ref) => {
      return (
        <Flex
          {...other}
          ref={ref}
          className={fieldSetCVA.root({ className, fillWidth, size })}
        >
          {children}
        </Flex>
      )
    }
  )
)

FieldSet.displayName = 'FieldSet'
export type { FieldSetProps } from './FieldSet.types'
