import type { FC } from 'react'
import React from 'react'

import type { ConditionalWrapProps } from './ConditionalWrap.types'

export const ConditionalWrap: FC<ConditionalWrapProps> = ({
  condition,
  children,
  wrap
}) => (condition ? React.cloneElement(wrap(children)) : children)
