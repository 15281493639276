import moment from 'moment'

import type { FormatterParams } from '../../types'

type DateFormatter = FormatterParams<moment.MomentInput>

// 2020-12-03
export const system = {
  format: ({ value }: DateFormatter) => moment(value).format('YYYY-MM-DD')
}

// When close to now: Today at 9:06 AM "
// When old:          06/05/2020"
export const calendar = {
  format: ({ value }: DateFormatter) => moment(value).calendar()
}

// Thu 03
export const dayShortAndNumber = {
  format: ({ value }: DateFormatter) => moment(value).format('ddd DD')
}

// 12
export const dayNumber = {
  format: ({ value }: DateFormatter) => moment(value).format('DD')
}

// mo, tu, we, th, fr, sa, su
export const dayShort = {
  format: ({ value }: DateFormatter) => moment(value).format('dd')
}

// jan., fev., mars, avr., mai, ...
export const monthShort = {
  format: ({ value }: DateFormatter) => moment(value).format('MMM')
}

// Janvier 2023
export const monthAndYear = {
  format: ({ value }) => moment(value).format('MMMM YYYY')
}

// Jan 2023
export const monthShortAndYear = {
  format: ({ value }) => moment(value).format('MMM YYYY')
}

// February 23, 2021 | 23 février 2021
export const dateWithYear = {
  format: ({ value }: DateFormatter) => moment(value).format('LL')
}

// 03/12
export const dateWithoutYearShort = {
  format: ({ value }: DateFormatter) => moment(value).format('DD/MM')
}

// 03 december
export const dateWithoutYear = {
  format: ({ value }: DateFormatter) => moment(value).format('DD MMMM')
}

// 3 dec
export const dayShortMonth = {
  format: ({ value }: DateFormatter) => moment(value).format('D MMM')
}

// 3 dec 2022
export const dayShortMonthWithYear = {
  format: ({ value }: DateFormatter) => moment(value).format('D MMM YYYY')
}

// Thursday 3rd December
export const literalDate = {
  format: ({ value }: DateFormatter) => moment(value).format('dddd Do MMMM')
}

// 03/12/2020
export const shortDate = {
  format: ({ value }: DateFormatter) => moment(value).format('DD/MM/YYYY')
}

// 22 décembre 2022 11:19
export const shortDateWithTime = {
  format: ({ value }: DateFormatter) => {
    return `${moment(value).format('LLL')}`
  }
}

// Thursday 3rd Dec
export const shortLiteralDate = {
  format: ({ value }: DateFormatter) => moment(value).format('dddd Do MMM')
}

// Thursday 3rd Dec 2017
export const longLiteralDate = {
  format: ({ value }: DateFormatter) => moment(value).format('dddd DD MMM YYYY')
}

// Thursday 3rd Dec 2017
export const longLiteralDateWithDayShort = {
  format: ({ value }: DateFormatter) => moment(value).format('ddd DD MMM YYYY')
}

// 3rd Dec 2020
export const shortLiteralYear = {
  format: ({ value }: DateFormatter) => moment(value).format('Do MMM YYYY')
}

// Thu 3rd
export const shortWeekDay = {
  format: ({ value }: DateFormatter) => moment(value).format('ddd Do')
}

// 49
export const weekNumber = {
  format: ({ value }: DateFormatter) => moment(value).format('WW')
}

// 2020-W49
export const yearNWeek = {
  format: ({ value }: DateFormatter) => moment(value).format('YYYY-[W]WW')
}

export const dayMonthHour = {
  format: ({ value }: DateFormatter) =>
    moment(value).format('dddd Do [à] HH[h]mm')
}

// 21st june 2021
export const dayMonthYear = {
  format: ({ value }: DateFormatter) => moment(value).format('Do MMMM YYYY')
}

// samedi 01
export const weekday = {
  format: ({ value }: DateFormatter) => moment(value).format('dddd DD')
}

// 13:48
export const shortHourMinute = {
  format: ({ value }) => moment(value).format('HH:mm')
}

// 13h48
export const hourMinute = {
  format: ({ value }) => moment(value).format('H[h]mm')
}

export const fullDateWithHour = {
  format: ({ value }) => moment(value).format('DD MMM Y [à] HH[:]mm')
}

export const fullDateWithHourAndSeconds = {
  format: ({ value }) => moment(value).format('DD MMM Y [à] HH[:]mm[:]ss')
}

// 09/04/1986 10:39 => date will use the local (ex: MM/DD/YY for en and DD/MM/YY for fr)
export const shortDateWithHourAndMinute = {
  format: ({ value }) => moment(value).format('L HH:mm')
}
