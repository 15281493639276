import { useMemoSelector } from '@spa/redux/hooks'
import { createSelector } from 'reselect'
import type { Shift } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import { rowsSelectors } from '../../rows'
import { shiftsBaseSelectors } from '../shiftsBaseSelectors'

/**
 * This selector aims to return shifts that are up to date without any unplanned shifts
 *
 * When deleting shifts, we don't delete the shifts entries in the store, resulting in stale data.
 * The fresh data relations are stored in the rows entity
 */
export const getFreshShiftsListWithoutUnplanned = createSelector(
  shiftsBaseSelectors.getCachedMap,
  rowsSelectors.getCachedList,
  (shiftsMap, rowsList) => {
    const displayedShiftList = rowsList
      .flatMap(row => row.shifts.map(shiftId => shiftsMap[shiftId]))
      .filter(shift => shift?.planification_type !== 'unscheduled')
    return displayedShiftList as Shift[]
  }
)

export const useGetFreshShiftsListWithoutUnplanned = () => {
  return useMemoSelector(getFreshShiftsListWithoutUnplanned)
}
