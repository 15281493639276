import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { AddonsModel } from '../models/AddonsModel'

const initialState: AddonsModel = {
  additional_users: [],
  formulas: [],
  stand_alones: []
}

const entitiesSlice = createSlice({
  name: 'billing/addons/entities',
  initialState,
  reducers: {
    setAddons(state, action: PayloadAction<AddonsModel>) {
      return { ...state, ...action.payload }
    }
  }
})

export const { setAddons } = entitiesSlice.actions
export default entitiesSlice.reducer
