import type { ReduxState } from '@spa/redux/ReduxState'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'

import { getDisplayFilters } from '../../../displayFilters'
import { membershipsSelectors } from '../../memberships'
import { rowsSelectors } from '../../rows'
import { getSortedTeamContracts } from '../getSortedTeamContracts'

import { hasContractNonScheduledShift } from './utils/hasContractNonScheduledShift'
import { hasOtherThanExtraOrInterimContractType } from './utils/hasOtherThanExtraOrInterimContractType'
import { isContractFoundBySearchValue } from './utils/isContractFoundBySearchValue'

type Params = {
  teamId: number
}

/**
 * Return filtered list of contracts that should be displayed on planning
 */
export const getDisplayedTeamContracts = createCachedSelector(
  (state: ReduxState, { teamId }: Params) =>
    getSortedTeamContracts(state, { teamId }),
  getDisplayFilters,
  rowsSelectors.getCachedMap,
  membershipsSelectors.getCachedMap,

  (sortedContracts, displayFilters, rowsMap, membershipsMap) => {
    return sortedContracts.filter(contract => {
      const shiftIds = rowsMap?.[contract.id]?.shifts
      const membership = membershipsMap?.[contract.membership_id]
      return (
        isContractFoundBySearchValue(
          membership,
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          displayFilters.searchContract
        ) &&
        !(
          hasContractNonScheduledShift(
            // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
            displayFilters.hideNonScheduled,
            shiftIds
          ) ||
          hasOtherThanExtraOrInterimContractType(
            contract,
            // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
            displayFilters.showExtraOrInterimOnly
          )
        )
      )
    })
  }
)((_, { teamId }) => fallbackToDefaultCacheKey(teamId))
