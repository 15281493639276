import type { TableProps } from '../../Table.types'

type Params = {
  states: TableProps['states']
  data: TableProps['data']
}

type Status = 'loading' | 'empty' | 'error' | 'idle'

// @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
export const useTableComputedStatus = ({ states, data }: Params): Status => {
  if (states?.loading) {
    return 'loading'
  }

  if (states?.error && states?.errorContent) {
    return 'error'
  }

  if (!data?.length && states?.emptyContent) {
    return 'empty'
  }

  return 'idle'
}
