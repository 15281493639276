import { useContext, useMemo } from 'react'

import { AuthContext } from '../AuthContext'

export const useAuthState = () => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuthState must be used within an AuthProvider')
  }

  const isAuthenticated = useMemo(() => {
    return Boolean(context.auth.accountId) && Boolean(context.auth.userId)
  }, [context])

  return { ...context, isAuthenticated }
}
