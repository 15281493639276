import { pick } from 'lodash-es'
import { put, select } from 'redux-saga/effects'
import type { Shift } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'
import { createShiftTemplate } from 'snap-redux/modules/shiftTemplates'

import { shiftsSelectors } from '../../../selectors'
import type { PayloadActionWithoutType } from '../../types/types'

import { addOneDayIfBefore, setMinimumDate } from './utils/utils'

type Params = PayloadActionWithoutType<{
  shiftId: number
  teamId: number
}>

export function* createFromShift(action: Params): any {
  const {
    payload: { shiftId, teamId }
  } = action
  const shift: Shift = yield select(state =>
    shiftsSelectors.getCachedById(state, shiftId)
  )

  yield put(
    // @ts-ignore migration from js(x) to ts(x)
    createShiftTemplate({
      team_id: teamId,
      starts_at: setMinimumDate(shift.starts_at),
      ends_at: addOneDayIfBefore(setMinimumDate(shift.ends_at), {
        start: shift.starts_at
      }),
      label_id: shift.label_id,

      /**
       * Only pick specified values and omit [id] and relational
       * properties since its defined in the back-end
       */
      shift_breaks: (shift.shift_breaks || []).map(i =>
        pick(i, [
          'planned_duration',
          'planned_ends_at',
          'planned_starts_at',
          'real_duration',
          'real_ends_at',
          'real_starts_at'
        ])
      ),

      /**
       * Only pick planned meal type
       * Omit id and relational properties
       */
      shift_meals: (shift.shift_meals || []).map(i =>
        pick(i, ['planned_meal_type'])
      )
    })
  )
}
