import type { ApiErrorResponse } from '@libs/data-access/queries'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import type { ParamsDeleteDocumentTemplate } from '../modules/entities'
import { deleteDocumentTemplate } from '../modules/entities'
import { setMetaLoading, setMetaSuccess, setMetaError } from '../modules/meta'

export default function* deleteDocumentTemplateData(
  payload: ParamsDeleteDocumentTemplate
) {
  yield put(setMetaLoading())

  try {
    yield apiRequest({
      service: 'core',
      route: 'deleteDocumentTemplate',
      params: payload
    })

    yield put(deleteDocumentTemplate(payload))
    yield put(setMetaSuccess())
  } catch (err) {
    yield put(setMetaError(err as ApiErrorResponse))
  }
}
