import { useUncontrolled, useUncontrolledInput } from '@libs/ui/ds'
import { cva } from 'class-variance-authority'
import { forwardRef, memo, useState } from 'react'

import { InputWrap } from '../../../forms/InputWrap/base/InputWrap'
import { formatMaxLengthCounter } from '../../../forms/InputWrap/utils/formatMaxLengthCounter'
import { Popover } from '../../../overlays/Popover/base/Popover'
import { InputBase } from '../../InputBase/base/InputBase'
import { PasswordInputRestrictions } from '../subs/PasswordInputRestrictions/PasswordInputRestrictions'
import { PasswordInputRevealButton } from '../subs/PasswordInputRevealButton'
import { allRestrictionsMatch } from '../utils/allRestrictionsMatch'

import type { PasswordInputProps } from './PasswordInput.types'

const PasswordInputCVA = {
  input: cva([], {
    variants: {
      showArrows: {
        true: '',
        false: [
          '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none',
          '[&::-webkit-inner-spin-button]:appearance-none'
        ]
      }
    }
  })
}

const BasePasswordInput = memo(
  forwardRef<HTMLInputElement, PasswordInputProps>(
    (
      {
        label,
        desc,
        errorMessage,
        helperText,
        required,
        disabled = false,
        fillWidth,
        className,
        name,
        labelRightSection,
        placeholder,
        maxLength,
        value,
        onChange,
        classNames = {},
        disableReveal = false,
        revealed,
        onRevealedChange,
        restrictions,
        ...other
      },
      ref
    ) => {
      const [focused, setFocused] = useState(false)

      const [controlledValue, setControlledValue] = useUncontrolledInput({
        value,
        onChange
      })

      const [revealedValue, setRevealedValue] = useUncontrolled({
        value: revealed,
        onChange: onRevealedChange
      })

      return (
        <Popover
          opened={focused}
          dropdownPosition='top-start'
          dropdownWidth='l'
        >
          <Popover.Target>
            <InputWrap
              label={label}
              desc={desc}
              errorMessage={errorMessage}
              helperText={
                formatMaxLengthCounter({ value: controlledValue, maxLength }) ||
                helperText
              }
              labelRightSection={labelRightSection}
              required={required}
              disabled={disabled}
              htmlFor={name}
              fillWidth={fillWidth}
              className={className}
              field={
                <InputBase
                  ref={ref}
                  type={revealedValue ? 'text' : 'password'}
                  aria-label={label as string}
                  aria-placeholder={placeholder}
                  placeholder={placeholder}
                  error={
                    Boolean(errorMessage) ||
                    !allRestrictionsMatch(controlledValue, restrictions)
                  }
                  disabled={disabled}
                  name={name}
                  maxLength={maxLength}
                  aria-required={required}
                  required={required}
                  value={controlledValue}
                  onChange={setControlledValue}
                  rightSection={
                    disableReveal ? undefined : (
                      <PasswordInputRevealButton
                        revealed={revealedValue}
                        setRevealedValue={setRevealedValue}
                      />
                    )
                  }
                  classNames={{
                    ...classNames,
                    input: PasswordInputCVA.input({
                      className: classNames?.input
                    })
                  }}
                  {...other}
                  /**
                   * These events below handlers should be registred after props drill
                   */
                  onFocus={e => {
                    setFocused(true)
                    other?.onFocus?.(e)
                  }}
                  onBlur={e => {
                    setFocused(false)
                    other?.onBlur?.(e)
                  }}
                />
              }
            />
          </Popover.Target>
          {Boolean(restrictions?.length) && (
            <Popover.Dropdown>
              <PasswordInputRestrictions
                restrictions={restrictions}
                passwordValue={controlledValue}
              />
            </Popover.Dropdown>
          )}
        </Popover>
      )
    }
  )
)

const TypedPasswordInput = BasePasswordInput
TypedPasswordInput.displayName = 'PasswordInput'

export type { PasswordInputProps } from './PasswordInput.types'
export const PasswordInput = TypedPasswordInput
