export const FilePaperDocumentIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.45198 0.585368C3.10492 0.624544 2.7376 0.780032 2.45615 1.00692C2.0516 1.33303 1.79973 1.79126 1.73437 2.32005C1.719 2.44438 1.719 17.5557 1.73437 17.68C1.76446 17.9235 1.82022 18.1129 1.92401 18.324C2.02933 18.5383 2.14803 18.7005 2.3254 18.8723C2.61879 19.1566 2.96166 19.3301 3.37198 19.4019L3.47598 19.42H9.99998C16.2646 19.42 16.5276 19.4195 16.6157 19.4055C17.0244 19.3408 17.3854 19.1611 17.679 18.8761C17.8777 18.6832 18.0204 18.4757 18.1235 18.2297C18.1944 18.0605 18.2447 17.8684 18.2635 17.695C18.2692 17.6421 18.2719 15.9271 18.2721 12.2464C18.2723 6.36486 18.2758 6.81133 18.2272 6.59351C18.1672 6.32491 18.0316 6.0431 17.8485 5.80654C17.777 5.71414 13.1487 1.08573 13.048 1.00589C12.7518 0.771144 12.3936 0.622768 12.0303 0.58428C11.931 0.57376 3.54538 0.574824 3.45198 0.585368ZM3.58237 2.1915C3.4625 2.22982 3.36874 2.32786 3.33701 2.44805C3.32969 2.47575 3.32802 4.21954 3.32974 10.016L3.33198 17.548L3.35074 17.5946C3.38666 17.6836 3.48321 17.7751 3.57496 17.8071C3.6089 17.819 4.14166 17.8202 9.97998 17.8222C15.7728 17.8242 16.3532 17.8233 16.4058 17.8117C16.5097 17.7889 16.6082 17.7041 16.6494 17.602L16.668 17.556L16.67 12.7423L16.672 7.92847L14.682 7.92591C12.5142 7.92313 12.668 7.92631 12.452 7.87974C11.9862 7.77935 11.554 7.48982 11.2761 7.09205C11.1232 6.87333 11.009 6.606 10.9597 6.35205C10.9277 6.1869 10.928 6.20723 10.928 4.15035V2.17605L7.27798 2.17648C3.7686 2.17689 3.62622 2.17747 3.58237 2.1915ZM12.528 4.38282C12.528 5.85834 12.5293 6.03002 12.5407 6.06919C12.5733 6.18143 12.6597 6.27025 12.7721 6.30698C12.8088 6.31898 12.9513 6.31991 14.4581 6.31806L16.104 6.31605L14.316 4.52806L12.528 2.74006V4.38282Z'
        fill='currentColor'
      />
    </svg>
  )
}
