import { BaseButton } from '@libs/ui/ds/lib/components'
import { createButton } from '@libs/ui/ds/lib/components/buttons/BaseButton/utils/createButton'
import { IconWrap } from '@libs/ui/ds/lib/components/dataDisplay/IconWrap/base/IconWrap'
import { Loader } from '@libs/ui/ds/lib/components/feedback/Loader/base/Loader'
import { Absolute } from '@libs/ui/ds/lib/components/primitiveLayouts/Absolute/base/Absolute'
import { outlineFocusMixin } from '@libs/ui/ds/lib/styles/mixins/mixins'
import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'

import type { IconButtonProps } from './IconButton.types'
import { buttonCompoundClasses } from './buttonCompoundClasses'

const iconButtonCVA = {
  root: cva(
    [
      'relative flex items-center justify-center rounded-full',
      outlineFocusMixin
    ],
    {
      variants: {
        size: {
          xs: 'size-6 min-h-6 min-w-6',
          s: 'size-8 min-h-8 min-w-8',
          m: 'size-10 min-h-10 min-w-10',
          l: 'size-12 min-h-12 min-w-12',
          xl: 'size-13 min-h-13 min-w-13'
        },
        variant: {
          primary: '',
          secondary: '',
          tertiary: ''
        },
        loading: {
          true: 'pointer-events-none text-opacity-0'
        },
        intent: {
          default: '',
          danger: '',
          'neutral-dark': ''
        },
        disabled: {
          true: 'pointer-events-none'
        }
      },
      compoundVariants: [...buttonCompoundClasses]
    }
  ),
  props: {
    iconSize: {
      xs: 'xs',
      s: 's',
      m: 's',
      l: 'm',
      xl: 'm'
    }
  }
} as const

export const IconButton = createButton<IconButtonProps>(
  forwardRef<HTMLButtonElement, IconButtonProps>(
    (
      {
        className,
        size = 'm',
        intent = 'default',
        variant = 'primary',
        disabled = false,
        icon,
        loading,
        ...other
      },
      ref
    ) => {
      const classNames = iconButtonCVA.root({
        loading,
        className,
        disabled,
        intent,
        size,
        variant
      })

      return (
        <BaseButton
          ref={ref}
          disabled={disabled || loading}
          className={classNames}
          {...other}
        >
          {loading && (
            <Absolute x='center' y='center'>
              <Loader theme='dark' size='xs' />
            </Absolute>
          )}
          {icon && (
            <IconWrap icon={icon} size={iconButtonCVA.props.iconSize[size]} />
          )}
        </BaseButton>
      )
    }
  )
)

IconButton.displayName = 'Button'
export type { IconButtonProps } from './IconButton.types'
