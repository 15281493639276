import Immutable from 'immutable'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_UNREAD_NOTIFICATIONS,
  FETCH_UNREAD_NOTIFICATIONS_SUCCEEDED,
  FETCH_UNREAD_NOTIFICATIONS_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchUnreadNotifications = (membershipId, resolve, reject) => ({
  type: FETCH_UNREAD_NOTIFICATIONS,
  membershipId,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const notificationsSagaEntity = {
  success: (data, membershipId) => ({
    type: FETCH_UNREAD_NOTIFICATIONS_SUCCEEDED,
    data,
    membershipId
  }),

  failure: (error, membershipId) => ({
    type: FETCH_UNREAD_NOTIFICATIONS_FAILED,
    error,
    membershipId
  }),

  fetchAPI: (membershipId, options) =>
    callAPI(`/memberships/${membershipId}/unread_notifications`, options)
}

export function* doFetchUnreadNotifications({ membershipId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    notificationsSagaEntity,
    membershipId,
    undefined,
    undefined,
    resolve,
    reject
  )
}

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map({
    unread_conversations: 0,
    unread_timeoffs: Immutable.Map({
      paid_leave: 0,
      unpaid_leave: 0,
      temporary_unavailability: 0,
      rtt: 0
    })
  }),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function notifications(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_UNREAD_NOTIFICATIONS: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_UNREAD_NOTIFICATIONS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_UNREAD_NOTIFICATIONS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const notifications = action.data

      return state.mergeDeepIn(['data'], notifications)
    }
    default:
      return state
  }
}

export default filterActions(notifications, [
  FETCH_UNREAD_NOTIFICATIONS,
  FETCH_UNREAD_NOTIFICATIONS_SUCCEEDED,
  FETCH_UNREAD_NOTIFICATIONS_FAILED
])
