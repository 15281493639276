import type { Shift } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

/**
 * Filters shifts related to weeklyschedule id
 */
export const filterShiftsByWeeklyschedule = (
  shifts: Shift[],
  weeklyscheduleId: number
) => {
  return shifts.filter(shift => shift.weeklyschedule_id === weeklyscheduleId)
}
