import { cva } from 'class-variance-authority'
import { forwardRef, memo } from 'react'

import { Flex } from '../../../primitiveLayouts/Flex/base/Flex'

import type { InputWrapExtendedProps } from './InputWrap.types'

const inputWrapCVA = {
  root: cva(['flex flex-col gap-2'], {
    variants: {
      fillWidth: {
        true: 'w-full justify-between'
      }
    }
  }),
  labelSection: cva(['gap-0.5'], {
    variants: {
      disabled: {
        true: 'opacity-60'
      },
      disableLabelInteraction: {
        true: 'pointer-events-none'
      }
    }
  }),
  asterisk: cva(['ml-1 text-xs font-semibold leading-4 text-semantic-red-400']),
  header: cva([
    'flex min-h-4 flex-row items-center text-s leading-5 text-neutral-800'
  ]),
  label: cva(['text-s font-medium text-neutral-800']),
  desc: cva([
    'whitespace-pre-line text-xs font-normal leading-4 text-neutral-500'
  ]),
  fieldSection: cva([''], {
    variants: {
      fillWidth: {
        true: 'w-full',
        false: 'w-fit'
      }
    }
  }),
  footerSection: cva([
    'mt-2 max-w-full !flex-nowrap items-start justify-between gap-4'
  ]),
  errorMessage: cva(['text-xs font-normal leading-4 text-semantic-red-400'])
}

const BaseInputWrap = memo(
  forwardRef<HTMLInputElement, InputWrapExtendedProps>(
    (
      {
        label,
        desc,
        errorMessage,
        helperText,
        required,
        disabled = false,
        fillWidth,
        htmlFor,
        field,
        labelRightSection,
        className,
        ...other
      },
      ref
    ) => {
      const displayLabelSection =
        label || desc || errorMessage || labelRightSection
      const displayFooter = helperText || errorMessage
      return (
        <div
          {...other}
          ref={ref}
          className={inputWrapCVA.root({
            className,
            fillWidth
          })}
        >
          {displayLabelSection && (
            <Flex
              className={inputWrapCVA.labelSection({
                disabled
              })}
            >
              {label && (
                <div className={inputWrapCVA.header()}>
                  <label htmlFor={htmlFor} className={inputWrapCVA.label()}>
                    {label}
                  </label>
                  {required && (
                    <span className={inputWrapCVA.asterisk()}>*</span>
                  )}
                  {labelRightSection}
                </div>
              )}
              {desc && <span className={inputWrapCVA.desc()}>{desc}</span>}
            </Flex>
          )}
          <div className={inputWrapCVA.fieldSection({ fillWidth })}>
            {field}
            {displayFooter && (
              <Flex row className={inputWrapCVA.footerSection()}>
                <span className={inputWrapCVA.errorMessage()}>
                  {Boolean(errorMessage) && errorMessage}
                </span>
                {helperText && (
                  <div className={inputWrapCVA.desc()}>{helperText}</div>
                )}
              </Flex>
            )}
          </div>
        </div>
      )
    }
  )
)

BaseInputWrap.displayName = 'InputWrap'

export const InputWrap = BaseInputWrap
export type { InputBaseProps } from '../../../inputs/InputBase/base/InputBase.types'
