import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_DASHBOARD_KPIS,
  FETCH_DASHBOARD_KPIS_SUCCEEDED,
  FETCH_DASHBOARD_KPIS_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchDashboardKPIs = (accountId, params) => ({
  type: FETCH_DASHBOARD_KPIS,
  accountId,
  params
})

// ------------------------------------
// Sagas
// ------------------------------------

const dashboardKPISagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_DASHBOARD_KPIS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_DASHBOARD_KPIS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/dashboard_kpis?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchDasbhoardKPIs({ accountId, params }) {
  // @ts-ignore migration from js(x) to ts(x)
  yield call(fetchSagaEntity, dashboardKPISagaEntity, accountId, params)
}

// ------------------------------------
// Models
// ------------------------------------

export const DashboardKPI = Immutable.Record({
  id: null,
  kpi_type: null,
  location_id: null,
  account_id: null,
  value: null,
  day: null,
  week: null
})

const foreignKeys = ['account_id', 'location_id', 'week']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function dashboardKPIs(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_DASHBOARD_KPIS:
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    case FETCH_DASHBOARD_KPIS_FAILED:
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    case FETCH_DASHBOARD_KPIS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const kpis = Immutable.fromJS(action.data)

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, DashboardKPI, kpis, foreignKeys).mergeDeepIn(
        ['meta'],
        {
          loading: false,
          updated_at: Date.now(),
          success: true
        }
      )
    }
    default:
      return state
  }
}

export default filterActions(dashboardKPIs, [
  FETCH_DASHBOARD_KPIS,
  FETCH_DASHBOARD_KPIS_SUCCEEDED,
  FETCH_DASHBOARD_KPIS_FAILED
])
