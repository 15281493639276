import {
  type ApiErrorResponse,
  type UseAppMutationOptions
} from '@libs/data-access/queries'
import { useMutation } from '@tanstack/react-query'

import {
  deleteMembership,
  type DeleteMembershipPayloadOptions
} from '../membership.api'
import { membershipQueryKeys } from '../membership.cacheKeys'

export const useDeleteMembership = (
  mutationOptions?: UseAppMutationOptions<
    {},
    ApiErrorResponse,
    DeleteMembershipPayloadOptions
  >
) => {
  return useMutation<{}, ApiErrorResponse, DeleteMembershipPayloadOptions>({
    ...mutationOptions,
    mutationKey: membershipQueryKeys.delete_membership(),
    mutationFn: (opts: DeleteMembershipPayloadOptions) =>
      deleteMembership(opts),
    onSuccess: (...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(...args)
      }
    }
  })
}
