import Immutable from 'immutable'
import { call } from 'redux-saga/effects'
import { User } from 'snap-redux/modules/users'

import {
  CREATE_ALL_LOCATIONS_REPORT_FILE,
  CREATE_ALL_LOCATIONS_REPORT_FILE_SUCCEEDED,
  CREATE_ALL_LOCATIONS_REPORT_FILE_FAILED,
  FETCH_ALL_LOCATIONS_REPORT_FILES,
  FETCH_ALL_LOCATIONS_REPORT_FILES_SUCCEEDED,
  FETCH_ALL_LOCATIONS_REPORT_FILES_FAILED,
  DELETE_ALL_LOCATIONS_REPORT_FILE,
  DELETE_ALL_LOCATIONS_REPORT_FILE_SUCCEEDED,
  DELETE_ALL_LOCATIONS_REPORT_FILE_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords, deleteRecord } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------
export const fetchAllLocationsReportFiles = (
  payPeriodId,
  params,
  resolve,
  reject
) => ({
  type: FETCH_ALL_LOCATIONS_REPORT_FILES,
  payPeriodId,
  params,
  resolve,
  reject
})

export const createAllLocationsReportFile = (
  payPeriodId,
  reportData,
  resolve,
  reject
) => ({
  type: CREATE_ALL_LOCATIONS_REPORT_FILE,
  payPeriodId,
  reportData,
  resolve,
  reject
})

export const deleteAllLocationsReportFile = (
  payPeriodId,
  reportFileId,
  resolve,
  reject
) => ({
  type: DELETE_ALL_LOCATIONS_REPORT_FILE,
  payPeriodId,
  reportFileId,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------
const fetchAllLocationsReportFilesSagaEntity = {
  success: (data, payPeriodId) => ({
    type: FETCH_ALL_LOCATIONS_REPORT_FILES_SUCCEEDED,
    data,
    payPeriodId
  }),
  failure: (error, payPeriodId) => ({
    type: FETCH_ALL_LOCATIONS_REPORT_FILES_FAILED,
    error,
    payPeriodId
  }),

  fetchAPI: (payPeriodId, options) =>
    callAPI(`/pay_periods/${payPeriodId}/all_locations_reports`, options)
}

export function* doFetchAllLocationsReportFiles({
  payPeriodId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    fetchAllLocationsReportFilesSagaEntity,
    payPeriodId,
    params,
    undefined,
    resolve,
    reject
  )
}

const createAllLocationsReportSagaEntity = {
  success: (data, reportData) => ({
    type: CREATE_ALL_LOCATIONS_REPORT_FILE_SUCCEEDED,
    data,
    reportData
  }),
  failure: (error, reportData) => ({
    type: CREATE_ALL_LOCATIONS_REPORT_FILE_FAILED,
    error,
    reportData
  }),

  fetchAPI: (id, options) =>
    callAPI(`/pay_periods/${id}/all_locations_reports`, {
      method: 'POST',
      ...options
    })
}

export function* doCreateAllLocationsReportFile({
  payPeriodId,
  reportData,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    createAllLocationsReportSagaEntity,
    payPeriodId,
    null,
    reportData,
    resolve,
    reject
  )
}

const deleteAllLocationsReportFileSagaEntity = {
  success: (data, { reportFileId }) => ({
    type: DELETE_ALL_LOCATIONS_REPORT_FILE_SUCCEEDED,
    data,
    reportFileId
  }),
  failure: (error, { reportFileId }) => ({
    type: DELETE_ALL_LOCATIONS_REPORT_FILE_FAILED,
    error,
    reportFileId
  }),

  fetchAPI: ({ payPeriodId, reportFileId }, options) =>
    callAPI(
      `/pay_periods/${payPeriodId}/all_locations_reports/${reportFileId}`,
      {
        method: 'DELETE',
        ...options
      }
    )
}

export function* doDeleteAllLocationsReportFile({
  payPeriodId,
  reportFileId,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    deleteAllLocationsReportFileSagaEntity,
    {
      payPeriodId,
      reportFileId
    },
    null,
    null,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const AllLocationsReportFile = Immutable.Record({
  id: null,
  generated_at: null,
  requester_id: null,
  export_type: null,
  requester: null,
  pay_period_id: null,
  document: null
})

const allLocationsReportFileForeignKeys = ['pay_period_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

const allLocationsReportFiles = (
  state = initialState,
  { type, data, reportFileId }
) => {
  switch (type) {
    case FETCH_ALL_LOCATIONS_REPORT_FILES: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], Immutable.Map({ loading: true }))
    }
    case FETCH_ALL_LOCATIONS_REPORT_FILES_SUCCEEDED: {
      const reportFiles = Immutable.fromJS(data, (key, value) =>
        key === 'requester' ? User(value) : value
      )

      return mergeRecords(
        state,
        AllLocationsReportFile,
        reportFiles,
        // @ts-ignore migration from js(x) to ts(x)
        allLocationsReportFileForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false
      })
    }
    case FETCH_ALL_LOCATIONS_REPORT_FILES_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], {
        loading: false
      })
    }
    case DELETE_ALL_LOCATIONS_REPORT_FILE_SUCCEEDED: {
      return deleteRecord(
        state,
        reportFileId,
        // @ts-ignore migration from js(x) to ts(x)
        allLocationsReportFileForeignKeys
      )
    }
    default:
      return state
  }
}

export default allLocationsReportFiles
