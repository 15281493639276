import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'

import { gapVariants } from '../../utils/properties/gap'
import { switchAtVariants } from '../../utils/properties/switchAt'

import type { SplitProps } from './Split.types'

const splitCVA = cva(['box-border flex [&>*]:box-border'], {
  variants: {
    fraction: {
      'auto-start': ['[&>*:first-child]:flex-1'],
      'auto-end': ['[&>*:last-child]:flex-1'],
      '1/4': ['[&>*:first-child]:basis-1/4 [&>*:last-child]:basis-3/4'],
      '1/3': ['[&>*:first-child]:basis-1/3 [&>*:last-child]:basis-2/3'],
      '1/2': ['[&>*:first-child]:basis-1/2 [&>*:last-child]:basis-1/2'],
      '2/3': ['[&>*:first-child]:basis-2/3 [&>*:last-child]:basis-1/3'],
      '3/4': ['[&>*:first-child]:basis-3/4 [&>*:last-child]:basis-1/4']
    },
    switchAt: switchAtVariants,
    gap: gapVariants
  }
})

export const Split = forwardRef<HTMLDivElement, SplitProps>(
  (
    {
      children,
      className,
      fraction = 'auto-start',
      gap = 0,
      switchAt = 'none',
      ...other
    },
    ref
  ) => {
    return (
      <div
        {...other}
        ref={ref}
        className={splitCVA({
          className,
          fraction,
          gap,
          switchAt
        })}
      >
        {children}
      </div>
    )
  }
)

Split.displayName = 'Split'
