import { Anchor, Banner } from '@libs/ui/ds'
import { useCommonAppTranslation } from '@libs/utils/translations'
import { routes } from '@spa/core/router'
import type { UpgradeUrlFeatureType } from '@spa/redux/billing/plan/hooks/useUpgradeUrl'
import useUpgradeUrl from '@spa/redux/billing/plan/hooks/useUpgradeUrl'
import React from 'react'
import { Link, generatePath } from 'react-router-dom'

type Props = {
  redirect?: UpgradeUrlFeatureType
  translationKey?: string
}

export const TrialEndBanner: React.FC<Props> = ({
  redirect,
  translationKey
}) => {
  const { t } = useCommonAppTranslation()
  const { url } = useUpgradeUrl(redirect)

  return (
    <Banner variant='danger'>
      {t('banner.cancelled.trialText')}
      {redirect ? (
        <Anchor href={url} variant='inherits' rel='noreferrer'>
          {translationKey ? t(translationKey) : t('banner.cancelled.trialLink')}
        </Anchor>
      ) : (
        <Anchor
          rel='noreferrer'
          variant='inherits'
          component={Link}
          to={{
            pathname: generatePath(routes.subscription.path)
          }}
        >
          {translationKey ? t(translationKey) : t('banner.cancelled.trialLink')}
        </Anchor>
      )}
    </Banner>
  )
}
