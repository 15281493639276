import { notifications } from '@libs/ui/ds'
import { DEFAULT_LANGUAGE } from '@libs/utils/environments'
import { errorLoggerService } from '@libs/utils/error-monitoring'
import i18n from '@libs/utils/translations'
import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call, put } from 'redux-saga/effects'

import {
  FETCH_MEMBERSHIPS,
  FETCH_MEMBERSHIPS_SUCCEEDED,
  FETCH_MEMBERSHIPS_FAILED,
  FETCH_MEMBERLIST_MEMBERSHIPS,
  FETCH_MEMBERLIST_MEMBERSHIPS_SUCCEEDED,
  FETCH_MEMBERLIST_MEMBERSHIPS_FAILED,
  FETCH_NOTIFICATION_MEMBERSHIPS,
  FETCH_NOTIFICATION_MEMBERSHIPS_SUCCEEDED,
  FETCH_NOTIFICATION_MEMBERSHIPS_FAILED,
  FETCH_MEMBERSHIP,
  FETCH_MEMBERSHIP_SUCCEEDED,
  FETCH_MEMBERSHIP_FAILED,
  CREATE_MEMBERSHIP,
  CREATE_MEMBERSHIP_SUCCEEDED,
  CREATE_MEMBERSHIP_FAILED,
  UPDATE_MEMBERSHIP_PERMISSIONS,
  UPDATE_MEMBERSHIP_PERMISSIONS_SUCCEEDED,
  UPDATE_MEMBERSHIP_PERMISSIONS_FAILED,
  UPDATE_MEMBERSHIP,
  UPDATE_MEMBERSHIP_SUCCEEDED,
  UPDATE_MEMBERSHIP_FAILED,
  DELETE_MEMBERSHIP,
  DELETE_MEMBERSHIP_SUCCEEDED,
  DELETE_MEMBERSHIP_FAILED,
  INVITE_MEMBERSHIP,
  INVITE_MEMBERSHIP_SUCCEEDED,
  INVITE_MEMBERSHIP_FAILED,
  UPDATE_ACCOUNT_SUCCEEDED,
  FETCH_USER_SUCCEEDED,
  UPDATE_USER_SUCCEEDED,
  FETCH_MEMBERSHIPS_TUTORS,
  FETCH_MEMBERSHIPS_TUTORS_SUCCEEDED,
  FETCH_MEMBERSHIPS_TUTORS_FAILED
} from '../../actionTypes'
import {
  call as callAPI,
  batchPaginated,
  concatPromisesData
} from '../../infra/http'
import {
  mergeRecords,
  deleteRecord,
  replaceRecord
} from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

import {
  FETCH_ALL_MEMBERSHIPS,
  FETCH_ALL_MEMBERSHIPS_ERROR,
  FETCH_ALL_MEMBERSHIPS_SUCCESS,
  HYDRATE_MEMBERSHIPS_SUCCEEDED
} from './actionTypes'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchMemberships = (accountId, params, resolve, reject) => ({
  type: FETCH_MEMBERSHIPS,
  accountId,
  params,
  resolve,
  reject
})

export const hydrateMemberships = (accountId, data) => ({
  type: HYDRATE_MEMBERSHIPS_SUCCEEDED,
  data,
  accountId
})

export const fetchMemberlistMemberships = (
  accountId,
  params,
  resolve,
  reject
) => ({
  type: FETCH_MEMBERLIST_MEMBERSHIPS,
  accountId,
  params,
  resolve,
  reject
})

export const fetchNotificationMemberships = (
  accountId,
  params,
  resolve,
  reject
) => ({
  type: FETCH_NOTIFICATION_MEMBERSHIPS,
  accountId,
  params,
  resolve,
  reject
})

export const fetchMembership = (
  membershipId: number,
  params?: {},
  resolve?: any,
  reject?: any
) => ({
  type: FETCH_MEMBERSHIP,
  params,
  membershipId,
  resolve,
  reject
})

export const createMembership = (membershipData, resolve, reject) => ({
  type: CREATE_MEMBERSHIP,
  membershipData,
  resolve,
  reject
})

export const updateMembership = (
  membershipId,
  membershipData,
  resolve,
  reject
) => ({
  type: UPDATE_MEMBERSHIP,
  membershipId,
  membershipData,
  resolve,
  reject
})

export const updateMembershipPermissions = (
  membershipId,
  membershipData,
  resolve,
  reject
) => ({
  type: UPDATE_MEMBERSHIP_PERMISSIONS,
  membershipId,
  membershipData,
  resolve,
  reject
})

export const deleteMembership = (membershipId, resolve, reject) => ({
  type: DELETE_MEMBERSHIP,
  membershipId,
  resolve,
  reject
})

export const inviteMembership = (membershipId, resolve, reject) => ({
  type: INVITE_MEMBERSHIP,
  membershipId,
  resolve,
  reject
})

export const fetchAllMemberships = (
  accountId,
  {
    locationId = null,
    perPage = null,
    teamIds = null,
    in_progress_on = null,
    skip_most_relevant_contract = false,
    ...params
  }
) => ({
  type: FETCH_ALL_MEMBERSHIPS,
  payload: {
    ...params,
    accountId,
    locationId,
    perPage,
    teamIds,
    in_progress_on,
    skip_most_relevant_contract
  }
})

export const fetchMembershipsTutors = (accountId, params, resolve, reject) => ({
  type: FETCH_MEMBERSHIPS_TUTORS,
  accountId,
  params,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const membershipsTutorsSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_MEMBERSHIPS_TUTORS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_MEMBERSHIPS_TUTORS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/memberships/tutors?${queryString.stringify(
        params
      )}`,
      options
    )
}

export function* doFetchMembershipsTutors({
  accountId,
  data,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    membershipsTutorsSagaEntity,
    accountId,
    params,
    data,
    resolve,
    reject
  )
}

// todo clean that part (batchPromise)
/**
 *
 * @param params {page, location_id, account_id, per_page }
 * @returns {Promise<any>}
 */
const fetchMemb = params => {
  const { account_id, ...others } = params

  const query = queryString.stringify({
    ...others,
    per_page: others.per_page || 75
  })

  return callAPI(`/accounts/${account_id}/memberships?${query}`)
}

export function* doFetchAllMemberships(action) {
  const {
    payload: {
      accountId,
      locationId,
      perPage,
      teamIds,
      in_progress_on,
      skip_most_relevant_contract,
      ...params
    }
  } = action

  const getParams = meta => {
    return [
      {
        ...params,
        page: (meta?.current_page || 0) + 1,
        account_id: accountId,
        per_page: perPage,
        ...(skip_most_relevant_contract && { skip_most_relevant_contract }),
        ...(locationId && { location_id: locationId }),
        ...(in_progress_on && { in_progress_on }),
        ...(!!teamIds?.length && { 'team_ids[]': teamIds })
      }
    ]
  }

  // @TODO CHECK check for error handling
  try {
    const resolvedPromises = yield call(batchPaginated, fetchMemb, getParams)
    const memberships = yield call(concatPromisesData, resolvedPromises, {
      dataAttribute: 'memberships'
    })
    yield put({
      type: FETCH_ALL_MEMBERSHIPS_SUCCESS,
      payload: { memberships: Immutable.fromJS(memberships) }
    })
  } catch (e) {
    errorLoggerService.error(e as any)
    yield put({
      type: FETCH_ALL_MEMBERSHIPS_ERROR,
      payload: { message: e }
    })
  }
}

const membershipsSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_MEMBERSHIPS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_MEMBERSHIPS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/memberships?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchMemberships({ accountId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    membershipsSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

const paginatedMemberlistMembershipsSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_MEMBERLIST_MEMBERSHIPS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_MEMBERLIST_MEMBERSHIPS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/memberships?${queryString.stringify({
        filtered: true,
        ...params
      })}`,
      options
    )
}

export function* doFetchMemberlistMemberships({
  accountId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    paginatedMemberlistMembershipsSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

const paginatedNotificationMembershipsSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_NOTIFICATION_MEMBERSHIPS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_NOTIFICATION_MEMBERSHIPS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/memberships?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchNotificationMemberships({
  accountId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    paginatedNotificationMembershipsSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

const membershipSagaEntity = {
  success: (data, membershipId) => ({
    type: FETCH_MEMBERSHIP_SUCCEEDED,
    data,
    membershipId
  }),
  failure: (error, membershipId) => ({
    type: FETCH_MEMBERSHIP_FAILED,
    error,
    membershipId
  }),

  fetchAPI: (membershipId, options, params) =>
    callAPI(
      `/memberships/${membershipId}?${
        typeof params === 'object' ? queryString.stringify(params) : params
      }`,
      options
    )
}

export function* doFetchMembership({ membershipId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    membershipSagaEntity,
    membershipId,
    params,
    undefined,
    resolve,
    reject
  )
}

const createMembershipSagaEntity = {
  success: (data, membershipData) => ({
    type: CREATE_MEMBERSHIP_SUCCEEDED,
    data,
    membershipData
  }),
  failure: (error, membershipData) => ({
    type: CREATE_MEMBERSHIP_FAILED,
    error,
    membershipData
  }),

  fetchAPI: (membershipData, options) =>
    callAPI('/memberships', { method: 'POST', ...options })
}

export function* doCreateMembership({ membershipData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createMembershipSagaEntity,
    null,
    null,
    membershipData,
    function* resolveFunction(...params) {
      if (resolve) {
        yield resolve(...params)
      }

      notifications.success({
        // @ts-ignore migration from js(x) to ts(x)
        message: i18n.t('common.notifications:members.create.success')
      })
    },
    function* rejectFunction(...params) {
      if (reject) {
        yield reject(...params)
      }

      notifications.error({
        // @ts-ignore migration from js(x) to ts(x)
        message: i18n.t('common.notifications:members.create.error')
      })
    }
  )
}

const updateMembershipSagaEntity = {
  success: (data, membershipId) => ({
    type: UPDATE_MEMBERSHIP_SUCCEEDED,
    data,
    membershipId
  }),
  failure: (error, membershipId) => ({
    type: UPDATE_MEMBERSHIP_FAILED,
    error,
    membershipId
  }),

  fetchAPI: (membershipId, options) =>
    callAPI(`/memberships/${membershipId}`, { method: 'PUT', ...options })
}

export function* doUpdateMembership({
  membershipId,
  membershipData,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    updateMembershipSagaEntity,
    membershipId,
    null,
    membershipData,
    resolve,
    reject
  )
}

const updateMembershipPermissionsSagaEntity = {
  success: (data, membershipId) => ({
    type: UPDATE_MEMBERSHIP_PERMISSIONS_SUCCEEDED,
    data,
    membershipId
  }),
  failure: (error, membershipId) => ({
    type: UPDATE_MEMBERSHIP_PERMISSIONS_FAILED,
    error,
    membershipId
  }),

  fetchAPI: (membershipId, options) =>
    callAPI(`/memberships/${membershipId}/permissions`, {
      method: 'PUT',
      ...options
    })
}

export function* doUpdateMembershipPermissions({
  membershipId,
  membershipData,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    updateMembershipPermissionsSagaEntity,
    membershipId,
    null,
    membershipData,
    resolve,
    reject
  )
}

const deleteMembershipSagaEntity = {
  success: (data, membershipId) => ({
    type: DELETE_MEMBERSHIP_SUCCEEDED,
    data,
    membershipId
  }),
  failure: (error, membershipId) => ({
    type: DELETE_MEMBERSHIP_FAILED,
    error,
    membershipId
  }),

  fetchAPI: (membershipId, options) =>
    callAPI(`/memberships/${membershipId}`, { method: 'DELETE', ...options })
}

export function* doDeleteMembership({ membershipId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    deleteMembershipSagaEntity,
    membershipId,
    null,
    null,
    resolve,
    reject
  )
}

const inviteMembershipSagaEntity = {
  success: (data, membershipId) => ({
    type: INVITE_MEMBERSHIP_SUCCEEDED,
    data,
    membershipId
  }),
  failure: (error, membershipId) => ({
    type: INVITE_MEMBERSHIP_FAILED,
    error,
    membershipId
  }),

  fetchAPI: (membershipId, options) =>
    callAPI(`/memberships/${membershipId}/invitations`, {
      method: 'POST',
      ...options
    })
}

export function* doInviteMembership({ membershipId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    inviteMembershipSagaEntity,
    membershipId,
    null,
    null,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const Membership = Immutable.Record({
  id: null,
  account_id: null,
  user_id: null,
  role: null,
  active: null,
  sort_index: null,
  employee_id: null,
  payroll_provider_sync: null,
  employee_id_mappings: null,
  accessible_team_ids: Immutable.List(),
  accessible_location_ids: Immutable.List(),
  most_relevant_contract: Immutable.Map(),
  user: null,

  // Preferences
  include_owner_in_schedules: true,
  has_access_to_all_locations: null,
  planning_hide_alerts_by_default: null,
  display_shifts_from_other_teams: null,
  manager_can_edit_employees: null,
  can_manage_published_planning: null,
  can_validate_own_real_hours: true,
  can_invalidate_shift: null,
  can_remove_employee: false,
  can_set_custom_rest_value: true,
  admin_restrict_access_to_timeoff: null,
  can_write_paid_leave_ledger: false,
  is_modulated: null,

  // Notification preferences
  email_notification_enabled: null,
  mobile_notification_enabled: null,
  sms_notification_enabled: null,

  // Planning alert preferences
  should_receive_planning_publication_reminder: null,

  // notify when users info change
  should_receive_mail_when_users_info_updated: null,

  // Onboarding attributes
  onboarding_section: null,
  onboarding_step: null,
  onboarded: null,

  // calendar feed
  calendar_sync_enabled: null,
  planning_calendar_feed: null,

  locale: DEFAULT_LANGUAGE,

  // Dates
  created_at: null,
  updated_at: null,

  // Membership personal data
  bic: null,
  birth_date: null,
  birth_department_code: null,
  birth_place: null,
  citizenship: null,
  city: null,
  enhanced_medical_examination: null,
  firstname: null,
  fixed_line_phone: { id: null, number: null },
  gender: null,
  health_plan: null,
  iban: null,
  last_medical_examination_date: null,
  lastname: null,
  mobile_phone: { id: null, number: null },
  national_identifier: null,
  phone_number: null,
  social_security_number: null,
  street_address: null,
  zip: null,

  // Work permit
  work_permit_authorization: false,
  work_permits: null
})

const membershipForeignKeys = ['account_id', 'user_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false, success: false, error: false }),
  relations: Immutable.Map(),
  pagination: Immutable.Map({
    current_page: 0,
    per_page: 0,
    total_count: 0,
    total_pages: 0,
    pages: Immutable.Map()
  })
})

function memberships(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_ALL_MEMBERSHIPS:
    case FETCH_MEMBERLIST_MEMBERSHIPS:
    case FETCH_MEMBERSHIPS: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], {
        // @ts-ignore migration from js(x) to ts(x)
        loading: (action.params || {}).silent !== true
      })
    }
    case FETCH_MEMBERSHIPS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_ALL_MEMBERSHIPS_SUCCESS: {
      // @ts-ignore migration from js(x) to ts(x)
      const { memberships } = action.payload

      return mergeRecords(
        state,
        Membership,
        memberships,
        // @ts-ignore migration from js(x) to ts(x)
        membershipForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    case FETCH_MEMBERSHIPS_SUCCEEDED:
    case HYDRATE_MEMBERSHIPS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const memberships = Immutable.fromJS(action.data)

      return mergeRecords(
        state,
        Membership,
        memberships,
        // @ts-ignore migration from js(x) to ts(x)
        membershipForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    case FETCH_NOTIFICATION_MEMBERSHIPS_FAILED:
    case FETCH_MEMBERLIST_MEMBERSHIPS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_NOTIFICATION_MEMBERSHIPS_SUCCEEDED:
    case FETCH_MEMBERLIST_MEMBERSHIPS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const memberships = Immutable.fromJS(action.data.memberships)
      // @ts-ignore migration from js(x) to ts(x)
      const pagination = Immutable.fromJS(action.data.meta)
      // @ts-ignore migration from js(x) to ts(x)
      const currentPage = action.data.meta.current_page

      return mergeRecords(
        state,
        Membership,
        memberships,
        // @ts-ignore migration from js(x) to ts(x)
        membershipForeignKeys
      ).withMutations(map => {
        map.mergeDeepIn(['meta'], {
          loading: false,
          updated_at: Date.now(),
          success: true
        })
        map.mergeDeepIn(['pagination'], pagination)
        map.setIn(
          ['pagination', 'pages', currentPage],
          memberships.map(membership => membership.get('id')).toSet()
        )
      })
    }
    case FETCH_USER_SUCCEEDED:
    case UPDATE_USER_SUCCEEDED:
    case UPDATE_ACCOUNT_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const memberships = Immutable.fromJS(action.data.memberships || [])
      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Membership, memberships, membershipForeignKeys)
    }
    case FETCH_MEMBERSHIP:
      return state.mergeDeepIn(['meta'], {
        // @ts-ignore migration from js(x) to ts(x)
        loading: true,
        error: false,
        success: false
      })
    case FETCH_MEMBERSHIP_SUCCEEDED:
    case CREATE_MEMBERSHIP_SUCCEEDED:
    case UPDATE_MEMBERSHIP_SUCCEEDED:
    case UPDATE_MEMBERSHIP_PERMISSIONS_SUCCEEDED:
    case INVITE_MEMBERSHIP_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const membership = Immutable.fromJS(action.data)
      return replaceRecord(
        state,
        Membership,
        membership,
        // @ts-ignore migration from js(x) to ts(x)
        membershipForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        error: false,
        success: true
      })
    }
    case DELETE_MEMBERSHIP_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { membershipId } = action
      // @ts-ignore migration from js(x) to ts(x)
      return deleteRecord(state, membershipId, membershipForeignKeys)
    }
    case FETCH_MEMBERSHIPS_TUTORS:
      return state.mergeDeepIn(['meta'], {
        // @ts-ignore migration from js(x) to ts(x)
        loading: true,
        error: false,
        success: false
      })
    case FETCH_MEMBERSHIPS_TUTORS_SUCCEEDED:
      return state.mergeDeepIn(['meta'], {
        // @ts-ignore migration from js(x) to ts(x)
        loading: false,
        error: false,
        success: true
      })
    case FETCH_MEMBERSHIPS_TUTORS_FAILED:
      return state.mergeDeepIn(['meta'], {
        // @ts-ignore migration from js(x) to ts(x)
        loading: false,
        error: true,
        success: true
      })
    default:
      return state
  }
}

export default filterActions(memberships, [
  FETCH_MEMBERSHIP,
  FETCH_MEMBERSHIPS,
  FETCH_MEMBERSHIPS_TUTORS,
  FETCH_MEMBERSHIPS_FAILED,
  FETCH_MEMBERSHIPS_SUCCEEDED,
  FETCH_MEMBERLIST_MEMBERSHIPS,
  FETCH_MEMBERLIST_MEMBERSHIPS_FAILED,
  FETCH_MEMBERLIST_MEMBERSHIPS_SUCCEEDED,
  FETCH_NOTIFICATION_MEMBERSHIPS_SUCCEEDED,
  FETCH_NOTIFICATION_MEMBERSHIPS_FAILED,
  FETCH_USER_SUCCEEDED,
  UPDATE_USER_SUCCEEDED,
  UPDATE_ACCOUNT_SUCCEEDED,
  FETCH_MEMBERSHIP_SUCCEEDED,
  CREATE_MEMBERSHIP_SUCCEEDED,
  UPDATE_MEMBERSHIP_SUCCEEDED,
  UPDATE_MEMBERSHIP_PERMISSIONS_SUCCEEDED,
  INVITE_MEMBERSHIP_SUCCEEDED,
  DELETE_MEMBERSHIP_SUCCEEDED,
  FETCH_MEMBERSHIPS_TUTORS_SUCCEEDED,
  FETCH_MEMBERSHIPS_TUTORS_FAILED,
  FETCH_ALL_MEMBERSHIPS,
  FETCH_ALL_MEMBERSHIPS_SUCCESS,
  FETCH_ALL_MEMBERSHIPS_ERROR,
  HYDRATE_MEMBERSHIPS_SUCCEEDED
])
