import { errorLoggerService } from '@libs/utils/error-monitoring'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import type { FetchBillingDataParams } from '../modules/entities'
import { updateLocationBilling } from '../modules/entities'
import { setMetaError, setMetaLoading, setMetaSuccess } from '../modules/meta'

export default function* fetchLocationBillingData({
  payload
}: FetchBillingDataParams) {
  yield put(setMetaLoading())

  try {
    const result = yield apiRequest({
      service: 'billing',
      route: 'locationBilling',
      queryParams: { account_id: payload.account_id }
    })

    yield put(updateLocationBilling([...result]))
    yield put(setMetaSuccess())
  } catch (err) {
    errorLoggerService.apiError(err)
    yield put(setMetaError())
  }
}
