import type { UserSchema } from '@libs/data-access/entities'
import { appQueryClient } from 'libs/data-access/queries/src/lib/provider'

import { userQueryKeys } from '../../User/user.cacheKeys'

import { getCurrentMembership } from './useFetchCurrentMembershipPermissions'

export const queryStateCurrentMembershipPermissions = () => {
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  const { data } = appQueryClient.getQueryState<UserSchema>(userQueryKeys.me())
  const currentMembership = getCurrentMembership(data)

  return currentMembership?.features_permissions
}
