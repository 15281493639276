import { GlobeIcon } from '@libs/ui/ds/assets/icons/GlobeIcon'
import { Button } from '@libs/ui/ds/lib/components/buttons/Button/base/Button'
import { DropdownMenu } from '@libs/ui/ds/lib/components/overlays/DropdownMenu/base/DropdownMenu'
import type { SupportedLanguage } from '@spa/features/i18n/types'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

type LanguageSelectProps = {
  'data-testid'?: string
}

const LANGUAGE_OPTIONS: { value: SupportedLanguage; label: string }[] = [
  { value: 'fr', label: 'Français' },
  { value: 'es', label: 'Español' },
  { value: 'en', label: 'English' },
  { value: 'it', label: 'Italiano' }
] as const

export const LanguageSelect: FC<LanguageSelectProps> = ({
  'data-testid': dataTestId
}) => {
  const { i18n } = useTranslation()
  const selectedLanguage = i18n.resolvedLanguage
  const selectedOption = LANGUAGE_OPTIONS.find(
    opt => opt.value === selectedLanguage
  )

  return (
    <DropdownMenu dropdownWidth='s'>
      <DropdownMenu.Target className='w-fit'>
        <Button
          data-testid={dataTestId}
          variant='tertiary'
          intent='neutral-dark'
          leftIcon={<GlobeIcon />}
        >
          {selectedOption?.label}
        </Button>
      </DropdownMenu.Target>
      <DropdownMenu.Dropdown>
        {LANGUAGE_OPTIONS.map(option => (
          <DropdownMenu.Option
            key={option.value}
            onClick={() => i18n.changeLanguage(option.value)}
            selected={selectedLanguage === option.value}
          >
            {option.label}
          </DropdownMenu.Option>
        ))}
      </DropdownMenu.Dropdown>
    </DropdownMenu>
  )
}
