import { useMergedScreenProps } from '@libs/ui/ds/lib/theme'
import { cva } from 'class-variance-authority'
import React, { forwardRef, memo } from 'react'

import { gapNegativeMarginAdapter } from '../utils/gapVariantAdapter'

import { GridProvider } from './Grid.Context'
import type { GridProps } from './Grid.types'
import { GridItem } from './compound/GridItem'

const gridCVA = cva(['box-border flex flex-wrap items-stretch justify-start'], {
  variants: {
    gap: gapNegativeMarginAdapter
  }
})

export const BaseGrid = memo(
  forwardRef<HTMLDivElement, GridProps>(
    (
      {
        screens,
        gap = 0,
        columns = 12,
        grow = false,
        className,
        children,
        ...other
      },
      ref
    ) => {
      const mergedProps = useMergedScreenProps({ gap }, screens)

      return (
        <GridProvider value={{ columns, grow, gap: mergedProps.gap }}>
          <div
            ref={ref}
            {...other}
            className={gridCVA({ gap: mergedProps.gap, className })}
          >
            {children}
          </div>
        </GridProvider>
      )
    }
  )
)

type CompoundGridType = {
  Item: typeof GridItem
}

const TypedGrid = BaseGrid as typeof BaseGrid & CompoundGridType

TypedGrid.displayName = 'Grid'
TypedGrid.Item = GridItem

export const Grid = TypedGrid
export type { GridProps } from './Grid.types'
