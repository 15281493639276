import { SkeletonText } from '@libs/ui/ds/lib/components/feedback/Skeleton/specialized/SkeletonText/base/SkeletonText'
import { useMediaQueryThreshold } from '@libs/ui/ds/lib/theme'
import type { Cell } from '@tanstack/react-table'
import { flexRender } from '@tanstack/react-table'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'

import type { TableProps } from '../../Table.types'
import { tableClasses } from '../tableClasses'

const tableRowCellCVA = {
  cell: cva([], {
    variants: {
      cellsContent: {
        ellipsis: 'truncate',
        auto: 'w-auto'
      },
      align: {
        left: 'text-left',
        center: 'text-center',
        right: 'text-right'
      },
      isPinned: {
        true: 'sticky z-[1] bg-neutral-50'
      },
      borderRight: {
        true: 'border-r-1 border-neutral-200'
      },
      cellsPaddingSize: {
        s: tableClasses.smallCell,
        m: tableClasses.cell
      }
    }
  })
}

type Props = {
  loading?: boolean
  cellsContent: TableProps['cellsContent']
  cellsPaddingSize?: TableProps['cellsPaddingSize']
  cell: Cell<any, unknown>
}

export const TableRowCell: FC<Props> = ({
  loading,
  cell,
  cellsContent,
  cellsPaddingSize
}) => {
  const { columnDef } = cell.column

  const match = useMediaQueryThreshold({
    mediaQueryTreshold: columnDef.meta?.hideOn
  })

  if (match) {
    return null
  }

  return (
    <td
      key={cell.id}
      style={{
        ...(cell.column.getIsPinned() === 'left' && {
          left: `${cell.column.getStart()}px`
        }),
        ...(cell.column.getIsPinned() === 'right' && {
          right: `${cell.column.getAfter()}px`
        }),
        ...(cell.column.getSize() && {
          width: `${cell.column.getSize()}px`
        })
      }}
      className={tableRowCellCVA.cell({
        cellsPaddingSize,
        className: columnDef.meta?.className,
        borderRight: columnDef.meta?.borderRight,
        align: columnDef.meta?.align,
        isPinned: Boolean(cell.column.getIsPinned()),
        cellsContent
      })}
    >
      {loading && <SkeletonText size='xs' />}
      {!loading && flexRender(columnDef.cell, cell.getContext())}
    </td>
  )
}
