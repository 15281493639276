import jsc from 'js-cookie'
import { useState, useEffect } from 'react'

import type { CookiesNames } from '../Cookies.constants'

const REACTIVE_INTERVAL_DURATION = 1000

export const useCookieListener = (cookieName: CookiesNames) => {
  const [cookieValue, setCookieValue] = useState(() => jsc.get(cookieName))

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newValue = jsc.get(cookieName)
      if (newValue !== cookieValue) {
        setCookieValue(newValue)
      }
    }, REACTIVE_INTERVAL_DURATION)

    return () => clearInterval(intervalId)
  }, [cookieName, cookieValue])

  return cookieValue
}
