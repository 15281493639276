import { PayPeriod } from '@spa/scenes/Plannings/redux/models'
import { setEntities } from '@spa/scenes/Plannings/redux/reducers/entities'
import { formatToIsoDay } from '@spa/utils/date'
import { normalize } from 'normalizr'
import { put, select } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'
import { getCurrentAccountId } from 'snap-redux/modules/accounts/selectors'

import { getWeekDateParams } from '../../../selectors'

export function* getPayPeriods(): any {
  const { week } = yield select(getWeekDateParams)
  const accountId = yield select(getCurrentAccountId)

  if (!week.start) {
    return
  }

  const data = yield apiRequest({
    route: 'getPayPeriods',
    params: {
      account_id: accountId
    },
    queryParams: {
      period_start: formatToIsoDay(week.start),
      period_end: formatToIsoDay(week.end)
    }
  })

  const normalized = normalize(data.pay_periods, [PayPeriod])
  yield put(setEntities({ payPeriods: normalized.entities.payPeriods }))
}
