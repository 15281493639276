import { Flex, HideAt, Split, Text, Title } from '@libs/ui/ds'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import { memo } from 'react'

import type { HeroCardProps } from './HeroCard.types'
import { HeroCardCTA } from './compound/HeroCardCTA'
import { HeroCardStartSection } from './compound/HeroCardStartSection'

const heroCardCVA = {
  root: cva(['relative w-full rounded-lg bg-secondary-blue-300 p-6']),
  desc: cva(['text-m font-semibold leading-6 text-neutral-900/60 md:text-l']),
  image: cva(['aspect-auto max-w-80 shrink object-contain lg:max-w-100']),
  split: cva(['gap-4']),
  rightSection: cva(['justify-between gap-4']),
  headContent: cva(['gap-4'])
}

const BaseHeroCard: FC<HeroCardProps> = memo(
  ({
    title,
    desc,
    startSection,
    endSection,
    className,
    imageSrc,
    ...other
  }) => {
    return (
      <Flex className={heroCardCVA.root({ className })} {...other}>
        <Split fraction='auto-start' className={heroCardCVA.split()}>
          <Flex className={heroCardCVA.rightSection()}>
            <Flex className={heroCardCVA.headContent()}>
              <Flex>{startSection}</Flex>
              <Title size={2}>{title}</Title>
              <Text className={heroCardCVA.desc()}>{desc}</Text>
            </Flex>
            <Flex>{endSection}</Flex>
          </Flex>
          {imageSrc && (
            <HideAt hideAt='max-md'>
              <img className={heroCardCVA.image()} src={imageSrc} alt='hero' />
            </HideAt>
          )}
        </Split>
      </Flex>
    )
  }
)

type CompoundHeroCardType = {
  StartSection: typeof HeroCardStartSection
  CTA: typeof HeroCardCTA
}

const TypedHeroCard = BaseHeroCard as typeof BaseHeroCard & CompoundHeroCardType

TypedHeroCard.displayName = 'HeroCard'
TypedHeroCard.StartSection = HeroCardStartSection
TypedHeroCard.CTA = HeroCardCTA
export const HeroCard = TypedHeroCard
export type { HeroCardProps } from './HeroCard.types'
export type { HeroCardCTAProps } from './compound/HeroCardCTA'
export type { HeroCardStartSectionProps } from './compound/HeroCardStartSection'
