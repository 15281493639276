import { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'

import { useCreateIntercomUserHash } from '../useCreateIntercomUserHash'

export const HandleBoot = () => {
  const { boot } = useIntercom()

  const { userHash, userId } = useCreateIntercomUserHash()

  useEffect(() => {
    if (!userId || !userHash) {
      boot()
      return
    }

    boot({ userHash, userId: String(userId) })
  }, [])

  return null
}
