import { useDebouncedValue, useUncontrolled } from '@libs/ui/ds'

import type { SharedDatePickerProps } from '../../SharedDatePicker.types'

export const useDatePickerOpened = ({
  onOpenedChange,
  opened
}: {
  onOpenedChange: SharedDatePickerProps['onOpenedChange']
  opened: SharedDatePickerProps['opened']
}) => {
  const [controlledOpened, setControlledOpened] = useUncontrolled<boolean>({
    onChange: onOpenedChange,
    value: opened
  })

  const [controlledDebounced] = useDebouncedValue(controlledOpened, 0)

  return {
    opened: controlledDebounced,
    setOpened: setControlledOpened
  }
}
