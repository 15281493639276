import type { PayloadAction } from '@reduxjs/toolkit'
import { SagaType, createSliceSaga } from 'redux-toolkit-saga'

import fetchPaidLeaveLedgerOpsData from '../generator/fetchPaidLeaveLedgeOps'
import postBulkPaidLeaveLedgerOpsData from '../generator/postBulkPaidLeaveLedgerOps'
import resetPaidLeaveLedgerOpsData from '../generator/resetPaidLeaveLedgeOps'

import type {
  ParamsFetchPaidLeaveLedgerOps,
  ParamsPostBulkPaidLeaveLedgerOps
} from './entities'

const paidLeaveLedgerSagaSlice = createSliceSaga({
  name: 'emp/paidLeaveLedger/sagas',
  caseSagas: {
    /**
     * Post a new bulk operation to the paid leaves.
     */
    *postBulkPaidLeaveLedgerOps({
      payload
    }: PayloadAction<ParamsPostBulkPaidLeaveLedgerOps>) {
      yield postBulkPaidLeaveLedgerOpsData(payload)
    },
    /**
     * Get operations of the paid leaves.
     */
    *fetchPaidLeaveLedgerOps({
      payload
    }: PayloadAction<ParamsFetchPaidLeaveLedgerOps>) {
      yield fetchPaidLeaveLedgerOpsData(payload)
    },
    /**
     * Reset state of the paid leaves ops store .
     */
    *resetPaidLeaveLedger() {
      yield resetPaidLeaveLedgerOpsData()
    }
  },
  sagaType: SagaType.TakeLatest
})

export const {
  postBulkPaidLeaveLedgerOps,
  fetchPaidLeaveLedgerOps,
  resetPaidLeaveLedger
} = paidLeaveLedgerSagaSlice.actions

export default paidLeaveLedgerSagaSlice.saga()
