import { isPlainObject, isArray } from 'lodash-es'

/**
 * Prevent passing invalid params to bugsnag.notify
 */
export const getCleanErrorPayload = (err: any) => {
  if (isPlainObject(err) || isArray(err)) {
    return JSON.stringify(err)
  }
  return err
}
