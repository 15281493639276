import { imagesUrls } from '@libs/ui/ds/assets/imagesUrls'
import type { FC } from 'react'

import type { EmptyContentProps } from '../../../../feedback/EmptyContent/base/EmptyContent'
import { EmptyContent } from '../../../../feedback/EmptyContent/base/EmptyContent'

type TableEmptyContentProps = {
  title: EmptyContentProps['title']
  desc?: EmptyContentProps['desc']
  action?: EmptyContentProps['action']
  imageSrc?: EmptyContentProps['imageSrc']
}

export const TableEmptyContent: FC<TableEmptyContentProps> = ({
  title,
  desc,
  action,
  imageSrc
}) => {
  return (
    <EmptyContent
      className='max-w-80'
      imageSrc={imageSrc || imagesUrls.illustrations.search}
      action={action}
      title={title}
      desc={desc}
      size='s'
    />
  )
}
