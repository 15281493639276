import type { PayloadAction } from '@reduxjs/toolkit'
import { createSliceSaga, SagaType } from 'redux-toolkit-saga'
import { abort } from 'snap-redux/infra/http/api/api.worker'

import type { ParamsState } from '../../reducers/params'
import { fetchSignableTimesheetsPeriodStatus } from '../entities/signableTimesheets/fetchSignableTimesheetsPeriodStatus'

import planningRefreshSaga from './refresh/planningRefresh'
import planningSilentRefreshSaga from './refresh/planningSilentRefresh'
import { deleteShiftsFromWeeklyscheduleById } from './request/deleteShiftsFromWeeklyschedule'
import type { PlannableSideEffectsParams } from './request/fetchPlannableSideEffectsEntities'
import fetchPlannablesSideEffectsEntities from './request/fetchPlannableSideEffectsEntities'
import clearLocalState from './reset/clearLocalState'

const controllersSagaSlice = createSliceSaga({
  name: 'sagas/planning',
  caseSagas: {
    /**
     * Refetch all planning data
     */
    *planningRefresh({ payload }: PayloadAction<ParamsState>) {
      yield planningRefreshSaga(payload)
    },

    *refreshPlanningCachedTeam() {
      yield fetchSignableTimesheetsPeriodStatus()
    },

    planningSilentRefresh: {
      sagaType: SagaType.Watch,
      *fn({ payload }: PayloadAction<ParamsState>) {
        yield planningSilentRefreshSaga(payload)
      }
    },

    *planningClear() {
      yield clearLocalState()
    },

    *abortStaleRequests() {
      yield abort()
    },

    *fetchPlannablesSideEffects({
      payload
    }: PayloadAction<PlannableSideEffectsParams>) {
      yield fetchPlannablesSideEffectsEntities(payload)
    },

    deleteShiftsFromWeeklyscheduleById
  },
  sagaType: SagaType.TakeLatest
})

export const controllersActions = controllersSagaSlice.actions
export default controllersSagaSlice.saga
