import { appQueryClient } from '../../provider'

export const accountCacheKeys = {
  current: () => ['account/current'] as const
}

export const userInvalidations = {
  invalidateCurrent: () =>
    appQueryClient.invalidateQueries({ queryKey: accountCacheKeys.current() })
}
