import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import { memo } from 'react'

import { BaseSkeleton } from '../../../base/BaseSkeleton'

import type { SkeletonTextProps } from './SkeletonText.types'

const skeletonTextCVA = {
  root: cva([], {
    variants: {
      size: {
        xs: 'h-2.5',
        s: 'h-4',
        m: 'h-6',
        l: 'h-8',
        xl: 'h-10'
      },
      fillWidth: {
        true: 'w-full',
        false: ''
      }
    }
  })
}

export const SkeletonText: FC<SkeletonTextProps> = memo(
  ({ size = 'm', className, fillWidth = false, ...other }) => {
    return (
      <BaseSkeleton
        {...other}
        className={skeletonTextCVA.root({
          fillWidth: Boolean(fillWidth),
          size,
          className
        })}
      />
    )
  }
)

SkeletonText.displayName = 'SkeletonText'
export const Skeleton = SkeletonText
export type { SkeletonTextProps } from './SkeletonText.types'
