import { billingService } from '@libs/data-access/queries'
import { Anchor, Banner } from '@libs/ui/ds'
import { useCommonAppTranslation } from '@libs/utils/translations'
import { routes } from '@spa/core/router'
import { NOT_PAID } from '@spa/scenes/Subscription/constant'
import type { FC } from 'react'
import { generatePath, Link } from 'react-router-dom'

type Props = {
  isAccountStatusCancelled: boolean
}

export const UnpaidInvoiceBanner: FC<Props> = ({
  isAccountStatusCancelled
}) => {
  const { t } = useCommonAppTranslation()

  const lastInvoice = billingService.account.useLastInvoice()
  const isLastInvoiceUnpaid = lastInvoice
    ? lastInvoice.status === NOT_PAID
    : false

  if (!isLastInvoiceUnpaid || isAccountStatusCancelled) {
    return null
  }

  return (
    <Banner variant='danger'>
      {t('banner.unpaid.default')}
      <Anchor
        rel='noreferrer'
        variant='inherits'
        component={Link}
        to={{
          pathname: generatePath(routes.subscription.path)
        }}
      >
        {t('banner.unpaid.link')}
      </Anchor>
    </Banner>
  )
}
