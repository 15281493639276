import { authService } from '@libs/data-access/queries'
import type { PostOauthResponse } from '@libs/data-access/queries'
import {
  SNAPSHIFT_CLIENT_SECRET,
  SNAPSHIFT_CLIENT_ID
} from '@libs/utils/environments'

import type { AuthState } from '../AuthState.types'
import { setAuthCookies, syncAuthState } from '../authServiceUtils'

import { useAuthState } from './useAuthState'
import { useClearAuth } from './useClearAuth'
import { useIsAccessTokenExpiredSoon } from './useIsAccessTokenExpiredSoon'

/**
 * Send a request to refresh both the access_token and the refresh_token
 *
 * The tokens are stored in a http-only cookie
 * We also update the auth state and related http services states
 */
export const usePostRefreshToken = () => {
  const { updateAuthState } = useAuthState()
  const { isTokenExpiredSoon } = useIsAccessTokenExpiredSoon()
  const { mutateAsync, isPending } = authService.usePostOauthToken()
  const clearAuth = useClearAuth({ redirectTo: true })

  return {
    isRefreshing: isPending || isTokenExpiredSoon,
    process: async () => {
      if (!isTokenExpiredSoon) {
        return
      }

      try {
        const res: PostOauthResponse = await mutateAsync({
          payload: {
            grant_type: 'refresh_token',
            client_secret: SNAPSHIFT_CLIENT_SECRET,
            client_id: SNAPSHIFT_CLIENT_ID
          }
        })

        const newAuthState = {
          accessTokenExpiresAt: res.expires_at
        }

        updateAuthState(newAuthState)
        syncAuthState(newAuthState as AuthState)
        setAuthCookies(newAuthState as AuthState)
      } catch {
        /**
         * If the request fails, then we sign-out the user
         * It means that the refresh_token was not valid anymore
         */
        clearAuth()
      }
    }
  }
}
