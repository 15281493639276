import { setEntities } from '@spa/scenes/Plannings/redux/reducers/entities'
import { put, select } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'
import type { AccountPermissions } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'
import { getAccountPermissions } from 'snap-redux/modules/accounts/selectors'

import type { ParamsState } from '../../../reducers'
import type { WeekDateParams } from '../../../selectors'
import { getParams, getWeekDateParams } from '../../../selectors'

export function* fetchSignableTimesheetsPeriodStatus() {
  const accountPermissions = yield select(getAccountPermissions)
  const weekDateParams = yield select(getWeekDateParams)
  const params = yield select(getParams)

  if (
    !(accountPermissions as AccountPermissions).signable_timesheets ||
    !params.teamId ||
    !weekDateParams?.week.start
  ) {
    return
  }

  const data = yield apiRequest({
    route: 'getSignableTimesheetsPeriodStatus',
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    params: { team_id: (params as ParamsState).teamId },
    queryParams: {
      period_start: (weekDateParams.week as WeekDateParams['week'])?.start
    }
  })

  yield put(
    setEntities({
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      signableTimesheetsPeriods: { [(params as ParamsState).teamId]: data }
    })
  )
}
