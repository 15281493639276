import { useMutation } from '@tanstack/react-query'

import type {
  ApiErrorBillingResponse,
  UseAppMutationOptions
} from '../../../../types'
import {
  putLocationGroupCustomerEmail,
  type PutLocationGroupCustomerEmail
} from '../locationGroupBillingInfo.api'
import {
  invalidateLocationGroupBillingInfoCache,
  locationGroupBillingInfoCacheKeys
} from '../locationGroupBillingInfo.cacheKeys'

export const useUpdateLocationGroupCustomerEmail = (
  mutationOptions?: UseAppMutationOptions<
    {},
    ApiErrorBillingResponse,
    PutLocationGroupCustomerEmail
  >
) => {
  return useMutation<
    {},
    ApiErrorBillingResponse,
    PutLocationGroupCustomerEmail
  >({
    ...mutationOptions,
    mutationKey: locationGroupBillingInfoCacheKeys.updateLocationGroupEmail(),
    mutationFn: (requestOptions: PutLocationGroupCustomerEmail) =>
      putLocationGroupCustomerEmail(requestOptions),
    onSuccess: (data, variables, ...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, ...args)
      }

      invalidateLocationGroupBillingInfoCache(variables)
    }
  })
}
