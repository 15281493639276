import type { Model, HolidaySchema } from '@libs/data-access/entities'
import { schema } from 'normalizr'

export type PublicHolidayModel = Model<HolidaySchema, { date: string }>

const PublicHoliday = new schema.Entity<PublicHolidayModel>(
  'publicHolidays',
  {},
  {
    idAttribute: 'date'
  }
)

export default PublicHoliday
