import type { ApiErrorResponse } from '@libs/data-access/queries'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import type { ParamsConvertDocument } from '../modules/entities'
import { resetDocumentConverted } from '../modules/entities'
import {
  setMetaLoading,
  setMetaSuccess,
  setMetaError,
  setMetaWS
} from '../modules/meta'

export default function* convertDocumentForSignatureData(
  body: ParamsConvertDocument
) {
  yield put(setMetaLoading())
  yield put(setMetaWS({ status: 'processing' }))

  try {
    yield apiRequest({
      service: 'core',
      route: 'postDocumentConvert',
      params: {
        document_id: body.document_id
      }
    })

    yield put(setMetaSuccess())
    yield put(resetDocumentConverted())
  } catch (err) {
    yield put(setMetaWS({ status: 'failure' }))
    yield put(setMetaError(err as ApiErrorResponse))
  }
}
