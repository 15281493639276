import type { AccountAddonName, AccountAddon } from '@libs/data-access/entities'
import { httpServices } from '@libs/data-access/queries'

import type { QueryFnParams } from '../../../types'

export type FetchAccountAddonOptions = QueryFnParams<{
  pathParams: {
    addon_enum: AccountAddonName
  }
}>

/**
 * Fetch account addon
 */
export const fetchAccountAddon = async (opts: FetchAccountAddonOptions) => {
  const response = await httpServices.core.get<AccountAddon>(
    `/accounts/${httpServices.authInfo.accountId}/addons/${opts.pathParams.addon_enum}`
  )
  return response.data
}

export type EnableAccountAddonOptions = FetchAccountAddonOptions

/**
 * Enable account addon
 */
export const enableAccountAddon = async (opts: EnableAccountAddonOptions) => {
  const response = await httpServices.core.post<AccountAddon>(
    `/accounts/${httpServices.authInfo.accountId}/addons/${opts.pathParams.addon_enum}/enable`
  )
  return response.data
}

export type DisableAccountAddonOptions = FetchAccountAddonOptions

/**
 * Disable account addon
 */
export const disableAccountAddon = async (opts: DisableAccountAddonOptions) => {
  const response = await httpServices.core.post<AccountAddon>(
    `/accounts/${httpServices.authInfo.accountId}/addons/${opts.pathParams.addon_enum}/disable`
  )
  return response.data
}
