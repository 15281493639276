import type { CalendarFeedPlanning } from '@libs/data-access/entities'
import { useMutation } from '@tanstack/react-query'

import { appQueryClient } from '../../../../provider'
import type { ApiErrorResponse, UseAppMutationOptions } from '../../../../types'
import {
  updateCalendarFeedIntegration,
  type UpdateCalendarFeedIntegrationOptions
} from '../calendarFeed.api'
import { calendarFeedQueryKeys } from '../calendarFeed.cacheKeys'

export const useUpdateCalendarFeedIntegration = (
  mutationOptions?: UseAppMutationOptions<
    CalendarFeedPlanning,
    ApiErrorResponse,
    UpdateCalendarFeedIntegrationOptions
  >
) => {
  return useMutation<
    CalendarFeedPlanning,
    ApiErrorResponse,
    UpdateCalendarFeedIntegrationOptions
  >({
    ...mutationOptions,
    mutationFn: opts => updateCalendarFeedIntegration(opts),
    onSuccess: (...args) => {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      mutationOptions?.onSuccess(...args)
      appQueryClient.invalidateQueries({
        queryKey: calendarFeedQueryKeys.all()
      })
    }
  })
}
