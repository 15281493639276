import { Anchor, Banner } from '@libs/ui/ds'
import { useCommonAppTranslation } from '@libs/utils/translations'
import { routes } from '@spa/core/router'
import moment from 'moment'
import React from 'react'
import type { FC } from 'react'
import { Link, generatePath } from 'react-router-dom'

type Props = {
  end: Date | null
  renewingAuto: boolean
}
const RenewBanner: FC<Props> = ({ end, renewingAuto }) => {
  const { t } = useCommonAppTranslation()

  return (
    <Banner variant='information'>
      {renewingAuto
        ? t('banner.renew.autoText', {
            date: moment(end).utc()
          })
        : t('banner.renew.noAutoText', {
            date: moment(end).utc()
          })}
      <Anchor
        component={Link}
        variant='inherits'
        rel='noreferrer'
        to={{
          pathname: generatePath(routes.subscription.path)
        }}
      >
        {renewingAuto
          ? t('banner.renew.autoLink')
          : t('banner.renew.noAutoLink')}
      </Anchor>
    </Banner>
  )
}

export default RenewBanner
