import { Stack } from '@libs/ui/ds/lib/components/primitiveLayouts/Stack/base/Stack'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import { memo } from 'react'

import type { ModalFooterProps } from './ModalFooter.types'
import { ModalFooterCancelButton } from './compound/ModalFooterCancelButton'
import { ModalFooterDangerButton } from './compound/ModalFooterCancelDanger'
import { ModalFooterSuccessButton } from './compound/ModalFooterCancelSuccess'

const modalFooterCVA = {
  root: cva(['px-6 pb-6']),
  wrap: cva([
    'sm:flex-row sm:items-center sm:justify-end',
    'flex-col-reverse items-stretch'
  ])
}

const BaseModalFooter: FC<ModalFooterProps> = memo(
  ({ className, children, ...other }) => {
    return (
      <div {...other} className={modalFooterCVA.root({ className })}>
        <Stack gap={4} className={modalFooterCVA.wrap()}>
          {children}
        </Stack>
      </div>
    )
  }
)

type CompoundModalType = {
  Success: typeof ModalFooterSuccessButton
  Danger: typeof ModalFooterDangerButton
  Cancel: typeof ModalFooterCancelButton
}

const TypedModalFooter = BaseModalFooter as typeof BaseModalFooter &
  CompoundModalType

TypedModalFooter.displayName = 'ModalFooter'
TypedModalFooter.Success = ModalFooterSuccessButton
TypedModalFooter.Danger = ModalFooterDangerButton
TypedModalFooter.Cancel = ModalFooterCancelButton

export const ModalFooter = TypedModalFooter
