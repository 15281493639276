import type { ContractSchema } from '@libs/data-access/entities'
import type { ReduxState } from '@spa/redux/ReduxState'
import moment from 'moment'
import { createSelector } from 'reselect'

import { getDisplayedTeamContracts } from '../getDisplayedTeamContracts/getDisplayedTeamContracts'

type Params = {
  teamId: number
  day: string
}

export const getBirthdaysByDayAndTeam = createSelector(
  (state: ReduxState, { teamId }: Params) =>
    getDisplayedTeamContracts(state, { teamId }),
  (_, { day }: Params) => day,

  (contracts: ContractSchema[], day: string) => {
    const birthdays = contracts.filter(contract => {
      const birthDate = moment(contract.birth_date)
      const dayDate = moment(day)
      return (
        birthDate.month() === dayDate.month() &&
        birthDate.date() === dayDate.date()
      )
    })
    return birthdays
  }
)
