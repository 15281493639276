import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'

import type { IconWrapProps } from './IconWrap.types'

const iconWrapCVA = cva(
  [
    'box-border inline-flex items-center justify-center [&>svg]:block [&>svg]:size-full'
  ],
  {
    variants: {
      size: {
        xs: 'size-3 max-h-3 min-h-3 min-w-3 max-w-3 p-0.5',
        s: 'size-4 max-h-4 min-h-4 min-w-4 max-w-4 p-0.5',
        m: 'size-5 max-h-5 min-h-5 min-w-5 max-w-5 p-0.5',
        l: 'size-8 max-h-8 min-h-8 min-w-8 max-w-8 p-0.5',
        xl: 'size-12 max-h-12 min-h-12 min-w-12 max-w-12 p-0.5'
      }
    }
  }
)

export const IconWrap = forwardRef<HTMLInputElement, IconWrapProps>(
  ({ icon, className, size = 'm', ...other }, ref) => {
    return (
      <div ref={ref} {...other} className={iconWrapCVA({ className, size })}>
        {icon}
      </div>
    )
  }
)

IconWrap.displayName = 'Icon'
export type { IconWrapProps } from './IconWrap.types'
