import type { AccountBillingInfo } from '@libs/data-access/entities'
import { httpServices } from '@libs/data-access/queries'

/**
 * Fetch billing information
 */
export const fetchBillingInfo = async () => {
  const response = await httpServices.billing.get<AccountBillingInfo>(
    `/account-based/accounts/${httpServices.authInfo.accountId}/billing-info`
  )
  return response.data
}
