import { FormProvider } from 'react-hook-form'

import type { BaseFormProps } from './BaseForm.types'

export const BaseForm = <T,>({
  methods,
  onSubmit,
  ref,
  children,
  enableHTMLValidation = false,
  ...other
}: BaseFormProps<T>) => (
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  <FormProvider {...methods}>
    <form
      {...other}
      ref={ref}
      noValidate={!enableHTMLValidation}
      {...(onSubmit && {
        onSubmit: methods.handleSubmit(onSubmit)
      })}
    >
      {children}
    </form>
  </FormProvider>
)

export type { BaseFormProps } from './BaseForm.types'
