import { DateRangePickerInput } from '@libs/ui/ds'
import { ControllerBase } from '@libs/ui/form'
import type { FieldValues, FieldPath } from 'react-hook-form'

import type { ControlledDateRangePickerInputProps } from './ControlledDateRangePickerInput.types'

export const ControlledDateRangePickerInput = <
  TFieldValues extends FieldValues = FieldValues,
  TInputName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  onChangeSideEffect,
  rules,
  ...other
}: ControlledDateRangePickerInputProps<TFieldValues, TInputName>) => (
  <ControllerBase
    rules={rules}
    control={control}
    field={<DateRangePickerInput {...other} />}
    name={name}
    onChangeAdapter={val => val}
    onChangeSideEffect={onChangeSideEffect}
  />
)
