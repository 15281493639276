import { appQueryClient } from '../../../provider'

export const accountStatusCacheKeys = {
  getByAccountId: () => ['accountStatus'] as const
}

export const invalidateAccountStatusCache = () => {
  appQueryClient.invalidateQueries({
    queryKey: accountStatusCacheKeys.getByAccountId()
  })
}
