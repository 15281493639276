import { usePrevious } from '@libs/ui/ds'
import type { Table } from '@tanstack/react-table'
import { useEffect } from 'react'

import type { TableProps } from '../base/Table.types'

export type Props<T> = {
  selection: TableProps<T>['selection']
  table: Table<T>
}

const useSelectAllFromPage = <T extends unknown>({
  selection,
  table
}: Props<T>) => {
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  const previousStateCount = usePrevious(Object.keys(selection.state).length)

  useEffect(() => {
    // We reset the table if none are selected BUT the state of the global selection is still active
    if (
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      Object.keys(selection.state).length === 0 &&
      selection?.selectAllRowFromPage
    ) {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      selection.setSelectAllRowFromPage(null)
      table.resetRowSelection()
    }

    // We reset global selection if the selection is modified (can only be less elements because all are supposed to be selected)
    if (
      selection?.selectAllRowFromPage &&
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      previousStateCount > Object.keys(selection.state).length
    ) {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      selection.setSelectAllRowFromPage(null)
    } else if (
      selection?.selectAllRowFromPage &&
      !table.getIsAllPageRowsSelected()
    ) {
      // If you've selected all the page already with the header, no need to select again.
      table.toggleAllPageRowsSelected()
    }
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  }, [selection.state, selection?.selectAllRowFromPage])

  // We reset the selection if the global selection is set to false.
  useEffect(() => {
    if (selection?.selectAllRowFromPage === false) {
      table.resetRowSelection()
    }
  }, [selection?.selectAllRowFromPage])
}

export default useSelectAllFromPage
