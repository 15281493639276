import { createSafeContext } from '@libs/ui/ds/lib/internals'

type TimelineContext = {
  showMore: boolean
  setShowMore: (bool: boolean) => void
}

export const [TimelineProvider, useTimelineContext] =
  createSafeContext<TimelineContext>({
    context: 'Timeline'
  })
