import { Flex, useMergedRef } from '@libs/ui/ds'
import { useCombobox } from '@mantine/core'
import { forwardRef, memo, useEffect, useRef, useState } from 'react'

import { Combobox } from '../../../overlays/Combobox/base/Combobox'
import { AutocompleteCustomTargetWrap } from '../../Autocomplete/base/subs/AutocompleteCustomTargetWrap'
import { AutocompleteOptionsList } from '../../Autocomplete/base/subs/AutocompleteOptionsList'
import { AutocompleteSearchInput } from '../../Autocomplete/base/subs/AutocompleteSearchInput'
import { AutocompleteTargetButton } from '../../Autocomplete/base/subs/targets/AutocompleteTargetButton'
import { AutocompleteTargetInputLike } from '../../Autocomplete/base/subs/targets/AutocompleteTargetInputLike'

import type { AsyncAutocompleteProps } from './AsyncAutocomplete.types'
import { useAsyncAutocompleteValuesUncontrolled } from './utils/useAsyncAutocompleteValuesUncontrolled'
import { useGetAsyncAutocompleteOption } from './utils/useGetAsyncAutocompleteOption'

const BaseAsyncAutocomplete = memo(
  forwardRef<HTMLElement, AsyncAutocompleteProps>(
    (
      {
        className,
        disabled,
        name,
        placeholder,

        /**
         *
         */
        defaultOptions,
        loadOptions,

        /**
         *
         */
        isMulti = false,
        onChange,
        defaultValue,
        value,
        dropdownPosition = 'bottom-start',
        closeOnOptionClick,
        dropdownHeight = 'm',
        dropdownWidth = 'm',
        loading,
        'data-testid': dataTestId,
        customTarget,
        ...other
      },
      forwardedRef
    ) => {
      const innerRef = useRef<HTMLElement>(null)
      const ref = useMergedRef(forwardedRef, innerRef)
      const store = useCombobox()
      const [cachedSearch, setCachedSearch] = useState<string>('')

      const { options: asyncOptions, isOptionsLoading } =
        useGetAsyncAutocompleteOption({
          defaultOptions,
          loadOptions,
          searchValue: cachedSearch || ''
        })

      const { select, search, selectedOptions } =
        useAsyncAutocompleteValuesUncontrolled({
          select: { onChange, defaultValue, value },
          isMulti,
          options: asyncOptions,
          closeOnOptionClick
        })

      useEffect(() => {
        setCachedSearch(search.debouncedSeachValue)
      }, [search.debouncedSeachValue])

      const isLoading = loading ?? isOptionsLoading

      return (
        <Combobox
          {...other}
          closeOnOptionClick={closeOnOptionClick ?? !isMulti}
          store={store}
          dropdownPosition={dropdownPosition}
          dropdownHeight={dropdownHeight}
          dropdownWidth={dropdownWidth}
          onClose={() => {
            search.resetSearchValue()
          }}
          onOptionSubmit={val => {
            if (!val) {
              return null
            }

            select.toggleSelectValue(val)
          }}
        >
          <Combobox.Target>
            <AutocompleteCustomTargetWrap
              ref={ref}
              className={className}
              disabled={disabled}
              name={name}
              placeholder={placeholder}
              store={store}
              selectedOptions={selectedOptions}
              customTarget={customTarget}
              data-testid={dataTestId}
            />
          </Combobox.Target>
          <Combobox.Dropdown>
            <Flex className='gap-3'>
              <AutocompleteSearchInput
                loading={isLoading}
                dropdownOpened={store.dropdownOpened}
                search={search}
              />
              <AutocompleteOptionsList
                options={asyncOptions}
                selectedOptions={selectedOptions}
                searchValue={search.debouncedSeachValue}
                loading={isLoading}
              />
            </Flex>
          </Combobox.Dropdown>
        </Combobox>
      )
    }
  )
)

type CompoundAsyncAutocompleteType = {
  Targets: {
    InputLike: typeof AutocompleteTargetInputLike
    Button: typeof AutocompleteTargetButton
  }
}

const TypedAsyncAutocomplete =
  BaseAsyncAutocomplete as typeof BaseAsyncAutocomplete &
    CompoundAsyncAutocompleteType

TypedAsyncAutocomplete.Targets = {
  InputLike: AutocompleteTargetInputLike,
  Button: AutocompleteTargetButton
}

TypedAsyncAutocomplete.displayName = 'AsyncAutocomplete'
export const AsyncAutocomplete = TypedAsyncAutocomplete

export type {
  AsyncAutocompleteProps,
  AsyncAutocompleteOptions
} from './AsyncAutocomplete.types'
