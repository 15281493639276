import { useEffect } from 'react'

import { useAuthState } from './useAuthState'
import { usePostRefreshToken } from './usePostRefreshToken'

const CHECK_INTERVAL_MS = 600_000 // 10 minutes

/**
 * Runs the provided callback function at a specified interval.
 */
const retryOnInterval = (cb: Function) => {
  return setInterval(cb, CHECK_INTERVAL_MS)
}

/**
 * Periodically checks if the access token is close to expiring.
 * When the expiration threshold is near, both accessToken and refreshToken are refreshed.
 */
export const useListenAuthTokensRefresh = () => {
  const { auth } = useAuthState()
  const refreshTokenQuery = usePostRefreshToken()
  const expiresAt = auth.accessTokenExpiresAt as string

  useEffect(() => {
    const intervalId = retryOnInterval(async () => {
      await refreshTokenQuery.process()
    })

    return () => clearInterval(intervalId)
  }, [expiresAt])
}
