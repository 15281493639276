import type { ApiErrorResponseData } from '@libs/data-access/queries'
import { notifications } from '@libs/ui/ds'
import type { RestModel } from '@spa/scenes/Plannings/redux/models/Rest'
import { copyDateOnly } from '@spa/utils/date'
import { put, select } from 'redux-saga/effects'
import { createRest } from 'snap-redux/modules/rests'

import { restsSelectors } from '../../../selectors'
import type { PayloadActionWithoutType } from '../../types/types'

type Params = PayloadActionWithoutType<{
  restId: number
  to: {
    contractId: number
    date: string
  }
}>

function* copyRest({ payload }: Params): any {
  const { restId, to } = payload

  const rest: RestModel = yield select(state =>
    restsSelectors.getCachedById(state, restId)
  )

  const diff =
    new Date(rest.starts_at).getTime() - new Date(rest.ends_at).getTime()

  const newDate = copyDateOnly(rest.starts_at, to.date)
  const edited = {
    start: newDate,
    end: newDate.clone().add(Math.abs(diff), 'milliseconds')
  }

  if (rest.duration_type === 'daily') {
    // If a daily rest is moved from/to a day with a hour change, the end hour change and we need to round it so it continues to be 0:00 or 12:00
    // e.g. we have a rest 2024-10-27T0:00:00 -> 2024-10-28T0:00:00 . Since it's a hour changing day, the rest duration is 25 hours. So if we move it to another day, it would finish at 01:00:00
    edited.end.set({
      hour: Math.round(edited.end.hour() / 12) * 12,
      minute: 0,
      second: 0
    })
  }

  yield put(
    createRest(
      {
        note: rest.note,
        rest_type: rest.rest_type,
        starts_at: edited.start.format(),
        ends_at: edited.end.format(),
        user_contract_id: to.contractId
      },
      null,
      (error: ApiErrorResponseData) => {
        notifications.errorAdapter({ error })
      }
    )
  )
}

export default copyRest
