import {
  getFreshShiftsListWithoutUnplanned,
  useGetFreshShiftsListWithoutUnplanned
} from './getFreshShiftListWithoutUnplanned/getFreshShiftsListWithoutUnplanned'
import {
  getFreshShiftsList,
  useGetFreshShiftsList
} from './getFreshShiftsList/getFreshShiftsList'
import {
  getFreshUnplannedShiftsList,
  useGetFreshUnplannedShiftsList
} from './getFreshUnplannedShiftList/getFreshUnplannedShiftsList'
import { getShiftsByContract } from './getShiftsByContract/getShiftsByContract'
import { getShiftsByTeam } from './getShiftsByTeam/getShiftsByTeam'
import { getValidShiftsListForBulkActions } from './getValidShiftsListForBulkActions/getValidShiftsListForBulkActions'
import { shiftsBaseSelectors } from './shiftsBaseSelectors'

export const shiftsSelectors = {
  ...shiftsBaseSelectors,
  getByTeam: getShiftsByTeam,
  getShiftsByContract,
  getFreshList: getFreshShiftsList,
  getFreshListWithoutUnplanned: getFreshShiftsListWithoutUnplanned,
  getFreshUnplannedList: getFreshUnplannedShiftsList,
  getValidListForBulkActions: getValidShiftsListForBulkActions,
  useGetFreshListWithoutUnplanned: useGetFreshShiftsListWithoutUnplanned,
  useGetFreshUnplannedList: useGetFreshUnplannedShiftsList,
  useGetFreshList: useGetFreshShiftsList
}
