import type { AccountBillingInfo } from '@libs/data-access/entities'
import type { UseAppQueryOptions } from '@libs/data-access/queries'
import { queryClientOptions } from '@libs/data-access/queries'
import { useQuery } from '@tanstack/react-query'

import { fetchBillingInfo } from '../accountBillingInfo.api'
import { accountBillingInfoCacheKeys } from '../accountBillingInfo.cacheKeys'

export const useGetAccountBillingInfo = (
  queryOptions?: UseAppQueryOptions<AccountBillingInfo>
) => {
  return useQuery({
    ...queryOptions,
    queryKey: accountBillingInfoCacheKeys.getByAccountId(),
    queryFn: () => fetchBillingInfo(),
    staleTime: queryClientOptions.time.INFINITY
  })
}
