import { clearEntities } from '@spa/scenes/Plannings/redux/reducers/entities'
import { clearMeta } from '@spa/scenes/Plannings/redux/reducers/meta'
import { clearParams } from '@spa/scenes/Plannings/redux/reducers/params'
import { put } from 'redux-saga/effects'

import { clearDisplayFilters } from '../../../reducers/displayFilters'

function* clearLocalState() {
  /**
   * We do not reset settings because settings are not stored outside redux
   * the settings will only apply during the current session
   */
  yield put(clearMeta())
  yield put(clearParams())
  yield put(clearEntities())
  yield put(clearDisplayFilters())
}

export default clearLocalState
