import type { AccountLocationGroupHostedPage } from '@libs/data-access/entities'
import { useQuery } from '@tanstack/react-query'

import type { UseAppQueryOptions } from '../../../../types'
import { fetchInvoiceCollectNow } from '../accountInvoices.api'
import { accountInvoicesCacheKeys } from '../accountInvoices.cacheKeys'

export const useAccountInvoiceCollectNow = (
  chargebeeCustomerId: string,
  queryOptions?: UseAppQueryOptions<AccountLocationGroupHostedPage>
) => {
  return useQuery({
    ...queryOptions,
    queryKey:
      accountInvoicesCacheKeys.getByCustomerIdInvoiceCollectNow(
        chargebeeCustomerId
      ),
    queryFn: () => fetchInvoiceCollectNow(chargebeeCustomerId)
  })
}
