import { useMutation } from '@tanstack/react-query'

import { appQueryClient } from '../../../../provider'
import type { ApiErrorResponse, UseAppMutationOptions } from '../../../../types'
import { invalidateAccountDetailsCache } from '../../AccountDetails/accountDetails.cacheKeys'
import { invalidateAccountStatusCache } from '../../AccountStatus/accountStatus.cacheKeys'
import { locationGroupBillingInfoCacheKeys } from '../../LocationGroupBillingInfo/locationGroupBillingInfo.cacheKeys'
import type { CardPaymentLocationBasedOptions } from '../locationGroupCardPayment.api'
import { cardPaymentLocationBased } from '../locationGroupCardPayment.api'
import { cardPaymentLocationBased as cardPaymentLocationBasedKey } from '../locationGroupPayment.cacheKeys'

export const useCardPaymentLocationGroup = (
  mutationOptions?: UseAppMutationOptions<
    {},
    ApiErrorResponse,
    CardPaymentLocationBasedOptions
  >
) => {
  return useMutation({
    ...mutationOptions,
    mutationKey: cardPaymentLocationBasedKey.one(),
    mutationFn: (options: CardPaymentLocationBasedOptions) =>
      cardPaymentLocationBased(options),
    onSuccess: (data, variables, ...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, ...args)
      }

      invalidateAccountStatusCache()
      invalidateAccountDetailsCache()

      appQueryClient.invalidateQueries({
        queryKey: locationGroupBillingInfoCacheKeys.getByLocationGroupId({
          pathParams: {
            location_group_id: variables?.queryParams?.location_group_id || ''
          }
        })
      })
    }
  })
}
