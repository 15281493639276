import { cookiesService } from '@libs/utils/cookies'
import { WS_PROTOCOL, API_APPLICATION_HOST } from '@libs/utils/environments'

import { rwsFactory } from '../../../internals/websocket/rwsFactory'
import { httpServices } from '../../http/httpServices'

class CoreWSAuth {
  private authState: { accountId: number } = {
    accountId: cookiesService.readAccountId() as number
  }

  /**
   * Establishes a WebSocket connection using an authentication ticket obtained from the server.
   */
  async connect() {
    const { ticket } = await this.fetchAuthTicket()
    const protocol = WS_PROTOCOL || 'wss'
    const url = `${protocol}://${API_APPLICATION_HOST}/api/cable?ticket=${ticket}`
    return rwsFactory({ url, queryParams: { ticket } })
  }

  /**
   * Fetches an authentication ticket from the server based on the current account ID
   */
  private async fetchAuthTicket() {
    const response = await httpServices.core.post<{ ticket: string }>(
      '/socket_authorization_tickets',
      { account_id: this.authState.accountId }
    )

    return response.data
  }

  public syncAuthState(opts: { accountId: number }) {
    this.authState = { ...this.authState, ...opts }
  }
}

export const coreWSAuth = new CoreWSAuth()
