import { useTranslation, withTranslation } from 'react-i18next'

export const COMMON_APP = 'common.app'
export const useCommonAppTranslation = () =>
  useTranslation(COMMON_APP, { useSuspense: true })
export const withCommonAppTranslation = () => withTranslation(COMMON_APP)

export const COMMON_DATES = 'common.dates'
export const useCommonDatesTranslation = () =>
  useTranslation(COMMON_DATES, { useSuspense: true })
export const withCommonDatesTranslation = () => withTranslation(COMMON_DATES)

export const COMMON_NOTIFICATIONS = 'common.notifications'
export const useCommonNotificationsTranslation = () =>
  useTranslation(COMMON_NOTIFICATIONS, { useSuspense: true })
export const withCommonNotificationsTranslation = () =>
  withTranslation(COMMON_NOTIFICATIONS)

export const COMMON_FORMS = 'common.forms'
export const useCommonFormsTranslation = () =>
  useTranslation(COMMON_FORMS, { useSuspense: true })

export const useCommonsTranslation = () => {
  const { t: tCommon } = useCommonAppTranslation()
  const { t: tDates } = useCommonDatesTranslation()
  const { t: tForms } = useCommonFormsTranslation()
  const { t: tNotifications } = useCommonNotificationsTranslation()

  return {
    tCommon,
    tDates,
    tForms,
    tNotifications
  }
}
