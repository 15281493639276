import type { ReduxState } from '@spa/redux/ReduxState'
import { createSelector } from 'reselect'

import { locationsSelectors } from '../../locations'
import { teamsBaseSelectors } from '../teamsBaseSelectors'

export const getPlanningTeams = createSelector(
  [
    teamsBaseSelectors.getCachedMap,
    (state: ReduxState, { locationId }) => {
      return locationsSelectors.getCachedById(state, locationId)
    },
    (_: ReduxState, { teamId }) => teamId
  ],
  (teams, location, teamId) => {
    if (!location || !teams) {
      return []
    }

    // every location's team
    if (teamId === 0 || !teamId) {
      // we base our loop on `team_ids` instead of `teams` attributes because teams size can vary.
      // If we use team_id as filter on /plannings
      //
      // 1er render - location.teams.length        = 4
      //              location.team_ids.length     = 4
      //
      // publish    -> re-fetch planning with team_id
      //
      // 2er render - location.teams.length        = 1
      //              location.team_ids.length     = 4
      return location.team_ids
        .map(id => teams[id])
        .filter(Boolean)
        .sort((a, b) => a.name.localeCompare(b.name))
    }

    const team = teams[teamId]
    return [team]
  }
)
