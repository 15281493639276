import type { PayloadActionWithoutType } from '../../types/types'

import { updateWeeklyscheduleById } from './updateWeeklyscheduleById'

type Params = PayloadActionWithoutType<{
  weeklyscheduleId: number
}>

export function* unpublishWeeklyscheduleById({
  payload: { weeklyscheduleId }
}: Params): any {
  yield updateWeeklyscheduleById({
    payload: {
      weeklyscheduleId,
      data: {
        published: false,
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        published_at: null,
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        publication_message: null
      }
    }
  })
}
