import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import { keyBy, mapValues } from 'lodash-es'
import type { MomentInput } from 'moment'
import moment from 'moment'
import createCachedSelector from 're-reselect'

import { getIsoDaysInTimeRange, getTimeRange } from '../../../timeRange'
import { contractsBaseSelectors } from '../contractsBaseSelectors'

const isBeforeDay = (date: MomentInput, compareDate: MomentInput) => {
  return moment(date).isBefore(compareDate, 'day')
}

const isAfterDay = (date: MomentInput, compareDate: MomentInput) => {
  return moment(date).isAfter(compareDate, 'day')
}

const isBetweenDays = (
  date: MomentInput,
  start: MomentInput,
  end: MomentInput
) => {
  return moment(date).isBetween(start, end, 'day', '[]')
}

type ContractDaysRange = {
  [key: string]: boolean
}

/**
 *
 */
export const getContractDaysRange = createCachedSelector(
  getTimeRange,
  getIsoDaysInTimeRange,
  contractsBaseSelectors.getCachedById,

  (timeRange, isoDays, contract): ContractDaysRange => {
    const contractStart = contract?.contract_start
    const contractEnd = contract?.contract_end
    const momentContractStart = moment(contractStart)
    const momentContractEnd = moment(contractEnd)

    /**
     * If contract_start and contract_end are outside the range of the week
     * early returns to prevent additional date computing
     */
    if (
      !contractStart ||
      (isBeforeDay(momentContractStart, timeRange.start) &&
        (!contractEnd || isAfterDay(momentContractEnd, timeRange.end)))
    ) {
      return mapValues(keyBy(isoDays), () => true)
    }

    /**
     * Return array of days that are in week range
     */
    const contractDaysInRange = mapValues(keyBy(isoDays), day => {
      const isInRange = contractEnd
        ? isBetweenDays(day, momentContractStart, momentContractEnd)
        : moment(day).isSameOrAfter(momentContractStart, 'day')
      return isInRange
    })

    return contractDaysInRange
  }
)((_, contractId) => fallbackToDefaultCacheKey(contractId))
