import { imagesUrls } from '@libs/ui/ds'
import { APP_NAMESPACE } from '@libs/utils/environments'
import { useEffect } from 'react'

const setFaviconLinkHref = (linkElement: HTMLLinkElement) => {
  linkElement.href = imagesUrls.logo.favicon(APP_NAMESPACE)
}

const findExistingFaviconLink = () => {
  return document.querySelector("link[rel~='icon']") as HTMLLinkElement
}

const createAndAppendNewLink = () => {
  const newLink = document.createElement('link')
  newLink.rel = 'icon'
  setFaviconLinkHref(newLink)
  document.head.appendChild(newLink)
}

export const Favicon = () => {
  useEffect(() => {
    const existingLink = findExistingFaviconLink()

    if (existingLink) {
      setFaviconLinkHref(existingLink)
      return
    }

    createAndAppendNewLink()
  }, [])

  return null
}
