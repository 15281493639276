import { useMergedRef, type SafeHotkeysCombination } from '@libs/ui/ds'
import { hotkeysService } from '@libs/ui/ds/lib/internals'
import type { ForwardedRef } from 'react'
import { useRef } from 'react'

export const useHandleClickOnListeners = (
  keysListener: SafeHotkeysCombination[] = [],
  forwardedRef: ForwardedRef<HTMLButtonElement> = null
) => {
  const innerRef = useRef<HTMLButtonElement>(null)
  const ref = useMergedRef(innerRef, forwardedRef)

  hotkeysService.useSafeHotkeysListener({
    enabled: Boolean(keysListener?.length),
    preventDefault: true,
    handlers: keysListener?.map(keys => ({
      keys,
      handler: e => {
        if (e.repeat) {
          return
        }
        e.stopPropagation()
        e.preventDefault()

        if (!innerRef.current) {
          return
        }

        innerRef.current.focus()
        innerRef.current.click()
      }
    }))
  })

  return ref
}
