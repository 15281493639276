// Plan & Features
export const CASH_CONNECTOR = 'connecteur-paie'
export const CASH_CONNECTOR_MONTHLY = 'connecteur-paie'
export const CASH_CONNECTOR_ANNUAL = 'connecteur-paie-annual'
export const BASIC_PLAN_5 = 'basic-plan-5'
export const MONTHLY_PLAN_MAX_5 = 'up-to-5-users'
export const MONTHLY_PLAN_5_INCLUDED = 'abo-mensuel-5-inclus'
export const MONTHLY_40_8_INCLUDED = 'abo-mensuel-40-8-users-included'
export const MONTHLY_48_8_INCLUDED = 'abo-mensuel-48-8-users-included'
export const MONTHLY_48_8_INCLUDED_FOR_ADDON_5 =
  'abo-mensuel-48-8-users-included-for-addon-5'
export const MONTHLY_5_1_INCLUDED = 'abo-mensuel-5-1-user'
export const MONTHLY_6_1_INCLUDED = 'abo-mensuel-6-1-user'
export const MONTHLY_0 = 'plan-mensuel-0€-'
export const MONTHLY_120 = 'monthly120'
export const OLD_ANNUAL = 'forfait-annuel'
export const ADDITIONAL_USER_3 = 'per-user-basic'
export const ADDITIONAL_USER_4 = 'additional_user'
export const ADDITIONAL_USER_5 = 'utilisateur_supp'
export const ADDITIONAL_USER_6 = 'additional_user_6'
export const FAP_HOSTING = 'hebergement-fap'
export const SNAP_PAD = 'addon-snappad'
export const ELECTRONIC_SIGNATURE = 'contract-signature'
export const PARTNER_API = 'partner-api'
export const MONTHLY = 'monthly2020'
export const MONTHLY_ENGAGED = 'monthly2020-engaged'
export const ANNUAL = 'annual2020'
export const PAD = 'pad'
export const PAD_ONLY = 'pad-only-'
export const PAD_ONLY_ENGAGED = 'pad-only-engaged'
export const PAD_ONLY_FREE = 'pad-only-free'
export const PAD_ONLY_ANNUAL = 'pad-only-annual'
export const CONNECTEUR_PAIE_ENGAGED = 'connecteur-paie-engaged'
export const CONNECTEUR_PAIE_ANNUAL = 'connecteur-paie-annual'
export const CONNECTEUR_PAIE = 'connecteur-paie'

export const NEW_PLANS = [MONTHLY_ENGAGED, MONTHLY, ANNUAL]

// Tabs names
export enum SubscriptionTabs {
  SUBSCRIPTION = 'subscription',
  PAYMENT_HISTORY = 'payment_history',
  INFORMATION = 'billing_information'
}

// Subscription type
export const MONTHLY_TYPE = 'monthly'
export const YEARLY_TYPE = 'yearly'

// Subscription status
export const CANCELLED = 'cancelled'
export const ACTIVE = 'active'
export const IN_TRIAL = 'in_trial'
export const CANCELED_TRIAL = 'canceled_trial'

// Payment
export const CARD = 'card'
export const DIRECT_DEBIT = 'direct_debit'
export const VAT_MOD = 1.2

// Invoice
export const PAID = 'paid'
export const POSTED = 'posted'
export const PAYMENT_DUE = 'payment_due'
export const NOT_PAID = 'not_paid'
export const VOIDED = 'voided'
export const PENDING = 'pending'

export const INVOICES_STATUS_LABEL = {
  [PAID]: 'constants.invoicesStatusLabel.paid',
  [POSTED]: 'constants.invoicesStatusLabel.posted',
  [PAYMENT_DUE]: 'constants.invoicesStatusLabel.paymentDue',
  [NOT_PAID]: 'constants.invoicesStatusLabel.notPaid',
  [VOIDED]: 'constants.invoicesStatusLabel.voided',
  [PENDING]: 'constants.invoicesStatusLabel.pending'
}

export const PLAN_TO_ADDON_NAME = {
  [ANNUAL]: 'annual',
  [MONTHLY_ENGAGED]: 'engaged',
  [MONTHLY]: 'free'
}

// Payroll Addons
export const PLAN_TO_PAYROLL_ADDON = {
  [ANNUAL]: CASH_CONNECTOR_ANNUAL,
  [MONTHLY]: CASH_CONNECTOR_MONTHLY
}

export const payAddonArray = [CONNECTEUR_PAIE_ANNUAL, CONNECTEUR_PAIE]

// Period label
export const MONTH = 'month'
export const MONTH_PER_EMPLOYEE = 'month_per_employee'
export const MONTH_PER_LOCATION = 'month_per_location'

export const MONTHLY_LABEL = {
  [MONTH]: 'module.month',
  [MONTH_PER_EMPLOYEE]: 'module.monthByEmployee',
  [MONTH_PER_LOCATION]: 'module.monthByEstablishment'
}

export const SOFTWARE = {
  DEFAULT: 'default',
  SIGMA: 'sigma',
  QUADRATUS: 'quadra_paie',
  SAGE: 'sage',
  SILAE: 'silae',
  PAIERH: 'paie_rh',
  TALENTIA: 'talentia',
  PAYFIT: 'payfit',
  ADP: 'adp',
  WINGIP: 'wingip'
}

type Plan = {
  id: string
  minUser?: number
  maxUser?: number
  notEngaged?: number
  notEngagedPad: number
  engaged?: number
  engagedPad: number
  annual2020?: number
  annual2020Pad: number
}

export const subscriptionPlans: Array<Plan> = [
  {
    id: 'pad-only',
    notEngagedPad: 18,
    engagedPad: 16,
    annual2020Pad: 14 / 12
  },
  {
    id: 'xs',
    minUser: 1,
    maxUser: 10,
    notEngaged: 54,
    notEngagedPad: 72,
    engaged: 48,
    engagedPad: 64,
    annual2020: 516 / 12,
    annual2020Pad: 684 / 12
  },
  {
    id: 's',
    minUser: 11,
    maxUser: 15,
    notEngaged: 90,
    notEngagedPad: 120,
    engaged: 81,
    engagedPad: 108,
    annual2020: 864 / 12,
    annual2020Pad: 1164 / 12
  },
  {
    id: 'm',
    minUser: 16,
    maxUser: 20,
    notEngaged: 114,
    notEngagedPad: 152,
    engaged: 102,
    engagedPad: 136,
    annual2020: 1092 / 12,
    annual2020Pad: 1464 / 12
  },
  {
    id: 'l',
    minUser: 21,
    maxUser: 30,
    notEngaged: 150,
    notEngagedPad: 218,
    engaged: 135,
    engagedPad: 196,
    annual2020: 1452 / 12,
    annual2020Pad: 2112 / 12
  },
  {
    id: 'xl',
    minUser: 31,
    maxUser: 50,
    notEngaged: 210,
    notEngagedPad: 298,
    engaged: 189,
    engagedPad: 268,
    annual2020: 2040 / 12,
    annual2020Pad: 2892 / 12
  },
  {
    id: 'xxl',
    minUser: 51,
    maxUser: 5000,
    notEngaged: 300,
    notEngagedPad: 500,
    engaged: 270,
    engagedPad: 450,
    annual2020: 2916 / 12,
    annual2020Pad: 4860 / 12
  }
]

export const SUBSCRIPTION_PLANS_OPTIONS = [
  { label: 'constant.none', value: '' },
  { label: 'constant.addon_select_xs', value: 'xs' },
  { label: 'constant.addon_select_s', value: 's' },
  { label: 'constant.addon_select_m', value: 'm' },
  { label: 'constant.addon_select_l', value: 'l' },
  { label: 'constant.addon_select_xl', value: 'xl' },
  { label: 'constant.addon_select_xxl', value: 'xxl' }
]

export const CREATE = 'create'
export const PAYMENT = 'payment'

export const LOCATION_MODAL_STEP = {
  [CREATE]: 'creation',
  [PAYMENT]: 'payment'
}

// SEEMS NOT USED ANYMORE
export const SOFTWARE_SETTINGS = {
  [SOFTWARE.DEFAULT]: {
    title: 'payRoll.software.default.title',
    description: 'payRoll.software.default.description',
    src: 'https://snapshift-public-assets.s3-eu-west-1.amazonaws.com/integration-images/paie-default.png',
    url: 'https://guide.combohr.com/fr/articles/1272994-connecter-mon-logiciel-de-caisse-a-combo',
    price: 'payRoll.software.default.price'
  },
  [SOFTWARE.SIGMA]: {
    title: 'payRoll.software.sigma.title',
    description: 'payRoll.software.sigma.description',
    src: 'https://snapshift-public-assets.s3-eu-west-1.amazonaws.com/integration-images/paie-sigma.png',
    url: 'https://guide.combohr.com/fr/articles/1272994-connecter-mon-logiciel-de-caisse-a-combo',
    price: 'payRoll.software.sigma.price'
  },
  [SOFTWARE.QUADRATUS]: {
    title: 'payRoll.software.quadra.title',
    description: 'payRoll.software.quadra.description',
    src: 'https://snapshift-public-assets.s3-eu-west-1.amazonaws.com/integration-images/paie-quadratus.png',
    url: 'https://guide.combohr.com/fr/articles/1272994-connecter-mon-logiciel-de-caisse-a-combo',
    price: 'payRoll.software.quadra.price'
  },
  [SOFTWARE.SAGE]: {
    title: 'payRoll.software.sage.title',
    description: 'payRoll.software.sage.description',
    src: 'https://snapshift-public-assets.s3-eu-west-1.amazonaws.com/integration-images/paie-sage.png',
    url: 'https://guide.combohr.com/fr/articles/1272994-connecter-mon-logiciel-de-caisse-a-combo',
    price: 'payRoll.software.sage.price'
  },
  [SOFTWARE.SILAE]: {
    title: 'payRoll.software.silae.title',
    description: 'payRoll.software.silae.description',
    src: 'https://snapshift-public-assets.s3-eu-west-1.amazonaws.com/integration-images/paie-silae.png',
    url: 'https://guide.combohr.com/fr/articles/1272994-connecter-mon-logiciel-de-caisse-a-combo',
    price: 'payRoll.software.silae.price'
  },
  [SOFTWARE.PAIERH]: {
    title: 'payRoll.software.paieRH.title',
    description: 'payRoll.software.paieRH.description',
    src: 'https://snapshift-public-assets.s3-eu-west-1.amazonaws.com/integration-images/paie-paierh.png',
    url: 'https://guide.combohr.com/fr/articles/1272994-connecter-mon-logiciel-de-caisse-a-combo',
    price: 'payRoll.software.paieRH.price'
  },
  [SOFTWARE.TALENTIA]: {
    title: 'payRoll.software.talentia.title',
    description: 'payRoll.software.talentia.description',
    src: 'https://snapshift-public-assets.s3-eu-west-1.amazonaws.com/settings/payroll-talentia-logo.png',
    url: 'https://guide.combohr.com/fr/articles/1272994-connecter-mon-logiciel-de-caisse-a-combo',
    price: 'payRoll.software.talentia.price'
  },
  [SOFTWARE.PAYFIT]: {
    title: 'payRoll.software.payfit.title',
    description: 'payRoll.software.payfit.description',
    src: 'https://snapshift-public-assets.s3-eu-west-1.amazonaws.com/settings/payfit-square-logo.jpg',
    url: 'https://guide.combohr.com/fr/articles/1272994-connecter-mon-logiciel-de-caisse-a-combo',
    price: 'payRoll.software.payfit.price'
  }
}
