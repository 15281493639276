import { contractsBaseSelectors } from './contractsBaseSelectors'
import { getAllDisplayedContracts } from './getAllDisplayedContracts/getAllDisplayedContracts'
import { getAvailabilitiesDays } from './getAvailabilitiesDays/getAvailabilitiesDays'
import { getBirthdaysByDayAndTeam } from './getBirthdaysByDayAndTeam/getBirthdaysByDayAndTeam'
import { getContractDaysRange } from './getContractDaysRange/getContractDaysRange'
import { getContractsByTeamId } from './getContractsByTeamId/getContractsByTeamId'
import { getDisplayedTeamContracts } from './getDisplayedTeamContracts/getDisplayedTeamContracts'
import { getContractsSortedByShiftStarts } from './getSortedContractByShiftStarts/getContractsSortedByShiftStarts'
import { getSortedTeamContracts } from './getSortedTeamContracts'

export const contractsSelectors = {
  ...contractsBaseSelectors,
  getAllDisplayedContracts,
  getContractsSortedByShiftStarts,
  getSortedTeamContracts,
  getAvailabilitiesDays,
  getContractDaysRange,
  getContractsByTeamId,
  getBirthdaysByDayAndTeam,
  getDisplayedTeamContracts
}
