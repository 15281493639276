import type {
  ChargebeeInvoiceSchema,
  ChurnSubscriptionParams,
  FormulaSchema,
  NewSubscriptionLocationSchema,
  NewSubscriptionSchema,
  ParamsSubscription,
  ParamsUpdateLocation
} from '@libs/data-access/entities'

import type { GetRoute, PatchRoute, PutRoute } from '../../types'

export const billingRoutes = {
  // Subscription
  putSubscriptionBilling: {
    method: 'PUT',
    path: '/billing/v1/subscriptions'
  },
  putSubscriptionPayConnector: {
    method: 'PUT',
    path: '/billing/v1/subscriptions'
  },
  manualReactivationSubscriptionBilling: {
    method: 'PUT',
    path: '/billing/v1/subscriptions'
  },
  churnSubscription: {
    method: 'PATCH',
    path: '/billing/v1/subscriptions/:id/churn'
  },

  // Plan
  getBillingPlan: {
    method: 'GET',
    path: '/billing/v1/plans'
  },

  // Location
  locationBilling: {
    method: 'GET',
    path: '/billing/v1/location_configurations'
  },
  putLocationBilling: {
    method: 'PUT',
    path: '/billing/v1/location_configurations'
  },

  // Addon
  addons: {
    method: 'GET',
    path: '/billing/v1/addons'
  },

  // Invoice
  getInvoiceURL: {
    method: 'GET',
    path: '/billing/v1/invoices/:id'
  },
  getInvoices: {
    method: 'GET',
    path: '/billing/v1/invoices'
  },

  // Estimate
  getEstimate: {
    method: 'GET',
    path: '/billing/v1/estimates/:id'
  }
} as const

export type BillingRoutes = {
  // Subscription
  putSubscriptionPayConnector: PutRoute<
    [],
    {
      pay_connector_activated: boolean
      addon_ids?: string[]
    },
    { account_id: number; include: string[] },
    NewSubscriptionSchema
  >
  putSubscriptionBilling: PutRoute<
    [],
    Partial<ParamsSubscription>,
    { account_id: number; include?: string[] },
    NewSubscriptionSchema
  >
  churnSubscription: PatchRoute<
    ['id'],
    Partial<ChurnSubscriptionParams>,
    {},
    NewSubscriptionSchema
  >
  manualReactivationSubscriptionBilling: PutRoute<
    [],
    { reactivate: boolean },
    { account_id: number; include?: string[] },
    NewSubscriptionSchema
  >

  // Plan
  getBillingPlan: GetRoute<[], {}, any>

  // Location
  locationBilling: GetRoute<
    [],
    { account_id: number },
    NewSubscriptionLocationSchema
  >
  putLocationBilling: PutRoute<
    [],
    ParamsUpdateLocation,
    { account_id: number },
    any[]
  >

  // Addon
  addons: GetRoute<[], {}, FormulaSchema>

  // Invoice
  getInvoiceURL: GetRoute<
    ['id'],
    { disposition_type: string },
    { url: string; id: string }
  >
  getInvoices: GetRoute<
    [],
    { chargebee_subscription_id: string },
    ChargebeeInvoiceSchema
  >

  // Estimate
  getEstimate: GetRoute<['id'], {}>
}
