import { useMutation } from '@tanstack/react-query'

import type { ApiErrorResponse, UseAppMutationOptions } from '../../../types'
import {
  getSignupRelatedAccount,
  type GetSignupRelatedAccountOptions
} from '../auth.api'
import { authCacheKeys } from '../auth.cacheKeys'
import type { GetSignupRelatedAccountResponse } from '../types/CheckRelatedAccount.get'

export const useGetSignupRelatedAccount = (
  mutationOptions?: UseAppMutationOptions<
    GetSignupRelatedAccountResponse,
    ApiErrorResponse,
    GetSignupRelatedAccountOptions
  >
) => {
  return useMutation({
    ...mutationOptions,
    mutationKey: authCacheKeys.getSignupRelatedAccount(),
    mutationFn: (opts: GetSignupRelatedAccountOptions) =>
      getSignupRelatedAccount(opts),

    onSuccess: (data, variables, ...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, ...args)
      }
    }
  })
}
