import { Button, Flex } from '@libs/ui/ds'
import { useOnboardingTranslation } from '@libs/utils/translations'
import { routes } from '@spa/core/router'
import { LanguageSelect } from '@spa/features/i18n'
import { useContext } from 'react'
import { Link } from 'react-router-dom'

import { AuthNavbarContext } from '../shared/services/authNavbar/AuthNavbarContext'

export const AuthNavbarAddons = () => {
  const { t, tCommon } = useOnboardingTranslation()

  const navbarContext = useContext(AuthNavbarContext)

  if (!navbarContext?.state) {
    return null
  }

  return (
    <Flex row className='grow-0 gap-2 sm:gap-4'>
      {navbarContext.state?.languages && (
        <LanguageSelect data-testid='auth-nav-language-input' />
      )}
      {navbarContext.state?.cta?.sign_in && (
        <Flex row className='items-center gap-2'>
          <Button
            data-testid='auth-nav-signin-link-button'
            component={Link}
            to={routes.signIn.rootPath}
            variant='secondary'
            intent='default'
          >
            {tCommon('action.login')}
          </Button>
        </Flex>
      )}
      {navbarContext.state?.cta?.sign_up && (
        <Button
          data-testid='auth-nav-signup-link-button'
          component={Link}
          to={routes.signUp.rootPath}
          variant='secondary'
          intent='default'
        >
          {t('onboarding.common.cta.signup')}
        </Button>
      )}
    </Flex>
  )
}
