import type { PostOauthPasswordGrantPayload } from '@libs/data-access/queries'
import { authService } from '@libs/data-access/queries'
import { useSafeRouterSearchParams } from '@libs/ui/ds'
import {
  SNAPSHIFT_CLIENT_ID,
  SNAPSHIFT_CLIENT_SECRET
} from '@libs/utils/environments'
import type { MutationOptionPostOauthToken } from 'libs/data-access/queries/src/lib/queries/Auth/hooks/usePostOauthToken'
import { useNavigate } from 'react-router-dom'

import { initAuth, isImpersonationFlow } from '../authServiceUtils'

import { useAuthState } from './useAuthState'
import { useImpersonateUserOnSignIn } from './useImpersonateUserOnSignIn'

export const useSignInWithPassword = () => {
  const { updateAuthState } = useAuthState()
  const navigate = useNavigate()
  const postRevokeOauthTokenMutation = authService.usePostRevokeOauthToken()
  const postOauthTokenMutation = authService.usePostOauthToken({
    onError: async () => {
      /**
       * Revoke the refresh token to avoid token leaks in case sign_in fails
       */
      await postRevokeOauthTokenMutation.mutateAsync({
        payload: {
          client_secret: SNAPSHIFT_CLIENT_SECRET,
          client_id: SNAPSHIFT_CLIENT_ID
        }
      })
    }
  })

  const impersonateUser = useImpersonateUserOnSignIn()
  const [searchParams] = useSafeRouterSearchParams()

  return {
    ...postOauthTokenMutation,
    process: async (
      payload: Pick<PostOauthPasswordGrantPayload, 'email' | 'password'>,
      options?: MutationOptionPostOauthToken
    ) => {
      try {
        const authResponse = await postOauthTokenMutation.mutateAsync(
          {
            payload: {
              grant_type: 'password',
              client_secret: SNAPSHIFT_CLIENT_SECRET,
              client_id: SNAPSHIFT_CLIENT_ID,
              ...payload
            }
          },
          options
        )

        const shouldImpersonate = isImpersonationFlow({
          isSuperUser: authResponse.info.super_user
        })

        const handleImpersonateSignin = async () => {
          await impersonateUser(authResponse, searchParams.redirect_to)
        }

        const handleRegularSignin = async () => {
          initAuth({
            authResponse,
            navigate,
            updateAuthState,
            redirectTo: searchParams.redirect_to
          })
        }

        if (shouldImpersonate) {
          await handleImpersonateSignin()
        } else {
          await handleRegularSignin()
        }
      } catch (error) {
        console.error('Error signing in with password', error)
      }
    }
  }
}
