import type {
  NormalizeEntity,
  SignableTimesheetPeriodStatusModel
} from '@libs/data-access/entities'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { forEach, mapValues, merge } from 'lodash-es'

import type { AccountModel } from '../../models/Account'
import type { AlertModel } from '../../models/Alert'
import type { ContractModel } from '../../models/Contract'
import type { ContractCounterModel } from '../../models/ContractCounter'
import type { LabelModel } from '../../models/Label'
import type { LocationModel } from '../../models/Location'
import type { MembershipModel } from '../../models/Membership'
import type { ModulationPeriodModel } from '../../models/ModulationPeriod'
import type { PayPeriodModel } from '../../models/PayPeriod'
import type { PublicHolidayModel } from '../../models/PublicHoliday'
import type { RestModel } from '../../models/Rest'
import type { RowModel } from '../../models/Row'
import type { ShiftModel } from '../../models/Shift'
import type { TeamModel } from '../../models/Team'
import type { WeeklyscheduleModel } from '../../models/Weeklyschedule'

export type EntitiesState = {
  publicHolidays: NormalizeEntity<PublicHolidayModel>
  alerts: NormalizeEntity<AlertModel>
  labels: NormalizeEntity<LabelModel>
  locations: NormalizeEntity<LocationModel>
  memberships: NormalizeEntity<MembershipModel>
  modulationperiods: NormalizeEntity<ModulationPeriodModel>
  teams: NormalizeEntity<TeamModel>
  contracts: NormalizeEntity<ContractModel>
  contractCounters: NormalizeEntity<ContractCounterModel>
  weeklyschedules: NormalizeEntity<WeeklyscheduleModel>
  accounts: NormalizeEntity<AccountModel>
  rests: NormalizeEntity<RestModel>
  shifts: NormalizeEntity<ShiftModel>
  signableTimesheetsPeriods: {
    [teamId: string]: SignableTimesheetPeriodStatusModel
  }
  rows: NormalizeEntity<RowModel>
  payPeriods: NormalizeEntity<PayPeriodModel>
}

export type EntitiesNames = keyof EntitiesState

/**
 * This type is defined as helper in order to provide simpler way to type in selectors
 */
export type EntitiesArrays = {
  publicHolidays: PublicHolidayModel[]
  alerts: AlertModel[]
  labels: LabelModel[]
  locations: LocationModel[]
  memberships: MembershipModel[]
  modulationperiods: ModulationPeriodModel[]
  teams: TeamModel[]
  contracts: ContractModel[]
  contractCounters: ContractCounterModel[]
  weeklyschedules: WeeklyscheduleModel[]
  accounts: AccountModel[]
  rests: RestModel[]
  shifts: ShiftModel[]
  rows: RowModel[]
  payPeriods: PayPeriodModel[]
  signableTimesheetsPeriods: SignableTimesheetPeriodStatusModel[]
}

const initialState: EntitiesState = {
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  publicHolidays: null,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  alerts: null,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  labels: null,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  locations: null,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  memberships: null,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  modulationperiods: null,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  teams: null,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  contracts: null,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  contractCounters: null,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  weeklyschedules: null,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  accounts: null,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  rests: null,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  shifts: null,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  signableTimesheetsPeriods: null,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  rows: null,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  payPeriods: null
}

const entitiesSlice = createSlice({
  name: 'ui/planning/entities',
  initialState,
  reducers: {
    /**
     * Update entities values by id, note: it keeps cached data
     */
    setEntities(state, action: PayloadAction<Partial<EntitiesState>>) {
      forEach(action.payload, (value, key) => {
        state[key] = { ...state[key], ...value }
      })

      return state
    },

    /**
     * Update entities values by id, note: it keeps cached data
     */
    mergeEntities(state, action: PayloadAction<Partial<EntitiesState>>) {
      forEach(action.payload, (value, key) => {
        state[key] = {
          ...state[key],
          ...mapValues(value, (data, id) => {
            return { ...state[key]?.[id], ...data }
          })
        }
      })

      return state
    },

    /**
     * Update entities values by id, note: it keeps cached data
     */
    mergeCountersEntities(
      state,
      action: PayloadAction<Partial<EntitiesState>>
    ) {
      const { contractCounters } = action.payload
      state.contractCounters = merge(state.contractCounters, contractCounters)

      return state
    },

    /**
     * Delete an entity by id
     */
    deleteEntityByIds(
      state,
      action: PayloadAction<{ entity: keyof EntitiesState; ids: number[] }>
    ) {
      const { entity, ids } = action.payload

      ids.forEach(id => {
        delete state[entity][id]
      })

      return state
    },

    /**
     * Re-initialize one entity state
     */
    clearEntity(state, action: PayloadAction<{ entity: keyof EntitiesState }>) {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      state[action.payload.entity] = null
      return state
    },

    /**
     * Re-initialize the entities state
     */
    clearEntities() {
      return { ...initialState }
    }
  }
})

export const {
  deleteEntityByIds,
  mergeEntities,
  clearEntities,
  mergeCountersEntities,
  clearEntity,
  setEntities
} = entitiesSlice.actions

export default entitiesSlice.reducer
