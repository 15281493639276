import { httpServices } from '@libs/data-access/queries'
import { cookiesService } from '@libs/utils/cookies'
import { CLIENT_APP_NAME, HTTP_PROTOCOL } from '@libs/utils/environments'

const formatAuthHeaders = () => {
  const impersonatedMembershipId = cookiesService.readImpersonatedMembershipId()

  return {
    'Account-Id': String(httpServices.authInfo.accountId),
    ...(impersonatedMembershipId && {
      'impersonated-membership-id': String(impersonatedMembershipId)
    })
  }
}

/**
 * Reject for status codes 3xx, 4xx, 5xx
 */
function checkStatus(response: Response): Promise<Response | never> {
  if (response instanceof Error && response.name === 'AbortError') {
    return Promise.reject()
  }

  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response)
  }
  return Promise.reject(response)
}

/**
 * Parse response to create file blob and download it
 */
function promiseFileSuccess(response: Response) {
  return response.blob().then(blob => blob)
}

function promiseFileError(response: Response) {
  if (!response) {
    return Promise.reject.bind(Promise)(response)
  }

  return response.json().then(
    json =>
      Promise.reject.bind(Promise)({
        status: response.status,
        url: response.url,
        ...json
      }),
    error => Promise.reject.bind(Promise)(error)
  )
}

function promiseJsonSucess(response: Response) {
  if (response.status === 204) {
    return {} as any
  }

  const contentType = response.headers.get('content-type')
  try {
    if (contentType && contentType.includes('application/json')) {
      return response.json().then(json => json)
    }
    if (contentType && contentType.includes('text/html')) {
      return response.text().then(text => {
        return text
      })
    }
    throw new Error('Invalid content-type')
  } catch (e) {
    return Promise.reject(e)
  }
}

function promiseJsonError(response: Response) {
  if (!response || !response.text) {
    return Promise.reject.bind(Promise)(response)
  }

  return response
    .text()
    .then(text => {
      try {
        return JSON.parse(text)
      } catch (e: any) {
        throw new Error(`${e.message} - Data : ${text}`)
      }
    })
    .then(
      json =>
        Promise.reject.bind(Promise)({
          status: response.status,
          url: response.url,
          ...json
        }),
      error => Promise.reject.bind(Promise)(error)
    )
}

/**
 * Rest API call using json content-type
 * Pass access-token and custom headers on each request
 *
 * This call is used to fetch data from snap-app service
 */
export const fetchResource = <T extends Response = Response>(
  path: string,
  options: RequestInit & {
    apiUrl: string
  }
): Promise<T> => {
  const { headers: oldHeaders, ...rest } = options
  const headers = {
    ...oldHeaders,
    'Content-Type': 'application/json',
    'x-snapshift-app': CLIENT_APP_NAME,
    ...formatAuthHeaders()
  }

  return fetch(`${HTTP_PROTOCOL}://${options.apiUrl}/api/v2${path}`, {
    credentials: 'include',
    headers,
    ...rest
  })
    .then(checkStatus)
    .then(promiseJsonSucess, promiseJsonError)
}

/**
 * Rest API call used for file downloading
 * Pass access-token and custom headers on each request
 *
 * This call is used to fetch file to download from snap-app service
 */
export const fetchFile = (
  path: string,
  options: RequestInit & {
    apiUrl: string
  }
): Promise<any> => {
  const { headers: oldHeaders, ...rest } = options
  const headers = {
    ...oldHeaders,
    'x-snapshift-app': CLIENT_APP_NAME,
    ...formatAuthHeaders()
  }

  return fetch(`${HTTP_PROTOCOL}://${options.apiUrl}/api/v2${path}`, {
    credentials: 'include',
    headers,
    ...rest
  })
    .then(checkStatus)
    .then(promiseFileSuccess, promiseFileError)
}

/**
 * Rest API call using json content-type
 * Pass access-token and custom headers on each request
 *
 * This call is used to fetch data trough snap-app gateway
 */
export const fetchBillingResource = <T extends Response = Response>(
  path: string,
  options: RequestInit & {
    apiUrl: string
  }
): Promise<T> => {
  const { headers: oldHeaders, ...rest } = options
  const headers = {
    ...oldHeaders,
    'Content-Type': 'application/json',
    'x-snapshift-app': CLIENT_APP_NAME,
    ...formatAuthHeaders()
  }

  return fetch(`${HTTP_PROTOCOL}://${options.apiUrl}/api${path}`, {
    credentials: 'include',
    headers,
    ...rest
  })
    .then(checkStatus)
    .then(promiseJsonSucess, promiseJsonError)
}
