import type { ReduxState } from '@spa/redux/ReduxState'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import { values, keys } from 'lodash-es'
import createCachedSelector from 're-reselect'

import type { EntitiesNames } from '../../../reducers'

/**
 * Return cached map for specific entity
 */
export const createCachedEntitiesMapSelector = createCachedSelector(
  (state: ReduxState, { type }: { type: EntitiesNames }) =>
    state.plannings.entities[type],
  res => res
)((state, { type }) => {
  return fallbackToDefaultCacheKey(type)
})

/**
 * Return cached array for specific entity
 */
export const createCachedEntitiesListSelector = createCachedSelector(
  (state: ReduxState, { type }: { type: EntitiesNames }) =>
    state.plannings.entities[type],
  res => values(res) as any
)((state, { type }) => {
  return type
})

/**
 * Return cached array of ids for specific entity
 */
export const createCachedEntitiesListIdsSelector = createCachedSelector(
  (state: ReduxState, { type }: { type: EntitiesNames }) =>
    state.plannings.entities[type],
  res => keys(res) as any
)((state, { type }) => {
  return type
})

/**
 * Return cached entity by id
 */
export const createCachedEntityByIdSelector = createCachedSelector(
  (state: ReduxState, { type, id }: { type: EntitiesNames; id: number }) =>
    state.plannings.entities[type]?.[id],
  res => res
)((state, { id }) => {
  return fallbackToDefaultCacheKey(id)
})
