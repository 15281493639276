import { isRestCard } from '@spa/constants/RestTypes'
import type { ReduxState } from '@spa/redux/ReduxState'
import { getWeekRange } from '@spa/scenes/Plannings/utils'
import type { MomentInput } from 'moment'
import moment from 'moment'
import createCachedSelector from 're-reselect'
import type { Rest } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import { restsSelectors } from '../../../entities'
import { getDate } from '../../../params'

/**
 * Return displayable rest cards for a given contract
 */
const restIsInWeek = (
  rest: Rest,
  { start, end }: { start: MomentInput; end: MomentInput }
) => {
  return moment(rest.starts_at).isBetween(start, end, 'minute', '[]')
}

/**
 * Return planning rest tubes mapped by week index days
 */
const getRestCards = createCachedSelector(
  (state: ReduxState) => getDate(state),
  (state: ReduxState, { contractId }: { contractId: number }) =>
    restsSelectors.getRestsByContract(state, { contractId }),

  (date, rests) => {
    const { momentStart, momentEnd } = getWeekRange(date)

    const restCards = rests.filter(
      rest =>
        !rest.original_shift_id &&
        isRestCard(rest) &&
        restIsInWeek(rest, {
          start: momentStart,
          end: momentEnd
        })
    )

    return restCards
  }
)((_, { contractId }) => contractId)

export default getRestCards
