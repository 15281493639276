import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_DSN_FILES,
  FETCH_DSN_FILES_SUCCEEDED,
  FETCH_DSN_FILES_FAILED,
  CREATE_DSN_FILES,
  CREATE_DSN_FILES_SUCCEEDED,
  CREATE_DSN_FILES_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'
import {
  subscribeToChannel,
  unsubscribeFromChannel,
  sendMessageToChannel
} from '../socket'
import { isConnectedToChannel } from '../socket/selectors'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchDsnFiles = (accountId, resolve, reject) => ({
  type: FETCH_DSN_FILES,
  accountId,
  resolve,
  reject
})

export const createDsnFiles = (params, resolve, reject) => ({
  type: CREATE_DSN_FILES,
  params,
  resolve,
  reject
})

// ------------------------------------
// Socket Actions
// ------------------------------------

const CHANNEL_NAME = 'DSN::FileChannel'

export const isConnectedToDsnFileChannel = state =>
  isConnectedToChannel(state, CHANNEL_NAME)
export const subscribeToDsnFileChannel = () => subscribeToChannel(CHANNEL_NAME)
export const unsubscribeFromDsnFileChannel = () =>
  unsubscribeFromChannel(CHANNEL_NAME)
const sendMessageToDsnFileChanel = (
  messageType,
  messageData,
  resolve,
  reject
) =>
  sendMessageToChannel(CHANNEL_NAME, messageType, messageData, resolve, reject)
export const downloadFile = (dsnFileId, messageId, resolve, reject) =>
  sendMessageToDsnFileChanel(
    'download',
    { dsn_file_id: dsnFileId, message_id: messageId },
    resolve,
    reject
  )

// ------------------------------------
// Sagas
// ------------------------------------

const fetchDsnFilesSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_DSN_FILES_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_DSN_FILES_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/dsn_files?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchDsnFiles({ accountId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    fetchDsnFilesSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

const createDsnFileSagaEntity = {
  success: data => ({
    type: CREATE_DSN_FILES_SUCCEEDED,
    data
  }),
  failure: error => ({
    type: CREATE_DSN_FILES_FAILED,
    error
  }),

  fetchAPI: (id, options) =>
    callAPI('/dsn_files', { method: 'POST', ...options })
}

export function* doCreateDsnFiles({ params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createDsnFileSagaEntity,
    null,
    null,
    params,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const DsnFile = Immutable.Record({
  id: null,
  account_id: null,
  document: null,
  events: null,
  generated_at: null
})

const dsnFilesForeignKeys = ['account_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function dsnFiles(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case CREATE_DSN_FILES:
    case FETCH_DSN_FILES: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case CREATE_DSN_FILES_FAILED:
    case FETCH_DSN_FILES_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case CREATE_DSN_FILES_SUCCEEDED: {
      return state
    }
    case FETCH_DSN_FILES_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const dsnFiles = Immutable.fromJS(action.data)

      return mergeRecords(
        state,
        DsnFile,
        dsnFiles,
        // @ts-ignore migration from js(x) to ts(x)
        dsnFilesForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    default:
      return state
  }
}

export default filterActions(dsnFiles, [
  CREATE_DSN_FILES_SUCCEEDED,
  CREATE_DSN_FILES,
  CREATE_DSN_FILES_FAILED,
  FETCH_DSN_FILES,
  FETCH_DSN_FILES_SUCCEEDED,
  FETCH_DSN_FILES_FAILED
])
