import type { LedgerOperation } from '@libs/data-access/entities'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

type StateType = {
  data: {
    paid_leave_ledger_operations: LedgerOperation[]
    pagination: {
      loading: boolean
      current_page?: number
      per_page?: number
      total_count?: number
    }
  }
}

const initialState: StateType = {
  data: {
    paid_leave_ledger_operations: [],
    pagination: {
      loading: true
    }
  }
}

const slice = createSlice({
  name: 'data/contracts/paidLeaveLedgerOp',
  initialState,
  reducers: {
    setData(
      state,
      action: PayloadAction<{
        data: {
          paid_leave_ledger_operations: LedgerOperation[]
          meta: { loading: boolean }
        }
      }>
    ) {
      const { payload } = action

      return {
        ...state,
        data: {
          paid_leave_ledger_operations: [
            ...payload.data.paid_leave_ledger_operations
          ],
          pagination: { ...payload.data.meta, loading: false }
        }
      }
    },
    setLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        data: {
          paid_leave_ledger_operations: [
            ...state.data.paid_leave_ledger_operations
          ],
          pagination: { ...state.data.pagination, loading: action.payload }
        }
      }
    }
  }
})

export const paidLeaveLedgerOperationReducerActions = slice.actions
export default slice.reducer
