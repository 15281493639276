import type { MomentInput } from 'moment'
import moment from 'moment'

const DATE_GRANULARITY = 'minutes'

/**
 * Get minutes difference between two dates
 */
export const getMinutesDiff = (date: MomentInput, compareDate: MomentInput) => {
  const diff = moment(date).diff(compareDate, DATE_GRANULARITY)
  return Math.round(diff)
}

/**
 * Add minutes to the given date
 */
export const addMinutes = (date: MomentInput, minutes: number) => {
  return moment(date).add(minutes, DATE_GRANULARITY)
}

export const resetSecondAndMilliseconds = (date: MomentInput) => {
  return moment(date).set({ second: 0, millisecond: 0 }).format()
}
