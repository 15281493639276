import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { PlanModel } from '../models/PlanModel'

export type ParamsState = {
  include?: 'addons' | 'invoices'
}

const initialState: PlanModel = [
  {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    chargebee_id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    billing_frequency: null
  }
]

const entitiesSlice = createSlice({
  name: 'billing/plan/entities',
  initialState,
  reducers: {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    setPlan(state, action: PayloadAction<Partial<PlanModel>>) {
      return { ...state, ...action.payload }
    }
  }
})

export const { setPlan } = entitiesSlice.actions
// @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
export default entitiesSlice.reducer
