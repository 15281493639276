import type { Table } from '@tanstack/react-table'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'

import type { TableProps } from '../../Table.types'
import { tableClasses } from '../tableClasses'

import { TableHeaderCell } from './TableHeaderCell'

const tableHeaderCVA = {
  headerRow: cva([tableClasses.headerRow], {
    variants: {
      headerPosition: {
        sticky: 'sticky top-0 z-10',
        initial: ''
      },
      isPrincipalHeader: {
        true: 'border-b-[5px] border-solid border-neutral-50'
      }
    }
  })
}

type TableHeaderProps = {
  loading?: boolean
  showHeader?: TableProps['showHeader']
  headerPosition?: TableProps['headerPosition']
  table: Table<any>
  cellsPaddingSize?: TableProps['cellsPaddingSize']
}

export const TableHeader: FC<TableHeaderProps> = ({
  loading,
  table,
  showHeader,
  headerPosition,
  cellsPaddingSize
}) => {
  if (!showHeader) {
    return null
  }

  return (
    <thead>
      {table.getHeaderGroups().map((headerGroup, index) => {
        const isPrincipalHeader =
          table.getHeaderGroups().length > 1 && index === 0
        return (
          <tr
            key={headerGroup.id}
            className={tableHeaderCVA.headerRow({
              headerPosition,
              isPrincipalHeader
            })}
          >
            {headerGroup.headers.map(header => (
              <TableHeaderCell
                isPrincipalHeader={isPrincipalHeader}
                loading={loading}
                key={header.id}
                header={header}
                cellsPaddingSize={cellsPaddingSize}
              />
            ))}
          </tr>
        )
      })}
    </thead>
  )
}
