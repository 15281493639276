import type { ReduxState } from '@spa/redux/ReduxState'
import { formatToIsoDay } from '@spa/utils/date'
import { groupBy } from 'lodash-es'
import createCachedSelector from 're-reselect'

import { HALF_HOUR_IN_MINUTES, ONE_HOUR_IN_MINUTES } from '../constants'
import {
  getDatesArrayWithMinutesInterval,
  selectInterval
} from '../getDatesArrayWithMinutesInterval/getDatesArrayWithMinutesInterval'

export type IsoHoursDaysDistributions = {
  [key: string]: string[]
}

/**
 * Retrive array of isoDates for each hours between a timeRange
 * Then split the array for each days
 */
export const getDatesIntervalMappedByDay = createCachedSelector(
  getDatesArrayWithMinutesInterval,
  hoursTimeRangeDays => {
    const daysMap = groupBy(hoursTimeRangeDays, formatToIsoDay)
    return daysMap as IsoHoursDaysDistributions
  }
)(selectInterval)

/**
 * Get hours interval dates map by day
 */
export const getHoursIntervalMappedByDay = (state: ReduxState) => {
  return getDatesIntervalMappedByDay(state, { interval: ONE_HOUR_IN_MINUTES })
}

/**
 * Return half hours intervals map by day
 */
export const getHalfHoursIntervalMappedByDay = (state: ReduxState) => {
  return getDatesIntervalMappedByDay(state, { interval: HALF_HOUR_IN_MINUTES })
}
