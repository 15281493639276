import type { DocumentTemplateAttributesSchema } from '@libs/data-access/entities'
import type { ApiErrorResponse } from '@libs/data-access/queries'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import { setDocumentAttributes } from '../modules/entities'
import { setMetaLoading, setMetaSuccess, setMetaError } from '../modules/meta'

export default function* fetchDocumentTemplateAttributes() {
  yield put(setMetaLoading())

  try {
    const result = yield apiRequest({
      service: 'core',
      route: 'fetchDocumentTemplateAttributes'
    })

    const formattedResult = {
      attributes: { ...result.attributes, delimiters: result.delimiters }
    } as any

    // Doing this because the result type is lost in the apiRequest result object

    yield put(
      setDocumentAttributes(formattedResult as DocumentTemplateAttributesSchema)
    )
    yield put(setMetaSuccess())
  } catch (err) {
    yield put(setMetaError(err as ApiErrorResponse))
  }
}
