import { put, select } from 'redux-saga/effects'
import { createNote } from 'snap-redux/modules/notes'

import { weeklyschedulesSelectors } from '../../../selectors'
import type { PayloadActionWithoutType } from '../../types/types'

type Params = PayloadActionWithoutType<{
  noteId: number
  to: {
    weeklyscheduleId: number
    date: 'YYYY-MM-DD' | string
  }
}>

function* copyNote({ payload }: Params) {
  const {
    noteId,
    to: { date, weeklyscheduleId }
  } = payload

  const weeklyschedule = yield select(state =>
    weeklyschedulesSelectors.getCachedById(state, weeklyscheduleId)
  )

  const noteIndex = weeklyschedule.daily_notes.findIndex(
    note => note.id === noteId
  )

  if (noteIndex === -1) {
    return
  }

  const { id: _, ...note } = weeklyschedule.daily_notes[noteIndex]

  // @ts-ignore migration from js(x) to ts(x)
  yield put(createNote({ ...note, date, weeklyschedule_id: weeklyscheduleId }))
}

export default copyNote
