import type { ApiErrorResponse } from '@libs/data-access/queries'
import { notifications } from '@libs/ui/ds'
import i18n from '@libs/utils/translations'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import { deleteDocumentSignature } from '../../DocumentSignature/modules/entities'
import type { ParamsDeleteDocumentEmployee } from '../modules/entities'
import { deleteDocumentEmployee } from '../modules/entities'
import { setMetaLoading, setMetaSuccess, setMetaError } from '../modules/meta'

export default function* deleteDocumentEmployeeData(
  params: ParamsDeleteDocumentEmployee
) {
  yield put(setMetaLoading())

  try {
    const promise = apiRequest({
      service: 'core',
      route: 'deleteDocumentEmployee',
      params: { document_id: params.document_id }
    })

    notifications.promise(promise, {
      loading: {
        message: i18n.t<any>(
          'common.notifications:documentEmployee.delete.loading'
        )
      },
      success: {
        message: i18n.t<any>(
          'common.notifications:documentEmployee.delete.success'
        )
      },
      apiError: true
    })

    yield promise

    yield put(deleteDocumentEmployee({ id: params.document_id }))
    if (params.signable_id) {
      yield put(deleteDocumentSignature({ id: params.signable_id }))
    }

    yield put(setMetaSuccess())
  } catch (err) {
    yield put(setMetaError(err as ApiErrorResponse))
  }
}
