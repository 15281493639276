import { Button } from '@libs/ui/ds/lib/components/buttons/Button/base/Button'
import { Flex } from '@libs/ui/ds/lib/components/primitiveLayouts/Flex/base/Flex'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import { memo } from 'react'

import { Image } from '../../../dataDisplay/Image/base/Image'
import { Text } from '../../../typography/Text/base/Text'
import { Title } from '../../../typography/Title/base/Title'

import type { EmptyContentProps } from './EmptyContent.types'

const emptyContentCVA = {
  root: cva(['relative items-center text-center']),
  textSection: cva([''], {
    variants: {
      size: {
        s: 'my-4',
        m: 'mb-4 mt-8'
      }
    }
  }),
  props: {
    title: {
      m: 2,
      s: 6
    },
    desc: {
      m: 'm',
      s: 'tiny'
    },
    image: {
      s: 'xs',
      m: 's'
    }
  }
} as const

export const EmptyContent: FC<EmptyContentProps> = memo(
  ({
    bottomSection = null,
    imageSrc,
    title,
    className,
    desc,
    size = 'm',
    action,
    ...other
  }) => {
    return (
      <Flex {...other} className={emptyContentCVA.root({ className })}>
        {imageSrc && (
          <Image
            size={emptyContentCVA.props.image[size]}
            src={imageSrc}
            alt={title}
          />
        )}
        <div className={emptyContentCVA.textSection({ size })}>
          <Title as='h1' size={emptyContentCVA.props.title[size]}>
            {title}
          </Title>
          {desc && (
            <Text size={emptyContentCVA.props.desc[size]} className='mt-1'>
              {desc}
            </Text>
          )}
        </div>
        {action?.onClick && (
          <Button
            onClick={action.onClick}
            variant='primary'
            intent='default'
            size={size}
            disabled={action?.disabled}
            leftIcon={action?.leftIcon}
            data-testid={action?.['data-testid']}
          >
            {action.label}
          </Button>
        )}
        {bottomSection}
      </Flex>
    )
  }
)

EmptyContent.displayName = 'EmptyContent'
export type { EmptyContentProps } from './EmptyContent.types'
