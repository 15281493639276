import type { ComboboxTargetProps as MantineComboboxTargetProps } from '@mantine/core'
import { ComboboxTarget as MantineComboboxTarget } from '@mantine/core'
import { forwardRef } from 'react'

export type ComboboxTargetProps = MantineComboboxTargetProps

export const ComboboxTarget = forwardRef<HTMLElement, ComboboxTargetProps>(
  ({ children, ...other }, ref) => {
    return (
      <MantineComboboxTarget ref={ref} {...other}>
        {children}
      </MantineComboboxTarget>
    )
  }
)
