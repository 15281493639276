import { useUncontrolled } from '@libs/ui/ds'
import type { DropzoneProps } from '@libs/ui/ds'
import type { FileWithPath } from '@mantine/dropzone'

type Params = {
  onChange: DropzoneProps['onChange']
  value: DropzoneProps['value']
  multiple: DropzoneProps['multiple']
}

export const useFilesState = ({ onChange, value, multiple }: Params) => {
  const [files, setFiles] = useUncontrolled<FileWithPath[]>({
    onChange,
    value,
    defaultValue: []
  })

  const deleteFile = (item: FileWithPath) => {
    const arr = [...files]
    const index = arr.findIndex(file => file.name === item.name)
    arr.splice(index, 1)

    return setFiles(arr)
  }

  const addFile = (file: FileWithPath[]) => {
    if (!multiple) {
      setFiles(file)
    } else {
      setFiles([...(files || []), file].flat())
    }
  }

  return { files, addFile, deleteFile }
}
