// Routing reducer

import { combineReducers } from '@reduxjs/toolkit'
import { reducer as billingReducer } from '@spa/redux/billing'
import documentEmployee from '@spa/redux/emp/DocumentEmployee/reducer'
import documentSignature from '@spa/redux/emp/DocumentSignature/reducer'
import documentTemplate from '@spa/redux/emp/DocumentTemplate/reducer'
import missingInformation from '@spa/redux/emp/MissingInformation/reducer'
import paidLeavesLedger from '@spa/redux/emp/PaidLeavesLedger/reducer'
import restModal from '@spa/scenes/Plannings/Modals/RestModal/redux/reducer'
import plannings from '@spa/scenes/Plannings/redux/reducers/combineReducers'
import root, { rootReducer } from 'snap-redux/rootReducer'

import planningWeekly from './planning/planningWeekly/reducer'

export default function createReducer() {
  return combineReducers({
    // Immer

    documentEmployee,
    documentSignature,
    documentTemplate,
    paidLeavesLedger,
    missingInformation,
    plannings,
    restModal,
    ...rootReducer,
    billing: billingReducer,
    // ImmutableJS
    planningWeekly,
    ...root
  })
}
