import { paidLeaveForecast } from 'snap-redux/infra/http/routesConfig/scopes/paidLeave'
import { publicHolidayRoutes } from 'snap-redux/infra/http/routesConfig/scopes/publicHolidays/routes'

import type { RoutesName } from './routesConfig.types'
import { bankHolidayAddressesRoutes } from './scopes/bankHolidayAddresses'
import { billingRoutes } from './scopes/billing'
import { countersRoutes } from './scopes/counters'
import { countryConfigurationsRoutes } from './scopes/countryConfigurations'
import { empRoutes } from './scopes/emp'
import { membershipRoutes } from './scopes/memberships'
import { modulationPeriodCountersRoutes } from './scopes/modulationPeriodCounters'
import { paidLeaveLedgerOperationsRoutes } from './scopes/paidLeaveLedgerOperations'
import { paidLeavesConfigurationsRoutes } from './scopes/paidLeavesConfigurations'
import { payPeriodRoutes } from './scopes/payPeriods/routes'
import { shiftsRoutes } from './scopes/shifts'
import { signableTimesheetsRoutes } from './scopes/signableTimesheets'
import { timesheetsRoutes } from './scopes/timesheet'
import { userContractCountersRoutes } from './scopes/userContractCounters'

type RoutesConfig = {
  [key in RoutesName]: {
    method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'
    path: string
  }
}

/**
 * Conventions:
 *
 * POST single: postUser
 * POST multiple: postUsers
 *
 * GET single: getUser
 * GET multiple: getUsers
 *
 * PUT single: putUser
 * PUT multiple: putUsers
 *
 * DELETE single: deleteUser
 * DELETE multiple: deleteUsers
 */

const config: RoutesConfig = {
  /**
   * /integrations
   */
  postADPIntegration: {
    method: 'POST',
    path: '/integrations/adp/check_credentials'
  },
  postSyncADPIntegration: {
    method: 'POST',
    path: '/integrations/adp/sync_all_users'
  },

  /**
   * /labels
   */
  getLabelsByAccount: {
    method: 'GET',
    path: '/accounts/:account_id/labels'
  },

  /**
   * /location
   */
  getLocations: {
    method: 'GET',
    path: '/accounts/:account_id/locations'
  },

  /**
   * /reports
   */
  postReport: { method: 'POST', path: '/reports' },
  getReport: { method: 'GET', path: '/reports/:id' },
  putReport: { method: 'PUT', path: '/reports/:id' },

  /**
   * /weeklyschedules
   */
  putWeeklyschedule: { method: 'PUT', path: '/weeklyschedules/:id' },
  getWeeklyschedules: {
    method: 'GET',
    path: '/accounts/:account_id/weeklyschedules'
  },
  getWeeklyschedule: {
    method: 'GET',
    path: '/weeklyschedules/:id'
  },

  ...bankHolidayAddressesRoutes,
  ...billingRoutes,
  ...countersRoutes,
  ...countryConfigurationsRoutes,
  ...empRoutes,
  ...membershipRoutes,
  ...modulationPeriodCountersRoutes,
  ...paidLeaveLedgerOperationsRoutes,
  ...paidLeavesConfigurationsRoutes,
  ...paidLeaveForecast,
  ...payPeriodRoutes,
  ...publicHolidayRoutes,
  ...shiftsRoutes,
  ...signableTimesheetsRoutes,
  ...timesheetsRoutes,
  ...userContractCountersRoutes
}

export default config
