import { InformationCircleIcon } from '@libs/ui/ds/assets/icons'
import { cva } from 'class-variance-authority'
import { forwardRef, memo } from 'react'

import { IconWrap } from '../../../dataDisplay/IconWrap/base/IconWrap'
import { Tooltip } from '../../../overlays/Tooltip/base/Tooltip'
import { Flex } from '../../../primitiveLayouts/Flex/base/Flex'

import type { InlineInputWrapExtendedProps } from './InlineInputWrap.types'

const inlineInputWrapCVA = {
  root: cva(['flex gap-3'], {
    variants: {
      fillWidth: {
        true: 'w-full justify-between'
      },
      disabled: {
        true: 'cursor-default',
        false: 'cursor-pointer'
      },
      position: {
        top: 'flex-col-reverse',
        bottom: 'flex-col',
        left: 'flex-row-reverse',
        right: 'flex-row'
      },
      standalone: {
        true: 'items-center'
      }
    }
  }),
  labelRoot: cva(['flex gap-3'], {
    variants: {
      fillWidth: {
        true: 'flex-1 justify-between'
      },
      disabled: {
        true: 'cursor-default',
        false: 'cursor-pointer'
      },
      position: {
        top: 'flex-col',
        bottom: 'flex-col-reverse',
        left: 'flex-row',
        right: 'flex-row-reverse'
      },
      standalone: {
        true: 'items-center'
      }
    }
  }),
  labelSection: cva([''], {
    variants: {
      disabled: {
        true: 'opacity-60'
      },
      disableLabelInteraction: {
        true: 'pointer-events-none'
      }
    }
  }),
  asterisk: cva(['ml-1 text-xs font-semibold leading-4 text-semantic-red-400']),
  label: cva(['text-s font-medium leading-5 text-neutral-800'], {
    variants: {
      standalone: {
        true: '',
        false: 'min-h-8 py-2'
      }
    }
  }),
  desc: cva([
    'whitespace-pre-line text-xs font-normal leading-4 text-neutral-500'
  ]),
  errorMessage: cva(['text-xs font-normal leading-4 text-semantic-red-400']),
  fieldSection: cva(['flex items-center'], {
    variants: {
      position: {
        left: 'h-8',
        right: 'h-8',
        top: '',
        bottom: ''
      }
    }
  })
}

export const InlineInputWrap = memo(
  forwardRef<HTMLDivElement, InlineInputWrapExtendedProps>(
    (
      {
        label,
        desc,
        endSection,
        infoTooltipContent,
        errorMessage,
        required,
        disabled = false,
        disableLabelInteraction = false,
        fillWidth,
        position = 'left',
        field,
        className,
        ...other
      },
      ref
    ) => {
      const displayLabelSection = label || desc || errorMessage
      const standalone = !desc && !errorMessage

      return (
        <Flex
          row
          className={inlineInputWrapCVA.root({
            fillWidth,
            position,
            disabled: disabled || disableLabelInteraction,
            standalone
          })}
        >
          <label
            {...other}
            /**
             * Disable label interaction by assigning invalid [htmlFor] string id
             */
            htmlFor={disableLabelInteraction ? '_' : undefined}
            className={inlineInputWrapCVA.labelRoot({
              className,
              position,
              fillWidth,
              disabled: disabled || disableLabelInteraction,
              standalone
            })}
          >
            {displayLabelSection && (
              <Flex
                className={inlineInputWrapCVA.labelSection({
                  disabled
                })}
              >
                {label && (
                  <Flex row className='items-center gap-2'>
                    <span className={inlineInputWrapCVA.label({ standalone })}>
                      {label}
                      {required && (
                        <span className={inlineInputWrapCVA.asterisk()}>*</span>
                      )}
                    </span>
                    {infoTooltipContent && (
                      <Tooltip label={infoTooltipContent}>
                        <IconWrap icon={<InformationCircleIcon />} size='s' />
                      </Tooltip>
                    )}
                  </Flex>
                )}
                {desc && (
                  <span className={inlineInputWrapCVA.desc()}>{desc}</span>
                )}
                {errorMessage && (
                  <span className={inlineInputWrapCVA.errorMessage()}>
                    {errorMessage}
                  </span>
                )}
              </Flex>
            )}
            <div
              className={inlineInputWrapCVA.fieldSection({ position })}
              ref={ref}
            >
              {field}
            </div>
          </label>
          {endSection && <div>{endSection}</div>}
        </Flex>
      )
    }
  )
)

InlineInputWrap.displayName = 'InlineInputWrap'
export type { InlineInputWrapProps } from './InlineInputWrap.types'
