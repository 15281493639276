import type { ParamsUpdateLocation } from '@libs/data-access/entities'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSliceSaga, SagaType } from 'redux-toolkit-saga'

import fetchLocationBillingData from '../generator/fetchLocationBillingData'
import updateLocationBillingData from '../generator/updateLocationBillingData'

import type { FetchBillingDataParams } from './entities'

const locationBillingSagaSlice = createSliceSaga({
  name: 'billing/locationBilling/sagas',
  caseSagas: {
    /**
     * Fetch Locations from snap billing service
     */
    *fetchLocationBilling(params: FetchBillingDataParams) {
      yield fetchLocationBillingData(params)
    },

    /**
     * Create/Update locations from snap billing service
     */
    *updateLocationBilling({ payload }: PayloadAction<ParamsUpdateLocation>) {
      yield updateLocationBillingData(payload)
    }
  },
  sagaType: SagaType.Watch
})

export const { fetchLocationBilling, updateLocationBilling } =
  locationBillingSagaSlice.actions

export default locationBillingSagaSlice.saga()
