import { httpServices } from '../../../apis'
import type { QueryFnParams } from '../../../types'

export type CardPaymentLocationBasedOptions = QueryFnParams<{
  queryParams: {
    location_group_id: string
  }
  payload: {
    stripe_token: string
  }
}>

export const cardPaymentLocationBased = async (
  options: CardPaymentLocationBasedOptions
) => {
  const response = await httpServices.billing.post(
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    `/location-based/location-groups/${options.queryParams.location_group_id}/payment/card`,
    { ...options.payload, snap_app_account_id: httpServices.authInfo.accountId }
  )

  return response.data
}
