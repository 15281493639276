import type { ReduxState } from '@spa/redux/ReduxState'
import { sortBy } from 'lodash-es'
import { createSelector } from 'reselect'

import { getParams } from '../../../params'
import { locationsSelectors } from '../../locations'

/**
 * Returns selected teams, depending on params we either have one team or all location teams selected
 */
export const getCurrentSelectedTeams = createSelector(
  (state: ReduxState) => getParams(state).teamId,
  (state: ReduxState) =>
    locationsSelectors.getCachedById(state, getParams(state).locationId),
  (teamId, location) => {
    return teamId ? [teamId] : sortBy(location?.team_ids || [])
  }
)
