import type {
  AvailabilityDetails,
  AvailabilityOption
} from '@libs/data-access/entities'
import { AVAILABLE } from '@libs/data-access/entities'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import { isPlainObject } from 'lodash-es'
import moment from 'moment'
import createCachedSelector from 're-reselect'

import type { ContractModel } from '../../../../models/Contract'
import { getIsoDaysInTimeRange } from '../../../timeRange'
import { contractsBaseSelectors } from '../contractsBaseSelectors'

type AvailabilityItem = {
  details: AvailabilityDetails
  availability: AvailabilityOption
}

export type AvailabilitiesSchema = {
  [x: string]: AvailabilityItem
}

export function groupAvailabilitiesByDay(
  availabilities: ContractModel['availabilities'],
  isoDays: string[]
): AvailabilitiesSchema {
  const entries = Object.entries(availabilities)

  const availabilitiesMap = entries.reduce(
    (map, [key, value]) => {
      const dayNumber = parseInt(key.charAt(key.length - 1), 10) // last char is the day index
      const isAvailableKey = key.indexOf('details') === -1
      const isDetailsKey = key.indexOf('details') > -1

      // if not available create item inside availabilities map for the given day
      if (!map[dayNumber] && isAvailableKey && value.toString() !== AVAILABLE) {
        map[dayNumber] = {
          details: null,
          availability: value
        }
      }

      if (
        isDetailsKey &&
        typeof map[dayNumber] === 'object' &&
        map[dayNumber] !== null &&
        typeof map[dayNumber].details !== 'undefined' &&
        typeof map[dayNumber].availability !== 'undefined'
      ) {
        map[dayNumber].details = value
      }
      return map
    },
    {
      0: null,
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null
    }
  )

  return isoDays.reduce((acc, day) => {
    return {
      ...acc,
      ...{ [day]: availabilitiesMap[moment(day).day()] }
    }
  }, {})
}

export function hasAvailabilities(value: AvailabilitiesSchema) {
  const days = Object.values(value)

  return days.length && days.some(value => value !== null)
}

/**
 * Return contract week availabilities
 */
export const getAvailabilitiesDays = createCachedSelector(
  contractsBaseSelectors.getCachedById,
  getIsoDaysInTimeRange,

  (contract, isoDays) => {
    if (!contract?.availabilities) {
      return null
    }

    if (isPlainObject(contract?.availabilities)) {
      const availabilitiesWeekDays = groupAvailabilitiesByDay(
        contract.availabilities,
        isoDays
      )

      /**
       * If there is no availabilities on any days return null
       */
      return hasAvailabilities(availabilitiesWeekDays)
        ? availabilitiesWeekDays
        : null
    }

    return null
  }
)((_, contractId: number) => fallbackToDefaultCacheKey(contractId))
