import { Badge, Tooltip } from '@libs/ui/ds'
import { useMemberPageTranslation } from '@libs/utils/translations'
import React from 'react'
import type { FC } from 'react'
import { useAuthorizedFeaturePolicies } from 'snap-redux/modules/countryConfigurations/selectors'

import { isValueInDPAE, isValueInSSR } from './utils'
import { isWorkPermit } from './utils/isWorkPermit'
import { workPermitFormatter } from './utils/workPermitFormatter'

type DotInfoRenderProps = {
  name: string
}
const DotInfoRender: FC<DotInfoRenderProps> = ({ name }) => {
  const { t } = useMemberPageTranslation()

  return (
    <Tooltip position='left' label={t(`inforDotMissing.${name}.description`)}>
      <Badge
        color='neutral'
        variant='light'
        label={t(`inforDotMissing.${name}.text`)}
        size='xs'
      />
    </Tooltip>
  )
}

type Props = {
  nameInput: string
  dpaeAvailable: boolean
  isValueFilled: boolean
  onlyDpae?: boolean
}

const InfoDotMissingValueComponent: FC<Props> = ({
  nameInput,
  dpaeAvailable,
  isValueFilled,
  onlyDpae = false
}) => {
  const { authorizedDpae, authorizedRup } = useAuthorizedFeaturePolicies()

  const value = isWorkPermit(nameInput)
    ? workPermitFormatter(nameInput)
    : nameInput

  const dpaeValue = isValueInDPAE(value)
  const SSRValue = isValueInSSR(value)

  const shouldDisplayStaffRegister = authorizedRup && SSRValue && !onlyDpae
  const shouldDisplayDpae = authorizedDpae && dpaeAvailable && dpaeValue

  if (isValueFilled || (!shouldDisplayStaffRegister && !shouldDisplayDpae)) {
    return null
  }

  return (
    <>
      {shouldDisplayDpae && <DotInfoRender name='dpae' />}
      {shouldDisplayStaffRegister && <DotInfoRender name='staffRegister' />}
    </>
  )
}

export const InfoDotMissingValue = React.memo(InfoDotMissingValueComponent)
