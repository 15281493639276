export const SearchIcon = () => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.75999 0.00806198C7.85047 0.064566 7.02868 0.244694 6.21646 0.565574C4.82335 1.11594 3.65459 1.98656 2.73895 3.15597C1.8252 4.32299 1.23396 5.75521 1.05632 7.23197C0.990946 7.77548 0.980946 8.3811 1.02832 8.92797C1.14186 10.2387 1.57286 11.5061 2.29019 12.6387C2.95332 13.6857 3.90199 14.6091 4.97599 15.2531C6.60043 16.2271 8.48475 16.6115 10.36 16.3516C11.7807 16.1546 13.2072 15.543 14.3193 14.6539L14.4185 14.5746L17.0473 17.2021C19.7582 19.9118 19.7066 19.8614 19.828 19.9221C20.0151 20.0157 20.2762 20.0246 20.4886 19.9447C20.5934 19.9052 20.7305 19.8036 20.811 19.7057C20.934 19.556 20.9888 19.4156 20.9977 19.2273C21.0066 19.0364 20.9674 18.889 20.8659 18.732C20.8375 18.6881 20.2625 18.1085 18.2018 16.0469L15.574 13.4179L15.6379 13.3389C15.9443 12.9605 16.2734 12.4524 16.5125 11.9881C16.9727 11.0948 17.2663 10.1298 17.3802 9.13597C17.4406 8.60898 17.447 7.93321 17.3962 7.43597C17.2889 6.38609 17.0229 5.46496 16.5587 4.53597C16.2686 3.95558 15.9581 3.47576 15.5568 2.98797C15.2637 2.63168 14.8462 2.21126 14.4867 1.91058C13.7452 1.29022 12.9034 0.809934 11.968 0.47351C11.2774 0.225118 10.6014 0.082782 9.79199 0.015358C9.66359 0.00466198 8.90146 -0.000730018 8.75999 0.00806198ZM8.85999 1.64C7.85555 1.70081 6.93524 1.96377 6.05199 2.44234C5.09979 2.95827 4.24793 3.75466 3.66678 4.67225C2.76986 6.08841 2.4417 7.76305 2.74023 9.40048C2.94455 10.5211 3.47532 11.6379 4.20769 12.488C5.04672 13.4619 6.09844 14.1455 7.33471 14.5205C8.28807 14.8097 9.35414 14.873 10.344 14.6993C11.2426 14.5415 12.1287 14.185 12.8898 13.675C13.8452 13.0347 14.6406 12.1178 15.1354 11.0862C15.7167 9.87424 15.9171 8.58193 15.728 7.26365C15.5976 6.35466 15.2505 5.43109 14.741 4.63783C14.5021 4.26581 14.2354 3.93389 13.9082 3.60126C13.1726 2.85354 12.3084 2.3138 11.304 1.97484C10.7903 1.80147 10.2895 1.69822 9.73599 1.65157C9.56298 1.63698 9.02675 1.6299 8.85999 1.64Z'
        fill='currentColor'
      />
    </svg>
  )
}
