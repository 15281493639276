import { useUncontrolledInput } from '@libs/ui/ds'
import { cva } from 'class-variance-authority'
import { forwardRef, memo } from 'react'

import { InputWrap } from '../../../forms/InputWrap/base/InputWrap'
import { formatMaxLengthCounter } from '../../../forms/InputWrap/utils/formatMaxLengthCounter'
import { InputBase } from '../../InputBase/base/InputBase'
import { onWheelPreventChange } from '../utils/onWheelPreventChange'

import type { NumberInputProps } from './NumberInput.types'

const numberInputCVA = {
  input: cva([], {
    variants: {
      showArrows: {
        true: '',
        false: [
          '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none',
          '[&::-webkit-inner-spin-button]:appearance-none'
        ]
      }
    }
  })
}

const BaseNumberInput = memo(
  forwardRef<HTMLInputElement, NumberInputProps>(
    (
      {
        label,
        desc,
        errorMessage,
        helperText,
        required,
        disabled = false,
        fillWidth,
        className,
        name,
        labelRightSection,
        placeholder,
        maxLength,
        value,
        onChange,
        classNames = {},
        showArrows = false,
        ...other
      },
      ref
    ) => {
      const [controlledValue, setControlledValue] = useUncontrolledInput({
        value,
        onChange
      })

      return (
        <InputWrap
          label={label}
          desc={desc}
          errorMessage={errorMessage}
          helperText={
            formatMaxLengthCounter({ value: controlledValue, maxLength }) ||
            helperText
          }
          labelRightSection={labelRightSection}
          required={required}
          disabled={disabled}
          htmlFor={name}
          fillWidth={fillWidth}
          className={className}
          field={
            <InputBase
              /**
               * Prevent scroll to change the value of on input number
               */
              onWheel={onWheelPreventChange}
              ref={ref}
              type='number'
              aria-label={label as string}
              aria-placeholder={placeholder}
              placeholder={placeholder}
              error={Boolean(errorMessage)}
              disabled={disabled}
              name={name}
              maxLength={maxLength}
              aria-required={required}
              required={required}
              value={controlledValue}
              onChange={setControlledValue}
              classNames={{
                ...classNames,
                input: numberInputCVA.input({
                  showArrows,
                  className: classNames?.input
                })
              }}
              {...other}
            />
          }
        />
      )
    }
  )
)

const TypedNumberInput = BaseNumberInput
TypedNumberInput.displayName = 'NumberInput'

export type { NumberInputProps } from './NumberInput.types'
export const NumberInput = TypedNumberInput
