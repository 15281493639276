import { httpServices } from '../../apis'
import type { QueryFnParams } from '../../types'

export type FetchSchedulesOptions = QueryFnParams<{
  queryParams: {
    week: 'YYYY-MM-DD' | string
    account_id?: number
    location_id?: number
    team_id?: number
    with_permissions?: string[]
  }
}>

export const fetchSchedules = async (opts: FetchSchedulesOptions) => {
  const response = await httpServices.core.get(`/planning`, {
    params: opts?.queryParams
  })
  return response.data
}

export type FetchScheduleRowsOptions = QueryFnParams<{
  queryParams: {
    account_id: number
    week: 'YYYY-MM-DD' | string
    user_contract_ids?: number[]
  }
}>

export const fetchScheduleRows = async (opts: FetchScheduleRowsOptions) => {
  const response = await httpServices.core.get(`/planning/rows`, {
    params: opts?.queryParams
  })
  return response.data
}

export type FetchScheduleAlertsOptions = QueryFnParams<{
  queryParams: {
    account_id: number
    start_date: 'YYYY-MM-DD' | string
    end_date: 'YYYY-MM-DD' | string
    user_contract_ids: number[]
  }
}>

export const fetchScheduleAlerts = async (opts: FetchScheduleAlertsOptions) => {
  const response = await httpServices.core.get(`/planning/alerts`, {
    params: opts?.queryParams
  })
  return response.data
}
