import type { ReduxState } from '@spa/redux/ReduxState'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'

import { getDate } from '../../../params'
import { alertsBaseSelectors } from '../alertsBaseSelectors'

import {
  computeDayAlertsList,
  computeDayAlertsMap,
  computeWeekAlertsList
} from './utils'

type Params = {
  contractId: number
  includesDayAlerts?: boolean
  includesWeekAlerts?: boolean
}

/**
 * Return all alerts for a contract on the current week
 * The selector can be filtered to return only alerts of specific scopes [day] or [week]
 */
const getAlertsByContract = createCachedSelector(
  (_: ReduxState, opts?: Params) =>
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    alertsBaseSelectors.getCachedById(_, opts.contractId),
  /* eslint-disable-next-line no-unused-vars */
  (state: ReduxState, _: Params) => getDate(state),
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  (_: ReduxState, opts?: Params) => opts.includesDayAlerts,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  (_: ReduxState, opts?: Params) => opts.includesWeekAlerts,

  (contractAlerts, date, includesDayAlerts, includesWeekAlerts) => {
    if (!contractAlerts) {
      return {
        dayAlertsMap: {},
        dayAlertsList: [],
        weekAlertsList: []
      }
    }

    return {
      dayAlertsMap: includesDayAlerts
        ? // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          computeDayAlertsMap(contractAlerts, date)
        : {},
      dayAlertsList: includesDayAlerts
        ? // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          computeDayAlertsList(contractAlerts, date)
        : [],
      weekAlertsList: includesWeekAlerts
        ? computeWeekAlertsList(contractAlerts)
        : []
    }
  }
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
)((_, { contractId }) => fallbackToDefaultCacheKey(contractId))

export default getAlertsByContract
