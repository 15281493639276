import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'

import type { FlexProps } from './Flex.types'

const flexCVA = cva(['box-border flex flex-wrap'], {
  variants: {
    row: {
      false: ['flex-col'],
      true: ['flex-row']
    }
  }
})

export const Flex = forwardRef<HTMLDivElement, FlexProps>(
  ({ row = false, className, children, ...other }, ref) => {
    return (
      <div {...other} ref={ref} className={flexCVA({ row, className })}>
        {children}
      </div>
    )
  }
)

Flex.displayName = 'Flex'
