import { getWeekRange } from '@spa/scenes/Plannings/utils'
import { flatten } from 'lodash-es'
import { normalize } from 'normalizr'
import { put, select } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'
import type { GetUserContractCountersQueryParams } from 'snap-redux/infra/http/routesConfig'

import { ContractCounter } from '../../../models'
import { mergeCountersEntities } from '../../../reducers/entities'
import { getPlanningPolicies, getSettings } from '../../../selectors'
import type { PayloadActionWithoutType } from '../../types/types'

type FetchUserContractCounters = {
  teamId?: number
  locationId?: number
  contractIds?: number[]
  start: string
  end: string
  params?: Partial<GetUserContractCountersQueryParams>
}
/**
 * Method to fetch with specific contractIds
 */
function* fetchUserContractCounters({
  teamId,
  locationId,
  contractIds,
  start,
  end,
  ...params
}: FetchUserContractCounters) {
  try {
    const { countersMode } = yield select(getSettings)
    const counters = yield apiRequest({
      route: 'getUserContractCounters',
      queryParams: {
        user_contract_ids: contractIds, // Either we update a single row or the whole schedule
        team_id: contractIds ? undefined : teamId,
        location_id: teamId || contractIds ? undefined : locationId,
        start_date: start,
        end_date: end,
        planning_counter_modes: [countersMode],
        ...params
      }
    })
    return counters
  } catch {
    return null
  }
}

export type FetchAndHydrateContractCountersParams = PayloadActionWithoutType<{
  teamId?: number
  locationId?: number
  contractIds?: number[] // Used to update a single planning row (e.g after shift creation)
  date: string
  params?: Partial<GetUserContractCountersQueryParams>
}>

/**
 * Retrieve data to fetch user_contract_counters
 * Chunk with a size of 10 contracts ids to prevent timeout on server
 * Then hydrate redux store with user_contract_counters
 */
export function* fetchAndHydrateContractCounters({
  payload: { teamId, locationId, contractIds, date, params }
}: FetchAndHydrateContractCountersParams): any {
  const { canSeeCounters, canSeeOwnCounters } =
    yield select(getPlanningPolicies)

  if (!date || (!canSeeCounters && !canSeeOwnCounters)) {
    return null
  }

  const { dayStart, dayEnd } = getWeekRange(date)

  const results = yield fetchUserContractCounters({
    teamId,
    locationId,
    contractIds,
    start: dayStart,
    end: dayEnd,
    ...params
  })

  if (results?.length) {
    const counters = flatten(results)
    const normalized = normalize(counters, [ContractCounter])

    yield put(mergeCountersEntities(normalized.entities))
  }
}
