import { errorLoggerService } from '@libs/utils/error-monitoring'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import type { ParamsDefaultFetch } from '../modules/entities'
import { replaceMissingInformation } from '../modules/entities'
import { setMetaLoading, setMetaSuccess, setMetaError } from '../modules/meta'
import { setPagination } from '../modules/pagination'

export default function* fetchMissingInformationData(
  queryParams: ParamsDefaultFetch
) {
  yield put(setMetaLoading())

  try {
    const result = yield apiRequest({
      service: 'core',
      route: 'getMissingInformation',
      queryParams
    })

    yield put(replaceMissingInformation(result.resources))
    yield put(setPagination(result.meta))
    yield put(setMetaSuccess())
  } catch (err) {
    errorLoggerService.apiError(err)
    yield put(setMetaError())
  }
}
