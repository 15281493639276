export const SortAscendingIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.87558 4.49918C9.2407 4.2073 9.7593 4.20731 10.1244 4.49918L12.9011 6.71893C13.6396 7.30928 13.2222 8.50002 12.2767 8.50002H6.72326C5.7778 8.50002 5.36036 7.30928 6.09885 6.71892L8.87558 4.49918Z'
        fill='currentColor'
      />
      <path
        d='M10.1244 14.5009C9.7593 14.7927 9.2407 14.7927 8.87558 14.5009L6.09885 12.2811C5.36035 11.6908 5.7778 10.5 6.72326 10.5L12.2767 10.5C13.2222 10.5 13.6396 11.6908 12.9011 12.2811L10.1244 14.5009Z'
        fill='currentColor'
        opacity={0.3}
      />
    </svg>
  )
}
