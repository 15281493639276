import type {
  ApiErrorResponse,
  NewApiErrorResponseData
} from '@libs/data-access/queries'
import { notifications } from '@libs/ui/ds'
import i18n from '@libs/utils/translations'
import { put } from 'redux-saga/effects'

import type { ParamsGenerateDocument } from '../modules/entities'
import { setDocumentGenerated } from '../modules/entities'
import { setMetaError, setMetaWS } from '../modules/meta'

export default function* generatingDocumentData(
  response: ParamsGenerateDocument
) {
  try {
    yield put(setMetaWS({ status: response.status }))

    if (response.status === 'success') {
      yield put(
        setDocumentGenerated({
          url: response.url,
          document_id: response.document_id
        })
      )
      notifications.success({
        message: i18n.t<any>(
          'common.notifications:documentGeneration.download.success'
        )
      })
    } else if (response.status === 'failure') {
      throw response
    }
  } catch (err) {
    yield put(setMetaWS({ status: 'failure' }))
    yield put(
      setMetaError({
        error: { ...err, code: '', raw_error_messages: [] }
      } as ApiErrorResponse)
    )
    notifications.errorAdapter({ error: err as NewApiErrorResponseData })
  }
}
