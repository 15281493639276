import { IS_INTERCOM_AVAILABLE } from '@libs/utils/environments'
import { useMemoSelector } from '@spa/redux/hooks'
import { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'
import { getCurrentAccount } from 'snap-redux/modules/accounts/selectors'
import { getCurrentMembershipSelector } from 'snap-redux/modules/memberships/selectors'
import { getCurrentUser } from 'snap-redux/modules/users/selectors'

export const useUpdateIntercomUserAttribute = () => {
  const { update } = useIntercom()
  const currentAccount = useMemoSelector(getCurrentAccount)
  const currentUser = useMemoSelector(getCurrentUser)
  const currentMembership = useMemoSelector(getCurrentMembershipSelector)

  useEffect(() => {
    if (IS_INTERCOM_AVAILABLE) {
      if (currentUser && currentMembership && currentAccount) {
        update({
          email: currentUser?.get('email'),
          name: `${currentMembership?.get(
            'firstname'
          )} ${currentMembership?.get('lastname')}`,
          phone: currentMembership?.getIn(['mobile_phone', 'number']),
          company: {
            companyId: currentAccount?.get('id').toString(),
            name: currentAccount?.get('name')
          },
          customAttributes: {
            id: currentUser?.get('id'),
            role: currentMembership?.get('role'),
            ...currentAccount?.get('csm_info')?.toJS()
          }
        })
      }
    }
  }, [currentUser, currentAccount, currentMembership, update])
}
