export const AlarmBellIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.13742 17.1759C8.57001 17.0489 9.02364 17.2967 9.15062 17.7292C9.20463 17.9132 9.31677 18.0747 9.47025 18.1897C9.62374 18.3046 9.81031 18.3667 10.002 18.3667C10.1938 18.3667 10.3803 18.3046 10.5338 18.1897C10.6873 18.0747 10.7995 17.9132 10.8535 17.7292C10.9805 17.2967 11.4341 17.0489 11.8667 17.1759C12.2993 17.3029 12.547 17.7565 12.42 18.1891C12.2666 18.7116 11.9482 19.1703 11.5123 19.4966C11.0764 19.8229 10.5465 19.9993 10.002 19.9993C9.45754 19.9993 8.92768 19.8229 8.4918 19.4966C8.05591 19.1703 7.73745 18.7116 7.58408 18.1891C7.45709 17.7565 7.70482 17.3029 8.13742 17.1759Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 0C10.4508 0 10.8163 0.365482 10.8163 0.816327V2.65306C10.8163 3.10391 10.4508 3.46939 10 3.46939C9.54916 3.46939 9.18368 3.10391 9.18368 2.65306V0.816327C9.18368 0.365482 9.54916 0 10 0Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 3.46939C8.59273 3.46939 7.2431 4.02842 6.24801 5.02352C5.25292 6.01861 4.69388 7.36824 4.69388 8.77551C4.69388 11.4243 4.38034 13.1876 4.04826 14.3129C4.00806 14.4491 3.9676 14.5759 3.92739 14.6939H16.005C15.9888 14.6463 15.9726 14.5972 15.9566 14.5467C15.617 13.4764 15.3061 11.703 15.3061 8.77551C15.3061 7.36824 14.7471 6.01861 13.752 5.02352C12.7569 4.02842 11.4073 3.46939 10 3.46939ZM17.9031 14.9126C17.8944 14.902 17.8745 14.8763 17.8461 14.8313C17.7742 14.7174 17.6484 14.4802 17.5128 14.0529C17.2402 13.1938 16.9388 11.5999 16.9388 8.77551C16.9388 6.93523 16.2077 5.17033 14.9065 3.86905C13.6052 2.56778 11.8403 1.83673 10 1.83673C8.15973 1.83673 6.39482 2.56778 5.09355 3.86905C3.79227 5.17033 3.06123 6.93523 3.06123 8.77551C3.06123 11.2974 2.76253 12.9014 2.48236 13.8508C2.34237 14.3252 2.20698 14.6362 2.11564 14.8172C2.06994 14.9077 2.03512 14.966 2.01603 14.9961C2.00806 15.0087 2.00281 15.0164 2.00062 15.0196C1.81665 15.2642 1.78476 15.5917 1.91918 15.8677C2.05592 16.1484 2.34081 16.3265 2.65306 16.3265H17.3469C17.6934 16.3265 18.0021 16.1079 18.117 15.781C18.2253 15.4731 18.1394 15.1324 17.9031 14.9126Z'
        fill='currentColor'
      />
    </svg>
  )
}
