import * as formats from './formatters'

/**
 *
 */
export function formatMediator(value, format, lng, args) {
  const formatter = formats[format]

  try {
    if (formatter) {
      return formatter.format({ value, lng, args })
    }

    if (!formatter) {
      console.error(`The formatter [${format}] does not exist`)
    }
  } catch (e) {
    if (formatter) {
      console.error(
        `While calling [${format}] formatter an error occurred - ${e}`
      )
    }
  }
}
