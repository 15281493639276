import membershipsSelector from '@spa/scenes/PlanningLegacy/selectorsNext/planningMemberships.selector'
import { put, select, take } from 'redux-saga/effects'
import { fetchShifts } from 'snap-redux/modules/shifts/index'

export function* planningTeamChange({
  accountId,
  locationId,
  teamId,
  start,
  end
}) {
  // Shifts for shared employee
  const memberships = yield select(state =>
    membershipsSelector(state, {
      teamId,
      locationId,
      week: start.format('GGGG-[W]WW'),
      archived: false
    })
  )

  const membershipsIds = memberships.map(m => m.id).toArray()

  yield put(
    // @ts-ignore migration from js(x) to ts(x)
    fetchShifts(accountId, {
      start_date: start.format('YYYY-MM-DD'),
      end_date: end.format('YYYY-MM-DD'),
      'membership_ids[]': membershipsIds
    })
  )

  yield take('api/FETCH_SHIFTS_SUCCEEDED')
}
