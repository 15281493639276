import { cva } from 'class-variance-authority'
import type { ReactElement, Ref } from 'react'
import { forwardRef, memo } from 'react'

import { Badge } from '../../Badge/base/Badge'

import type { IndicatorProps } from './Indicator.types'

const indicatorCVA = {
  root: cva(['relative box-border w-fit']),
  absoluteWrap: cva(['absolute z-10'], {
    variants: {
      position: {
        'top-end': '-right-2 -top-2',
        'bottom-end': '-bottom-2 -right-2'
      }
    }
  })
}

const BaseIndicator = memo(
  forwardRef<HTMLElement, IndicatorProps>(
    (
      {
        label,
        size = 'xs',
        position = 'top-end',
        color = 'semantic.red',
        hide = false,
        processing,
        className,
        children,
        ...other
      },
      ref
    ) => {
      if (hide) {
        return children as ReactElement
      }

      return (
        <div
          ref={ref as Ref<HTMLDivElement>}
          className={indicatorCVA.root({ className })}
        >
          <div className={indicatorCVA.absoluteWrap({ position })}>
            <Badge
              {...other}
              variant='plain'
              className={className}
              label={label}
              size={size}
              processing={processing}
              color={color}
            />
          </div>
          {children}
        </div>
      )
    }
  )
)

BaseIndicator.displayName = 'Indicator'

export const Indicator = BaseIndicator
export type { IndicatorProps } from './Indicator.types'
