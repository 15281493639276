import type {
  ApiErrorResponse,
  ApiErrorResponseData
} from '@libs/data-access/queries'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type MetaState = {
  error: boolean
  success: boolean
  loading: boolean
  rawError: ApiErrorResponseData | null
}

const initialState: MetaState = {
  error: false,
  success: false,
  loading: false,
  rawError: null
}

const paramsSlice = createSlice({
  name: 'emp/documentEmployee/meta',
  initialState,
  reducers: {
    setMetaError(_, error: PayloadAction<ApiErrorResponse>) {
      return {
        error: true,
        success: false,
        loading: false,
        rawError: error.payload.error
      }
    },
    setMetaSuccess() {
      return {
        error: false,
        success: true,
        loading: false,
        rawError: null
      }
    },
    setMetaLoading() {
      return {
        error: false,
        success: false,
        loading: true,
        rawError: null
      }
    }
  }
})

export const { setMetaError, setMetaSuccess, setMetaLoading } =
  paramsSlice.actions
export default paramsSlice.reducer
