import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_REVENUES,
  FETCH_REVENUES_SUCCEEDED,
  FETCH_REVENUES_FAILED,
  UPDATE_REVENUE,
  UPDATE_REVENUE_SUCCEEDED,
  UPDATE_REVENUE_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords, updateRecord } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchRevenues = (accountId, params, resolve, reject) => ({
  type: FETCH_REVENUES,
  accountId,
  params,
  resolve,
  reject
})

export const updateRevenue = (revenueId, revenueData, resolve, reject) => ({
  type: UPDATE_REVENUE,
  revenueId,
  revenueData,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const revenuesSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_REVENUES_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_REVENUES_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/revenues?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchRevenues({ accountId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    revenuesSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

const updateRevenueSagaEntity = {
  success: (data, revenueId, revenueData) => ({
    type: UPDATE_REVENUE_SUCCEEDED,
    data,
    revenueId,
    revenueData
  }),
  failure: (error, revenueId, revenueData) => ({
    type: UPDATE_REVENUE_FAILED,
    error,
    revenueId,
    revenueData
  }),

  fetchAPI: (revenueId, options) =>
    callAPI(`/revenues/${revenueId}`, { method: 'PUT', ...options })
}

export function* doUpdateRevenue({ revenueId, revenueData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    updateRevenueSagaEntity,
    revenueId,
    null,
    revenueData,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const Revenue = Immutable.Record({
  id: null,
  location_id: null,
  date: null,
  week: null,
  estimated_amount: null,
  actual_amount: null
})

const revenueForeignKeys = ['location_id', 'week']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function revenues(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_REVENUES: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_REVENUES_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_REVENUES_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const revenues = Immutable.fromJS(action.data)

      return mergeRecords(
        state,
        Revenue,
        revenues,
        // @ts-ignore migration from js(x) to ts(x)
        revenueForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    case UPDATE_REVENUE_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const revenue = Immutable.fromJS(action.data)
      // @ts-ignore migration from js(x) to ts(x)
      return updateRecord(state, Revenue, revenue, revenueForeignKeys)
    }
    default:
      return state
  }
}

export default filterActions(revenues, [
  FETCH_REVENUES,
  FETCH_REVENUES_FAILED,
  FETCH_REVENUES_SUCCEEDED,
  UPDATE_REVENUE_SUCCEEDED
])
