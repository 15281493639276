import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { LocationBillingModel } from '../models/LocationBillingModel'

export type FetchBillingDataParams = {
  payload: {
    account_id: number
  }
}

const initialState: LocationBillingModel = []

const entitiesSlice = createSlice({
  name: 'billing/locationBilling/entities',
  initialState,
  reducers: {
    // This is used to replace location in the state with the gateway api returns
    updateLocationBilling(state, action: PayloadAction<LocationBillingModel>) {
      const updatedLocations = action.payload

      // We update old locations
      const newState = state.map(
        oldLocation =>
          updatedLocations.find(
            newLocation => newLocation.location_id === oldLocation.location_id
          ) || oldLocation
      )

      // We add new locations that have been created.
      updatedLocations.forEach(newLocation => {
        if (
          !newState.find(
            oldLocation => newLocation.location_id === oldLocation.location_id
          )
        ) {
          newState.push(newLocation)
        }
      })

      return newState
    }
  }
})

export const { updateLocationBilling } = entitiesSlice.actions
export default entitiesSlice.reducer
