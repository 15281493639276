import type { MembershipSchema, UserSchema } from '@libs/data-access/entities'
import type { MembershipFeaturesPermissionsSchema } from '@libs/data-access/entities/lib/entities/User/Membership/MembershipFeaturesPermissions/MembershipFeaturesPermissions'
import { cookiesService } from '@libs/utils/cookies'
import { queryClientOptions } from 'libs/data-access/queries/src/lib/provider'

import { useFetchCurrentUser } from '../../User/hooks/useFetchCurrentUser/useFetchCurrentUser'

export const getCurrentMembership = (
  currentUser: UserSchema
): MembershipSchema | undefined => {
  return currentUser?.memberships.find(
    i => i.account_id === cookiesService.readAccountId()
  )
}

export const useCurrentMembershipPermissions = ():
  | MembershipFeaturesPermissionsSchema
  | undefined => {
  const { data } = useFetchCurrentUser(
    {},
    {
      staleTime: queryClientOptions.time.INFINITY
    }
  )

  return data ? getCurrentMembership(data)?.features_permissions : undefined
}
