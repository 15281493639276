/* eslint-disable import/no-extraneous-dependencies */
import { useTranslation } from 'react-i18next'

import { i18nDSInstance } from '../i18nDS.setup'

export const useDSTranslation = () => {
  return useTranslation('ds', {
    i18n: i18nDSInstance
  })
}
