import { appQueryClient } from '../../../provider'

export const userQueryKeys = {
  me: () => ['users/me'],
  one: (userId: number) => ['users', userId]
}

export const userInvalidations = {
  invalidateCurrent: () =>
    appQueryClient.invalidateQueries({ queryKey: userQueryKeys.me() }),
  invalidateOne: (userId: number) =>
    appQueryClient.invalidateQueries({ queryKey: userQueryKeys.one(userId) })
}
