import type { FC, PropsWithChildren } from 'react'
import { useMemo, useState } from 'react'

import { AuthNavbarContext } from './AuthNavbarContext'
import type { AuthNavbarState } from './AuthNavbarContext.types'

export type AuthNavbarProviderProps = PropsWithChildren<{
  value?: AuthNavbarState
}>

export const AuthNavbarProvider: FC<AuthNavbarProviderProps> = ({
  children,
  value
}) => {
  const [state, setState] = useState<AuthNavbarState | undefined>(value)

  const updateNavbar = (newState: AuthNavbarState) => {
    setState(newState)
  }

  const contextValue = useMemo(() => ({ state, updateNavbar }), [state])

  return (
    <AuthNavbarContext.Provider value={contextValue}>
      {children}
    </AuthNavbarContext.Provider>
  )
}
