import { i18nDSInstance } from '@libs/ui/ds/lib/internals'
import type { NotificationData } from '@mantine/notifications'
import {
  notifications as mantineNotifications,
  notificationsStore
} from '@mantine/notifications'
import uuid from 'uuid/v4'

import type { NotificationParams } from './notifications.types'
import { ToastItem } from './subs/ToastItem'
import { toastItemClasses } from './subs/ToastItemClasses'

const notificationDelays = {
  shortest: 3000,
  short: 4000,
  medium: 6000,
  long: 10000,
  longest: 20000
}

const defaultsParams: Partial<NotificationData> = {
  autoClose: notificationDelays.medium
}

class Notifications {
  private propsToMantine(opts: NotificationParams): NotificationData {
    return {
      ...defaultsParams,
      id: opts.id,
      autoClose: opts.autoClose ? notificationDelays[opts.autoClose] : false,
      withCloseButton: opts.closeable,
      message: (
        <ToastItem
          intent={opts.intent}
          message={opts.message}
          title={opts.title}
        />
      ),
      classNames: {
        root: toastItemClasses.root(),
        closeButton: toastItemClasses.closeButton()
      }
    }
  }

  private show(opts: NotificationParams): string {
    const id = opts.id || uuid()
    mantineNotifications.show({
      ...this.propsToMantine(opts),
      id
    })
    return id as string
  }

  private update(opts: NotificationParams): string {
    const idExist = opts.id && this.findIdInStore(opts?.id)

    if (idExist) {
      mantineNotifications.update({
        ...this.propsToMantine(opts),
        id: opts.id
      })
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      return opts.id
    }

    return this.show(opts)
  }

  private hide(id?: string) {
    if (!id) {
      return
    }
    mantineNotifications.hide(id)
  }

  private findIdInStore(id: string) {
    return notificationsStore.getState()?.notifications?.find(n => n.id === id)
  }

  info(opts: Omit<NotificationParams, 'intent'>) {
    return this.update({
      ...opts,
      intent: 'info',
      autoClose: opts.autoClose || 'medium'
    })
  }

  loading(opts: Omit<NotificationParams, 'intent'>) {
    return this.update({
      ...opts,
      intent: 'loading',
      autoClose: opts.autoClose || 'longest'
    })
  }

  success(opts: Omit<NotificationParams, 'intent'>) {
    return this.update({
      ...opts,
      intent: 'success',
      autoClose: opts.autoClose || 'short'
    })
  }

  warning(opts: Omit<NotificationParams, 'intent'>) {
    return this.update({
      ...opts,
      intent: 'warning',
      autoClose: opts.autoClose || 'long'
    })
  }

  error(opts: Omit<NotificationParams, 'intent'>) {
    return this.update({
      ...opts,
      intent: 'error',
      autoClose: opts.autoClose || 'longest'
    })
  }

  errorAdapter(
    opts: Omit<NotificationParams, 'intent' | 'title' | 'message'> & {
      error: {
        message: string
        details: { message: string }[]
      }
    }
  ) {
    return this.update({
      ...opts,
      title:
        opts?.error?.message ||
        i18nDSInstance.t<string>(
          'ds:ds.components.notifications.errors.generic'
        ),
      message: opts?.error?.details?.[0]?.message,
      intent: 'error',
      autoClose: opts.autoClose || 'longest'
    })
  }

  promise<T extends any = any>(
    promiseFn: Promise<T>,
    obj: {
      loading?: Pick<
        NotificationParams,
        'title' | 'message' | 'onClose' | 'autoClose'
      >
      success?: Pick<
        NotificationParams,
        'title' | 'message' | 'onClose' | 'autoClose'
      >
      error?: Pick<
        NotificationParams,
        'title' | 'message' | 'onClose' | 'autoClose'
      >
      apiError?: boolean
    }
  ) {
    const id = obj.loading ? this.loading({ ...obj.loading }) : undefined

    promiseFn
      .then(data => {
        if (obj.success) {
          this.success({ id, ...obj.success })
        }
        return data
      })
      .catch(errObj => {
        if (obj.apiError) {
          // RQ error are nested inside an error object.
          if (errObj?.error) {
            this.errorAdapter({ id, error: errObj.error })
          } else {
            this.errorAdapter({ id, error: errObj })
          }
        } else if (obj.error) {
          this.error({ id, ...obj.error })
        } else {
          this.hide(id)
        }
        return errObj
      })
  }

  delays = {
    s: 2500,
    m: 5000,
    l: 8000,
    xl: 20000
  }
}

export const notifications = new Notifications()
export * from './notifications.types'
