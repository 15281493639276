import { Switch } from '@libs/ui/ds'
import { ControllerBase } from '@libs/ui/form'
import type { RefObject } from 'react'
import type { FieldValues, FieldPath } from 'react-hook-form'

import type { ControlledSwitchProps } from './ControlledSwitch.types'

export const ControlledSwitch = <
  TFieldValues extends FieldValues = FieldValues,
  TInputName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  ref,
  onChangeSideEffect,
  rules,
  ...switchProps
}: ControlledSwitchProps<TFieldValues, TInputName>) => (
  <ControllerBase
    rules={rules}
    control={control}
    field={<Switch {...switchProps} ref={ref as RefObject<HTMLInputElement>} />}
    name={name}
    onChangeAdapter={e => e.target.checked}
    valueAdapter='checked'
    onChangeSideEffect={onChangeSideEffect}
  />
)
