import { call } from 'redux-saga/effects'

import {
  FETCH_PLANNABLE_OVERLAP,
  FETCH_PLANNABLE_OVERLAP_SUCCEEDED,
  FETCH_PLANNABLE_OVERLAP_FAILED
} from '../actionTypes'
import { call as callAPI } from '../infra/http'
import { fetchSagaEntity } from '../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchPlannableOverlap = (plannableData, resolve, reject) => ({
  type: FETCH_PLANNABLE_OVERLAP,
  plannableData,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const plannableOverlapSagaEntity = {
  success: (data, plannableData) => ({
    type: FETCH_PLANNABLE_OVERLAP_SUCCEEDED,
    data,
    plannableData
  }),
  failure: (error, plannableData) => ({
    type: FETCH_PLANNABLE_OVERLAP_FAILED,
    error,
    plannableData
  }),

  fetchAPI: (plannableData, options) =>
    callAPI('/stats/plannable_count', {
      method: 'POST',
      ...options
    })
}

export function* doFetchPlannableOverlap({ plannableData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    plannableOverlapSagaEntity,
    null,
    undefined,
    plannableData,
    resolve,
    reject
  )
}
