import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

export const useOnLocationChange = (handleLocationChange: any) => {
  const location = useLocation()
  const params = useParams()

  useEffect(() => {
    if (handleLocationChange) {
      handleLocationChange({ location, params })
    }
  }, [location, handleLocationChange])
}

export default useOnLocationChange
