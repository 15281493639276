import { useDSTranslation } from '@libs/ui/ds/lib/internals'
import React from 'react'

export const PercentSpan = ({ value }) => {
  const { t } = useDSTranslation()

  return (
    <span className='inline-block'>
      <span>
        {t('ds.components.progressButton.progress', { count: value })}
      </span>
      <span className='mr-1 inline-block w-10'>
        {t('ds.components.progressButton.value', { value })}
      </span>
    </span>
  )
}
