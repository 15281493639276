import { createSafeContext } from '@libs/ui/ds/lib/internals'

import type { TabsProps } from './Tabs.types'

export type TabsContext = {
  onChange?: TabsProps['onChange']
  value?: TabsProps['value']
}

export const [TabsProvider, useTabsContext] = createSafeContext<TabsContext>({
  context: 'Tabs'
})
