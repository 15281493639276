import { formatToIsoDay } from '@spa/utils/date'
import moment from 'moment'
import { createSelector } from 'reselect'

import { getTimeRange } from '../getTimeRange/getTimeRange'

/**
 * Return a list of ISO days contained in the current time range
 */
export const getIsoDaysInTimeRange = createSelector(
  getTimeRange,
  (timeRange): string[] => {
    const currentDate = moment(timeRange.start)
    const isoDaysArr = []

    while (currentDate.isSameOrBefore(timeRange.end)) {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      isoDaysArr.push(formatToIsoDay(currentDate))
      currentDate.add(1, 'day')
    }

    return isoDaysArr
  }
)
