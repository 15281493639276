import {
  CloseIcon,
  Flex,
  HideAt,
  IconButton,
  Text,
  Title,
  imagesUrls,
  Image,
  Button,
  useUncontrolled
} from '@libs/ui/ds'
import { useDSTranslation } from '@libs/ui/ds/lib/internals'
import type { MediaQueryThreshold } from '@libs/ui/ds/lib/theme'
import { useMediaQueryThreshold } from '@libs/ui/ds/lib/theme'
import { cva } from 'class-variance-authority'
import { forwardRef, memo } from 'react'

import type { PromotionBannerProps } from './PromotionBanner.types'

const promotionBannerCVA = {
  root: cva(['relative rounded-lg p-8'], {
    variants: {
      type: {
        new: 'bg-brand-green-200',
        people: 'bg-brand-beige-100',
        addon: 'bg-secondary-blue-200',
        beta: 'bg-secondary-coral-50',
        activation: 'border-1 border-neutral-200 bg-neutral-50'
      }
    }
  }),
  layoutWrap: cva(['flex-nowrap gap-6']),
  imageZone: cva(['size-20 min-h-20 min-w-20 rounded-xl p-4 md:self-center'], {
    variants: {
      type: {
        new: 'bg-brand-green-100',
        people: 'bg-brand-beige-50',
        addon: 'bg-secondary-blue-100',
        beta: 'bg-secondary-coral-100',
        activation: 'bg-neutral-100'
      }
    }
  }),
  image: {
    new: imagesUrls?.illustrations.shiny,
    people: imagesUrls?.illustrations.crown,
    addon: imagesUrls?.illustrations.shinyBold
  },
  contentSection: cva(['grow gap-2']),
  title: cva(['text-m font-semibold']),
  description: cva(['text-s text-neutral-700']),
  closable: cva(['absolute right-2 top-2 size-8 max-h-8 max-w-8']),
  illustrations: cva(['absolute bottom-2 right-2 size-8 max-h-8 max-w-8'])
}

const BasePromotionBanner = memo(
  forwardRef<HTMLDivElement, PromotionBannerProps>(
    (
      {
        badges,
        buttonProps,
        className,
        closeable,
        description,
        illustrationSrc,
        onClose,
        switchAt = 'md',
        title,
        type = 'new',
        ...other
      },
      ref
    ) => {
      const { t } = useDSTranslation()
      const [open, setOpen] = useUncontrolled({
        defaultValue: true,
        onChange: onClose
      })
      const isLarge = useMediaQueryThreshold({
        mediaQueryTreshold: `min-${switchAt}` as MediaQueryThreshold
      })

      if (!open) {
        return null
      }

      return (
        <div
          {...other}
          ref={ref}
          className={promotionBannerCVA.root({ className, type })}
        >
          <Flex row={isLarge} className={promotionBannerCVA.layoutWrap()}>
            {illustrationSrc && (
              <Flex
                className={promotionBannerCVA.imageZone({
                  type
                })}
              >
                <Image
                  aspect='square'
                  fit='contain'
                  size='m'
                  src={illustrationSrc}
                />
              </Flex>
            )}
            <Flex className={promotionBannerCVA.contentSection()}>
              <Flex row className='gap-2'>
                {badges && badges.map(badge => badge)}
              </Flex>
              {title && (
                <Title size={3} className={promotionBannerCVA.title()}>
                  {title}
                </Title>
              )}
              {description && (
                <Text className={promotionBannerCVA.description()}>
                  {description}
                </Text>
              )}
            </Flex>

            {switchAt !== 'none' && buttonProps?.onClick && (
              <HideAt hideAt={`min-${switchAt}` as MediaQueryThreshold}>
                <Flex row className='items-center'>
                  <Button
                    intent={type === 'activation' ? 'default' : 'neutral-dark'}
                    size='m'
                    type='button'
                    variant='primary'
                    onClick={buttonProps.onClick}
                  >
                    {buttonProps?.label
                      ? buttonProps.label
                      : t('ds.components.promotionBanner.actionButton')}
                  </Button>
                </Flex>
              </HideAt>
            )}

            {switchAt !== 'all' && buttonProps?.onClick && (
              <HideAt hideAt={`max-${switchAt}` as MediaQueryThreshold}>
                <Flex row className='items-center'>
                  <Button
                    intent={type === 'activation' ? 'default' : 'neutral-dark'}
                    size='m'
                    type='button'
                    variant='primary'
                    onClick={buttonProps.onClick}
                  >
                    {buttonProps?.label
                      ? buttonProps.label
                      : t('ds.components.promotionBanner.actionButton')}
                  </Button>
                </Flex>
              </HideAt>
            )}

            {closeable && (
              <div className={promotionBannerCVA.closable()}>
                <IconButton
                  aria-label='close-alert-button'
                  size='s'
                  variant='tertiary'
                  intent='neutral-dark'
                  icon={<CloseIcon />}
                  onClick={() => setOpen(false)}
                />
              </div>
            )}

            <div className={promotionBannerCVA.illustrations()}>
              <Image
                aspect='auto'
                fit='cover-center'
                size='xs'
                src={promotionBannerCVA.image[type]}
              />
            </div>
          </Flex>
        </div>
      )
    }
  )
)

const TypedPromotionBanner = BasePromotionBanner as typeof BasePromotionBanner
TypedPromotionBanner.displayName = 'PromotionBanner'

export const PromotionBanner = TypedPromotionBanner
