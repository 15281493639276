import ReconnectingWebSocket from 'reconnecting-websocket'

type SocketFactoryOptions = {
  url: string
  queryParams: Record<string, any>
}

export const rwsFactory = ({ url }: SocketFactoryOptions) => {
  return new ReconnectingWebSocket(url, [], {
    maxRetries: 5,
    maxReconnectionDelay: 30000,
    minReconnectionDelay: 5000
  })
}
