import type { BaseComponent } from '@libs/ui/ds/lib/internals'
import { Tooltip as MantineTooltip } from '@mantine/core'
import type { FC, PropsWithChildren } from 'react'
import { memo } from 'react'

export const TooltipGroup: FC<PropsWithChildren<BaseComponent>> = memo(
  ({ children, ...other }) => {
    return (
      <MantineTooltip.Group {...other} openDelay={200} closeDelay={300}>
        {children}
      </MantineTooltip.Group>
    )
  }
)

TooltipGroup.displayName = 'TooltipGroup'
