import { useDeleteRest } from './hooks/useDeleteRest'
import { useDeleteShift } from './hooks/useDeleteShift'
import { useDuplicateRest } from './hooks/useDuplicateRest'
import { useDuplicateShift } from './hooks/useDuplicateShift'
import { useFetchHeader } from './hooks/useFetchHeader'
import { useFetchRow } from './hooks/useFetchRow'
import { useMoveRest } from './hooks/useMoveRest'
import { useMoveShift } from './hooks/useMoveShift'
import { monthlyServiceInvalidations } from './monthly.cacheKeys'

class MonthlyService {
  row = {
    useFetchAll: useFetchRow,
    invalidateAll: monthlyServiceInvalidations.invalidateRow
  }

  header = {
    useFetchMonth: useFetchHeader,
    invalidateHeader: monthlyServiceInvalidations.invalidateHeader
  }

  shift = {
    move: useMoveShift,
    duplicate: useDuplicateShift,
    delete: useDeleteShift
  }

  rest = {
    move: useMoveRest,
    duplicate: useDuplicateRest,
    delete: useDeleteRest
  }
}

export const monthlyService = new MonthlyService()
