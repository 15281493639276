import type { AmendmentSchema } from '@libs/data-access/entities'
import { useMutation } from '@tanstack/react-query'
import { appQueryClient } from 'libs/data-access/queries/src/lib/provider'
import type {
  UseAppMutationOptions,
  NewApiErrorResponse
} from 'libs/data-access/queries/src/lib/types'

import { postAmendment, type PostAmendmentOption } from '../amendments.api'
import { amendmentsCacheKeys } from '../amendments.cacheKeys'

export const usePostAmendment = (
  mutationOptions?: UseAppMutationOptions<
    AmendmentSchema,
    NewApiErrorResponse,
    PostAmendmentOption
  >
) => {
  return useMutation<AmendmentSchema, NewApiErrorResponse, PostAmendmentOption>(
    {
      ...mutationOptions,
      mutationKey: amendmentsCacheKeys.post_amendment(),
      mutationFn: opts => postAmendment(opts),
      onSuccess: (...args) => {
        mutationOptions?.onSuccess?.(...args)
        appQueryClient.invalidateQueries({
          queryKey: amendmentsCacheKeys.fetch_contracts_invalidate()
        })
      }
    }
  )
}
