import { Popover as MantinePopover } from '@mantine/core'
import type { FC, PropsWithChildren } from 'react'
import React, { memo } from 'react'

import { usePopoverContext } from '../Popover.context'

type Props = PropsWithChildren

export const PopoverTarget: FC<Props> = memo(({ children, ...other }) => {
  const ctx = usePopoverContext()

  const mouseHandlerProps =
    ctx?.trigger === 'hover'
      ? {
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          onMouseEnter: () => ctx.onChange(true),
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          onMouseLeave: () => ctx.onChange(false)
        }
      : {
          onClick: () => {
            // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
            // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
            // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
            ctx.onChange(!ctx.opened)
          }
        }

  /**
   * Pass mouse handler props to handle trigger=hover
   * Also be sure to forward the ref
   */

  const childrenWithProps = React.isValidElement(children)
    ? React.cloneElement(children, {
        ...mouseHandlerProps
      })
    : children

  return (
    <MantinePopover.Target {...other}>
      {childrenWithProps}
    </MantinePopover.Target>
  )
})

PopoverTarget.displayName = 'PopoverTarget'
