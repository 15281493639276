import { authService } from '@libs/data-access/queries'
import {
  SNAPSHIFT_CLIENT_SECRET,
  SNAPSHIFT_CLIENT_ID
} from '@libs/utils/environments'
import { trackerService } from '@spa/core/externals/segment'
import { routes } from '@spa/core/router'
import { useNavigate } from 'react-router-dom'

import { clearAuthState } from '../authServiceUtils'

import { useAuthState } from './useAuthState'
import { useRedirectToWhenSignedOut } from './useRedirectToWhenSignedOut'

type Params = {
  redirectTo?: boolean
}

export const useClearAuth = ({ redirectTo = false }: Params = {}) => {
  const { mutateAsync } = authService.usePostRevokeOauthToken()
  const navigate = useNavigate()
  const { updateAuthState } = useAuthState()
  const redirectToUrl = useRedirectToWhenSignedOut()

  return async () => {
    await mutateAsync({
      payload: {
        client_secret: SNAPSHIFT_CLIENT_SECRET,
        client_id: SNAPSHIFT_CLIENT_ID
      }
    })

    trackerService.reset()
    clearAuthState(updateAuthState)
    navigate(redirectTo ? redirectToUrl : routes.signIn.rootPath)
  }
}
