import type { FlipperFeatureFlagsSchema } from '@libs/data-access/entities'

import { httpServices } from '../../../apis'

export const fetchFlipperFeatureFlags = async () => {
  const featureFlags = await httpServices.core.get<FlipperFeatureFlagsSchema>(
    '/feature_flags',
    {
      params: {
        account_id: httpServices.authInfo.accountId
      }
    }
  )

  return featureFlags.data
}
