import type { AccountDetails } from '@libs/data-access/entities'
import type { UseAppQueryOptions } from '@libs/data-access/queries'
import { queryClientOptions } from '@libs/data-access/queries'
import { useQuery } from '@tanstack/react-query'

import { fetchAccountDetails } from '../accountDetails.api'
import { accountDetailsCacheKeys } from '../accountDetails.cacheKeys'

export const useGetAccountDetails = (
  queryOptions?: UseAppQueryOptions<AccountDetails>
) => {
  return useQuery({
    ...queryOptions,
    queryKey: accountDetailsCacheKeys.getByAccountId(),
    queryFn: () => fetchAccountDetails(),
    staleTime: queryClientOptions.time.INFINITY
  })
}
