import type {
  BillingInformation,
  LocationGroupBillingInfo
} from '@libs/data-access/entities'

import { httpServices } from '../../../apis'
import type { QueryFnParams } from '../../../types'

export type FetchLocationGroupBillingInfo = QueryFnParams<{
  pathParams: {
    location_group_id: string
  }
}>

export const fetchLocationGroupBillingInfo = async (
  options: FetchLocationGroupBillingInfo
) => {
  const response = await httpServices.billing.get<LocationGroupBillingInfo>(
    `/location-based/billing-info/${options.pathParams.location_group_id}/new`
  )

  return response.data
}

export type PutLocationGroupUpdateBillingInfoOptions = QueryFnParams<{
  pathParams: {
    location_group_id: string
  }
  payload: BillingInformation
}>

export const putLocationGroupUpdateBillingInfo = async (
  options: PutLocationGroupUpdateBillingInfoOptions
) => {
  const response = await httpServices.billing.put<BillingInformation>(
    `/location-based/billing-info/${options.pathParams.location_group_id}`,
    { billing_information: options.payload }
  )

  return response.data
}

export type PostLinkLocationToGroup = QueryFnParams<{
  pathParams: {
    location_group_id: string
    location_id: string
  }
}>

export type PutLocationGroupCustomerEmail = QueryFnParams<{
  pathParams: {
    location_group_id: string
  }
  payload: { email: string }
}>

export const putLocationGroupCustomerEmail = async (
  options: PutLocationGroupCustomerEmail
) => {
  const response = await httpServices.billing.put<{ email: string }>(
    `/location-based/billing-info/${options.pathParams.location_group_id}/email`,
    { email: options.payload.email }
  )

  return response.data
}

export const postLinkLocationToGroup = async (
  options: PostLinkLocationToGroup
) => {
  const response = await httpServices.billing.post<{}>(
    `/location-based/location-groups/${options.pathParams.location_group_id}/locations/${options.pathParams.location_id}`
  )

  return response.data
}

export type CreateLocationGroupEmailRecipient = QueryFnParams<{
  pathParams: {
    location_group_id: string
  }
  payload: { email: string }
}>

export const createLocationGroupEmailRecipient = async (
  options: CreateLocationGroupEmailRecipient
) => {
  const response = await httpServices.billing.post<{ email: string }>(
    `/location-based/billing-info/${options.pathParams.location_group_id}/recipients`,
    { email: options.payload.email }
  )

  return response.data
}

export type UpdateLocationGroupEmailRecipient = QueryFnParams<{
  pathParams: {
    location_group_id: string
    recipient_id: string
  }
  payload: { email: string }
}>

export const updateLocationGroupEmailRecipient = async (
  options: UpdateLocationGroupEmailRecipient
) => {
  const response = await httpServices.billing.put<{ email: string }>(
    `/location-based/billing-info/${options.pathParams.location_group_id}/recipients/${options.pathParams.recipient_id}`,
    { email: options.payload.email }
  )

  return response.data
}

export type DeleteLocationGroupEmailRecipient = QueryFnParams<{
  pathParams: {
    location_group_id: string
    recipient_id: string
  }
}>

export const deleteLocationGroupEmailRecipient = async (
  options: DeleteLocationGroupEmailRecipient
) => {
  const response = await httpServices.billing.delete<{}>(
    `/location-based/billing-info/${options.pathParams.location_group_id}/recipients/${options.pathParams.recipient_id}`
  )

  return response.data
}
