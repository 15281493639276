import type { AccountRecord } from '@libs/data-access/entities'
import {
  useAccountFlipperFeatureFlags,
  userService
} from '@libs/data-access/queries'
import { Badge, ExpandIcon, RewardStarsIcon } from '@libs/ui/ds'
import { useAppNavBarTranslation } from '@libs/utils/translations'
import { useMemoSelector } from '@spa/redux/hooks'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import React from 'react'
import { getCurrentMembershipSelector } from 'snap-redux/modules/memberships/selectors'
import { getCurrentUser } from 'snap-redux/modules/users/selectors'

const comboPayBadgeCVA = {
  root: cva([
    'cursor-pointer hover:bg-semantic-green-300 active:bg-semantic-green-400'
  ])
}

type Props = {
  account: AccountRecord
}

export const ComboPayBadge: FC<Props> = ({ account }) => {
  const currentUser = useMemoSelector(getCurrentUser)
  const currentMembership = useMemoSelector(getCurrentMembershipSelector)

  const permissions = userService.membership.useFeaturePermissions()
  const featureFlags = useAccountFlipperFeatureFlags()
  const { t } = useAppNavBarTranslation()

  if (featureFlags?.hr_arhia_hr_synchronisation) {
    return (
      <Badge
        className={comboPayBadgeCVA.root()}
        label={t('comboPay.goTo')}
        leftIcon={<ExpandIcon />}
        color='semantic.green'
        onClick={() =>
          window.open(` https://combohr.arhia.fr/#/login`, '_blank')
        }
      />
    )
  }

  if (permissions?.payroll.should_promote) {
    return (
      <Badge
        className={comboPayBadgeCVA.root()}
        label={t('comboPay.promote')}
        leftIcon={<RewardStarsIcon />}
        color='semantic.green'
        onClick={() =>
          window.open(
            `https://combohr.com/app/paie?account_id=${account.id}&slug=${account.subdomain}&user_id=${currentUser?.id}&role=${currentMembership.role}&billin_plan=${account.billing_plan}`,
            '_blank'
          )
        }
      />
    )
  }
  return null
}
