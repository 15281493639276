import type { BaseComponent } from '@libs/ui/ds/lib/internals'
import { Combobox } from '@mantine/core'
import { cva } from 'class-variance-authority'
import type { FC, HTMLAttributes } from 'react'

const comboboxEmptyCVA = cva([
  'w-full !break-words px-8 py-5 text-xs text-neutral-500'
])

export type ComboboxEmptyProps = BaseComponent & HTMLAttributes<HTMLDivElement>

export const ComboboxEmpty: FC<ComboboxEmptyProps> = ({
  className,
  children,
  ...other
}) => {
  return (
    <Combobox.Empty {...other} className={comboboxEmptyCVA({ className })}>
      {children}
    </Combobox.Empty>
  )
}
