import type { MomentInput } from 'moment'
import moment from 'moment'

import { addMinutes } from './dateUtils'
import type { Range } from './types'

/**
 * Compute the start of an interval given a custom date
 */
export const getStartOfInterval = (date: MomentInput, interval: number) => {
  const minutesOffset = moment(date).minute() % interval
  const startInterval = addMinutes(date, -minutesOffset)
  return startInterval
}

/**
 * For a given shift, return a chunk of ranges intervals
 * The size of each range is defined by the input interval
 */
export const chunkShiftByInterval = (
  rangeItem: { start: string; end: string },
  interval: number
) => {
  let currentIntervalStart = getStartOfInterval(rangeItem.start, interval)
  const arr: Range[] = []

  while (currentIntervalStart.isBefore(rangeItem.end)) {
    const intervalEnd = addMinutes(currentIntervalStart, interval)
    arr.push({
      start: currentIntervalStart.toISOString(),
      end: intervalEnd.toISOString()
    })
    currentIntervalStart = intervalEnd
  }

  return arr
}
