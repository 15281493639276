import React, { createContext, useMemo, useReducer } from 'react'

const OPEN = 'OPEN'
const CLOSE = 'CLOSE'

type ModalReadContextState = {
  opened: {
    name: string
    data: { [k: string]: any }
  }
}
type ModalDispatchContextState = {
  open: (name: string, data: any) => any
  close: (name: string) => any
}

export const reducer = (state: ModalReadContextState, action) => {
  switch (action.type) {
    case OPEN:
      return { opened: action.payload }
    case CLOSE:
      if (state.opened?.name === action.payload.name) {
        return { opened: null }
      }
      return state
    default:
      return state
  }
}

export const ModalDispatchContext =
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  createContext<ModalDispatchContextState>(null)
// @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
export const ModalReadContext = createContext<ModalReadContextState>(null)

export const ModalStateProvider = ({ children }) => {
  const initialState: ModalReadContextState = {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    opened: null
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  const actions = useMemo(() => {
    const open = (name: string, data: any) => {
      dispatch({ type: OPEN, payload: { name, data } })
    }
    const close = (name: string) => {
      dispatch({ type: CLOSE, payload: { name } })
    }

    return { open, close }
  }, [])

  return (
    <ModalDispatchContext.Provider value={actions}>
      <ModalReadContext.Provider value={state}>
        {children}
      </ModalReadContext.Provider>
    </ModalDispatchContext.Provider>
  )
}
