import moment from 'moment'

import type { FormatterParams } from '../../types'

/**
 * Convert month index to month name
 *
 * Input: 1
 * Output: January
 *
 */
export function monthNameFromIndex({
  value: monthIndex
}: FormatterParams<number>) {
  return moment(monthIndex, 'MM').format('MMMM')
}

export default {
  format: monthNameFromIndex
}
