import { scheduleService } from '@libs/data-access/queries'
import { flatten } from 'lodash-es'
import { normalize } from 'normalizr'
import { all, fork, put, select } from 'redux-saga/effects'
import { getCurrentAccountId } from 'snap-redux/modules/accounts/selectors'

import { Alert } from '../../../models'
import { setEntities } from '../../../reducers/entities'
import type { PlanningPolicies } from '../../../selectors'
import { getPlanningPolicies, getWeekDateParams } from '../../../selectors'
import type { PayloadActionWithoutType } from '../../types/types'
import { getContractsChunks } from '../../utils/getContractsChunks'

/**
 * Fetch alerts by contract ids
 */
function* fetchAlerts({ accountId, startDate, endDate, contractIds }) {
  if (!startDate || !endDate || !contractIds.length) {
    return
  }

  const alerts = yield scheduleService.api.fetchScheduleAlerts({
    queryParams: {
      account_id: accountId,
      start_date: startDate,
      end_date: endDate,
      user_contract_ids: contractIds
    }
  })

  return alerts
}

type GetAlertsChunkedParams = PayloadActionWithoutType<{
  date: string
  contractIds: number[]
}>

/**
 * Fetch alerts and hydrate redux store
 * No loading state is needed for alerts since there is no UI loading state
 * and the request is made asynchronously
 */
export function* getAlertsChunked({
  payload: { contractIds }
}: GetAlertsChunkedParams): any {
  const policies: PlanningPolicies = yield select(getPlanningPolicies)

  /**
   * Prevent fetching for roles that has not access to alerts
   */
  if (!contractIds?.length || !policies.canSeeAlerts) {
    return
  }

  const accountId = yield select(getCurrentAccountId)
  const { week } = yield select(getWeekDateParams)

  const chunks = getContractsChunks(contractIds)

  yield all(
    chunks.map(ids => {
      return fork(function* () {
        const alerts = yield fetchAlerts({
          contractIds: ids,
          accountId,
          startDate: week.start,
          endDate: week.end
        })

        if (alerts) {
          const normalizedAlerts = normalize(flatten(alerts), [Alert]).entities
          yield put(setEntities(normalizedAlerts))
        }
      })
    })
  )
}
