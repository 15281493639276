import { useMemoSelector } from '@spa/redux/hooks'
import { useMemo } from 'react'
import { getCurrentMembershipSelector } from 'snap-redux/modules/memberships/selectors'
import { getRoleRights } from 'snap-redux/modules/memberships/utils'

export const useRoleRights = () => {
  const currentMembership = useMemoSelector(getCurrentMembershipSelector)

  const rolesRights = useMemo(
    () => getRoleRights(currentMembership),
    [currentMembership]
  )

  return useMemo(
    () => ({
      currentMembership,
      ...rolesRights
    }),
    [currentMembership, rolesRights]
  )
}
