import { race, take } from 'redux-saga/effects'

export function workerSagasFactory(task, cancelAction) {
  return function* (...args) {
    yield race({
      task: task(...args),
      cancel: take(cancelAction)
    })
  }
}
