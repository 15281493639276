import type { ButtonProps } from '@libs/ui/ds'
import type { FC } from 'react'
import { memo } from 'react'

import type { ComboboxOptionProps } from '../../../../overlays/Combobox/base/Combobox'
import { DropdownMenu } from '../../../../overlays/DropdownMenu/base/DropdownMenu'
import type { AdaptiveButtonListAction } from '../AdaptiveButtonList.types'

const menuItemCVA = {
  props: {
    intent: {
      danger: 'danger',
      'neutral-dark': 'neutral-dark',
      default: 'neutral-dark'
    }
  }
} as const

const buttonToDropdownItemProps = ({
  leftIcon,
  rightIcon,
  onClick,
  disabled,
  intent = 'default'
}: ButtonProps) => {
  return {
    leftIcon,
    rightSection: rightIcon,
    onClick: onClick as unknown,
    disabled,
    intent: menuItemCVA.props.intent[intent],
    value: ''
  } as ComboboxOptionProps
}

export const AdaptiveDropdownItem: FC<AdaptiveButtonListAction> = memo(
  ({ asButton, children, ...other }) => {
    const itemProps = (
      asButton ? buttonToDropdownItemProps(other) : other
    ) as ComboboxOptionProps

    return <DropdownMenu.Option {...itemProps}>{children}</DropdownMenu.Option>
  }
)
AdaptiveDropdownItem.displayName = 'AdaptiveDropdownItem'
