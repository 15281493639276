import { wsServices } from '../../../../apis'

type ShiftNotificationSent = {
  type: 'SHIFT_NOTIFICATION_SENT'
  data: {
    membership_id: number
    full_name: string
    shift_id: number
  }
}

type ShiftsNotificationsSent = {
  type: 'SHIFTS_NOTIFICATIONS_SENT'
}

export const useShiftSentNotificationsSubscription =
  wsServices.core.subscription.create({
    subscribePayload: () => ({
      channel: 'Planning::ShiftSentNotificationsChannel'
    }),
    messages: {
      onNotificationSent:
        wsServices.core.subscription.defineMessageHandler<ShiftNotificationSent>(
          { type: 'SHIFT_NOTIFICATION_SENT' }
        ),
      onNotificationsSent:
        wsServices.core.subscription.defineMessageHandler<ShiftsNotificationsSent>(
          { type: 'SHIFTS_NOTIFICATIONS_SENT' }
        )
    }
  })
