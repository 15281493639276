/* eslint-disable react-hooks/rules-of-hooks */
import { mapValues, noop, throttle } from 'lodash-es'
import { useEffect, useState } from 'react'
import type ReconnectingWebSocket from 'reconnecting-websocket'

import type {
  WSSubscribeOptions,
  WebSocketClientInterface
} from '../WebSocketClient.interface'

import { coreWSAuth } from './coreWSAuth.service'

class CoreWSService implements WebSocketClientInterface {
  // @ts-ignore TO DO: fix (https://snapshiftapp.atlassian.net/browse/COP-332)
  private client: ReconnectingWebSocket

  // @ts-ignore TO DO: fix (https://snapshiftapp.atlassian.net/browse/COP-332)
  private defaultMessageThrottleInMs: 200

  /**
   * Establishes a WebSocket connection using the coreWSAuth service.
   */
  useConnect() {
    const [state, setState] = useState({ isPending: true, isFinish: false })

    useEffect(() => {
      coreWSAuth
        .connect()
        .then(res => {
          this.client = res
        })
        .catch(() => {
          this.mockClient()
        })
        .finally(() => setState({ isPending: false, isFinish: true }))
    }, [])

    return state
  }

  /**
   * If the WS connection is not established, it will create a mock client to avoid errors.
   */
  private mockClient() {
    this.client = {
      send: noop,
      removeEventListener: noop,
      addEventListener: noop
    } as ReconnectingWebSocket
  }

  /**
   * Sends a subscription request to the WebSocket server with the specified options.
   */
  subscribe(opts: WSSubscribeOptions): void {
    this.client.send(
      JSON.stringify({
        command: 'subscribe',
        identifier: JSON.stringify(opts)
      })
    )
  }

  /**
   * Sends an unsubscription request to the WebSocket server with the specified options.
   */
  unsubscribe(opts: WSSubscribeOptions): void {
    this.client.send(
      JSON.stringify({
        command: 'unsubscribe',
        identifier: opts
      })
    )
  }

  /**
   * Sends a generic WebSocket message with the provided options.
   */
  send(opts: any): void {
    return this.client.send(
      JSON.stringify({
        command: opts?.command || 'message',
        ...mapValues(opts, value => JSON.stringify(value))
      })
    )
  }

  /**
   * Listens for incoming WebSocket messages and executes the provided callback, excluding 'ping' messages.
   */
  onMessage(
    callback: (...args: any) => void,
    opts?: { throttleDelay: number }
  ) {
    const handleMessage = message => {
      const res = JSON.parse(message.data)
      if (res.type !== 'ping' && res?.message) {
        callback(res.message)
      }
    }

    const throttledHandleMessage = throttle(
      handleMessage,
      opts?.throttleDelay || this.defaultMessageThrottleInMs
    )

    this.client.addEventListener('message', throttledHandleMessage)

    return {
      unsubscribe: () => {
        this.client.removeEventListener('message', throttledHandleMessage)
      }
    }
  }

  /**
   * Listens for WebSocket errors and executes the provided callback.
   */
  onError(callback: (...args: any) => void): void {
    return this.client.addEventListener('error', callback)
  }
}

export const coreWSService = new CoreWSService()
