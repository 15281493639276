import type { CounterSchema } from '@libs/data-access/entities'

import type { DeleteRoute, GetRoute, PostRoute, PutRoute } from '../../types'

export const countersRoutes = {
  postCounter: { method: 'POST', path: '/counters' },
  getCounter: { method: 'GET', path: '/counters/:id' },
  putCounter: { method: 'PUT', path: '/counters/:id' },
  deleteCounter: { method: 'DELETE', path: '/counters/:id' },
  getCountersByLocation: {
    method: 'GET',
    path: '/locations/:location_id/counters'
  },
  getCountersByUserContract: {
    method: 'GET',
    path: '/user_contracts/:user_contract_id/counters'
  }
} as const

export type CountersRoutes = {
  postCounter: PostRoute<[], {}, CounterSchema>
  getCounter: GetRoute<['id'], {}, CounterSchema>
  putCounter: PutRoute<['id'], {}, CounterSchema>
  deleteCounter: DeleteRoute<['id'], {}, CounterSchema>
  getCountersByLocation: GetRoute<['location_id'], {}, CounterSchema[]>
  getCountersByUserContract: GetRoute<['user_contract_id'], {}, CounterSchema[]>
}
