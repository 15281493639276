import { outlineFocusMixin } from '@libs/ui/ds/lib/styles/mixins/mixins'
import { UnstyledButton } from '@mantine/core'
import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'

import { useButtonProps } from '../hooks/useButtonProps'
import { createButton } from '../utils/createButton'
import { useHandleClickOnListeners } from '../utils/useHandleClickOnListeners'

import type { BaseButtonProps } from './BaseButton.types'

const baseButtonCVA = cva([
  'relative overflow-hidden disabled:cursor-default aria-disabled:cursor-default',
  outlineFocusMixin
])

export const BaseButton = createButton<BaseButtonProps>(
  forwardRef<HTMLButtonElement, BaseButtonProps>(
    ({ className, keysListener = [], ...other }, forwardedRef) => {
      const ref = useHandleClickOnListeners(keysListener, forwardedRef)
      const buttonProps = useButtonProps(other)

      const classNames = baseButtonCVA({
        className
      })

      return (
        <UnstyledButton
          ref={ref}
          className={classNames}
          unstyled
          {...other}
          {...buttonProps}
        />
      )
    }
  )
)

BaseButton.displayName = 'Button'
export type { BaseButtonProps } from './BaseButton.types'
export { createButton } from '../utils/createButton'
