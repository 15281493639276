import { httpServices } from 'libs/data-access/queries/src/lib/apis'
import type { QueryFnParams } from 'libs/data-access/queries/src/lib/types'

export type SetAccountCardOptions = QueryFnParams<{
  payload: {
    stripe_token: string
  }
}>

export const setAccountCard = async (options: SetAccountCardOptions) => {
  await httpServices.billing.post<void>(
    `/account-based/accounts/${httpServices.authInfo.accountId}/payment/card`,
    options.payload
  )
}
