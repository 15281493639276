import type { FormatterParams } from '../../types'

function formatOneName(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase()
}

/**
 * Given a firstname or lastname, returns an ISO name
 * Capitalize first letter of names or composed names
 *
 * Input: georges sand
 * Output: Georges Sand
 */
export function isoName({ value = '' }: FormatterParams<string>) {
  // All except alpha characters
  const re = /[\s-']+/g

  // Get array of all special characters
  const specialChars = [...value.matchAll(re)]

  // Get array of all words
  const words = value.split(re)
  const formattedWords = words.map(formatOneName)

  return formattedWords.reduce((acc, word, idx) => {
    return `${acc}${word}${specialChars[idx] || ''}`
  }, '')
}

export default {
  format: isoName
}
