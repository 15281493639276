import { Center } from '@libs/ui/ds/lib/components/primitiveLayouts/Center/base/Center'
import { cva } from 'class-variance-authority'
import type { FC, PropsWithChildren, ReactElement } from 'react'

import type { TableProps } from '../../Table.types'

import { useTableComputedStatus } from './useTableComputedStatus'

type Props = PropsWithChildren<{
  states?: TableProps['states']
  data: TableProps['data']
}>

const tableStateCVA = {
  wrap: cva(['py-12'])
}

export const TableStatesContent: FC<Props> = ({ states, data, children }) => {
  const status = useTableComputedStatus({ data, states })

  if (status === 'error') {
    return (
      <Center className={tableStateCVA.wrap()}>{states?.errorContent}</Center>
    )
  }

  if (status === 'empty') {
    return (
      <Center className={tableStateCVA.wrap()}>{states?.emptyContent}</Center>
    )
  }

  return children as ReactElement
}
