import { useDSTranslation } from '@libs/ui/ds/lib/internals'
import type { MomentInput } from 'moment'
import moment from 'moment'
import { useMemo } from 'react'

/**
 * Convert string to date
 */
export const toDate = (opts: {
  value?: string
  format?: string
  fallback?: Date
}) => {
  const momentDate = moment(opts.value, opts.format)
  return momentDate.isValid() ? momentDate.endOf('day').toDate() : opts.fallback
}

/**
 * Convert date to string
 */
export const toString = (opts: {
  value: MomentInput
  format: string
  fallback?: any
}) => {
  const str = moment(opts.value).format(opts.format)
  return moment(opts.value).isValid() ? str : opts.fallback
}

/**
 * Check if date type is valid
 * And if the year is over 100 (Mantine related logic)
 */
export const isDateValid = (value: MomentInput) => {
  const isValid = moment(value).isValid()
  const isYearOver100 = moment(value).year() > 100
  return isValid && isYearOver100
}

export const isInConstrainedRange = (opts: {
  minDate?: string
  maxDate?: string
  value?: string
}) => {
  const isBeforeValid = opts.minDate
    ? moment(opts.value).isSameOrAfter(opts.minDate, 'day')
    : true
  const isAfterValid = opts.maxDate
    ? moment(opts.value).isSameOrBefore(opts.maxDate, 'day')
    : true
  return isBeforeValid && isAfterValid
}

/**
 * Returns error message for date input
 */
export const useErrorMessage = (opts: {
  value?: string
  isDirty: boolean
  minDate?: string
  maxDate?: string
}) => {
  const { t } = useDSTranslation()

  return useMemo(() => {
    if (!opts.isDirty) {
      return undefined
    }

    if (opts.isDirty && opts.value === '') {
      return undefined
    }

    if (!isDateValid(opts.value)) {
      return t('ds.components.datePickers.invalidFormat')
    }

    if (
      !isInConstrainedRange({
        minDate: opts.minDate,
        maxDate: opts.maxDate,
        value: opts.value
      })
    ) {
      return t('ds.components.datePickers.dateOutsideRange')
    }

    return undefined
  }, [opts.value, opts.isDirty])
}

/**
 * Returns error message for range input
 */
export const useRangeErrorMessage = (opts: {
  isDirty: boolean
  minDate?: string
  maxDate?: string
  value?: {
    start?: string
    end?: string
  }
}) => {
  const { start, end } = opts.value || {}
  const { t } = useDSTranslation()

  return useMemo(() => {
    if (!opts.isDirty) {
      return undefined
    }

    if (!isDateValid(start) || !isDateValid(end)) {
      return t('ds.components.datePickers.invalidFormat')
    }

    const isInvalidRange = moment(start).isAfter(end, 'day')
    if (isInvalidRange) {
      return t('ds.components.datePickers.invalidRange')
    }

    if (
      !isInConstrainedRange({
        minDate: opts.minDate,
        maxDate: opts.maxDate,
        value: start
      })
    ) {
      return t('ds.components.datePickers.startDateOutsideRange')
    }

    if (
      !isInConstrainedRange({
        minDate: opts.minDate,
        maxDate: opts.maxDate,
        value: end
      })
    ) {
      return t('ds.components.datePickers.endDateOutsideRange')
    }

    return undefined
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  }, [opts.value.start, opts.value.end])
}
