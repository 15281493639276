import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_SHIFT_TEMPLATES,
  FETCH_SHIFT_TEMPLATES_SUCCEEDED,
  FETCH_SHIFT_TEMPLATES_FAILED,
  CREATE_SHIFT_TEMPLATE,
  CREATE_SHIFT_TEMPLATE_SUCCEEDED,
  CREATE_SHIFT_TEMPLATE_FAILED,
  UPDATE_SHIFT_TEMPLATE,
  UPDATE_SHIFT_TEMPLATE_SUCCEEDED,
  UPDATE_SHIFT_TEMPLATE_FAILED,
  DELETE_SHIFT_TEMPLATE,
  DELETE_SHIFT_TEMPLATE_SUCCEEDED,
  DELETE_SHIFT_TEMPLATE_FAILED,
  DELETE_TEAM_SUCCEEDED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import {
  mergeRecords,
  updateRecord,
  deleteRecord
} from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchShiftTemplates = (teamId, resolve, reject) => ({
  type: FETCH_SHIFT_TEMPLATES,
  teamId,
  resolve,
  reject
})

export const createShiftTemplate = (shiftTemplateData, resolve, reject) => ({
  type: CREATE_SHIFT_TEMPLATE,
  shiftTemplateData,
  resolve,
  reject
})

export const updateShiftTemplate = (
  shiftTemplateId,
  shiftTemplateData,
  resolve,
  reject
) => ({
  type: UPDATE_SHIFT_TEMPLATE,
  shiftTemplateId,
  shiftTemplateData,
  resolve,
  reject
})

export const deleteShiftTemplate = (shiftTemplateId, resolve, reject) => ({
  type: DELETE_SHIFT_TEMPLATE,
  shiftTemplateId,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const shiftTemplatesSagaEntity = {
  success: (data, teamId) => ({
    type: FETCH_SHIFT_TEMPLATES_SUCCEEDED,
    data,
    teamId
  }),
  failure: (error, teamId) => ({
    type: FETCH_SHIFT_TEMPLATES_FAILED,
    error,
    teamId
  }),

  fetchAPI: (teamId, options, params) =>
    callAPI(
      `/teams/${teamId}/shift_templates?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchShiftTemplates({ teamId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    shiftTemplatesSagaEntity,
    teamId,
    undefined,
    undefined,
    resolve,
    reject
  )
}

const createShiftTemplateSagaEntity = {
  success: data => ({ type: CREATE_SHIFT_TEMPLATE_SUCCEEDED, data }),
  failure: error => ({ type: CREATE_SHIFT_TEMPLATE_FAILED, error }),

  fetchAPI: (id, options) =>
    callAPI('/shift_templates', { method: 'POST', ...options })
}

export function* doCreateShiftTemplate({ shiftTemplateData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createShiftTemplateSagaEntity,
    null,
    null,
    shiftTemplateData,
    resolve,
    reject
  )
}

const updateShiftTemplateSagaEntity = {
  success: (data, shiftTemplateId) => ({
    type: UPDATE_SHIFT_TEMPLATE_SUCCEEDED,
    data,
    shiftTemplateId
  }),
  failure: (error, shiftTemplateId) => ({
    type: UPDATE_SHIFT_TEMPLATE_FAILED,
    error,
    shiftTemplateId
  }),

  fetchAPI: (shiftTemplateId, options) =>
    callAPI(`/shift_templates/${shiftTemplateId}`, {
      method: 'PUT',
      ...options
    })
}

export function* doUpdateShiftTemplate({
  shiftTemplateId,
  shiftTemplateData,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    updateShiftTemplateSagaEntity,
    shiftTemplateId,
    null,
    shiftTemplateData,
    resolve,
    reject
  )
}

const deleteShiftTemplateSagaEntity = {
  success: (data, shiftTemplateId) => ({
    type: DELETE_SHIFT_TEMPLATE_SUCCEEDED,
    data,
    shiftTemplateId
  }),
  failure: (error, shiftTemplateId) => ({
    type: DELETE_SHIFT_TEMPLATE_FAILED,
    error,
    shiftTemplateId
  }),

  fetchAPI: (shiftTemplateId, options) =>
    callAPI(`/shift_templates/${shiftTemplateId}`, {
      method: 'DELETE',
      ...options
    })
}

export function* doDeleteShiftTemplate({ shiftTemplateId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    deleteShiftTemplateSagaEntity,
    shiftTemplateId,
    null,
    null,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const ShiftTemplate = Immutable.Record({
  id: null,
  starts_at: null,
  ends_at: null,
  breakduration: null,
  index: null,
  team_id: null,
  label_id: null,
  shift_breaks: Immutable.List(),
  shift_meals: Immutable.List()
})

const shiftTemplateForeignKeys = ['team_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function shiftTemplates(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_SHIFT_TEMPLATES: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_SHIFT_TEMPLATES_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_SHIFT_TEMPLATES_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const shiftTemplates = Immutable.fromJS(action.data)

      return mergeRecords(
        state,
        ShiftTemplate,
        shiftTemplates,
        // @ts-ignore migration from js(x) to ts(x)
        shiftTemplateForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    case CREATE_SHIFT_TEMPLATE_SUCCEEDED:
    case UPDATE_SHIFT_TEMPLATE_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const shiftTemplate = Immutable.fromJS(action.data)
      return updateRecord(
        state,
        ShiftTemplate,
        shiftTemplate,
        // @ts-ignore migration from js(x) to ts(x)
        shiftTemplateForeignKeys
      )
    }
    case DELETE_SHIFT_TEMPLATE_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { shiftTemplateId } = action
      // @ts-ignore migration from js(x) to ts(x)
      return deleteRecord(state, shiftTemplateId, shiftTemplateForeignKeys)
    }
    case DELETE_TEAM_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { teamId } = action
      const shiftTemplatesIds =
        state.getIn(['relations', 'team_id', teamId]) || Immutable.Set()

      return state.withMutations(store => {
        store.deleteIn(['relations', 'team_id', teamId])
        shiftTemplatesIds.forEach(shiftTemplateId => {
          store.deleteIn(['data', shiftTemplateId])
        })
      })
    }
    default:
      return state
  }
}

export default filterActions(shiftTemplates, [
  FETCH_SHIFT_TEMPLATES,
  FETCH_SHIFT_TEMPLATES_FAILED,
  FETCH_SHIFT_TEMPLATES_SUCCEEDED,
  CREATE_SHIFT_TEMPLATE_SUCCEEDED,
  UPDATE_SHIFT_TEMPLATE_SUCCEEDED,
  DELETE_SHIFT_TEMPLATE_SUCCEEDED,
  DELETE_TEAM_SUCCEEDED
])
