import type { AccountSchema } from '@libs/data-access/entities'
import type { UseAppQueryOptions } from '@libs/data-access/queries'
import { queryClientOptions } from '@libs/data-access/queries'
import { useQuery } from '@tanstack/react-query'

import type { FetchCurrentAccountOptions } from '../account.api'
import { fetchCurrentAccount } from '../account.api'
import { accountCacheKeys } from '../account.cacheKeys'

export const useFetchCurrentAccount = (
  request?: FetchCurrentAccountOptions,
  queryOptions?: UseAppQueryOptions<AccountSchema>
) => {
  return useQuery({
    ...queryOptions,
    retry: 2,
    queryKey: accountCacheKeys.current(),
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    queryFn: () => fetchCurrentAccount(request),
    staleTime: queryOptions?.staleTime || queryClientOptions.time.INFINITY
  })
}
