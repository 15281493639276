import type { Model } from '@libs/data-access/entities'
import Immutable from 'immutable'
import { schema } from 'normalizr'
import type { Weeklyschedule as WeeklyscheduleSchema } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

export type WeeklyscheduleModel = Model<WeeklyscheduleSchema, {}>
export type Note = WeeklyscheduleSchema['daily_notes'][number]

const Weeklyschedule = new schema.Entity<WeeklyscheduleModel>(
  'weeklyschedules',
  {}
)

export function create(
  data: Partial<WeeklyscheduleModel>
): WeeklyscheduleModel {
  const original = {
    id: null,
    publication_message: null,
    week_start_date: null,
    week: null,
    published: false,
    published_at: null,
    daily_notes: [],

    /* Relations */
    team_id: null,
    published_by_id: null,

    updated_at: null,
    created_at: null,
    permissions: Immutable.Map().toJS()
  }

  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  return {
    ...original,
    ...data
  }
}

export default Weeklyschedule
