import { useMutation } from '@tanstack/react-query'
import type {
  ApiErrorResponse,
  UseAppMutationOptions
} from 'libs/data-access/queries/src/lib/types'

import { invalidateAccountStatusCache } from '../../../AccountStatus/accountStatus.cacheKeys'
import { reactivateAccountLocationBased } from '../accountReactivateLocationBased.api'
import { accountReactivateLocationBased } from '../accountReactivateLocationBased.cacheKeys'

export const useAccountReactivateLocationBased = (
  mutationOptions?: UseAppMutationOptions<{}, ApiErrorResponse, {}>
) => {
  return useMutation({
    ...mutationOptions,
    mutationKey: accountReactivateLocationBased.one(),
    mutationFn: () => reactivateAccountLocationBased(),
    onSuccess: (...args) => {
      mutationOptions?.onSuccess?.(...args)

      invalidateAccountStatusCache()
    }
  })
}
