import { billingService } from '@libs/data-access/queries'
import { useMemo } from 'react'

const useBillingPlanId = (): string | null => {
  const detailsQuery = billingService.account.useGetDetails()

  const isLocationBased = useMemo(() => {
    if (!detailsQuery || !detailsQuery.isSuccess || !detailsQuery.data) {
      return false
    }

    const { is_location_based } = detailsQuery.data
    return !!is_location_based
  }, [detailsQuery])

  // Conditionally enable billing info query based on isLocationBased
  const billingInfoQuery = billingService.account.useGetBillingInfo({
    enabled: !isLocationBased && detailsQuery && detailsQuery.isSuccess
  })

  const isSuccess =
    detailsQuery &&
    detailsQuery.isSuccess &&
    (isLocationBased || (detailsQuery && detailsQuery.isSuccess))

  const planId = useMemo(() => {
    if (!isSuccess) {
      return null
    }
    if (isLocationBased && detailsQuery.data) {
      return detailsQuery.data?.plan
    }

    return billingInfoQuery.data?.chargebee_plan_id
  }, [isSuccess, isLocationBased, billingInfoQuery, detailsQuery])

  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  return planId
}

export default useBillingPlanId
