import type { AccountLocationGroupNotPaidInvoice } from '@libs/data-access/entities'
import { useQuery } from '@tanstack/react-query'

import type { UseAppQueryOptions } from '../../../../types'
import { fetchNotPaidInvoices } from '../accountInvoices.api'
import { accountInvoicesCacheKeys } from '../accountInvoices.cacheKeys'

export const useGetAccountNotPaidInvoices = (
  queryOptions?: UseAppQueryOptions<AccountLocationGroupNotPaidInvoice[]>
) => {
  return useQuery({
    ...queryOptions,
    queryKey: accountInvoicesCacheKeys.getByAccountIdNotPaidInvoices(),
    queryFn: () => fetchNotPaidInvoices()
  })
}
