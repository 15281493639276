import { mapKeys } from 'lodash-es'

export const prefixKeys = (
  obj: Record<string, any> | null | undefined,
  prefix: string
): Record<string, any> => {
  if (!obj) {
    return {}
  }

  // Check if the object has a toJS method
  const jsObj = typeof obj.toJS === 'function' ? obj.toJS() : obj
  return mapKeys(jsObj, (_, key) => `${prefix}_${key}`)
}
