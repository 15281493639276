import { ViewIcon, ViewOffIcon } from '@libs/ui/ds/assets/icons'
import type { FC } from 'react'
import React from 'react'

import { IconButton } from '../../../buttons/IconButton/base/IconButton'

import type { PasswordInputRevealButtonProps } from './PasswordInputRevealButton.types'

export const PasswordInputRevealButton: FC<PasswordInputRevealButtonProps> = ({
  revealed,
  setRevealedValue
}) => {
  return (
    <IconButton
      tabIndex={-1}
      size='s'
      variant='tertiary'
      intent='neutral-dark'
      onClick={() => setRevealedValue(!revealed)}
      icon={revealed ? <ViewOffIcon /> : <ViewIcon />}
    />
  )
}
