import type { ShiftRecordMap } from '@libs/data-access/entities'
import { filter } from 'lodash-es'
import { put, all } from 'redux-saga/effects'
import { FETCH_RESTS_SUCCEEDED } from 'snap-redux/actionTypes'
import { deleteShiftsFromStore } from 'snap-redux/modules/shifts'

import { Step, createRestsSuccess, updateStep } from '../modules/main'

/**
 * Cleanup deleted overlapping shifts in redux store
 */
const removeShiftsFromStore = ({
  overlappingShiftsIds,
  successResults,
  shifts
}) => {
  if (!overlappingShiftsIds?.length) {
    return []
  }

  const contractIdsWihShiftsToDelete = successResults
    .filter(rest =>
      overlappingShiftsIds.includes(String(rest.user_contract_id))
    )
    .map(rest => rest.user_contract_id)

  return shifts
    .toArray()
    .filter(i => contractIdsWihShiftsToDelete.includes(i.user_contract_id))
}

/**
 * Format success and error results from bulk requests
 */
const getFormattedResults = results => {
  const successResults = filter(results, result => result.status !== 'err')
  const errorContractIds = filter(
    results,
    result => result.status === 'err'
  ).map(result => result.userContractId)
  return { successResults, errorContractIds }
}

/**
 * Create or update rest (with or without counter) given array of promises
 * Then handle response to update the store and cleanup overlapping shifts
 */
function* createOrUpdateRest({
  shifts,
  overlappingShiftsIds,
  promises,
  onFormSuccess
}: {
  overlappingShiftsIds?: string[]
  shifts: ShiftRecordMap
  promises: any
  onFormSuccess: () => void
}) {
  yield put(updateStep(Step.SUBMIT_LOADING))

  const results = yield all(promises)
  const { successResults, errorContractIds } = getFormattedResults(results)
  const overlappingShiftsToClean = removeShiftsFromStore({
    shifts,
    successResults,
    overlappingShiftsIds
  })

  yield put(
    createRestsSuccess({
      successResults,
      errorContractIds
    })
  )

  /**
   * Close the form if there is no errors on request
   */
  if (!errorContractIds.length) {
    onFormSuccess()
  }

  /**
   * Display rest form error page if there is request errors
   */
  if (errorContractIds.length) {
    yield put(updateStep(Step.SUBMIT_ERROR_FEEDBACK))
  }

  yield put({ type: FETCH_RESTS_SUCCEEDED, data: successResults })
  yield put(deleteShiftsFromStore(overlappingShiftsToClean))
}

export default createOrUpdateRest
