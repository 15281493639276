import type { GapVariant } from '../../utils/properties/gap'

export const gapNegativeMarginAdapter: Record<GapVariant, string> = {
  0: '-m-0',
  0.5: '-m-0.5',
  1: '-m-1',
  1.5: '-m-1.5',
  2: '-m-2',
  2.5: '-m-2.5',
  3: '-m-3',
  3.5: '-m-3.5',
  4: '-m-4',
  5: '-m-5',
  6: '-m-6',
  7: '-m-7',
  8: '-m-8',
  9: '-m-9',
  10: '-m-10',
  11: '-m-11',
  12: '-m-12',
  14: '-m-14',
  16: '-m-16',
  20: '-m-20',
  24: '-m-24'
} as const

export const gapPaddingAdapter: Record<GapVariant, string> = {
  0: 'p-0',
  0.5: 'p-0.5',
  1: 'p-1',
  1.5: 'p-1.5',
  2: 'p-2',
  2.5: 'p-2.5',
  3: 'p-3',
  3.5: 'p-3.5',
  4: 'p-4',
  5: 'p-5',
  6: 'p-6',
  7: 'p-7',
  8: 'p-8',
  9: 'p-9',
  10: 'p-10',
  11: 'p-11',
  12: 'p-12',
  14: 'p-14',
  16: 'p-16',
  20: 'p-20',
  24: 'p-24'
} as const
