import { I18nDSProvider } from '@libs/ui/ds/lib/internals/i18n'
import { MantineProvider } from '@mantine/core'
import { DatesProvider } from '@mantine/dates'
import { Notifications } from '@mantine/notifications'
import type { FC, PropsWithChildren } from 'react'

import '@mantine/notifications/styles.css'
import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import '../../styles/index.css'
import '../locales'

import { ConfirmModal } from '../../components/overlays/Modal/specialized/ConfirmModal/ConfirmModal'
import { ModalStateProvider } from '../../internals/contexts'
import { tailwindConfig } from '../resolvedConfig'

import {
  InternalDesignSystemProvider,
  useInternalDesignSystem
} from './InternalDesignSystemProvider'

export type DesignSystemProviderProps = {
  timezone?: string
}

export const DesignSystemProvider: FC<
  PropsWithChildren<DesignSystemProviderProps>
> = ({ children }) => {
  return (
    <I18nDSProvider>
      <InternalDesignSystemProvider>
        <DesignSystemProviderContent>{children}</DesignSystemProviderContent>
      </InternalDesignSystemProvider>
    </I18nDSProvider>
  )
}

const DesignSystemProviderContent: FC<PropsWithChildren> = ({ children }) => {
  const { config } = useInternalDesignSystem()

  return (
    <DatesProvider
      settings={{
        locale: config.language,
        timezone: config.timezone
      }}
    >
      <MantineProvider
        forceColorScheme='light'
        theme={{
          fontFamily: tailwindConfig.theme.fontFamily.sans
        }}
      >
        <ModalStateProvider>
          <ConfirmModal />
          <Notifications
            transitionDuration={300}
            position='bottom-left'
            limit={5}
          />
          {children}
        </ModalStateProvider>
      </MantineProvider>
    </DatesProvider>
  )
}
