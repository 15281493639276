import type { FC } from 'react'
import React, { memo, useEffect } from 'react'

import type { StepperProps } from './Stepper.types'
import { StepperStep } from './compound/StepperStep'
import { useStepperState } from './hooks/useStepperState'

const BaseStepper: FC<StepperProps> = memo(
  ({ value, onChange, children, ...other }) => {
    const { active, register } = useStepperState({
      value,
      onChange
    })

    const stepChildren = React.Children.map(children, ({ props }: any) => {
      return {
        disabled: props.disabled
      }
    })

    useEffect(() => {
      register.onChange({
        ...register.value,
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        steps: stepChildren
      })
    }, [JSON.stringify(stepChildren)])

    const items = React.Children.map(children, (childProps: any, idx) =>
      React.cloneElement(childProps, {
        active,
        stepIndex: idx
      })
    )

    return <div {...other}>{items}</div>
  }
)

type CompoundStepperType = {
  Step: typeof StepperStep
}

const TypedStepper = BaseStepper as typeof BaseStepper & CompoundStepperType
TypedStepper.displayName = 'Stepper'

TypedStepper.Step = StepperStep
export const Stepper = TypedStepper

export type { StepperProps } from './Stepper.types'
export type { StepperStepProps } from './compound/StepperStep'
export { useStepperState } from './hooks/useStepperState'
