import { ArrowDownIcon } from '@libs/ui/ds/assets/icons'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import { memo } from 'react'

import { IconButton } from '../../../../../buttons/IconButton/base/IconButton'
import { DropdownMenu } from '../../../../../overlays/DropdownMenu/base/DropdownMenu'
import { Flex } from '../../../../../primitiveLayouts/Flex/base/Flex'
import { SwitchAt } from '../../../../../primitiveLayouts/SwitchAt/base/SwitchAt'
import { useTabsContext } from '../../Tabs.context'

import type { TabsListProps } from './TabsList.types'

const tabsListCVA = {
  root: cva(
    [
      'h-13 max-h-13 items-center rounded-full border-none bg-brand-green-50 text-s'
    ],
    {
      variants: {
        dropdown: {
          true: 'cursor-pointer hover:bg-brand-green-100/75 active:bg-brand-green-200/50'
        },
        placement: {
          start: 'justify-start',
          end: 'justify-end'
        }
      }
    }
  ),
  listWrap: cva(['h-full flex-nowrap items-center gap-2 truncate px-2.5'], {
    variants: {
      spaced: {
        true: 'w-full justify-between'
      }
    }
  }),
  tabText: cva(['h-9 items-center self-center px-4']),
  tabSelectable: cva(
    [
      'flex h-9 cursor-pointer items-center justify-center rounded-full border-none px-4'
    ],
    {
      variants: {
        selected: {
          true: 'bg-brand-green-600 text-neutral-50 hover:bg-brand-green-700 active:bg-brand-green-800',
          false: 'hover:bg-brand-green-100 active:bg-brand-green-200'
        },
        disabled: {
          false: 'text-neutral-800',
          true: 'pointer-events-none text-brand-green-900/50'
        }
      }
    }
  )
}

export const TabsList: FC<TabsListProps> = memo(
  ({ data, placement, switchToDropdownAt, className, ...other }) => {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    const { value, onChange } = useTabsContext()
    const currentLabel = data.find(i => i.value === value)?.label

    return (
      <SwitchAt
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        breakpoint={switchToDropdownAt}
        smaller={
          <Flex
            {...other}
            row
            className={tabsListCVA.root({
              className,
              placement,
              dropdown: true
            })}
          >
            <DropdownMenu dropdownWidth='target'>
              <DropdownMenu.Target className='w-full'>
                <Flex row className={tabsListCVA.listWrap({ spaced: true })}>
                  <Flex row className={tabsListCVA.tabText()}>
                    {currentLabel}
                  </Flex>
                  <IconButton
                    intent='neutral-dark'
                    variant='tertiary'
                    icon={<ArrowDownIcon />}
                  />
                </Flex>
              </DropdownMenu.Target>
              <DropdownMenu.Dropdown>
                {data.map(item => (
                  <DropdownMenu.Option
                    key={item.value}
                    data-testid={`tabs-list-option-${item.value}`}
                    onClick={() => onChange(item.value)}
                    disabled={item.disabled}
                  >
                    {item.label}
                  </DropdownMenu.Option>
                ))}
              </DropdownMenu.Dropdown>
            </DropdownMenu>
          </Flex>
        }
        larger={
          <Flex
            {...other}
            row
            className={tabsListCVA.root({ className, placement })}
          >
            <Flex row className={tabsListCVA.listWrap()}>
              {data.map(item => (
                <div
                  key={item.value}
                  onClick={() => onChange(item.value)}
                  className={tabsListCVA.tabSelectable({
                    disabled: item.disabled,
                    selected: value === item.value
                  })}
                  data-testid={item.value}
                >
                  {item.label}
                </div>
              ))}
            </Flex>
          </Flex>
        }
      />
    )
  }
)

TabsList.displayName = 'TabsList'
