import { fetchResource, fetchBillingResource, fetchFile } from './api.service'

const buffer = []

export const fetchResourceWindow = (path, options) => {
  const index = buffer.length

  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  buffer.push(new AbortController())

  const ctrl = buffer[index]

  if (options?.service === 'billing') {
    return fetchBillingResource(path, {
      ...options,
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      signal: ctrl.signal
    }).finally(() => {
      buffer.splice(index, 1)
    })
  }

  if (options?.service === 'file') {
    return fetchFile(path, {
      ...options,
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      signal: ctrl.signal
    }).finally(() => {
      buffer.splice(index, 1)
    })
  }

  return fetchResource(path, {
    ...options,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    signal: ctrl.signal
  }).finally(() => {
    buffer.splice(index, 1)
  })
}

export const abort = () => {
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  buffer.forEach(ctrl => ctrl.abort('Abort stale requests'))
}
