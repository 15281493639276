export const ViewIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 3.67423C13.6316 3.61942 17.237 6.11863 19.4398 8.54057L19.4425 8.54349C19.8021 8.94275 20.0011 9.46106 20.0011 9.99841C20.0011 10.5358 19.8021 11.0541 19.4425 11.4533L19.4401 11.456C17.2879 13.8245 13.6911 16.3807 10 16.325C6.30887 16.3807 2.71285 13.8245 0.562155 11.4557L0.559987 11.4533C0.200355 11.0541 0.00134277 10.5358 0.00134277 9.99841C0.00134277 9.46106 0.200359 8.94275 0.559992 8.54349L0.562318 8.54091C2.76296 6.11861 6.3684 3.61942 10 3.67423ZM1.77216 9.6372C1.68321 9.73647 1.634 9.86509 1.634 9.99841C1.634 10.1318 1.68323 10.2604 1.77223 10.3597C3.80505 12.5981 6.98898 14.7428 9.98627 14.6924C9.99543 14.6923 10.0046 14.6923 10.0137 14.6924C13.011 14.7428 16.1957 12.5981 18.2303 10.3596C18.3193 10.2603 18.3685 10.1317 18.3685 9.99841C18.3685 9.86519 18.3193 9.73666 18.2305 9.63743C16.152 7.35287 12.958 5.2572 10.0138 5.30687C10.0046 5.30702 9.99541 5.30702 9.98624 5.30687C7.04194 5.2572 3.84796 7.35297 1.77216 9.6372Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.8595 7.92509C10.587 7.81228 10.2951 7.75425 10.0002 7.75433C9.55625 7.75433 9.12203 7.88597 8.75287 8.13262C8.38371 8.37927 8.09598 8.72984 7.92605 9.14C7.75612 9.55017 7.71163 10.0015 7.7982 10.437C7.88477 10.8724 8.09852 11.2724 8.41241 11.5864C8.72631 11.9004 9.12625 12.1142 9.56168 12.2009C9.99711 12.2876 10.4485 12.2432 10.8587 12.0734C11.2689 11.9036 11.6195 11.616 11.8663 11.2469C12.113 10.8778 12.2448 10.4438 12.2449 9.99983C12.2451 9.70498 12.1871 9.41277 12.0744 9.14033C11.9616 8.86789 11.7963 8.62033 11.5878 8.41182C11.3794 8.2033 11.1319 8.03791 10.8595 7.92509ZM10 6.12168C10.5093 6.12157 11.0136 6.22181 11.4841 6.41668C11.9547 6.61157 12.3823 6.89729 12.7425 7.25751C13.1026 7.61773 13.3882 8.04539 13.583 8.51604C13.7777 8.98663 13.8778 9.49097 13.8776 10.0003M10 6.12168C9.23322 6.12172 8.48344 6.3491 7.84586 6.77509C7.20822 7.20111 6.71123 7.80664 6.41771 8.51511C6.1242 9.22358 6.04735 10.0032 6.19689 10.7553C6.34642 11.5075 6.71561 12.1984 7.2578 12.7407C7.79998 13.283 8.4908 13.6524 9.2429 13.8021C9.995 13.9519 10.7746 13.8752 11.4832 13.5819C12.1917 13.2886 12.7974 12.7918 13.2236 12.1542C13.6497 11.5168 13.8773 10.767 13.8776 10.0003'
        fill='currentColor'
      />
    </svg>
  )
}
