import type { ReduxState } from '@spa/redux/ReduxState'
import { useMemoSelector } from '@spa/redux/hooks'

export const getTimeRange = (state: ReduxState) => {
  return state.plannings.timeRange
}

export const useGetTimeRange = () => {
  return useMemoSelector(state => state.plannings.timeRange)
}
