import { fuzzySearch } from '@libs/ui/ds'
import type { MembershipModel } from '@spa/scenes/Plannings/redux/models/Membership'

export const isContractFoundBySearchValue = (
  membership: MembershipModel,
  searchValue: string
) => {
  if (!searchValue) {
    return true
  }

  const fullname = `${membership.firstname} ${membership.lastname}`

  return fuzzySearch({
    search: searchValue,
    options: [fullname]
  })
}
