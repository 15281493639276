import { BaseButton } from '@libs/ui/ds/lib/components'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import React from 'react'

const paginationNumberItemCVA = cva(
  [
    'h-8 w-8 select-none rounded-full bg-transparent',
    'border-1 font-semibold text-brand-green-600',
    ''
  ],
  {
    variants: {
      selected: {
        true: [
          '!bg-brand-green-500  text-neutral-50 hover:!bg-brand-green-600 active:!bg-brand-green-700',
          'border-1 border-brand-green-500 hover:border-brand-green-600 active:border-brand-green-700'
        ],
        false: [
          'hover:bg-brand-green-100 active:bg-brand-green-200',
          'border-brand-green-200 hover:border-brand-green-100 active:border-brand-green-200'
        ]
      },
      disabled: {
        true: ''
      }
    },
    compoundVariants: [
      {
        selected: true,
        disabled: true,
        class: 'border-none !bg-neutral-200 !text-neutral-500'
      },
      {
        selected: false,
        disabled: true,
        class: 'border-brand-green-200 !bg-neutral-50 !text-brand-green-300'
      }
    ]
  }
)

type Props = {
  /**
   * Optional classnames
   */
  className?: string
  /**
   * Click handler of the item
   */
  onClick: (value: number) => void
  /**
   * Label of the page number
   */
  value: number
  /**
   * Selected state of the item
   */
  selected?: boolean
  disabled?: boolean
}

export const PaginationNumberItem: FC<Props> = ({
  value,
  selected,
  onClick,
  className,
  disabled
}) => {
  return (
    <BaseButton
      onClick={() => onClick(value)}
      className={paginationNumberItemCVA({
        className,
        selected,
        disabled
      })}
    >
      {value}
    </BaseButton>
  )
}
