import type {
  DocumentSignatureEntrySchema,
  DocumentEmployeeSchema,
  DocumentTemplateAttributesSchema,
  DocumentTemplateEntrySchema,
  MissingInformationSchema,
  DocumentStatuses,
  SignatoriesFormatType,
  InitalFormatType
} from '@libs/data-access/entities'
import type { PaginationDocumentSignatureState } from '@spa/redux/emp/DocumentSignature/modules/pagination'
import type { PaginationDocumentTemplateState } from '@spa/redux/emp/DocumentTemplate/modules/pagination'
import type { PaginationMissingInfoState } from '@spa/redux/emp/MissingInformation/modules/pagination'

import type { DeleteRoute, GetRoute, PatchRoute, PostRoute } from '../../types'

export const empRoutes = {
  // Missing Information
  getMissingInformation: {
    method: 'GET',
    path: '/employee_management/incomplete_files'
  },
  getPaginatedMissingInformation: {
    method: 'GET',
    path: '/employee_management/incomplete_files'
  },
  getMissingInformationByMembershipId: {
    method: 'GET',
    path: '/employee_management/incomplete_files'
  },
  getSsrRegisteryFile: {
    method: 'GET',
    path: '/employee_management/single_staff_registers'
  },

  // Document signature
  fetchDocumentSignature: {
    method: 'GET',
    path: '/employee_management/signable_documents'
  },
  downloadDocumentSignature: {
    method: 'GET',
    path: '/employee_management/signable_documents/:signable_document_id/download'
  },
  downloadDocumentProofSignature: {
    method: 'GET',
    path: '/employee_management/signable_documents/:signable_document_id/download_audit_trail'
  },
  postDocumentSignature: {
    method: 'POST',
    path: '/employee_management/signable_documents'
  },
  postRemindDocumentSignature: {
    method: 'POST',
    path: '/employee_management/signable_documents/:signable_document_id/remind'
  },
  deleteDocumentSignature: {
    method: 'DELETE',
    path: '/employee_management/signable_documents/:signable_document_id'
  },

  // Document template
  fetchDocumentTemplateAttributes: {
    method: 'GET',
    path: '/employee_management/document_templates/available_attributes'
  },
  fetchDocumentTemplate: {
    method: 'GET',
    path: '/employee_management/document_templates'
  },
  postDocumentTemplate: {
    method: 'POST',
    path: '/employee_management/document_templates'
  },
  fillDocumentTemplate: {
    method: 'POST',
    path: '/employee_management/document_templates/:document_template_id/fill'
  },
  updateDocumentTemplate: {
    method: 'PATCH',
    path: '/employee_management/document_templates/:document_template_id'
  },
  deleteDocumentTemplate: {
    method: 'DELETE',
    path: '/employee_management/document_templates/:document_template_id'
  },

  // Attachment (related to membership)
  fetchDocumentEmployee: {
    method: 'GET',
    path: '/employee_management/documents'
  },
  postSignatureDocumentEmployee: {
    method: 'POST',
    path: '/employee_management/documents/:document_id/request_signature'
  },
  postDocumentConvert: {
    method: 'POST',
    path: '/employee_management/documents/:document_id/convert'
  },
  deleteDocumentEmployee: {
    method: 'DELETE',
    path: '/employee_management/documents/:document_id'
  }
} as const

export type EMPRoutes = {
  getMissingInformation: GetRoute<
    [],
    {
      location_id?: number
      team_id?: number
      dpae?: boolean
      ssr?: boolean
    },
    { resources: MissingInformationSchema[]; meta: PaginationMissingInfoState }
  >
  getPaginatedMissingInformation: GetRoute<
    [],
    {
      location_id?: number
      team_id?: number
      page?: number
      per_page?: number
      dpae?: boolean
      ssr?: boolean
    },
    { resources: MissingInformationSchema[]; meta: PaginationMissingInfoState }
  >
  getMissingInformationByMembershipId: GetRoute<
    [],
    { membership_id: number; dpae?: boolean; ssr?: boolean },
    { resources: MissingInformationSchema; meta: PaginationMissingInfoState }
  >
  getSsrRegisteryFile: GetRoute<
    [],
    {
      location_id?: number
    },
    any
  >

  // Document signature
  fetchDocumentSignature: GetRoute<
    [],
    {
      page: number
      per_page: number
      location_ids?: number[]
      team_ids?: number[]
      status?: DocumentStatuses
      search?: string
    },
    {
      signable_documents: DocumentSignatureEntrySchema[]
      meta: PaginationDocumentSignatureState
    }
  >
  downloadDocumentSignature: GetRoute<['signable_document_id'], {}, any>
  downloadDocumentProofSignature: GetRoute<['signable_document_id'], {}, any>
  postDocumentSignature: PostRoute<
    [],
    {
      document: {
        data: string
        filename: string
        originalFile?: string | ArrayBuffer
      }
      manually_positioned: boolean
      signatories: SignatoriesFormatType[]
      ordered_signatures: boolean
      reminder_delay?: number
      attach_to_membership_id?: number
      read_and_approved?: boolean
      initals?: InitalFormatType
    },
    DocumentSignatureEntrySchema
  >
  postRemindDocumentSignature: PostRoute<
    ['signable_document_id'],
    {},
    DocumentSignatureEntrySchema
  >
  postDocumentConvert: PostRoute<['document_id'], {}, {}>
  deleteDocumentSignature: DeleteRoute<
    ['signable_document_id'],
    { delete_attachment: boolean },
    {}
  >

  // Document template
  fetchDocumentTemplateAttributes: GetRoute<
    [],
    {},
    { attributes: DocumentTemplateAttributesSchema }
  >
  fetchDocumentTemplate: GetRoute<
    [],
    {
      page: number
      per_page: number
    },
    {
      resources: DocumentTemplateEntrySchema[]
      meta: PaginationDocumentTemplateState
    }
  >
  postDocumentTemplate: PostRoute<
    [],
    {
      title: string
      document: File
    },
    DocumentTemplateEntrySchema
  >
  fillDocumentTemplate: PostRoute<
    ['document_template_id'],
    {
      contract_id: number
      output_name: string
      convert_pdf: boolean
      amendment_id?: number
    }
  >
  updateDocumentTemplate: PatchRoute<
    ['document_template_id'],
    {
      title: string
      document: File
    },
    DocumentTemplateEntrySchema
  >
  deleteDocumentTemplate: DeleteRoute<['document_template_id'], {}>

  // Documents (related to membership)
  fetchDocumentEmployee: GetRoute<
    [],
    {
      membership_id: number
      page: number
      per_page: number
    },
    {
      resources: DocumentEmployeeSchema[]
      meta: PaginationDocumentSignatureState
    }
  >
  postSignatureDocumentEmployee: PostRoute<
    ['document_id'],
    {
      document: {
        data: string
        filename: string
        originalFile?: File
      }
      manually_positioned: boolean
      signatories: SignatoriesFormatType[]
      ordered_signatures?: boolean
      reminder_delay?: number
      read_and_approved?: boolean
      initals?: InitalFormatType
    },
    DocumentEmployeeSchema
  >
  deleteDocumentEmployee: DeleteRoute<['document_id'], {}, {}>
}
