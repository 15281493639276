import type { MembershipContractTimeline } from '@libs/data-access/entities'
import moment from 'moment'

import { isContractInProgressAt } from './isContractInProgressAt'

export const isContractActive = (contract: MembershipContractTimeline) => {
  if (!contract) {
    return null
  }

  return isContractInProgressAt(contract, moment().format('YYYY-MM-DD'))
    ? contract
    : null
}
