import type {
  MembershipRecord,
  MembershipSchema,
  Role
} from '@libs/data-access/entities/lib/entities/User/Membership'

export type RolesType = Record<Role, boolean>

export type RoleRights = {
  exact: RolesType
  atLeast: RolesType
}

const orderedRoles = ['employee', 'manager', 'director', 'owner', 'main_owner']

export const getRoleRights = (
  membership?: Pick<MembershipSchema | MembershipRecord, 'role'>
): RoleRights => {
  const exact = orderedRoles.reduce(
    (acc, current) => ({
      ...acc,
      [current]: current === membership?.role
    }),
    {} as RolesType
  )

  const exactRoleIndex = orderedRoles.indexOf(membership?.role || '')
  const atLeast = orderedRoles.reduce((acc, current, index) => {
    return { ...acc, [current]: index <= exactRoleIndex }
  }, {} as RolesType)

  return {
    exact,
    atLeast
  }
}
