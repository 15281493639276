import type { UserSchema } from '@libs/data-access/entities'
import { queryClientOptions } from '@libs/data-access/queries'
import { useQuery } from '@tanstack/react-query'

import type { ApiErrorResponse, UseAppQueryOptions } from '../../../../../types'
import type { FetchCurrentUserOptions } from '../../user.api'
import { fetchCurrentUser } from '../../user.api'
import { userQueryKeys } from '../../user.cacheKeys'

export const useFetchCurrentUser = <ReturnSchema = UserSchema>(
  request: FetchCurrentUserOptions,
  queryOptions?: UseAppQueryOptions<UserSchema, ApiErrorResponse, ReturnSchema>
) => {
  const defaultParams = {
    queryParams: {
      ...request.queryParams,
      with_permissions: true
    }
  }

  return useQuery<UserSchema, ApiErrorResponse, ReturnSchema>({
    ...queryOptions,
    retry: 2,
    queryKey: userQueryKeys.me(),
    queryFn: () => fetchCurrentUser(defaultParams),
    staleTime: queryOptions?.staleTime || queryClientOptions.time.INFINITY
  })
}
