import classnames from 'classnames'
import type { FC, HTMLProps, PropsWithChildren } from 'react'
import React from 'react'

import styles from './HideWrapper.module.css'

type Props = {
  hide?: boolean
} & Partial<HTMLProps<HTMLDivElement>>

export const HideWrapper: FC<PropsWithChildren<Props>> = ({
  hide,
  className = '',
  children,
  ...other
}) => (
  <div
    className={classnames(styles.Defaults, className, {
      [styles.hide]: hide
    })}
    {...other}
  >
    {children}
  </div>
)
