import { Button, type ButtonProps } from '@libs/ui/ds/lib/components'
import type { FC, PropsWithChildren } from 'react'
import React from 'react'

type Props = PropsWithChildren & ButtonProps

export const ModalFooterDangerButton: FC<Props> = ({ children, ...other }) => {
  return (
    <Button
      {...other}
      intent='danger'
      variant={other?.variant ? other.variant : 'primary'}
      size='m'
    >
      {children}
    </Button>
  )
}
