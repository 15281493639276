import { fork } from 'redux-saga/effects'

import type { CopiedTarget, SelectedTarget } from '../../../reducers'
import type { PayloadActionWithoutType } from '../../types/types'

import bulkDeleteShifts from './bulkDeleteShifts/bulkDeleteShifts'
import createShiftsFromCopiedSelection from './createShiftsFromCopiedSelection'

function* invertShiftsFromCopiedSelection({
  payload: { copied, selected }
}: PayloadActionWithoutType<{
  copied: CopiedTarget
  selected: SelectedTarget
}>) {
  yield fork(() =>
    createShiftsFromCopiedSelection({ payload: { copied, selected } })
  )
  yield fork(() =>
    bulkDeleteShifts({
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      payload: copied.data
    })
  )
}

export default invertShiftsFromCopiedSelection
