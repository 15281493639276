import type { ApiErrorResponse } from '@libs/data-access/queries'
import { put } from 'redux-saga/effects'

import type { ParamsGetDocumentB64 } from '../modules/entities'
import { setDocumentConverted } from '../modules/entities'
import { setMetaError, setMetaWS } from '../modules/meta'

export default function* getDocumentB64Data(response: ParamsGetDocumentB64) {
  try {
    yield put(setMetaWS({ status: response.status }))

    if (response.status === 'success') {
      yield put(
        setDocumentConverted({
          data: response.file_base64
        })
      )
    }
  } catch (err) {
    yield put(setMetaWS({ status: 'failure' }))
    yield put(setMetaError(err as ApiErrorResponse))
  }
}
