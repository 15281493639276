import { scheduleService } from '@libs/data-access/queries'
import { normalize } from 'normalizr'
import { select } from 'redux-saga/effects'
import { getCurrentAccountId } from 'snap-redux/modules/accounts/selectors'

import { Structure } from '../../../models'
import type { PayloadActionWithoutType } from '../../types/types'

type Params = PayloadActionWithoutType<{
  date: string
  locationId: number
  teamId: number
  withPermissions: ['weeklyschedules']
}>

export function* fetchPlanningAggregate({
  payload: { date, locationId, teamId, withPermissions }
}: Params): any {
  const accountId = yield select(getCurrentAccountId)

  const result = yield scheduleService.api.fetchSchedules({
    queryParams: {
      week: date,
      account_id: accountId,
      location_id: locationId,
      with_permissions: withPermissions,
      ...(!!teamId && { team_id: teamId })
    }
  })

  const { entities: normalizedData } = normalize(result, Structure)

  return normalizedData
}
