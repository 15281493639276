import { httpServices } from '../../../apis/http/httpServices'
import type { QueryFnParams } from '../../../types'

export type CreateReportFilePayload = { export_type: string }

export type CreateReportFileResponse = {
  job_id: string
}

export type CreateReportFileOptions = QueryFnParams<{
  payload: CreateReportFilePayload
  queryParams: { reportId: number }
}>

/**
 *
 */
export const createReportFile = async (opts?: CreateReportFileOptions) => {
  const response = await httpServices.core.post<CreateReportFileResponse>(
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    `/reports/${opts.queryParams.reportId}/report_files`,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    opts.payload
  )
  return response.data
}
