import { Flex } from '@libs/ui/ds'
import { useCombobox } from '@mantine/core'
import { forwardRef, memo } from 'react'

import { Combobox } from '../../../overlays/Combobox/base/Combobox'

import type { AutocompleteProps } from './Autocomplete.types'
import { AutocompleteCustomTargetWrap } from './subs/AutocompleteCustomTargetWrap'
import { AutocompleteOptionsList } from './subs/AutocompleteOptionsList'
import { AutocompleteSearchInput } from './subs/AutocompleteSearchInput'
import { AutocompleteTargetButton } from './subs/targets/AutocompleteTargetButton'
import { AutocompleteTargetInputLike } from './subs/targets/AutocompleteTargetInputLike'
import { filterAutocompleteOption } from './utils/filterAutocompleteOption'
import { useAutocompleteValuesUncontrolled } from './utils/useAutocompleteValuesUncontrolled'

const BaseAutocomplete = memo(
  forwardRef<HTMLElement, AutocompleteProps>(
    (
      {
        className,
        disabled,
        name,
        placeholder,

        /**
         *
         */
        onSearchChange,
        searchValue,
        searchAdapter = 'startingWith',

        /**
         *
         */
        isMulti = false,
        options,
        onChange,
        defaultValue,
        value,
        loading,
        dropdownPosition = 'bottom-start',
        closeOnOptionClick,
        dropdownHeight = 'm',
        dropdownWidth = 'm',
        'data-testid': dataTestId,
        customTarget,
        ...other
      },
      forwardedRef
    ) => {
      const store = useCombobox()

      const { select, search, selectedOptions } =
        useAutocompleteValuesUncontrolled({
          select: { onChange, defaultValue, value },
          search: { onSearchChange, searchValue },
          options,
          isMulti
        })

      const filteredOptions = filterAutocompleteOption({
        options,
        searchAdapter,
        searchValue: search.searchValue
      })

      return (
        <Combobox
          {...other}
          closeOnOptionClick={closeOnOptionClick ?? !isMulti}
          store={store}
          loading={loading}
          dropdownPosition={dropdownPosition}
          dropdownHeight={dropdownHeight}
          dropdownWidth={dropdownWidth}
          onClose={() => search.resetSearchValue()}
          onOptionSubmit={val => {
            if (!val) {
              return null
            }
            select.toggleSelectValue(val)
          }}
        >
          <Combobox.Target>
            <AutocompleteCustomTargetWrap
              ref={forwardedRef}
              className={className}
              disabled={disabled}
              name={name}
              placeholder={placeholder}
              store={store}
              selectedOptions={selectedOptions}
              customTarget={customTarget}
              data-testid={dataTestId}
            />
          </Combobox.Target>
          <Combobox.Dropdown>
            <Flex className='gap-3'>
              <AutocompleteSearchInput
                search={search}
                dropdownOpened={store.dropdownOpened}
              />
              <AutocompleteOptionsList
                options={filteredOptions}
                selectedOptions={selectedOptions}
                searchValue={search.searchValue}
              />
            </Flex>
          </Combobox.Dropdown>
        </Combobox>
      )
    }
  )
)

type CompoundAutocompleteType = {
  Targets: {
    InputLike: typeof AutocompleteTargetInputLike
    Button: typeof AutocompleteTargetButton
  }
}

const TypedAutocomplete = BaseAutocomplete as typeof BaseAutocomplete &
  CompoundAutocompleteType

TypedAutocomplete.Targets = {
  InputLike: AutocompleteTargetInputLike,
  Button: AutocompleteTargetButton
}

TypedAutocomplete.displayName = 'Autocomplete'
export const Autocomplete = TypedAutocomplete

export type {
  AutocompleteProps,
  AutocompleteOptions
} from './Autocomplete.types'
