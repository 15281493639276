import type { ApiErrorResponse } from '@libs/data-access/queries'
import { notifications } from '@libs/ui/ds'
import i18n from '@libs/utils/translations'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import type { ParamsReminderDocument } from '../modules/entities'
import { setMetaLoading, setMetaSuccess, setMetaError } from '../modules/meta'

export default function* postRemindDocumentSignatureData(
  queryParams: ParamsReminderDocument
) {
  yield put(setMetaLoading())

  try {
    const promise = apiRequest({
      service: 'core',
      route: 'postRemindDocumentSignature',
      params: queryParams
    })

    notifications.promise(promise, {
      loading: {
        message: i18n.t<any>('common.notifications:documentSign.remind.loading')
      },
      success: {
        message: i18n.t<any>('common.notifications:documentSign.remind.success')
      },
      apiError: true
    })

    yield promise
    yield put(setMetaSuccess())
  } catch (err) {
    yield put(setMetaError(err as ApiErrorResponse))
  }
}
