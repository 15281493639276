import { Button, type ButtonProps } from '@libs/ui/ds/lib/components'
import { useDSTranslation } from '@libs/ui/ds/lib/internals'
import type { FC, MouseEvent } from 'react'

import { useModalContext } from '../../../Modal.context'

type Props = ButtonProps

export const ModalFooterCancelButton: FC<Props> = ({
  children,
  onClick,
  ...other
}) => {
  const { t } = useDSTranslation()
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  const { onClose, disableClose } = useModalContext()

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (disableClose) {
      return
    }

    if (onClick) {
      return onClick(e)
    }

    return onClose(e)
  }

  return (
    <Button
      {...other}
      onClick={handleClick}
      intent='neutral-dark'
      variant='tertiary'
      size='m'
    >
      {children || t('ds.components.modal.footer.cancel')}
    </Button>
  )
}
