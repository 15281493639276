import type { ReduxState } from '@spa/redux/ReduxState'
import { useSelector } from 'react-redux'

import { deepCustomEquals } from '../helpers'

/**
 * Abstract redux selector using custom memoization fn
 * We use differents types of comparison to check if we should render the selectors
 */
const useMemoSelector = <T extends any>(
  selectorFn: (state: ReduxState) => T
) => {
  return useSelector(selectorFn, (a, b) => {
    return deepCustomEquals(a, b)
  })
}

export default useMemoSelector
