import type { AccountDetails } from '@libs/data-access/entities'
import { groupBy, keyBy } from 'lodash-es'

import type { AccountDetailsDerivedState } from './AccountDetailsDerivedState.type'

export const computeAccountDetailsDerivedState = (
  data: AccountDetails
): AccountDetailsDerivedState => {
  const locationMapByGroupId = groupBy(
    data.locations_mapping || [],
    'location_group_id'
  )
  const locationMapByDraftGroupId = groupBy(
    data.locations_mapping || [],
    'draft_location_group_id'
  )

  const locationGroupById = keyBy(data.location_groups || [], 'id')
  const locationList = (data.locations_mapping || []).map(location => ({
    ...location,
    locationGroup: locationGroupById[location.location_group_id],
    draft: locationGroupById[location.draft_location_group_id || '']
  }))

  const locationGroupList = (data.location_groups || []).map(locationGroup => ({
    ...locationGroup,
    locationsMap:
      (locationGroup.is_draft
        ? locationMapByDraftGroupId[locationGroup.id]
        : locationMapByGroupId[locationGroup.id]) || []
  }))

  const locationGroupListGrouped = groupBy(locationGroupList, 'is_draft')
  const locationMapGrouped = groupBy(locationList || [], locationMap =>
    Boolean(locationMap.draft_location_group_id)
  )

  return {
    locationGroups: {
      byId: keyBy(locationGroupList, 'id'),
      list: locationGroupList,
      draftList: locationGroupListGrouped.true || [],
      regularList: locationGroupListGrouped.false || []
    },
    locations: {
      draftList: locationMapGrouped.true || [],
      regularList: locationMapGrouped.false || [],
      byLocationGroupId: groupBy(locationList, 'location_group_id'),
      byId: keyBy(locationList, 'id')
    }
  }
}
