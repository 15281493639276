import { errorLoggerService } from '@libs/utils/error-monitoring'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import { setAddons } from '../modules/entities'
import { setMetaError, setMetaLoading, setMetaSuccess } from '../modules/meta'

export default function* fetchAddonsData() {
  yield put(setMetaLoading())

  try {
    const result = yield apiRequest({
      service: 'billing',
      route: 'addons'
    })

    yield put(setAddons({ ...result }))
    yield put(setMetaSuccess())
  } catch (err) {
    errorLoggerService.apiError(err)
    yield put(setMetaError())
  }
}
