import { useUncontrolledInput } from '@libs/ui/ds'
import { cva } from 'class-variance-authority'
import { forwardRef, memo } from 'react'

import { InputWrap } from '../../../forms/InputWrap/base/InputWrap'
import { formatMaxLengthCounter } from '../../../forms/InputWrap/utils/formatMaxLengthCounter'
import { inputBaseCVA } from '../../InputBase/base/InputBase.classes'

import type { TextAreaProps } from './TextArea.types'

const textAreaCVA = {
  textarea: cva(['min-h-24 w-full py-2 text-s text-neutral-700 outline-none'], {
    variants: {
      disableResize: {
        true: 'resize-none',
        false: 'resize-y'
      }
    }
  })
}

const BaseTextArea = memo(
  forwardRef<HTMLTextAreaElement, TextAreaProps>(
    (
      {
        label,
        desc,
        errorMessage,
        helperText,
        required,
        disabled = false,
        fillWidth,
        className,
        name,
        placeholder,
        maxLength,
        labelRightSection,
        disableResize = false,
        value,
        onChange,
        ...other
      },
      ref
    ) => {
      const [controlledValue, setControlledValue] = useUncontrolledInput<
        string,
        HTMLTextAreaElement
      >({ value, onChange })

      return (
        <InputWrap
          label={label}
          desc={desc}
          errorMessage={errorMessage}
          helperText={
            formatMaxLengthCounter({ value: controlledValue, maxLength }) ||
            helperText
          }
          required={required}
          labelRightSection={labelRightSection}
          disabled={disabled}
          htmlFor={name}
          fillWidth={fillWidth}
          className={className}
          field={
            <textarea
              ref={ref}
              className={inputBaseCVA.root({
                size: 'none',
                disabled,
                error: Boolean(errorMessage),
                className: textAreaCVA.textarea({
                  className,
                  disableResize
                })
              })}
              // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
              rows={null}
              aria-label={label as string}
              aria-placeholder={placeholder}
              placeholder={placeholder}
              disabled={disabled}
              name={name}
              maxLength={maxLength}
              aria-required={required}
              required={required}
              value={controlledValue}
              onChange={setControlledValue}
              {...other}
            />
          }
        />
      )
    }
  )
)

BaseTextArea.displayName = 'TextArea'
export const TextArea = BaseTextArea
export type { TextAreaProps } from './TextArea.types'
