import { ArrowRightFullIcon } from '@libs/ui/ds/assets/icons'
import type { FC } from 'react'
import React from 'react'

import { IconWrap } from '../../../dataDisplay/IconWrap/base/IconWrap'

export const CalendarRangeArrow: FC = () => {
  return (
    <IconWrap
      size='s'
      icon={<ArrowRightFullIcon />}
      className='text-neutral-600'
    />
  )
}
