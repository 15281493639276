import { useAuthState } from '@spa/scenes/Auth/shared/services'
import { memo } from 'react'

import { AuthenticatedCustomerCareMenu } from './CustomerCareMenu/AuthenticatedCustomerCareMenu/AuthenticatedCustomerCareMenu'
import { UnauthenticatedCustomerCareMenu } from './CustomerCareMenu/UnauthenticatedCustomerCareMenu/UnauthenticatedCustomerCareMenu'

export const CustomerCareMenu = memo(() => {
  const authState = useAuthState()

  return authState.isAuthenticated ? (
    <AuthenticatedCustomerCareMenu />
  ) : (
    <UnauthenticatedCustomerCareMenu />
  )
})
