import type {
  DocumentEmployeeSchema,
  InitalFormatType,
  NormalizeEntity,
  SignatoriesFormatType
} from '@libs/data-access/entities'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { DocumentEmployeeModel } from '../models/DocumentEmployeeModel'

export type ParamsDeleteDocumentEmployee = {
  document_id: number
  signable_id?: number
}

export type ParamsFetchDocumentEmployee = {
  membership_id: number
  page: number
  per_page: number
}

export type ParamsPostSignatureDocumentEmployee = {
  document_id: number
  document: {
    data: string
    filename: string
    originalFile?: File
  }
  manually_positioned: boolean
  signatories: SignatoriesFormatType[]
  ordered_signatures?: boolean
  reminder_delay?: number
  read_and_approved?: boolean
  initals?: InitalFormatType
}

const initialState: DocumentEmployeeModel = []

export type EntitiesState = {
  documentEmployee: NormalizeEntity<DocumentEmployeeModel>
}

const entitiesSlice = createSlice({
  name: 'emp/documentEmployee/entities',
  initialState,
  reducers: {
    replaceDocumentEmployee(_, action: PayloadAction<DocumentEmployeeModel>) {
      return [...action.payload]
    },

    setDocumentEmployee(state, action: PayloadAction<DocumentEmployeeModel>) {
      return [...action.payload, ...state]
    },

    updateDocumentEmployee(
      state,
      action: PayloadAction<DocumentEmployeeSchema>
    ) {
      const documentsEmployee = state
      const itemIndex = documentsEmployee.findIndex(
        document => document.id === action.payload.id
      )
      if (!state[itemIndex]) {
        state[itemIndex] = {
          account_id: 0,
          created_at: '',
          document_content_type: '',
          document_url: '',
          id: 0,
          membership_id: 0,
          original_filename: '',
          owner_name: '',
          requester: undefined,
          signable_document: undefined,
          title: ''
        }
      }

      state.splice(itemIndex, 1, action.payload)
    },

    deleteDocumentEmployee(state, action: PayloadAction<{ id: number }>) {
      const documentsEmployee = state
      const itemIndex = documentsEmployee.findIndex(
        document => document.id === action.payload.id
      )

      if (itemIndex !== -1) {
        state.splice(itemIndex, 1)
      }
    },

    deleteSignatureFromDocumentEmployee(
      state,
      action: PayloadAction<{ id: number }>
    ) {
      const documentsEmployee = state
      const itemIndex = documentsEmployee.findIndex(
        document => document.id === action.payload.id
      )

      if (itemIndex !== -1) {
        const item = {
          ...documentsEmployee[itemIndex],
          signable_document: undefined
        }

        state.splice(itemIndex, 1, item)
      }
    }
  }
})

export const {
  setDocumentEmployee,
  replaceDocumentEmployee,
  updateDocumentEmployee,
  deleteDocumentEmployee,
  deleteSignatureFromDocumentEmployee
} = entitiesSlice.actions
export default entitiesSlice.reducer
