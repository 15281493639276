import { useMutation } from '@tanstack/react-query'
import type {
  ApiErrorResponse,
  UseAppMutationOptions
} from 'libs/data-access/queries/src/lib/types'

import { invalidateAccountBillingInfoCache } from '../../../../AccountBillingInfo/accountBillingInfo.cacheKeys'
import { invalidateAccountDetailsCache } from '../../../../AccountDetails/accountDetails.cacheKeys'
import type { SetAccountCardOptions } from '../setAccountCard.api'
import { setAccountCard } from '../setAccountCard.api'
import { accountCardSetup } from '../setAccountCard.cacheKeys'

export const useSetAccountCard = (
  mutationOptions?: UseAppMutationOptions<
    void,
    ApiErrorResponse,
    SetAccountCardOptions
  >
) => {
  return useMutation<void, ApiErrorResponse, SetAccountCardOptions>({
    ...mutationOptions,
    mutationKey: accountCardSetup.one(),
    mutationFn: (requestOptions: SetAccountCardOptions) =>
      setAccountCard(requestOptions),
    onSuccess: (...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(...args)
      }

      invalidateAccountDetailsCache()
      invalidateAccountBillingInfoCache()
    }
  })
}
