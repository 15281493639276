import {
  type PostOauthResponse,
  type PostImpersonateUserResponse,
  authService
} from '@libs/data-access/queries'
import { useNavigate } from 'react-router-dom'

import { getSubDomainAccountSlug, initAuth } from '../authServiceUtils'

import { useAuthState } from './useAuthState'

const mergeImpersonateAuth = (
  authResponse: PostOauthResponse,
  impersonateResponse: PostImpersonateUserResponse
) => {
  return {
    ...authResponse,
    user_id: impersonateResponse.user_id,
    impersonated_membership_id: impersonateResponse.membership_id,
    info: {
      ...authResponse.info,
      account_ids: [impersonateResponse.account_id],
      membership_ids: [impersonateResponse.membership_id]
    }
  }
}

export const useImpersonateUserOnSignIn = () => {
  const navigate = useNavigate()
  const postImpersonateUser = authService.usePostImpersonateUser()
  const { updateAuthState } = useAuthState()

  return async (authResponse: PostOauthResponse, redirectTo?: string) => {
    const impersonateResponse = await postImpersonateUser.mutateAsync({
      pathParams: {
        account_slug_or_id: getSubDomainAccountSlug()
      },
      config: {
        super_user_account_id: authResponse.info.account_ids[0],
        impersonate_membership_id: authResponse.info.membership_ids[0]
      }
    })

    const mergedAuth = mergeImpersonateAuth(authResponse, impersonateResponse)

    initAuth({
      authResponse: mergedAuth,
      navigate,
      updateAuthState,
      redirectTo
    })
  }
}
