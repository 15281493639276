export const UploadButtonIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0016 6.1233C10.4525 6.1233 10.818 6.48879 10.818 6.93963V19.1845C10.818 19.6354 10.4525 20.0009 10.0016 20.0009C9.55079 20.0009 9.1853 19.6354 9.1853 19.1845V6.93963C9.1853 6.48879 9.55079 6.1233 10.0016 6.1233Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.4244 6.3624C9.74319 6.0436 10.2601 6.0436 10.5789 6.3624L14.2523 10.0359C14.5711 10.3547 14.5711 10.8715 14.2523 11.1903C13.9335 11.5091 13.4167 11.5091 13.0979 11.1903L10.0016 8.09409L6.90539 11.1903C6.58659 11.5091 6.06973 11.5091 5.75093 11.1903C5.43213 10.8715 5.43213 10.3547 5.75093 10.0359L9.4244 6.3624Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.10204 1.63351C4.66903 1.63351 4.25376 1.80552 3.94758 2.1117C3.6414 2.41788 3.46938 2.83315 3.46938 3.26616V13.6735C3.46938 14.1065 3.6414 14.5218 3.94758 14.828C4.25376 15.1342 4.66903 15.3062 5.10204 15.3062H6.9404C7.39125 15.3062 7.75673 15.6716 7.75673 16.1225C7.75673 16.5733 7.39125 16.9388 6.9404 16.9388H5.10204C4.23602 16.9388 3.40548 16.5948 2.79312 15.9824C2.18075 15.3701 1.83673 14.5395 1.83673 13.6735V3.26616C1.83673 2.40015 2.18075 1.5696 2.79312 0.957241C3.40548 0.344877 4.23602 0.000854492 5.10204 0.000854492H14.898C15.764 0.000854492 16.5945 0.344877 17.2069 0.957241C17.8192 1.5696 18.1633 2.40015 18.1633 3.26616V13.6735C18.1633 14.5395 17.8192 15.3701 17.2069 15.9824C16.5945 16.5948 15.764 16.9388 14.898 16.9388H13.0612C12.6104 16.9388 12.2449 16.5733 12.2449 16.1225C12.2449 15.6716 12.6104 15.3062 13.0612 15.3062H14.898C15.331 15.3062 15.7462 15.1342 16.0524 14.828C16.3586 14.5218 16.5306 14.1065 16.5306 13.6735V3.26616C16.5306 2.83315 16.3586 2.41788 16.0524 2.1117C15.7462 1.80552 15.331 1.63351 14.898 1.63351H5.10204Z'
        fill='currentColor'
      />
    </svg>
  )
}
