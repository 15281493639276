import React from 'react'
import {
  Controller,
  type FieldPath,
  type FieldValues,
  useFormContext
} from 'react-hook-form'

import type { ControllerBaseProps } from './ControllerBase.types'

export const ControllerBase = <
  TFieldValues extends FieldValues = FieldValues,
  TInputName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  field,
  onChangeAdapter,
  valueAdapter = 'value',
  onChangeSideEffect,
  ...rest
}: ControllerBaseProps<TFieldValues, TInputName>) => {
  const connectToClosestContext = useFormContext<TFieldValues>()
  // only read the context if no control is passed
  const currentControl = control || connectToClosestContext?.control

  return (
    <Controller
      control={currentControl}
      render={({ field: { onChange, value, name, ref, onBlur } }) => {
        return (
          <>
            {React.cloneElement(field as React.ReactElement<any>, {
              onBlur,
              onChange: e => {
                if (onChangeSideEffect) {
                  onChangeSideEffect(e)
                }
                onChange(onChangeAdapter(e))
              },
              [valueAdapter]: value,
              name,
              ref,
              ...(connectToClosestContext?.formState?.errors?.[rest?.name]
                ?.message && {
                errorMessage:
                  connectToClosestContext?.formState?.errors?.[rest.name]
                    ?.message
              })
            })}
          </>
        )
      }}
      {...rest}
    />
  )
}
