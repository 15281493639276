import { loadEnvs } from './loadEnvs'

const loadedEnvs = loadEnvs()

const {
  CLOUD_SPA_ENABLED_FOR,
  CLOUD_BUCKET_URL,
  FARO_URL,
  FARO_API_KEY,
  FARO_ENV,
  API_APPLICATION_HOST,
  APP_ENV,
  CLIENT_APP_NAME,
  APP_NAMESPACE,
  APPLICATION_VERSION,
  AUTH_BEFORE_TOKEN_EXPIRES,

  AUTH_TOKEN_CHECK_INTERVAL,
  AUTH_TOKEN_DURATION,
  BUGSNAG_API_SERVER_KEY,
  BUGSNAG_REACT_API_KEY,
  BUGSNAG_RELEASE_STAGE,
  COMMIT_HASH,
  COOKIE_SALT,
  COOKIE_SECRET,
  COOKIE_DOMAIN,
  DEFAULT_LANGUAGE,
  DELETE_TC_PHOTOS_DAYS,
  DOMAIN,
  ENABLE_SIGNUP_FLOW,
  FAP_HOMEPAGE_URL,
  FORMATION_URL,
  FORMATION_ES_URL,
  FORMATION_EN_URL,
  GOOGLE_MAPS_EMBED_KEY,
  GOOGLE_RECAPTCHA_ACTIVATED,
  GOOGLE_RECAPTCHA_KEY,
  GOOGLE_RECAPTCHA_MIN_SCORE,
  GOOGLE_RECAPTCHA_SECRET,
  GTM_APP_ID,
  GUIDE_URL,
  HEROKU_APP_NAME,
  HTTP_PROTOCOL,
  I18N_DEBUG_ENABLED,
  INTERCOM_API_SECRET,
  INTERCOM_APP_ID,
  LOG_LEVEL = 'tiny',
  MAZE_ID,
  PHRASE_APP_IN_CONTEXT_ENABLED,
  PHRASE_APP_PROJECT_ID,
  REDUX_LOGGER,
  REQUEST_TIMEOUT,
  SEGMENT_WRITE_KEY,
  SESSION_TOKEN_DURATION_IN_DAYS,
  SNAPSHIFT_CLIENT_ID,
  SNAPSHIFT_CLIENT_SECRET,
  STRIPE_PUBLISHABLE_KEY,
  UPSCOPE_KEY,
  WEB_MAINTENANCE_BANNER_MESSAGE,
  WS_PROTOCOL,
  PORT,
  NODE_ENV
} = loadedEnvs

/**
 * Current environment
 */
const IS_PROD_ENV = APP_ENV === 'production'
const IS_DEV_ENV = APP_ENV === 'development'
const IS_TEST_ENV = NODE_ENV === 'test'

const NUMBER_OF_DAYS_BEFORE_DELETION =
  DELETE_TC_PHOTOS_DAYS !== undefined ? Number(DELETE_TC_PHOTOS_DAYS) : 30

const IS_ENABLE_SIGNUP_FLOW = ENABLE_SIGNUP_FLOW === 'true'
/**
 * Dev tools
 */
const IS_I18N_DEBUG_ENABLED = I18N_DEBUG_ENABLED === 'true'
const IS_REDUX_LOGGER_ENABLED =
  !IS_TEST_ENV && IS_DEV_ENV && REDUX_LOGGER === 'true'

/**
 * 3rd party services
 */
const IS_MAINTENANCE_BANNER_ENABLED = Boolean(WEB_MAINTENANCE_BANNER_MESSAGE)
const IS_BUGSNAG_AVAILABLE =
  !IS_TEST_ENV &&
  Boolean(BUGSNAG_API_SERVER_KEY) &&
  Boolean(BUGSNAG_RELEASE_STAGE) &&
  Boolean(BUGSNAG_REACT_API_KEY)
const IS_SEGMENT_AVAILABLE = Boolean(SEGMENT_WRITE_KEY)
const IS_UPSCOPE_AVAILABLE = Boolean(UPSCOPE_KEY)
const IS_PHRASE_APP_IN_CONTEXT_ENABLED =
  PHRASE_APP_IN_CONTEXT_ENABLED === 'true' && Boolean(PHRASE_APP_PROJECT_ID)
const IS_INTERCOM_AVAILABLE =
  Boolean(INTERCOM_API_SECRET) && Boolean(INTERCOM_APP_ID)
const IS_GOOGLE_RECAPTCHA_AVAILABLE =
  GOOGLE_RECAPTCHA_ACTIVATED === 'true' &&
  Boolean(GOOGLE_RECAPTCHA_SECRET) &&
  Boolean(GOOGLE_RECAPTCHA_MIN_SCORE) &&
  Boolean(GOOGLE_RECAPTCHA_KEY)
const IS_GOOGLE_TAG_MANAGER_AVAILABLE = Boolean(GTM_APP_ID)

/**
 * Marketing service
 */

const MARKET_APPLICATION_URL = 'https://combohr.com/'

export {
  /** Load SPA from GCS for select account IDs */
  CLOUD_SPA_ENABLED_FOR,
  CLOUD_BUCKET_URL,

  /** Faro */
  FARO_URL,
  FARO_API_KEY,
  FARO_ENV,

  /** Current env */
  IS_PROD_ENV,
  IS_DEV_ENV,
  IS_TEST_ENV,

  /** Domain & API */
  NUMBER_OF_DAYS_BEFORE_DELETION,
  IS_ENABLE_SIGNUP_FLOW,

  /** Dev Tools */
  IS_I18N_DEBUG_ENABLED,
  IS_REDUX_LOGGER_ENABLED,

  /** 3rd party */
  IS_MAINTENANCE_BANNER_ENABLED,
  IS_BUGSNAG_AVAILABLE,
  IS_SEGMENT_AVAILABLE,
  IS_UPSCOPE_AVAILABLE,
  IS_PHRASE_APP_IN_CONTEXT_ENABLED,
  IS_INTERCOM_AVAILABLE,
  IS_GOOGLE_RECAPTCHA_AVAILABLE,
  IS_GOOGLE_TAG_MANAGER_AVAILABLE,
  GOOGLE_RECAPTCHA_SECRET,

  /** Auth */
  SESSION_TOKEN_DURATION_IN_DAYS,
  SNAPSHIFT_CLIENT_ID,
  SNAPSHIFT_CLIENT_SECRET,

  /** */
  API_APPLICATION_HOST,
  APP_ENV,
  APP_NAMESPACE,
  CLIENT_APP_NAME,
  APPLICATION_VERSION,
  AUTH_BEFORE_TOKEN_EXPIRES,
  AUTH_TOKEN_CHECK_INTERVAL,
  AUTH_TOKEN_DURATION,
  BUGSNAG_API_SERVER_KEY,
  BUGSNAG_REACT_API_KEY,
  BUGSNAG_RELEASE_STAGE,
  COMMIT_HASH,
  COOKIE_SALT,
  COOKIE_SECRET,
  COOKIE_DOMAIN,
  DEFAULT_LANGUAGE,
  DELETE_TC_PHOTOS_DAYS,
  DOMAIN,
  FAP_HOMEPAGE_URL,
  FORMATION_URL,
  FORMATION_ES_URL,
  FORMATION_EN_URL,
  GOOGLE_MAPS_EMBED_KEY,
  GOOGLE_RECAPTCHA_MIN_SCORE,
  GOOGLE_RECAPTCHA_KEY,
  GTM_APP_ID,
  GUIDE_URL,
  HEROKU_APP_NAME,
  HTTP_PROTOCOL,
  I18N_DEBUG_ENABLED,
  INTERCOM_API_SECRET,
  INTERCOM_APP_ID,
  LOG_LEVEL,
  MAZE_ID,
  PHRASE_APP_IN_CONTEXT_ENABLED,
  PHRASE_APP_PROJECT_ID,
  REDUX_LOGGER,
  REQUEST_TIMEOUT,
  SEGMENT_WRITE_KEY,
  STRIPE_PUBLISHABLE_KEY,
  UPSCOPE_KEY,
  WEB_MAINTENANCE_BANNER_MESSAGE,
  WS_PROTOCOL,
  PORT,

  /**
   * Marketing service
   */
  MARKET_APPLICATION_URL
}
