import type { NotificationsRecord } from '@libs/data-access/entities'
import { Badge, DropdownMenu } from '@libs/ui/ds'
import { useAppNavBarTranslation } from '@libs/utils/translations'
import { routes } from '@spa/core/router'
import React from 'react'
import type { FC } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

type NotificationLineProps = {
  count: number
  path: string
  label: string
}

const NotificationLine: FC<NotificationLineProps> = ({
  label,
  count,
  path
}) => {
  const navigate = useNavigate()
  if (count < 1) {
    return null
  }

  return (
    <DropdownMenu.Option
      onClick={() => navigate(path)}
      rightSection={
        <Badge
          variant='plain'
          color='semantic.red'
          size='xs'
          label={String(count)}
        />
      }
    >
      {label}
    </DropdownMenu.Option>
  )
}

type Props = {
  count: number
  notifications: NotificationsRecord
}

export const NotificationsDropdownMenuContent: FC<Props> = ({
  notifications,
  count
}) => {
  const { t } = useAppNavBarTranslation()

  const otherCount = ['family_event'].reduce(
    (acc, type) => acc + (notifications.getIn(['unread_timeoffs', type]) || 0),
    0
  )

  return (
    <DropdownMenu.Dropdown>
      {count < 1 && (
        <DropdownMenu.Option disabled>
          {t('notifications.noRequests')}
        </DropdownMenu.Option>
      )}
      <NotificationLine
        label={t('notifications.unreadMessages')}
        count={notifications.get('unread_conversations') || 0}
        path={generatePath(routes.conversations.rootPath)}
      />
      <NotificationLine
        label={t('notifications.paidLeaveRequests')}
        count={notifications.getIn(['unread_timeoffs', 'paid_leave']) || 0}
        path={generatePath(routes.timeoffs.rootPath)}
      />
      <NotificationLine
        label={t('notifications.unpaidLeaveRequests')}
        count={notifications.getIn(['unread_timeoffs', 'unpaid_leave']) || 0}
        path={generatePath(routes.timeoffs.rootPath)}
      />
      <NotificationLine
        label={t('notifications.unavailabilityRequests')}
        count={
          notifications.getIn([
            'unread_timeoffs',
            'temporary_unavailability'
          ]) || 0
        }
        path={generatePath(routes.timeoffs.rootPath)}
      />
      <NotificationLine
        label={t('notifications.rttRequests')}
        count={notifications.getIn(['unread_timeoffs', 'rtt']) || 0}
        path={generatePath(routes.timeoffs.rootPath)}
      />
      <NotificationLine
        label={t('notifications.otherLeaveRequests')}
        count={otherCount}
        path={generatePath(routes.timeoffs.rootPath)}
      />
    </DropdownMenu.Dropdown>
  )
}
