import { httpServices } from '../../../apis'
import type { QueryFnParams } from '../../../types'

export type BankPaymentLocationBasedOptions = QueryFnParams<{
  queryParams: {
    location_group_id: string
  }
  payload: {
    redirect_url: string
  }
}>

export const bankPaymentLocationBased = async (
  options: BankPaymentLocationBasedOptions
) => {
  const response = await httpServices.billing.post(
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    `/location-based/location-groups/${options.queryParams.location_group_id}/payment/direct-debit`,
    {
      ...options.payload,
      snap_app_account_id: httpServices.authInfo.accountId
    }
  )

  return response.data
}

export type CompleteBankPaymentLocationBasedOptions = QueryFnParams<{
  queryParams: {
    location_group_id: string
  }
  payload: {
    redirect_flow_id: string
  }
}>

/**
 * @description Complete the flow for bank payment with Gocardless
 */
export const completeBankPaymentLocationBased = async (
  options: CompleteBankPaymentLocationBasedOptions
) => {
  const response = await httpServices.billing.post(
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    `/location-based/location-groups/${options.queryParams.location_group_id}/payment/direct-debit/complete`,
    {
      ...options.payload,
      snap_app_account_id: httpServices.authInfo.accountId
    }
  )

  return response.data
}
