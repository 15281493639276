import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { PaidLeaveLedgerModel } from '../models/PaidLeaveLedgerModel'

type AdjustementsSchema = {
  acquired_target_value_in_days: number
  paid_leave_acquisition_period_id: number
  parent_id: number
  user_contract_id: number
  taken_target_value_in_days?: number
}

export type ParamsPostBulkPaidLeaveLedgerOps = {
  locationId: number
  adjustements: AdjustementsSchema[]
  successCallback?: () => void
  errorCallback?: () => void
}

export type ParamsFetchPaidLeaveLedgerOps = {
  locationId: number
  paid_leave_acquisition_period_ids: any[]
  user_contract_ids: []
  only_total?: boolean
  page?: number
  perPage?: number
}

const initialState: PaidLeaveLedgerModel = []

const entitiesSlice = createSlice({
  name: 'emp/paidLeavesLedger/entities',
  initialState,
  reducers: {
    setPaidLeaveLegder(_, action: PayloadAction<PaidLeaveLedgerModel>) {
      return [...action.payload]
    }
  }
})

export const { setPaidLeaveLegder } = entitiesSlice.actions
export default entitiesSlice.reducer
