import { groupBy } from 'lodash-es'
import moment from 'moment'
import type { Alert } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import { alertTypeScopes } from '../constants/alertTypeScopes'

export const computeDayAlertsMap = (contractAlerts: Alert, date: string) => {
  const dayAlertsList = computeDayAlertsList(contractAlerts, date)
  return groupBy(dayAlertsList, 'date')
}

export const computeDayAlertsList = (contractAlerts: Alert, date: string) => {
  const startWeek = moment(date).startOf('isoWeek')
  const endWeek = startWeek.clone().endOf('isoWeek')

  return contractAlerts.alerts.filter(
    i =>
      alertTypeScopes.day.includes(i.type) &&
      moment(i.date).isBetween(startWeek, endWeek, null, '[]')
  )
}

export const computeWeekAlertsList = (contractAlerts: Alert) => {
  return contractAlerts.alerts.filter(i =>
    alertTypeScopes.week.includes(i.type as any)
  )
}
