import { useMutation } from '@tanstack/react-query'

import type { ApiErrorResponse, UseAppMutationOptions } from '../../../types'
import type { GetSignupCheckExistingEmailOptions } from '../auth.api'
import { getSignupCheckExistingEmail } from '../auth.api'
import { authCacheKeys } from '../auth.cacheKeys'
import type { GetSignupCheckExistingEmailResponse } from '../types/CheckExistingEmail.post'

export const useGetSignupCheckExistingEmail = (
  mutationOptions?: UseAppMutationOptions<
    GetSignupCheckExistingEmailResponse,
    ApiErrorResponse,
    GetSignupCheckExistingEmailOptions
  >
) => {
  return useMutation({
    ...mutationOptions,
    mutationKey: authCacheKeys.getSignupCheckExistingEmail(),
    mutationFn: (opts: GetSignupCheckExistingEmailOptions) =>
      getSignupCheckExistingEmail(opts),

    onSuccess: (data, variables, ...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, ...args)
      }
    }
  })
}
