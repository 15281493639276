import type { ReduxState } from '@spa/redux/ReduxState'
import moment from 'moment'
import createCachedSelector from 're-reselect'

import { getDate } from '../../params'
import { ONE_HOUR_IN_MINUTES } from '../constants'

type IntervalOptions = { interval: number }

const selectInterval = (_: ReduxState, opts?: IntervalOptions) =>
  opts?.interval || ONE_HOUR_IN_MINUTES

/**
 * Return array of isoDates for each hours between a timeRange
 * Optional interval can be passed as parameters to change the dates intervals
 */
export const getDatesArrayInOneDay = createCachedSelector(
  selectInterval,
  (state: ReduxState) => getDate(state),

  (interval, date): string[] => {
    const datesArr = []
    const resetStartDate = moment(date).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    })

    let currentDate = resetStartDate
    const lastDate = moment(resetStartDate).endOf('day')

    while (currentDate.isBefore(lastDate)) {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      datesArr.push(currentDate.toISOString())
      currentDate = currentDate.add(interval, 'minutes')
    }

    return datesArr
  }
)(selectInterval)
