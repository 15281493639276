import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { MomentInput } from 'moment'
import moment from 'moment'

import {
  addOneDay,
  isFirstDayOfWeek,
  isLastDayOfWeek,
  removeOneDay
} from './utils'

export type TimeRangeState = {
  start: 'YYYY-MM-DDThh:mm:ss' | string
  end: 'YYYY-MM-DDThh:mm:ss' | string
}

const initialState: TimeRangeState = {
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  start: null,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  end: null
}

const timeRange = createSlice({
  name: 'ui/planning/timeRange',
  initialState,
  reducers: {
    setTimeRange(state, action: PayloadAction<Partial<TimeRangeState>>) {
      return { ...state, ...action.payload }
    },

    /**
     *
     */
    setWeekTimeRange(_, { payload }: PayloadAction<string>) {
      return {
        start: moment(payload).startOf('isoWeek').toISOString(),
        end: moment(payload).endOf('isoWeek').toISOString()
      }
    },

    /**
     * Set the timerange for one specific day
     */
    setOneDayTimeRange(_, { payload }: PayloadAction<string>) {
      return {
        start: moment(payload).startOf('day').toISOString(),
        end: moment(payload).endOf('day').toISOString()
      }
    },

    /**
     * Set the timerange for one day and adjacents days
     */
    setDaysTimeRange(_, { payload }: PayloadAction<string>) {
      const start = isFirstDayOfWeek(payload) ? payload : removeOneDay(payload)
      const end = isLastDayOfWeek(payload) ? payload : addOneDay(payload)

      return {
        start: moment(start).startOf('day').toISOString(),
        end: moment(end).endOf('day').toISOString()
      }
    },

    setTimerangeStart(state, { payload }: PayloadAction<MomentInput>) {
      return {
        start: moment(payload).startOf('day').toISOString(),
        end: state.end
      }
    },

    setTimerangeEnd(state, { payload }: PayloadAction<MomentInput>) {
      return {
        start: state.start,
        end: moment(payload).endOf('day').toISOString()
      }
    },
    /**
     *
     */
    setMonthTimeRange(_, { payload }: PayloadAction<string>) {
      return {
        start: moment(payload)
          .startOf('month')
          .startOf('isoWeek')
          .toISOString(),
        end: moment(payload).endOf('month').endOf('isoWeek').toISOString()
      }
    },

    clearTimeRange() {
      return { ...initialState }
    }
  }
})

export const {
  setTimeRange,
  setWeekTimeRange,
  setOneDayTimeRange,
  setDaysTimeRange,
  setMonthTimeRange,
  clearTimeRange,
  setTimerangeStart,
  setTimerangeEnd
} = timeRange.actions
export default timeRange.reducer
