import { AsyncAutocomplete } from '@libs/ui/ds'
import { ControllerBase } from '@libs/ui/form'
import type { FieldValues, FieldPath } from 'react-hook-form'

import type { ControlledAsyncAutocompleteProps } from './ControlledAsyncAutocomplete.types'

export const ControlledAsyncAutocomplete = <
  TFieldValues extends FieldValues = FieldValues,
  TInputName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  onChangeSideEffect,
  rules,
  ...asyncAutocompleteProps
}: ControlledAsyncAutocompleteProps<TFieldValues, TInputName>) => (
  <ControllerBase
    rules={rules}
    control={control}
    field={<AsyncAutocomplete {...asyncAutocompleteProps} />}
    name={name}
    onChangeAdapter={e => e}
    onChangeSideEffect={onChangeSideEffect}
  />
)
