import { findKey } from 'lodash-es'
import type { FC, PropsWithChildren, ReactElement } from 'react'

import { BugsnagErrorBoundary } from './BugsnagErrorBoundary'

// testing error boundary

const errorNamesResolverMap = {
  ChunkLoadError: (error: Error) => {
    const regex = /imported module|importing a module/i

    // Trying to match 'Failed to fetch dynamically imported module' or 'Importing a module script failed.'
    return regex.test(error.message)
  },
  MimeMismatchError: (error: Error) => {
    const regex = /not a valid javascript mime type/i
    // Trying to match "'text/html' is not a valid JavaScript MIME type"
    return regex.test(error.message)
  }
} as const

type Props = {
  defaultFallback: ReactElement
  errorsFallback?: Record<keyof typeof errorNamesResolverMap, ReactElement>
  onError?: (e: any) => void
}

export const ErrorBoundary: FC<PropsWithChildren<Props>> = ({
  defaultFallback,
  children,
  errorsFallback = {},
  onError
}) => {
  return (
    <BugsnagErrorBoundary
      onError={onError}
      FallbackComponent={clearError => {
        const errorName = findKey(errorNamesResolverMap, resolver =>
          resolver(clearError?.error)
        )

        if (errorName && errorsFallback?.[errorName]) {
          return errorsFallback?.[errorName]
        }

        return defaultFallback
      }}
    >
      {children}
    </BugsnagErrorBoundary>
  )
}
