import { Flex, inputFileUtility } from '@libs/ui/ds'
import { useDSTranslation } from '@libs/ui/ds/lib/internals'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import React, { useMemo } from 'react'

import { Text } from '../../../../typography/Text/base/Text'
import type { DropzoneProps } from '../Dropzone.types'

const dropzoneHelperTextCVA = {
  root: cva(['items-center justify-center text-center'], {
    variants: {
      size: {
        s: 'mt-1',
        m: 'mt-3'
      }
    }
  }),
  inner: cva(
    [
      'w-full max-w-xs truncate text-center text-neutral-500',
      'group-data-[accept]/dropzone:text-neutral-700',
      'group-data-[reject]/dropzone:text-semantic-red-400'
    ],
    {
      variants: {
        size: {
          m: 'leading-4',
          s: 'text-xxs leading-3'
        }
      }
    }
  )
}

type DropzoneHelperTextProps = {
  accept?: DropzoneProps['accept']
  maxSize?: DropzoneProps['maxSize']
  size: DropzoneProps['size']
}

export const DropzoneHelperText: FC<DropzoneHelperTextProps> = ({
  accept = [],
  maxSize,
  size
}) => {
  const { t } = useDSTranslation()

  const fileType = useMemo(() => {
    return accept
      .map(mimeType =>
        inputFileUtility.extensionByMimeType[mimeType]?.toUpperCase()
      )
      .filter(Boolean)
      .join(', ')
  }, [accept])

  if (!fileType && !maxSize) {
    return null
  }

  return (
    <Flex className={dropzoneHelperTextCVA.root({ size })}>
      {maxSize && (
        <Text className={dropzoneHelperTextCVA.inner({ size })}>
          {t('ds.components.dropzone.helperText.maxSize', {
            maxSize: inputFileUtility.formatBytesToSize({
              // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
              value: inputFileUtility.convertFileSize(maxSize)
            })
          })}
        </Text>
      )}
      {fileType && (
        <Text className={dropzoneHelperTextCVA.inner({ size })}>
          {t('ds.components.dropzone.helperText.fileType', { fileType })}
        </Text>
      )}
    </Flex>
  )
}
