import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call, select } from 'redux-saga/effects'

import {
  FETCH_PAYROLL_SOFTWARE,
  FETCH_PAYROLL_SOFTWARE_SUCCEEDED,
  FETCH_PAYROLL_SOFTWARE_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'
import { getCurrentAccountId } from '../accounts/selectors'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchPayrollSoftware = () => ({
  type: FETCH_PAYROLL_SOFTWARE
})

// ------------------------------------
// Sagas
// ------------------------------------

const fetchPayrollSoftwareSagaEntity = {
  success: data => ({
    type: FETCH_PAYROLL_SOFTWARE_SUCCEEDED,
    data
  }),
  failure: error => ({
    type: FETCH_PAYROLL_SOFTWARE_FAILED,
    error
  }),

  fetchAPI: (id, options, params = {}) =>
    callAPI(`/payroll_softwares?${queryString.stringify(params)}`, options)
}

export function* doFetchPayrollSoftware() {
  const accountId = yield select(getCurrentAccountId)

  // @ts-ignore migration from js(x) to ts(x)
  yield call(fetchSagaEntity, fetchPayrollSoftwareSagaEntity, null, {
    account_id: accountId
  })
}

// ------------------------------------
// Models
// ------------------------------------

export const PayrollSoftware = Immutable.Record({
  id: null,
  name: null,
  with_configuration: false,
  has_alternate_file: false,
  user_identifier: null,
  uses_constant_value: false
})

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function payrollSoftware(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_PAYROLL_SOFTWARE: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_PAYROLL_SOFTWARE_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_PAYROLL_SOFTWARE_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const payrollSoftware = Immutable.fromJS(action.data)

      return mergeRecords(state, PayrollSoftware, payrollSoftware).mergeDeepIn(
        ['meta'],
        {
          loading: false,
          updated_at: Date.now(),
          success: true
        }
      )
    }
    default:
      return state
  }
}

export default filterActions(payrollSoftware, [
  FETCH_PAYROLL_SOFTWARE,
  FETCH_PAYROLL_SOFTWARE_FAILED,
  FETCH_PAYROLL_SOFTWARE_SUCCEEDED
])
