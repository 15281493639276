import { notifications } from '@libs/ui/ds'
import { shiftsSelectors } from '@spa/scenes/Plannings/redux/selectors'
import { select } from 'redux-saga/effects'
import type { Shift } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import type { PayloadActionWithoutType } from '../../../types/types'
import updateShiftTimeRange from '../updateShiftTimeRange'

import { adaptShiftBreaksSlotOnResize } from './utils/adaptShiftBreaksSlotOnResize'
import * as toastNotifications from './utils/toastNotifications'

type Params = PayloadActionWithoutType<{
  shiftId: number
  newStart: string
  newEnd: string
  notifyUser?: boolean
  errorCallback?: (err: any) => void
}>

function* updateShiftOnResize({ payload }: Params): any {
  const shift: Shift = yield select(state =>
    shiftsSelectors.getCachedById(state, payload.shiftId)
  )

  const { updatedShiftBreaks, deletedShiftBreaks } =
    adaptShiftBreaksSlotOnResize(
      {
        newStart: payload.newStart,
        newEnd: payload.newEnd
      },
      shift
    )

  yield updateShiftTimeRange({
    payload: {
      shiftId: payload.shiftId,
      starts_at: payload.newStart,
      ends_at: payload.newEnd,
      notify_user: payload.notifyUser,
      shift_breaks_attributes: updatedShiftBreaks,
      destroy_shift_breaks_attributes: deletedShiftBreaks,
      errorCallback: err => {
        notifications.errorAdapter(err)
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        payload.errorCallback(err)
      }
    }
  })

  if (updatedShiftBreaks.length || deletedShiftBreaks.length) {
    toastNotifications.breaksHasBeenUpdated({
      updatedShiftBreaksCount: updatedShiftBreaks.length,
      deletedShiftBreaksCount: deletedShiftBreaks.length
    })
  }
}

export default updateShiftOnResize
