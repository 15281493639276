import type { PayloadAction } from '@reduxjs/toolkit'
import { createSliceSaga, SagaType } from 'redux-toolkit-saga'

import { createRestsSaga, updateRestSaga } from '../generators'
import type { CreateRestPayload } from '../generators/createRestsSaga'
import type { UpdateRestPayload } from '../generators/updateRestSaga'

const planningSagaSlice = createSliceSaga({
  name: 'sagas/restModal',
  caseSagas: {
    /**
     * Create one or multiple rest
     */
    *createRests({ payload }: PayloadAction<CreateRestPayload>) {
      yield createRestsSaga(payload)
    },
    /**
     * Update one rest
     */
    *updateRest({ payload }: PayloadAction<UpdateRestPayload>) {
      yield updateRestSaga(payload)
    }
  },
  sagaType: SagaType.Watch
})

export const { createRests, updateRest } = planningSagaSlice.actions

export default planningSagaSlice.saga()
