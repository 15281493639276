import { useMutation } from '@tanstack/react-query'

import type { UseAppMutationOptions, ApiErrorResponse } from '../../../types'
import type { PostImpersonateUserOptions } from '../auth.api'
import { postImpersonateUser } from '../auth.api'
import { authCacheKeys } from '../auth.cacheKeys'
import type { PostImpersonateUserResponse } from '../types/ImpersonateUser.post'

export const usePostImpersonateUser = (
  mutationOptions?: UseAppMutationOptions<
    PostImpersonateUserResponse,
    ApiErrorResponse,
    PostImpersonateUserOptions
  >
) => {
  return useMutation({
    ...mutationOptions,
    mutationKey: authCacheKeys.postImpersonateUser(),
    mutationFn: (opts: PostImpersonateUserOptions) => postImpersonateUser(opts),

    onSuccess: (data, variables, ...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, ...args)
      }
    }
  })
}
