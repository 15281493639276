import { isNumber } from 'lodash-es'

export const computeInnerWidth = (value: number, max: number) => {
  if (!isNumber(value) || !isNumber(max)) {
    return '0%'
  }
  if (value > max) {
    return '100%'
  }
  return `${(value / max) * 100}%`
}
