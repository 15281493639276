import { keys, merge } from 'lodash-es'

import type { Screen, ScreensProp } from '../screens'
import { useScreensMediaQuery } from '../useScreensMediaQuery/useScreensMediaQuery'

export const useMergedScreenProps = <T extends {}>(
  props: T,
  screens?: ScreensProp<T>
) => {
  const screenMatch = useScreensMediaQuery({
    screenList: keys(screens) as Screen[]
  })

  return merge(props, screens?.[screenMatch])
}
