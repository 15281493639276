import {
  CameraStudioIcon,
  DropdownMenu,
  HelpWheelIcon,
  IconButton,
  QuestionHelpCircleBoldIcon,
  Badge,
  Tooltip,
  MessagesBubbleSquareTypingIcon,
  Indicator
} from '@libs/ui/ds'
import { IS_INTERCOM_AVAILABLE } from '@libs/utils/environments'
import { useHelpMenuTranslation } from '@libs/utils/translations'
import { type FC } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useRoleRights } from 'snap-redux/modules/memberships/utils'

import { useUpdateIntercomUserAttribute } from './useUpdateIntercomUserAttribute'

type Props = {
  unreadMessagesCount: number
}

export const AuthenticatedCustomerCareMenuDropdown: FC<Props> = ({
  unreadMessagesCount
}) => {
  useUpdateIntercomUserAttribute()

  const { t } = useHelpMenuTranslation()
  const { atLeast } = useRoleRights()
  const { show } = useIntercom()

  const handleOpenLink = (url: string) => {
    window.open(url, '_blank')
  }

  return (
    <DropdownMenu className='fixed bottom-4 right-4 z-30'>
      <DropdownMenu.Target>
        <Tooltip label={t('helpMenu.tooltip')} position='left'>
          <Indicator
            position='bottom-end'
            label={String(unreadMessagesCount)}
            hide={!unreadMessagesCount}
          >
            <IconButton
              size='l'
              variant='primary'
              intent='neutral-dark'
              icon={<QuestionHelpCircleBoldIcon />}
            />
          </Indicator>
        </Tooltip>
      </DropdownMenu.Target>
      <DropdownMenu.Dropdown>
        {IS_INTERCOM_AVAILABLE && atLeast.manager && (
          <DropdownMenu.Option
            onClick={show}
            leftIcon={<MessagesBubbleSquareTypingIcon />}
            rightSection={
              unreadMessagesCount > 0 ? (
                <Badge
                  label={String(unreadMessagesCount)}
                  color='semantic.red'
                  size='xs'
                />
              ) : null
            }
          >
            {t('helpMenu.intercomOption')}
          </DropdownMenu.Option>
        )}
        <DropdownMenu.Option
          onClick={() => {
            handleOpenLink(t('helpMenu.links.guide'))
          }}
          leftIcon={<HelpWheelIcon />}
        >
          {t('helpMenu.guideOption')}
        </DropdownMenu.Option>
        <DropdownMenu.Option
          onClick={() => {
            handleOpenLink(t('helpMenu.links.formation'))
          }}
          leftIcon={<CameraStudioIcon />}
        >
          {t('helpMenu.formationOption')}
        </DropdownMenu.Option>
      </DropdownMenu.Dropdown>
    </DropdownMenu>
  )
}
