import type { SelectProps } from '../Select.types'

import { selectFilterAdapters } from './selectFilterAdapters'

type Params = {
  options: SelectProps['options']
  searchAdapter: SelectProps['searchAdapter']
  searchValue: string
}

export const filterSelectOptions = ({
  options,
  searchAdapter,
  searchValue
}: Params) => {
  const shouldFilterOptions = options.every(i => i.label !== searchValue)
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  const filterAlgorithm = selectFilterAdapters[searchAdapter]

  if (!shouldFilterOptions) {
    return options
  }

  return options.filter(i =>
    filterAlgorithm({
      search: searchValue,
      options: [i.group, i.label, `${i.group} ${i.label}`]
    })
  )
}
