import React from 'react'
import type { PropsWithChildren, ReactNode, FC } from 'react'
import type { LinkProps } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'

type Props = {
  children: ReactNode
  className?: string
  to: LinkProps['to']
  onClick?(): void
  'data-testid'?: string
  target?: string
}

export const Link: FC<PropsWithChildren<Props>> = ({ children, ...other }) => (
  <RouterLink {...other}>{children}</RouterLink>
)
