import { useUncontrolledInput, useDebouncedValue } from '@libs/ui/ds'
import { useEffect } from 'react'

import type { InputBaseProps } from '../InputBase.types'

export const useDebouncedControlledState = ({
  value,
  onChange,
  debounceDelay,
  onDebouncedChange
}: {
  value: InputBaseProps['value']
  onChange: InputBaseProps['onChange']
  onDebouncedChange: InputBaseProps['onDebouncedChange']
  debounceDelay: number
}) => {
  const [controlledValue, setControlledValue] = useUncontrolledInput({
    value,
    defaultValue: '',
    onChange
  })

  const [debouncedValue] = useDebouncedValue(controlledValue, debounceDelay)

  useEffect(() => {
    if (onDebouncedChange) {
      onDebouncedChange(debouncedValue)
    }
  }, [debouncedValue])

  return [controlledValue, setControlledValue] as const
}
