import { wsServices } from '@libs/data-access/queries'

import type { CreateReportFileOptions } from '../reportFile.api'
import { createReportFile } from '../reportFile.api'

const ReportFileChannelPayload = { channel: 'Report::FileChannel' } as const

type GenerateReportFileSucceeded = {
  message_id: string
  type: 'api/GENERATE_REPORT_FILE_SUCCEEDED'
  export_file_id: number
  data: {
    id: number
    generated_at: string
    requester_id: number
    export_type:
      | 'synthesis'
      | 'rests'
      | 'evp'
      | 'detailed'
      | 'mvp_spain'
      | 'silae'
      | 'quadra_paie'
      | 'quadra_paie_csv'
      | 'sage_evp'
      | 'sage_rests'
      | 'sigma_evp'
      | 'sigma_rests'
      | 'talentia_evp'
      | 'talentia_rests'
      | 'paie_rh'
      | 'inpact_evp'
      | 'inpact_rests'
      | 'payfit'
      | 'adp'
      | 'cegid_rests'
      | 'cegid_evp'
      | 'wingip_evp'
      | 'wingip_rep'
    requester: null
    report_id: number
    document: string
  }
}

const sendWebsocketDownloadRequest = (job_id: string, report_id: number) => {
  wsServices.core.ws.send({
    data: {
      action: 'download',
      message_id: job_id,
      report_id
    },
    identifier: ReportFileChannelPayload
  })
}

const waitForJobCompleted = (
  message_id: string
): Promise<GenerateReportFileSucceeded> => {
  return new Promise(resolve => {
    wsServices.core.ws.onMessage((message: GenerateReportFileSucceeded) => {
      if (message.message_id === message_id) {
        return resolve(message)
      }
    })
  })
}

export const useReportFileSubscription = wsServices.core.subscription.create({
  subscribePayload: () => ReportFileChannelPayload,
  messages: {
    onReportFileSucceeded:
      wsServices.core.subscription.defineMessageHandler<GenerateReportFileSucceeded>(
        { type: 'api/GENERATE_REPORT_FILE_SUCCEEDED' }
      )
  },
  emit: {
    /**
     * Transform websocket emit/receipt message into a Promise
     * So the caller can use async/await to handle the asynchronous flow
     */
    download: async (opts: CreateReportFileOptions) => {
      const { job_id } = await createReportFile(opts)
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      sendWebsocketDownloadRequest(job_id, opts.queryParams.reportId)
      return waitForJobCompleted(job_id)
    }
  }
})
