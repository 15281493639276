import { useMutation } from '@tanstack/react-query'

import type { ApiErrorResponse, UseAppMutationOptions } from '../../../../types'
import {
  type BulkActionOptions,
  bulkValidateShiftsList
} from '../shift.bulk.api'
import { shiftBulkCacheKeys } from '../shift.bulk.cacheKeys'

export const useBulkValidateShifts = (
  mutationOpts?: UseAppMutationOptions<BulkActionOptions>
) => {
  return useMutation<BulkActionOptions, ApiErrorResponse, BulkActionOptions>({
    ...mutationOpts,
    mutationKey: shiftBulkCacheKeys.action.validate(),
    mutationFn: (opts: BulkActionOptions) => bulkValidateShiftsList(opts)
  })
}
