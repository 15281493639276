import { appQueryClient } from '../../../../provider'
import type { FetchInvoice } from '../accountInvoice.api'
import { fetchInvoice } from '../accountInvoice.api'
import { accountInvoicesCacheKeys } from '../accountInvoices.cacheKeys'

export const getAccountInvoice = (requestOptions: FetchInvoice) => {
  return appQueryClient.fetchQuery({
    queryKey: accountInvoicesCacheKeys.getByAccountInvoiceId(requestOptions),
    queryFn: () => fetchInvoice(requestOptions),
    staleTime: 1000
  })
}
