import { Flex } from '@libs/ui/ds'
import { AuthNavbar } from '@spa/scenes/Auth/AuthNavbar/AuthNavbar'
import { Outlet } from 'react-router-dom'

export const AuthCardLayout = () => {
  return (
    <div className='h-screen md:bg-brand-beige-50'>
      <Flex className='md:max-h-20'>
        <AuthNavbar className='md:bg-brand-beige-50' />
      </Flex>
      <Flex className='w-full'>
        <Flex className='md:m-auto'>
          <Flex className='w-screen px-4 py-8 md:my-14 md:max-w-120 md:rounded-lg md:border-1 md:border-solid md:border-neutral-200 md:bg-neutral-50 md:p-10'>
            <Outlet />
          </Flex>
        </Flex>
      </Flex>
    </div>
  )
}
