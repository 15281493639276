import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_PAD_USERS,
  FETCH_PAD_USERS_SUCCEEDED,
  FETCH_PAD_USERS_FAILED,
  CREATE_PAD_USER,
  CREATE_PAD_USER_SUCCEEDED,
  CREATE_PAD_USER_FAILED,
  UPDATE_PAD_USER,
  UPDATE_PAD_USER_SUCCEEDED,
  UPDATE_PAD_USER_FAILED,
  DELETE_PAD_USER,
  DELETE_PAD_USER_SUCCEEDED,
  DELETE_PAD_USER_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import {
  mergeRecords,
  deleteRecord,
  replaceRecord
} from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchPadUsers = (accountId, params, resolve, reject) => ({
  type: FETCH_PAD_USERS,
  accountId,
  params,
  resolve,
  reject
})

export const createPadUser = (padUserData, resolve, reject) => ({
  type: CREATE_PAD_USER,
  padUserData,
  resolve,
  reject
})

export const updatePadUser = (padUserId, padUserData, resolve, reject) => ({
  type: UPDATE_PAD_USER,
  padUserId,
  padUserData,
  resolve,
  reject
})

export const deletePadUser = (padUserId, resolve, reject) => ({
  type: DELETE_PAD_USER,
  padUserId,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const padUsersSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_PAD_USERS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_PAD_USERS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/pad_users?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchPadUsers({ accountId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    padUsersSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

const createPadUserSagaEntity = {
  success: (data, padUserData) => ({
    type: CREATE_PAD_USER_SUCCEEDED,
    data,
    padUserData
  }),
  failure: (error, padUserData) => ({
    type: CREATE_PAD_USER_FAILED,
    error,
    padUserData
  }),

  fetchAPI: (padUserData, options) =>
    callAPI('/pad_users', { method: 'POST', ...options })
}

export function* doCreatePadUser({ padUserData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createPadUserSagaEntity,
    null,
    null,
    padUserData,
    resolve,
    reject
  )
}

const updatePadUserSagaEntity = {
  success: (data, padUserId) => ({
    type: UPDATE_PAD_USER_SUCCEEDED,
    data,
    padUserId
  }),
  failure: (error, padUserId) => ({
    type: UPDATE_PAD_USER_FAILED,
    error,
    padUserId
  }),

  fetchAPI: (padUserId, options) =>
    callAPI(`/pad_users/${padUserId}`, { method: 'PUT', ...options })
}

export function* doUpdatePadUser({ padUserId, padUserData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    updatePadUserSagaEntity,
    padUserId,
    null,
    padUserData,
    resolve,
    reject
  )
}

const deletePadUserSagaEntity = {
  success: (data, padUserId) => ({
    type: DELETE_PAD_USER_SUCCEEDED,
    data,
    padUserId
  }),
  failure: (error, padUserId) => ({
    type: DELETE_PAD_USER_FAILED,
    error,
    padUserId
  }),

  fetchAPI: (padUserId, options) =>
    callAPI(`/pad_users/${padUserId}`, { method: 'DELETE', ...options })
}

export function* doDeletePadUser({ padUserId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    deletePadUserSagaEntity,
    padUserId,
    null,
    null,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const PadUser = Immutable.Record({
  id: null,
  account_id: null,
  device_id: null,
  name: null,
  team_ids: []
})

const padUserForeignKeys = ['account_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function padUsers(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_PAD_USERS: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case CREATE_PAD_USER_FAILED:
    case UPDATE_PAD_USER_FAILED:
    case FETCH_PAD_USERS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_PAD_USERS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const padUsers = Immutable.fromJS(action.data)

      return mergeRecords(
        state,
        PadUser,
        padUsers,
        // @ts-ignore migration from js(x) to ts(x)
        padUserForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    case CREATE_PAD_USER_SUCCEEDED:
    case UPDATE_PAD_USER_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const padUser = Immutable.fromJS(action.data)
      // @ts-ignore migration from js(x) to ts(x)
      return replaceRecord(state, PadUser, padUser, padUserForeignKeys)
    }
    case DELETE_PAD_USER_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { padUserId } = action
      // @ts-ignore migration from js(x) to ts(x)
      return deleteRecord(state, padUserId, padUserForeignKeys)
    }
    default:
      return state
  }
}

export default filterActions(padUsers, [
  FETCH_PAD_USERS,
  FETCH_PAD_USERS_FAILED,
  FETCH_PAD_USERS_SUCCEEDED,
  CREATE_PAD_USER_SUCCEEDED,
  UPDATE_PAD_USER_SUCCEEDED,
  DELETE_PAD_USER_SUCCEEDED
])
