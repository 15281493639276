import Immutable from 'immutable'
/* import queryString from 'query-string' */
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  CREATE_TIMEOFF,
  CREATE_TIMEOFF_SUCCEEDED,
  CREATE_TIMEOFF_FAILED,
  UPDATE_TIMEOFF,
  UPDATE_TIMEOFF_SUCCEEDED,
  UPDATE_TIMEOFF_FAILED,
  DELETE_TIMEOFF,
  DELETE_TIMEOFF_SUCCEEDED,
  DELETE_TIMEOFF_FAILED,
  FETCH_MEMBERSHIP_SUCCEEDED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { updateRecord, deleteRecord } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// TODO: Pass to RQ.

// ------------------------------------
// Actions
// ------------------------------------

export const createTimeoff = (timeoffData, resolve, reject) => ({
  type: CREATE_TIMEOFF,
  timeoffData,
  resolve,
  reject
})
export const updateTimeoff = (timeoffId, timeoffData, resolve, reject) => ({
  type: UPDATE_TIMEOFF,
  timeoffId,
  timeoffData,
  resolve,
  reject
})
export const deleteTimeoff = (timeoffId, params, resolve, reject) => ({
  type: DELETE_TIMEOFF,
  timeoffId,
  params,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const createTimeoffSagaEntity = {
  success: (data, timeoffData) => ({
    type: CREATE_TIMEOFF_SUCCEEDED,
    data,
    timeoffData
  }),
  failure: (error, timeoffData) => ({
    type: CREATE_TIMEOFF_FAILED,
    error,
    timeoffData
  }),

  fetchAPI: (id, options) =>
    callAPI('/timeoffs', { method: 'POST', ...options })
}

export function* doCreateTimeoff({ timeoffData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createTimeoffSagaEntity,
    null,
    null,
    timeoffData,
    resolve,
    reject
  )
}

const updateTimeoffSagaEntity = {
  success: (data, timeoffId) => ({
    type: UPDATE_TIMEOFF_SUCCEEDED,
    data,
    timeoffId
  }),
  failure: (error, timeoffId) => ({
    type: UPDATE_TIMEOFF_FAILED,
    error,
    timeoffId
  }),

  fetchAPI: (timeoffId, options) =>
    callAPI(`/timeoffs/${timeoffId}`, { method: 'PUT', ...options })
}

export function* doUpdateTimeoff({ timeoffId, timeoffData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    updateTimeoffSagaEntity,
    timeoffId,
    null,
    timeoffData,
    resolve,
    reject
  )
}

const deleteTimeoffSagaEntity = {
  success: (data, timeoffId) => ({
    type: DELETE_TIMEOFF_SUCCEEDED,
    data,
    timeoffId
  }),
  failure: (error, timeoffId) => ({
    type: DELETE_TIMEOFF_FAILED,
    error,
    timeoffId
  }),

  fetchAPI: (timeoffId, options) =>
    callAPI(`/timeoffs/${timeoffId}`, { method: 'DELETE', ...options })
}

export function* doDeleteTimeoff({ timeoffId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    deleteTimeoffSagaEntity,
    timeoffId,
    null,
    params,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const Timeoff = Immutable.Record({
  id: null,
  membership_id: null,
  begins_at: null,
  created_at: null,
  begin_day_part: null,
  end_day_part: null,
  counted_days: null,
  created_from_plannings: null,
  ends_at: null,
  message: null,
  response_message: null,
  status: null,
  treated: null,
  updated_at: null,
  validated_on: null,
  validator: null,
  rest_type: null,
  rest_deleted: null,
  permissions: {
    can_read_paid_leave_forecast: null
  }
})

const timeoffForeignKeys = ['membership_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function timeoffs(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case UPDATE_TIMEOFF_SUCCEEDED:
    case CREATE_TIMEOFF_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const timeoff = Immutable.fromJS(action.data)
      // @ts-ignore migration from js(x) to ts(x)
      return updateRecord(state, Timeoff, timeoff, timeoffForeignKeys)
    }
    case DELETE_TIMEOFF_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { timeoffId } = action

      // @ts-ignore migration from js(x) to ts(x)
      return deleteRecord(state, timeoffId, timeoffForeignKeys)
    }
    default:
      return state
  }
}

export default filterActions(timeoffs, [
  FETCH_MEMBERSHIP_SUCCEEDED,
  UPDATE_TIMEOFF_SUCCEEDED,
  CREATE_TIMEOFF_SUCCEEDED,
  DELETE_TIMEOFF_SUCCEEDED
])
