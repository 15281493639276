import type {
  DocumentGeneratedSchema,
  DocumentTemplateAttributesSchema,
  DocumentTemplateEntrySchema,
  NormalizeEntity
} from '@libs/data-access/entities'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { DocumentTemplateModel } from '../models/DocumentTemplateModel'

const initialState: DocumentTemplateModel = {
  attributes: {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    account: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    global: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    contract: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    location: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    team: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    delimiters: null
  },
  templates: [],
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  documentGenerated: null
}

export type ParamsPostDocumentTemplate = {
  title: string
  document: File
}

export type ParamsFillDocumentTemplate = {
  output_name: string
  document_template_id: number
  contract_id: number
  convert_pdf: boolean
  amendment_id?: number
}

export type ParamsGetDocumentTemplate = {
  page: number
  per_page: number
}

export type ParamsGenerateDocument = {
  document_id: number
  url: string
  status: 'success' | 'failure'
}

export type ParamsUpdateDocumentTemplate = {
  document_template_id: number
  title: string
  document: File
}

export type ParamsDeleteDocumentTemplate = {
  document_template_id: number
}

export type EntitiesState = {
  documentTemplate: NormalizeEntity<DocumentTemplateModel>
}

type DocumentAttributesPayload = DocumentTemplateAttributesSchema

type PostDocumentTemplatePayload = DocumentTemplateEntrySchema

type GetDocumentTemplatePayload = DocumentTemplateEntrySchema[]

type SetDocumentGeneratedPayload = DocumentGeneratedSchema

type DeleteDocumentTemplatePayload = {
  document_template_id: number
}

const entitiesSlice = createSlice({
  name: 'emp/documentTemplate/entities',
  initialState,
  reducers: {
    setDocumentAttributes(
      state,
      action: PayloadAction<DocumentAttributesPayload>
      // I dunno why <Pick<DocumentTemplateModel, 'attributes'>> doesn't work :thinking:
    ) {
      return { ...state, ...action.payload }
    },
    setDocumentTemplate(
      state,
      action: PayloadAction<GetDocumentTemplatePayload>
    ) {
      const { attributes, documentGenerated } = state
      return {
        attributes,
        documentGenerated,
        templates: [...action.payload]
      }
    },
    createDocumentTemplate(
      state,
      action: PayloadAction<PostDocumentTemplatePayload>
    ) {
      const { attributes, templates, documentGenerated } = state
      return {
        attributes,
        documentGenerated,
        templates: [...templates, action.payload]
      }
    },
    updateDocumentTemplate(
      state,
      action: PayloadAction<PostDocumentTemplatePayload>
    ) {
      const { templates } = state
      const itemIndex = templates.findIndex(
        template => template.id === action.payload.id
      )
      if (!state.templates[itemIndex]) {
        state.templates[itemIndex] = {
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          id: null,
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          account_id: null,
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          contract_type: null,
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          content: null,
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          title: null,
          variables: [],
          // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
          document_url: null
        }
      }

      state.templates.splice(itemIndex, 1, action.payload)
    },
    deleteDocumentTemplate(
      state,
      action: PayloadAction<DeleteDocumentTemplatePayload>
    ) {
      const { templates } = state
      const itemIndex = templates.findIndex(
        template => template.id === action.payload.document_template_id
      )
      if (!state.templates[itemIndex]) {
        return state
      }
      state.templates.splice(itemIndex, 1)
    },
    setDocumentGenerated(
      state,
      action: PayloadAction<SetDocumentGeneratedPayload>
    ) {
      const { attributes, templates } = state
      return { attributes, templates, documentGenerated: action.payload }
    },
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    resetDocumentGenerated(state) {
      const { attributes, templates } = state
      return { attributes, templates, documentGenerated: null }
    }
  }
})

export const {
  setDocumentAttributes,
  createDocumentTemplate,
  setDocumentTemplate,
  updateDocumentTemplate,
  deleteDocumentTemplate,
  resetDocumentGenerated,
  setDocumentGenerated
} = entitiesSlice.actions
// @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
export default entitiesSlice.reducer
