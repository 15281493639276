import { convertFileSize } from './convertFileSize/convertFileSize'
import { mimeTypes, extensionByMimeType } from './file.constants'
import { formatBytesToSize } from './formatBytesToSize/formatBytesToSize'

class InputFileUtility {
  mimeTypes = mimeTypes

  extensionByMimeType = extensionByMimeType

  convertFileSize = convertFileSize

  formatBytesToSize = formatBytesToSize
}

export const inputFileUtility = new InputFileUtility()
