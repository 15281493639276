import { cva } from 'class-variance-authority'
import type { FC } from 'react'

import styles from './Spinner.module.css'
import type { SpinnerProps } from './Spinner.types'
import { SpinnerSvg } from './SpinnerSvg'

const spinnerCVA = {
  root: cva([styles.Wrapper], {
    variants: {
      size: {
        xs: styles['Wrapper-xs'],
        s: styles['Wrapper-s'],
        m: styles['Wrapper-m'],
        l: styles['Wrapper-l'],
        xl: styles['Wrapper-xl']
      }
    }
  }),
  spinner: cva([styles.Spinner], {
    variants: {
      theme: {
        dark: styles['Spinner-dark'],
        light: styles['Spinner-light']
      }
    }
  })
}

export const Spinner: FC<SpinnerProps> = ({
  className,
  size = 's',
  theme = 'dark',
  ...other
}) => {
  return (
    <div {...other} className={spinnerCVA.root({ className, size })}>
      <div className={spinnerCVA.spinner({ theme })}>
        <SpinnerSvg className='block' />
      </div>
    </div>
  )
}
