import { updateAuthAccount } from '../authServiceUtils'

/**
 * Switch current authenticated membership/account without signing-out the logged user
 */
export const useSwitchAuthAccount = () => {
  const handleUpdateAuthAccount = (accountId: number) =>
    updateAuthAccount(accountId)

  return handleUpdateAuthAccount
}
