import type { CountryConfigurationSchema } from '@libs/data-access/entities'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type CountryConfigurationsState = {
  data: CountryConfigurationSchema
}

const initialState = {
  data: null
}

const entitiesSlice = createSlice({
  name: 'data/contryConfigurations',
  initialState,
  reducers: {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    setData(state, action: PayloadAction<Partial<CountryConfigurationsState>>) {
      return { ...state, ...action.payload }
    }
  }
})

export const countryConfigurationsReducerActions = entitiesSlice.actions
export default entitiesSlice.reducer
