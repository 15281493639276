import type { FormatterParams } from '../../types'

import { minuteDecimalToHours } from './minuteDecimalToHours'

/**
 * Transform decimal hours to readable hours:minutes
 * This transformation doesn't handle zero(s) trim
 *
 * Input: 12,5
 * Output: 12h30
 *
 * Input: 12,3333
 * Output: 12h20
 */
export const hourDecimalToHours = ({ value }: FormatterParams) => {
  return minuteDecimalToHours({ value: value * 60 })
}

export default {
  format: hourDecimalToHours
}
