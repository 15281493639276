import {
  CheckCircleIcon,
  DeleteIcon,
  UploadButtonIcon
} from '@libs/ui/ds/assets/icons'
import { useDSTranslation } from '@libs/ui/ds/lib/internals'
import { Dropzone as MantineDropzone } from '@mantine/dropzone'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import React from 'react'

import { IconWrap } from '../../../../dataDisplay/IconWrap/base/IconWrap'
import { Loader } from '../../../../feedback/Loader/base/Loader'
import { Flex } from '../../../../primitiveLayouts/Flex/base/Flex'
import { Text } from '../../../../typography/Text/base/Text'
import type { DropzoneProps } from '../Dropzone.types'

const dropzoneLabelCVA = {
  root: cva(['relative h-full items-center'], {
    variants: {
      size: {
        s: '',
        m: 'gap-2'
      }
    }
  }),
  icon: cva(
    [
      'group-data-[idle]/dropzone:text-neutral-500',
      'group-data-[accept]/dropzone:text-neutral-700',
      'group-data-[reject]/dropzone:text-semantic-red-600'
    ],
    {
      variants: {
        size: {
          s: '',
          m: 'mb-2'
        }
      }
    }
  ),
  title: cva([
    'max-w-sm text-center text-s font-semibold text-neutral-700',
    'group-data-[accept]/dropzone:text-neutral-900',
    'group-data-[reject]/dropzone:text-semantic-red-600'
  ]),
  desc: cva([
    'max-w-xs text-center text-xs text-neutral-500',
    'group-data-[accept]/dropzone:text-neutral-700',
    'group-data-[reject]/dropzone:text-semantic-red-400'
  ])
}

type DropzoneLabelProps = {
  loading?: DropzoneProps['loading']
  title?: DropzoneProps['title']
  desc?: DropzoneProps['desc']
  size: DropzoneProps['size']
}

export const DropzoneLabel: FC<DropzoneLabelProps> = ({
  loading,
  title,
  desc,
  size
}) => {
  const { t } = useDSTranslation()
  const titleOrDefault = title || t('ds.components.dropzone.title')

  return (
    <Flex className={dropzoneLabelCVA.root({ size })}>
      <MantineDropzone.Idle>
        {loading && (
          <Loader
            className={dropzoneLabelCVA.icon({ size })}
            size='xs'
            theme='dark'
          />
        )}
        {!loading && (
          <IconWrap
            size='m'
            className={dropzoneLabelCVA.icon({ size })}
            icon={<UploadButtonIcon />}
          />
        )}
      </MantineDropzone.Idle>
      <MantineDropzone.Accept>
        <IconWrap
          size='m'
          className={dropzoneLabelCVA.icon({ size })}
          icon={<CheckCircleIcon />}
        />
      </MantineDropzone.Accept>
      <MantineDropzone.Reject>
        <IconWrap
          size='m'
          className={dropzoneLabelCVA.icon()}
          icon={<DeleteIcon />}
        />
      </MantineDropzone.Reject>
      <Flex>
        <Text className={dropzoneLabelCVA.title()}>{titleOrDefault}</Text>
        {desc && <Text className={dropzoneLabelCVA.desc()}>{desc}</Text>}
      </Flex>
    </Flex>
  )
}
