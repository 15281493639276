import { getWeekRange } from '@spa/scenes/Plannings/utils'
import { map, concat } from 'lodash-es'
import moment from 'moment'
import type { Alert } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import { alertTypeScopes } from '../constants/alertTypeScopes'

const fitlerAlertsDataByRange = (
  alerts: Omit<Alert, never>[],
  { start, end }: { start: string; end: string }
) => {
  /**
   * Filter day alerts that are between start and end of a range
   */
  const filteredRangeAlertsData = map(alerts, alert => ({
    ...alert,
    alerts: alert.alerts.filter(
      alertData =>
        alertTypeScopes.day.includes(alertData.type) &&
        moment(alertData.date).isBetween(start, end, null, '[]')
    )
  }))

  const currentWeek = getWeekRange(start)
  /**
   * Filter contract alerts that are between start and end of the week of the start date
   */
  const filteredGlobalAlertsData = map(alerts, alert => ({
    ...alert,
    alerts: alert.alerts.filter(
      alertData =>
        alertTypeScopes.week.includes(alertData.type) &&
        moment(alertData.date).isBetween(
          currentWeek.dayStart,
          currentWeek.dayEnd,
          null,
          '[]'
        )
    )
  }))

  /**
   * Merge the two array to retreive daily alerts & weekly contract alerts
   */
  return filteredGlobalAlertsData.map(globalAlert => {
    const sameUserAlert = filteredRangeAlertsData.find(
      rangeAlert => rangeAlert.user_contract_id === globalAlert.user_contract_id
    )

    return {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      user_contract_id: sameUserAlert.user_contract_id,
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      alerts: concat(sameUserAlert.alerts, globalAlert.alerts)
    }
  })
}

export default fitlerAlertsDataByRange
