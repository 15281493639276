import Immutable from 'immutable'
import { filterActions } from 'redux-ignore'

import {
  FETCH_CONTRACTS_SUCCEEDED,
  DELETE_CONTRACT_SUCCEEDED,
  FETCH_MEMBERSHIP_SUCCEEDED,
  FETCH_ALL_MEMBERSHIPS_SUCCESS
} from '../../actionTypes'
import { mergeRecords } from '../../services/immutableUtils'

// ------------------------------------
// Models
// ------------------------------------

export const Amendment = Immutable.Record({
  id: null,
  contract_time: null,
  contract_type: null,
  end_date: null,
  mensual_brut: null,
  hourly_brut: null,
  account_job: null,
  start_date: null,
  user_contract_id: null,
  echelon_level: null,
  echelon_number: null,
  echelon_other: null,
  working_days_in_week: null,
  has_minimum_wage: null
})

const amendmentForeignKeys = ['user_contract_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function amendments(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_ALL_MEMBERSHIPS_SUCCESS: {
      // @ts-ignore migration from js(x) to ts(x)
      const amendments = Immutable.fromJS(action.payload.memberships)
        .map(membership => {
          return (
            Immutable.fromJS(membership).getIn([
              'most_relevant_contract',
              'amendments'
            ]) || Immutable.List()
          )
        })
        .flatten(true)

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Amendment, amendments, amendmentForeignKeys)
    }

    case FETCH_MEMBERSHIP_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const amendments = Immutable.fromJS(action.data.contracts || [])
        .map(contract => contract.get('amendments') || Immutable.List())
        .flatten(true)

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Amendment, amendments, amendmentForeignKeys)
    }
    case FETCH_CONTRACTS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const amendments = Immutable.fromJS(action.data)
        .map(contract => contract.get('amendments') || Immutable.List())
        .flatten(true)

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Amendment, amendments, amendmentForeignKeys)
    }
    case DELETE_CONTRACT_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { contractId } = action
      const amendmentsIds =
        state.getIn(['relations', 'user_contract_id', contractId]) ||
        Immutable.Set()

      return state
        .deleteIn(['relations', 'user_contract_id', contractId])
        .withMutations(store => {
          amendmentsIds.forEach(amendmentId => {
            store.deleteIn(['data', amendmentId])
          })
        })
    }
    default:
      return state
  }
}

export default filterActions(amendments, [
  FETCH_MEMBERSHIP_SUCCEEDED,
  FETCH_ALL_MEMBERSHIPS_SUCCESS,
  FETCH_CONTRACTS_SUCCEEDED,
  DELETE_CONTRACT_SUCCEEDED
])
