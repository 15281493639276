import { cva } from 'class-variance-authority'
import type { FC } from 'react'

import type { ImageProps } from './Image.types'

const imageCVA = cva(['relative box-border block'], {
  variants: {
    fit: {
      contain: 'object-contain ',
      'cover-center': 'object-cover object-center'
    },
    aspect: {
      auto: 'aspect-auto',
      square: 'aspect-square'
    },
    size: {
      xs: 'w-10',
      s: 'w-16',
      m: 'w-24',
      l: 'w-32'
    }
  }
})

export const Image: FC<ImageProps> = ({
  src,
  size = 'm',
  alt = '',
  loading = 'lazy',
  fit = 'contain',
  aspect = 'auto',
  className,
  ...other
}) => {
  return (
    <img
      {...other}
      src={src}
      alt={alt}
      loading={loading}
      className={imageCVA({ className, aspect, fit, size })}
    />
  )
}

export type { ImageProps } from './Image.types'
