import { cva } from 'class-variance-authority'
import { memo, forwardRef } from 'react'

import { IconWrap } from '../../IconWrap/base/IconWrap'

import type { ThemeIconProps } from './ThemeIcon.types'

const themeIconCVA = cva(
  ['box-border inline-flex items-center justify-center'],
  {
    variants: {
      size: {
        xs: 'size-7 min-h-7 min-w-7 rounded',
        s: 'size-8 min-h-8 min-w-8 rounded',
        m: 'size-9 min-h-9 min-w-9 rounded',
        l: 'size-14 min-h-14 min-w-14 rounded-lg',
        xl: 'size-20 min-h-20 min-w-20 rounded-lg'
      },
      color: {
        neutral: 'bg-neutral-100 text-neutral-700',
        'brand.green': 'bg-brand-green-100 text-brand-green-600',
        'brand.beige': 'bg-brand-beige-100 text-brand-beige-600',
        'secondary.orange': 'bg-secondary-orange-100 text-secondary-orange-600',
        'secondary.blue': 'bg-secondary-blue-100 text-secondary-blue-600',
        'secondary.purple': 'bg-secondary-purple-100 text-secondary-purple-600',
        'secondary.coral': 'bg-secondary-coral-100 text-secondary-coral-600'
      }
    }
  }
)

export const ThemeIcon = memo(
  forwardRef<HTMLDivElement, ThemeIconProps>(
    ({ icon, className, color = 'neutral', size = 'm', ...other }, ref) => {
      return (
        <div
          {...other}
          ref={ref}
          className={themeIconCVA({ className, color, size })}
        >
          <IconWrap size={size} icon={icon} />
        </div>
      )
    }
  )
)

ThemeIcon.displayName = 'ThemeIcon'
export type { ThemeIconProps } from './ThemeIcon.types'
