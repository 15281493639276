import moment from 'moment'
import type {
  Break,
  Shift
} from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import { filterShiftBreaksWithSlot } from '../utils/filterShiftBreaksWithSlot'

const addMinutesToDate = (date: string, offset: number) => {
  return moment(date).add(offset, 'minutes').toISOString()
}

/**
 * Update all slots property from a given break
 */
const keepTimeSlotRelative = (shiftBreak: Break, offset: number) => {
  return {
    ...shiftBreak,
    real_starts_at: addMinutesToDate(shiftBreak.real_starts_at, offset),
    real_ends_at: addMinutesToDate(shiftBreak.real_ends_at, offset),
    planned_starts_at: addMinutesToDate(shiftBreak.planned_starts_at, offset),
    planned_ends_at: addMinutesToDate(shiftBreak.planned_ends_at, offset)
  }
}

/**
 * Adapt breaks with slots with the new start of the shift
 *
 * E.g.
 * If the initial [Shift.start] is [12:00], and after update the [Shift.start] is [13:00]
 * Then all Shift Breaks with slots should be updated (add 1 hour)
 */
export const adaptShiftBreaksSlotOnSlide = (
  updatedStart: string,
  shift: Shift
) => {
  const diffInHour = moment(updatedStart).diff(shift.real_starts_at, 'minutes')
  const breaksWithSlot = filterShiftBreaksWithSlot(shift.shift_breaks)
  const updatedShiftBreaks = breaksWithSlot.map(shiftBreak =>
    keepTimeSlotRelative(shiftBreak, diffInHour)
  )

  return updatedShiftBreaks
}
