import type { Row } from '@tanstack/react-table'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'

import type { TableProps } from '../../Table.types'
import { tableClasses } from '../tableClasses'

import { TableRowCell } from './TableRowCell'

const tableRowCVA = {
  row: cva(
    [tableClasses.bodyRow, ' border-b-1 border-neutral-200 last:border-b-0'],
    {
      variants: {
        clickable: {
          true: 'cursor-pointer hover:bg-neutral-100'
        }
      }
    }
  )
}

type TableRowProps<T = any> = {
  loading?: boolean
  row: Row<T>
  cellsContent?: TableProps['cellsContent']
  cellsPaddingSize?: TableProps['cellsPaddingSize']
  clickableRowsFilter?: TableProps['clickableRowsFilter']
  onClick?: TableProps['onRowClick']
  'data-testid'
}

export const TableRow: FC<TableRowProps> = ({
  loading,
  row,
  cellsContent,
  cellsPaddingSize,
  clickableRowsFilter,
  onClick,
  'data-testid': dataTestId
}) => {
  const clickable =
    Boolean(onClick) &&
    (clickableRowsFilter ? clickableRowsFilter(row.original) : true)

  return (
    <tr
      className={tableRowCVA.row({ clickable })}
      data-testid={dataTestId}
      {...(clickable && onClick
        ? {
            onClick: () => onClick(row.original)
          }
        : {})}
    >
      {row.getVisibleCells().map(cell => (
        <TableRowCell
          loading={loading}
          cell={cell}
          cellsContent={cellsContent}
          cellsPaddingSize={cellsPaddingSize}
          key={cell.id}
        />
      ))}
    </tr>
  )
}
