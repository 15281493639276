import { padStart } from 'lodash-es'

import type { FormatterParams } from '../../types'

import { trimHours } from './helpers/trimHours'

export const displayTwoNumber = num => padStart(String(num), 2, '0')

/**
 * Transform decimal minutes to readable hours:minutes
 * This transformation doesn't handle zero(s) trim
 *
 * Input: 1835
 * Output: 30h35
 *
 * Input: 60
 * Output: 01h00
 *
 * Input: -90
 * Output: -01h30
 */
export const minuteDecimalToHours = ({ value }: FormatterParams) => {
  const separator = 'h'
  const signBeforeNumber = Math.sign(value) === -1 ? '-' : ''
  const roundedMinutes = Math.abs(Math.round(value))
  const hours = Math.floor(roundedMinutes / 60)
  const minutes = roundedMinutes % 60

  const hourFormat = `${displayTwoNumber(hours)}${separator}${displayTwoNumber(
    minutes
  )}`

  const trimHourFormat = trimHours(hourFormat)
  return `${signBeforeNumber}${trimHourFormat}`
}
export default {
  format: minuteDecimalToHours
}
