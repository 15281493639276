import type { FC, PropsWithChildren, ReactElement } from 'react'

import type { TableProps } from '../../Table.types'

import { useTableComputedStatus } from './useTableComputedStatus'

type Props = PropsWithChildren<{
  states?: TableProps['states']
  data?: TableProps['data']
}>

export const TableStatesWrap: FC<Props> = ({ states, data, children }) => {
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  const computedStatus = useTableComputedStatus({ states, data })

  if (computedStatus === 'loading') {
    return children as ReactElement
  }

  if (computedStatus === 'error' || computedStatus === 'empty') {
    return null
  }

  return children as ReactElement
}
