import { BaseButton } from '@libs/ui/ds/lib/components/buttons/BaseButton/base/BaseButton'
import { createButton } from '@libs/ui/ds/lib/components/buttons/BaseButton/utils/createButton'
import { IconWrap } from '@libs/ui/ds/lib/components/dataDisplay/IconWrap/base/IconWrap'
import { Loader } from '@libs/ui/ds/lib/components/feedback/Loader/base/Loader'
import { Absolute } from '@libs/ui/ds/lib/components/primitiveLayouts/Absolute/base/Absolute'
import { outlineFocusMixin } from '@libs/ui/ds/lib/styles/mixins/mixins'
import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'

import type { ButtonProps } from './Button.types'
import { buttonCompoundClasses } from './buttonCompoundClasses'

const buttonCVA = {
  root: cva(
    [
      'relative inline-flex min-w-0 items-center justify-center overflow-hidden rounded-full font-medium',
      outlineFocusMixin
    ],
    {
      variants: {
        size: {
          xs: 'h-6 gap-1 px-2.5 text-xs',
          s: 'h-8 gap-1.5 px-3 text-xs',
          m: 'h-10 gap-2 px-4 text-s',
          l: 'h-12 gap-2 px-5 text-s',
          xl: 'h-13 gap-3 px-6 text-m'
        },
        variant: {
          primary: '',
          secondary: '',
          tertiary: ''
        },
        loading: {
          true: 'pointer-events-none text-opacity-0'
        },
        intent: {
          default: '',
          danger: '',
          'neutral-dark': ''
        },
        fillWidth: {
          true: 'w-full'
        },
        disabled: {
          true: ''
        }
      },
      compoundVariants: [...buttonCompoundClasses]
    }
  ),
  props: {
    iconSize: {
      xs: 'xs',
      s: 's',
      m: 's',
      l: 's',
      xl: 's'
    }
  }
} as const

export const Button = createButton<ButtonProps>(
  forwardRef<HTMLButtonElement, ButtonProps>(
    (
      {
        className,
        children,
        size = 'm',
        intent = 'default',
        variant = 'primary',
        disabled = false,
        fillWidth,
        leftIcon,
        rightIcon,
        loading,
        ...other
      },
      ref
    ) => {
      const classNames = buttonCVA.root({
        loading,
        fillWidth,
        className,
        disabled,
        intent,
        size,
        variant
      })

      return (
        <BaseButton
          {...other}
          ref={ref}
          disabled={disabled || loading}
          className={classNames}
        >
          {loading && (
            <Absolute x='center' y='center'>
              <Loader theme='dark' size='xs' />
            </Absolute>
          )}
          {leftIcon && (
            <IconWrap icon={leftIcon} size={buttonCVA.props.iconSize[size]} />
          )}
          <span className='inline-block truncate'>{children}</span>
          {rightIcon && (
            <IconWrap icon={rightIcon} size={buttonCVA.props.iconSize[size]} />
          )}
        </BaseButton>
      )
    }
  )
)

Button.displayName = 'Button'
export type { ButtonProps } from './Button.types'
