import { cookiesService } from '@libs/utils/cookies'
import { API_APPLICATION_HOST, HTTP_PROTOCOL } from '@libs/utils/environments'

import type {
  HttpClientAuthState,
  HttpServiceEndpoints
} from './interfaces/HttpClient.interface'
import HttpClientService from './services/HttpClient'

export class HttpServices {
  private httpService: HttpClientService

  public core: HttpServiceEndpoints

  public billing: HttpServiceEndpoints

  private authState: HttpClientAuthState = {
    accountId: cookiesService.readAccountId() as number,
    userId: cookiesService.readUserId() as number
  }

  public static contentTypes = {
    multipartFormData: { 'Content-Type': 'multipart/form-data' }
  }

  constructor() {
    this.httpService = new HttpClientService(
      `${HTTP_PROTOCOL}://${API_APPLICATION_HOST}`,
      this.authState
    )

    this.core = this.httpService.createServiceEndpoints('/api/v2')
    this.billing = this.httpService.createServiceEndpoints(
      '/gateway/api/billing'
    )
  }

  public syncAuthState(authState: Partial<HttpClientAuthState>) {
    this.authState = { ...this.authState, ...authState }
    this.httpService.updateHeaders(this.authState as HttpClientAuthState)
  }

  get authInfo() {
    return this.authState
  }
}

export const httpServices = new HttpServices()
export type { HttpClientAuthState } from './interfaces/HttpClient.interface'
