import type { ModulationUserContractSchema } from '@libs/data-access/entities'

import { httpServices } from '../../../apis'
import type { QueryFnParams } from '../../../types'

export type FetchAllModulationUserContractsOptions = QueryFnParams<{
  pathParams: {
    membership_id: number
  }
  queryParams?: {
    includes?: string[]
  }
}>

/**
 *
 */
export const fetchAllModulationUserContracts = async (
  opts: FetchAllModulationUserContractsOptions
) => {
  const response = await httpServices.core.get<ModulationUserContractSchema[]>(
    `/payroll/memberships/${opts.pathParams.membership_id}/modulation_user_contracts`,
    {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      params: opts?.queryParams
    }
  )
  return response.data
}

export type CreateModulationUserContractOptions = QueryFnParams<{
  payload: {
    user_contract_id: number
  }
}>

export const createModulationUserContract = async (
  opts: CreateModulationUserContractOptions
) => {
  const response = await httpServices.core.post(
    `/modulation_user_contracts`,
    opts?.payload
  )
  return response.data
}
