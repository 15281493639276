import type { WeeklyScheduleSchema } from '@libs/data-access/entities'
import type { ReduxState } from '@spa/redux/ReduxState'
import { useMemoSelector } from '@spa/redux/hooks'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'

import { weeklyschedulesSelectors } from '../../entities'
import { payPeriodsSelectors } from '../../entities/payPeriods'
import { getParams } from '../../params'
import { getIsoDaysInTimeRange } from '../../timeRange'
import type { PlanningPolicies } from '../getPlanningPolicies'
import { getPlanningPolicies } from '../getPlanningPolicies'

type Params = {
  teamId: number
}

const getWeeklyscheduleFromTeamdIdAndDate = (
  state: ReduxState,
  { teamId }: Params
) => {
  const { date } = getParams(state)
  return weeklyschedulesSelectors.getWeeklyscheduleFromTeamId(state, {
    teamId,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    date
  })
}

const computeCanCreatePlannablePoliciesByDay = (
  weeklyschedule: Omit<WeeklyScheduleSchema, 'permissions'>,
  dayMap: string[],
  policies: PlanningPolicies,
  lockedDays: {
    [x: string]: {
      isLocked: boolean
    }
  }
) => {
  const canCreatePlannable = weeklyschedule.published
    ? policies.canEditPublishedRest || policies.canEditPublishedShift
    : policies.canEditShift || policies.canEditRest

  const canCreateByDay = dayMap.reduce((acc, day) => {
    return {
      ...acc,
      ...{
        [day]: !lockedDays[day]?.isLocked && canCreatePlannable
      }
    }
  }, {})

  return canCreateByDay
}

export const getCanCreatePlannablePoliciesByDay = createCachedSelector(
  getWeeklyscheduleFromTeamdIdAndDate,
  getIsoDaysInTimeRange,
  getPlanningPolicies,
  payPeriodsSelectors.getLockedDaysInTimerange,

  computeCanCreatePlannablePoliciesByDay
)((_, { teamId }: Params) => fallbackToDefaultCacheKey(teamId))

export const useCanCreatePlannablePoliciesByDay = (opts: Params) => {
  return useMemoSelector(state =>
    getCanCreatePlannablePoliciesByDay(state, opts)
  )
}
