import type { ContractSchema } from '@libs/data-access/entities'
import { getContractType } from 'snap-redux/modules/contracts/helpers'

export const hasOtherThanExtraOrInterimContractType = (
  contract: ContractSchema,
  showExtraOrInterimOnly: boolean
) => {
  const { isExtra, isInterim } = getContractType(contract)

  return showExtraOrInterimOnly && !(isExtra || isInterim)
}
