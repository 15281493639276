import { formatErrors } from '@spa/components/FormFields/helpers/formatErrors'
import type { FC } from 'react'
import React from 'react'
import type { Control, FieldErrors } from 'react-hook-form'
import { Controller } from 'react-hook-form'

type Options = {
  // Property from RHF Controller component
  // This prop allows you to support inputs that doesn't use a prop called value. eg: checked, selected and etc.
  valueName?: string
  onChangeName?: string
  onBlurName?: string
  forwardControl?: boolean
}

// Controlled Component Props
type ControlledProps<TValue> = {
  control: Control<any>
  inputName: string
  onChange?: TOnChange<TValue> | TOnChange<TValue, []>
  onBlur?: TOnChange<TValue> | TOnChange<TValue, []>
  defaultValue?: TValue
  index?: number
  errors?: FieldErrors
}

// RHF Controller `onChange` Generic Type
type TOnChange<TValue, Arr = never> = Arr extends []
  ? (value: [TValue, ...any[]]) => TValue
  : (value: TValue) => TValue

// Given Component Props Type
// It assume the given component has an error props.
// Then we extends the given interface with ours ({error: string}) to match typescript definition for dynamic types
// @TODO check if there is a way to require error directly from `T` insteadof "faking" props `& {error: string}`
type TComponentProps<T> = T & { error: string }

// Given Component Type
// It pass the given type to TComponentProps to extends it with "error" prop type
type TComponent<T> = FC<TComponentProps<T>>

// HoC Return Component Type
// This is the type of Enhanced component
// It merges the given Component Type with Controlled Component Type
type EnhancedComponent<TProps, TValue> = FC<ControlledProps<TValue> & TProps>

function withController<TProps = object, TValue = any>({
  valueName = 'value',
  onChangeName = 'onChange',
  onBlurName = 'onBlur',
  forwardControl
}: Options = {}) {
  return (Component: TComponent<TProps>): EnhancedComponent<TProps, TValue> => {
    const EnhancedComponent: EnhancedComponent<TProps, TValue> = ({
      defaultValue,
      inputName,
      control,
      errors,
      onChange: handleCustomChange,
      onBlur: handleCustomBlur,
      index,
      ...other
    }) => {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      const error = formatErrors({ inputName, errors, index })
      return (
        <Controller
          render={({ field: { onChange, value, onBlur } }) => (
            // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
            <Component
              /**
               * Handle custom onChange props for specific usecases
               */
              {...{
                [onChangeName]: newValue => {
                  const val = handleCustomChange
                    ? handleCustomChange(newValue)
                    : newValue
                  return onChange(val)
                }
              }}
              /**
               * Handle custom onBlur props for specific usecases
               */
              {...{
                [onBlurName]: newValue => {
                  const val = handleCustomBlur
                    ? handleCustomBlur(newValue)
                    : newValue
                  return (onBlur as any)(val)
                }
              }}
              {...{ [valueName]: value }}
              {...(other as TProps)}
              {...(forwardControl && { control })}
              error={error}
              inputName={inputName}
            />
          )}
          defaultValue={defaultValue}
          name={inputName}
          control={control}
        />
      )
    }

    EnhancedComponent.displayName = `Controlled${Component.name}`

    return EnhancedComponent
  }
}

export default withController
