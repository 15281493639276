/**
 *
 */
function fetchAfterEach(fetchFn, paramsFn, nextFn, promises, meta = null) {
  const params = paramsFn(meta)
  const promise = fetchFn(...params)

  promises.push(promise)

  return promise.then(data => {
    const shouldContinue = nextFn(data)
    if (shouldContinue) {
      return fetchAfterEach(fetchFn, paramsFn, nextFn, promises, data?.meta)
    }
    return promises
  })
}

/**
 *
 */
export function concatPromisesData(promises, opts: any = {}) {
  const { dataAttribute } = opts

  return Promise.all(promises).then(values => {
    const arr = dataAttribute ? values.map(v => v[dataAttribute]) : values
    return arr.flat()
  })
}

/**
 *
 */
const paginationNext = data => {
  // if last page we return array of promises
  if (data.meta.total_pages === data.meta.current_page) {
    return false
  }

  if (data.meta.total_pages > 1) {
    return true
  }
}

/**
 *
 */
export function batch(fetchFn, queue: any[], promises = []) {
  let callLeft = queue.length
  const paramsFn = () => queue[queue.length - callLeft]
  const nextFn = () => {
    if (callLeft < 1) {
      return false
    }
    callLeft -= 1
    return true
  }
  return fetchAfterEach(fetchFn, paramsFn, nextFn, promises)
}

/**
 *
 */
export function batchPaginated(fetchFn, paramFn, promises = []) {
  return fetchAfterEach(fetchFn, paramFn, paginationNext, promises)
}
