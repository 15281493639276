import type { Table } from '@tanstack/react-table'
import { cva } from 'class-variance-authority'
import { useMemo, type FC, type PropsWithChildren } from 'react'

import { useTableHorizontalScroll } from '../../../services/useTableHorizontalScroll'
import type { TableProps } from '../../Table.types'

const tableCVA = {
  horizontalShadow: cva([], {
    variants: {
      tableShadowRight: {
        true: 'absolute right-0 top-0 z-[2] h-full w-[15px] bg-gradient-to-r from-neutral-300/0 to-neutral-600/20'
      },
      tableShadowLeft: {
        true: 'absolute top-0 z-[2] h-full w-[15px] bg-gradient-to-l from-neutral-300/0 to-neutral-600/20'
      }
    }
  })
}

type TableHorizontalShadowProps = PropsWithChildren<{
  horizontalScroll: TableProps['horizontalScroll']
  table: Table<any>
  tableRef: any
}>

export const TableHorizontalShadow: FC<TableHorizontalShadowProps> = ({
  horizontalScroll,
  table,
  tableRef,
  children
}) => {
  const { tableShadowLeft, tableShadowRight } = useTableHorizontalScroll({
    horizontalScroll,
    tableRef
  })

  const columnPinningSize = useMemo(() => {
    if (!horizontalScroll) {
      return 0
    }
    const lastHeaderGroup =
      table.getHeaderGroups()[table.getHeaderGroups().length - 1]

    const headerPinSize = lastHeaderGroup.headers.reduce((acc, header) => {
      return header.column.getIsPinned() === 'left'
        ? acc + header.column.getSize()
        : acc
    }, 0)

    return headerPinSize
  }, [horizontalScroll, table])

  return (
    <div className='relative'>
      {horizontalScroll && (
        <div
          className={tableCVA.horizontalShadow({
            tableShadowLeft,
            tableShadowRight: false
          })}
          style={{
            ...(tableShadowLeft && { left: `${columnPinningSize}px` })
          }}
        />
      )}
      {children}
      {horizontalScroll && (
        <div
          className={tableCVA.horizontalShadow({
            tableShadowLeft: false,
            tableShadowRight
          })}
        />
      )}
    </div>
  )
}
