import { useDSTranslation } from '@libs/ui/ds/lib/internals'
import type { FC } from 'react'
import React from 'react'

import { Flex } from '../../../../primitiveLayouts/Flex/base/Flex'
import { Text } from '../../../../typography/Text/base/Text'

import { PasswordInputRestrictionItem } from './PasswordInputRestrictionItem'
import type { PasswordInputRestrictionsProps } from './PasswordInputRestrictions.types'

export const PasswordInputRestrictions: FC<PasswordInputRestrictionsProps> = ({
  passwordValue,
  restrictions
}) => {
  const { t } = useDSTranslation()
  return (
    <Flex className='gap-1'>
      <Text className='mb-2 text-s font-semibold'>
        {t('ds.components.passwordInput.passwordRestrictionsTitle')}
      </Text>
      {(restrictions || []).map((restriction, idx) => (
        <PasswordInputRestrictionItem
          key={idx}
          restriction={restriction}
          passwordValue={passwordValue}
        />
      ))}
    </Flex>
  )
}
