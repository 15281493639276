import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type PaginationDocumentEmployeeState = {
  current_page: number
  per_page: number
  total_count: number
  total_pages: number
}

const initialState: PaginationDocumentEmployeeState = {
  current_page: 0,
  per_page: 0,
  total_count: 0,
  total_pages: 0
}

const paramsSlice = createSlice({
  name: 'emp/docuementEmployee/pagination',
  initialState,
  reducers: {
    setPagination(_, action: PayloadAction<PaginationDocumentEmployeeState>) {
      return {
        ...action.payload
      }
    }
  }
})

export const { setPagination } = paramsSlice.actions
export default paramsSlice.reducer
