import type {
  MediaQueryThreshold,
  Screen,
  ScreenThresholdType
} from '../screens'
import { useScreensMediaQuery } from '../useScreensMediaQuery/useScreensMediaQuery'

/**
 * Return boolean when media-query threshold is matched
 */
export const useMediaQueryThreshold = ({
  mediaQueryTreshold
}: {
  mediaQueryTreshold?: MediaQueryThreshold
}) => {
  const [thresholdType, screenToken] = mediaQueryTreshold
    ? (mediaQueryTreshold.split('-') as [ScreenThresholdType, Screen])
    : []

  const reach = useScreensMediaQuery({
    thresholdType,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    screenList: [screenToken]
  })

  if (!thresholdType) {
    return false
  }

  return reach === screenToken
}
