import { defineTranslationService } from './defineTranslationService'

export const [useAppNavBarTranslation] = defineTranslationService('appNavbar')
export const [useAuthTranslation] = defineTranslationService('authentication')
export const [useOnboardingTranslation] = defineTranslationService('onboarding')

export const [usePlanningTranslation, withPlanningTranslation] =
  defineTranslationService('planning')

export const [usePlanningFormTranslation, withPlanningFormTranslation] =
  defineTranslationService('planningForm')

export const [useDashboardRHTranslation, withDashboardRHTranslation] =
  defineTranslationService('dashboardRH')

export const [useSettingsPlanification, withSettingsPlanificationTranslation] =
  defineTranslationService('settingsPlanification')

export const [useSettingsIntegrationsTranslation] = defineTranslationService(
  'settingsIntegrations'
)
export const [useSettingsLocationTranslation] = defineTranslationService(
  'billingSettingsLocation'
)

export const [
  useSettingsContractsTranslation,
  ,
  loadSettingsContractsTranslation
] = defineTranslationService('settingsContracts')

export const [useTimeOffTranslation, withTimeOffTranslation] =
  defineTranslationService('timeoff')

export const [useIntegrationsTranslation, withIntegrationsTranslation] =
  defineTranslationService('integrations')

export const [useHelpMenuTranslation] = defineTranslationService('helpMenu')

export const [useArticlesTranslation, withArticlesTranslation] =
  defineTranslationService('articles')

export const [useConversationsTranslation, withConversationsTranslation] =
  defineTranslationService('conversations')

export const [useDashboardTranslation, withDashboardTranslation] =
  defineTranslationService('dashboard')

export const [useMembersTranslation, withMembersTranslation] =
  defineTranslationService('members')

export const [useMemberCountersTranslation] =
  defineTranslationService('memberCounters')

export const [
  useMemberPreferencesTranslation,
  ,
  loadMemberPreferencesTranslation
] = defineTranslationService('memberPreferences')

export const [usePayrollReportTranslation, withPayrollReportTranslation] =
  defineTranslationService('payrollReport')

export const [usePlanningAnalysisTranslation, withPlanningAnalysisTranslation] =
  defineTranslationService('planningAnalysis')

export const [useSettingsTranslation, withSettingsTranslation] =
  defineTranslationService('settings')

export const [
  useSettingsNotificationTranslation,
  withSettingsNotificationTranslation
] = defineTranslationService('settingsNotification')

export const [useSettingsGestionTranslation, withSettingsGestionTranslation] =
  defineTranslationService('settingsGestion')

export const [useRGPDSettingTranslation, withRGPDSettingTranslation] =
  defineTranslationService('RGPDSetting')

export const [useSubscriptionTranslation] =
  defineTranslationService('subscription')
export const [useBillingTranslation] = defineTranslationService('billing')
export const [useSubscriptionInformationTranslation] = defineTranslationService(
  'subscriptionInformation'
)

export const [useMemberListTranslation, withMemberListTranslation] =
  defineTranslationService('memberList')

export const [useSettingsCompanyTranslation, withSettingsCompanyTranslation] =
  defineTranslationService('settingsCompany')

export const [useSettingsAdminTranslation, withSettingsAdminTranslation] =
  defineTranslationService('settingsAdmin')

export const [useMemberPageTranslation, withMemberPageTranslation] =
  defineTranslationService('memberPage')

export const [useMembershipPageTranslation, withMembershipPageTranslation] =
  defineTranslationService('membershipPage')

export const [useMemberLockTranslation] = defineTranslationService('memberLock')

export const [
  useMemberPlanningFormTranslation,
  withMemberPlanningFormTranslation
] = defineTranslationService('memberPlanningForm')

export const [useMemberTimeOffTranslation, withMemberTimeOffTranslation] =
  defineTranslationService('memberTimeoff')

export const [useMemberUserTranslation, withMemberUserTranslation] =
  defineTranslationService('memberUser')

export const [
  useMemberPublicHolidayTranslation,
  withMemberPublicHolidayTranslation
] = defineTranslationService('memberPublicHoliday')

export const [useReportsTranslation] = defineTranslationService('reports')

export const [useTimesheetTranslation, withTimesheetTranslation] =
  defineTranslationService('timesheet')

export const [
  useCounterCompensatoryRestTranslation,
  withCounterCompensatoryRestTranslation
] = defineTranslationService('counterCompensatoryRest')

export const [
  useCounterModulationTranslation,
  withCounterModulationTranslation
] = defineTranslationService('counterModulation')

export const [useActivitiesTranslation, withActivitiesTranslation] =
  defineTranslationService('activities')

export const [useTimeLockReportTranslation] =
  defineTranslationService('timeClockReport')

export const [useComponentsTranslation, withComponentsTranslation] =
  defineTranslationService('components')

export const [useWelcomeTranslation] = defineTranslationService('welcome')

export const [useMarketplaceTranslation] =
  defineTranslationService('marketplace')

export const [useElectronicSignatureTranslation] = defineTranslationService(
  'electronicSignature'
)

export const [useIaTranslation] = defineTranslationService('ia')
