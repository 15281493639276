import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_MODULATION_PERIOD_COUNTER,
  FETCH_MODULATION_PERIOD_COUNTER_SUCCEEDED,
  FETCH_MODULATION_PERIOD_COUNTER_FAILED,
  FETCH_MODULATION_PERIOD_COUNTERS,
  FETCH_MODULATION_PERIOD_COUNTERS_SUCCEEDED,
  FETCH_MODULATION_PERIOD_COUNTERS_FAILED,
  UPDATE_MODULATION_PERIOD_COUNTER,
  UPDATE_MODULATION_PERIOD_COUNTER_SUCCEEDED,
  UPDATE_MODULATION_PERIOD_COUNTER_FAILED,
  DELETE_MODULATION_PERIOD_COUNTER_FROM_STORE,
  FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION,
  FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION_SUCCEEDED,
  FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION_FAILED,
  FETCH_MODULATION_PERIOD_COUNTERS_BY_MEMBERSHIP,
  FETCH_MODULATION_PERIOD_COUNTERS_BY_MEMBERSHIP_SUCCEEDED,
  FETCH_MODULATION_PERIOD_COUNTERS_BY_MEMBERSHIP_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import {
  mergeRecords,
  updateRecord,
  deleteRecord
} from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'
import { subscribeToChannel, unsubscribeFromChannel } from '../socket'
import { isConnectedToChannel } from '../socket/selectors'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchModulationPeriodCounter = (
  modulationPeriodCounterId,
  params,
  resolve,
  reject
) => ({
  type: FETCH_MODULATION_PERIOD_COUNTER,
  modulationPeriodCounterId,
  params,
  resolve,
  reject
})

export const fetchModulationPeriodCounters = (
  userContractId,
  params,
  resolve,
  reject
) => ({
  type: FETCH_MODULATION_PERIOD_COUNTERS,
  userContractId,
  params,
  resolve,
  reject
})

export const fetchModulationPeriodCountersByLocation = (
  locationId,
  params,
  resolve,
  reject
) => ({
  type: FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION,
  locationId,
  params,
  resolve,
  reject
})

export const fetchModulationPeriodCountersByMembership = (
  membershipId,
  params,
  resolve,
  reject
) => ({
  type: FETCH_MODULATION_PERIOD_COUNTERS_BY_MEMBERSHIP,
  membershipId,
  params,
  resolve,
  reject
})

export const updateModulationPeriodCounter = (
  modulationPeriodCounterId,
  data,
  resolve,
  reject
) => ({
  type: UPDATE_MODULATION_PERIOD_COUNTER,
  modulationPeriodCounterId,
  data,
  resolve,
  reject
})

export const deleteModulationPeriodCounterFromStore = counterId => ({
  type: DELETE_MODULATION_PERIOD_COUNTER_FROM_STORE,
  counterId
})

// ------------------------------------
// Socket actions
// ------------------------------------

const CHANNEL_NAME = 'PlanningChannel'

export const isConnectedToModulationPeriodCounterChannel = state =>
  isConnectedToChannel(state, CHANNEL_NAME)
export const subscribeToModulationPeriodCounterChannel = (params = {}) =>
  subscribeToChannel(CHANNEL_NAME, params)
export const unsubscribeFromModulationPeriodCounterChannel = () =>
  unsubscribeFromChannel(CHANNEL_NAME)

// ------------------------------------
// Sagas
// ------------------------------------

const modulationPeriodCounterSagaEntity = {
  success: (data, modulationPeriodCounterId) => ({
    type: FETCH_MODULATION_PERIOD_COUNTER_SUCCEEDED,
    data,
    modulationPeriodCounterId
  }),
  failure: (error, modulationPeriodCounterId) => ({
    type: FETCH_MODULATION_PERIOD_COUNTER_FAILED,
    error,
    modulationPeriodCounterId
  }),

  fetchAPI: (modulationPeriodCounterId, options, params) =>
    callAPI(
      `/modulation_period_counters/${modulationPeriodCounterId}?${queryString.stringify(
        params
      )}`,
      options
    )
}

export function* doFetchModulationPeriodCounter({
  modulationPeriodCounterId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    modulationPeriodCounterSagaEntity,
    modulationPeriodCounterId,
    params,
    undefined,
    resolve,
    reject
  )
}

const modulationPeriodCountersSagaEntity = {
  success: (data, userContractId) => ({
    type: FETCH_MODULATION_PERIOD_COUNTERS_SUCCEEDED,
    data,
    userContractId
  }),
  failure: (error, userContractId) => ({
    type: FETCH_MODULATION_PERIOD_COUNTERS_FAILED,
    error,
    userContractId
  }),

  fetchAPI: (userContractId, options, params) =>
    callAPI(
      `/user_contracts/${userContractId}/modulation_period_counters?${queryString.stringify(
        params
      )}`,
      options
    )
}

export function* doFetchModulationPeriodCounters({
  userContractId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    modulationPeriodCountersSagaEntity,
    userContractId,
    params,
    undefined,
    resolve,
    reject
  )
}

const modulationPeriodCountersByLocationSagaEntity = {
  success: (data, locationId) => ({
    type: FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION_SUCCEEDED,
    data,
    locationId
  }),
  failure: (error, locationId) => ({
    type: FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION_FAILED,
    error,
    locationId
  }),

  fetchAPI: (locationId, options, params) =>
    callAPI(
      `/locations/${locationId}/modulation_period_counters?${queryString.stringify(
        { ...params }
      )}`,
      options
    )
}

export function* doFetchModulationPeriodCountersByLocation({
  locationId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    modulationPeriodCountersByLocationSagaEntity,
    locationId,
    params,
    undefined,
    resolve,
    reject
  )
}

const modulationPeriodCountersByMembershipSagaEntity = {
  success: (data, membershipId) => ({
    type: FETCH_MODULATION_PERIOD_COUNTERS_BY_MEMBERSHIP_SUCCEEDED,
    data,
    membershipId
  }),
  failure: (error, membershipId) => ({
    type: FETCH_MODULATION_PERIOD_COUNTERS_BY_MEMBERSHIP_FAILED,
    error,
    membershipId
  }),

  fetchAPI: (membershipId, options, params) =>
    callAPI(
      `/memberships/${membershipId}/modulation_period_counters?${queryString.stringify(
        params
      )}`,
      options
    )
}

export function* doFetchModulationPeriodCountersByMembership({
  membershipId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    modulationPeriodCountersByMembershipSagaEntity,
    membershipId,
    params,
    undefined,
    resolve,
    reject
  )
}

const updateModulationPeriodCounterSagaEntity = {
  success: (data, modulationPeriodCounterId) => ({
    type: UPDATE_MODULATION_PERIOD_COUNTER_SUCCEEDED,
    data,
    modulationPeriodCounterId
  }),
  failure: (error, modulationPeriodCounterId) => ({
    type: UPDATE_MODULATION_PERIOD_COUNTER_FAILED,
    error,
    modulationPeriodCounterId
  }),
  fetchAPI: (modulationPeriodCounterId, options) =>
    callAPI(`/modulation_period_counters/${modulationPeriodCounterId}`, {
      method: 'PUT',
      ...options
    })
}

export function* doUpdateModulationPeriodCounter({
  modulationPeriodCounterId,
  data,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    updateModulationPeriodCounterSagaEntity,
    modulationPeriodCounterId,
    null,
    data,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const ModulationPeriodCounter = Immutable.Record({
  id: null,
  user_contract_id: null,
  entries: null,
  modulation_period_id: null,
  balance_in_minutes: null,
  maximum_worked_hours_for_period: null,
  custom_maximum_worked_hours: null
})

const modulationPeriodCountersForeignKeys = [
  'user_contract_id',
  'modulation_period_id'
]

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function modulationPeriodCounters(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_MODULATION_PERIOD_COUNTER:
    case FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION:
    case FETCH_MODULATION_PERIOD_COUNTERS_BY_MEMBERSHIP:
    case FETCH_MODULATION_PERIOD_COUNTERS: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_MODULATION_PERIOD_COUNTER_FAILED:
    case FETCH_MODULATION_PERIOD_COUNTERS_FAILED:
    case FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION_FAILED:
    case FETCH_MODULATION_PERIOD_COUNTERS_BY_MEMBERSHIP_FAILED:
    case UPDATE_MODULATION_PERIOD_COUNTER_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_MODULATION_PERIOD_COUNTER_SUCCEEDED:
    case UPDATE_MODULATION_PERIOD_COUNTER_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const counter = Immutable.fromJS(action.data)

      return updateRecord(
        state,
        ModulationPeriodCounter,
        counter,
        // @ts-ignore migration from js(x) to ts(x)
        modulationPeriodCountersForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    case FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION_SUCCEEDED:
    case FETCH_MODULATION_PERIOD_COUNTERS_BY_MEMBERSHIP_SUCCEEDED:
    case FETCH_MODULATION_PERIOD_COUNTERS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const counters = Immutable.fromJS(action.data)

      return mergeRecords(
        state,
        ModulationPeriodCounter,
        counters,
        // @ts-ignore migration from js(x) to ts(x)
        modulationPeriodCountersForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    case DELETE_MODULATION_PERIOD_COUNTER_FROM_STORE: {
      // @ts-ignore migration from js(x) to ts(x)
      const { counterId } = action
      // @ts-ignore migration from js(x) to ts(x)
      return deleteRecord(state, counterId, modulationPeriodCountersForeignKeys)
    }
    default:
      return state
  }
}

export default filterActions(modulationPeriodCounters, [
  FETCH_MODULATION_PERIOD_COUNTER,
  FETCH_MODULATION_PERIOD_COUNTER_SUCCEEDED,
  FETCH_MODULATION_PERIOD_COUNTER_FAILED,
  FETCH_MODULATION_PERIOD_COUNTERS,
  FETCH_MODULATION_PERIOD_COUNTERS_SUCCEEDED,
  FETCH_MODULATION_PERIOD_COUNTERS_FAILED,
  FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION,
  FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION_SUCCEEDED,
  FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION_FAILED,
  FETCH_MODULATION_PERIOD_COUNTERS_BY_MEMBERSHIP,
  FETCH_MODULATION_PERIOD_COUNTERS_BY_MEMBERSHIP_SUCCEEDED,
  FETCH_MODULATION_PERIOD_COUNTERS_BY_MEMBERSHIP_FAILED,
  UPDATE_MODULATION_PERIOD_COUNTER_SUCCEEDED,
  UPDATE_MODULATION_PERIOD_COUNTER_FAILED,
  DELETE_MODULATION_PERIOD_COUNTER_FROM_STORE
])
