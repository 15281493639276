export const workPermitFormatter = (value: string): string | null => {
  if (value.includes('.permit_type')) {
    return 'work_permit_type'
  }
  if (value.includes('.number')) {
    return 'work_permit_number'
  }
  if (value.includes('.start_date')) {
    return 'work_permit_start_date'
  }
  if (value.includes('.authorization_date')) {
    return 'work_permit_authorization_date'
  }
  if (value.includes('.authorization_request_date')) {
    return 'work_permit_authorization_request_date'
  }
  if (value.includes('.expiration_date')) {
    return 'work_permit_expiration_date'
  }
  return null
}
