import type { JobSchema } from '@libs/data-access/entities'
import {
  appQueryClient,
  type NewApiErrorResponse,
  type UseAppMutationOptions
} from '@libs/data-access/queries'
import { useMutation } from '@tanstack/react-query'

import type { PostJobOptions } from '../jobs.api'
import { postJob } from '../jobs.api'
import { jobsCacheKeys } from '../jobs.cacheKeys'

export const usePostJob = (
  mutationOptions?: UseAppMutationOptions<
    JobSchema,
    NewApiErrorResponse,
    PostJobOptions
  >
) => {
  return useMutation<JobSchema, NewApiErrorResponse, PostJobOptions>({
    ...mutationOptions,
    mutationKey: jobsCacheKeys.post_job(),
    mutationFn: opts => postJob(opts),
    onSuccess: (data, variables, ...args) => {
      mutationOptions?.onSuccess?.(data, variables, ...args)
      appQueryClient.invalidateQueries({
        queryKey: jobsCacheKeys.fetch_jobs_invalidate()
      })
    }
  })
}
