import type { ChargebeeInvoiceSchema } from '@libs/data-access/entities'
import { useQuery } from '@tanstack/react-query'

import { queryClientOptions } from '../../../../provider'
import type { UseAppQueryOptions } from '../../../../types'
import { fetchAccountBasedInvoices } from '../accountInvoices.api'
import { accountInvoicesCacheKeys } from '../accountInvoices.cacheKeys'

export const useAccountBasedGetAccountInvoices = (
  chargebeeSubscriptionId: string,
  queryOptions?: UseAppQueryOptions<ChargebeeInvoiceSchema[]>
) => {
  return useQuery({
    ...queryOptions,
    queryKey: accountInvoicesCacheKeys.getByAccountBasedAccountId(),
    queryFn: () => fetchAccountBasedInvoices(chargebeeSubscriptionId),
    staleTime: queryClientOptions.time.INFINITY
  })
}
