import { useQuery } from '@tanstack/react-query'

import type { FetchLocationGroupEstimates } from '../locationGroupEstimates.api'
import { fetchLocationGroupEstimates } from '../locationGroupEstimates.api'
import { locationGroupEstimatesCacheKeys } from '../locationGroupEstimates.cacheKeys'

export const useGetLocationGroupEstimates = (
  request: FetchLocationGroupEstimates
) => {
  return useQuery({
    queryKey: locationGroupEstimatesCacheKeys.getByLocationGroupId(request),
    queryFn: () => fetchLocationGroupEstimates(request),
    staleTime: 1000
  })
}
