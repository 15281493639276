import { cva } from 'class-variance-authority'
import type { FC } from 'react'

import { LoaderOverlay } from './LoaderOverlay'
import type { LoaderWrapProps } from './LoaderWrap.types'

const loaderWrapCVA = {
  root: cva(['relative'])
}

export const LoaderWrap: FC<LoaderWrapProps> = ({
  loaderProps,
  className,
  loading = false,
  children,
  ...other
}) => {
  return (
    <div {...other} className={loaderWrapCVA.root({ className })}>
      {loading ? <LoaderOverlay loaderProps={loaderProps} /> : children}
    </div>
  )
}
