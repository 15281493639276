import type { Model } from '@libs/data-access/entities'
import { schema } from 'normalizr'
import type { Account as AccountSchema } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import Location from './Location'
import PayPeriod from './PayPeriod'

export type AccountModel = Model<
  AccountSchema,
  {
    locations: number[]
  }
>

const Account = new schema.Entity<AccountModel>('accounts', {
  locations: [Location],
  pay_periods: [PayPeriod]
})

export default Account
