import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call, put } from 'redux-saga/effects'

import {
  FETCH_SHIFTS,
  FETCH_SHIFTS_SUCCEEDED,
  FETCH_SHIFTS_FAILED,
  FETCH_SHIFT,
  FETCH_SHIFT_SUCCEEDED,
  FETCH_SHIFT_FAILED,
  FETCH_PAGINATED_SHIFTS,
  FETCH_PAGINATED_SHIFTS_SUCCEEDED,
  FETCH_PAGINATED_SHIFTS_FAILED,
  CREATE_SHIFT,
  CREATE_SHIFT_SUCCEEDED,
  CREATE_SHIFT_FAILED,
  CREATE_BULK_SHIFTS,
  CREATE_BULK_SHIFTS_SUCCEEDED,
  CREATE_BULK_SHIFTS_FAILED,
  VALIDATE_BULK_SHIFTS,
  VALIDATE_BULK_SHIFTS_SUCCEEDED,
  VALIDATE_BULK_SHIFTS_FAILED,
  INVALIDATE_BULK_SHIFTS,
  INVALIDATE_BULK_SHIFTS_SUCCEEDED,
  INVALIDATE_BULK_SHIFTS_FAILED,
  UPDATE_SHIFT,
  UPDATE_SHIFT_SUCCEEDED,
  UPDATE_SHIFT_FAILED,
  DELETE_SHIFT,
  DELETE_SHIFT_SUCCEEDED,
  DELETE_SHIFT_FAILED,
  DELETE_WEEKLYSCHEDULE_SUCCEEDED,
  UPDATE_WEEKLYSCHEDULE_SUCCEEDED,
  DELETE_CONTRACT_SUCCEEDED,
  DELETE_SHIFTS_FROM_STORE
} from '../../actionTypes'
import { NOT_FOUND } from '../../constants'
import {
  call as callAPI,
  batchPaginated,
  concatPromisesData
} from '../../infra/http'
import {
  mergeRecords,
  updateRecord,
  deleteRecord
} from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

import {
  FETCH_ALL_SHIFTS,
  FETCH_ALL_SHIFTS_SUCCESS,
  FETCH_ALL_SHIFTS_FAILED
} from './actionTypes'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchShifts = (accountId, params, resolve, reject) => ({
  type: FETCH_SHIFTS,
  accountId,
  params,
  resolve,
  reject
})

export const hydrateShifts = (accountId, data) => ({
  type: FETCH_SHIFTS_SUCCEEDED,
  data,
  accountId
})

export const fetchShift = (
  shiftId,
  params?: {},
  resolve?: any,
  reject?: any
) => ({
  type: FETCH_SHIFT,
  shiftId,
  params,
  resolve,
  reject
})

export const fetchPaginatedShifts = (accountId, params, resolve, reject) => ({
  type: FETCH_PAGINATED_SHIFTS,
  accountId,
  params,
  resolve,
  reject
})

export const createShift = (shiftData, resolve, reject) => ({
  type: CREATE_SHIFT,
  shiftData,
  resolve,
  reject
})

export const createBulkShifts = (shiftsData, resolve, reject) => ({
  type: CREATE_BULK_SHIFTS,
  shiftsData,
  resolve,
  reject
})

export const validateBulkShifts = (data, resolve, reject) => ({
  type: VALIDATE_BULK_SHIFTS,
  data,
  resolve,
  reject
})

export const invalidateBulkShifts = (data, resolve, reject) => ({
  type: INVALIDATE_BULK_SHIFTS,
  data,
  resolve,
  reject
})

export const updateShift = (shiftId, shiftData, resolve, reject) => ({
  type: UPDATE_SHIFT,
  shiftId,
  shiftData,
  resolve,
  reject
})

export const deleteShift = (shiftId, params, resolve, reject) => ({
  type: DELETE_SHIFT,
  params,
  shiftId,
  resolve,
  reject
})

export const deleteShiftsFromStore = shifts => ({
  type: DELETE_SHIFTS_FROM_STORE,
  shifts
})

export const fetchAllShifts = (
  accountId,
  { locationId, teamId, startDate, endDate, perPage }
) => ({
  type: FETCH_ALL_SHIFTS,
  payload: { accountId, locationId, teamId, startDate, endDate, perPage }
})

// ------------------------------------
// Sagas
// ------------------------------------

/**
 *
 * @param params { account_id, per_page, page, start_date, end_date }
 * @returns {Promise<any>}
 */
const fetchAll = params => {
  const { account_id, ...others } = params

  const query = queryString.stringify({
    ...others,
    per_page: others.per_page || 100
  })

  return callAPI(`/accounts/${account_id}/shifts?${query}`)
}

export function* doFetchAllShifts(action) {
  const {
    payload: { accountId, locationId, teamId, startDate, endDate, perPage }
  } = action

  const getParams = meta => {
    return [
      {
        account_id: accountId,
        location_id: locationId,
        start_date: startDate,
        end_date: endDate,
        per_page: perPage,
        page: (meta?.current_page || 0) + 1,
        ...(teamId && { team_id: teamId })
      }
    ]
  }

  // @TODO CHECK check for error handling
  try {
    const resolvedPromises = yield call(batchPaginated, fetchAll, getParams)
    const shifts = yield call(concatPromisesData, resolvedPromises, {
      dataAttributes: 'shifts'
    })

    yield put({ type: FETCH_ALL_SHIFTS_SUCCESS, payload: { shifts } })
  } catch (e) {
    yield put({
      type: FETCH_ALL_SHIFTS_FAILED,
      payload: { message: e }
    })
  }
}

const shiftsSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_SHIFTS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_SHIFTS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/shifts?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchShifts({ accountId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    shiftsSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

const shiftSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_SHIFT_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_SHIFT_FAILED,
    error,
    accountId
  }),

  fetchAPI: (shiftId, options) => callAPI(`/shifts/${shiftId}`, options)
}

export function* doFetchShift({ shiftId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    shiftSagaEntity,
    shiftId,
    params,
    undefined,
    resolve,
    reject
  )
}

const paginatedShiftsSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_PAGINATED_SHIFTS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_PAGINATED_SHIFTS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/shifts?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchPaginatedShifts({
  accountId,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    paginatedShiftsSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

const createShiftSagaEntity = {
  success: data => ({
    type: CREATE_SHIFT_SUCCEEDED,
    data
  }),
  failure: error => ({ type: CREATE_SHIFT_FAILED, error }),

  fetchAPI: (id, options) => callAPI('/shifts', { method: 'POST', ...options })
}

export function* doCreateShift({ shiftData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createShiftSagaEntity,
    null,
    null,
    shiftData,
    resolve,
    reject
  )
}

const createBulkShiftsSagaEntity = {
  success: data => ({
    type: CREATE_BULK_SHIFTS_SUCCEEDED,
    data
  }),
  failure: error => ({ type: CREATE_BULK_SHIFTS_FAILED, error }),

  fetchAPI: (id, options) =>
    callAPI('/shifts/bulk_create', { method: 'POST', ...options })
}

export function* doCreateBulkShifts({ shiftsData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createBulkShiftsSagaEntity,
    null,
    null,
    shiftsData,
    resolve,
    reject
  )
}

const validateBulkShiftsSagaEntity = {
  success: data => ({
    type: VALIDATE_BULK_SHIFTS_SUCCEEDED,
    data
  }),
  failure: error => ({ type: VALIDATE_BULK_SHIFTS_FAILED, error }),

  fetchAPI: (id, options) =>
    callAPI('/planning/shifts/bulk_validate', { method: 'POST', ...options })
}

export function* doValidateBulkShifts({ data, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    validateBulkShiftsSagaEntity,
    null,
    null,
    data,
    resolve,
    reject
  )
}

const invalidateBulkShiftsSagaEntity = {
  success: data => ({
    type: INVALIDATE_BULK_SHIFTS_SUCCEEDED,
    data
  }),
  failure: error => ({ type: INVALIDATE_BULK_SHIFTS_FAILED, error }),

  fetchAPI: (id, options) =>
    callAPI('/planning/shifts/bulk_invalidate', { method: 'POST', ...options })
}

export function* doInvalidateBulkShifts({ data, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    invalidateBulkShiftsSagaEntity,
    null,
    null,
    data,
    resolve,
    reject
  )
}

const updateShiftSagaEntity = {
  success: (data, shiftId) => ({ type: UPDATE_SHIFT_SUCCEEDED, data, shiftId }),
  failure: (error, shiftId) => ({ type: UPDATE_SHIFT_FAILED, error, shiftId }),

  fetchAPI: (shiftId, options) =>
    callAPI(`/shifts/${shiftId}`, { method: 'PUT', ...options })
}

export function* doUpdateShift({ shiftId, shiftData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    updateShiftSagaEntity,
    shiftId,
    null,
    shiftData,
    resolve,
    reject
  )
}

const deleteShiftSagaEntity = {
  success: (data, shiftId) => ({ type: DELETE_SHIFT_SUCCEEDED, data, shiftId }),
  failure: (error, shiftId) => ({ type: DELETE_SHIFT_FAILED, error, shiftId }),

  fetchAPI: (shiftId, options, params) =>
    callAPI(`/shifts/${shiftId}?${queryString.stringify(params)}`, {
      method: 'DELETE',
      ...options
    })
}

export function* doDeleteShift({ shiftId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    deleteShiftSagaEntity,
    shiftId,
    params,
    null,
    resolve,
    reject
  )
}
// ------------------------------------
// Models
// ------------------------------------

export const Shift = Immutable.Record({
  id: null,
  user_contract_id: null,
  weeklyschedule_id: null,
  label_id: null,
  locked_at: null,
  planification_type: null,

  breakduration: null,
  note: null,
  starts_at: null,
  ends_at: null,
  week: null,

  real_breakduration: null,
  real_note: null,
  real_starts_at: null,
  real_ends_at: null,
  witheld_from_pay: null,
  did_not_show: null,

  validated_at: null,
  validator_id: null,
  validator: null,

  real_hours_last_modified_by_employee_at: null,
  planned_hours_last_edited_at: null,
  planned_hours_last_edited_by_id: null,

  shift_events: Immutable.List(),
  shift_breaks: Immutable.List(),
  shift_meals: Immutable.List(),

  permissions: {
    can_validate: null,
    can_invalidate: null,
    can_update: null,
    can_delete: null
  }
})

const shiftForeignKeys = ['weeklyschedule_id', 'user_contract_id', 'week']

const getDuration = (start, end, paidBreaks, breakduration) =>
  (new Date(end).getTime() - new Date(start).getTime()) / (1000 * 60) -
  (paidBreaks ? 0 : breakduration)

export const getShiftDuration = (shift, paidBreaks) =>
  getDuration(shift.starts_at, shift.ends_at, paidBreaks, shift.breakduration)

export const getShiftRealDuration = (shift, paidBreaks) =>
  shift.did_not_show || !shift?.real_ends_at
    ? 0
    : getDuration(
        shift.real_starts_at,
        shift.real_ends_at,
        paidBreaks,
        shift.real_breakduration
      )

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map(),
  pagination: Immutable.Map()
})

function shifts(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case DELETE_SHIFT:
    case FETCH_ALL_SHIFTS:
    case FETCH_PAGINATED_SHIFTS:
    case FETCH_SHIFT:
    case FETCH_SHIFTS: {
      // @ts-ignore migration from js(x) to ts(x)
      return action.params?.silent === true
        ? state
        : // @ts-ignore migration from js(x) to ts(x)
          state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_ALL_SHIFTS_FAILED:
    case FETCH_PAGINATED_SHIFTS_FAILED:
    case FETCH_SHIFT_FAILED:
    case FETCH_SHIFTS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_ALL_SHIFTS_SUCCESS: {
      // @ts-ignore migration from js(x) to ts(x)
      const shifts = Immutable.fromJS(action.payload.shifts)

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Shift, shifts, shiftForeignKeys).mergeDeepIn(
        ['meta'],
        {
          loading: false,
          updated_at: Date.now(),
          success: true
        }
      )
    }
    case FETCH_SHIFTS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const shifts = Immutable.fromJS(action.data)

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Shift, shifts, shiftForeignKeys).mergeDeepIn(
        ['meta'],
        {
          loading: false,
          updated_at: Date.now(),
          success: true
        }
      )
    }
    case FETCH_SHIFT_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const shifts = Immutable.fromJS([action.data])

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Shift, shifts, shiftForeignKeys).mergeDeepIn(
        ['meta'],
        {
          loading: false,
          updated_at: Date.now(),
          success: true
        }
      )
    }
    case FETCH_PAGINATED_SHIFTS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const shifts = Immutable.fromJS(action.data.shifts)
      // @ts-ignore migration from js(x) to ts(x)
      const pagination = Immutable.fromJS(action.data.meta)

      // @ts-ignore migration from js(x) to ts(x)
      const currentPage = action.data.meta.current_page

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Shift, shifts, shiftForeignKeys).withMutations(
        map => {
          map.mergeDeepIn(['meta'], {
            loading: false,
            updated_at: Date.now(),
            success: true
          })
          map.mergeDeepIn(['pagination'], pagination)
          map.setIn(
            ['pagination', 'pages', currentPage],
            shifts.map(shift => shift.get('id')).toSet()
          )
        }
      )
    }
    case CREATE_SHIFT_SUCCEEDED:
    case UPDATE_SHIFT_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const shift = Immutable.fromJS(action.data)
      // @ts-ignore migration from js(x) to ts(x)
      return updateRecord(state, Shift, shift, shiftForeignKeys)
    }
    case CREATE_BULK_SHIFTS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const shifts = Immutable.fromJS(action.data)
      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Shift, shifts, shiftForeignKeys)
    }
    case DELETE_SHIFT_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { shiftId } = action
      // @ts-ignore migration from js(x) to ts(x)
      return deleteRecord(state, shiftId, shiftForeignKeys)
    }
    case DELETE_WEEKLYSCHEDULE_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { weeklyscheduleId } = action
      const shiftsIds =
        state.getIn(['relations', 'weeklyschedule_id', weeklyscheduleId]) ||
        Immutable.Set()

      return state
        .deleteIn(['relations', 'weeklyschedule_id', weeklyscheduleId])
        .withMutations(store => {
          shiftsIds.forEach(shiftId => {
            store.deleteIn(['data', shiftId])
          })
          store.updateIn(['relations', 'user_contract_id'], contractMap =>
            contractMap?.map(item_ids => item_ids.subtract(shiftsIds))
          )
          store.updateIn(['relations', 'week'], week =>
            week?.map(shiftsRelatedToWeek =>
              shiftsRelatedToWeek.subtract(shiftsIds)
            )
          )
        })
    }
    case UPDATE_WEEKLYSCHEDULE_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const shifts = Immutable.fromJS(action.data.shifts)

      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Shift, shifts, shiftForeignKeys)
    }
    case DELETE_SHIFT_FAILED: {
      const {
        // @ts-ignore migration from js(x) to ts(x)
        error: {
          error: { type }
        },
        // @ts-ignore migration from js(x) to ts(x)
        shiftId
      } = action

      // Handle already deleted shifts.
      const isAlreadyDeleted = type === NOT_FOUND
      if (isAlreadyDeleted) {
        // @ts-ignore migration from js(x) to ts(x)
        return deleteRecord(state, shiftId, shiftForeignKeys)
      }

      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case DELETE_CONTRACT_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { contractId } = action
      const shiftsIds =
        state.getIn(['relations', 'user_contract_id', contractId]) ||
        Immutable.Set()

      return state
        .deleteIn(['relations', 'user_contract_id', contractId])
        .withMutations(store => {
          if (shiftsIds.size) {
            shiftsIds.forEach(shiftId => {
              store.deleteIn(['data', shiftId])
            })
            store.updateIn(
              ['relations', 'weeklyschedule_id'],
              weeklyscheduleMap =>
                weeklyscheduleMap.map(item_ids => item_ids.subtract(shiftsIds))
            )
            store.updateIn(['relations', 'week'], week =>
              week.map(shiftsRelatedToWeek =>
                shiftsRelatedToWeek.subtract(shiftsIds)
              )
            )
          }
        })
    }
    case DELETE_SHIFTS_FROM_STORE: {
      // @ts-ignore migration from js(x) to ts(x)
      const shiftsToDelete = action.shifts
      return state.withMutations(store => {
        shiftsToDelete.forEach(shift =>
          // @ts-ignore migration from js(x) to ts(x)
          deleteRecord(store, shift.id, shiftForeignKeys)
        )
      })
    }
    default:
      return state
  }
}

export default filterActions(shifts, [
  FETCH_SHIFTS,
  FETCH_SHIFTS_FAILED,
  FETCH_SHIFTS_SUCCEEDED,
  FETCH_SHIFT,
  FETCH_SHIFT_FAILED,
  FETCH_SHIFT_SUCCEEDED,
  FETCH_PAGINATED_SHIFTS,
  FETCH_PAGINATED_SHIFTS_SUCCEEDED,
  FETCH_PAGINATED_SHIFTS_FAILED,
  CREATE_SHIFT_SUCCEEDED,
  CREATE_BULK_SHIFTS_SUCCEEDED,
  VALIDATE_BULK_SHIFTS,
  VALIDATE_BULK_SHIFTS_SUCCEEDED,
  INVALIDATE_BULK_SHIFTS,
  INVALIDATE_BULK_SHIFTS_SUCCEEDED,
  UPDATE_SHIFT_SUCCEEDED,
  DELETE_SHIFT_SUCCEEDED,
  DELETE_WEEKLYSCHEDULE_SUCCEEDED,
  UPDATE_WEEKLYSCHEDULE_SUCCEEDED,
  DELETE_CONTRACT_SUCCEEDED,
  DELETE_SHIFTS_FROM_STORE,
  FETCH_ALL_SHIFTS,
  FETCH_ALL_SHIFTS_FAILED,
  FETCH_ALL_SHIFTS_SUCCESS
])
