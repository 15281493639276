import Immutable from 'immutable'
import { filterActions } from 'redux-ignore'

import {
  FETCH_COUNTRY_MIN_WAGES,
  FETCH_COUNTRY_MIN_WAGES_SUCCEEDED,
  FETCH_COUNTRY_MIN_WAGES_FAILED
} from '../../actionTypes'
import { mergeRecords } from '../../services/immutableUtils'

// ------------------------------------
// Models
// ------------------------------------

export const CountryMinWage = Immutable.Record({
  id: null,
  country: null,
  start_date: null,
  end_date: null,
  value_in_cents: null,
  currency: null
})

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

const foreignKeys = ['country']

function reducer(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_COUNTRY_MIN_WAGES: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_COUNTRY_MIN_WAGES_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_COUNTRY_MIN_WAGES_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const minWages = Immutable.fromJS(action.data)

      return mergeRecords(
        state,
        CountryMinWage,
        minWages,
        // @ts-ignore migration from js(x) to ts(x)
        foreignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    default:
      return state
  }
}

export default filterActions(reducer, [
  FETCH_COUNTRY_MIN_WAGES,
  FETCH_COUNTRY_MIN_WAGES_SUCCEEDED,
  FETCH_COUNTRY_MIN_WAGES_FAILED
])

export * from './sagas'
export * from './actions'
