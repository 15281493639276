import type { FileWithPath } from '@mantine/dropzone'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'

import type { DropzoneProps } from '../Dropzone.types'

import { DropzoneFile } from './DropzoneFile'

const dropzoneFileListCVA = {
  root: cva(['flex w-full flex-col gap-y-2'], {
    variants: {
      size: {
        s: 'mt-0',
        m: 'mt-4'
      }
    }
  })
}

type DropzoneFileListProps = {
  accept: DropzoneProps['accept']
  files: FileWithPath[]
  deleteFile: (file: FileWithPath) => void
  disabled: DropzoneProps['disabled']
  size: DropzoneProps['size']
}

export const DropzoneFileList: FC<DropzoneFileListProps> = ({
  files,
  accept,
  deleteFile,
  disabled,
  size
}) => {
  return (
    <div className={dropzoneFileListCVA.root({ size })}>
      {files.map((file, index) => (
        <DropzoneFile
          key={index}
          file={file}
          onDelete={deleteFile}
          fileType={accept as string[]}
          disabled={disabled}
        />
      ))}
    </div>
  )
}
