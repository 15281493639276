export const buttonCompoundClasses = [
  /**
   * default intent
   */
  {
    disabled: true,
    class: 'bg-neutral-200 text-neutral-500'
  },
  {
    intent: 'default',
    variant: 'primary',
    disabled: false,
    class: `bg-brand-green-700 text-neutral-50
    hover:bg-brand-green-800
    active:bg-brand-green-900`
  },

  {
    intent: 'default',
    variant: 'secondary',
    disabled: false,
    class: `bg-brand-green-50 text-brand-green-700
    hover:bg-brand-green-100 hover:text-brand-green-800
    active:bg-brand-green-200 active:text-brand-green-900`
  },
  {
    intent: 'default',
    variant: 'tertiary',
    disabled: false,
    class: `bg-transparent text-brand-green-700
    hover:bg-brand-green-50 hover:text-brand-green-800
    active:bg-brand-green-100 active:text-brand-green-900`
  },
  /**
   * danger intent
   */
  {
    intent: 'danger',
    variant: 'primary',
    disabled: false,
    class: `bg-semantic-red-500 text-neutral-50
    hover:bg-semantic-red-600
    active:bg-semantic-red-700`
  },
  {
    intent: 'danger',
    variant: 'secondary',
    disabled: false,
    class: `bg-semantic-red-50 text-semantic-red-500
    hover:bg-semantic-red-100 hover:text-semantic-red-600
    active:bg-semantic-red-200 active:text-semantic-red-700`
  },
  {
    intent: 'danger',
    variant: 'tertiary',
    disabled: false,
    class: `bg-transparent text-semantic-red-500
    hover:bg-semantic-red-50 hover:text-semantic-red-600
    active:bg-semantic-red-100 active:text-semantic-red-700`
  },
  /**
   * neutral-dark intent
   */
  {
    intent: 'neutral-dark',
    variant: 'primary',
    disabled: false,
    class: `bg-neutral-900/80 text-neutral-75
    hover:bg-neutral-900/70
    active:bg-neutral-900/60`
  },
  {
    intent: 'neutral-dark',
    variant: 'secondary',
    disabled: false,
    class: `bg-neutral-200 text-neutral-800
    hover:bg-neutral-300 hover:text-neutral-800
    active:bg-neutral-400 active:text-neutral-800`
  },
  {
    intent: 'neutral-dark',
    variant: 'tertiary',
    disabled: false,
    class: `bg-transparent text-neutral-700
    hover:bg-neutral-700/10 hover:text-neutral-800
    active:bg-neutral-700/20 active:text-neutral-900`
  }
] as const
