import type { ReduxState } from '@spa/redux/ReduxState'

import type { CountryConfigurationsState } from '../reducer'

/**
 * @deprecated Use {@link accountService.countryConfiguration.useFetch} instead.
 */
export const getCountryConfiguration = (state: ReduxState) => {
  return state.countryConfigurations.data as CountryConfigurationsState['data']
}
