import type { MembershipSchema } from '@libs/data-access/entities'
import {
  appQueryClient,
  type ApiErrorResponse,
  type UseAppMutationOptions
} from '@libs/data-access/queries'
import { useMutation } from '@tanstack/react-query'

import type { UpdateMembershipPayloadOptions } from '../membership.api'
import { updateMembership } from '../membership.api'
import { membershipQueryKeys } from '../membership.cacheKeys'

export const useUpdateMembership = (
  mutationOptions?: UseAppMutationOptions<
    MembershipSchema,
    ApiErrorResponse,
    UpdateMembershipPayloadOptions
  >
) => {
  return useMutation<
    MembershipSchema,
    ApiErrorResponse,
    UpdateMembershipPayloadOptions
  >({
    ...mutationOptions,
    mutationKey: membershipQueryKeys.update_membership(),
    mutationFn: (opts: UpdateMembershipPayloadOptions) =>
      updateMembership(opts),
    onSuccess: (membershipData, ...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(membershipData, ...args)
      }
      appQueryClient.setQueryData(
        membershipQueryKeys.membership(membershipData.id),
        membershipData
      )
    }
  })
}
