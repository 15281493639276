import type { Model } from '@libs/data-access/entities'
import { schema } from 'normalizr'
import type {
  Shift as ShiftSchema,
  Break,
  Meal
} from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

export type ShiftModel = Model<ShiftSchema, {}>

const Shift = new schema.Entity<ShiftModel>('shifts', {})

export function create(data: Partial<ShiftModel>): ShiftModel {
  const original: ShiftModel = {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    week: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    note: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    starts_at: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    ends_at: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    real_note: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    real_starts_at: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    real_ends_at: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    planification_type: null,
    witheld_from_pay: false,
    did_not_show: false,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    meal_type: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    locked_at: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    real_hours_last_modified_by_employee_at: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    real_hours_declared_correct_at: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    planned_hours_last_edited_at: null,

    /* Relations */
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    rest_id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    planned_hours_last_edited_by_id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    user_contract_id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    weeklyschedule_id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    label_id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    validated_at: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    validator_id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    original_planning_id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    original_real_id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    partner_id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    account_id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    location_id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    team_id: null,
    shift_meals: [],
    shift_breaks: [],
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    updated_at: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    created_at: null
  }

  return {
    ...original,
    ...data
  }
}

export const createBreak = (data: Partial<Break>): Break => {
  const original: Break = {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    planned_duration: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    planned_ends_at: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    planned_starts_at: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    real_duration: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    real_ends_at: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    real_starts_at: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    shift_id: null
  }

  return {
    ...original,
    ...data
  }
}

export const createMeal = (data: Partial<Meal>): Meal => {
  const original: Meal = {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    planned_meal_type: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    real_meal_type: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    clocked_meal_taken_status: null
  }

  return {
    ...original,
    ...data
  }
}
export default Shift
