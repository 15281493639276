import type { MembershipSchema } from '@libs/data-access/entities'
import { useQuery } from '@tanstack/react-query'

import type { UseAppQueryOptions } from '../../../../types'
import type { FetchMembershipsOptions } from '../membership.api'
import { fetchMemberships } from '../membership.api'
import { membershipQueryKeys } from '../membership.cacheKeys'

export const useFetchMemberships = (
  request: FetchMembershipsOptions,
  queryOptions?: UseAppQueryOptions<MembershipSchema[]>
) => {
  return useQuery({
    ...queryOptions,
    queryKey: membershipQueryKeys.memberships(request?.queryParams),
    queryFn: () => fetchMemberships(request)
  })
}
