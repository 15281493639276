import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_ACCOUNT,
  FETCH_ACCOUNT_SUCCEEDED,
  FETCH_ACCOUNT_FAILED,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_SUCCEEDED,
  UPDATE_ACCOUNT_FAILED,
  UPDATE_ACCOUNT_REORDER,
  UPDATE_ACCOUNT_REORDER_SUCCEEDED,
  UPDATE_ACCOUNT_REORDER_FAILED,
  FETCH_MEMBERSHIP_SUCCEEDED,
  FETCH_USER_SUCCEEDED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords, updateRecord } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchAccount = (accountId, params, resolve, reject) => ({
  type: FETCH_ACCOUNT,
  accountId,
  params,
  resolve,
  reject
})

export const updateAccount = (accountId, accountData, resolve, reject) => ({
  type: UPDATE_ACCOUNT,
  accountId,
  accountData,
  resolve,
  reject
})

export const updateAccountReorder = (
  accountId,
  accountData,
  resolve,
  reject
) => ({
  type: UPDATE_ACCOUNT_REORDER,
  accountId,
  accountData,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const accountSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_ACCOUNT_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_ACCOUNT_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params = {}) =>
    callAPI(`/accounts/${accountId}?${queryString.stringify(params)}`, options)
}

export function* doFetchAccount({ accountId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    accountSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

const updateAccountSagaEntity = {
  success: (data, accountId, accountData) => ({
    type: UPDATE_ACCOUNT_SUCCEEDED,
    data,
    accountId,
    accountData
  }),
  failure: (error, accountId, accountData) => ({
    type: UPDATE_ACCOUNT_FAILED,
    error,
    accountId,
    accountData
  }),

  fetchAPI: (accountId, options) =>
    callAPI(`/accounts/${accountId}`, { method: 'PUT', ...options })
}

export function* doUpdateAccount({ accountId, accountData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    updateAccountSagaEntity,
    accountId,
    null,
    accountData,
    resolve,
    reject
  )
}

const updateAccountReorderSagaEntity = {
  success: (data, accountId, accountData) => ({
    type: UPDATE_ACCOUNT_REORDER_SUCCEEDED,
    data,
    accountId,
    accountData
  }),
  failure: (error, accountId, accountData) => ({
    type: UPDATE_ACCOUNT_REORDER_FAILED,
    error,
    accountId,
    accountData
  }),

  fetchAPI: (accountId, options) =>
    callAPI(`/accounts/${accountId}/memberships/reorder`, {
      method: 'PUT',
      ...options
    })
}

export function* doUpdateAccountReorder({
  accountId,
  accountData,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    updateAccountReorderSagaEntity,
    accountId,
    null,
    accountData,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const TimeClockPreferencesRecord = Immutable.Record({
  id: null,
  account_id: null,
  clock_in_threshold_in_minutes: 5,
  clock_out_threshold_in_minutes: 5,
  round_value_in_minutes: null,
  has_photo_enabled: false,
  use_event_time_as_real_hours: null,
  skip_for_daily_worker: false,
  use_clock_in_time_as_real_hour: false,
  use_clock_out_time_as_real_hour: false,
  require_comment_on_anomaly: null,
  allow_filling_meal: false,
  allow_filling_break_duration: true,
  allow_clock_in_without_shift: false,
  mobile_clock_in: false,
  mobile_clock_in_allowed_to_all: false,
  mobile_clock_in_allowed_memberships: Immutable.List([])
})

export const Account = Immutable.Record({
  id: null,
  collective_agreement: null,
  collective_agreement_attributes: null,
  name: null,
  social_tax: null,
  street: null,
  zip: null,
  city: null,
  country: null,
  logo: null,
  time_zone: null,
  subdomain: null,
  blocked: null,

  // Subscription
  premium: null,
  premium_status: null,
  trial: null,
  trial_end: null,
  current_term_ends_at: null,
  current_term_starts_at: null,
  type: null,
  plan: null,
  billing_plan: null,

  // Preferences
  cheat_sheet_hour_cut: null,
  employees_can_view_timesheets: null,
  employee_can_read_coworker_planning: null,
  print_orientation: null,
  display_sunday_for_planning_pdf: null,
  display_signature_for_planning_pdf: null,
  planning_pdf_display_mode: null,
  hr_dashboard_access_granted: null,
  paid_breaks: null,
  show_real_and_planned_hours_on_planning: null,
  show_contact_informations_in_member_list: null,
  manager_can_access_to_payroll: null,
  display_rewards_on_memberpage: null,
  manager_can_read_all_salaries: null,
  uses_timeclock: null,
  meal_by_default_for_real: null,
  send_activities_report_mail_to_owner: null,
  display_week_hours_for_planning_pdf: null,
  lock_meals: null,
  productivity_objective: null,
  currency_iso_code: null,
  display_shifts_from_other_teams: null,
  minimum_days_for_timeoff_request: null,
  allow_duplication_of_schedule_over_more_weeks: false,
  include_over_hours_in_wages: false,
  directors_can_access_dashboard_rh: false,
  directors_can_modify_pay_periods: false,
  director_can_read_paid_leave_dashboard: false,
  manager_can_read_paid_leave_dashboard: false,
  enabled_conversation: false,
  report_evened_out_by_default: false,
  quadra_paie_separator: '',
  manager_created_employees_plannable: false,

  employee_can_receive_sms_notification: false,

  payroll_software_id: 'default',
  prevent_overlapping_pay_periods: true,
  generate_regular_hours: false,
  allow_directors_to_schedule_on_all_locations: false,

  timeclock_preferences: TimeClockPreferencesRecord(),
  feature_flags: Immutable.Map(),
  features: Immutable.Map(),
  permissions: Immutable.Map(),
  has_contract_template: null,
  has_dpae: null,
  hide_counters_alerts_planning_to_daily_worker: null,
  automatically_generate_employee_ids: false,

  // Misc
  csm_info: Immutable.Map()
})

export const countryWithSmic = [
  'BL',
  'FR',
  'GF',
  'GP',
  'GY',
  'MF',
  'MQ',
  'PM',
  'RE'
]

export const getAccountHasSmic = account =>
  countryWithSmic.includes(account?.country)

export const getAccountCurrencyIsoCode = account => {
  if (!account) {
    return null
  }

  return account.get('currency_iso_code') === 'eur'
    ? '€'
    : account.get('currency_iso_code').toUpperCase()
}

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false, success: false, error: false }),
  relations: Immutable.Map()
})

const foreignKeys = ['subdomain']

function accounts(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case UPDATE_ACCOUNT_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case UPDATE_ACCOUNT_REORDER:
    case UPDATE_ACCOUNT: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], {
        // @ts-ignore migration from js(x) to ts(x)
        loading: (action.params || {}).silent !== true
      })
    }
    case UPDATE_ACCOUNT_REORDER_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: true })
    }
    case FETCH_ACCOUNT_SUCCEEDED:
    case UPDATE_ACCOUNT_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const account = Immutable.fromJS(action.data)
      // @ts-ignore migration from js(x) to ts(x)
      return updateRecord(state, Account, account, foreignKeys).mergeDeepIn(
        ['meta'],
        { loading: false, success: true }
      )
    }
    case FETCH_MEMBERSHIP_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const account = Immutable.fromJS(action.data.account)
      return updateRecord(state, Account, account)
    }
    case FETCH_USER_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const accounts = Immutable.fromJS(action.data.memberships || []).map(
        memberships => memberships.get('account')
      )
      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(state, Account, accounts, foreignKeys)
    }
    default:
      return state
  }
}

export default filterActions(accounts, [
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_REORDER,
  FETCH_ACCOUNT_SUCCEEDED,
  UPDATE_ACCOUNT_SUCCEEDED,
  UPDATE_ACCOUNT_REORDER_SUCCEEDED,
  FETCH_MEMBERSHIP_SUCCEEDED,
  FETCH_USER_SUCCEEDED,
  UPDATE_ACCOUNT_FAILED
])
