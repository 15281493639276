export type TailwindConfigTheme = {
  colors:
    | 'neutral'
    | 'semantic.blue'
    | 'semantic.orange'
    | 'semantic.green'
    | 'semantic.red'
    | 'brand.green'
    | 'brand.beige'
    | 'secondary.green'
    | 'secondary.orange'
    | 'secondary.coral'
    | 'secondary.purple'
    | 'secondary.blue'
  colorShade:
    | '50'
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900'
  screens: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
}

export const tailwindConfig = {
  theme: {
    fontFamily: {
      sans: 'Inter'
    },
    screens: {
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      '2xl': 1536
    }
  }
}
