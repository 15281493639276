import { MAZE_ID } from '@libs/utils/environments'
import { useEffect } from 'react'

export const useInitMaze = () => {
  useEffect(() => {
    if (MAZE_ID) {
      let mazeItem: string | null = window.sessionStorage?.getItem('maze-us')

      if (!mazeItem) {
        mazeItem = new Date().getTime().toString()
        window.sessionStorage?.setItem('maze-us', mazeItem)
      }

      const script = document.createElement('script')

      script.src = `https://snippet.maze.co/maze-universal-loader.js?t=${mazeItem}&apiKey=${MAZE_ID}`
      script.async = true
      document.getElementsByTagName('head')[0].appendChild(script)
      window.mazeUniversalSnippetApiKey = MAZE_ID
    }
  }, [])
}
