import { API_APPLICATION_HOST } from '@libs/utils/environments'
import { errorLoggerService } from '@libs/utils/error-monitoring'
import { reduce } from 'lodash-es'
import queryString from 'query-string'

import type { RoutesName, RoutesTypes } from '../routesConfig'
import { routesConfig } from '../routesConfig'

import { fetchResourceWindow, abort as abortFn } from './api.worker'

type PathParams = { [k: string]: string | number }
type QueryParams = { [k: string]: string | number | boolean }

type ApiServices = 'core' | 'billing' | 'file'

const serviceMap = {
  billing: `${API_APPLICATION_HOST}/gateway`,
  core: API_APPLICATION_HOST,
  file: API_APPLICATION_HOST
}

/**
 * Replace all matching url params
 * From:   /accounts/:id/users/:name
 * To:     /accounts/123/users/billy
 */
export const formatPathParams = (path: string, params: PathParams): string => {
  return reduce(
    params,
    (acc, value, key) => {
      const regex = new RegExp(`:${key}`, 'gi')
      return acc.replace(regex, String(value))
    },
    path
  )
}

/**
 *
 */
export const formatQueryParams = (
  path: string,
  queryParams: QueryParams
): string => {
  const hasQueryParams = Boolean(Object.keys(queryParams).length)
  const queryParamsStr = hasQueryParams
    ? `?${queryString.stringify(queryParams, { arrayFormat: 'bracket' })}`
    : ''

  return `${path}${queryParamsStr}`
}

export const formatPath = (
  path: string,
  opts: { params: PathParams; queryParams?: QueryParams }
) => {
  const formattedPathParams = formatPathParams(path, opts.params)
  return opts?.queryParams
    ? formatQueryParams(formattedPathParams, opts.queryParams)
    : formattedPathParams
}

/**
 * Retrieve specific route config
 * Format QueryParams, PathParams, body and request server API
 */
export const apiRequest = async <
  T extends RoutesName,
  RouteType extends RoutesTypes[T]
>(
  payload: {
    route: T
    service?: ApiServices
  } & RouteType['payload']
): Promise<RouteType['response']> => {
  const { path, method } = routesConfig[payload.route]

  const {
    params,
    queryParams,
    body,
    service = 'core',
    ...restOptions
  } = payload as any
  const formattedPath = formatPath(path, { params, queryParams })

  try {
    const res = await fetchResourceWindow(formattedPath, {
      ...restOptions,
      ...(body && { body: JSON.stringify(body) }),
      method,
      apiUrl: serviceMap[service],
      service
    })

    return res
  } catch (err) {
    errorLoggerService.apiError(err as any)
    throw err
  }
}

/**
 * Calls the given URL and returns the json object
 */
export const call = (...args) => {
  const [path, options] = args

  const { service = 'core' } = options || {}

  return fetchResourceWindow(path, {
    ...options,
    apiUrl: serviceMap[service]
  })
}

export const abort = () => abortFn()
