import { PencilIcon } from '@libs/ui/ds/assets/icons'
import { IconButton } from '@libs/ui/ds/lib/components/buttons/IconButton/base/IconButton'
import { Loader } from '@libs/ui/ds/lib/components/feedback/Loader/base/Loader'
import { DropdownMenu } from '@libs/ui/ds/lib/components/overlays/DropdownMenu/base/DropdownMenu'
import { Absolute } from '@libs/ui/ds/lib/components/primitiveLayouts/Absolute/base/Absolute'
import type { Size } from '@libs/ui/ds/lib/internals'
import { useDSTranslation } from '@libs/ui/ds/lib/internals'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import React, { useRef } from 'react'

import { useAvatarContext } from '../../Avatar.context'

import type { AvatarInputProps } from './AvatarInput.types'

const avatarInputCVA = {
  root: cva([`group absolute inline-flex size-full`], {
    variants: {
      loading: {
        true: 'pointer-events-none cursor-default'
      }
    }
  }),
  input: cva(['hidden']),
  placeholder: cva(['flex size-full items-center justify-center'], {
    variants: {
      loading: {
        true: 'visible',
        false: 'invisible'
      }
    }
  }),
  dropDownMenu: cva(
    [
      'invisible absolute z-10 translate-x-1/2 translate-y-1/2 transition-opacity duration-300 group-hover:visible'
    ],
    {
      variants: {
        size: {
          xxl: 'bottom-3 right-6',
          xl: 'bottom-1 right-3',
          l: 'bottom-0.5 left-1'
        }
      }
    }
  ),
  props: {
    iconSize: {
      l: 's',
      xl: 'm',
      xxl: 'l'
    },
    loaderSize: {
      l: 'xs',
      xl: 's',
      xxl: 's'
    },
    iconButtonSize: {
      l: 's',
      xl: 's',
      xxl: 'l'
    }
  }
} as const

export const AvatarInput: FC<AvatarInputProps> = ({
  className,
  children,
  onChange,
  onDelete,
  disablePreview,
  ...other
}) => {
  const ctx = useAvatarContext({ throwErrorIfNoContext: true })
  const size = (ctx?.size || 'l') as Size<'l' | 'xl' | 'xxl'>
  const inputRef = useRef<HTMLInputElement>(null)
  const { t } = useDSTranslation()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    event.preventDefault()
    const file = event.target.files?.[0]

    if (!file) {
      return
    }

    if (onChange) {
      onChange(file, event)
      event.target.value = ''
    }

    if (!disablePreview && file && ctx?.setPreview) {
      ctx.setPreview(URL.createObjectURL(file))
    }
  }

  const handleDelete = () => {
    if (onDelete && ctx?.setPreview) {
      onDelete()
      ctx.setPreview('')
    }
  }
  return (
    <div className={avatarInputCVA.root({ className, loading: ctx?.loading })}>
      <input
        ref={inputRef}
        disabled={ctx?.loading}
        className={avatarInputCVA.input()}
        onChange={handleChange}
        accept='image/*'
        type='file'
        {...other}
      />
      <Absolute
        className={avatarInputCVA.placeholder({
          loading: Boolean(ctx?.loading)
        })}
        x='center'
        y='center'
      >
        <Loader theme='dark' size={avatarInputCVA.props.loaderSize[size]} />
      </Absolute>
      {children}
      <div className={avatarInputCVA.dropDownMenu({ size })}>
        <DropdownMenu>
          <DropdownMenu.Target>
            <IconButton
              data-testid='avatar-input-icon-button'
              icon={<PencilIcon />}
              intent='neutral-dark'
              variant='secondary'
              size={avatarInputCVA.props.iconButtonSize[size]}
            />
          </DropdownMenu.Target>
          <DropdownMenu.Dropdown>
            <DropdownMenu.Option
              onClick={() => {
                inputRef.current?.click()
              }}
            >
              <p>{t('ds.components.avatarInput.upload')}</p>
            </DropdownMenu.Option>
            {ctx?.loadedImage && (
              <DropdownMenu.Option
                intent='danger'
                onClick={ctx?.loadedImage ? handleDelete : undefined}
              >
                {t('ds.components.avatarInput.remove')}
              </DropdownMenu.Option>
            )}
          </DropdownMenu.Dropdown>
        </DropdownMenu>
      </div>
      {children}
    </div>
  )
}
