import { useMutation } from '@tanstack/react-query'

import type { ApiErrorResponse, UseAppMutationOptions } from '../../../../types'
import { type BulkActionOptions, bulkDeleteShiftsList } from '../shift.bulk.api'
import { shiftBulkCacheKeys } from '../shift.bulk.cacheKeys'

export const useBulkDeleteShiftsList = (
  mutationOpts?: UseAppMutationOptions<
    void,
    ApiErrorResponse,
    BulkActionOptions
  >
) => {
  return useMutation<void, ApiErrorResponse, BulkActionOptions>({
    ...mutationOpts,
    mutationKey: shiftBulkCacheKeys.action.delete(),
    mutationFn: (opts: BulkActionOptions) => bulkDeleteShiftsList(opts)
  })
}
