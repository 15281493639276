import { controllersActions } from '@spa/scenes/Plannings/redux/modules/controllers'
import {
  getParams,
  weeklyschedulesSelectors
} from '@spa/scenes/Plannings/redux/selectors'
import { put, select, takeEvery } from 'redux-saga/effects'
import {
  DELETE_WEEKLYSCHEDULE_SUCCEEDED,
  UPDATE_WEEKLYSCHEDULE_SUCCEEDED
} from 'snap-redux/actionTypes'

function* weeklyscheduleSync(action: any) {
  const params = yield select(getParams)

  // @todo register module redux on planning v2 scene
  if (!params.date) {
    return
  }

  switch (action.type) {
    /*
     * // case CREATE_WEEKLYSCHEDULE_SUCCEEDED:
     *
     * Create weeklySchedule is used inside planning duplication
     * and this feature redirect to the plannings url so no need to handle it in sync params
     *
     */
    case DELETE_WEEKLYSCHEDULE_SUCCEEDED: {
      const { weeklyscheduleId } = action

      const weeklyschedule = yield select(state =>
        weeklyschedulesSelectors.getCachedById(state, weeklyscheduleId)
      )

      if (!weeklyschedule) {
        break
      }

      const params = yield select(getParams)

      yield put(
        controllersActions.planningRefresh({
          locationId: params.locationId,
          teamId: weeklyschedule.team_id,
          date: params.date
        })
      )

      break
    }

    case UPDATE_WEEKLYSCHEDULE_SUCCEEDED: {
      const { weeklyscheduleId } = action

      const weeklyschedule = yield select(state =>
        weeklyschedulesSelectors.getCachedById(state, weeklyscheduleId)
      )

      if (!weeklyschedule) {
        break
      }

      const params = yield select(getParams)

      yield put(
        controllersActions.planningSilentRefresh({
          locationId: params.locationId,
          teamId: weeklyschedule.team_id,
          date: params.date
        })
      )
      break
    }

    default:
      break
  }
}

export default function* weeklyscheduleWatcher() {
  yield takeEvery(
    [UPDATE_WEEKLYSCHEDULE_SUCCEEDED, DELETE_WEEKLYSCHEDULE_SUCCEEDED],
    weeklyscheduleSync
  )
}
