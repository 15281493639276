import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type ParamsState = {
  locationId: number
  teamId?: number
  date: 'YYYY-MM-DD' | string | null
  action?: 'delete' | 'validate' | 'invalidate' | null
}

const initialState: ParamsState = {
  locationId: 0,
  teamId: 0,
  date: null,
  action: null
}

const paramsSlice = createSlice({
  name: 'ui/planning/params',
  initialState,
  reducers: {
    setParams(state, action: PayloadAction<Partial<ParamsState>>) {
      return { ...state, ...action.payload }
    },
    clearParams() {
      return { ...initialState }
    }
  }
})

export const { setParams, clearParams } = paramsSlice.actions
export default paramsSlice.reducer
