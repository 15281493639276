import { i18nDSInstance } from '@libs/ui/ds/lib/internals'
import moment from 'moment'

import type {
  PickerPresetShape,
  PredefinedPickerPresetName
} from './PickerPresetList.types'

export const pickerPresets: Record<
  PredefinedPickerPresetName,
  () => PickerPresetShape
> = {
  today: () => ({
    start: moment().format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: i18nDSInstance.t('ds:ds.components.datePickers.presets.today.label')
  }),

  last3Days: () => ({
    start: moment().add(-3, 'day').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: i18nDSInstance.t(
      'ds:ds.components.datePickers.presets.last3Days.label'
    )
  }),

  last7Days: () => ({
    start: moment().add(-7, 'day').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: i18nDSInstance.t(
      'ds:ds.components.datePickers.presets.last7Days.label'
    )
  }),

  last15Days: () => ({
    start: moment().add(-15, 'day').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: i18nDSInstance.t(
      'ds:ds.components.datePickers.presets.last15Days.label'
    )
  }),

  last30Days: () => ({
    start: moment().add(-30, 'day').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: i18nDSInstance.t(
      'ds:ds.components.datePickers.presets.last30Days.label'
    )
  }),

  last90Days: () => ({
    start: moment().add(-90, 'day').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: i18nDSInstance.t(
      'ds:ds.components.datePickers.presets.last90Days.label'
    )
  }),

  last6Months: () => ({
    start: moment().add(-6, 'month').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: i18nDSInstance.t(
      'ds:ds.components.datePickers.presets.last6Months.label'
    )
  }),

  last12Months: () => ({
    start: moment().add(-12, 'month').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: i18nDSInstance.t(
      'ds:ds.components.datePickers.presets.last12Months.label'
    )
  }),

  next3Days: () => ({
    start: moment().format('YYYY-MM-DD'),
    end: moment().add(3, 'day').format('YYYY-MM-DD'),
    label: i18nDSInstance.t(
      'ds:ds.components.datePickers.presets.next3Days.label'
    )
  }),

  next7Days: () => ({
    start: moment().format('YYYY-MM-DD'),
    end: moment().add(7, 'day').format('YYYY-MM-DD'),
    label: i18nDSInstance.t(
      'ds:ds.components.datePickers.presets.next7Days.label'
    )
  }),

  next15Days: () => ({
    start: moment().format('YYYY-MM-DD'),
    end: moment().add(15, 'day').format('YYYY-MM-DD'),
    label: i18nDSInstance.t(
      'ds:ds.components.datePickers.presets.next15Days.label'
    )
  }),

  next30Days: () => ({
    start: moment().format('YYYY-MM-DD'),
    end: moment().add(30, 'day').format('YYYY-MM-DD'),
    label: i18nDSInstance.t(
      'ds:ds.components.datePickers.presets.next30Days.label'
    )
  }),

  next90Days: () => ({
    start: moment().format('YYYY-MM-DD'),
    end: moment().add(90, 'day').format('YYYY-MM-DD'),
    label: i18nDSInstance.t(
      'ds:ds.components.datePickers.presets.next90Days.label'
    )
  }),

  next6Months: () => ({
    start: moment().format('YYYY-MM-DD'),
    end: moment().add(6, 'month').format('YYYY-MM-DD'),
    label: i18nDSInstance.t(
      'ds:ds.components.datePickers.presets.next6Months.label'
    )
  }),

  next12Months: () => ({
    start: moment().format('YYYY-MM-DD'),
    end: moment().add(12, 'month').format('YYYY-MM-DD'),
    label: i18nDSInstance.t(
      'ds:ds.components.datePickers.presets.next12Months.label'
    )
  })
}
