import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_ACCOUNT_LOCATIONS,
  FETCH_ACCOUNT_LOCATIONS_SUCCEEDED,
  FETCH_ACCOUNT_LOCATIONS_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'
import { Location } from '../locations'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchAccountLocations = (params, resolve, reject) => ({
  type: FETCH_ACCOUNT_LOCATIONS,
  params,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const locationsSagaEntity = {
  success: data => ({
    type: FETCH_ACCOUNT_LOCATIONS_SUCCEEDED,
    data
  }),
  failure: error => ({
    type: FETCH_ACCOUNT_LOCATIONS_FAILED,
    error
  }),

  fetchAPI: (_, options, params) =>
    callAPI(`/locations?${queryString.stringify(params)}`, options)
}

export function* doFetchAccountLocations({ params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    locationsSagaEntity,
    null,
    params,
    undefined,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------
//

// Use the Location existing Record.
const locationForeignKeys = ['account_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function locations(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_ACCOUNT_LOCATIONS: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_ACCOUNT_LOCATIONS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_ACCOUNT_LOCATIONS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const locations = Immutable.fromJS(action.data)

      return mergeRecords(
        state,
        Location,
        locations,
        // @ts-ignore migration from js(x) to ts(x)
        locationForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    default:
      return state
  }
}

export default filterActions(locations, [
  FETCH_ACCOUNT_LOCATIONS,
  FETCH_ACCOUNT_LOCATIONS_SUCCEEDED,
  FETCH_ACCOUNT_LOCATIONS_FAILED
])
