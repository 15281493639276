import { useQuery } from '@tanstack/react-query'
import type { UseAppQueryOptions } from 'libs/data-access/queries/src/lib/types'

import type { FetchTimeoffEligibilityForDateOptions } from '../membership.timeoff.api'
import { fetchTimeoffEligibilityForDate } from '../membership.timeoff.api'
import { membershipTimeoffQueryKeys } from '../membership.timeoff.cacheKeys'
import type { MembershipTimeoffEligibilitySchema } from '../types/membership.timeoff.schema'

export const useFetchTimeoffEligibilityForDate = (
  request: FetchTimeoffEligibilityForDateOptions,
  queryOptions?: UseAppQueryOptions<MembershipTimeoffEligibilitySchema>
) =>
  useQuery({
    ...queryOptions,
    queryKey: membershipTimeoffQueryKeys.timeoff_eligibility({
      ...request?.pathParams,
      ...request?.queryParams
    }),
    queryFn: () => fetchTimeoffEligibilityForDate(request)
  })
