import { useMutation } from '@tanstack/react-query'

import type { UseAppMutationOptions, ApiErrorResponse } from '../../../types'
import type { PostUserSignupOptions } from '../auth.api'
import { postUserSignup } from '../auth.api'
import { authCacheKeys } from '../auth.cacheKeys'
import type { PostUserSignupResponse } from '../types/UserSignup.post'

export const usePostUserSignup = (
  mutationOptions?: UseAppMutationOptions<
    PostUserSignupResponse,
    ApiErrorResponse,
    PostUserSignupOptions
  >
) => {
  return useMutation({
    ...mutationOptions,
    mutationKey: authCacheKeys.postUserSignup(),
    mutationFn: (opts: PostUserSignupOptions) => postUserSignup(opts),

    onSuccess: (data, variables, ...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, ...args)
      }
    }
  })
}
