import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_PAY_PERIODS,
  FETCH_PAY_PERIODS_SUCCEEDED,
  FETCH_PAY_PERIODS_FAILED,
  FETCH_PAY_PERIOD,
  FETCH_PAY_PERIOD_SUCCEEDED,
  FETCH_PAY_PERIOD_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords, updateRecord } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchPayPeriods = (accountId, params, resolve, reject) => ({
  type: FETCH_PAY_PERIODS,
  accountId,
  params,
  resolve,
  reject
})

export const fetchPayPeriod = (payPeriodId, params, resolve, reject) => ({
  type: FETCH_PAY_PERIOD,
  payPeriodId,
  params,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const payPeriodsSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_PAY_PERIODS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_PAY_PERIODS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (accountId, options, params) =>
    callAPI(
      `/accounts/${accountId}/pay_periods?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchPayPeriods({ accountId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    payPeriodsSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

const payPeriodSagaEntity = {
  success: (data, payPeriodId) => ({
    type: FETCH_PAY_PERIOD_SUCCEEDED,
    data,
    payPeriodId
  }),
  failure: (error, payPeriodId) => ({
    type: FETCH_PAY_PERIOD_FAILED,
    error,
    payPeriodId
  }),

  fetchAPI: (payPeriodId, options, params) =>
    callAPI(
      `/pay_periods/${payPeriodId}?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchPayPeriod({ payPeriodId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    payPeriodSagaEntity,
    payPeriodId,
    params,
    undefined,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const PayPeriod = Immutable.Record({
  id: null,
  start_date: null,
  end_date: null,
  account_id: null,
  locked_at: null,
  locked_by_id: null,
  principal: null,
  has_ongoing_unscheduled_shifts_locking_warning: null,
  principal_pay_period_id: null,
  can_edit_pay_period: null,
  intersect_modulation: null,
  alternative_pay_periods: null,
  contract_filters: Immutable.Map(),
  title: null
})

const foreignKeys = ['account_id', 'principal_pay_period_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  pagination: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function payPeriods(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_PAY_PERIOD:
    case FETCH_PAY_PERIODS: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_PAY_PERIOD_FAILED:
    case FETCH_PAY_PERIODS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_PAY_PERIODS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const principalPeriods = action.data.pay_periods
      // @ts-ignore migration from js(x) to ts(x)
      const alternativePeriods = action.data.pay_periods.flatMap(
        pp => pp.alternative_pay_periods
      )
      const periods = Immutable.fromJS([
        ...principalPeriods,
        ...alternativePeriods
      ])
      // @ts-ignore migration from js(x) to ts(x)
      const pagination = Immutable.fromJS(action.data.meta)

      // We override the existing state by using the initial state because we want to always have
      // the newest data.
      // @ts-ignore migration from js(x) to ts(x)
      return mergeRecords(initialState, PayPeriod, periods, foreignKeys)
        .mergeDeepIn(['meta'], {
          loading: false,
          updated_at: Date.now(),
          success: true
        })
        .mergeDeepIn(['pagination'], pagination)
    }
    case FETCH_PAY_PERIOD_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const payPeriod = Immutable.fromJS(action.data)
      // @ts-ignore migration from js(x) to ts(x)
      return updateRecord(state, PayPeriod, payPeriod, foreignKeys).mergeDeepIn(
        ['meta'],
        {
          loading: false,
          updated_at: Date.now(),
          success: true
        }
      )
    }
    default:
      return state
  }
}

export default filterActions(payPeriods, [
  FETCH_PAY_PERIODS,
  FETCH_PAY_PERIODS_FAILED,
  FETCH_PAY_PERIODS_SUCCEEDED,
  FETCH_PAY_PERIOD,
  FETCH_PAY_PERIOD_FAILED,
  FETCH_PAY_PERIOD_SUCCEEDED
])
