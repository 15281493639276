import type { DeleteBulkShiftsResponse } from '@libs/data-access/queries'
import { shiftService } from '@libs/data-access/queries'
import type { ReduxState } from '@spa/redux/ReduxState'
import { select } from 'redux-saga/effects'

import { contractsSelectors, getParams } from '../../../../selectors'
import fetchPlannablesSideEffectsEntities from '../../../controllers/request/fetchPlannableSideEffectsEntities'
import type { PayloadActionWithoutType } from '../../../types/types'

type Params = PayloadActionWithoutType<
  Partial<{
    contractId: number
    day: string
    teamId: number
    weeklyscheduleId: number
  }>
>

function* bulkDeleteShifts({ payload }: Params) {
  const params = yield select(getParams)
  const contract: any = yield select((state: ReduxState) =>
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    contractsSelectors.getCachedById(state, payload.contractId)
  )

  const res: DeleteBulkShiftsResponse =
    yield shiftService.bulk.api.deleteBulkShifts({
      queryParams: {
        date: payload.day,
        membership_id: contract?.membership_id,
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        weeklyschedule_id: payload.weeklyscheduleId
      }
    })

  yield fetchPlannablesSideEffectsEntities({
    contractIds: res.data.resources.map(i => i.contract_id),
    date: params.date,
    countersParams: { live: true }
  })
}

export default bulkDeleteShifts
