import { forwardRef, memo } from 'react'

import { InlineInputWrap } from '../../../forms/InlineInputWrap/base/InlineInputWrap'

import type { CheckboxProps } from './Checkbox.types'
import { CheckboxGroup } from './compound/CheckboxGroup/CheckboxGroup'
import { useMergedCheckboxProps } from './compound/CheckboxGroup/CheckboxGroup.context'
import { CheckboxInput } from './subs/CheckboxInput'

export const BaseCheckbox = memo(
  forwardRef<HTMLInputElement, CheckboxProps>(
    (
      {
        disabled,
        name,
        className,
        checked,
        onChange,
        size = 'm',
        label,
        desc,
        value,
        indeterminate,
        errorMessage,
        required,
        position,
        fillWidth,
        disableLabelInteraction,
        ...other
      },
      ref
    ) => {
      const mergedProps = useMergedCheckboxProps({
        value,
        disabled,
        checked,
        name,
        size,
        onChange
      })

      return (
        <InlineInputWrap
          className={className}
          label={label}
          desc={desc}
          errorMessage={errorMessage}
          htmlFor={mergedProps.name}
          required={required}
          fillWidth={fillWidth}
          position={position}
          disabled={mergedProps.disabled}
          disableLabelInteraction={disableLabelInteraction}
          field={
            <CheckboxInput
              {...other}
              ref={ref}
              id={mergedProps.name}
              required={required}
              value={value}
              checked={mergedProps.checked}
              name={mergedProps.name}
              disabled={mergedProps.disabled}
              onChange={mergedProps.onChange}
              size={mergedProps.size}
              indeterminate={indeterminate}
            />
          }
        />
      )
    }
  )
)

type CheckboxCompoundType = {
  Group: typeof CheckboxGroup
  InputBox: typeof CheckboxInput
}

const TypedCheckbox = BaseCheckbox as typeof BaseCheckbox & CheckboxCompoundType

TypedCheckbox.displayName = 'Checkbox'
TypedCheckbox.Group = CheckboxGroup
TypedCheckbox.InputBox = CheckboxInput

export const Checkbox = TypedCheckbox
export type { CheckboxProps } from './Checkbox.types'
export type { CheckboxInputProps } from './subs/CheckboxInput.types'
export type { CheckboxGroupProps } from './compound/CheckboxGroup/CheckboxGroup.types'
