import type { ReduxState } from '@spa/redux/ReduxState'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'

import { contractsBaseSelectors } from '../contractsBaseSelectors'

type Params = {
  teamId: number
}

export const getContractsByTeamId = createCachedSelector(
  (_: ReduxState, { teamId }: Params) => teamId,
  contractsBaseSelectors.getCachedList,

  (teamId, contracts) => {
    return contracts.filter(contract => contract.team_ids.includes(teamId))
  }
)((_, { teamId }) => fallbackToDefaultCacheKey(teamId))
