export const ArrowRightIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.54666 0.0113038C4.2917 0.0599038 4.07506 0.224984 3.96003 0.45832C3.89523 0.589784 3.88475 0.640216 3.88489 0.819984C3.88499 0.96212 3.8864 0.976544 3.90655 1.04229C3.92967 1.11769 3.96879 1.20113 4.01425 1.27198C4.03262 1.30062 5.55847 2.83241 8.38321 5.65798L12.7239 9.99998L8.38321 14.342C5.55847 17.1676 4.03262 18.6994 4.01425 18.728C3.96879 18.7988 3.92967 18.8823 3.90655 18.9577C3.8864 19.0234 3.88499 19.0378 3.88489 19.18C3.88475 19.3618 3.89519 19.4103 3.96407 19.548C4.08334 19.7865 4.31422 19.9543 4.57533 19.9924C4.6627 20.0051 4.80761 19.9978 4.89564 19.9761C4.977 19.9561 5.09545 19.9014 5.16281 19.8527C5.23607 19.7998 14.0984 10.9315 14.1574 10.852C14.4435 10.4671 14.5172 9.96748 14.3546 9.51598C14.3035 9.3741 14.2421 9.26138 14.1452 9.13198C14.0976 9.06845 13.1721 8.13874 9.64863 4.61508C5.70896 0.675168 5.20438 0.173272 5.14399 0.134416C5.06039 0.0806238 4.97901 0.0445038 4.89326 0.0231118C4.80828 0.00191175 4.62814 -0.00422425 4.54666 0.0113038Z'
        fill='currentColor'
      />
    </svg>
  )
}
