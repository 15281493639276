import { useUncontrolled } from '@libs/ui/ds'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import { memo } from 'react'

import { TabsProvider } from './Tabs.context'
import type { TabsProps } from './Tabs.types'
import { TabsList } from './compound/TabsList/TabsList'
import { TabsPanel } from './compound/TabsPanel/TabsPanel'

const tabsCVA = {
  root: cva(['w-full'])
}

export const BaseTabs: FC<TabsProps> = memo(
  ({ className, defaultValue, children, onChange, value, ...other }) => {
    const [state, setState] = useUncontrolled({
      defaultValue,
      value,
      onChange
    })

    return (
      <TabsProvider
        value={{
          value: state,
          onChange: setState as TabsProps['onChange']
        }}
      >
        <div {...other} className={tabsCVA.root({ className })}>
          {children}
        </div>
      </TabsProvider>
    )
  }
)

type CompoundTabsType = {
  List: typeof TabsList
  Panel: typeof TabsPanel
}

const Typed = BaseTabs as typeof BaseTabs & CompoundTabsType
Typed.displayName = 'Tabs'

Typed.List = TabsList
Typed.Panel = TabsPanel
export const Tabs = Typed

export type { TabsProps } from './Tabs.types'
export type {
  TabsListProps,
  TabsListItem
} from './compound/TabsList/TabsList.types'
export type { TabsPanelProps } from './compound/TabsPanel/TabsPanel.types'
