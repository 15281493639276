import type { ShiftSchema } from '@libs/data-access/entities'

import { httpServices } from '../../../apis'
import type { QueryFnParams } from '../../../types'

/**
 * Fetch one shift
 *
 */
export type FetchShiftOptions = QueryFnParams<{
  pathParams: { id: number }
}>

export const fetchShift = async (opts: FetchShiftOptions) => {
  const response = await httpServices.core.get<ShiftSchema>(
    `/shifts/${opts?.pathParams.id}`
  )
  return response.data
}

/**
 * Fetch multiple shifts
 *
 */
export type FetchShiftsOptions = QueryFnParams<{
  queryParams: {
    membership_id: number
    user_contract_id: number
    location_id: number
    team_id: number
    start_date: 'YYYY-MM-DD' | string
    end_date: 'YYYY-MM-DD' | string
    'membership_ids[]': number[]
    include_without_end_date: boolean
  }
}>

export const fetchShifts = async (opts: FetchShiftsOptions) => {
  const response = await httpServices.core.get<ShiftSchema>(
    `/accounts/${httpServices.authInfo.accountId}/shifts`,
    {
      params: opts?.queryParams
    }
  )
  return response.data
}

export type PostShiftOptions = QueryFnParams<{
  payload: Partial<ShiftSchema>
}>

export const postShift = async (opts: PostShiftOptions) => {
  const response = await httpServices.core.post(`/shifts`, opts.payload)
  return response.data
}
