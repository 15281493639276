import { useMutation } from '@tanstack/react-query'

import type { ApiErrorResponse, UseAppMutationOptions } from '../../../types'
import type {
  GetSignupCheckExistingEmailOptions,
  PostSignupResendInvitationOptions
} from '../auth.api'
import { postSignupResendInvitation } from '../auth.api'
import { authCacheKeys } from '../auth.cacheKeys'

export const usePostSignupResendInvitation = (
  mutationOptions?: UseAppMutationOptions<
    null,
    ApiErrorResponse,
    GetSignupCheckExistingEmailOptions
  >
) => {
  return useMutation({
    ...mutationOptions,
    mutationKey: authCacheKeys.postSignupResendInvitation(),
    mutationFn: (opts: PostSignupResendInvitationOptions) =>
      postSignupResendInvitation(opts),

    onSuccess: (data, variables, ...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, ...args)
      }
    }
  })
}
