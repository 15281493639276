import type {
  AccountLocationGroupHostedPage,
  AccountLocationGroupInvoice,
  AccountLocationGroupNotPaidInvoice,
  ChargebeeInvoiceSchema
} from '@libs/data-access/entities'

import { httpServices } from '../../../apis'
import type {
  PaginatedQueryRequest,
  PaginatedQueryResponse,
  QueryFnParams
} from '../../../types'

export type FetchInvoicesOptions = QueryFnParams<{
  queryParams: PaginatedQueryRequest
}>

export const fetchInvoices = async (options: FetchInvoicesOptions = {}) => {
  const response = await httpServices.billing.get<
    PaginatedQueryResponse<AccountLocationGroupInvoice>
  >('/location-based/invoices', {
    params: {
      ...options.queryParams,
      snap_app_account_id: httpServices.authInfo.accountId
    }
  })
  return response.data
}

export const fetchNotPaidInvoices = async () => {
  const response = await httpServices.billing.get<
    AccountLocationGroupNotPaidInvoice[]
  >('/location-based/invoices/not_paid', {
    params: {
      snap_app_account_id: httpServices.authInfo.accountId
    }
  })
  return response.data
}

export const fetchInvoiceCollectNow = async (chargebeeCustomerId: string) => {
  const response =
    await httpServices.billing.get<AccountLocationGroupHostedPage>(
      '/location-based/invoices/collect_now',
      {
        params: {
          customer_id: chargebeeCustomerId
        }
      }
    )
  return response.data
}

export const fetchAccountBasedInvoices = async (
  chargebeeSubscriptionId: string
) => {
  const response = await httpServices.billing.get<ChargebeeInvoiceSchema[]>(
    '/v1/invoices',
    {
      params: {
        chargebee_subscription_id: chargebeeSubscriptionId
      }
    }
  )
  return response.data
}
