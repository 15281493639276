import type { PayloadAction } from '@reduxjs/toolkit'
import { SagaType, createSliceSaga } from 'redux-toolkit-saga'

import convertDocumentForSignatureData from '../generator/convertDocumentForSignature'
import deleteDocumentSignatureData from '../generator/deleteDocumentSignature'
import downloadDocumentProofSignatureData from '../generator/downloadDocumentProofSignature'
import downloadDocumentSignatureData from '../generator/downloadDocumentSignature'
import fetchDocumentSignatureData from '../generator/fetchDocumentSignature'
import getDocumentB64Data from '../generator/getDocumentB64'
import postDocumentSignatureData from '../generator/postDocumentSignature'
import postRemindDocumentSignatureData from '../generator/postRemindDocumentSignature'

import type {
  ParamsDownloadDocument,
  ParamsFetchDocument,
  ParamsPostDocument,
  ParamsReminderDocument,
  ParamsConvertDocument,
  ParamsGetDocumentB64,
  ParamsDeleteDocumentSignature,
  ParamsDownloadProofDocument
} from './entities'

const documentSignatureSagaSlice = createSliceSaga({
  name: 'emp/documentSignature/sagas',
  caseSagas: {
    /**
     * Post document for electornic signatures with all the signatories and information needed.
     */
    *postDocumentSignature({ payload }: PayloadAction<ParamsPostDocument>) {
      yield postDocumentSignatureData(payload)
    },
    /**
     * Fetch all the documents in the database used for the e-signature in a paginated manner.
     */
    *fetchDocumentSignature({ payload }: PayloadAction<ParamsFetchDocument>) {
      yield fetchDocumentSignatureData(payload)
    },
    /**
     * Download the document used for the e-signature based by the document_id.
     */
    *downloadDocumentSignature({
      payload
    }: PayloadAction<ParamsDownloadDocument>) {
      yield downloadDocumentSignatureData(payload)
    },
    /**
     * Download the proof of signature for a document already signed based by the document_id.
     */
    *downloadDocumentProofSignature({
      payload
    }: PayloadAction<ParamsDownloadProofDocument>) {
      yield downloadDocumentProofSignatureData(payload)
    },
    /**
     * Remind all the signers of the document used for the e-signature based by the document_id.
     */
    *remindDocumentSignature({
      payload
    }: PayloadAction<ParamsReminderDocument>) {
      yield postRemindDocumentSignatureData(payload)
    },
    /**
     * POST method send to the back to launch the worker for the convertion of a document to PDF.
     */
    *convertDocumentForSignature({
      payload
    }: PayloadAction<ParamsConvertDocument>) {
      yield convertDocumentForSignatureData(payload)
    },
    /**
     * Retreieve the B64 of the document that was converted to PDF.
     */
    *getDocumentB64({ payload }: PayloadAction<ParamsGetDocumentB64>) {
      yield getDocumentB64Data(payload)
    },
    /**
     * Delete the signature of a document.
     */
    *deleteSignature({
      payload
    }: PayloadAction<ParamsDeleteDocumentSignature>) {
      yield deleteDocumentSignatureData(payload)
    }
  },
  sagaType: SagaType.TakeLatest
})

export const {
  postDocumentSignature,
  fetchDocumentSignature,
  downloadDocumentSignature,
  downloadDocumentProofSignature,
  remindDocumentSignature,
  convertDocumentForSignature,
  getDocumentB64,
  deleteSignature
} = documentSignatureSagaSlice.actions

export default documentSignatureSagaSlice.saga()
