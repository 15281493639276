import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type MetaState = {
  error: boolean
  success: boolean
  loading: boolean
  rawError: {
    message: string
    details: {
      attribute: any
      id: number
      message: string
      resource: string
      value: any
    }[]
  }
}

const initialState: MetaState = {
  error: false,
  success: false,
  loading: false,
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  rawError: null
}

const paramsSlice = createSlice({
  name: 'emp/paidLeavesLedger/meta',
  initialState,
  reducers: {
    setMetaError(_, error: PayloadAction<{ rawError: any }>) {
      return {
        error: true,
        success: false,
        loading: false,
        rawError: error.payload.rawError
      }
    },
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    setMetaSuccess() {
      return {
        error: false,
        success: true,
        loading: false,
        rawError: null
      }
    },
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    setMetaLoading() {
      return {
        error: false,
        success: false,
        loading: true,
        rawError: null
      }
    },
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    resetMeta() {
      return {
        error: false,
        success: false,
        loading: false,
        rawError: null
      }
    }
  }
})

export const { setMetaError, setMetaSuccess, setMetaLoading, resetMeta } =
  paramsSlice.actions
export default paramsSlice.reducer
