import { Button, Flex, Image, imagesUrls, Text } from '@libs/ui/ds'
import { useAuthTranslation } from '@libs/utils/translations'
import { routes } from '@spa/core/router'
import { LanguageSelect } from '@spa/features/i18n'
import { cva } from 'class-variance-authority'
import classNames from 'classnames'
import type { FC } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'

const authNavbarCVA = {
  root: cva([
    'w-full items-center justify-between gap-4 bg-neutral-50 px-4 py-6 sm:px-8'
  ]),
  alreadyAnAccount: cva(['hidden text-center text-s text-neutral-600 sm:block'])
}

/**
 * Check if the current path is a signup flow page
 */
const useIsSignupFlow = () => {
  const location = useLocation()
  return (
    matchPath(routes.signUp.rootPath, location.pathname) ||
    matchPath(routes.signUpInvitation.rootPath, location.pathname) ||
    matchPath(routes.signUpCompany.rootPath, location.pathname)
  )
}

type AuthNavbarType = {
  className?: string
}

export const AuthNavbar: FC<AuthNavbarType> = ({ className }) => {
  const { t, tCommon } = useAuthTranslation()
  const isSignupFlow = useIsSignupFlow()

  return (
    <Flex row className={classNames(className, authNavbarCVA.root())}>
      <Link to={routes.signIn.rootPath} className='flex justify-center'>
        <Image
          data-testid='auth-nav-company-logo-image'
          className='!w-20 hover:opacity-75 sm:!w-24'
          src={imagesUrls.logo.company.comboLogoBlack}
        />
      </Link>

      <Flex row className='grow-0 gap-2 sm:gap-4'>
        <LanguageSelect data-testid='auth-nav-language-input' />
        {isSignupFlow && (
          <Flex row className='items-center gap-2'>
            <Text className={authNavbarCVA.alreadyAnAccount()}>
              {t('authentication.navbar.alreadyAnAccount')}
            </Text>
            <Button
              data-testid='auth-nav-signin-link-button'
              component={Link}
              to={routes.signIn.rootPath}
              variant='secondary'
              intent='default'
            >
              {tCommon('action.login')}
            </Button>
          </Flex>
        )}
        {!isSignupFlow && (
          <Button
            data-testid='auth-nav-signup-link-button'
            component={Link}
            to={routes.signUp.rootPath}
            variant='secondary'
            intent='default'
          >
            {t('authentication.common.createAccount')}
          </Button>
        )}
      </Flex>
    </Flex>
  )
}
