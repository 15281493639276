import { useAtom } from 'jotai'

import { atomWithLocalStorage } from '../../../../services'

export type SilaeSyncConfirmation = {
  [membershipId: number]: boolean
}

const dismissAtom = atomWithLocalStorage<SilaeSyncConfirmation>(
  'silae-confirmation',
  {}
)

export const useSilaeConfirmation = () => {
  const [data, setData] = useAtom(dismissAtom)
  const confirmOne = (membershipId: number) => {
    setData({
      ...data,
      [membershipId]: true
    })
  }

  return {
    data,
    confirmOne
  }
}
