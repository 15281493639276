import type { SchedulesContractSchema, Model } from '@libs/data-access/entities'
import { schema } from 'normalizr'

export type ContractModel = Model<SchedulesContractSchema, {}>

const Contract = new schema.Entity<ContractModel>('contracts', {})

export const create = (data: ContractModel): ContractModel => {
  const original = {
    id: null,
    amendments: null,
    availabilities: null,
    contract_end: null,
    contract_time: null,
    contract_start: null,
    contract_end_trial: null,
    contract_type: null,
    lastname: null,
    firstname: null,
    gender: null,
    avatar_file_name: null,
    avatar_content_type: null,
    avatar_file_size: null,
    avatar_updated_at: null,
    daily_worker: null,
    contract_end_reason: null,
    start_time: null,
    avatar_urls: null,

    /* Relations */
    location_id: null,
    membership_id: null,
    partner_id: null,
    team_id: null,
    team_ids: null,

    created_at: null,
    updated_at: null
  }

  return {
    ...original,
    ...data
  }
}

export default Contract
