import { useQuery } from '@tanstack/react-query'

import type { FetchLineManagersOptions } from '../lineManagers.api'
import { fetchLineManagers } from '../lineManagers.api'
import { lineManagerQueryKeys } from '../lineManagers.cacheKeys'

export const useFetchLineManagers = (
  request: FetchLineManagersOptions,
  queryOptions?: { enabled?: boolean }
) =>
  useQuery({
    queryKey: lineManagerQueryKeys.get_line_manager(request?.queryParams),
    queryFn: () => fetchLineManagers(request),
    select: data => {
      return {
        ...data,
        options: data.resources?.map(manager => ({
          label: `${manager.firstname} ${manager.lastname}`,
          value: String(manager.id)
        }))
      }
    },
    enabled: queryOptions?.enabled
  })
