import { isRestCard } from '@spa/constants/RestTypes'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'

import { getPlannablesByTeam } from '../getPlannablesByTeam/getPlannablesByTeam'

export const getPlannableCardsByTeam = createCachedSelector(
  getPlannablesByTeam,
  plannables => {
    return {
      shifts: plannables.shifts,
      rests: plannables.rests.filter(rest => isRestCard(rest))
    }
  }
)((state, { teamId }) => fallbackToDefaultCacheKey(teamId))
