import type { LocationSchema } from '@libs/data-access/entities'
import { pick } from 'lodash-es'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import { mergeEntities } from '../../../reducers/entities'
import type { PayloadActionWithoutType } from '../../types/types'

type Params = PayloadActionWithoutType<{
  accountId: number
}>

export function* getLocations({ payload: { accountId } }: Params): any {
  const data: LocationSchema[] = yield apiRequest({
    route: 'getLocations',
    params: { account_id: accountId },
    queryParams: { account_id: accountId }
  })

  const locations = data.reduce((map, location) => {
    const attrs = {
      ...pick(location, [
        'id',
        'name',
        'account_id',
        'collective_agreement_id',
        'modulation_periods',
        'updated_at',
        'created_at'
      ]),
      team_ids: location.teams.map(team => team.id),
      teams: location.teams.map(team => team.id)
    }

    return { ...map, [location.id]: attrs }
  }, {})
  const teams = data.reduce((map, location) => {
    const teams = location.teams.reduce((teamMap, team) => {
      const attrs = pick(team, [
        'id',
        'name',
        'location_id',
        'updated_at',
        'created_at'
      ])
      return { ...teamMap, [team.id]: attrs }
    }, {})
    return { ...map, ...teams }
  }, [])

  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  yield put(mergeEntities({ locations, teams }))
}
