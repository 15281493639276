import { appQueryClient } from '../../../provider'

export const addonCacheKeys = {
  one: (id: string) => ['addons', id] as const
}

export const invalidateAddonCache = (id: string) => {
  appQueryClient.invalidateQueries({
    queryKey: addonCacheKeys.one(id)
  })
}
