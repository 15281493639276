import type { ReduxState } from '@spa/redux/ReduxState'
import {
  composeCacheKey,
  fallbackToDefaultCacheKey
} from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'

import { getTimeRange } from '../../../timeRange'
import { computeStackedItems } from '../../utils/computeStackedItems'
import getRestCards from '../getRestCards/getRestCards'
import getShiftCards from '../getShiftCards/getShiftCards'

import { filterCardsInTimeRange, formatToPlannableCards } from './utils'

type Params = {
  teamId: number
  contractId: number
}

/**
 * Because contracts can be related to different teams we need to use combination
 * of teamId + contractId as the cache key
 */
const cacheKeySelector = (_: ReduxState, { teamId, contractId }: Params) => {
  const cacheKey = composeCacheKey(teamId, contractId)
  return fallbackToDefaultCacheKey(cacheKey)
}

/**
 * Return planning rest cards mapped by week index days
 */
const getPlannableCardsInGrid = createCachedSelector(
  getTimeRange,
  (state: ReduxState, params: Params) => getShiftCards(state, params),
  (state: ReduxState, params: Params) => getRestCards(state, params),

  (timeRange, shiftCards, restCards) => {
    const shiftInRange = filterCardsInTimeRange(shiftCards, timeRange)
    const restInRange = filterCardsInTimeRange(restCards, timeRange)
    const cards = formatToPlannableCards([...restInRange, ...shiftInRange])
    const cardsWithOffset = computeStackedItems(cards)

    return cardsWithOffset
  }
)(cacheKeySelector)

export default getPlannableCardsInGrid
