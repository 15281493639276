import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_REWARD_TEMPLATES,
  FETCH_REWARD_TEMPLATES_SUCCEEDED,
  FETCH_REWARD_TEMPLATES_FAILED,
  CREATE_REWARD_TEMPLATE,
  CREATE_REWARD_TEMPLATE_SUCCEEDED,
  CREATE_REWARD_TEMPLATE_FAILED,
  UPDATE_REWARD_TEMPLATE,
  UPDATE_REWARD_TEMPLATE_SUCCEEDED,
  UPDATE_REWARD_TEMPLATE_FAILED,
  DELETE_REWARD_TEMPLATE,
  DELETE_REWARD_TEMPLATE_SUCCEEDED,
  DELETE_REWARD_TEMPLATE_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import {
  mergeRecords,
  updateRecord,
  deleteRecord
} from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchRewardTemplates = (locationId, resolve, reject) => ({
  type: FETCH_REWARD_TEMPLATES,
  locationId,
  resolve,
  reject
})

export const createRewardTemplate = (rewardTemplateData, resolve, reject) => ({
  type: CREATE_REWARD_TEMPLATE,
  rewardTemplateData,
  resolve,
  reject
})

export const updateRewardTemplate = (
  rewardTemplateId,
  rewardTemplateData,
  resolve,
  reject
) => ({
  type: UPDATE_REWARD_TEMPLATE,
  rewardTemplateId,
  rewardTemplateData,
  resolve,
  reject
})

export const deleteRewardTemplate = (rewardTemplateId, resolve, reject) => ({
  type: DELETE_REWARD_TEMPLATE,
  rewardTemplateId,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const rewardTemplatesSagaEntity = {
  success: (data, locationId) => ({
    type: FETCH_REWARD_TEMPLATES_SUCCEEDED,
    data,
    locationId
  }),
  failure: (error, locationId) => ({
    type: FETCH_REWARD_TEMPLATES_FAILED,
    error,
    locationId
  }),

  fetchAPI: (locationId, options, params) =>
    callAPI(
      `/locations/${locationId}/reward_templates?${queryString.stringify(
        params
      )}`,
      options
    )
}

export function* doFetchRewardTemplates({ locationId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    rewardTemplatesSagaEntity,
    locationId,
    null,
    undefined,
    resolve,
    reject
  )
}

const createRewardTemplateSagaEntity = {
  success: data => ({ type: CREATE_REWARD_TEMPLATE_SUCCEEDED, data }),
  failure: error => ({ type: CREATE_REWARD_TEMPLATE_FAILED, error }),

  fetchAPI: (id, options) =>
    callAPI('/reward_templates', { method: 'POST', ...options })
}

export function* doCreateRewardTemplate({
  rewardTemplateData,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    createRewardTemplateSagaEntity,
    null,
    null,
    rewardTemplateData,
    resolve,
    reject
  )
}

const updateRewardTemplateSagaEntity = {
  success: (data, rewardTemplateId) => ({
    type: UPDATE_REWARD_TEMPLATE_SUCCEEDED,
    data,
    rewardTemplateId
  }),
  failure: (error, rewardTemplateId) => ({
    type: UPDATE_REWARD_TEMPLATE_FAILED,
    error,
    rewardTemplateId
  }),

  fetchAPI: (rewardTemplateId, options) =>
    callAPI(`/reward_templates/${rewardTemplateId}`, {
      method: 'PUT',
      ...options
    })
}

export function* doUpdateRewardTemplate({
  rewardTemplateId,
  rewardTemplateData,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    updateRewardTemplateSagaEntity,
    rewardTemplateId,
    null,
    rewardTemplateData,
    resolve,
    reject
  )
}

const deleteRewardTemplateSagaEntity = {
  success: (data, rewardTemplateId) => ({
    type: DELETE_REWARD_TEMPLATE_SUCCEEDED,
    data,
    rewardTemplateId
  }),
  failure: (error, rewardTemplateId) => ({
    type: DELETE_REWARD_TEMPLATE_FAILED,
    error,
    rewardTemplateId
  }),

  fetchAPI: (rewardTemplateId, options) =>
    callAPI(`/reward_templates/${rewardTemplateId}`, {
      method: 'DELETE',
      ...options
    })
}

export function* doDeleteRewardTemplate({ rewardTemplateId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    deleteRewardTemplateSagaEntity,
    rewardTemplateId,
    null,
    null,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const RewardTemplate = Immutable.Record({
  id: null,
  name: null,
  location_id: null,
  payroll_software_code: null,
  reward_type: null
})

const rewardTemplateForeignKeys = ['location_id']

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function rewardTemplates(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_REWARD_TEMPLATES: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case FETCH_REWARD_TEMPLATES_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_REWARD_TEMPLATES_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const rewardTemplates = Immutable.fromJS(action.data)

      return mergeRecords(
        state,
        RewardTemplate,
        rewardTemplates,
        // @ts-ignore migration from js(x) to ts(x)
        rewardTemplateForeignKeys
      ).mergeDeepIn(['meta'], {
        loading: false,
        updated_at: Date.now(),
        success: true
      })
    }
    case CREATE_REWARD_TEMPLATE_SUCCEEDED:
    case UPDATE_REWARD_TEMPLATE_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const rewardTemplate = Immutable.fromJS(action.data)
      return updateRecord(
        state,
        RewardTemplate,
        rewardTemplate,
        // @ts-ignore migration from js(x) to ts(x)
        rewardTemplateForeignKeys
      )
    }
    case DELETE_REWARD_TEMPLATE_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const { rewardTemplateId } = action
      // @ts-ignore migration from js(x) to ts(x)
      return deleteRecord(state, rewardTemplateId, rewardTemplateForeignKeys)
    }
    default:
      return state
  }
}

export default filterActions(rewardTemplates, [
  FETCH_REWARD_TEMPLATES,
  FETCH_REWARD_TEMPLATES_FAILED,
  FETCH_REWARD_TEMPLATES_SUCCEEDED,
  CREATE_REWARD_TEMPLATE_SUCCEEDED,
  UPDATE_REWARD_TEMPLATE_SUCCEEDED,
  DELETE_REWARD_TEMPLATE_SUCCEEDED
])
