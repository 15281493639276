import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'

import type { CenterProps } from './Center.types'

const centerCVA = cva(['box-border flex items-center justify-center'], {
  variants: {
    textCenter: {
      true: ['text-center']
    },
    col: {
      true: ['flex-col']
    }
  }
})

export const Center = forwardRef<HTMLDivElement, CenterProps>(
  ({ textCenter = false, col = false, className, children, ...other }, ref) => {
    return (
      <div
        {...other}
        ref={ref}
        className={centerCVA({ textCenter, col, className })}
      >
        {children}
      </div>
    )
  }
)

Center.displayName = 'Center'
