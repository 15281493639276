import type { PayslipMatchingResponseSchema } from '@libs/data-access/entities'

import { wsServices } from '../../../../apis'

export type PayslipmportCompletedMessage = {
  type: 'PAYSLIPS_DISTRIBUTION_IMPORT_COMPLETED'
  data: PayslipMatchingResponseSchema
}

export type PayslipPageScannedMessage = {
  type: 'PAYSLIPS_DISTRIBUTION_PAGE_SCANNED'
  data: {
    file_id: number
    page_number: number
    pages_count: number
    file_number: number
    files_count: number
  }
}

export const usePayslipUploadSubscription = wsServices.core.subscription.create(
  {
    subscribePayload: (opts: { import_id: number }) => ({
      channel: 'PayslipsDistribution::ImportAndAutoMatchChannel',
      ...opts
    }),

    messages: {
      onImportCompleted:
        wsServices.core.subscription.defineMessageHandler<PayslipmportCompletedMessage>(
          { type: 'PAYSLIPS_DISTRIBUTION_IMPORT_COMPLETED' }
        ),

      onPageScanned:
        wsServices.core.subscription.defineMessageHandler<PayslipPageScannedMessage>(
          { type: 'PAYSLIPS_DISTRIBUTION_PAGE_SCANNED' }
        )
    }
  }
)
