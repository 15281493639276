import { CloseIcon } from '@libs/ui/ds/assets/icons'
import { Combobox } from '@libs/ui/ds/lib/components/overlays/Combobox/base/Combobox'
import { useDSTranslation } from '@libs/ui/ds/lib/internals/i18n'
import type { FC } from 'react'

import type { MultiSelectProps } from '../../MultiSelect.types'

type SelectAllOptionProps = {
  maxSelected: MultiSelectProps['maxSelected']
  selectValue: MultiSelectProps['value']
  setSelectValue: (arg: any) => void
  options: MultiSelectProps['options']
  enabled: boolean
}

export const SelectAllOption: FC<SelectAllOptionProps> = ({
  enabled,
  maxSelected,
  selectValue,
  options,
  setSelectValue
}) => {
  const { t } = useDSTranslation()
  if (!enabled) {
    return null
  }

  const allEnabledOptions = options.filter(i => !i.disabled)
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  const isActive = allEnabledOptions.length === selectValue.length

  const handleSelectAll = () => {
    const payload = isActive ? [] : allEnabledOptions.map(i => i.value)
    setSelectValue(payload)
  }

  if (maxSelected !== Infinity || !allEnabledOptions?.length) {
    return null
  }

  return (
    <Combobox.Option
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      value={null}
      onClick={handleSelectAll}
      leftIcon={isActive ? <CloseIcon /> : null}
    >
      {isActive
        ? t('ds.components.multiSelect.deselectAll')
        : t('ds.components.multiSelect.selectAll')}
    </Combobox.Option>
  )
}
