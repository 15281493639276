import { forwardRef } from 'react'

import { createAnchor } from '../util/createAnchor'

import type { AnchorProps } from './Anchor.types'
import { anchorCVA } from './anchorClasses'
import { AnchorLinkIcon } from './subs/AnchorLinkIcon'

export const BaseAnchor = createAnchor<AnchorProps>(
  forwardRef<HTMLAnchorElement, AnchorProps>(
    (
      {
        component = 'a',
        variant = 'inherits',
        className,
        rightIcon,
        children,
        ...other
      },
      ref
    ) => {
      const Component = component

      return (
        <Component
          ref={ref}
          {...other}
          className={anchorCVA({ variant, className })}
        >
          {children}
          {rightIcon && <AnchorLinkIcon />}
        </Component>
      )
    }
  )
)

const TypedAnchor = BaseAnchor as typeof BaseAnchor
TypedAnchor.displayName = 'Anchor'

export const Anchor = TypedAnchor
export type { AnchorProps } from './Anchor.types'
