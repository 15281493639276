export const LockBoldIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.25 7.91667H15.625V5.625C15.625 4.13316 15.0323 2.70242 13.9775 1.64752C12.9226 0.592632 11.4918 0 9.99998 0C8.50814 0 7.0774 0.592632 6.0225 1.64752C4.96761 2.70242 4.37498 4.13316 4.37498 5.625V7.91667H3.74998C3.30795 7.91667 2.88403 8.09226 2.57147 8.40482C2.25891 8.71738 2.08331 9.14131 2.08331 9.58333V18.3333C2.08331 18.7754 2.25891 19.1993 2.57147 19.5118C2.88403 19.8244 3.30795 20 3.74998 20H16.25C16.692 20 17.1159 19.8244 17.4285 19.5118C17.7411 19.1993 17.9166 18.7754 17.9166 18.3333V9.58333C17.9166 9.14131 17.7411 8.71738 17.4285 8.40482C17.1159 8.09226 16.692 7.91667 16.25 7.91667ZM8.33331 12.9167C8.33331 12.4746 8.50891 12.0507 8.82147 11.7382C9.13403 11.4256 9.55795 11.25 9.99998 11.25C10.442 11.25 10.8659 11.4256 11.1785 11.7382C11.4911 12.0507 11.6666 12.4746 11.6666 12.9167C11.6649 13.2075 11.587 13.4929 11.4409 13.7443C11.2947 13.9958 11.0852 14.2046 10.8333 14.35V16.25C10.8333 16.471 10.7455 16.683 10.5892 16.8393C10.433 16.9955 10.221 17.0833 9.99998 17.0833C9.77897 17.0833 9.567 16.9955 9.41072 16.8393C9.25444 16.683 9.16665 16.471 9.16665 16.25V14.35C8.91475 14.2046 8.7053 13.9958 8.5591 13.7443C8.41291 13.4929 8.33507 13.2075 8.33331 12.9167ZM6.45831 5.625C6.45831 4.68569 6.83145 3.78485 7.49564 3.12066C8.15983 2.45647 9.06067 2.08333 9.99998 2.08333C10.9393 2.08333 11.8401 2.45647 12.5043 3.12066C13.1685 3.78485 13.5416 4.68569 13.5416 5.625V7.5C13.5416 7.61051 13.4977 7.71649 13.4196 7.79463C13.3415 7.87277 13.2355 7.91667 13.125 7.91667H6.87498C6.76447 7.91667 6.65849 7.87277 6.58035 7.79463C6.50221 7.71649 6.45831 7.61051 6.45831 7.5V5.625Z'
        fill='currentColor'
      />
    </svg>
  )
}
