import type { FC } from 'react'
import React, { useMemo } from 'react'

import { Button } from '../../../../buttons/Button/base/Button'
import { Flex } from '../../../../primitiveLayouts/Flex/base/Flex'

import type { PickerPresetListProps } from './PickerPresetList.types'
import { resolvePresets } from './resolvePresets'

export const PickerPresetList: FC<PickerPresetListProps> = ({
  presets = [],
  onChange,
  value
}) => {
  const resolvedPresets = useMemo(() => {
    return resolvePresets(presets, value)
  }, [presets, value])

  if (!presets.length) {
    return null
  }

  return (
    <Flex row className='w-full max-w-120 flex-wrap gap-1.5'>
      {resolvedPresets.map(preset => (
        <Button
          key={preset.label}
          size='xs'
          intent='neutral-dark'
          variant={preset.isActive ? 'primary' : 'secondary'}
          onClick={() => {
            onChange({
              isPast: preset.isPast,
              start: preset.start,
              end: preset.end
            })
          }}
        >
          {preset.label}
        </Button>
      ))}
    </Flex>
  )
}
