import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type DisplayFilters = {
  hideAlerts?: boolean
  hideRests?: boolean
  hideAvailabilities?: boolean
  hideNonScheduled?: boolean
  hideOthersLocations?: boolean
  hideRCRCounters?: boolean
  showExtraOrInterimOnly?: boolean
  searchContract?: string
}

export type DisplayFilterName = keyof DisplayFilters

const initialState: DisplayFilters = {
  hideAlerts: false,
  hideRests: false,
  hideAvailabilities: false,
  hideNonScheduled: false,
  hideOthersLocations: false,
  hideRCRCounters: false,
  showExtraOrInterimOnly: false,
  searchContract: ''
}

const displayFiltersSlice = createSlice({
  name: 'ui/planning/displayFilters',
  initialState,
  reducers: {
    setDisplayFilters(state, action: PayloadAction<Partial<DisplayFilters>>) {
      return { ...state, ...action.payload }
    },
    clearDisplayFilters() {
      return { ...initialState }
    }
  }
})

export const { setDisplayFilters, clearDisplayFilters } =
  displayFiltersSlice.actions
export default displayFiltersSlice.reducer
