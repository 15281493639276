import { combineReducers } from '@reduxjs/toolkit'

import addons from './addons/reducer'
import estimate from './estimate/reducer'
import location from './locations/reducer'
import plan from './plan/reducer'
import subscription from './subscription/reducer'

export default combineReducers({
  addons,
  estimate,
  location,
  plan,
  subscription
})
