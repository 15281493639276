import { isRestCard } from '@spa/constants/RestTypes'
import type { ReduxState } from '@spa/redux/ReduxState'
import { fallbackToDefaultCacheKey } from '@spa/redux/utils/reselect'
import createCachedSelector from 're-reselect'
import type { Rest } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

import { restsSelectors } from '../../entities'

export const filterRestTubes = (rests: Rest[]) => {
  return rests.filter(rest => {
    return !rest.original_shift_id && !isRestCard(rest)
  })
}

/**
 * Return planning rest tubes mapped by week index days
 */
const getRestTubesByContract = createCachedSelector(
  (state: ReduxState, { contractId }: { contractId: number }) =>
    restsSelectors.getRestsByContract(state, { contractId }),

  rests => {
    return filterRestTubes(rests)
  }
)((_, { contractId }) => fallbackToDefaultCacheKey(contractId))

export default getRestTubesByContract
