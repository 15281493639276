import { Accordion } from '@mantine/core'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'

import { Divider } from '../../../../Divider/base/Divider'

import type { AccordionPanelProps } from './AccordionPanel.types'

const accordionPanelCVA = {
  separator: cva(['mb-6'])
}

export const AccordionPanel: FC<AccordionPanelProps> = ({
  children,
  ...other
}) => {
  return (
    <Accordion.Panel {...other}>
      <Divider className={accordionPanelCVA.separator()} />
      {children}
    </Accordion.Panel>
  )
}
