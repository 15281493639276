import { useUncontrolled } from '@libs/ui/ds'
import { useEffect } from 'react'

import type { NativeSelectProps } from '../NativeSelect.types'

type Params = {
  select: {
    value: NativeSelectProps['value']
    defaultValue: NativeSelectProps['defaultValue']
    onChange: NativeSelectProps['onChange']
  }
}

export const useNativeSelectValuesUncontrolled = ({ select }: Params) => {
  const [selectValue, setSelectValue] = useUncontrolled<string>(select)

  const resetSelectValue = () => {
    setSelectValue(null as any)
  }

  useEffect(() => {
    if (selectValue === null) {
      resetSelectValue()
    }
  }, [selectValue])

  return {
    select: {
      selectValue,
      setSelectValue,
      resetSelectValue
    }
  }
}
