import { normalize } from 'normalizr'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http/api'

import { Weeklyschedule } from '../../../models'
import { setEntities } from '../../../reducers/entities'
import type { PayloadActionWithoutType } from '../../types/types'

type Params = PayloadActionWithoutType<{
  accountId: number
  week: 'YYYY-[W]WW' | string
  withPermissions: boolean
}>

export function* getWeeklyschedules({
  payload: { accountId, week, withPermissions }
}: Params): any {
  if (!week) {
    return
  }

  const data = yield apiRequest({
    route: 'getWeeklyschedules',
    params: { account_id: accountId },
    queryParams: { week, with_permissions: withPermissions }
  })

  const normalized = normalize(data, [Weeklyschedule])

  yield put(
    setEntities({ weeklyschedules: normalized.entities.weeklyschedules })
  )
}
