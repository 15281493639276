import type { Model } from '@libs/data-access/entities'
import Contract from '@spa/scenes/Plannings/redux/models/Contract'
import Label from '@spa/scenes/Plannings/redux/models/Label'
import Membership from '@spa/scenes/Plannings/redux/models/Membership'
import Weeklyschedule from '@spa/scenes/Plannings/redux/models/Weeklyschedule'
import { schema } from 'normalizr'
import type { Team as TeamSchema } from 'snap-redux/infra/http/routesConfig/scopes/planning/types'

export type TeamModel = Model<
  TeamSchema,
  {
    user_contracts: number[]
    weeklyschedules: number[]
    memberships: number[]
    labels: number[]
  }
>

const Team = new schema.Entity<TeamModel>('teams', {
  user_contracts: [Contract],
  weeklyschedules: [Weeklyschedule],
  memberships: [Membership],
  labels: [Label]
})

export function create(data: Partial<TeamModel>): TeamModel {
  const original: TeamModel = {
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    id: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    name: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    location_id: null,
    user_contracts: [],
    weeklyschedules: [],
    memberships: [],
    labels: [],
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    updated_at: null,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    created_at: null
  }

  return {
    ...original,
    ...data
  }
}

export default Team
