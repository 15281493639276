/* eslint-disable import/no-named-as-default */

import { SuspenseWrap } from '@libs/ui/ds'
import { NotFoundError, PageCentered } from '@spa/components/ErrorsFallback'
import { routes } from '@spa/core/router'
import React, { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

const ADPInfoPage = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/CashRegisterSetting/infoPages/ADPInfoPage'
    )
)
const CarrePOSInfoPage = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/CashRegisterSetting/infoPages/CarrePOSInfoPage'
    )
)
const CashpadInfoPage = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/CashRegisterSetting/infoPages/CashpadInfoPage'
    )
)
const ContactDetails = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/ContactSetting/ContactForm/ContactDetails'
    )
)
const InnovorderInfoPage = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/CashRegisterSetting/infoPages/InnovorderInfoPage'
    )
)
const IziPassInfoPage = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/CashRegisterSetting/infoPages/IziPassInfoPage'
    )
)
const LadditionInfoPage = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/CashRegisterSetting/infoPages/LadditionInfoPage'
    )
)
const LightspeedInfoPage = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/CashRegisterSetting/infoPages/LightspeedInfoPage'
    )
)
const MenlogInfoPage = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/CashRegisterSetting/infoPages/MenlogInfoPage'
    )
)
const PIElectronicsInfoPage = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/CashRegisterSetting/infoPages/PIElectronicsInfoPage'
    )
)
const TillerInfoPage = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/CashRegisterSetting/infoPages/TillerInfoPage'
    )
)
const ZeltyInfoPage = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/CashRegisterSetting/infoPages/ZeltyInfoPage'
    )
)

const CollectiveAgreementSetting = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/CollectiveAgreementSetting/CollectiveAgreementSetting'
    )
)
const LegalSetting = lazy(
  () => import('@spa/scenes/Settings/containers/LegalSetting')
)

const ComboPayDashboard = lazy(
  () =>
    import(
      '@spa/features/ComboPayHrSynchronization/components/ComboPayDashboard/ComboPayDashboard'
    )
)
const ComboPayEmployeeTable = lazy(
  () =>
    import(
      '@spa/features/ComboPayHrSynchronization/components/ComboPayEmployeeTable/ComboPayEmployeeTable'
    )
)
const ComboPayConfiguration = lazy(
  () =>
    import(
      '@spa/features/ComboPayHrSynchronization/components/ComboPayConfiguration/ComboPayConfiguration'
    )
)

const SilaePage = lazy(
  () =>
    import(
      '@spa/features/SilaeHrSynchronization/components/SilaePage/SilaePage'
    )
)
const SilaeTablePage = lazy(
  () =>
    import(
      '@spa/features/SilaeHrSynchronization/components/SilaeTablePage/SilaeTablePage'
    )
)
const SilaeConfigurationPage = lazy(
  () =>
    import(
      '@spa/features/SilaeHrSynchronization/components/SilaeConfigurationPage/SilaeConfigurationPage'
    )
)
const SilaePromotionPage = lazy(
  () =>
    import(
      '@spa/features/SilaeHrSynchronization/components/SilaePromotionPage/SilaePromotionPage'
    )
)
const LocationPage = lazy(
  () => import('@spa/scenes/Settings/containers/LocationsSetting/LocationPage')
)
const ModulationLocationContractPage = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/LocationsSetting/ModulationLocationContractPage'
    )
)
const SettingsLocationBilling = lazy(
  () => import('@spa/scenes/Settings/containers/LocationsSetting/Billing')
)
const NotificationSetting = lazy(
  () => import('@spa/scenes/Settings/containers/NotificationSetting')
)
const PayPreferences = lazy(
  () => import('@spa/scenes/Settings/containers/PayPreferences')
)
const TimeOffPoliciesSettings = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/TimeOffPoliciesSettings/TimeOffPoliciesSettings'
    )
)
const CreateTimeOffPolicyPage = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/TimeOffPoliciesSettings/TimeOffPoliciesForm/CreateTimeOffPolicyForm/CreateTimeOffPolicyForm'
    )
)
const UpdateTimeOffPolicyPage = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/TimeOffPoliciesSettings/TimeOffPoliciesForm/UpdateTimeOffPolicyForm/UpdateTimeOffPolicy'
    )
)

const PayrollSoftwareSetting = lazy(
  () => import('@spa/scenes/Settings/containers/PayrollSoftwareSetting')
)
const MarketplaceIntegrationSetting = lazy(
  () => import('@spa/scenes/Settings/containers/MarketplaceIntegrationSetting')
)
const LocationsSetting = lazy(
  () => import('@spa/scenes/Settings/containers/LocationsSetting')
)
const LocationCreationView = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/LocationsSetting/LocationCreation/LocationCreationView'
    )
)
const PreferencesForm = lazy(
  () => import('@spa/scenes/Settings/containers/PreferencesSetting')
)
const PrintForm = lazy(
  () => import('@spa/scenes/Settings/containers/PrintSetting')
)
const ProductivityForm = lazy(
  () => import('@spa/scenes/Settings/containers/ProductivitySetting')
)
const TemplateDocumentsSetting = lazy(
  () => import('@spa/scenes/Settings/containers/TemplateDocumentsSettings')
)
const JobTitleSetting = lazy(
  () => import('@spa/scenes/Settings/containers/JobTitle')
)
const TimeclockSetting = lazy(
  () => import('@spa/scenes/Settings/containers/TimeclockSetting')
)
const ConfigurationTab = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/TimeclockSetting/TimeclockTabs/ConfigurationTab'
    )
)
const EstablishmentTab = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/TimeclockSetting/TimeclockTabs/EstablishmentTab'
    )
)
const IdTab = lazy(
  () =>
    import(
      '@spa/scenes/Settings/containers/TimeclockSetting/TimeclockTabs/IdTab'
    )
)
const WageRatioForm = lazy(
  () => import('@spa/scenes/Settings/containers/WageRatioSetting/WageRatioForm')
)
const ArticleCreateOrUpdate = lazy(
  () => import('@spa/scenes/Articles/components/ArticleCreateOrUpdate')
)
const ArticleView = lazy(
  () => import('@spa/scenes/Articles/components/ArticleView/ArticleView')
)
const DashboardAbsenceLogPage = lazy(
  () => import('@spa/scenes/DashboardRH/AbsenceLog/AbsenceLog')
)
const DashboardHomePage = lazy(() => import('@spa/scenes/DashboardRH/HomePage'))
const DashboardIncompletePage = lazy(
  () => import('@spa/scenes/DashboardRH/IncompleteProfilesPage')
)
const DashboardContractsChangesPage = lazy(
  () => import('@spa/scenes/DashboardRH/ContractsChangesPage')
)
const DashboardEntriesPage = lazy(
  () => import('@spa/scenes/DashboardRH/LastEntriesPage')
)
const DashboardPaidLeaves = lazy(
  () => import('@spa/scenes/DashboardRH/PaidLeaves')
)
const DashboardExitsPage = lazy(
  () => import('@spa/scenes/DashboardRH/ExitsPage')
)
const DashboardWorkPermitPage = lazy(
  () => import('@spa/scenes/DashboardRH/WorkPermitPage/WorkPermitPage')
)
const DashboardTrialEndPage = lazy(
  () => import('@spa/scenes/DashboardRH/TrialEndPage')
)
const DashboardClockInOutFollowUpPage = lazy(
  () =>
    import('@spa/scenes/DashboardRH/ClockInOutFollowUpPage/ClockInOutFollowUp')
)
const DashboardDocumentSignaturePage = lazy(
  () => import('@spa/scenes/DashboardRH/DashboardDocumentSignaturePage')
)
const DashboardPayslipsPage = lazy(
  () => import('@spa/scenes/DashboardRH/PayslipsPage')
)

const PayslipsMarketing = lazy(
  () =>
    import(
      '@spa/scenes/DashboardRH/PayslipsPage/PayslipMarketing/PayslipMarketingPage'
    )
)

const PayslipsHistory = lazy(
  () =>
    import(
      '@spa/scenes/DashboardRH/PayslipsPage/PayslipsHistory/PayslipsHistoryPage'
    )
)
const PayslipsDistributionFlow = lazy(
  () =>
    import(
      '@spa/scenes/DashboardRH/PayslipsPage/PayslipsDistributionFlow/PayslipsDistributionFlow'
    )
)

const PaidLeavesCounters = lazy(
  () => import('@spa/features/MembershipPage/containers/PaidLeaveCounters')
)
const PayrollList = lazy(() => import('@spa/scenes/Payroll/PayrollListPage'))
const PayrollReport = lazy(() => import('@spa/scenes/Payroll/PayrollReport'))
const ReportFilesList = lazy(
  () => import('@spa/scenes/Payroll/ReportFilesList')
)
const ContractExport = lazy(
  () =>
    import(
      '@spa/scenes/Payroll/PayrollListPage/LegacyPayrollList/components/MultiExports/ContractExport'
    )
)
const ADPSetting = lazy(
  () => import('@spa/scenes/Settings/containers/ADPSetting')
)
const CashRegisterSetting = lazy(
  () => import('@spa/scenes/Settings/containers/CashRegisterSetting')
)

const Plannings = lazy(() => import('@spa/scenes/Plannings/Planning'))

const CounterModulation = lazy(
  () => import('@spa/scenes/CounterModulation/CounterModulation')
)

const DashboardRH = lazy(() => import('@spa/scenes/DashboardRH'))
const MemberList = lazy(() => import('@spa/scenes/MemberList'))
const MembershipPage = lazy(
  () => import('@spa/scenes/MembershipPage/MembershipPage')
)
const Messages = lazy(() => import('@spa/scenes/Conversations/Conversations'))
const Articles = lazy(() => import('@spa/scenes/Articles/Articles'))
const Activities = lazy(() => import('@spa/scenes/Activities/ActivitiesGuard'))
const SubscriptionAccountBased = lazy(
  () => import('@spa/scenes/Subscription/account-based')
)
const SubscriptionsView = lazy(
  () =>
    import(
      '@spa/scenes/Subscription/location-based/views/home/SubscriptionsView'
    )
)
const SubscriptionDetailsView = lazy(
  () =>
    import(
      '@spa/scenes/Subscription/location-based/views/details/SubscriptionDetailsView'
    )
)
const TimeClockReport = lazy(() => import('@spa/scenes/TimeClockReport'))
const Timesheet = lazy(() => import('@spa/scenes/Timesheet'))
const ToucanDashboard = lazy(
  () => import('@spa/scenes/ToucanDashboard/ToucanDashboard')
)
const Timeoffs = lazy(() => import('@spa/scenes/Timeoffs/TimeoffsPage'))
const TimeoffsPending = lazy(
  () => import('@spa/scenes/Timeoffs/components/TimeoffTable/PendingTimeoffs')
)
const TimeoffsTreated = lazy(
  () => import('@spa/scenes/Timeoffs/components/TimeoffTable/TreatedTimeoffs')
)
const TimeoffsExpired = lazy(
  () => import('@spa/scenes/Timeoffs/components/TimeoffTable/ExpiredTimeoffs')
)
const CalendarLocationTimeoff = lazy(
  () => import('@spa/scenes/Timeoffs/components/CalendarLocationTimeoff')
)
const MemberPreferences = lazy(() => import('@spa/scenes/MemberPreferences'))
const Reports = lazy(() => import('@spa/scenes/Reports'))
const Payroll = lazy(() => import('@spa/scenes/Payroll'))
const Settings = lazy(() => import('@spa/scenes/Settings/Settings'))

const Dashboard = lazy(() => import('@spa/scenes/Dashboard/DashboardContainer'))
const Welcome = lazy(() => import('@spa/scenes/Welcome'))
const ElectronicSignature = lazy(
  () => import('@spa/scenes/ElectronicSignature/ElectronicSignature')
)

export const AuthenticatedAppRoutes = React.memo(() => {
  return (
    <SuspenseWrap>
      <Routes>
        <Route path={routes.welcome.path} element={<Welcome />} />

        <Route path={routes.home.path} element={<Dashboard />} />

        <Route path={routes.plannings.path} element={<Plannings />} />

        <Route path={routes.members.path} element={<MemberList />} />
        <Route path={routes.member.path} element={<MembershipPage />}>
          <Route
            path={routes.memberSubview.path}
            element={<MembershipPage />}
          />
        </Route>

        <Route path={routes.memberTimesheet.path} element={<Timesheet />} />
        <Route
          path={routes.memberPreferences.path}
          element={<MemberPreferences />}
        />
        <Route
          path={routes.memberCounterModulation.path}
          element={<CounterModulation />}
        />
        <Route
          path={routes.subscription.path}
          element={<SubscriptionAccountBased />}
        />
        <Route
          path={routes.subscriptionsDetails.path}
          element={<SubscriptionDetailsView />}
        />
        <Route
          path={routes.subscriptions.path}
          element={<SubscriptionsView />}
        />

        <Route path={routes.conversations.path} element={<Messages />}>
          <Route path={routes.conversation.path} element={<Messages />} />
        </Route>

        <Route path={routes.articles.path} element={<Articles />} />

        <Route
          path={routes.articleCreate.path}
          element={<ArticleCreateOrUpdate />}
        />
        <Route path={routes.article.path} element={<ArticleView />} />
        <Route
          path={routes.articleUpdate.path}
          element={<ArticleCreateOrUpdate />}
        />

        <Route path={routes.timeoffs.rootPath} element={<Timeoffs />}>
          <Route
            index
            element={<Navigate to={routes.timeoffsPending.rootPath} />}
          />
          <Route
            path={routes.timeoffsPending.rootPath}
            element={<TimeoffsPending />}
          />

          <Route
            path={routes.timeoffsTreated.rootPath}
            element={<TimeoffsTreated />}
          />

          <Route
            path={routes.timeoffsExpired.rootPath}
            element={<TimeoffsExpired />}
          />

          <Route
            path={routes.timeoffsCalendar.rootPath}
            element={<CalendarLocationTimeoff />}
          />
        </Route>

        <Route path={routes.dashboardRh.path} element={<DashboardRH />}>
          <Route
            path={routes.dashboardRhHome.path}
            element={<DashboardHomePage />}
          />
          <Route
            path={routes.dashboardRhEntries.path}
            element={<DashboardEntriesPage />}
          />
          <Route
            path={routes.dashboardRhTrialend.path}
            element={<DashboardTrialEndPage />}
          />
          <Route
            path={routes.dashboardRhAbsenceLog.path}
            element={<DashboardAbsenceLogPage />}
          />
          <Route
            path={routes.dashboardRhIncompleteProfiles.path}
            element={<DashboardIncompletePage />}
          />
          <Route
            path={routes.dashboardRhClockInOutFollowUp.path}
            element={<DashboardClockInOutFollowUpPage />}
          />
          <Route
            path={routes.dashboardRhContractsChanges.path}
            element={<DashboardContractsChangesPage />}
          />
          <Route
            path={routes.dashboardRhExits.path}
            element={<DashboardExitsPage />}
          />
          <Route
            path={routes.dashboardRhWorkPermitPermit.path}
            element={<DashboardWorkPermitPage />}
          />
          <Route
            path={routes.dashboardRhPaidLeaves.path}
            element={<DashboardPaidLeaves />}
          />
          <Route
            path={routes.dashboardRhDocumentSignature.path}
            element={<DashboardDocumentSignaturePage />}
          />

          <Route
            path={routes.payslips.path}
            element={<DashboardPayslipsPage />}
          >
            <Route
              index
              element={<Navigate to={routes.payslipsHistory.rootPath} />}
            />
            <Route
              path={routes.payslipsHistory.path}
              element={<PayslipsHistory />}
            />
            <Route
              path={routes.payslipsMarketing.path}
              element={<PayslipsMarketing />}
            />
            <Route
              path={routes.payslipsDistributionFlow.path}
              element={<PayslipsDistributionFlow />}
            />
          </Route>
          <Route
            path={routes.dashboardRhToucan.path}
            element={<ToucanDashboard />}
          />
        </Route>

        <Route
          path={routes.memberPaidLeaveCounter.path}
          element={<PaidLeavesCounters />}
        />

        <Route path={routes.reportsPayrollContainer.path} element={<Payroll />}>
          <Route
            path={routes.reportsPayroll.path}
            element={<PayrollReport />}
          />
          <Route
            path={routes.reportsPayrollFiles.path}
            element={<ReportFilesList />}
          />
        </Route>

        <Route path={routes.reports.path} element={<Reports />}>
          <Route path={routes.reportsPayrolls.path} element={<Payroll />}>
            <Route
              path={routes.reportsPayrollsList.path}
              element={<PayrollList />}
            />
          </Route>
          <Route
            path={routes.reportsActivities.path}
            element={<Activities />}
          />
          <Route
            path={routes.reportsTimeclock.path}
            element={<TimeClockReport />}
          />
          <Route
            path={routes.reportsContracts.path}
            element={<ContractExport />}
          />
        </Route>

        <Route
          path={routes.settingsLocationBilling.path}
          element={<SettingsLocationBilling />}
        />

        <Route
          path={routes.settingsLocationsNew.rootPath}
          element={<LocationCreationView />}
        />
        <Route
          path={routes.settingsTimeoffPolicyNew.rootPath}
          element={<CreateTimeOffPolicyPage />}
        />

        <Route
          path={routes.settingsTimeoffPolicy.rootPath}
          element={<UpdateTimeOffPolicyPage />}
        />

        <Route path={routes.settings.path} element={<Settings />}>
          <Route
            path={routes.settingsLocations.path}
            element={<LocationsSetting />}
          />
          <Route
            path={routes.settingsLocation.path}
            element={<LocationPage />}
          />
          <Route
            path={routes.settingsContact.path}
            element={<ContactDetails />}
          />
          <Route
            path={routes.settingsCollectiveAgreement.path}
            element={<CollectiveAgreementSetting />}
          />
          <Route path={routes.settingsPrint.path} element={<PrintForm />} />
          <Route
            path={routes.settingsPreferences.path}
            element={<PreferencesForm />}
          />
          <Route
            path={routes.settingsNotification.path}
            element={<NotificationSetting />}
          />
          <Route
            path={routes.settingsWageRatio.path}
            element={<WageRatioForm />}
          />
          <Route
            path={routes.settingsProductivity.path}
            element={<ProductivityForm />}
          />
          <Route
            path={routes.settingsPayPreferences.path}
            element={<PayPreferences />}
          />
          <Route
            path={routes.settingsTimeoffPolicies.path}
            element={<TimeOffPoliciesSettings />}
          />

          <Route
            path={routes.settingsMarketplace.path}
            element={<MarketplaceIntegrationSetting />}
          />
          <Route
            path={routes.settingsCashRegister.path}
            element={<CashRegisterSetting />}
          />
          <Route
            path={routes.settingsPayrollSoftware.path}
            element={<PayrollSoftwareSetting />}
          />
          <Route
            path={routes.settingsSilaeHrSynchronization.rootPath}
            element={<SilaePage />}
          >
            <Route
              index
              element={
                <Navigate
                  to={routes.settingsSilaeHrSynchronizationTable.rootPath}
                />
              }
            />
            <Route
              path={routes.settingsSilaeHrSynchronizationTable.rootPath}
              element={<SilaeTablePage />}
            />
            <Route
              path={routes.settingsSilaeHrSynchronizationConfiguration.rootPath}
              element={<SilaeConfigurationPage />}
            />
            <Route
              path={routes.settingsSilaeHrSynchronizationPromotion.rootPath}
              element={<SilaePromotionPage />}
            />
          </Route>
          <Route
            path={routes.settingsComboPayHrSynchronization.rootPath}
            element={<ComboPayDashboard />}
          >
            <Route
              index
              element={
                <Navigate
                  to={routes.settingsComboPayHrSynchronizationTable.rootPath}
                />
              }
            />
            <Route
              path={routes.settingsComboPayHrSynchronizationTable.rootPath}
              element={<ComboPayEmployeeTable />}
            />
            <Route
              path={
                routes.settingsComboPayHrSynchronizationConfiguration.rootPath
              }
              element={<ComboPayConfiguration />}
            />
          </Route>
          <Route path={routes.settingsADP.path} element={<ADPSetting />} />
          <Route
            path={routes.settingsTemplateDocuments.path}
            element={<TemplateDocumentsSetting />}
          />

          <Route
            path={routes.settingsJobTitle.path}
            element={<JobTitleSetting />}
          />
          <Route
            path={routes.settingsTimeclock.path}
            element={<TimeclockSetting />}
          >
            <Route
              path=''
              element={<Navigate to={routes.timeclockId.path} replace />}
            />
            <Route
              path={routes.timeclockConfiguration.path}
              element={<ConfigurationTab />}
            />
            <Route path={routes.timeclockId.path} element={<IdTab />} />
            <Route
              path={routes.timeclockEstablishment.path}
              element={<EstablishmentTab />}
            />
          </Route>
          <Route
            path={routes.settingsLegalRgpd.path}
            element={<LegalSetting />}
          />
        </Route>
        <Route
          path={routes.modulationLocationContract.path}
          element={<ModulationLocationContractPage />}
        />
        <Route
          path={routes.integrations.path}
          element={<Navigate to={routes.tillerInfo.path} />}
        />

        <Route path={routes.tillerInfo.path} element={<TillerInfoPage />} />
        <Route path={routes.zeltyInfo.path} element={<ZeltyInfoPage />} />
        <Route path={routes.cashpadInfo.path} element={<CashpadInfoPage />} />
        <Route
          path={routes.innovorderInfo.path}
          element={<InnovorderInfoPage />}
        />
        <Route
          path={routes.lightspeedInfo.path}
          element={<LightspeedInfoPage />}
        />
        <Route path={routes.menlogInfo.path} element={<MenlogInfoPage />} />
        <Route
          path={routes.ladditionInfo.path}
          element={<LadditionInfoPage />}
        />
        <Route path={routes.carrePOSInfo.path} element={<CarrePOSInfoPage />} />
        <Route
          path={routes.pielectronicsInfo.path}
          element={<PIElectronicsInfoPage />}
        />

        <Route path={routes.iziPassInfo.path} element={<IziPassInfoPage />} />
        <Route path={routes.adpInfo.path} element={<ADPInfoPage />} />
        <Route
          path={routes.authWildcard.rootPath}
          element={<Navigate to={routes.welcome.rootPath} />}
        />
        <Route
          path={routes.electronicSignature.rootPath}
          element={<ElectronicSignature />}
        />
        <Route
          path='*'
          element={
            <PageCentered>
              <NotFoundError />
            </PageCentered>
          }
        />
      </Routes>
    </SuspenseWrap>
  )
})
