import type { ExportProperties } from './types/export'
import type { ScheduleFeaturesProperties } from './types/scheduleFeature'

export * from './types/scheduleFeature'
export * from './types/export'

export type BannerProperties = {
  account_id?: number
  item_type: ItemType
  item_name: ItemName
  action_name: ActionName
  banner_id: string
  page_source: string
}

export type SilaeBannerProperties = Omit<BannerProperties, 'banner_id'> & {
  subdomain?: string
  user_email?: string
  membership_id?: number
}

export type CollapsibleProperties = {
  feature: string
  action_name: ActionName
  panel_name: string
}

export type AddonSubscriptionProperties = {
  account_id: number
  addon_name: ItemName
  step_name: StepName
  funnel_id: string
}

export type SilaeProperties = {
  action_name: SilaeActionName
  page_source: string
  account_id?: number
  subdomain?: string
  user_email?: string
  membership_id?: number
}

export type PinnedItemProperties = {
  action_name: PinnedItem
  page_name: string
  account_id?: number
  subdomain?: string
  user_email?: string
  membership_id?: number
}

export type ESignDocProperties = {
  action_name: ESignActionName
  page_source: string
  account_id?: number
  subdomain?: string
  user_email?: string
  membership_id?: number
}

export type LoginCompletedProperties = {
  userId: string
  email: string
  account_ids: number[]
  membership_ids: number[]
  super_user: boolean
}

export type AccountCreatedProperties = {
  userId: string
  account_id: number
}

export type TrackProperties =
  | AddonSubscriptionProperties
  | BannerProperties
  | CollapsibleProperties
  | ExportProperties
  | ScheduleFeaturesProperties
  | SilaeBannerProperties
  | SilaeProperties
  | PinnedItemProperties
  | ESignDocProperties
  | LoginCompletedProperties
  | AccountCreatedProperties

export enum ActionName {
  BANNER_DISPLAYED = 'banner_displayed',
  BANNER_CLICKED = 'banner_clicked',
  BANNER_CLOSED = 'banner_closed'
}

export enum ItemName {
  PAYSLIPS = 'payslips_addon',
  SILAE = 'silae_addon'
}

export enum ItemType {
  PLAN = 'plan',
  ADDON = 'addon'
}

export enum PinnedItem {
  PINNED_ITEM = 'PIN ITEM',
  UNPINNED_ITEM = 'UNPIN ITEM'
}

export enum StepName {
  LANDING_PAGE_DISPLAYED = 'landing_page_displayed',
  MODAL_DISPLAYED = 'modal_displayed',
  ADDON_SUBSCRIBED = 'addon_subscribed'
}

export enum EventName {
  SILAE_LANDING_INTERACTED = 'Silae Landing Interacted',
  SILAE_SUBSCRIPTION_MODAL_INTERACTED = 'Silae Subscription Modal Interacted',
  BANNER_INTERACTED = 'Banner Interacted',
  ADDON_SUBSCRIPTION_COMPLETED = 'Addon Subscription - Step Completed',
  SCHEDULE_FEATURES_INTERACTED = 'Schedule Features Interacted',
  EXPORT_INTERACTED = 'Export Interaction',
  PINNED_ITEM_INTERACTED = 'Pin Item',
  ESIGN_DOC_INTERACTED = 'Download Esign doc',
  LOGIN_COMPLETED = 'Login Completed',
  ACCOUNT_CREATED = 'Account Created'
}

export enum SilaeActionName {
  TOP_LANDING_INTERACTION = 'top_silae_landing_interaction',
  BOTTOM_LANDING_INTERACTION = 'bottom_silae_landing_interaction',
  NEXT_STEP_SUBSCRIPTION = 'next_step_silae_subscription',
  CLOSE_SUBSCRIPTION = 'close_silae_subscription',
  FIND_ID_SUBSCRIPTION = 'find_id_silae_subscription',
  DO_SUBSCRIPTION = 'do_silae_subscription'
}

export enum ESignActionName {
  DOWNLOAD_ESIGNED_DOC = 'download_esign_doc',
  DOWNLOAD_PROOF_OF_SIGNATURE = 'download_proof_of_signature',
  DOWNLOAD_REMIND = 'download_remind'
}
