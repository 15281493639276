import Immutable from 'immutable'
import queryString from 'query-string'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_MEMBERSHIPS_SUCCEEDED,
  FETCH_MEMBERSHIP_SUCCEEDED,
  CREATE_MEMBERSHIP_SUCCEEDED,
  UPDATE_MEMBERSHIP_SUCCEEDED,
  INVITE_MEMBERSHIP_SUCCEEDED,
  FETCH_USER,
  FETCH_USER_SUCCEEDED,
  FETCH_USER_FAILED,
  FETCH_USER_TOUCAN_JWT,
  FETCH_USER_TOUCAN_JWT_SUCCEEDED,
  FETCH_USER_TOUCAN_JWT_FAILED,
  UPDATE_USER,
  UPDATE_USER_SUCCEEDED,
  UPDATE_USER_FAILED,
  FETCH_REPORT_FILES_SUCCEEDED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import { mergeRecords, updateRecord } from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'
import { FETCH_ALL_MEMBERSHIPS_SUCCESS } from '../memberships/actionTypes'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchUser = (userId, resolve, reject) => ({
  type: FETCH_USER,
  userId,
  resolve,
  reject
})

export const fetchUserToucanJWT = (userId, params, resolve, reject) => ({
  type: FETCH_USER_TOUCAN_JWT,
  userId,
  params,
  resolve,
  reject
})

export const updateUser = (userId, userData, params, resolve, reject) => ({
  type: UPDATE_USER,
  userId,
  userData,
  params,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const userToucanJWTSagaEntity = {
  success: (data, userId) => ({
    type: FETCH_USER_TOUCAN_JWT_SUCCEEDED,
    data,
    userId
  }),
  failure: (error, userId) => ({
    type: FETCH_USER_TOUCAN_JWT_FAILED,
    error,
    userId
  }),

  fetchAPI: (userId, options, params) =>
    callAPI(
      `/users/${userId}/toucan_jwt?${queryString.stringify(params)}`,
      options
    )
}

export function* doFetchUserToucanJWT({
  userId,
  userData,
  params,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    userToucanJWTSagaEntity,
    userId,
    params,
    userData,
    resolve,
    reject
  )
}

const userSagaEntity = {
  success: (data, userId) => ({
    type: FETCH_USER_SUCCEEDED,
    data,
    userId
  }),
  failure: (error, userId) => ({
    type: FETCH_USER_FAILED,
    error,
    userId
  }),

  fetchAPI: (userId, options) => callAPI(`/users/${userId}`, options)
}

export function* doFetchUser({ userId, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    userSagaEntity,
    userId,
    undefined,
    undefined,
    resolve,
    reject
  )
}

const updateUserSagaEntity = {
  success: (data, userId, userData) => ({
    type: UPDATE_USER_SUCCEEDED,
    data,
    userId,
    userData
  }),
  failure: (error, userId, userData) => ({
    type: UPDATE_USER_FAILED,
    error,
    userId,
    userData
  }),

  fetchAPI: (userId, options, params) =>
    callAPI(
      `/users/${userId}?${
        typeof params === 'object' ? queryString.stringify(params) : params
      }`,
      { method: 'PUT', ...options }
    )
}

export function* doUpdateUser({ userId, userData, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    updateUserSagaEntity,
    userId,
    params,
    userData,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const User = Immutable.Record({
  id: null,
  firstname: null,
  lastname: null,
  phone_number: null,
  mobile_phone: null,
  fixed_line_phone: null,
  email: null,
  avatar_urls: null,
  birth_date: null,
  birth_place: null,
  citizenship: null,
  gender: null,
  social_security_number: null,
  national_identifier: null,
  street_address: null,
  zip: null,
  city: null,
  invitation_accepted_at: null,
  invitation_sent_at: null,
  created_at: null,
  updated_at: null,
  locale: null
})

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false, success: false, error: false }),
  relations: Immutable.Map()
})

function users(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case FETCH_ALL_MEMBERSHIPS_SUCCESS: {
      // @ts-ignore migration from js(x) to ts(x)
      const users = action.payload.memberships.map(m => m.get('user'))
      return mergeRecords(state, User, users)
    }
    case FETCH_MEMBERSHIPS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const users = Immutable.fromJS(action.data).map(membership =>
        membership.get('user')
      )

      return mergeRecords(state, User, users)
    }
    case FETCH_MEMBERSHIP_SUCCEEDED:
    case CREATE_MEMBERSHIP_SUCCEEDED:
    case UPDATE_MEMBERSHIP_SUCCEEDED:
    case INVITE_MEMBERSHIP_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const user = Immutable.fromJS(action.data.user || {})
      return updateRecord(state, User, user)
    }
    case UPDATE_USER: {
      return state.mergeDeepIn(['meta'], {
        // @ts-ignore migration from js(x) to ts(x)
        loading: true,
        success: false,
        error: false
      })
    }
    case FETCH_USER_SUCCEEDED:
    case UPDATE_USER_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const user = Immutable.fromJS(action.data)

      return updateRecord(state, User, user).mergeDeepIn(['meta'], {
        loading: false,
        success: true,
        error: false
      })
    }
    case FETCH_REPORT_FILES_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const users = Immutable.fromJS(action.data).map(file =>
        file.get('requester')
      )
      return mergeRecords(state, User, users)
    }
    case FETCH_USER_TOUCAN_JWT_SUCCEEDED:
    default:
      return state
  }
}

const filterList = [
  FETCH_MEMBERSHIPS_SUCCEEDED,
  FETCH_MEMBERSHIP_SUCCEEDED,
  CREATE_MEMBERSHIP_SUCCEEDED,
  UPDATE_MEMBERSHIP_SUCCEEDED,
  INVITE_MEMBERSHIP_SUCCEEDED,
  FETCH_USER_SUCCEEDED,
  UPDATE_USER,
  UPDATE_USER_FAILED,
  UPDATE_USER_SUCCEEDED,
  FETCH_USER_TOUCAN_JWT_SUCCEEDED,
  FETCH_USER_TOUCAN_JWT_FAILED,
  FETCH_REPORT_FILES_SUCCEEDED,
  FETCH_ALL_MEMBERSHIPS_SUCCESS
]

export default filterActions(users, filterList)
