import type {
  LocationRecord,
  LocationRecordMap
} from '@libs/data-access/entities'
import type { ReduxState } from '@spa/redux/ReduxState'
import { defaultMemoize, createSelectorCreator, createSelector } from 'reselect'
import {
  isImmutableEquals,
  getDataByForeignId
} from 'snap-redux/services/immutableUtils'

import { getCurrentAccountId } from '../accounts/selectors'

export const getIsLocationsLoading = (state: ReduxState): boolean =>
  state.locations.getIn(['meta', 'loading'])

export const getLocationsByAccountIdUnsorted = (
  state: ReduxState,
  accountId?: number | string,
  archived = false
): LocationRecordMap =>
  getDataByForeignId(state, 'locations', 'account_id', accountId).filter(
    location => location.archived === archived
  )

const locationByAccountIdSelectorCreator = createSelectorCreator(
  defaultMemoize,
  isImmutableEquals
)

export const getLocationsByAccountId = locationByAccountIdSelectorCreator(
  getLocationsByAccountIdUnsorted,
  (locations: LocationRecordMap): LocationRecordMap =>
    locations.sort((firstLoc, secLoc) =>
      firstLoc.name.localeCompare(secLoc.name)
    ) as LocationRecordMap
)

export const getFirstLocationByAccountId = locationByAccountIdSelectorCreator(
  getLocationsByAccountId,
  (locations: LocationRecordMap): LocationRecord =>
    locations?.first() as LocationRecord
)

export const getAllLocationsByAccountId = (
  state: ReduxState,
  accountId: number
): LocationRecordMap =>
  getDataByForeignId(state, 'locations', 'account_id', accountId)

export const haveArchivedLocations = (
  state: ReduxState,
  accountId: number | string
): boolean => getLocationsByAccountIdUnsorted(state, accountId, true).size > 0

export const getLocationById = (
  state: ReduxState,
  locationId: number
): LocationRecord => state.locations.getIn(['data', Number(locationId)])

export const getCurrentAccountLocations: (
  state: ReduxState
) => LocationRecordMap = state =>
  createSelector(getCurrentAccountId, accountId =>
    getLocationsByAccountId(state, accountId)
  )(state)
