import { createSafeContext } from '@libs/ui/ds/lib/internals'

import type { RadioCardButtonProps } from '../RadioCardButton.types'

import type { RadioCardButtonGroupProps } from './RadioCardButtonGroup.types'

export type RadioCardButtonGroupContext = {
  onChange?: (value: string) => void
  value?: RadioCardButtonGroupProps['value']
  name?: RadioCardButtonGroupProps['name']
  disabled?: RadioCardButtonGroupProps['disabled']
}

export const [RadioCardButtonGroupProvider, useRadioCardButtonGroupContext] =
  createSafeContext<RadioCardButtonGroupContext>({
    context: 'RadioButtonGroup'
  })

export const useMergedRadioCardButtonProps = (
  radioCardButtonProps: Pick<
    RadioCardButtonProps,
    'disabled' | 'name' | 'onChange' | 'value'
  >
) => {
  const groupProps = useRadioCardButtonGroupContext({
    throwErrorIfNoContext: false
  })
  if (!groupProps) {
    return radioCardButtonProps
  }

  return {
    disabled: groupProps.disabled || radioCardButtonProps.disabled,
    name: groupProps.name,
    value: groupProps.value,
    onChange: () => {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      groupProps.onChange(radioCardButtonProps.value)
    }
  }
}
