import { Accordion as MantineAccordion } from '@mantine/core'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import { memo } from 'react'

import type { AccordionProps } from './Accordion.types'
import { AccordionControl } from './compound/AccordionControl/AccordionControl'
import { AccordionItem } from './compound/AccordionItem/AccordionItem'
import { AccordionPanel } from './compound/AccordionPanel/AccordionPanel'

const accordionCVA = {
  chevron: cva(['w-auto [&[data-rotate]]:-rotate-90']),
  content: cva(['px-6 pb-6 pt-0']),
  label: cva(['p-0'])
}

const BaseAccordion: FC<AccordionProps> = memo(
  ({ children, onOpen, onClose, ...other }) => {
    return (
      <MantineAccordion
        {...other}
        onChange={value => {
          if (onOpen) {
            onOpen(value)
          }
          if (onClose) {
            onClose(value)
          }
        }}
        classNames={{
          chevron: accordionCVA.chevron(),
          label: accordionCVA.label(),
          content: accordionCVA.content()
        }}
      >
        {children}
      </MantineAccordion>
    )
  }
)

type CompoundAccordionType = {
  Item: typeof AccordionItem
  Control: typeof AccordionControl
  Panel: typeof AccordionPanel
}

const TypedAccordion = BaseAccordion as typeof BaseAccordion &
  CompoundAccordionType
TypedAccordion.displayName = 'Accordion'

TypedAccordion.Item = AccordionItem
TypedAccordion.Control = AccordionControl
TypedAccordion.Panel = AccordionPanel
export const Accordion = TypedAccordion
export type { AccordionProps } from './Accordion.types'
export type { AccordionItemProps } from './compound/AccordionItem/AccordionItem.types'
export type { AccordionControlProps } from './compound/AccordionControl/AccordionControl.types'
export type { AccordionPanelProps } from './compound/AccordionPanel/AccordionPanel.types'
