import type { PayloadAction } from '@reduxjs/toolkit'
import { SagaType, createSliceSaga } from 'redux-toolkit-saga'

import deleteDocumentTemplateData from '../generator/deleteDocumentTemplate'
import fetchDocumentTemplateData from '../generator/fetchDocumentTemplate'
import fetchDocumentTemplateAttributesData from '../generator/fetchDocumentTemplateAttributes'
import fillDocumentTemplateData from '../generator/fillDocumentTemplate'
import generatingDocumentData from '../generator/generatingDocument'
import postDocumentTemplateData from '../generator/postDocumentTemplate'
import updateDocumentTemplateData from '../generator/updateDocumentTemplate'

import type {
  ParamsDeleteDocumentTemplate,
  ParamsFillDocumentTemplate,
  ParamsGenerateDocument,
  ParamsGetDocumentTemplate,
  ParamsPostDocumentTemplate,
  ParamsUpdateDocumentTemplate
} from './entities'

const documentTemplateSagaSlice = createSliceSaga({
  name: 'emp/documentTemplate/sagas',
  caseSagas: {
    /**
     * Fetch all the attributes used for the document template feature.
     */
    *fetchDocumentTemplateAttributes() {
      yield fetchDocumentTemplateAttributesData()
    },
    /**
     * Create a new Document template.
     */
    *postDocumentTemplate({
      payload
    }: PayloadAction<ParamsPostDocumentTemplate>) {
      yield postDocumentTemplateData(payload)
    },
    /**
     * Fetch all the documents template in the database in a paginated manner.
     */
    *fetchDocumentTemplate({
      payload
    }: PayloadAction<ParamsGetDocumentTemplate>) {
      yield fetchDocumentTemplateData(payload)
    },
    /**
     * Update the document template by id in the database.
     */
    *updateDocumentTemplate({
      payload
    }: PayloadAction<ParamsUpdateDocumentTemplate>) {
      yield updateDocumentTemplateData(payload)
    },
    /**
     * Delete the document template by id in the database.
     */
    *deleteDocumentTemplate({
      payload
    }: PayloadAction<ParamsDeleteDocumentTemplate>) {
      yield deleteDocumentTemplateData(payload)
    },
    /**
     * Download the document generated. This saga is supposed to be trigger by the return payload of the WS.
     */
    *generateDocument({ payload }: PayloadAction<ParamsGenerateDocument>) {
      yield generatingDocumentData(payload)
    },
    /**
     * POST method send to the back to launch the worker for the fill of the documentTemplate.
     */
    *fillDocumentTemplate({
      payload
    }: PayloadAction<ParamsFillDocumentTemplate>) {
      yield fillDocumentTemplateData(payload)
    }
  },
  sagaType: SagaType.TakeLatest
})

export const {
  fetchDocumentTemplateAttributes,
  postDocumentTemplate,
  fetchDocumentTemplate,
  updateDocumentTemplate,
  deleteDocumentTemplate,
  generateDocument,
  fillDocumentTemplate
} = documentTemplateSagaSlice.actions

export default documentTemplateSagaSlice.saga()
