import { binaryFactor, binaryUnit } from '../file.constants'

const defaultDecimal = 2

type Params = {
  decimal?: 0 | 1 | 2 | 3
  value: number
}

/**
 * Format bytes numbers to readable format
 *
 * E.g.
 *  formatBytesToSize(2048) // returns 2.00 KB
 *  formatBytesToSize(1048576) // returns 1.00 MB
 */
export const formatBytesToSize = ({ value, decimal }: Params) => {
  const decimalOrDefault = decimal ?? defaultDecimal

  if (!+value) {
    const decimalValue = Number(0).toFixed(decimalOrDefault)
    return `${decimalValue}B`
  }

  const i = Math.floor(Math.log(value) / Math.log(binaryFactor))
  const sizeValue = parseFloat(
    (value / binaryFactor ** i).toFixed(decimalOrDefault)
  )

  return `${sizeValue}${binaryUnit[i]}`
}
