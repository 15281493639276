import type { Rest, ShiftRecordMap } from '@libs/data-access/entities'
import { call } from 'redux-saga/effects'
import { call as callAPI } from 'snap-redux/infra/http/api'

import createOrUpdateRest from './createOrUpdateRest'

export type UpdateRestPayload = {
  restId: number
  onFormSuccess: () => void
  rest: Partial<Rest & { delete_shifts?: boolean }>
  overlappingShiftsIds?: string[]
  shifts: ShiftRecordMap
}

/**
 *
 */
const updateRestCallAPI = ({
  body,
  restId
}: {
  restId: number
  body: Partial<Rest>
}) =>
  callAPI(`/rests/${restId}`, {
    method: 'PUT',
    body: JSON.stringify(body)
  }).catch(reason => ({
    status: 'err',
    userContractId: body.user_contract_id,
    reason
  }))

/**
 *
 */
function* updateRestSaga({
  shifts,
  overlappingShiftsIds,
  rest,
  onFormSuccess,
  restId
}: UpdateRestPayload) {
  yield createOrUpdateRest({
    shifts,
    overlappingShiftsIds,
    onFormSuccess,
    promises: [
      call(updateRestCallAPI, {
        restId,
        body: rest
      })
    ]
  })
}

export default updateRestSaga
