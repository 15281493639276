import { userService } from '@libs/data-access/queries'
import {
  DropdownMenu,
  Flex,
  IconWrap,
  AvatarLabel,
  ArrowDownIcon,
  BaseButton
} from '@libs/ui/ds'
import { useAppSelector } from '@spa/redux/hooks'
import { useAuthState } from '@spa/scenes/Auth/shared/services'
import type { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  getCurrentMembershipSelector,
  getMembershipsByUserId
} from 'snap-redux/modules/memberships/selectors'

import { UserMenuDropdownContent } from './UserMenuDropdownContent'

export const UserMenuDropdown: FC = () => {
  const { auth } = useAuthState()
  const currentUser = userService.user.useFetchCurrent({})
  const currentMembership = useSelector(getCurrentMembershipSelector)
  const currentUserMemberships = useAppSelector(state =>
    getMembershipsByUserId(state, auth.userId)
  )

  return (
    <DropdownMenu
      dropdownWidth='m'
      dropdownPosition='bottom-end'
      className='h-full'
      dropdownHeight='l'
    >
      <DropdownMenu.Target className='flex items-center justify-center'>
        <BaseButton
          className='rounded-full bg-transparent px-3 py-2 text-neutral-75 hover:bg-neutral-700/60 active:bg-neutral-700'
          data-testid='navbar-profile'
        >
          <Flex row className='flex-nowrap items-center gap-1'>
            <AvatarLabel
              src={currentUser?.data?.avatar_urls?.thumb}
              labels={[
                currentMembership?.firstname || '',
                currentMembership?.lastname || ''
              ]}
              size='s'
              variant='inherits'
            />
            <IconWrap icon={<ArrowDownIcon />} size='s' />
          </Flex>
        </BaseButton>
      </DropdownMenu.Target>
      <DropdownMenu.Dropdown>
        <UserMenuDropdownContent
          currentUserMemberships={currentUserMemberships}
          currentMembership={currentMembership}
        />
      </DropdownMenu.Dropdown>
    </DropdownMenu>
  )
}
