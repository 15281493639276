import { useComponentsTranslation } from '@libs/utils/translations'
import { useMemoSelector } from '@spa/redux/hooks'
import { useEffect } from 'react'
import { getCurrentAccount } from 'snap-redux/modules/accounts/selectors'

export const useInitTitle = () => {
  const account = useMemoSelector(getCurrentAccount)
  const { t } = useComponentsTranslation()

  const title = account?.name
    ? t('components.app.titleName', { name: account.name })
    : t('components.app.title')

  useEffect(() => {
    document.title = title
  }, [title])
}
