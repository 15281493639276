import { AppQueryClientProvider } from '@libs/data-access/queries'
import { DesignSystemProvider, Loader } from '@libs/ui/ds'
import { ErrorBoundary } from '@libs/utils/error-monitoring'
import { I18nAppProvider } from '@libs/utils/translations'
import {
  OutdatedError,
  PageCentered,
  UnhandledError
} from '@spa/components/ErrorsFallback'
import { FaroSetup } from '@spa/core/externals/faro/components/FaroSetup'
import * as ga from '@spa/core/externals/ga/trackHistoryChange'
import store from '@spa/redux/store'
import { AuthStateProvider } from '@spa/scenes/Auth/shared/services'
import type { FC, PropsWithChildren } from 'react'
import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import '@spa/components/legacyGlobalCSS/index.css'

ga.trackHistoryChange()

export const CommonProviders: FC<PropsWithChildren> = ({ children }) => {
  return (
    <DesignSystemProvider>
      <I18nAppProvider>
        <ErrorBoundary
          errorsFallback={{
            ChunkLoadError: (
              <PageCentered>
                <OutdatedError />
              </PageCentered>
            ),
            MimeMismatchError: (
              <PageCentered>
                <OutdatedError />
              </PageCentered>
            )
          }}
          defaultFallback={
            <PageCentered>
              <UnhandledError />
            </PageCentered>
          }
        >
          <DndProvider backend={HTML5Backend}>
            <AppQueryClientProvider>
              <React.Suspense fallback={<Loader.Container />}>
                <BrowserRouter>
                  <Provider store={store}>
                    <AuthStateProvider>
                      <FaroSetup />
                      {children}
                    </AuthStateProvider>
                  </Provider>
                </BrowserRouter>
              </React.Suspense>
            </AppQueryClientProvider>
          </DndProvider>
        </ErrorBoundary>
      </I18nAppProvider>
    </DesignSystemProvider>
  )
}
