import { httpServices } from '@libs/data-access/queries'
import {
  GOOGLE_RECAPTCHA_KEY,
  IS_GOOGLE_RECAPTCHA_AVAILABLE
} from '@libs/utils/environments'
import { useEffect } from 'react'

export const useInitRecaptcha = () => {
  useEffect(() => {
    if (
      IS_GOOGLE_RECAPTCHA_AVAILABLE &&
      !document.getElementById('grecaptcha')
    ) {
      const script = document.createElement('script')
      script.id = 'grecaptcha'
      script.src = `https://www.google.com/recaptcha/api.js?render=${GOOGLE_RECAPTCHA_KEY}`

      document.body.append(script)

      const styleElement = document.createElement('style')
      styleElement.append('.grecaptcha-badge{visibility: hidden;}')
      document.head.append(styleElement)
    }
  }, [])
}

export const submitRecaptchaValidation = async () => {
  const grecaptcha: any | null = window.document.getElementById('grecaptcha')

  if (!IS_GOOGLE_RECAPTCHA_AVAILABLE || !grecaptcha) {
    return
  }

  const token = await grecaptcha.execute(GOOGLE_RECAPTCHA_KEY, {
    action: 'submit'
  })

  await httpServices.core.get(`/recaptcha?token=${token}`)
}
