import { useDebouncedFunction } from '@libs/ui/ds'
import {
  INTERCOM_APP_ID,
  IS_INTERCOM_AVAILABLE
} from '@libs/utils/environments'
import { isImpersonationContext } from '@spa/scenes/Auth/shared/services/authState/authServiceUtils'
import { useEffect, useState, type FC } from 'react'
import { IntercomProvider } from 'react-use-intercom'

import { useCreateIntercomUserHash } from '../useCreateIntercomUserHash'

import { AuthenticatedCustomerCareMenuDropdown } from './AuthenticatedCustomerCareMenuDropdown'

export const AuthenticatedCustomerCareMenu: FC = () => {
  const { userHash, userId } = useCreateIntercomUserHash()
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0)
  const debouncedSetUnreadMessagesCount = useDebouncedFunction(
    setUnreadMessagesCount,
    { delay: 400 }
  )

  useEffect(() => {
    return () => {
      debouncedSetUnreadMessagesCount.cancel()
    }
  }, [])

  if (isImpersonationContext() || !IS_INTERCOM_AVAILABLE) {
    return null
  }

  return (
    <IntercomProvider
      autoBoot
      appId={INTERCOM_APP_ID}
      onUnreadCountChange={debouncedSetUnreadMessagesCount}
      autoBootProps={{
        userHash,
        userId: String(userId),
        customAttributes: {
          hide_default_launcher: true
        }
      }}
    >
      <AuthenticatedCustomerCareMenuDropdown
        unreadMessagesCount={unreadMessagesCount}
      />
    </IntercomProvider>
  )
}
