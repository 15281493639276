import { useMutation } from '@tanstack/react-query'

import type { ApiErrorResponse, UseAppMutationOptions } from '../../../../types'
import type { BankPaymentLocationBasedOptions } from '../locationGroupBankPayment.api'
import { bankPaymentLocationBased } from '../locationGroupBankPayment.api'
import { bankPaymentLocationBased as bankPaymentLocationBasedKey } from '../locationGroupPayment.cacheKeys'

export type BackPaymentResult = {
  id: string
  url: string
}

export const useBankPaymentLocationGroup = (
  mutationOptions?: UseAppMutationOptions<
    BackPaymentResult,
    ApiErrorResponse,
    BankPaymentLocationBasedOptions
  >
) => {
  return useMutation({
    mutationKey: bankPaymentLocationBasedKey.one(),
    mutationFn: (options: BankPaymentLocationBasedOptions) =>
      bankPaymentLocationBased(options),
    onSuccess: (...args) => {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      mutationOptions?.onSuccess(...args)
    }
  })
}
