import type {
  FetchCurrentUserQueryParams,
  UserSchema
} from '@libs/data-access/entities'

import { httpServices } from '../../../apis'
import type { QueryFnParams } from '../../../types'

export type FetchCurrentUserOptions = QueryFnParams<{
  queryParams: FetchCurrentUserQueryParams
}>

export type FetchUserOptions = QueryFnParams<{
  pathParams: {
    user_id: number
  }
}>

/**
 *
 */
export const fetchCurrentUser = async (opts?: FetchCurrentUserOptions) => {
  const response = await httpServices.core.get<UserSchema>(`/users/me`, {
    params: opts?.queryParams
  })
  return response.data
}

export const fetchUserById = async (opts: FetchUserOptions) => {
  const response = await httpServices.core.get<UserSchema>(
    `/users/${opts.pathParams.user_id}`
  )

  return response.data
}
