import type { JobSchema } from '@libs/data-access/entities'

import { httpServices } from '../../../apis'
import type {
  PaginatedQueryRequest,
  PaginatedQueryResponse,
  QueryFnParams
} from '../../../types'

export type FetchJobsOptions = QueryFnParams<{
  queryParams: PaginatedQueryRequest & {
    search?: string
  }
}>

export const fetchJobs = async (opts?: FetchJobsOptions) => {
  const response = await httpServices.core.get<
    PaginatedQueryResponse<JobSchema, 'jobs'>
  >('/employee_management/accounts/jobs', {
    params: opts?.queryParams
  })
  return response.data
}

export type PostJobOptions = QueryFnParams<{
  payload: {
    title: string
  }
}>

export const postJob = async (opts?: PostJobOptions) => {
  const response = await httpServices.core.post<JobSchema>(
    '/employee_management/accounts/jobs',
    opts?.payload
  )
  return response.data
}

export type MergeJobsOptions = QueryFnParams<{
  payload: {
    source_ids: number[]
    target_id: number
  }
}>

export const mergeJobs = async (opts?: MergeJobsOptions) => {
  const response = await httpServices.core.post<JobSchema>(
    '/employee_management/accounts/jobs/merge',
    opts?.payload
  )

  return response.data
}

export type PutJobOptions = QueryFnParams<{
  pathParams: {
    job_ids: number
  }
  payload: {
    title: string
  }
}>

export const putJob = async (opts?: PutJobOptions) => {
  const response = await httpServices.core.put<JobSchema>(
    `/employee_management/accounts/jobs/${opts?.pathParams.job_ids}`,
    opts?.payload
  )
  return response.data
}
