import type {
  AccountStatus,
  SubscriptionStatus
} from '@libs/data-access/entities'

export const getStatusString = (
  billingAccountStatus?: AccountStatus
): SubscriptionStatus | null => {
  if (!billingAccountStatus) {
    return null
  }

  const { is_in_trial, is_cancelled, is_non_renewing, is_active } =
    billingAccountStatus

  if (is_in_trial) {
    return 'in_trial'
  }
  if (is_cancelled) {
    return 'cancelled'
  }
  if (is_non_renewing) {
    return 'non_renewing'
  }
  if (is_active) {
    return 'active'
  }

  return null
}
