import type { ImageMimeType } from '@libs/ui/ds'
import {
  BinIcon,
  Text,
  FilePaperDocumentIcon,
  Flex,
  IconButton,
  OfficeFilePDFIcon,
  SimpleCard,
  ThemeIcon,
  Tooltip,
  inputFileUtility,
  useDSTranslation,
  Image,
  imagesMimeTypes
} from '@libs/ui/ds'
import type { FileWithPath } from '@mantine/dropzone'
import { cva } from 'class-variance-authority'

const dropzoneFileCVA = {
  container: cva(['flex-nowrap items-center justify-between']),
  fileData: cva(['grow flex-nowrap items-center gap-2 truncate']),
  leftTextContainer: cva(['grow gap-1 truncate']),
  title: cva(['truncate']),
  desc: cva(['truncate text-neutral-400']),
  image: cva(['rounded'])
}

const mimeTypeIconsMap = {
  pdf: () => <OfficeFilePDFIcon />
}

type Props = {
  file: FileWithPath
  fileType: string[]
  onDelete: (file: FileWithPath) => void
  disabled?: boolean
}

export const DropzoneFile = ({ file, fileType, onDelete, disabled }: Props) => {
  const { t } = useDSTranslation()
  return (
    <SimpleCard size='s'>
      <Flex row className={dropzoneFileCVA.container()}>
        <Flex row className={dropzoneFileCVA.fileData()}>
          {imagesMimeTypes.includes(file.type as ImageMimeType) ? (
            <Image
              size='xs'
              fit='cover-center'
              aspect='square'
              className={dropzoneFileCVA.image()}
              src={URL.createObjectURL(file)}
              alt={file.name}
            />
          ) : (
            <ThemeIcon
              color='neutral'
              icon={
                mimeTypeIconsMap[fileType?.toString()]?.() || (
                  <FilePaperDocumentIcon />
                )
              }
              size='m'
            />
          )}
          <div className={dropzoneFileCVA.leftTextContainer()}>
            <Text size='m' className={dropzoneFileCVA.title()}>
              {file.name}
            </Text>
            <Text size='small' className={dropzoneFileCVA.desc()}>
              {inputFileUtility.formatBytesToSize({ value: file.size })}
            </Text>
          </div>
        </Flex>
        <Tooltip label={t('ds.components.dropzone.file.delete')}>
          <IconButton
            size='s'
            intent='danger'
            variant='tertiary'
            icon={<BinIcon />}
            onClick={() => onDelete(file)}
            disabled={disabled}
            className='ml-2'
          />
        </Tooltip>
      </Flex>
    </SimpleCard>
  )
}
