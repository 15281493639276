import type { ApiErrorResponse } from '@libs/data-access/queries'
import { put } from 'redux-saga/effects'
import { apiRequest } from 'snap-redux/infra/http'

import type { ParamsPostDocumentTemplate } from '../modules/entities'
import { createDocumentTemplate } from '../modules/entities'
import { setMetaLoading, setMetaSuccess, setMetaError } from '../modules/meta'

export default function* postDocumentTemplateData(
  body: ParamsPostDocumentTemplate
) {
  yield put(setMetaLoading())

  try {
    const result = yield apiRequest({
      service: 'core',
      route: 'postDocumentTemplate',
      body
    })

    yield put(createDocumentTemplate(result))
    yield put(setMetaSuccess())
  } catch (err) {
    yield put(setMetaError(err as ApiErrorResponse))
  }
}
