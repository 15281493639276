import type { BaseComponent } from '@libs/ui/ds/lib/internals'
import { Popover as MantinePopover } from '@mantine/core'
import { cva } from 'class-variance-authority'
import type { FC, HTMLAttributes, PropsWithChildren } from 'react'
import React, { memo } from 'react'

import { simpleCardCVA } from '../../../../cards/SimpleCard/base/SimpleCard.classes'
import type { SimpleCardProps } from '../../../../cards/SimpleCard/base/SimpleCard.types'
import { usePopoverContext } from '../Popover.context'

const popoverDropdownCVA = {
  root: cva(
    [
      simpleCardCVA.root({
        size: 's',
        rounded: 'm',
        shadow: true
      })
    ],
    {
      variants: {
        dropdownWidth: {
          s: '!min-w-56 !max-w-56',
          m: '!min-w-72 !max-w-72',
          l: '!min-w-96 !max-w-96',
          xl: '!min-w-120 !max-w-120',
          auto: '',
          target: ''
        },
        dropdownHeight: {
          s: '!max-h-52 overflow-y-auto',
          m: '!max-h-72 overflow-y-auto',
          l: '!max-h-96 overflow-y-auto',
          auto: '!h-auto'
        },
        loading: {
          true: 'overflow-hidden'
        }
      }
    }
  )
}

type Props = PropsWithChildren<
  BaseComponent & HTMLAttributes<HTMLDivElement> & SimpleCardProps
>

export const PopoverDropdown: FC<Props> = memo(
  ({ children, className, ...other }) => {
    const ctx = usePopoverContext()

    return (
      <MantinePopover.Dropdown
        {...other}
        className={popoverDropdownCVA.root({
          className,
          dropdownHeight: ctx?.dropdownHeight,
          dropdownWidth: ctx?.dropdownWidth
        })}
      >
        {children}
      </MantinePopover.Dropdown>
    )
  }
)

PopoverDropdown.displayName = 'PopoverDropdown'
