import { cookiesService } from '@libs/utils/cookies'
import { CLIENT_APP_NAME } from '@libs/utils/environments'
import i18n from '@libs/utils/translations'
import axios, { type AxiosRequestConfig } from 'axios'

import { defaultParamsSerializer } from '../../../internals'
import type { ApiErrorResponse } from '../../../types'
import type {
  HttpClientAuthState,
  HttpClientInstance,
  HttpClientInterface,
  HttpServiceEndpoints,
  SafeURLPath
} from '../interfaces/HttpClient.interface'

class HttpClientService implements HttpClientInterface {
  private httpClientInstance: HttpClientInstance

  constructor(
    private baseURL: string,
    authState: HttpClientAuthState
  ) {
    this.httpClientInstance = axios.create({
      baseURL: this.baseURL,
      paramsSerializer: defaultParamsSerializer,
      withCredentials: true
    })

    this.updateHeaders(authState)
    this.setupInterceptors()
  }

  private constructDefaultHeaders(
    opts: HttpClientAuthState
  ): Record<string, string> {
    const headers = {
      'Content-Type': 'application/json',
      'x-snapshift-app': CLIENT_APP_NAME,
      'Account-Id': String(opts.accountId)
    }

    const impersonatedMembershipId =
      cookiesService.readImpersonatedMembershipId()
    if (impersonatedMembershipId) {
      headers['impersonated-membership-id'] = String(impersonatedMembershipId)
    }

    return headers
  }

  public updateHeaders(opts: HttpClientAuthState): void {
    const newHeaders = this.constructDefaultHeaders(opts)
    Object.assign(this.httpClientInstance.defaults.headers.common, newHeaders)
  }

  public createServiceEndpoints(basePath: string): HttpServiceEndpoints {
    return {
      get: <T>(url: SafeURLPath, config?: AxiosRequestConfig<any>) =>
        this.httpClientInstance.get<T>(`${basePath}${url}`, config),
      post: <T>(
        url: SafeURLPath,
        data?: any,
        config?: AxiosRequestConfig<any>
      ) => this.httpClientInstance.post<T>(`${basePath}${url}`, data, config),
      put: <T>(
        url: SafeURLPath,
        data?: any,
        config?: AxiosRequestConfig<any>
      ) => this.httpClientInstance.put<T>(`${basePath}${url}`, data, config),
      patch: <T>(
        url: SafeURLPath,
        data?: any,
        config?: AxiosRequestConfig<any>
      ) => this.httpClientInstance.patch<T>(`${basePath}${url}`, data, config),
      delete: <T>(url: SafeURLPath, config?: AxiosRequestConfig<any>) =>
        this.httpClientInstance.delete<T>(`${basePath}${url}`, config)
    }
  }

  private setupInterceptors(): void {
    this.httpClientInstance.interceptors.response.use(
      response => response,
      error => {
        const defaultError = {
          message: i18n.t('common.app:message.error.internalErrorRetry'),
          target: null,
          code: null,
          details: []
        }

        const formattedError: ApiErrorResponse = {
          error: error.response?.data?.message
            ? error.response?.data
            : defaultError
        }

        return Promise.reject(formattedError)
      }
    )
  }
}

export default HttpClientService
